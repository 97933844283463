import { Injectable } from '@angular/core';
import { ILocalStorageService } from './ilocal-storage-service';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageKey } from './local-storage-key.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageManagerService implements ILocalStorageService {
  constructor(private _localStorage: LocalStorageService) {}

  clearAll() {
    this._existingKeys().map(k => this.deleteKey(k));
  }

  getKey(key: LocalStorageKey): string {
    return this._localStorage.getKey(key);
  }

  getKeyWtihCallback(
    key: LocalStorageKey,
    callback: () => Observable<string>,
  ): Observable<unknown> {
    return this._localStorage.getKeyWtihCallback(key, callback);
  }

  setKey(key: LocalStorageKey, value: string) {
    this._localStorage.setKey(key, value);
  }

  deleteKey(key: LocalStorageKey): void {
    this._localStorage.deleteKey(key);
  }

  private _existingKeys(): LocalStorageKey[] {
    const entries = [];
    for (const x in localStorage) {
      if (x.startsWith('{"') === false) {
        continue;
      }
      const key = JSON.parse(x) as LocalStorageKey;
      entries.push(
        new LocalStorageKey(key.modelStructureIndicator, key.keyName),
      );
    }
    return entries;
  }
}
