import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SearchableListItemWithSelect, SortOption } from '@nimbus/shared-lib';
import { Observable, of } from 'rxjs';
import { map, filter, switchMap, tap } from 'rxjs/operators';
import { LinksetCacheService } from '../../../../services/linkset-cache.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'linkset-sub-vertical',
  templateUrl: './sub-vertical.component.html',
  styleUrls: ['./sub-vertical.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubVerticalComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  readonly noSort: SortOption = SortOption.None;
  items$: Observable<SearchableListItemWithSelect[]>;
  private readonly parent = 'vertical';
  isLoading$ = of(false);

  constructor(
    public ovs: ObjectValidatorService,
    private _linksetCacheService: LinksetCacheService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._updateOnParentChanged();
  }

  private _updateOnParentChanged() {
    this.items$ = this.field.parent?.formControl
      .get('vertical')
      .valueChanges.pipe(
        filter(vertical => !this.ovs.isNullOrEmpty(vertical)),
        switchMap(vertical =>
          this._linksetCacheService
            .getSubverticals(vertical)
            .pipe(
              map(subVerticals =>
                [...new Set(subVerticals)].map(
                  subVertical => new SearchableListItemWithSelect(subVertical),
                ),
              ),
            ),
        ),
        tap(subverticals => {
          this.field.parent?.formControl.get('geo').setValue(null);
          this.field.parent?.formControl.get('language').setValue(null);
          if (
            !this.ovs.existsInAt(this.model.subvertical, subverticals, 'value')
          ) {
            if (this.formControl.value !== '') {
              this.formControl.setValue('');
            }
          }
        }),
      );
  }
}
