import { NgModule, ModuleWithProviders } from '@angular/core';
import { ObjectSanitizer } from './core/object-sanitizer';
import { TokenCacheService } from './services/user/token-cache.service';
import { ConfigService } from './core/config.service';
import { LocalStorageManagerService } from './core/local-storage/local-storage-manager.service';
import { LocalStorageKeyFactory } from './core/local-storage/local-storage-key-factory';
import { ObjectValidatorService } from './core/object-validator-service/object-validator.service';
import { QLocalStorageService } from './core/local-storage/local-storage.service';
import { TimeService } from './core/time-service';
import { DateRangeFactory } from './core/date-range-factory';
import { EnumHelperService } from './core/enum-helper.service';
import { UserProfileCacheService } from './services/user/user-profile-cache.service';
import { UserWebService } from './services/user/user.web.service';
import { HasUserContextGuard } from './services/user/has-user-context-guard.service';
import { TopOptionsTogglerModule } from './features/top-options-toggler/top-options-toggler.module';
import { TopOptionsTogglerComponent } from './features/top-options-toggler/top-options-toggler.component';
import { AngularMaterialModule } from './core/angular-material.module';
import { FileOperationsService } from './services/files/file-operations.service';
import { SnackbarService } from 'core-global-frontend-snackbar';
import { ConfirmationModule } from './features/layout/confirmation/confirmation.module';
import { SessionManagerService } from './services/session-manager/session-manager.service';
import { AuthWebService } from './services/user/auth.web.service';
import { NavigationModule } from './features/navigation/navigation.module';
import { NavigationService } from './features/navigation/navigation.service';
import { NavigatorModule } from './features/navigation/navigator/navigator.module';
import { NavigatorComponent } from './features/navigation/navigator/navigator.component';
import { AccessControlModule } from './features/access-control/access-control.module';
import { AccessControlGuardService } from './features/access-control/access-control-guard.service';
import { AccessControlService } from './features/access-control/access-control.service';
import { DateRangeFilterModule } from './form/date-range-filter/date-range-filter.module';
import { DateRangeFilterComponent } from './form/date-range-filter/date-range-filter.component';
import { ContextModule } from './features/context/context.module';
import { ContextComponent } from './features/context/component/context.component';
import { ContextFactoryService } from './features/context/services/context-factory.service';
import { ContextWebService } from './features/context/services/context-web.service';
import { ContextService } from './features/context/services/context.service';
import { LocalStorageProxyBuilder } from './core/local-storage/local-storage-proxy-builder';
import { KeyService } from './core/local-storage/key-service';
import { SearchableSelectControlModule } from './form/searchable-select-control/searchable-select-control.module';
import { HttpRequestInterceptor } from './services/user/interceptor/http-interceptor';
import { LoggerConfigBuilder } from './core/logging-service/logger-config-builder';
import { DockModule } from './features/layout/dock/dock.module';
import { EnvironmentDetailsService } from './features/debug-bar/services/environment-details.service';
import { HealthMonitorWebService } from './features/debug-bar/services/health-monitor-web.service';
import { DebugBarComponent } from './features/debug-bar/debug-bar.component';
import { DebugBarModule } from './features/debug-bar/debug-bar.module';
import { LayoutModule } from './features/layout/layout.module';
import { LayoutService } from './features/layout/layout.service';
import { SearchableChipListModule } from './form/searchable-chip-list/searchable-chip-list.module';
import { MessageHubService } from './core/message-hub.service';
import { ValidationErrorService } from './core/validation/validation-error.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { LibFormModule } from './form/lib-form.module';
import { UIBlockerService } from './features/navigation/ui-blocker-service/ui-blocker.service';
import { FormPreFiltersModule } from './features/layout/form-pre-filters/form-pre-filters.module';
import { PreFiltersService } from './features/layout/pre-filters.service';
import { GenericWebServiceBuilder } from './core/generic-web.service';
import { FormlyFiltersHelper } from './features/layout/formly-filters-helper';
import { LayoutPageServicesProxy } from './features/layout/layout-page-services-proxy';
import { StringHelper } from './core/string-helper';
// import { StoreModule } from '@ngrx/store';
// import { formsReducer } from './store/forms/reducer';
// import { FormEffects } from './store/forms/effects';
// import { EffectsModule } from '@ngrx/effects';
// import { FormsFeature } from './store/forms/form';
import { CustomInputMudule } from './form/types/custom-input/custom-input.module';
import { ActionButtonModule } from './form/types/action-button/action-button.module';
import { FormTypeModule } from './form/types/form-type/form-type.module';
import { FormlyJsonEditorModule } from './form/types/formly-json-editor/formly-json-editor.module';
import { FormDockModule } from './features/layout/form-dock/form-dock.module';
import { NewBuildNotifierService } from './features/new-build/new-build-notifier.service';
import { PaginationProperties } from './core/ientities';
import { TimePickerModule } from './form/controls/time-picker/time-picker.module';
import {
  HttpClientQ,
  HttpOptionsBuilderFactory,
  HttpUrlBuilderFactory,
} from 'core-global-frontend-http';
import { HttpHeadersBuilderFactory } from 'core/libs/global/frontend/http/src/lib/http/http-headers-builder.service';

@NgModule({
  imports: [
    TopOptionsTogglerModule,
    AngularMaterialModule,
    ConfirmationModule,
    ContextModule,
    NavigationModule,
    NavigatorModule,
    AccessControlModule,
    DateRangeFilterModule,
    SearchableSelectControlModule,
    SearchableChipListModule,
    DockModule,
    CustomInputMudule,
    LayoutModule,
    DebugBarModule,
    ReactiveFormsModule,
    FormlyMaterialModule,
    FormPreFiltersModule,
    LibFormModule,
    TimePickerModule,
    FormTypeModule,
    FormlyJsonEditorModule,
    ActionButtonModule,
    FormDockModule,
  ],
  exports: [
    TopOptionsTogglerComponent,
    NavigatorComponent,
    DebugBarComponent,
    DateRangeFilterComponent,
    ContextComponent,
  ],
})
export class SharedLibModule {
  // Using the forRoot "style" ensure that all provided service by the
  // shared library would be true singleton even for lazy laoded modules.
  static forRoot(): ModuleWithProviders<SharedLibModule> {
    return {
      ngModule: SharedLibModule,
      providers: [
        LocalStorageManagerService,
        QLocalStorageService,
        LocalStorageKeyFactory,
        ConfigService,
        DateRangeFactory,
        EnumHelperService,
        HttpOptionsBuilderFactory,
        HttpUrlBuilderFactory,
        StringHelper,
        TokenCacheService,
        HttpHeadersBuilderFactory,
        HttpClientQ,
        ObjectSanitizer,
        HasUserContextGuard,
        UserProfileCacheService,
        SessionManagerService,
        UserWebService,
        TimeService,
        LoggerConfigBuilder,
        ObjectValidatorService,
        FileOperationsService,
        SnackbarService,
        AuthWebService,
        NavigationService,
        AccessControlGuardService,
        AccessControlService,
        LayoutService,
        LayoutPageServicesProxy,
        GenericWebServiceBuilder,
        ContextFactoryService,
        ContextWebService,
        ContextService,
        PaginationProperties,
        LocalStorageProxyBuilder,
        KeyService,
        ValidationErrorService,
        HttpRequestInterceptor,
        EnvironmentDetailsService,
        HealthMonitorWebService,
        MessageHubService,
        UIBlockerService,
        PreFiltersService,
        FormlyFiltersHelper,
        NewBuildNotifierService,
      ],
    };
  }
}
