import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { ColumnClassHelper } from "../shared/columnClassHelper";
import { AnchorService } from "../../anchor.service";

@Component({
    selector: 'app-specifying-keywords',
    templateUrl: './specifying-keywords.component.html',
    styleUrls: ['./specifying-keywords.component.scss'],
    standalone: true,
    imports: [CommonModule]
})
export class SpecifyingKeywordsComponent {
    constructor(
        public columnClassHelper: ColumnClassHelper,
        private _anchorService: AnchorService
    ) { }

    forceKeyParameters = [
        {
            name: "area"
        },
        {
            name: "city"
        },
        {
            name: "country"
        },
        {
            name: "day"
        },
        {
            name: "keyword",
            note: "dynamically populated via `dynamic_term` URL parameter"
        },
        {
            name: "holiday"
        },
        {
            name: "month"
        },
        {
            name: "region"
        },
        {
            name: "state"
        },
        {
            name: "year"
        },
        {
            name: "zip"
        },
    ];

    keywordSpecificationMethods = [
        "appending URL parameters to the page’s URL.",
        "including keywords directly in the page’s JavaScript.",
        "using the keywords meta tag in the page’s HTML"
    ];

    keywordMetatagExamples = [
        "Accounting Software",
        "Accounting Degrees",
        "Accounting Services",
        "Tax Return Options",
    ];

    metaTagList(): string {
        return this.keywordMetatagExamples.join(', ');
    }

    public navigateToAnchor(location: string) {
        this._anchorService.updateAnchorLocation(location);
    }
}