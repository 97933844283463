import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  SCHEMAS_FEATURE_KEY,
  SchemasState,
  schemasAdapter,
} from './schemas.reducer';

// Lookup the 'Schemas' feature state managed by NgRx
export const selectSchemasState =
  createFeatureSelector<SchemasState>(SCHEMAS_FEATURE_KEY);

const { selectAll, selectEntities } = schemasAdapter.getSelectors();

export const selectSchemasLoaded = createSelector(
  selectSchemasState,
  (state: SchemasState) => state.loaded,
);

export const selectSchemasError = createSelector(
  selectSchemasState,
  (state: SchemasState) => state.error,
);

export const selectAllSchemas = createSelector(
  selectSchemasState,
  (state: SchemasState) => selectAll(state),
);

export const selectSchemasEntities = createSelector(
  selectSchemasState,
  (state: SchemasState) => selectEntities(state),
);

export const selectSelectedId = createSelector(
  selectSchemasState,
  (state: SchemasState) => state.selectedId,
);

export const selectEntity = createSelector(
  selectSchemasEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined),
);
