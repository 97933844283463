<h2 *ngIf="props?.label">{{ props.label }}</h2>
<json-editor
  *ngIf="formControl.value"
  [options]="editorOptions"
  [data]="formControl.value"
  (change)="change($event)"
></json-editor>
<button
  mat-flat-button
  color="primary"
  *ngIf="props.attributes?.['buttonLabel']"
  (click)="apply(); $event.stopPropagation()"
>
  {{ props.attributes?.['buttonLabel'] }}
</button>
