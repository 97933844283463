<ng-container>
  <div class="centered-with-top-header">
 <frontend-grid [model]="reviewsCacheService.subverticalRequestListGridModel"
    [class]="(reviewsCacheService.isLoading$|ngrxPush) === true ? 'minheight gray-loader' : ''" 
    [showRefreshButton]="true"
    [showOptionsBar]="true"
    (refreshEvent)="load()"
    [columns]="columns" 
    ></frontend-grid>
  </div>
</ng-container>
