import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'formly-wrapper-panel',
  templateUrl: './nimbus-wrapper-panel.component.html',
  styleUrls: ['./nimbus-wrapper-panel.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class NimbusWrapperPanelComponent extends FieldWrapper {}
