import { Injectable } from '@angular/core';
import { UserWebService } from './user.web.service';
import { ListItem } from '../../core/list-item';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserModel } from './user.model';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';

@Injectable()
export class UserProfileCacheService {
  usernames: UserModel[] = [];
  usernamesListOptions: ListItem[] = [];

  constructor(
    private _ovs: ObjectValidatorService,
    private _snackbarService: SnackbarService,
    private _userWebService: UserWebService
  ) {}

  loadUsers(): Observable<UserModel[]> {
    if (this._ovs.isNullOrEmpty(this.usernames) === false) {
      return of(this.usernames);
    }

    return this._userWebService.getUsers().pipe(
      map(data => {
        for (let i = 0; i < data.length; i++) {
          const item: UserModel = {
            userId: data[i].userId.toUpperCase(),
            username: data[i].username,
            fullName: data[i].fullName,
            type: data[i].type
          };
          this.usernames.push(item);

          const listitem = new ListItem(data[i].username, data[i].userId.toUpperCase(), i);
          this.usernamesListOptions.push(listitem);
        }

        return this.usernames;
      }),
      catchError((response: any) => {
        if (this._ovs.isDefined(response.error.message)) {
          this._snackbarService.open(
            'Loading Users: An error has occurred: ' + response.error.message,
            SnackBarType.error
          );
        } else {
          this._snackbarService.open(
            'Loading Users: An error has occurred loading users',
            SnackBarType.error
          );
        }
        return of([]);
      })
    );
  }

  getUsername(userId: string): string {
    if (this._ovs.isNullOrEmpty(userId)) {
      return '';
    }
    for (const item of this.usernames) {
      if (item.userId.toUpperCase() === userId.toUpperCase()) {
        return item.username;
      }
    }
    return '';
  }

  getUserId(username: string): string {
    for (const item of this.usernames) {
      if (item.username.toUpperCase() === username.toUpperCase()) {
        return item.userId;
      }
    }
    return '';
  }

  getUserType(userId: string): string {
    if (this._ovs.isNullOrEmpty(userId)) {
      return '';
    }
    for (const item of this.usernames) {
      if (item.userId.toUpperCase() === userId.toUpperCase()) {
        return item.type;
      }
    }
    return '';
  }
}
