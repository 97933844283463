import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-type',
  templateUrl: './checkbox-type.component.html',
  styleUrls: ['./checkbox-type.component.scss']
})
export class CheckboxTypeComponent extends FieldType {
  getFormControl() {
    return this.formControl as FormControl;
  }
}
