import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { SubjectItem } from './subject-item';
import { SubjectType } from './subject-type';
import { ObjectValidatorService } from './object-validator-service/object-validator.service';
import { SubjectRegistration } from './subject-registration';

export class SubjectManager {
  private _subjects: { [id: string]: SubjectItem<any> } = {};
  protected _ovs = new ObjectValidatorService();

  registerMultiple(
    subjectRegistrations: SubjectRegistration[] | string[],
    subjectType: SubjectType = SubjectType.ReplaySubject
  ) {
    if (this._ovs.isNullOrEmpty(subjectRegistrations)) {
      return;
    }
    if (this._ovs.isString(subjectRegistrations[0])) {
      subjectRegistrations.forEach(key => this.register(key as any as string, subjectType));
      return;
    }

    (subjectRegistrations as SubjectRegistration[]).forEach(subjectRegistration =>
      this.register(
        subjectRegistration.key,
        subjectRegistration.subjectType,
        subjectRegistration.input
      )
    );
  }

  register<T>(key: string, subjectType: SubjectType = SubjectType.ReplaySubject, input?: T) {
    if (!this._ovs.isDefined(this._subjects[key])) {
      switch (subjectType) {
        case SubjectType.ReplaySubject:
          this._subjects[key] = new SubjectItem<T>(new ReplaySubject<T>());
          if (input) {
            this._subjects[key].next(input);
          }
          break;
        case SubjectType.BehaviourSubject:
          this._subjects[key] = new SubjectItem<T>(new BehaviorSubject<T>(input as T));
          break;
        case SubjectType.Subject:
          this._subjects[key] = new SubjectItem<T>(new Subject<T>());
          if (input) {
            this._subjects[key].next(input);
          }
          break;
      }
    }
  }

  next<T>(key: string, input?: T): void {
    this._subjects[key].next(input as T);
  }

  clear() {
    for (const key in this._subjects) {
      this._subjects[key].unsubscribe();
    }
    this._subjects = {};
  }

  // Deprecated, use the get<T> instead.
  getObservable(key: string): Observable<any> {
    return this._subjects[key]?.getObservable();
  }

  get<T>(key: string): Observable<T> {
    return this._subjects[key].getObservable() as Observable<T>;
  }

  getSharedObservable<T>(key: string): Observable<T> {
    return this._subjects[key].observable$;
  }
}
