import { FilterMode } from './filter-mode.enum';

export class FilterBoxInit {
  get globalFilter(): boolean {
    return this.filterMode === FilterMode.Global || this.filterMode === FilterMode.GlobalAndColumns;
  }
  get columnFilter(): boolean {
    return (
      this.filterMode === FilterMode.Columns || this.filterMode === FilterMode.GlobalAndColumns
    );
  }
  get filterEnabled(): boolean {
    return this.globalFilter || this.columnFilter;
  }
  constructor(
    public filterMode: FilterMode = FilterMode.None,
    public columnNamesWithListFilter: string[] = [],
    public dateColumnNames: string[] = null,
    public listCallback = null,
    public persistenceId: string = null,
    public persistenceDurationInMs?: number
  ) {}
}
