import { Injectable } from '@angular/core';
import { GridClipboard } from '../q-models/grid-clipboard';
import { ClipboardDataConversionService } from './clipboard-data-conversion.service';
import { StringFunctionsService } from '../shared/string-functions.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class GridClipboardBuilderService {
  constructor(
    private _textToTable: ClipboardDataConversionService,
    private _stringFuncs: StringFunctionsService,
    private _ovs: ObjectValidatorService,
  ) {}
  build(): GridClipboard {
    return new GridClipboard(this._textToTable, this._stringFuncs, this._ovs);
  }
}
