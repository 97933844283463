<div class="mobile-header">
  <div
    class="top-bar-toggle"
    (click)="
      topOptionsTogglerCacheService.hideTopControl = !topOptionsTogglerCacheService.hideTopControl
    ">
    <svg class="top-bar-toggle__icon" role="img">
      <use xlink:href="assets/img/sprites.svg#gear-icon" />
    </svg>
  </div>
</div>
