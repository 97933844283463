import { GridInfo } from '../grid/grid-info';
import { NumberRange } from '../grid-models/number-range';

export class FileFromData {
  private readonly _columnDelimiter = ',';
  private readonly _carriageReturn = '\r';
  private readonly _lineFeed = '\n';
  private readonly _crLf = this._carriageReturn + this._lineFeed;

  getCsv(gridInfo: GridInfo, rowRumberRange: NumberRange): string {
    const { lower, upper } = rowRumberRange;
    let exportableColumns = gridInfo.columns.filter(
      column => column.csvExportable !== false,
    );
    let csvData = '';
    for (let rowIndex = lower; rowIndex < upper + 1; rowIndex += 1) {
      csvData +=
        exportableColumns.reduce(
          (acc, column) =>
            acc +
            (gridInfo.model[rowIndex][column.field] || '') +
            this._columnDelimiter,
          '',
        ) + this._crLf;
    }
    return `${exportableColumns.reduce((acc, column) => acc + column.label + this._columnDelimiter, '')}${this._crLf}${csvData}${this._crLf}`;
  }
}
