import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class DomainCreatorAddService {
  private domainAddedSubject = new Subject<void>();
  domainAdded$ = this.domainAddedSubject.asObservable();

  addDomain() {
    this.domainAddedSubject.next(null);
  }
}
