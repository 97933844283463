import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { SiteMapNode } from 'core-global-frontend-navigation';

@Component({
  selector: 'nimbus-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule],
  standalone: true,
})
export class DashboardCardComponent {
  constructor(private router: Router) {}
  @Input() siteMapNode: SiteMapNode;
  followLink(path: string) {
    this.router.navigate([path]);
  }
}
