import { Injectable } from '@angular/core';
import { ObjectValidatorService } from '../../../../../core/object-validator-service/object-validator.service';
import { JsonEditorValidator } from './jsoneditor-validator.service';

@Injectable({
  providedIn: 'root'
})
export class ActionButtonKeyValidatorService extends JsonEditorValidator {
  constructor(protected _ovs: ObjectValidatorService) {
    super();
    this.errorMessage = 'action-button should not have a key';
  }

  override isMatch(obj: any): boolean {
    return (
      this._ovs.isObject(obj) &&
      'type' in obj &&
      obj['type'].toString().toLowerCase() === 'action-button'
    );
  }

  isValid(obj: any): boolean {
    return this._ovs.isObject(obj) && !('key' in obj);
  }
}
