<div mat-dialog-actions>
    <h1 mat-dialog-title *ngFor="let message of data.messages">{{ message }}</h1>
    <button
      mat-button
      [mat-dialog-close]="'Thanks for approving.'"
      cdkFocusInitial
    >
      Ok
    </button>
  </div>

