import { Injectable } from '@angular/core';
import { ColumnConfigurationBuilder } from './column-configuration-builder';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { FilterBoxConfigurationBuilderFactory } from '../filter-box/services/filter-box-configuration-builder-factory';
@Injectable({
  providedIn: 'root',
})
export class ColumnConfigurationBuilderFactory {
  constructor(
    private _filterBoxConfigurationBuilderFactory: FilterBoxConfigurationBuilderFactory,
    private _ovs: ObjectValidatorService,
  ) {}
  get builder(): ColumnConfigurationBuilder {
    return new ColumnConfigurationBuilder(
      this._filterBoxConfigurationBuilderFactory,
      this._ovs,
    );
  }
}
