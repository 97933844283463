import { ValidationErrors, AbstractControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function MaxCharacterLengthValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  option: any = {}
): ValidationErrors {
  if (control.value == null || control.value == undefined || option.maxCharaters == undefined) {
    return {};
  }
  return control.value.length <= option.maxCharaters ? {} : { maxlength: true };
}
