import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { StepperResetService } from '../../../services/stepper/stepper-reset.service';

@Component({
  selector: 'formly-field-stepper',
  templateUrl: './stepper-section.component.html',
  styleUrls: ['./stepper-section.component.scss']
})
export class StepperSectionComponent extends FieldType implements AfterViewInit, OnDestroy {
  @ViewChild('selectList') selectList: ElementRef;
  @ViewChild('stepper') stepper: CdkStepper;
  nextText = 'Next';
  subscriptionManager: SubscriptionManager = new SubscriptionManager();

  constructor(
    private _renderer: Renderer2,
    private el: ElementRef,
    private _ovs: ObjectValidatorService,
    private _stepperResetService: StepperResetService
  ) {
    super();
  }

  isValid(formlyFieldConfig) {
    return formlyFieldConfig.key
      ? formlyFieldConfig.formControl.valid || formlyFieldConfig.formControl.disabled
      : formlyFieldConfig.fieldGroup
        ? formlyFieldConfig.fieldGroup.every(field => this.isValid(field))
        : true;
  }

  formValid(fieldGroup): boolean {
    return fieldGroup.every(field => (field.props['disabled'] ? true : this.isValid(field)));
  }

  getActiveSteps(fieldGroup) {
    return fieldGroup.filter(field => !field.props['disabled']);
  }

  ngAfterViewInit(): void {
    if (this.field.props['showStepperHeader'] === false) {
      this._renderer.setStyle(
        (<HTMLElement>this.el.nativeElement).querySelector(
          '.mat-horizontal-stepper-header-container'
        ),
        'display',
        'none'
      );
    }

    this.subscriptionManager.register(
      this._stepperResetService.stepperReset$.subscribe(() => {
        this.stepper.selectedIndex = 0;
      })
    );
  }

  skipTo(step: number): void {
    this.stepper.selectedIndex = step;
  }

  interactable(step: number): boolean {
    let valid = true;
    for (let i = 0; i < step; i++) {
      if (!this.isValid(this.getActiveSteps(this.field.fieldGroup)[i])) {
        valid = false;
      }
    }
    return valid;
  }

  ngOnDestroy(): void {
    this.subscriptionManager.clear();
  }

  hideFirstStep(): boolean {
    if (this._ovs.isNullOrUndefined(this.stepper)) {
      return true;
    }
    return this.props['hideOnFirstStep'] ? (this.stepper.selectedIndex === 0 ? false : true) : true;
  }
}
