import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { Observable, map } from 'rxjs';
import { PushPipe } from '@ngrx/component';
import { IClickableCell, ICustomCell } from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';

@Component({
  selector: 'configure-domain-cell',
  templateUrl: './configure-domain-cell.component.html',
  styleUrls: ['./configure-domain-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    PushPipe,
  ],
})
export class ConfigureDomainCellComponent
  implements ICustomCell, IClickableCell
{
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Output() clickEvent = new EventEmitter<ICustomCell>();
  enabledClass$: Observable<string>;

  ngOnInit(): void {
    this.enabledClass$ = this.form?.valueChanges.pipe(
      map(value => {
        let classSuffix = value.status === 'Success' ? 'enabled' : 'default';
        return `container ${classSuffix}`;
      }),
    );
  }

  configure() {
    this.clickEvent.emit(this);
  }
}
