import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DockContent } from './dock/dock-content';
import { DockVisibleState } from './dock/dock-visible-state';

@Injectable(/*Only provide this component at the area level so each area gets its own instance*/)
export class LayoutService {
  title: string;
  topOptionTogglerVisible: boolean = true;
  private _dockVisibilityChangeSubject = new ReplaySubject<DockVisibleState>();
  dockVisibilityChange$ = this._dockVisibilityChangeSubject.asObservable();
  parentDockResizeSubject = new ReplaySubject<number>();
  onParentDockResize$ = this.parentDockResizeSubject.asObservable();
  private _dockVisibility: DockVisibleState;
  get dockVisibility() {
    return this._dockVisibility;
  }
  set dockVisibility(dockVisibleChangeEvent: DockVisibleState) {
    this._dockVisibility = dockVisibleChangeEvent;
    this._dockVisibilityChangeSubject.next(dockVisibleChangeEvent);
  }
  private _modelSubject = new ReplaySubject<any>();
  modelChange$ = this._modelSubject.asObservable().pipe(distinctUntilChanged());
  set model(model: any) {
    this._modelSubject.next(model);
  }
  private _visibleDockableIndexSubject = new ReplaySubject<number>();
  visibleDockableIndexChange$ = this._visibleDockableIndexSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  set visibleDockableIndex(index: number) {
    this._visibleDockableIndexSubject.next(index);
  }
  private _dockContentSubject = new ReplaySubject<DockContent>();
  dockContentChange$ = this._dockContentSubject.asObservable().pipe(distinctUntilChanged());
  set dockContent(dockContent: DockContent) {
    this._dockContentSubject.next(dockContent);
  }
  private _closeDockSubject = new Subject<boolean>();
  onDockClose$ = this._closeDockSubject.asObservable();
  private _preFiltersComponentSubject = new ReplaySubject<any>();
  preFiltersComponentChange$ = this._preFiltersComponentSubject
    .asObservable()
    .pipe(distinctUntilChanged());
  set preFiltersComponent(preFiltersComponent: any) {
    this._preFiltersComponentSubject.next(preFiltersComponent);
  }

  constructor() {
    this._dockVisibilityChangeSubject.next(DockVisibleState.close);
  }

  closeDock(withRedirect: boolean) {
    this.model = null;
    this.visibleDockableIndex = -1;
    this.dockVisibility = DockVisibleState.close;
    if (!withRedirect) {
      return;
    }
    this._closeDockSubject.next(true);
  }

  clear() {
    this.title = null;
    this.model = null;
    this.visibleDockableIndex = -1;
    this.preFiltersComponent = null;
    this.dockContent = null;
  }
}
