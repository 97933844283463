import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopOptionsTogglerCacheService } from './top-options-toggler-cache.service';
import { TopOptionsTogglerComponent } from './top-options-toggler.component';

@NgModule({
  imports: [CommonModule],
  exports: [TopOptionsTogglerComponent],
  declarations: [TopOptionsTogglerComponent],
  providers: [TopOptionsTogglerCacheService]
})
export class TopOptionsTogglerModule {}
