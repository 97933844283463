<div class="container url-wrapper">
  <svg
  role="img"
  (click)="$event.stopPropagation(); copyToClipboard()">
      <use xlink:href="assets/img/sprites.svg#partner-clipboard" />
  </svg>
  <a
    [href]="checkLink() ? this.form.controls[this.column.field].value : '//' + this.form.controls[this.column.field].value"
    target="_blank">
    {{ this.form.controls[this.column.field].value }}
  </a>
</div>
