<div class="documentation tab-content">
  <div>
    Welcome to System1's Related Search on Content (RSoC) Guide for Partners! This document will
    guide you through the process of configuring a page on your domain to work with System1's RampJs
    product for RSoC.
  </div>
  <br />
  <div>
    There are three steps that you'll need to complete before your domain can show RSoC ads to end
    users.
  </div>
  <ol>
    <li *ngFor="let step of steps">{{ step }}</li>
  </ol>
  <div>Let's get started!</div>
  <h3 class="documentation-sub-header">1. Installing RampJs</h3>
  <app-installing-rampjs></app-installing-rampjs>
  <h3 id="keywords" class="documentation-sub-header">2. Specifying Keywords</h3>
  <app-specifying-keywords></app-specifying-keywords>
  <h3 id="url-parameters" class="documentation-sub-header">3. URL Parameters</h3>
  <p>You can think of your page’s URL like an affiliate URL. It will usually follow the below template:</p>
  <div class="code-block">
    <code>
      <pre>https://&lbrace;domain.com&rbrace;/&lbrace;article-name&rbrace;?segment=&lbrace;segment&rbrace;&headline=&lbrace;ad_creative&rbrace;&optkey=&lbrace;zoltar_hint&rbrace;&forceKeyA=&lbrace;first_term&rbrace;&forceKeyB=&lbrace;second_term&rbrace;&forceKeyC=&lbrace;third_term&rbrace;&forceKeyD=&lbrace;fourth_term&rbrace;&forceKeyE=&lbrace;fifth_term&rbrace;&forceKeyF=&lbrace;sixth_term&rbrace;&fbid=&lbrace;facebook_pixel_id&rbrace;&fbclick=&lbrace;facebook_pixel_event_name&rbrace;&subid=&lbrace;subid&rbrace; </pre>
    </code>
  </div>
  <p>When all of these parameters are filled in with real values, the link might look something like this:</p>
  <div class="code-block">
    <code>
      <pre>https://www.walletgenius.com/money/save-money/6-ways-for-seniors-to-receive-dental-care-for-little-to-no-money/?segment=agencyA.rsoc.1&subid=1562981594_bidswitch_inneractive_213702_iPad&headline=6+Ways+for+Seniors+to+Receive+Dental+Care+for+Little+to+no+Money&optkey=senior+dental+care+(at+$0+cost)&forceKeyA=Senior+Dental+Care+(at+$0+cost)&forceKeyB=Find+a+dentist+with+low+cost+options+for+seniors&forceKeyC=Rebates+for+senior+dental+care&forceKeyD=Low+cost+dental+care+for+seniors&forceKeyE=Find+senior+care+near+me+(at+$0+cost)&fbid=1234371237&fbclick=Purchase&fbclid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890 </pre>
    </code>
  </div>
  <p>The forceKey, headline, and optkey parameters are discussed in the section on <a (click)="navigateToAnchor('keywords')" class="documentation-link" >specifying keywords.</a> The other parameters in this URL are reporting and conversion tracking parameters. The following sections explain what purpose these parameters serve and how they should be used.</p>
  <ol>
    <li>
      <app-reporting-parameters [table]="tableReportingParameters"></app-reporting-parameters>
    </li>
    <hr />
    <li>
      <app-conversion-tracking-parameters></app-conversion-tracking-parameters>     
    </li>
  </ol>
  <hr />
  <app-accessing-api-reports [possibleReportTypeValues]="possibleReportTypeValues"></app-accessing-api-reports>
</div>
