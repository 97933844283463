import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BasePageComponent } from '@nimbus/shared-lib';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { CopyService } from '../services/copy.service';

// Reference external RAMP.js function
// eslint-disable-next-line no-var
declare var _rampJs: any;
@Component({
  selector: 'app-rampjs-builder',
  templateUrl: './rampjs-builder.component.html',
  styleUrls: ['./rampjs-builder.component.scss']
})
export class RampjsScriptBuilderComponent extends BasePageComponent implements AfterViewInit {
  @ViewChild('ads')
  ads: ElementRef;

  @ViewChild('code')
  code: ElementRef;

  fields: FormlyFieldConfig[] = [
    {
      key: 'referrer',
      type: 'input',
      templateOptions: {
        label: 'Page URL',
        required: true,
        type: 'url'
      }
    },
    {
      fieldGroupClassName: 'two-columns',
      fieldGroup: [
        {
          key: 'subdomain',
          type: 'input',
          templateOptions: {
            label: 'Subdomain (Optional)'
          }
        },
        {
          type: 'button',
          className: 'preview-button',
          templateOptions: {
            type: 'submit',
            value: 'Preview'
          }
        }
      ]
    }
  ];

  form = new FormGroup({});

  model: any = {
    terms: [
      'Term One', 'Term Two', 'Term Three', 'Term Four',
      'Term Five', 'Term Six', 'Term Seven', 'Term Eight',
      'Term Nine', 'Term Ten', 'Term Eleven', 'Term Twelve'
    ].join(',')
  };

  constructor(
    private _copyService: CopyService,
    private _snackbarService: SnackbarService,
  ) {
    super();
    this._setupRampJs();
  }

  private _setupRampJs() {
      const external = document.createElement('script');
      const inline = document.createElement('script');
      external.async = true;
      external.src = 'https://rampjs-cdn.system1.com/ramp.js';
      inline.text = `(function(w,r){w[r]=w[r]||function(){(w[r]['q']=w[r]['q']||[]).push(arguments)},w[r]['t']=1*new Date})(window,'_rampJs');`;
      document.head.prepend(external, inline);
  }

  ngAfterViewInit() {
    this.output();

    window.addEventListener('rampjsResponseError', (event: CustomEvent) => {
      const { response } = event.detail;
      let reason;
      switch (response.status) {
        case 502:
          reason = '502 Bad Gateway';
          break;
        case 400:
          reason = '400 Bad Request';
          break;
        default:
          reason = `${response.status} error`;
          break;
      }
      this._snackbarService.open(`Preview failed: ${reason}`, SnackBarType.error, 3000);
    });
  }

  copy() {
    this._copyService.copy(this.code.nativeElement.innerText);
    this._snackbarService.open('Copied to clipboard!', SnackBarType.done, 3000);
  }

  getConfig() {
    const config = { ...this.model };
    for (const prop in config) {
      if (!config[prop]) {
        config[prop] = undefined;
      }
    }
    return config;
  }

  output() {
    const config = this.getConfig();
    delete config.referrer;
    delete config.terms;
    this.code.nativeElement.innerText = (
`<script async src="https://rampjs-cdn.system1.com/ramp.js"></script>
<script>
  (function(w,r){w[r]=w[r]||function(){(w[r]['q']=w[r]['q']||[]).push(arguments)},w[r]['t']=1*new Date})(window,'_rampJs');
  _rampJs(${(Object.keys(config).length > 0) ? JSON.stringify(config) : ""});
</script>`
    );
    for (const child of this.ads.nativeElement.children) {
      child.innerHTML = "";
    }
  }

  preview() {
    const config = this.getConfig();
    config.testMode = true;
    _rampJs(config);
  }
}
