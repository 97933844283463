import { Injectable } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { Credentials } from './credentials';
import { BaseWebService } from '../../core/base-web.service';
import { ConfigService } from '../../core/config.service';
import { TokenResponse } from './token-response';
import { UserInfoResponse } from './user-info-response';
import { catchError } from 'rxjs/operators';
import { UserVerification } from './user-verification.model';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { HttpClientQ } from 'core-global-frontend-http';

@Injectable()
export class AuthWebService extends BaseWebService {
  constructor(
    private _configService: ConfigService,
    private _http: HttpClientQ,
    private _snackbarService: SnackbarService,
  ) {
    super();
  }

  login(credentials: Credentials | string): Observable<TokenResponse> {
    return this._ovs.isDefined(credentials) &&
      this._ovs.isDefined((credentials as Credentials).userName)
      ? this._loginBasic(credentials as Credentials)
      : this._loginOkta(credentials as string);
  }

  logout(token: string): Observable<any> {
    const route = this._configService.getRoute('Logout');
    return this._ovs.isNullOrEmpty(route)
      ? of(true)
      : this._http.get(
          this._configService.getApi().host + route.path,
          null,
          token,
        );
  }

  getUserInfo(token: string): Observable<UserInfoResponse> {
    try {
      return this._http
        .get(`${this._configService.getApi().host}/v1/users/me`, null, token)
        .pipe(
          catchError(error => {
            if (!error.reported) {
              this._snackbarService.open(
                `An error occured while fetching the user's details.`,
                SnackBarType.error,
              );
              return EMPTY;
            }
            throw error;
          }),
        );
    } catch (e) {
      return EMPTY;
    }
  }

  verifyUser(
    userVerification: UserVerification,
    token: string,
  ): Observable<any> {
    return this._http
      .post(
        `${this._configService.getApi().host}/auth/verify`,
        userVerification,
        null,
        token,
      )
      .pipe(
        catchError(error => {
          if (!error.reported) {
            this._snackbarService.open(
              `An error occured while verifying the user's details.`,
              SnackBarType.error,
            );
            return EMPTY;
          }
          throw error;
        }),
      );
  }

  private _loginBasic(credentials: Credentials): Observable<TokenResponse> {
    return this._http.post(
      `${this._configService.getApi().host}/auth/token`,
      credentials,
    );
  }

  private _loginOkta(token: string): Observable<TokenResponse> {
    return this._http.get(
      `${this._configService.getApi().host}/auth/token/okta`,
      { token: token },
    );
  }
}
