import { Injectable } from '@angular/core';
import { TrafficSource } from '../domain-configuration/models/traffic-source.model';
import { AccessControlService } from '@nimbus/shared-lib';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class DomainConfigurationSchemaService {
  private trafficeSourceRecord = '';

  constructor(
    private _ovs: ObjectValidatorService,
    private _accesscontrolService: AccessControlService,
  ) {}

  private facebook(): any {
    return [
      {
        key: 'fbid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Facebook Pixel ID (fbid):',
        },
      },
      {
        key: 'fbland',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Facebook Land Event Conversion Name (fbland):',
        },
      },
      {
        key: 'fbserp',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Facebook Search Event Conversion Name (fbserp):',
        },
      },
      {
        key: 'fbclick',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Facebook Click Event Conversion Name (fbclick):',
        },
      },
    ];
  }

  private taboola(): any {
    return [
      {
        key: 'tbid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Taboola advertiser account ID (tbid):',
        },
      },
      {
        key: 'tbclickid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Taboola Click ID (tbclickid):',
        },
      },
      {
        key: 'tbland',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Taboola Land Event Conversion Name (tbland):',
        },
      },
      {
        key: 'tbserp',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Taboola Search Event Conversion Name (tbserp):',
        },
      },
      {
        key: 'tbclick',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Taboola Click Event Conversion Name (tbclick):',
        },
      },
    ];
  }

  private tikTok(): any {
    return [
      {
        key: 'ttid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'TikTok pixel ID (ttid):',
        },
      },
      {
        key: 'ttland',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'TikTok Land Event Conversion Name (ttland):',
        },
      },
      {
        key: 'ttserp',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'TikTok Search Event Conversion Name (ttserp):',
        },
      },
      {
        key: 'ttclick',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'TikTok Click Event Conversion Name (ttclick):',
        },
      },
      {
        key: 'ttclid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'TikTok Click ID (ttclid):',
        },
      },
    ];
  }

  private outbrain(): any {
    return [
      {
        key: 'obid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Outbrain pixel ID (obid):',
        },
      },
      {
        key: 'obclickid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Outbrain Click ID (obclickid):',
        },
      },
      {
        key: 'obland',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Outbrain Land Event Conversion Name (obland):',
        },
      },
      {
        key: 'obserp',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Outbrain Search Event Conversion Name (obserp):',
        },
      },
      {
        key: 'obclick',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Outbrain Click Event Conversion Name (obclick):',
        },
      },
    ];
  }

  private google(): any {
    return [
      {
        key: 'gamid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Google Ads Measurement ID (gamid):',
        },
      },
      {
        key: 'glcid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Google Land Event Conversion ID (glcid):',
        },
      },
      {
        key: 'gcid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Google Search Event Conversion ID (gcid):',
        },
      },
      {
        key: 'gclcid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Google monetizes with advertiser conversion ID (gclcid):',
        },
      },
      {
        key: 'ga4mid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label:
            'Additional Property ID to measure page views and events (ga4mid):',
        },
      },
    ];
  }

  private snapchat(): any {
    return [
      {
        key: 'scid',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'ID of the Snapchat pixel (scid):',
        },
      },
      {
        key: 'scland',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Snapchat Land Event Conversion ID (scland):',
        },
      },
      {
        key: 'scserp',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Snapchat Search Event Conversion ID (scserp):',
        },
      },
      {
        key: 'scclick',
        type: 'input-with-tooltip',
        defaultValue: '',
        templateOptions: {
          label: 'Snapchat monetizes with advertiser conversion ID (scclick):',
        },
      },
    ];
  }

  private createConversionTrackingByTrafficSource(
    configModel,
    trafficSource: string[],
  ) {
    const template = {
      key: '',
      wrappers: ['panel-wrapper'],
      hideExpression: () =>
        configModel.configure.trafficSource !== TrafficSource.Facebook,
      templateOptions: {
        label: 'Set buyside network tracking pixels, if supported.',
        headerStyle: 'sub-title',
        internalCss: 'internal-wrapper',
      },
      fieldGroupClassName: 'field-group-css',
      fieldGroup: [],
    };

    const fieldGroup: object[] = [];
    trafficSource.forEach(source => {
      const field = JSON.parse(JSON.stringify(template));
      field.key = source;
      field.hideExpression = this.hideExpressionBySource(source, configModel);
      field.fieldGroup = this.fieldBySource(source, configModel);
      fieldGroup.push(field);
    });

    fieldGroup.push({
      key: 'internalClickTracking',
      wrappers: ['panel-wrapper'],
      templateOptions: {
        label:
          'Include your internal click tracking URLs (e.g. postback URLs) for session, search, and click events.',
        headerStyle: 'sub-title',
        internalCss: 'internal-wrapper',
      },
      fieldGroupClassName: 'singleRow',
      fieldGroup: [
        {
          key: 'impression_track_url',
          type: 'input-with-tooltip',
          defaultValue: '',
          templateOptions: {
            showInfoButton: 'true',
            label: 'Impression Track URL',
            maxLength: 200,
            toolTip: 'Tell us what postback URL to fire on the landing page',
          },
        },
        {
          key: 'search_track_url',
          type: 'input-with-tooltip',
          defaultValue: '',
          templateOptions: {
            showInfoButton: 'true',
            label: 'Search Track URL',
            maxLength: 200,
            toolTip: 'Tell us what postback URL to fire on the SERP',
          },
        },
        {
          key: 'click_track_url',
          type: 'input-with-tooltip',
          defaultValue: '',
          templateOptions: {
            showInfoButton: 'true',
            label: 'Click Track URL',
            maxLength: 200,
            toolTip:
              'Tell us what postback URL to fire on the Monetization Event',
          },
        },
      ],
    });
    return fieldGroup;
  }

  private fieldBySource(source: string, configModel) {
    switch (source) {
      case TrafficSource.Facebook:
        return this.facebook();
      case TrafficSource.Google:
        return this.google();
      case TrafficSource.Outbrain:
        return this.outbrain();
      case TrafficSource.Snapchat:
        return this.snapchat();
      case TrafficSource.Taboola:
        return this.taboola();
      case TrafficSource.TikTok:
        return this.tikTok();
      default:
        return this.facebook();
    }
  }

  private hideExpressionBySource(source: string, configModel) {
    switch (source) {
      case TrafficSource.Facebook:
        return () =>
          configModel.configure.trafficSource !== TrafficSource.Facebook;
      case TrafficSource.Google:
        return () =>
          configModel.configure.trafficSource !== TrafficSource.Google;
      case TrafficSource.Outbrain:
        return () =>
          configModel.configure.trafficSource !== TrafficSource.Outbrain;
      case TrafficSource.Snapchat:
        return () =>
          configModel.configure.trafficSource !== TrafficSource.Snapchat;
      case TrafficSource.Taboola:
        return () =>
          configModel.configure.trafficSource !== TrafficSource.Taboola;
      case TrafficSource.TikTok:
        return () =>
          configModel.configure.trafficSource !== TrafficSource.TikTok;
      default:
        return () =>
          configModel.configure.trafficSource !== TrafficSource.Facebook;
    }
  }

  private createOneSelectOption(
    value: number | string,
    selected: boolean,
    display: string,
  ) {
    return {
      value,
      selected,
      display,
    };
  }

  private CreateTrafficSourceSelectOptions(list: string[]) {
    const listOfOptions: object[] = [];
    list.forEach(item => {
      listOfOptions.push(this.createOneSelectOption(item, false, item));
    });
    return listOfOptions;
  }

  private createOneTextInput(key: string, label: string) {
    return {
      key,
      type: 'input',
      defaultValue: '',
      templateOptions: {
        label,
      },
    };
  }

  private createQueryStringKeywords() {
    const template = {
      key: 'keywords_query_string',
      wrappers: ['panel-wrapper'],
      templateOptions: {
        label: 'Creates keywords',
        headerStyle: 'sub-title',
        internalCss: 'internal-wrapper-standard',
      },
      fieldGroupClassName: 'keywordstyle',
      fieldGroup: [],
    };
    for (let i = 0; i < 6; i++) {
      const text = `forcekey${String.fromCharCode(i + 65)}`;
      template.fieldGroup.push(this.createOneTextInput(text, text));
    }
    return template;
  }

  updateComplianceDefaults(configModel, options, pageReload: boolean = false) {
    if (
      !configModel ||
      !configModel.configure ||
      !configModel.configure.trafficSource ||
      configModel.configure.trafficSource === this.trafficeSourceRecord
    ) {
      return;
    }
    if (pageReload) {
      this.trafficeSourceRecord = configModel.configure.trafficSource;
      return;
    }
    this.trafficeSourceRecord = configModel.configure.trafficSource;
    switch (this.trafficeSourceRecord) {
      case TrafficSource.Facebook:
        configModel.compliance.ref = 'facebook-{{placement}}';
        configModel.compliance.headline = '';
        break;
      case TrafficSource.Outbrain:
        configModel.compliance.ref = 'outbrain-{{publisher_name}}';
        configModel.compliance.headline = '{{ad_title}}';
        break;
      case TrafficSource.Taboola:
        configModel.compliance.ref = 'taboola-{site}';
        configModel.compliance.headline = '{title}';
        break;
      case TrafficSource.TikTok:
        configModel.compliance.ref = 'tiktok-__placement__';
        configModel.compliance.headline = '';
        break;
      case TrafficSource.Snapchat:
        configModel.compliance.ref = 'Snapchat-{{site_source_name}}';
        configModel.compliance.headline = '';
        break;
      case TrafficSource.Google:
        configModel.compliance.ref = '';
        configModel.compliance.headline = '';
        break;
      default:
        configModel.compliance.ref = '';
        configModel.compliance.headline = '';
        break;
    }
    options.updateInitialValue!();
    options.resetModel!();
  }

  async getSchema(
    domain: string | undefined,
    configModel,
    trafficSource: string[],
  ): Promise<any[]> {
    const fields = [
      {
        type: 'tabs',
        fieldGroup: [
          {
            templateOptions: {
              label: 'Configuration',
              icon: 'gear-icon-black',
            },
            fieldGroup: [
              {
                key: 'configure',
                wrappers: ['panel-wrapper'],
                fieldGroupClassName: 'field-group-css',
                fieldGroup: [
                  {
                    key: 'trafficSource',
                    type: 'searchable-multi-select',
                    defaultValue: '',
                    templateOptions: {
                      showInfoButton: 'true',
                      required: true,
                      enableMultiSelect: false,
                      label: 'Traffic Source',
                      selectOptions:
                        this.CreateTrafficSourceSelectOptions(trafficSource),
                      toolTip: 'Tell us where you will be purchasing traffic.',
                    },
                  },
                  {
                    key: 'facebookVerificationCode',
                    type: 'input-with-tooltip',
                    defaultValue: '',
                    hideExpression: model =>
                      configModel.configure.trafficSource !==
                      TrafficSource.Facebook,
                    templateOptions: {
                      label: 'Facebook Verification Code',
                      showInfoButton: 'true',
                      errorMessageMaxCharactersValue: 35,
                      toolTip:
                        'Enter the 30 character code located in your Facebook Business Manager. This can be found by navigating to Brand Safety > Domains > Add New Domain, and entering your Target URL there.',
                    },
                    validators: {
                      validation: [
                        { name: 'maxLength', options: { maxCharaters: 35 } },
                      ],
                    },
                  },
                  {
                    key: 'eu_designated_domain',
                    type: 'checkbox-type',
                    className: 'checkbox-config',
                    defaultValue: false,
                    templateOptions: {
                      label: 'Privacy Region',
                    },
                  },
                ],
              },
            ],
          },
          {
            templateOptions: { label: 'Reporting', icon: 'reports-icon' },
            fieldGroup: [
              {
                key: 'reporting',
                wrappers: ['panel-wrapper'],
                fieldGroupClassName: 'field-group-css',
                fieldGroup: [
                  {
                    key: 'sub_id',
                    type: 'input-with-tooltip',
                    defaultValue: '',
                    templateOptions: {
                      showInfoButton: 'true',
                      label: 'subid',
                      toolTip:
                        'This field will be returned to you in reporting and via API. Pass any fields you plan to optimize against here like placement or ad identification information. We accept any character in the following set: [A-Za-z0-9.-~]',
                    },
                  },
                ],
              },
            ],
          },
          {
            templateOptions: { label: 'Compliance', icon: 'compliance-icon' },
            fieldGroup: [
              {
                key: 'compliance',
                wrappers: ['panel-wrapper'],
                fieldGroupClassName: 'singleRow',
                fieldGroup: [
                  {
                    key: 'ref',
                    type: 'input-with-tooltip',
                    defaultValue: '',
                    templateOptions: {
                      required: true,
                      label: 'Referral Source (Ref)',
                      toolTip:
                        'Tell us the buyside placement where your traffic originated.',
                      showInfoButton: 'true',
                    },
                  },
                  {
                    key: 'rskey',
                    type: 'input-with-tooltip',
                    defaultValue: '',
                    templateOptions: {
                      showInfoButton: 'true',
                      required: true,
                      label: 'rskey',
                      toolTip:
                        'Enter the primary search term or phrase that you plan to target. Ex: "Dental+Implants+Near+Me."',
                    },
                  },
                  {
                    key: 'headline',
                    type: 'input-with-tooltip',
                    defaultValue: '',
                    templateOptions: {
                      showInfoButton: 'true',
                      required: true,
                      label: 'Headline',
                      toolTip: 'Enter the title of your buyside ad.',
                    },
                  },
                ],
              },
            ],
          },
          {
            templateOptions: {
              label: 'Conversion Tracking',
              icon: 'tracking-icon',
            },
            fieldGroup: [
              {
                key: 'conversionTracking',
                wrappers: ['panel-wrapper'],
                fieldGroup: this.createConversionTrackingByTrafficSource(
                  configModel,
                  trafficSource,
                ),
              },
            ],
          },
        ],
      },
    ];
    if (
      this._accesscontrolService.canAccessBySelector(
        'keyword-method-authorization',
      ) &&
      this._ovs.isDefined(
        configModel?.keywordSelection?.keywordSelectionTypes,
      ) &&
      configModel?.keywordSelection?.keywordSelectionTypes
    ) {
      fields[0].fieldGroup.push({
        templateOptions: { label: 'Keyword Access', icon: 'keywords-icon' },
        fieldGroup: [
          {
            key: 'keywordSelection',
            wrappers: ['panel-wrapper'],
            fieldGroup: [this.createQueryStringKeywords()],
          },
        ],
      });
    }
    return fields;
  }
}
