import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SubverticalReviewsManagerPrefilterSchemaService } from '../schemas/subvertical-reviews-manager-prefilter-schema.service';
import { SubverticalReviewsManagerPrefilterModel } from '../models/subvertical-reviews-manager-prefilter.model';
import { SubverticalReviewsCacheService } from '../services/subvertical-reviews-cache.service';
import { debounceTime } from 'rxjs/operators';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Component({
  selector: 'app-subvertical-reviews-prefilters',
  templateUrl: './subvertical-reviews-prefilters.component.html',
  styleUrls: ['./subvertical-reviews-prefilters.component.scss']
})
export class SubverticalReviewsPrefiltersComponent implements OnInit, OnDestroy {
  private _subscriptionManager = new SubscriptionManager();
  form = new FormGroup({});
  fields: FormlyFieldConfig[] = this._subverticalReviewsManagerPrefilterSchema.getSchema();
  model: SubverticalReviewsManagerPrefilterModel;
  constructor(
    private _subverticalReviewsManagerPrefilterSchema: SubverticalReviewsManagerPrefilterSchemaService,
    private _subverticalReviewsCacheService: SubverticalReviewsCacheService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.model = this._subverticalReviewsCacheService.getReviewsManagerPrefilterModel(
      this._subverticalReviewsCacheService.pageSize
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  ngOnInit(): void {
    this._subscriptionManager.registerMultiple([
      this.form.valueChanges.pipe(debounceTime(500)).subscribe(model => {
        if (
          (model as SubverticalReviewsManagerPrefilterModel).statuses !=
          this._subverticalReviewsCacheService.selectedReviewsModelFilters.statuses
        ) {
          this._subverticalReviewsCacheService.selectedReviewsModelFilters =
            model as SubverticalReviewsManagerPrefilterModel;
        } else {
          this._subverticalReviewsCacheService.selectedReviewsModelFilters =
            model as SubverticalReviewsManagerPrefilterModel;
          this._subverticalReviewsCacheService.filter();
        }
      }),
    ]);
  }

  onSubmit() {
    this._subverticalReviewsCacheService.load(true).subscribe(() => {
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    });
  }
}
