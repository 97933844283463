<div class="cover-container" #container>
  <mat-form-field class="mat-form-field--no-padding" [floatLabel]="'always'">
    <mat-label class="label">{{ label$ | async }}</mat-label>
    <input
      type="text"
      matInput
      [formControl]="filterField"
      [placeholder]="selectedItems"
      [matAutocomplete]="autocomplete"
      [readonly]="readonly"
      data-test="searchable-select-control-input"
      (click)="$event.stopPropagation(); clickEmitter($event)"
      (focus)="onFocus()" />
    <mat-icon matSuffix *ngIf="!showAdd">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="showAdd" (click)="addListItem($event)" matSuffix>add_circle_outline</mat-icon>
    <mat-autocomplete #autocomplete="matAutocomplete" (opened)="opened()" (closed)="closed()">
      <mat-option *ngIf="enableMultiSelect && selectAllHandler" class="select-all">
        <div (click)="$event.stopPropagation(); toggleSelectAll()" class="full-div">
          <mat-checkbox
            class="inline"
            [checked]="selectAllHandler && selectAllHandler.isSelected"
            [indeterminate]="selectAllHandler.isIndeterminate"
            (change)="toggleSelectAll()"
            (click)="$event.stopPropagation(); clickEmitter($event)">
            {{ selectAllHandler.selectAllItem.display }}
          </mat-checkbox>
        </div>
      </mat-option>
      <cdk-virtual-scroll-viewport
        [appendOnly]="appendOnly"
        class="autocomplete-viewport"
        [ngStyle]="{ height: height }"
        itemSize="50"
        (scrolledIndexChange)="scrolledIndexChanged()"
        minBufferPx="500"
        maxBufferPx="1000"
        data-test="virtual-scroll-viewport">
        <mat-option
          *cdkVirtualFor="let option of filteredList$ | async"
          [disabled]="option.disabled"
          [value]="option.value"
          class="autocomplete-item">
          <div
            (click)="$event.stopPropagation(); toggleSelection(option); clickEmitter($event)"
            class="full-div">
            <mat-checkbox
              class="inline"
              [checked]="option.selected"
              (change)="toggleSelection(option)"
              *ngIf="enableMultiSelect"
              (click)="$event.stopPropagation(); clickEmitter($event)">
              <div [title]="option.tooltip" class="searchable-select-header-items">
                {{ option.display }}
              </div>
            </mat-checkbox>
            <div
              [title]="option.tooltip"
              [class]="
                option.disabled || enableMultiSelect || !adjustMargins
                  ? 'searchable-select-header-items'
                  : 'searchable-select-header-items searchable-select-header-items_adjusted-margin'
              "
              *ngIf="!enableMultiSelect"
              [innerHTML]="boldMatchText(option.display, filterField.value)"></div>
          </div>
        </mat-option>
      </cdk-virtual-scroll-viewport>
    </mat-autocomplete>
  </mat-form-field>
  <div (click)="populateListAndOpenPanel()" *ngIf="!isListPopulated">
    <mat-label
      class="cover-populate-list-on-click"
      data-test="searchable-select-control-populate-list-on-click"
      >{{ initialDisplayValue }}</mat-label
    >
  </div>
</div>
