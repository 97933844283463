import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Routes } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringHelper } from '../../core/string-helper';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { cloneDeep } from 'lodash';

@Injectable()
export class PreFiltersService {
  constructor(private _stringHelper: StringHelper, private _ovs: ObjectValidatorService) {}

  hasPrefiltersParams(params, queryStringParameters): boolean {
    return (
      !this._ovs.isNullOrEmpty(queryStringParameters) &&
      queryStringParameters.reduce(
        (previous, current) =>
          previous && this._ovs.isDefined(Object.keys(params).find(x => x === current)),
        this._ovs.isDefined(params)
      )
    );
  }

  hasPopulatedIdProperties(params, idsParameters): boolean {
    return (
      !this._ovs.isNullOrEmpty(idsParameters) &&
      !idsParameters.reduce(
        (previous, current) => previous || this._ovs.isNullOrEmpty(params[current]),
        this._ovs.isNullOrEmpty(params)
      )
    );
  }

  addOptionalAddEntityPage(
    formFields$: Observable<FormlyFieldConfig[]>,
    config: Routes,
    baseRoute: string,
    area: string,
    page: string
  ): Observable<FormlyFieldConfig[]> {
    return formFields$.pipe(
      map(fields =>
        this._isHavingAddPage(config, area, page)
          ? [
              ...fields,
              {
                type: 'add-new',
                templateOptions: {
                  label:
                    'Add ' +
                    this._stringHelper.substitute(
                      new TitleCasePipe().transform(this._stringHelper.singularize(page)),
                      '-',
                      ' '
                    ),
                  route: `/${baseRoute}/add`
                }
              }
            ]
          : fields
      )
    );
  }

  isSameIdProperties(newParameters: any, params, idsParameter: string) {
    return newParameters[idsParameter] === params[idsParameter];
  }

  addIdProperties(results, params, idsParameters: string[]) {
    return idsParameters.reduce((previous, current) => {
      const queryStringParameters = cloneDeep(previous) as any;
      queryStringParameters[current] = encodeURI(results[current]);
      return queryStringParameters;
    }, params);
  }

  mapToTargetedProperties(params: any, targetModelKeys: string[]): any {
    return this._ovs.isNullOrEmpty(targetModelKeys)
      ? {}
      : targetModelKeys.reduce((previous, key) => {
          previous[key] = this._ovs.isNullOrEmpty(params[key]) ? '' : params[key];
          return previous;
        }, {});
  }

  areEqual(source1: string[], source2: string[]) {
    if (!this._ovs.isDefined(source1) === !this._ovs.isDefined(source2)) {
      return true;
    }
    if (this._ovs.isDefined(source1) !== this._ovs.isDefined(source2)) {
      return false;
    }
    return (
      source1.length === source2.length &&
      source1.reduce(
        (previous, source) => previous && source2.findIndex(s => s === source) !== -1,
        true
      )
    );
  }

  private _isHavingAddPage(config: Routes, area: string, page: string): boolean {
    return !this._ovs.isNullOrEmpty(
      config.find(
        c =>
          c.path === area &&
          !this._ovs.isNullOrEmpty(c.children) &&
          c.children.find(child => child.path === `${page}/add`)
      )
    );
  }
}
