import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { ColumnInfo } from './column-info';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { GridPresentationComponent } from '../grid-presentation/grid-presentation.component';
import { Sort } from '@angular/material/sort';
import { RowInfo } from './row-info';
import { ActionsBarComponent } from '../actions-bar/actions-bar.component';
import { NumberRange } from '../grid-models/number-range';
import { ObjectValidatorService } from 'core/libs/global/frontend/object-validator/src/lib/object-validator.service';
import {
  FileDownloadService,
  FileType,
} from '../grid-services/file-download.service';
import { FilterInfo } from '../grid-models/filter-info';
import { FileFromData } from '../grid-services/file-from-data.service';
import { GridSortingService } from '../grid-sorting.service';
import { GridDatasourceBuilder } from '../grid-datasource-builder';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subscription,
  combineLatest,
  filter,
  map,
  of,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import { PushPipe } from '@ngrx/component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { GridModel } from '../grid-models/grid-model';

/**
 * @title Front-end grid / Mat-table wrapper.
 *
 * Allows to quickly setup a grid with common styling and features.
 *
 * @param editable - boolean - if true, the grid is editable (can't be updated once set, changing this setting requires a re-instantiation of the table).
 * @param inputs - form and dataSource (use GridInputsBuilder to build).
 * @param columns - ColumnInfo[] - the columns to show in the grid
 * @param (optional) disableComponentsCache - component instantiation will be skipped if the component is already in the cache when not disabled.
 * @param (optional) pageSize - number - the number of rows per page
 * @param (optional) disableSubmitForUnchanged - boolean - if true, the submit button will be disabled if the form is unchanged.
 * @param (optional) submitEvent - EventEmitter<any> - event emitted when the form is submitted.
 */
@Component({
  selector: 'frontend-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    GridPresentationComponent,
    ActionsBarComponent,
    PushPipe,
  ],
  providers: [ObjectValidatorService, FileDownloadService],
})
export class GridComponent implements OnDestroy {
  private readonly _subscription: Subscription[] = [];
  private readonly _modelSubject = new ReplaySubject<any[]>();
  private readonly sortEventSubject = new BehaviorSubject<Sort>({
    active: '',
    direction: '',
  });
  private _filterSubject = new BehaviorSubject<FilterInfo>({} as FilterInfo);
  private _columnsSubject = new ReplaySubject<ColumnInfo[]>();
  private readonly _columns$ = this._columnsSubject.asObservable();
  readonly columns$ = this._columns$ as any;
  private readonly _model$ = this._modelSubject.asObservable();
  dataSource$ = combineLatest([
    this._model$.pipe(filter(model => !!model)),
    this.sortEventSubject.asObservable(),
    this._columns$.pipe(filter(columns => !!columns)),
    this._filterSubject.asObservable(),
  ]).pipe(
    map(([domains, sort, columns, filter]) =>
      this.gridSortingService.sort(
        this._filterDomains(domains, filter),
        sort,
        columns,
      ),
    ),
    map(domains => this.gridDatasourceBuilder.buildDatasource(domains)),
  ) as Observable<MatTableDataSource<FormGroup<any>, MatPaginator>>;
  dataSourceAsAny$ = this.dataSource$ as any;
  serversidePagination: boolean = false;
  dataLength!: number;
  @Input() public set model(value: GridModel) {
    if (value) {
      if (value.length) {
        this.serversidePagination = true;
        this.dataLength = value.length;
      }
      this.dataId = value.id;
      this.defaultSort = value.defaultSort;
      this._modelSubject.next(value.data);
    }
  }
  @Input() showOptionsBar: boolean = false;
  @Input() useExternalDownload: boolean = false;
  @Input() editable: boolean = false;
  @Output() submitEvent = new EventEmitter<any>();
  @Input() submitButtonLabel = 'Submit';
  @Input() refreshButtonText: string = 'Refresh';
  @Input() showRefreshButton: boolean = false;
  @Input() disableComponentsCache = false;
  @Input() rowInfo!: RowInfo;
  @Input() disableSubmitForUnchanged = true;
  @Input() set columns(value: ColumnInfo[]) {
    this._columnsSubject.next(value);
  }
  @Input() downloadFileType: FileType = FileType.NONE;
  @Input() pageSize!: number;
  @Output() refreshEvent = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Output() sortEvent = new EventEmitter<Sort>();
  @Output() downloadAllEvent = new EventEmitter<any>();
  paginator!: MatPaginator;
  @ViewChild(GridPresentationComponent) gridPresentationComponent!: GridPresentationComponent;
  dataId!: string;
  defaultSort?: Sort;

  constructor(
    public ovs: ObjectValidatorService,
    public gridDatasourceBuilder: GridDatasourceBuilder,
    public gridSortingService: GridSortingService,
    private _fileDownloadService: FileDownloadService,
  ) { }

  ngOnDestroy(): void {
    this._subscription.forEach(sub => sub.unsubscribe());
  }

  filter(filter: FilterInfo) {
    if (!this.serversidePagination) {
      this._filterSubject.next(filter);
    }
  }

  refreshGrid() {
    this.refreshEvent.emit({});
  }

  sort(sort: Sort) {
    if (!this.serversidePagination) {
      setTimeout(() => this.sortEventSubject.next(sort), 100);
    }
    this.sortEvent.emit(sort);
  }

  download(allPages: boolean) {
    if (this.serversidePagination && allPages) {
      this.downloadAllEvent.emit();
      return;
    }
    this._subscription.push(
      of(allPages)
        .pipe(
          withLatestFrom(this.dataSource$.pipe(take(1))),
          map(([allPages, dataSource]) => [
            allPages
              ? new NumberRange(0, (dataSource as any).data.length - 1)
              : this._currentPageRowNumberRange(),
            dataSource,
          ]),
          switchMap(([range, dataSource]) =>
            combineLatest([
              of(dataSource),
              this._model$,
              this._columns$,
              of(range),
            ]),
          ),
          take(1),
          tap(([dataSource, model, columns, range]) =>
            this._fileDownloadService.provideFile(
              new FileFromData().getCsv(
                {
                  model: model,
                  columns: columns,
                  pageSize: (dataSource as any)?.paginator?.pageSize || 0,
                },
                range as any,
              ),
              'data.csv',
              this.downloadFileType,
            ),
          ),
        )
        .subscribe(),
    );
  }

  private _filterDomains(domains: any[], filter: FilterInfo) {
    return this.ovs.isObjectNullOrEmpty(filter)
      ? domains
      : domains.filter(domain =>
        !this.ovs.isNullOrEmpty(domain[filter.field])
          ? domain[filter.field]
            .toString()
            .toLowerCase()
            .includes(filter.filterString.toLowerCase())
          : false,
      );
  }

  private _currentPageRowNumberRange(): NumberRange {
    const pageSize = this.paginator?.pageSize || 0;
    const start = (this.paginator?.pageIndex || 0) * pageSize;
    return new NumberRange(start, start + pageSize - 1);
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageChange.emit(pageEvent)
  }

  resetServerPageIndex() {
    if (this.gridPresentationComponent) {
      this.gridPresentationComponent.resetServerPageIndexIndex();
    }
  }
}
