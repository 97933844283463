import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AccessControlGuardService } from '@nimbus/shared-lib';
import { OktaAuthGuard } from '@okta/okta-angular';
import { siteMap } from '../../../../site-map';
import { UtilityService } from 'core-global-frontend-object-validator';
import { SiteMapNode } from 'core-global-frontend-navigation';
import { EmbeddedComponent } from './embedded.component';

const utilityService = new UtilityService();
const segments = utilityService.flattenListNodes<SiteMapNode>(siteMap as any);
const routes: Routes = segments
    .filter(segment => 'embedded' in segment)
    .map(segment => ({
      path: segment.path,
      component: EmbeddedComponent,
      canActivate: [OktaAuthGuard, AccessControlGuardService],
      data: {
        resourceName: segment.resource,
        containerId: segment.embedded.containerId,
      }
    }));

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmbeddedRoutingModule {}
