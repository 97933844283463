import { Injectable } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';

@Injectable()
export class CopyService {
  private readonly _copyElement: HTMLTextAreaElement;
  constructor(
    private _snackbarService: SnackbarService,
    private _ovs: ObjectValidatorService,
  ) {
    this._copyElement = document.createElement('textarea');
    this._copyElement.id = 'copy tag';
    this._copyElement.style.width = '0px';
    this._copyElement.style.height = '0px';
    this._copyElement.style.opacity = '0';
    document.body.appendChild(this._copyElement);
  }
  copy(value: string | undefined) {
    this._copyElement.value = value === undefined ? '' : value;
    this._copyElement.focus();
    this._copyElement.select();
    document.execCommand('copy');
    this._snackbarService.open('Copied to clipboard!', SnackBarType.done, 3000);
  }
}
