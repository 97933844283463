export class Group {
  public groupId: string;
  public groupName: string;
  public groupSortOrder: number;

  constructor(groupId: string, groupName: string, groupSortOrder: number) {
    this.groupId = groupId;
    this.groupName = groupName;
    this.groupSortOrder = groupSortOrder;
  }
}
