/**
 * Enum for native grid column types
 * Should not contain any custom components defined outside the grid library
 */
export enum ColumnType {
  StaticText = 'frontend-static-text-cell',
  InputText = 'frontend-input-text-cell',
  Checkbox = 'frontend-checkbox-cell',
  Button = 'frontend-button-cell',
  Link = 'frontend-link-cell',
}
