import { Pipe, PipeTransform } from '@angular/core';
import { DockVisibleState } from './dock/dock-visible-state';

@Pipe({
  name: 'childContainerHeightPercent'
})
export class ChildContainerHeightPercentPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    return `height: ${args[0] === DockVisibleState.maximize ? args[1] : value}%;`;
  }
}
