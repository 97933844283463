import { Injectable } from '@angular/core';

@Injectable()
export class ObjectSanitizer {
  sanitize(obj: any): any {
    return Object.keys(obj)
      .filter(key => this[key] !== null)
      .reduce((sanitizedObject, key) => {
        sanitizedObject[key] = obj[key];
        return sanitizedObject;
      }, {});
  }

  removeProperties(propertyToRemove: string[], sourceObject: any): any {
    const clone = {};
    Object.keys(sourceObject)
      .filter(
        propertyName => propertyToRemove.findIndex(property => property === propertyName) === -1
      )
      .forEach(propertyName => (clone[propertyName] = sourceObject[propertyName]));
    return clone;
  }

  removePropertiesIfValues(values: string[], sourceObject: any): any {
    const clone = {};
    Object.keys(sourceObject)
      .filter(
        propertyName => values.findIndex(value => value === sourceObject[propertyName]) === -1
      )
      .forEach(propertyName => (clone[propertyName] = sourceObject[propertyName]));
    return clone;
  }
}
