import { Injectable } from '@angular/core';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DomainTermsWebService {
  constructor(private _webServiceBuilderService: NimbusWebServiceBuilderService) { }

  getDisallowedTerms(): Observable<any> {
    return this._webServiceBuilderService.builder
      .withUrl('parkeddomains/disallowedterms')
      .build()
      .get({});
  }
}
