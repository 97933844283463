import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SiteMapNode } from 'core-global-frontend-navigation';
import { TokenCacheService } from '@nimbus/shared-lib';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  readonly siteMapNodes: SiteMapNode[] =
    this._activatedRoute.data['value'].siteMapNodes;

  constructor(
    private _activatedRoute: ActivatedRoute,
    public tokenCacheService: TokenCacheService,
  ) {}
}
