import { Injectable } from '@angular/core';
import { ColumnInfo } from 'core-global-frontend-grid';

@Injectable({
  providedIn: 'root',
})
export class PixelManagerAccessTokenHistoryColumnBuilderService {
  readonly columns: ColumnInfo[] = [
    {
      field: 'status',
      type: 'frontend-status-cell',
      toolTip: 'Current status of the pixel access token request.',
      label: 'Status',
    },
    {
      field: 'buysideNetwork',
      label: 'Buyside Network',
    },
    {
      field: 'pixelId',
      label: 'Pixel ID',
    },
    {
      type: 'edit-access-token',
      field: 'accessToken',
      toolTip: 'Click to edit the access token',
      label: 'Access Token',
    },
    {
      type: 'access-token-date',
      field: 'updatedDate',
      label: 'Last Updated Date',
      sortable: true,
    },
  ];
}
