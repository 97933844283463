import { ValidationErrors, AbstractControl } from '@angular/forms';

export function DomainTldValidator(control: AbstractControl): ValidationErrors {
  const allowedTldList: string[] = ['live','fyi','world','zone'];
  return allowedTldList.indexOf(control.value.split('.').pop()) > -1
    ? {}
    : { 'domain-tld-validator': {
          message: `Only certain Top Level Domains are available, including ${allowedTldList.slice(0, -1).map(tld => `.${tld}`).join(', ')} and .${allowedTldList.slice(-1)[0]}`
        } 
      };
}
