<h3 *ngIf="props.label">{{ props.label }}</h3>
<p *ngIf="props.description">{{ props.description }}</p>
<div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
  <formly-validation-message [field]="field"></formly-validation-message>
</div>
<div class="form-container">
  <formly-field *ngFor="let f of simpleFields" [field]="f"></formly-field>
</div>
<div *ngFor="let f of complexFields" class="object-container">
  <formly-field [field]="f"></formly-field>
</div>
