import { Component, OnInit } from '@angular/core';
import { BasePageComponent } from '@nimbus/shared-lib';
import { DomainCreateLimiterService } from './services/domain-create-limiter.service';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { SnackBarType, SnackbarService } from 'core-global-frontend-snackbar';
import { OverlayService, TextLinesDialogComponent } from 'core-global-frontend-common-ui';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { HeaderService } from 'core/libs/global/frontend/header/src/lib/header.service';
import { DomainCount } from '../models/domain-count.model';

@Component({
  selector: 'app-domain-creator',
  templateUrl: './domain-creator.component.html',
  styleUrls: ['./domain-creator.component.scss']
})
export class DomainCreatorComponent extends BasePageComponent implements OnInit {
  domainCountUpdate$: Observable<any> | undefined;

  constructor(
    public partnerCacheService: PartnerCacheService,
    private _domainCreateLimiterService: DomainCreateLimiterService,
    private _overlayService: OverlayService,
    private _headerService: HeaderService,
    private _snackbarService: SnackbarService
  ) {
    super();
  }

  override async ngAfterViewInit() {
    super.ngAfterViewInit('domain/create');
  }

  ngOnInit(): void {
    this.domainCountUpdate$ = this._domainCreateLimiterService.domainCountUpdate$
      .pipe(
        map((counts: DomainCount[]) => [
          counts,
          counts.filter(p => p.dailyLimit !== null && p.usedToday > p.dailyLimit)
            .map(p => `Your ${counts.length > 1 ? p.partnerName : ''} account has exceeded the daily limit of ${p.dailyLimit} domains. Please remove one queued domain and/or contact your account manager to increase the daily limit.`)
        ]),
        switchMap(([counts, allModalMessage]) => {
          let allTimeMessage: string[] = [];
          counts.filter((p: DomainCount) => p.allTimeLimit !== null && p.usedAllTime >= p.allTimeLimit).forEach((p: DomainCount) => {
            allTimeMessage[p.partnerId] = ` All-time limit of ${p.allTimeLimit} domains ${p.usedAllTime == p.allTimeLimit ? 'reached' : 'exceeded'}.`;
            if (p.usedAllTime == p.allTimeLimit) {
              this._snackbarService.open(
                `Your ${counts.length > 1 ? p.partnerName : ''} account has reached the all-time limit of ${p.allTimeLimit} domains. Please contact your account manager to increase the all-time limit.`,
                SnackBarType.warn
              );
            }
            else {
              (allModalMessage as string[]).push(`Your ${counts.length > 1 ? p.partnerName : ''} account has exceeded the all-time limit of ${p.allTimeLimit} domains. Please contact your account manager to increase the all-time limit.`);
            }
          });

          let msg = `Launch net new domains with us. `;
          if (allModalMessage.length === 0) msg += `Created today: ${counts.map(p => ` ${counts.length > 1 ? p.partnerName : ''} ${p.usedToday}/${p.dailyLimit == null ? '∞' : p.dailyLimit}${allTimeMessage[p.partnerId] ?? ''}`).join(', ')}`;
          this._headerService.setSubheading(msg);
          return allModalMessage.length > 0 ? this._overlayService.openDialog(TextLinesDialogComponent, { data: { messages: [allModalMessage.pop()] } }) : of(null);
        }));
  }
}
