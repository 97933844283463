import { Injectable } from '@angular/core';

@Injectable()
export class SubverticalRequestsDockSchemaService {
  getSchema(formModel: any) {
    return [
      {
        type: 'tabs',
        fieldGroup: [
          {
            templateOptions: { label: 'Review Request', icon: 'eye-icon' },
            fieldGroup: [
              {
                key: 'date-header',
                type: 'date-header',
                templateOptions: {
                  submitDate: formModel.submitDate,
                  reviewDate: formModel.reviewDate
                }
              },
              {
                key: 'subverticalRequest',
                wrappers: ['section-wrapper'],
                fieldGroupClassName: 'subvertical-wrapper',
                fieldGroup: [
                  {
                    key: 'vertical',
                    defaultValue: '',
                    type: 'vertical',
                    templateOptions: {
                      label: 'Vertical *',
                      width: 100,
                      readOnly: true
                    }
                  },
                  {
                    key: 'subVertical',
                    defaultValue: '',
                    type: 'subvertical-input',
                    templateOptions: {
                      label: 'Subvertical',
                      width: 100,
                      readOnly: true
                    }
                  },
                  {
                    key: 'geo',
                    defaultValue: '',
                    className: 'geo-display-show',
                    type: 'geo',
                    templateOptions: {
                      label: 'Geo',
                      width: 100,
                      showFullList: true,
                      readOnly: true
                    }
                  },
                  {
                    key: 'language',
                    defaultValue: '',
                    type: 'language',
                    templateOptions: {
                      label: 'Language',
                      width: 100,
                      showFullList: true,
                      readOnly: true
                    }
                  }
                ]
              },
              {
                key: 'keywords',
                defaultValue: '',
                type: 'keywords',
                templateOptions: {
                  placeholder: 'eg:\nkeyword1\nkeyword2\nkeyword3',
                  label: 'Keywords*',
                  width: 100,
                  rows: 10,
                  readOnly: true,
                  showTitle: false
                }
              }
            ]
          },
          {
            templateOptions: { label: 'Notes', icon: 'partner-note' },
            fieldGroup: [
              {
                key: 'notes',
                defaultValue: '',
                type: 'keywords',
                className: 'note-style',
                templateOptions: {
                  placeholder: 'The notes will be displayed here when available',
                  label: 'Notes',
                  width: 100,
                  rows: 20,
                  readOnly: true,
                  showTitle: false
                }
              }
            ]
          }
        ]
      }
    ];
  }
}
