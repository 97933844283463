import { Injectable } from '@angular/core';

@Injectable()
export class TopOptionsTogglerCacheService {
  hideTopControl = false;
  viewWidth = 0;

  isControlVisibleAndSmallerView() {
    return this.viewWidth <= 1200 && this.hideTopControl === false;
  }
}
