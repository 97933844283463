import { Component } from '@angular/core';
import { PartnerCacheService } from '../../../../shared/services/partner-cache.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { DomainManagerPrefilterModel } from '../../models/domain-manager-prefilter.model';
import { ReplaySubject } from 'rxjs';
import { DomainListCacheService } from '../services/domain-list-cache.service';

@Component({
  selector: 'app-domain-manager-wrapper',
  templateUrl: './domain-manager-wrapper.component.html',
  styleUrls: ['./domain-manager-wrapper.component.scss'],
})
export class DomainManagerWrapperComponent {
  private readonly _filterSubject =
    new ReplaySubject<DomainManagerPrefilterModel>();
  filter$ = this._filterSubject.asObservable();

  constructor(
    public partnerCacheService: PartnerCacheService,
    public domainListCacheService: DomainListCacheService,
    public ovs: ObjectValidatorService,
  ) { }

  filter($event: DomainManagerPrefilterModel) {
    this._filterSubject.next($event);
  }
}
