<section class="dashboard-section">
  <div class="dashboard-container">
    <div class="dashboard-content">
      <h1 class="dashboard-header">Welcome back to RAMP Partner, {{ tokenCacheService.tokenResponse.fullName }}!</h1>
      <p class="dashboard-paragraph">Get started by jumping into our most popular tools.</p>
      <div class="dashboard-menu-card-holder">
        <nimbus-dashboard-card *ngFor="let siteMapNode of siteMapNodes"
          [siteMapNode]="siteMapNode"></nimbus-dashboard-card>
      </div>
    </div>
  </div>
</section>