import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationRoutingModule } from './notification-routing.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, NotificationRoutingModule]
})
export class NotificationRouteModule {}

