import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'frontend-area-shell',
  templateUrl: './area-shell.component.html',
  styleUrls: ['./area-shell.component.scss'],
  imports: [CommonModule, RouterModule],
  standalone: true,
})
export class AreaShellComponent {}
