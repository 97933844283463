<div [class]="'dock ' + (menuTitles.length > 1 ? '' : 'dock__no-menu')">
  <mat-nav-list class="dock__nav" *ngIf="menuTitles.length > 1">
    <span *ngFor="let item of menuTitles">
      <span>
        <a
          (click)="menuClick(item)"
          mat-list-item
          [class]="
            item === menuTitles[visibleDockableIndex]
              ? ' mat-mdc-list-item mat-mdc-list-item--active'
              : 'mat-mdc-list-item'
          ">
          <span class="mat-mdc-nav-list__text">{{ item }}</span>
        </a>
      </span>
    </span>
  </mat-nav-list>
  <div class="dock__title max-height no-margin-right">
    {{ model[dockContent.modelTitleProperty] }}
  </div>
  <section class="section">
    <ng-container #bottomDockContainer> </ng-container>
  </section>
</div>
