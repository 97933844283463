import { Role } from '../../features/access-control/models/role';

export class TokenResponse {
  constructor(
    public expiration: string = String(new Date()),
    public userName: string = '',
    public roles: Role[] = [],
    public fullName: string = '',
    public userId: string = '',
    public email: string = ''
  ) {}
}
