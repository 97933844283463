import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  /**
   * Compares the colorCode param to the threshold param and indicates if the colorCode is lighter or darker than the threshold value.
   * @param {string} colorCode The color code to compare.
   * @param {number} threshold The middle value for determining the line between light and dark. Default is 128.
   * @returns {string} Returns 'light' or 'dark'.
   */
  lightOrDark(
    colorCode: {
      color: any;
      charAt: (arg0: number) => string;
      substring: (arg0: number, arg1: number) => any;
    },
    threshold = 128,
  ) {
    if (!colorCode) {
      return 'light';
    }

    if (typeof colorCode !== 'string') {
      if (colorCode.color) {
        colorCode = colorCode.color;
      } else {
        return 'light';
      }
    }

    // split up the color code and reduce it to a single value.
    const color =
      colorCode.charAt(0) === '#' ? colorCode.substring(1, 7) : colorCode;
    const r = parseInt(color.substring(0, 2), 16); // hexToR
    const g = parseInt(color.substring(2, 4), 16); // hexToG
    const b = parseInt(color.substring(4, 6), 16); // hexToB
    const rgbAdjustment = r * 0.299 + g * 0.587 + b * 0.114;

    return rgbAdjustment > threshold ? 'light' : 'dark';
  }

  flattenListNodes<T>(nodeList: any[], flatList: T[] = []) {
    for (const node of nodeList) {
      if (node.siteMapNodes) {
        this.flattenListNodes<T>(node.siteMapNodes, flatList);
      }
      flatList.push(node);
    }
    return flatList;
  }
}
