import { KeyboardActions } from './keyboard-actions';
export class KeyboardAction {
  constructor(
    public action: KeyboardActions,
    public shift: boolean = false,
    public ctrl?: boolean,
    public char?: string,
    public preventDefault: () => any = null
  ) {}
}
