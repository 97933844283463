<div class="documentation-highlighted">Conversion Tracking Parameters</div>
<br />
<div>
Conversion tracking parameters allow System1 to report conversions back to your buyside
campaign. System1 currently supports the following buysides:
</div>
<ul>
<li *ngFor="let network of buysideNetworks">{{ network }}</li>
</ul>
<br />
<ng-container *ngFor="let table of tables">
<hr />
<div>{{ table.title }}</div>
<div *ngIf="!(table.notes | callbackRunnerPipe: ovs.isNullOrEmpty)" class="notes">
    <br />
    <div class="documentation bold">Please Note:</div>
    <ul>
    <li *ngFor="let note of table.notes">{{ note }}</li>
    </ul>
    <br />
</div>
<div class="documentation-grid-container">
    <div
    *ngFor="let header of table.headers; index as i"
    [class]="columnClassHelper.getColumnHeaderClass(table, i)">
    <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let element of table.data">
    <div class="documentation-grid section-header">
        <span class="documentation bold">{{ element.header }}</span>
    </div>
    <ng-container *ngFor="let record of element.records">
        <div [class]="columnClassHelper.getColumnValueClass(0, table)">
        <span class="documentation bold">{{ record.parameter }}</span>
        <ng-container *ngIf="record['required']">
            <br />
            <span class="documentation red">required*</span>
        </ng-container>
        </div>
        <div [class]="columnClassHelper.getColumnValueClass(1, table)">
        <span>{{ record.description }}</span>
        </div>
        <div [class]="columnClassHelper.getColumnValueClass(2, table)">
        <span class="example">
            {{ record.example }}
        </span>
        </div>
    </ng-container>
    </ng-container>
</div>
</ng-container>