import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DebugBarComponent } from './debug-bar.component';
import { EnvironmentDetailsService } from './services/environment-details.service';
import { HealthMonitorWebService } from './services/health-monitor-web.service';
import { ImpersonationModule } from '../impersonation/impersonation.module';
import { AngularMaterialModule } from '../../core/angular-material.module';
import { MatTabsModule } from '@angular/material/tabs';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AccessControlModule } from '../../features/access-control/access-control.module';

@NgModule({
  declarations: [DebugBarComponent],
  exports: [DebugBarComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatTabsModule,
    ScrollingModule,
    ImpersonationModule,
    AccessControlModule
  ],
  providers: [EnvironmentDetailsService, HealthMonitorWebService]
})
export class DebugBarModule {}
