import { Subscription, Subject, ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { QRow } from './q-row';
import { ColumnConfiguration, ColumnType } from './column-configuration';
import { ClipboardDataConversionService } from '../q-services/clipboard-data-conversion.service';
import { StringFunctionsService } from '../shared/string-functions.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { GridCallbackParameter } from './grid-callback-parameter';

export class GridClipboard {
  private readonly _clipboardTaggedBox: HTMLTextAreaElement;
  private readonly _internalCopyBox: HTMLTextAreaElement;
  private readonly _clipboardBox: HTMLTextAreaElement;
  private _changeSubject = new Subject<void>();
  private _changeSubscription = new Subscription();
  private readonly _masterTag: string = '<qGridClipboardMasterTag>';
  private _isQGridSourced = false;

  public onClipboardDataReadySubject = new ReplaySubject<void>();
  public readonly colTag: string = '<qGridClipboardColTag>';
  public readonly rowTag: string = '<qGridClipboardRowTag>';
  public readonly defaultColumnDelimiter = '\t';
  public readonly carriageReturn = '\r';
  public readonly lineFeed = '\n';

  get defaultRowDelimiter(): string {
    return this.carriageReturn + this.lineFeed;
  }

  constructor(
    private _textToTable: ClipboardDataConversionService,
    private _stringFuncs: StringFunctionsService,
    private _ovs: ObjectValidatorService,
  ) {
    const _qgcb = 'qGridClipboardHTMLTextAreaElement';

    this._clipboardTaggedBox = this._createHiddenTextarea(`tagged-${_qgcb}`);
    this._clipboardTaggedBox.value = '';

    this._internalCopyBox = this._createHiddenTextarea(`internal-${_qgcb}`);
    this._internalCopyBox.value = '';

    this._clipboardBox = this._createHiddenTextarea(_qgcb);
    this._clipboardBox.value = '';

    this._changeSubscription = this._changeSubject
      .asObservable()
      .pipe(debounceTime(100))
      .subscribe(() => {
        if (this._clipboardBox.value !== '') {
          this.onClipboardDataReadySubject.next(null);
        }
      });
  }

  private _createHiddenTextarea(id: string) {
    const el = document.getElementById(id) as HTMLTextAreaElement;
    if (document.getElementById(id)) {
      return el;
    }
    const element = document.createElement('textarea');
    element.id = id;
    element.style.position = 'fixed';
    element.style.left = '0';
    element.style.top = '0';
    element.style.opacity = '0';
    document.body.appendChild(element);
    return element;
  }

  isDataFromInternalCopy(gridGUID: string): boolean {
    return (
      this._isDataFromQGridSource() &&
      gridGUID + this._clipboardTaggedBox.value === this._internalCopyBox.value
    );
  }

  copyToClipboard(rawValue: string, gridGUID: string, taggedValue: string) {
    this._internalCopyBox.value = gridGUID + this._masterTag + taggedValue;
    this._clipboardTaggedBox.value = this._masterTag + taggedValue;
    this._clipboardBox.value = rawValue;
    this._clipboardBox.focus();
    this._clipboardBox.select();
    document.execCommand('copy');
  }

  readClipboardAndStore(): void {
    // this will take care of pasting from the external copy
    // the keyboard paste action will occur in the box when gets focus
    this._clipboardBox.focus();
    this._clipboardBox.select();
    this._changeSubject.next(null);
  }

  getTable(): string[][] {
    return this._isQGridSourced
      ? this._textToTable.textToTable(
          this._stringFuncs.replaceStrings(
            [this._masterTag],
            this._clipboardTaggedBox.value,
            '',
          ),
          this.colTag,
          this.rowTag,
        )
      : this._textToTable.textToTable(
          this._clipboardBox.value,
          this.defaultColumnDelimiter,
          this.defaultRowDelimiter,
        );
  }

  resetInternalCopy() {
    this._internalCopyBox.value = '';
  }

  isClipboardDataCopyable(
    targetColNo: number,
    cellValueToCopy: any,
    targetRow: QRow,
    columns: ColumnConfiguration[],
  ): any {
    const targetCol = columns.find(c => c.visibleColumnNumber === targetColNo);
    if (
      targetCol.isColumnVisible() === false ||
      (this._ovs.isFunction(targetCol.isEditable)
        ? (
            targetCol.isEditable as (
              qCallbackParam: GridCallbackParameter,
            ) => boolean
          )(targetRow.getGridCallbackParameter())
        : (targetCol.isEditable as boolean)) === false ||
      targetCol.fieldName === ColumnConfiguration.nA
    ) {
      // can not be copied, if the target column is not visible, or not editable, or its field name is NA
      return false;
    }
    if (
      // if the target type is string, it can accept any value
      targetCol.columnType === ColumnType.String || // if types is boolean and convertable
      (targetCol.columnType === ColumnType.Boolean &&
        this._ovs.isBoolean(cellValueToCopy))
    ) {
      return true;
    }
    if (
      targetCol.columnType === ColumnType.Component &&
      (this._ovs.isString(cellValueToCopy) ||
        this._ovs.isNumber(cellValueToCopy))
    ) {
      return true;
    }
    if (targetCol.columnType === ColumnType.Number) {
      // fro number types, if the value can be parsed can then be copied
      return !isNaN(Number.parseFloat(cellValueToCopy));
    }
    return false;
  }

  unsubscribe(): void {
    if (
      this._ovs.isDefined(this._changeSubscription) &&
      !this._changeSubscription.closed
    ) {
      this._changeSubscription.unsubscribe();
    }
  }

  private _isDataFromQGridSource(): boolean {
    const a = this._stringFuncs.replaceCharacters(
      [this.carriageReturn, this.lineFeed, this.defaultColumnDelimiter],
      this._stringFuncs.replaceStrings(
        [this._masterTag, this.colTag, this.rowTag],
        this._clipboardTaggedBox.value,
        '',
      ),
      '',
    );
    this._isQGridSourced =
      a ===
      this._stringFuncs.replaceCharacters(
        [this.carriageReturn, this.lineFeed, this.defaultColumnDelimiter],
        this._clipboardBox.value,
        '',
      );

    return this._isQGridSourced;
  }
}
