import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { SubverticalReviewsStatusEnum } from '../models/subvertical-reviews-status.enum';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import { ICustomCell, IClickableCell } from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';

@Component({
  selector: 'subvertical-reviews-action-cell',
  templateUrl: './subvertical-reviews-action-cell.component.html',
  styleUrls: ['./subvertical-reviews-action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
  ],
})
export class SubverticalReviewsActionCellComponent
  implements ICustomCell, IClickableCell
{
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() transform?: (input: string) => string;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Output() clickEvent = new EventEmitter<any>();

  get isReviewPendingOrDenied(): boolean {
    return (
      this.form.controls['status'].value ===
        SubverticalReviewsStatusEnum.Pending ||
      this.form.controls['status'].value === SubverticalReviewsStatusEnum.Denied
    );
  }

  setMode(review: boolean) {
    this.clickEvent.emit({ ...this, review });
  }
}
