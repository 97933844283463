import { Injectable } from '@angular/core';
import { ChangeTracker } from '../q-models/change-tracker';
import { QGrid } from '../q-models/q-grid';

@Injectable()
export class ChangeTrackerBuilderService {
  constructor() {}

  build(qGrid: () => QGrid): ChangeTracker {
    return new ChangeTracker(qGrid);
  }
}
