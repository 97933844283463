import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SearchableListItemWithSelect, SortOption } from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LinksetCacheService } from '../../../../services/linkset-cache.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'linkset-vertical-search',
  templateUrl: './vertical-subvertical-country.component.html',
  styleUrls: ['./vertical-subvertical-country.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalSubverticalCountryComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  private _subscriptionManager = new SubscriptionManager();
  readonly noSort: SortOption = SortOption.None;
  items$: Observable<SearchableListItemWithSelect[]>;
  isLoading$ = this._linksetCacheService.isLoading$;

  constructor(
    private _ovs: ObjectValidatorService,
    private _linksetCacheService: LinksetCacheService,
    private _router: Router,
  ) {
    super();
    this.items$ = this._linksetCacheService.linksets.pipe(
      map(linksets =>
        linksets
          .filter(item => !this._ovs.isNullOrEmpty(item.geoTwoLetterCode))
          .reduce((acc, linkset) => {
            const key = `${linkset.vertical}/${linkset.subVertical}/${linkset.geoFullName}`;
            acc[key] = acc[key] ?? [];
            acc[key].push(linkset);
            return acc;
          }, {}),
      ),
      map(linksetDictionary =>
        Object.keys(linksetDictionary).map(
          key =>
            new SearchableListItemWithSelect(
              JSON.stringify({ availableLinksets: linksetDictionary[key] }),
              false,
              this._display(linksetDictionary[key]),
            ),
        ),
      ),
    );
  }

  private _display(linksets: any) {
    let display = '';
    const match = /.*(-[a-zA-Z0-9]{2,5})$/.exec(linksets[0].subVertical);
    if (!this._ovs.isNullOrEmpty(match)) {
      display = `${linksets[0].vertical}/${linksets[0].subVertical.replace(
        match[1],
        '',
      )}/${linksets[0].geoTwoLetterCode.toLowerCase()}`;
    } else {
      display = `${linksets[0].vertical}/${linksets[0].subVertical}/${linksets[0].geoTwoLetterCode.toLowerCase()}`;
    }
    return display;
  }

  ngOnInit(): void {
    this._subscriptionManager.register(
      this.formControl?.valueChanges
        .pipe(
          tap(linksetStr =>
            this.formControl?.patchValue(linksetStr, { emitEvent: false }),
          ),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  goToRequestPage() {
    this._router.navigate(['self-serve/subverticals/requests']);
  }
}
