import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import {
  BasePageComponent,
  DockableItem,
  DockContent,
  ILayoutContent,
} from '@nimbus/shared-lib';
import { SubverticalReviewsListColumnsBuilderService } from './services/subvertical-reviews-list-columns-builder.service';
import { SubverticalReviewsCacheService } from './services/subvertical-reviews-cache.service';
import { SubverticalReviewComponent } from './subvertical-review/subvertical-review.component';
import { SubverticalReviewCacheService } from './subvertical-review/services/subvertical-review-cache.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import { SubverticalReadonlyComponent } from './subvertical-readonly/subvertical-readonly.component';
import { SubverticalReadonlyCacheService } from './subvertical-readonly/services/subvertical-readonly-cache.service';
import { ColumnInfo } from 'core-global-frontend-grid';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-subvertical-reviews-manager',
  templateUrl: './subvertical-reviews-manager.component.html',
  styleUrls: ['./subvertical-reviews-manager.component.scss'],
})
export class SubverticalReviewsManagerComponent
  extends BasePageComponent
  implements AfterViewInit, OnDestroy, ILayoutContent
{
  columns: ColumnInfo[];

  constructor(
    private _reviewsListColumnsBuilderService: SubverticalReviewsListColumnsBuilderService,
    public reviewsCacheService: SubverticalReviewsCacheService,
    private _subverticalReviewCacheService: SubverticalReviewCacheService,
    private _subverticalReadonlyCacheService: SubverticalReadonlyCacheService,
    private _ovs: ObjectValidatorService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.columns = this._reviewsListColumnsBuilderService.columns;
  }

  load() {
    this.reviewsCacheService.load(true).subscribe();
  }

  async ngAfterViewInit() {
    super.ngAfterViewInit('self-serve/subverticals/reviews');
    this._setupSubscriptions();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subverticalReviewCacheService.closeSubverticalReviewDock();
    this._subverticalReadonlyCacheService.closeSubverticalReadonlyDock();
  }

  private _setupSubscriptions() {
    this._subscriptionManager.registerMultiple([
      this.reviewsCacheService.load().subscribe(() => {
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
      }),
      this.reviewsCacheService.isLoading$.subscribe(() => {
        this._changeDetectorRef.markForCheck();
        this._changeDetectorRef.detectChanges();
      }),
      this._subverticalReviewCacheService.closeSubverticalReviewDock$
        .pipe(tap(() => this.closeDock()))
        .subscribe(),
      this._subverticalReadonlyCacheService.closeSubverticalReadonlyDock$
        .pipe(tap(() => this.closeDock()))
        .subscribe(),
      this._subverticalReviewCacheService
        .openDock$()
        .pipe(
          switchMap(
            () => this._subverticalReviewCacheService.subverticalRequestId$,
          ),
        )
        .pipe(filter(value => !this._ovs.isNullOrEmpty(value)))
        .subscribe(value => {
          this.setDockContent(
            new DockContent(
              [new DockableItem('Review', SubverticalReviewComponent)],
              'id',
            ),
          );
          this.setDockModel(value);
        }),
      this._subverticalReadonlyCacheService
        .openDock$()
        .pipe(
          switchMap(
            () => this._subverticalReadonlyCacheService.subverticalRequestId$,
          ),
        )
        .pipe(filter(value => !this._ovs.isNullOrEmpty(value)))
        .subscribe(value => {
          this.setDockContent(
            new DockContent(
              [new DockableItem('Readonly', SubverticalReadonlyComponent)],
              'id',
            ),
          );
          this.setDockModel(value);
        }),
    ]);
  }
}
