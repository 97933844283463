import { NgModule } from '@angular/core';
import { DateRangeFilterComponent } from './date-range-filter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../core/angular-material.module';

@NgModule({
  imports: [
    CommonModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AngularMaterialModule
  ],
  declarations: [DateRangeFilterComponent],
  exports: [DateRangeFilterComponent]
})
export class DateRangeFilterModule {}
