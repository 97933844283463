import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarType } from './snackbar.model';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class SnackbarService {
  constructor(
    private _snackBar: MatSnackBar,
    private _ovs: ObjectValidatorService,
  ) {}

  open(message: string, type?: SnackBarType, duration?: number) {
    let action = null;

    if (!this._ovs.isDefined(type)) {
      type = SnackBarType.done;
    }

    if (!this._ovs.isDefined(duration)) {
      if (type === SnackBarType.error || type === SnackBarType.important) {
        duration = 0;
        action = 'Dismiss';
      } else {
        duration = 3500;
      }
    }

    this._snackBar.open(message, action as any, {
      verticalPosition: 'top',
      horizontalPosition: 'end',
      panelClass: [type as any, 'mat-snackbar'],
      duration: duration,
    });
  }
}
