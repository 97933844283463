<div *ngIf="userName">
  <h4>Logged in as</h4>
  <div class="user-area__user">
    {{ userName }}
  </div>
</div>
<div class="user-section">
  <a mat-button [matMenuTriggerFor]="settings" class="settings-button">
    <img
      src="assets/icons/settings-icon.svg"
      alt="settings"
      class="settings-icon"
    />
  </a>
  <mat-menu #settings="matMenu" xPosition="before">
    <div class="settings-menu">
      <button mat-menu-item class="signout-button" (click)="logout()">
        Sign out
      </button>
      <a
        mat-menu-item
        class="external-link-button"
        target="_blank"
        href="https://openmail.atlassian.net/jira/core/projects/INC/form/10"
        >Create Ticket: Incident (emergency)</a
      >
      <a
        mat-menu-item
        class="external-link-button"
        target="_blank"
        href="https://openmail.atlassian.net/jira/core/projects/IRCO/form/9"
        >Create Ticket: Intake (non-emergency)</a
      >
    </div>
    <div class="toggle-container">
      <mat-slide-toggle
        color="primary"
        [ngModel]="isDebug"
        (change)="toggleDebug($event)"
      >
        <span class="user-area__debug-text">Debug</span>
      </mat-slide-toggle>
    </div>
  </mat-menu>
</div>
