import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicButtonComponent } from './dynamic-button.component';
import { AngularMaterialModule } from '../../core/angular-material.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [DynamicButtonComponent],
  imports: [CommonModule, AngularMaterialModule, BrowserModule],
  exports: [DynamicButtonComponent]
})
export class DynamicButtonModule {}
