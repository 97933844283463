import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromSchemas from './+state/schemas/schemas.reducer';
import { SchemasEffects } from './+state/schemas/schemas.effects';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SchemasService } from './+state/schemas/schemas.service';

@NgModule({ imports: [CommonModule,
        StoreModule.forFeature(fromSchemas.SCHEMAS_FEATURE_KEY, fromSchemas.schemasReducer),
        EffectsModule.forFeature([SchemasEffects]),
        StoreModule.forFeature(fromSchemas.SCHEMAS_FEATURE_KEY, fromSchemas.schemasReducer)], providers: [SchemasService, provideHttpClient(withInterceptorsFromDi())] })
export class GlobalFrontendGlobalStoreModule {}
