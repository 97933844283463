import { ValidationErrors, AbstractControl } from '@angular/forms';
import { InjectorService } from '../../services/injector.service';
import { DomainCreateLimiterService } from '../../../areas/domain/domain-creator/services/domain-create-limiter.service';

export function DomainLimitValidator(control: AbstractControl): ValidationErrors {
  const domainLimitService = InjectorService.injector.get(DomainCreateLimiterService);
  return !domainLimitService.isDomainLimitExceeded() && !domainLimitService.isAllTimeLimitExceeded()
    ? {}
    : { 'domain-limit-validator': {
      message: domainLimitService.isAllTimeLimitExceeded() 
        ? 'You have exceeded your all-time domain limit. Please remove some to continue and contact your account manager.'
        : 'You have exceeded your daily domain limit. Please remove some to continue.'
    }};
}
