import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { IArea } from './i-area';

export class Area implements IArea {
  public firstTd: HTMLTableCellElement;
  public lastTd: HTMLTableCellElement;

  constructor(private _ovs: ObjectValidatorService) {}

  private get _firstExists(): boolean {
    return this._ovs.isDefined(this.firstTd);
  }

  private get _lastExists(): boolean {
    return this._ovs.isDefined(this.lastTd);
  }

  public get left(): number {
    return this._firstExists ? this.firstTd.offsetLeft - 1 : 0;
  }

  public get top(): number {
    return this._firstExists ? this.firstTd.offsetTop - 1 : 0;
  }

  public get width(): number {
    return this._firstExists && this._lastExists
      ? this.lastTd.offsetLeft -
          this.firstTd.offsetLeft +
          this.lastTd.clientWidth -
          1
      : 0;
  }

  public get height(): number {
    return this._firstExists && this._lastExists
      ? this.lastTd.offsetTop -
          this.firstTd.offsetTop +
          this.lastTd.offsetHeight -
          1
      : 0;
  }

  public isReady(): boolean {
    return this._firstExists && this._lastExists;
  }
}
