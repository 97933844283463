import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { RangeType } from '../range/range.model';
import { FilterConfiguration } from './filter-configuration';

export class FilterDefaults {
  protected _ovs = new ObjectValidatorService();
  public static getDefault(): FilterDefaults {
    return new FilterDefaults(
      RangeType.default(),
      '',
      '',
      [],
      FilterConfiguration.All,
      false,
      [],
      [],
    );
  }
  constructor(
    public range: RangeType,
    public includeText: string,
    public excludeText: string,
    public equals: string[],
    public selectedEqual: string,
    public check: boolean,
    public customValues: string[],
    public selectedCustomValues: string[],
  ) {}
  copy(): FilterDefaults {
    return new FilterDefaults(
      this.range,
      this.includeText,
      this.excludeText,
      this.equals,
      this.selectedEqual,
      this.check,
      this.customValues,
      this.selectedCustomValues,
    );
  }
  clear() {
    this.check = false;
    this.range.reset();
    this.includeText = '';
    this.excludeText = '';
    this.selectedEqual = FilterConfiguration.All;
    this.selectedCustomValues = FilterConfiguration.CustomAll;
  }
  needsToPersist(): boolean {
    const defaultValue = FilterDefaults.getDefault();
    return (
      this.check !== defaultValue.check ||
      this.includeText !== defaultValue.includeText ||
      this.excludeText !== defaultValue.excludeText ||
      this.selectedEqual !== defaultValue.selectedEqual ||
      !this.range.isInInitialState ||
      !this._ovs.isNullOrEmpty(this.selectedCustomValues)
    );
  }
}
