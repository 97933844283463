import { OMLinksetKeyword } from './o-m-linkset-keyword';

export class OMLinkset {
  constructor(
    public vertical: string,
    public subvertical: string,
    public keywords: OMLinksetKeyword[],
    public geo: string,
    public language: string
  ) {}
}
