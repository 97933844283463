import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { ColumnClassHelper } from "../shared/columnClassHelper";

@Component({
    selector: 'app-installing-rampjs',
    templateUrl: './installing-rampjs.component.html',
    styleUrl: '/installing-rampjs.component.scss',
    standalone: true,
    imports: [CommonModule]
})
export class InstallingRampjsComponent {
    constructor (
        public columnClassHelper: ColumnClassHelper
    ){}
}