/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
  Component,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  ColumnConfiguration,
  ColumnType,
} from '../../q-models/column-configuration';
import { ValidatorType } from '../../q-models/validator-type';
import { CellEditParameter } from '../../q-models/cell-edit-parameter';
import { CellEditor } from '../../q-models/cell-editor';
import { ICellBlocker } from '../../plugins/icell-blocker';
import { NativeValidatorType } from '../../plugins/native-validator-type';
import { ListItem } from '../../shared/list-item';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ListRange } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { SubscriptionManager } from '../../shared/subscription-manager';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BackdropService } from './backdrop.service';
import { GridCallbackParameter } from '../../q-models/grid-callback-parameter';

@Component({
  selector: 'q-cell-editor',
  templateUrl: './q-cell-editor.component.html',
  styleUrls: ['../../q-grid/q-grid.component.scss'],
  //OnPush doesn't work here as is becuase of the _backdrop dependency that triggers events affecting this component.
})
export class QCellEditorComponent implements OnDestroy {
  private readonly _numberMatchingPattern = /[\d\-.]/g;
  cellEditor: CellEditor;
  editingColumnNo: number;
  mainDivClass$: Observable<string> = of('');
  textClass$: Observable<string> = of('');
  numberDivClass$: Observable<string> = of('');
  lookupDivClass$: Observable<string> = of('');
  readonly notEditingColumnNo: number;
  private _isNumInteger: boolean;
  numberVal: string;
  private _initialRange: ListRange = { start: 0, end: 5 } as ListRange;
  matSelectHeight = '150px';
  private _subscriptionManager = new SubscriptionManager();
  private _lookup: ListItem[];
  filteredLookupOptions: Observable<ListItem[]>;
  private _lookupSubject = new Subject<void>();
  private _lookupOpenList$ = this._lookupSubject.asObservable();
  isANumber = false;
  isALookup = false;
  isAString = false;
  private _fldName = '';
  lookupFilterControl = new UntypedFormControl();
  selectedLookupValue = '';

  @Output() onLeave = new EventEmitter<void>();
  @Output() onFocus = new EventEmitter<void>();

  @ViewChild('stringTag', { static: true }) private _stringTag: ElementRef;
  @ViewChild('numberTag', { static: true }) private _numberTag: ElementRef;
  @ViewChild('listInputTag', { static: true }) private _listInput: ElementRef;
  @ViewChild(MatAutocompleteTrigger)
  private _autoComplete: MatAutocompleteTrigger;
  @ViewChild('voidTag', { static: true }) private _voidTag: ElementRef;
  @ViewChild(CdkVirtualScrollViewport)
  private _viewPort: CdkVirtualScrollViewport;

  private get value(): any {
    if (!this.ovs.isDefined(this.cellEditor.rowData)) {
      return '';
    }
    return this.cellEditor.rowData.value[this._fldName];
  }

  private set value(cellValue: any) {
    if (!this.ovs.isDefined(this.cellEditor.rowData)) {
      return;
    }
    this.cellEditor.rowData.value[this._fldName] = cellValue;
  }

  get stringValue(): any {
    if (!this.isAString) {
      return '';
    }
    return this.value;
  }

  set stringValue(cellValue: any) {
    this.value = cellValue;
  }

  get numValue(): any {
    if (!this.isANumber) {
      return '';
    }
    return this.value;
  }

  set numValue(cellValue: any) {
    this.value = cellValue;
  }

  get lookupValue(): any {
    if (!this.isALookup) {
      return '';
    }
    return this.value;
  }

  set lookupValue(cellValue: any) {
    this.value = cellValue;
  }

  get lookup(): ListItem[] {
    return this._lookup;
  }

  private _isCellOnAColumnWithLookups(): boolean {
    return (
      this.ovs.isDefined(this.cellEditor.column) &&
      this.ovs.isFunction(this.cellEditor.column.cellLookupsKey)
    );
  }

  private _isRowAsLookups(): boolean {
    return (
      this.ovs.isDefined(this.cellEditor.rowData) &&
      this.ovs.isDefined(this.cellEditor.rowData.rowComponent) &&
      this.ovs.isDefined(this.cellEditor.rowData.qGrid.lookups())
    );
  }

  get maxLength(): number {
    if (!this.ovs.isDefined(this.cellEditor.rowData)) {
      return 1000;
    }
    return this.cellEditor.rowData.rowComponent.hasValidators &&
      this.cellEditor.column
      ? this._maxLengthValidator(this.cellEditor.column)
      : 1000;
  }

  getIsANumber(): boolean {
    return (
      this.ovs.isDefined(this.cellEditor.column) &&
      this.cellEditor.column.columnType === ColumnType.Number
    );
  }

  getIsALookup(): boolean {
    return (
      this.ovs.isDefined(this.cellEditor.column) &&
      this.cellEditor.column.columnType === ColumnType.Lookup &&
      this.ovs.isDefined(this.lookup)
    );
  }

  getIsAString(): boolean {
    return (
      this.ovs.isDefined(this.cellEditor.column) &&
      this.cellEditor.column.columnType === ColumnType.String
    );
  }

  get __numMax(): number {
    if (!this.ovs.isDefined(this.cellEditor.rowData)) {
      return Number.MAX_SAFE_INTEGER;
    }
    return this.cellEditor.rowData.rowComponent.hasValidators && this.isANumber
      ? this._getNumMax(this.cellEditor.column)
      : Number.MAX_SAFE_INTEGER;
  }

  get __numMin(): number {
    if (!this.ovs.isDefined(this.cellEditor.rowData)) {
      return Number.MIN_SAFE_INTEGER;
    }
    return this.cellEditor.rowData.rowComponent.hasValidators && this.isANumber
      ? this._getNumMin(this.cellEditor.column)
      : Number.MIN_SAFE_INTEGER;
  }

  constructor(
    public ovs: ObjectValidatorService,
    private _changeDetector: ChangeDetectorRef,
    private _backdrop: BackdropService,
  ) {
    this.notEditingColumnNo = -2;
    this.editingColumnNo = this.notEditingColumnNo;
    this._setMainDivClass();
    this._setTextClass();
    this._setNumberDivClass();
    this.cellEditor = null;
    this._isNumInteger = true;
    this.numberVal = '';
    this._subscriptionManager.registerMultiple([
      this._lookupOpenList$.pipe(debounceTime(75)).subscribe(() => {
        const selectedLookupItem = this._lookup.find(
          x => x.getValue() == this.lookupValue,
        );
        this.selectedLookupValue = ovs.isDefined(selectedLookupItem)
          ? selectedLookupItem.label
          : '';
        this.lookupFilterControl.setValue(this.selectedLookupValue);
        this.filteredLookupOptions = this.lookupFilterControl.valueChanges.pipe(
          startWith(''),
          map(value => this._filter(value)),
        );
        this._autoComplete.openPanel();
        this._listInput.nativeElement.focus();
      }),
      _backdrop.onBackdropClose.subscribe(_event => this.closed()),
    ]);
  }

  setCellEditor(cellEditor: CellEditor) {
    this.cellEditor = cellEditor;
  }

  ngOnDestroy(): void {
    if (!this.cellEditor) {
      this.cellEditor.unsubscribe();
    }
    if (!this.onLeave) {
      this.onLeave.unsubscribe();
    }
    if (!this.onFocus) {
      this.onFocus.unsubscribe();
    }
    this._subscriptionManager.clear();
  }

  emptyFields() {
    this._stringTag.nativeElement.value = '';
    this._numberTag.nativeElement.value = '';
    this.selectedLookupValue = '';
  }

  openCellEdit(param: CellEditParameter): void {
    const isEditable = this.ovs.isFunction(this.cellEditor.column.isEditable)
      ? this.ovs.isFunction(this.cellEditor.column.isEditable)
        ? (
            this.cellEditor.column.isEditable as (
              qCallbackParam: GridCallbackParameter,
            ) => boolean
          )(this.cellEditor.rowData.getGridCallbackParameter())
        : (this.cellEditor.column.isEditable as boolean)
      : this.cellEditor.column.isEditable;

    if (!this.cellEditor.column || !isEditable) {
      this.editingColumnNo = -2;
      this._setMainDivClass();
      return;
    }
    this._fldName = this.cellEditor.column.fieldName;
    this.editingColumnNo = param.column.visibleColumnNumber; // make the cell-editor visible
    this._setMainDivClass();
    if (param.column.columnType === ColumnType.String) {
      this._stringTag.nativeElement.focus();
    } else if (param.column.columnType === ColumnType.Number) {
      this._numberTag.nativeElement.focus();
    } else {
      this._populateLookup();
    }
    this.isANumber = this.getIsANumber();
    this.isAString = this.getIsAString();
    this._setTextClass();
    this._setNumberDivClass();
    this.isALookup = this.getIsALookup();
    this._setLookupDivClass();
    // overwrite if user started typing on the cell or if current value is null make it blank
    if (
      (param.overwrite && !this.isALookup) ||
      !this.ovs.isDefined(this.cellEditor.rowData.value[this._fldName])
    ) {
      this.cellEditor.rowData.value[this._fldName] = '';
    }
    if (this.isANumber) {
      this._isNumInteger = this._hasNumIntegerBlocker(this.cellEditor.column);
      this.numberVal = '';
    }
    if (this.isALookup) {
      this._lookupSubject.next(null);
    }
    this._changeDetector.detectChanges();
  }

  closeCellEdit(): void {
    if (this.isANumber) {
      const num = this._isNumInteger
        ? parseInt(this.numValue, 10)
        : parseFloat(this.numValue);
      this.numValue = isNaN(num) ? '' : num;
      if (!!this.numValue && this.numValue !== '') {
        if (this.numValue > this.__numMax) {
          this.numValue = this.__numMax;
        }
        if (this.numValue < this.__numMin) {
          this.numValue = this.__numMin;
        }
      }
    }
    this.cellEditor.column = null;
    this.cellEditor.rowData = null;
    this.editingColumnNo = this.notEditingColumnNo;
    this._setMainDivClass();
    this._autoComplete.closePanel();
    this._voidTag.nativeElement.focus({
      preventScroll: true,
    });
    this._lookup = null;
    this.isANumber = false;
    this.isAString = false;
    this._setTextClass();
    this._setNumberDivClass();
    this.isALookup = false;
    this._setLookupDivClass();
    this._changeDetector.detectChanges();
  }

  private _setTextClass() {
    this.textClass$ = of(
      `ce-input${!this.isAString ? ' ce-input-invisible' : ''}`,
    );
  }

  private _setNumberDivClass() {
    this.numberDivClass$ = of(
      `ce-input${!this.isANumber ? ' ce-input-invisible' : ''}`,
    );
  }

  private _setLookupDivClass() {
    this.lookupDivClass$ = of(
      `ce-input${!this.isALookup ? ' ce-input-invisible' : ''} ce-input-lookup`,
    );
  }

  private _setMainDivClass() {
    this.mainDivClass$ = of(
      `ce-container${
        this.editingColumnNo !== this.notEditingColumnNo
          ? ' ce-container-visible'
          : ''
      }`,
    );
  }

  private _maxLengthValidator(column: ColumnConfiguration): number {
    return this._getBlockerValue(column, NativeValidatorType.MaxLength, 1000);
  }

  private _getNumMax(column: ColumnConfiguration): number {
    return this._getBlockerValue(
      column,
      NativeValidatorType.NumMaxValue,
      Number.MAX_SAFE_INTEGER,
    );
  }

  private _getNumMin(column: ColumnConfiguration): number {
    return this._getBlockerValue(
      column,
      NativeValidatorType.NumMinValue,
      Number.MIN_SAFE_INTEGER,
    );
  }

  private _getBlockerValue(
    column: ColumnConfiguration,
    nativeValidatorType: NativeValidatorType,
    defaultValue: number,
  ): number {
    if (
      !this.ovs.isDefined(
        this.cellEditor.rowData.rowComponent.getColumnCssClassPipeContext
          .columnsValidators[column.GUID][ValidatorType.Blocker],
      )
    ) {
      return defaultValue;
    }
    const matchingValidators =
      this.cellEditor.rowData.rowComponent.getColumnCssClassPipeContext.columnsValidators[
        column.GUID
      ][ValidatorType.Blocker].filter(
        validator =>
          (validator as ICellBlocker).nativeValidator === nativeValidatorType,
      );

    if (this.ovs.isNullOrEmpty(matchingValidators)) {
      return defaultValue;
    }

    return parseInt(
      (matchingValidators[0] as ICellBlocker).rule(null, null, null),
      10,
    );
  }

  private _hasNumIntegerBlocker(column: ColumnConfiguration): boolean {
    if (
      !this.ovs.isDefined(
        this.cellEditor.rowData.rowComponent.getColumnCssClassPipeContext
          .columnsValidators[column.GUID][ValidatorType.Blocker],
      )
    ) {
      return false;
    }

    const matchingValidators =
      this.cellEditor.rowData.rowComponent.getColumnCssClassPipeContext.columnsValidators[
        column.GUID
      ][ValidatorType.Blocker].filter(
        validator =>
          (validator as ICellBlocker).nativeValidator ===
          NativeValidatorType.NumInteger,
      );
    if (this.ovs.isNullOrEmpty(matchingValidators)) {
      return false;
    }

    return true;
  }

  valueChanged() {
    if (this.cellEditor.rowData) {
      this.cellEditor.rowData.onValueChangedViaInput();
    }
  }

  __numValidation(keyboardEvent: KeyboardEvent) {
    let val = this.numValue;
    if (!val) {
      val = '';
    }
    let hasDecimalPoint = !val ? false : ('' + val).indexOf('.') > -1;
    if (!hasDecimalPoint) {
      hasDecimalPoint = !this.numberVal
        ? false
        : ('' + this.numberVal).indexOf('.') > -1;
    }
    let hasMinus = !val ? false : ('' + val).indexOf('-') > -1;
    if (!hasMinus) {
      hasMinus = !this.numberVal
        ? false
        : ('' + this.numberVal).indexOf('-') > -1;
    }

    if (
      !keyboardEvent.key.match(this._numberMatchingPattern) ||
      (keyboardEvent.key === '.' && (this._isNumInteger || hasDecimalPoint)) ||
      (keyboardEvent.key === '-' &&
        (this.__numMin > 0 || hasDecimalPoint || hasMinus || val !== ''))
    ) {
      keyboardEvent.preventDefault();
      return;
    }
  }

  opened() {
    this._viewPort.setRenderedContentOffset(0);
    this._viewPort.setRenderedRange(this._initialRange);
    this._backdrop.onPanelOpen.next();
  }

  closed() {
    this.closeCellEdit();
    this._backdrop.onPanelClose.next();
    this.onLeave.emit();
  }

  scrolledIndexChanged() {
    if (!this._initialRange) {
      this._initialRange = this._viewPort.getRenderedRange();
    }
  }

  private _populateLookup() {
    if (!this._isCellOnAColumnWithLookups() || !this._isRowAsLookups()) {
      return;
    }
    const lookups: ListItem[] =
      this.cellEditor.rowData.qGrid.lookups()[
        this.ovs.isFunction(this.cellEditor.column.cellLookupsKey)
          ? (
              this.cellEditor.column.cellLookupsKey as (
                qCallbackParam: GridCallbackParameter,
              ) => string
            )(this.cellEditor.rowData.getGridCallbackParameter())
          : (this.cellEditor.column.cellLookupsKey as string)
      ];

    this._lookup = this.ovs.isNullOrEmpty(lookups) ? null : lookups;
    this.matSelectHeight =
      (this.lookup.length < 6 ? this.lookup.length * 2.5 : 15) + 'rem';
  }

  selectionChanged(event: MouseEvent, listItem: ListItem) {
    event.stopImmediatePropagation();
    this.lookupValue = listItem.getValue();
    this.valueChanged();
    this.closed();
  }

  private _filter(value: string): ListItem[] {
    if (this.ovs.isNullOrEmpty(this._lookup)) {
      return [];
    }
    if (this.ovs.isNullOrEmpty(value)) {
      return this._lookup;
    }
    if (typeof value === 'object' && this.ovs.isDefined(value)) {
      value = value!['label'];
    }
    return this._lookup.filter(option =>
      option.label.toLowerCase().includes(value.toLowerCase()),
    );
  }
}
