import { Component } from '@angular/core';

@Component({
  selector: 'app-syndication-api-endpoints',
  templateUrl: './syndication-api-endpoints.component.html',
  styleUrls: ['../documentation.scss']
})
export class SyndicationApiEndpointsComponent {
  tables = [
    {
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          parameter: 'auth_key (required)',
          description:
            'Your Reporting API authentication key. This is required for ' +
            'all requests to the Reporting API.',
          example: `https://reports.openmail.com/v2/campaign_intraday.json/?auth_key=test_06IDAJuUjUKgsdd`
        },
        {
          parameter: 'days (optional)',
          description:
            'Specify either a number of days for lookback ' + 'or a list of explicit date(s).',
          example: 'days=2 ' + '(or) ' + 'days=2020-05-21,2020-05-22'
        },
        {
          parameter: 'campaigns (optional)',
          description: 'Specify a particular campaign or a list of campaigns.',
          example:
            'campaigns=system1campaign.com ' + '(or) ' + 'campaigns=campaign1.com,campaign2.com'
        },
        {
          parameter: 'subids (optional)',
          description:
            'Specify a particular subid or list of subids. ' +
            'Default behavior will include all subids.',
          example: 'subids=test:subid1 ' + '(or) ' + 'subids=test:subid1,test:subid2'
        }
      ]
    },
    {
      headers: ['Field', 'Description', 'Example', 'Data Type'],
      data: [
        {
          field: 'utc_hour',
          description: 'Hour when the events are attributed',
          example: '“utc_hour”:”2020-11-18T22:00:00”',
          dataType: 'string ' + 'yyyy-mm-dd-hhmmss'
        },
        {
          field: 'campaign',
          description: 'A System1 campaign domain',
          example: '“campaign”:”system1campaign.com”',
          dataType: 'string'
        },
        {
          field: 'sub_id',
          description: '',
          example: '“sub id”:”mysubid:asdf-404040”',
          dataType: 'string '
        },
        {
          field: 'date',
          description: 'The UTC day the data represents',
          example: '“date”:”2020-05-21”',
          dataType: 'string yyyy-mm-dd'
        },
        {
          field: 'searches',
          description: 'Cumulative daily total of searches on keyword lander',
          example: '“searches”:200',
          dataType: 'int'
        },
        {
          field: 'clicks',
          description: 'Cumulative daily total of clicks to advertisers',
          example: '“clicks”:100',
          dataType: 'int'
        },
        {
          field: 'estimated_revenue',
          description: 'Cumulative daily total revenue earned from ad clicks',
          example: '“estimated_revenue”:100.01',
          dataType: 'decimal'
        },
        {
          field: 'last_updated',
          description: 'UTC hour where the cumulative totals above was last updated',
          example: '“last_updated”:”2020-05-21T21:00:00”',
          dataType: 'string yyyy-mm-dd-hhmmss'
        }
      ] as any
    }
  ];

  constructor() {}

  getHeaderCount(table) {
    return `grid-template-columns: repeat(${table.headers.length}, auto)`;
  }

  getColumnHeaderClass(table, index) {
    return index < table.headers.length - 1
      ? 'documentation-grid column-header column-header__standard'
      : 'documentation-grid column-header column-header__end';
  }

  getColumnValueClass(columnIndex, table) {
    return columnIndex < table.headers.length - 1
      ? 'documentation-grid column-value column-value__standard'
      : 'documentation-grid column-value column-value__end';
  }
}
