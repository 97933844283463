import { FilterMode } from '../models/filter-mode.enum';
import { FilterBoxInit } from '../models/filter-box-init';

export class FilterBoxInitBuilder {
  private _filterMode: FilterMode = FilterMode.None;
  private _persistenceId: string = null;
  private _persistenceDurationInMs: number = null;
  private _columnNamesWithListFilter: string[] = [];
  private _dateColumnNames: string[] = null;
  private _listCallback: () => any = null;

  withFilterMode(filterMode: FilterMode): FilterBoxInitBuilder {
    this._filterMode = filterMode;
    return this;
  }
  withPersistence(persistenceId: string, persistenceDurationInMs = 86400000): FilterBoxInitBuilder {
    this._persistenceId = persistenceId;
    this._persistenceDurationInMs = persistenceDurationInMs;
    return this;
  }
  withColumnNamesWithListFilter(columnNamesWithListFilter: string[]): FilterBoxInitBuilder {
    this._columnNamesWithListFilter = columnNamesWithListFilter;
    return this;
  }
  withDateColumnNames(dateColumnNames: string[]): FilterBoxInitBuilder {
    this._dateColumnNames = dateColumnNames;
    return this;
  }
  withListCallback(listCallback: () => any): FilterBoxInitBuilder {
    this._listCallback = listCallback;
    return this;
  }
  build(): FilterBoxInit {
    return new FilterBoxInit(
      this._filterMode,
      this._columnNamesWithListFilter,
      this._dateColumnNames,
      this._listCallback,
      this._persistenceId,
      this._persistenceDurationInMs
    );
  }
}
