import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import { ICustomCell } from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';

@Component({
  selector: 'access-token-date',
  templateUrl: './access-token-date.component.html',
  styleUrls: ['./access-token-date.component.scss'],
  standalone: true,
  imports: [DatePipe],
})
export class AccessTokenDateComponent implements ICustomCell {
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() transform?: (input: string) => string;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;
}
