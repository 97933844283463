import { Type } from '@angular/core';
import { ICustomCellComponent } from './custom-cell-component-interface';
import { FilterBoxConfiguration } from '../filter-box/filter-box-configuration';
import { CellGrid } from './cell-grid';
import { GUIDBuilder } from './guid-builder';
import { GridCallbackParameter } from './grid-callback-parameter';
import { CustomAction } from './custom-action';
import { ICellPlugin } from '../plugins/icell-plugin';
import { QRow } from './q-row';
import { Observable } from 'rxjs';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export enum SortOrder {
  Default = 0,
  Ascending = 1,
  Descending = -1,
}

export enum ColumnType {
  Number = 0,
  String,
  Html,
  Boolean,
  Lookup,
  Any,
  Component,
  Link,
  QGrid,
}

export enum ValueType {
  Key = 0, // This is to be used where a column is identifier for table
  Value,
}

export enum InputRequiredType {
  Optional = 0,
  Required,
}

export enum LookupDisplayType {
  Value = 0,
  Label,
}

export enum PipeType {
  Default = 0,
  Percent,
  Currency,
  Date,
  Time,
  DateTime,
  Decimal,
  DaysOrHours,
}

// https://openmail.atlassian.net/wiki/spaces/QPLT/pages/929660929/Qool-Grid+Configuration
export class ColumnConfiguration {
  static readonly numberColumnIndex = -1000;
  static readonly nA = 'nA';
  static readonly qGridRowSelectorFieldName = 'qGridRowSelectorFieldName';
  readonly GUID: string;
  // The following properties are for JSON dump purpose to have visibility on the field name
  private readonly _fieldNm: string;
  readonly isRowSelector: boolean;
  // The following properties could easily be turned into callback like the following ones as needed.
  visibleColumnNumber: number;
  sortOrder: SortOrder = SortOrder.Default;
  dynamicComponentType: Type<ICustomCellComponent>;
  valueType = ValueType.Value;
  inputRequiredType = InputRequiredType.Optional;
  columnHeaderText = '';
  tooltipText = '';
  cellPlugins: ICellPlugin[] = [];
  filterConfiguration: FilterBoxConfiguration;
  lookupDisplayType = LookupDisplayType.Value;
  pipeType: PipeType = PipeType.Default;
  pipeFormat = '';
  nullSubstitute: string;
  width: number;
  getWidth: () => number;
  resizable = false;
  cellGrid: CellGrid;
  isSticky = false;
  stickyLeftSiblings: ColumnConfiguration[];
  stickyLeft = 0;
  cellLookupsKey: any; //((qCallbackParam: GridCallbackParameter) => string) | string;
  customSort: (
    rowA: QRow,
    rowB: QRow,
    rows: QRow[],
    sortOrder: SortOrder,
  ) => number;
  onCellClick: (event: MouseEvent, data: any) => void;
  isColumnVisible: () => boolean;
  isEditable: ((qCallbackParam: GridCallbackParameter) => boolean) | boolean;
  skipDownload: boolean = false;
  defaultValue: ((qCallbackParam: GridCallbackParameter) => any) | any;
  customCellActions:
    | ((qCallbackParam: GridCallbackParameter) => CustomAction[])
    | CustomAction[];
  defaultSelectedRowCellValue$: Observable<any>;
  enableRowClickEmit: boolean;
  cellCssClass: (row: QRow, rows: QRow[]) => string;
  customFooter: (data: any[]) => any = null;
  filterBoxValue: ((qCallbackParam: GridCallbackParameter) => any) | any;
  protected _ovs = new ObjectValidatorService();

  get requiresCellEditor(): boolean {
    switch (this.columnType) {
      case ColumnType.String:
      case ColumnType.Number:
      case ColumnType.Lookup:
        return true;
      default:
        return false;
    }
  }

  constructor(
    public text: string,
    public fieldName: string,
    public columnType = ColumnType.Number,
  ) {
    this.GUID = GUIDBuilder.build();
    this.columnHeaderText = text;
    this.tooltipText = text;
    this._fieldNm = this.fieldName;
    this.isRowSelector =
      this._fieldNm === ColumnConfiguration.qGridRowSelectorFieldName;
  }
}
