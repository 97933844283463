import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable, filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private _dialog!: MatDialog;
  set(dialog: MatDialog) {
    this._dialog = dialog;
  }

  openDialog(
    component: any,
    config: MatDialogConfig = {},
  ): Observable<any> | undefined {
    if (!this._dialog) {
      return undefined;
    }

    const dialogRef = this._dialog.open(component, {
      width: '350px',
      disableClose: false,
      ...config,
    });

    return dialogRef.afterClosed().pipe(filter(x => !!x));
  }
}
