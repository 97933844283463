<div [class]="(apiCallInProgress$ | async) ? 'gray-loader' : ''">
  <form
    [formGroup]="form"
    (ngSubmit)="submitRequest()"
    [class]="(pixelManagerEditService.inEditMode$ | async) ? 'disabled' : ''">
    <div class="scrollable">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"></formly-form>
    </div>
    <div class="access-token-create-buttons container">
      <button mat-button type="submit" [disabled]="!canSubmit()" class="access-token-create-buttons submit">
        Complete Setup
      </button>
    </div>
  </form>
</div>
