<ng-container>
  <div class="centered-with-top-header">
    <frontend-grid [model]="gridModel$|ngrxPush"
      [columns]="columns"
      [editable]="false"
      [showOptionsBar]="true"
      [showRefreshButton]="true"
      (refreshEvent)="refresh()"
      (pageChange)="pageChanged($event)"
      (sortEvent)="sortChanged($event)"
      [useExternalDownload]="true"
      [downloadFileType]="downloadFileType"
      [rowInfo]="domainListColumnsBuilderService.rowInfo"
      [pageSize]="20"
      (downloadAllEvent)="downloadAll()"
      ></frontend-grid>
  </div>
</ng-container>
