import { Injectable } from '@angular/core';

@Injectable()
export class ClipboardDataConversionService {
  textToTable(text: string, colDelimiter: string, rowDelimiter: string): string[][] {
    const _carriageReturn = '\r';
    const _lineFeed = '\n';
    const _defalutRowDelimiter = _carriageReturn + _lineFeed;
    const rows: string[][] = [];
    if (text === '') {
      return rows;
    }
    // find the used row delimiter, if the default rowDelimiter is used
    if (rowDelimiter === _defalutRowDelimiter) {
      rowDelimiter = text.includes(_carriageReturn) ? _carriageReturn : _lineFeed;
      // clear extra row delimiter
      text = text.includes(_carriageReturn)
        ? text.replace(_lineFeed, '')
        : text.replace(_carriageReturn, '');
    }

    // loop through lines and split columns
    text.split(rowDelimiter).forEach(row => rows.push(row.split(colDelimiter)));
    return rows; // return output matrix (table)
  }
}
