import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { BasePageComponent, ConfigService, TokenCacheService } from '@nimbus/shared-lib';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';
import { createRoot } from 'react-dom/client';
import { EmbeddedWrapper } from './embedded-wrapper';
import { catchError, EMPTY, from, Observable, tap } from 'rxjs';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

declare global {
  interface Window {
    retool: any;
  }
}

@Component({
  selector: 'app-embedded',
  templateUrl: './embedded.component.html',
  styleUrls: ['./embedded.component.scss'],
  imports: [CommonModule],
  standalone: true,
})
export class EmbeddedComponent
  extends BasePageComponent
  implements AfterViewInit, OnDestroy, OnInit {
  _userEmailFromOkta?: string;
  urlLoaded = false;
  retoolBaseUrl = this._configService.getApi('RetoolEmbedAPI').host;
  accessToken: string | undefined;

  get containerId(): string {
    return this.route.snapshot.data.containerId;
  }

  get pageUuid(): string {
    const containerId = this.route.snapshot.data.containerId;
    return this._configService.getEmbedIds(containerId).pageUuid;
  }
  get groupId(): number {
    const containerId = this.route.snapshot.data.containerId;
    return this._configService.getEmbedIds(containerId).groupId;
  }

  get userEmail(): string {
    return this._tokenCacheService.tokenResponse.email;
  }

  get userFirstName(): string {
    const fullName = this._tokenCacheService.tokenResponse.fullName;
    return fullName.split(' ')[0];
  }

  get userLastName(): string {
    const fullNameParts = this._tokenCacheService.tokenResponse.fullName.split(' ');
    return fullNameParts.length > 1 ? fullNameParts[1] : '';
  }

  get nimbusApiKey(): string {
    return this._configService.getNimbusApiKey();
  }

  constructor(
    public route: ActivatedRoute,
    private _tokenCacheService: TokenCacheService,
    private _configService: ConfigService,
    private _snackBarService: SnackbarService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
  ) {
    super();
  }

  getEmbeddedUrl(pageUuid: string, groupId: number, userFirstName: string, userLastName: string, userEmail: string): Observable<string> {
    return this._webServiceBuilderService.builder
      .withApi('RetoolEmbedAPI')
      .withUrl('v1/retool/embed-url')
      .build()
      .create({
        pageUuid,
        groupId,
        userFirstName,
        userLastName,
        userEmail
      })
  }

  async ngOnInit() {
     this.accessToken = await this._oktaAuth.getAccessToken();
  }

  ngAfterViewInit(): void {
    this._subscriptionManager.register(
      this.getEmbeddedUrl(
        this.pageUuid,
        this.groupId,
        this.userFirstName,
        this.userLastName,
        this.userEmail
      ).pipe(
        tap(embedUrl => createRoot(document.getElementById(this.containerId)).render(EmbeddedWrapper({ embedUrl, data: {oktaToken: this.accessToken} }))),
        tap(() => {
          this.urlLoaded = true;
          this._changeDetectorRef.markForCheck();
          this._changeDetectorRef.detectChanges();
        }),
        catchError(() => {
            this.urlLoaded = true;
            this._snackBarService.open('An error occurred while loading the external tool. Please try again later.', SnackBarType.error);
            this._changeDetectorRef.markForCheck();
            this._changeDetectorRef.detectChanges();
            return EMPTY;
          }
        ),
      ).subscribe()
    );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
