import { Injectable } from '@angular/core';
import { SubjectManager, SubjectType } from 'core/libs/global/frontend/subscription-manager/src';
import { Observable } from 'rxjs';

/**
 * ActionBarService:
 * The formly action type sends it notification to the ActionService to
 * which it would first have registered using a uniq string.
 *
 * The service must be provided by the closest module that holds the scope containing
 * both form and the ActionService client.
 *
 * The client would subscirbe to onUpdate$ which gets triggered everytime a new sender
 * registers to the service. Upon desired action having been registered, the client can then
 * subscribe to the events emmited for the disered action.
 */
@Injectable()
export class ActionService<T> {
  private _stringSubjectManager = new SubjectManager<string>();
  private _actionSubjectManager = new SubjectManager<T>();
  onUpdate$: Observable<string>;

  constructor() {
    this._stringSubjectManager.register('onUpdate', SubjectType.Subject);
    this.onUpdate$ = this._stringSubjectManager.get('onUpdate');
  }

  register(action: string) {
    this._actionSubjectManager.register(action, SubjectType.Subject);
    this._stringSubjectManager.next('onUpdate', action);
  }

  get(action: string): Observable<T> {
    return this._actionSubjectManager.get<T>(action);
  }

  next(action: string, params?: T) {
    this._actionSubjectManager.next(action, params);
  }
}
