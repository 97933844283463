import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function AllowedCharactersValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: any = {}
): ValidationErrors {
  let strRegex = new RegExp(options.regex ?? /^[a-z0-9]+$/i);
  return strRegex.test(control.value) ? {} : { 'allowed-characters-validator': true };
}
