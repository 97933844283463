<mat-menu
  #menu="matMenu"
  [overlapTrigger]="false"
  *ngIf="siteMapNode?.siteMapNodes"
>
  <ng-container *ngFor="let siteMapNode of siteMapNode.siteMapNodes">
    <ng-container *ngIf="!ovs.isNullOrEmpty(siteMapNode?.siteMapNodes)">
      <button mat-menu-item [matMenuTriggerFor]="subMenu.menu">
        {{ siteMapNode.label }}
        <svg
          class="menu-icon"
          width="11"
          height="12"
          viewBox="0 0 11 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M5,3 l3,3 -3,3" stroke="#426bf3" stroke-width="1.25" />
        </svg>
      </button>
      <frontend-sub-menu
        #subMenu
        [siteMapNode]="siteMapNode"
      ></frontend-sub-menu>
    </ng-container>
    <ng-container *ngIf="ovs.isNullOrEmpty(siteMapNode?.siteMapNodes)">
      <a
        *ngIf="siteMapNode['path'] && path !== siteMapNode['path']"
        mat-menu-item
        [routerLink]="siteMapNode['path']"
        >{{ siteMapNode.label }}</a
      >
      <span
        *ngIf="siteMapNode['path'] && path === siteMapNode['path']"
        mat-menu-item
        [routerLink]="siteMapNode['path']"
        class="deactivated-link"
        >{{ siteMapNode.label }}</span
      >
      <a mat-menu-item *ngIf="siteMapNode['url']" [href]="siteMapNode['url']">{{
        siteMapNode.label
      }}</a>
    </ng-container>
  </ng-container>
</mat-menu>
