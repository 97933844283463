import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from '../../../../core/object-validator-service/object-validator.service';
import { DockContent } from '../dock-content';
import { IDockable } from '../idockable';

@Component({
  selector: 'lib-dock',
  templateUrl: './dock.component.html',
  styleUrls: ['./dock.component.scss']
})
export class DockComponent implements OnDestroy, OnInit, OnChanges {
  @ViewChild('bottomDockContainer', { read: ViewContainerRef, static: true })
  private _bottomDockContainer: ViewContainerRef;
  private _dockContent: DockContent;
  get dockContent(): DockContent {
    return this._dockContent;
  }
  @Input() set dockContent(value: DockContent) {
    this._dockContent = value;
  }
  private readonly _subscriptionManager = new SubscriptionManager();
  visibleDockable: IDockable;
  @Input() visibleDockableIndex = -1;
  menuTitles: string[] = [];
  private _isInitialized = false;
  private _model: any;
  @Output() modelChange = new EventEmitter<any>();
  @Output() modelUpdate = new EventEmitter<any>();
  @Input() set model(model: any) {
    if (!this.ovs.isDefined(model)) {
      return;
    }
    this._model = model;
    if (!this._isInitialized) {
      return;
    }
    this.createDockable(model);
  }
  get model(): any {
    return this._model;
  }

  constructor(
    public ovs: ObjectValidatorService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngOnInit(): void {
    this.menuTitles = this.dockContent.dockableItems.map(dockableItem => dockableItem.title);
    this._isInitialized = true;
    this.createDockable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['visibleDockableIndex']) {
      this.visibleDockableIndex = changes['visibleDockableIndex'].currentValue;
      this.createDockable();
    }
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  createDockable(model: any = null, index: number = -1) {
    if (this.ovs.isDefined(model)) {
      this._model = model;
    }
    if (
      !this._isInitialized ||
      !this.ovs.isDefined(this._model) ||
      !this.ovs.isDefined(this._bottomDockContainer)
    ) {
      return;
    }
    this._bottomDockContainer.clear();

    if (this.visibleDockableIndex === -1) {
      this.visibleDockableIndex = 0;
    }

    if (index !== -1) {
      this.visibleDockableIndex = index;
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.dockContent.dockableItems[this.visibleDockableIndex].componentClass as Type<Component>
    );

    this.visibleDockable = this._bottomDockContainer.createComponent(componentFactory)
      .instance as IDockable;
    this.visibleDockable.model = this._model;
    this.visibleDockable.tabIndex = this.visibleDockableIndex;
    this.visibleDockable.title = this.dockContent.dockableItems.map(
      dockableItem => dockableItem.title
    )[this.visibleDockableIndex];
    this._subscriptionManager.clear();
    if (this.ovs.isDefined(this.visibleDockable.modelChange)) {
      this._subscriptionManager.register(
        this.visibleDockable.modelChange.subscribe(submitedModel => {
          this.modelChange.emit(submitedModel);
          this.modelUpdate.emit(submitedModel);
        })
      );
    }
  }

  menuClick(item: string) {
    this.visibleDockableIndex = this.menuTitles.findIndex(title => title === item);
    this.createDockable();
  }

  childrenKey(index: number): string {
    return this.menuTitles[index];
  }
}
