import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NavigatorComponent } from './navigator.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AngularMaterialModule } from '../../../core/angular-material.module';
import { NavigationModule } from '../navigation.module';
import { ImpersonationModule } from '../../impersonation/impersonation.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    AngularMaterialModule,
    MatSlideToggleModule,
    NavigationModule,
    ImpersonationModule
  ],
  exports: [NavigatorComponent],
  declarations: [NavigatorComponent]
})
export class NavigatorModule {}
