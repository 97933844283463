export class LocalStorageKey {
  keyVersion = '2.0.0';

  constructor(public modelStructureIndicator: string, public keyName: string) {
    this.modelStructureIndicator = modelStructureIndicator;
    this.keyName = keyName;
  }

  getString() {
    return JSON.stringify(this);
  }

  equals(key: LocalStorageKey): boolean {
    return (
      this.keyName === key.keyName &&
      this.modelStructureIndicator === key.modelStructureIndicator &&
      this.keyVersion === key.keyVersion
    );
  }
}
