import { Component } from '@angular/core';

@Component({
  selector: 'app-sem',
  templateUrl: './sem.component.html',
  styleUrls: ['./sem.component.scss', '../documentation.scss']
})
export class SemComponent {
  prohibitedKeywords = [
    'government check',
    'irs stimulus form',
    'stimulus',
    'irs stimulus',
    'coronavirus stimulus',
    'government money',
    'stimulus money',
    'where is my stimulus',
    'where is my stimulus check',
    'covid stimulus check',
    'claim stimulus money',
    'covid stimulus',
    'coronavirus money',
    'where is my coronavirus money',
    'unemployment money',
    'where is my covid money',
    'eviction notice',
    'where is my government money',
    'eviction',
    'stimulus check'
  ];
  constructor() {}
}
