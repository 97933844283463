import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AngularMaterialModule } from "core-global-frontend-angular-material";

@Component({
  selector: "nimbus-syndication-welcome-to-new-ux",
  templateUrl: "./syndication-welcome-to-new-ux.component.html",
  styleUrls: ["./syndication-welcome-to-new-ux.component.scss"],
  imports: [AngularMaterialModule, MatDialogModule, CommonModule],
  standalone: true,
})
export class SyndicationWelcomeToNewUXComponent {
  static resource = 'splash-screen';

  constructor(private _router: Router) { }

  navigate(route: string) {
    this._router.navigate([route]);
  }
}
