import { Directive, OnInit, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { AccessControlService } from './access-control.service';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[appAccessControl]'
})
export class AccessControlDirective implements OnInit {
  private _featureName: string;
  @Input() set appAccessControl(featureName: string) {
    this._featureName = featureName;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private _accessControlService: AccessControlService,
    private _ovs: ObjectValidatorService
  ) { }

  ngOnInit() {
    const elementName = this.templateRef.elementRef.nativeElement.data.split(':')[1];
    if (this._accessControlService
      .canAccessBySelector(
        this._ovs.isNullOrEmpty(this._featureName) ? elementName : this._featureName
      )) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
