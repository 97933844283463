<div class="centered-with-top-header">
  <div class="filter-container">
    <div class="header form-header">Enter the Page URL you wish to preview.</div>
    <p>You can also enter the subdomain being used for the SERP. Leave empty to use the default subdomain.</p>
    <form [formGroup]="form" (ngSubmit)="preview()">
      <formly-form [fields]="fields" [form]="form" [model]="model" (modelChange)="output()"></formly-form>
    </form>
  </div>
  <div class="header">
    Preview:
  </div>
  <div #ads class="rsoc-preview-container">
    <div id="rampjs_slot1"></div>
    <div id="rampjs_slot2"></div>
    <div id="rsonc_widget"></div>
    <div id="rsonc_widget_example_2"></div>
  </div>
  <div class="header">
    Code Sample:
  </div>
  <pre><code #code></code></pre>
  <div class="copy-code-button-container">
    <button type="button" (click)="copy()"><svg role="img"><use xlink:href="assets/img/sprites.svg#copy-icon"></use></svg>Copy Code</button>
  </div>
  <p><br />To see the ads populate on your site, you&apos;ll also need to insert empty div placeholders on the page wherever you want each RSoC ad to render. Each div must have a unique id, and ids must follow the naming convention: rampjs_slot1, rampjs_slot2.</p>
  <pre><code>  
    &lt;div id=&quot;rampjs_slot1&quot;&gt;&lt;/div&gt;
    ...
    &lt;div id=&quot;rampjs_slot2&quot;&gt;&lt;/div&gt;
  </code></pre>
  <p>Please review the RSoC Partner Documentation, or reach out to your account manager if you have any questions.</p>
</div>