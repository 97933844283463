import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConfigService } from '@nimbus/shared-lib';

// Reference external gta function
// eslint-disable-next-line no-var
declare var gtag: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  constructor(public router: Router, private config: ConfigService) {
    if (config.getGoogleTag()) {
      // Append google tag manager script
      const scriptImport = document.createElement('script');
      scriptImport.async = true;
      scriptImport.src = 'https://www.googletagmanager.com/gtag/js?id=' + config.getGoogleTag();
      document.head.prepend(scriptImport);

      const script = document.createElement('script');
      script.text = ` window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date());
                            gtag('config', '${config.getGoogleTag()}');`;
      document.head.prepend(script);

      // Listen to navigation end events
      const navEndEvents = router.events.pipe(filter(event => event instanceof NavigationEnd));
      navEndEvents.subscribe((event: NavigationEnd) => {
        gtag('config', config.getGoogleTag(), { page_path: event.urlAfterRedirects });
      });
    }
  }
}
