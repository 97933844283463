import { Sort } from '@angular/material/sort';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { Injectable } from '@angular/core';
import { ColumnInfo } from './grid/column-info';

@Injectable({ providedIn: 'root' })
export class GridSortingService {
  constructor(private _ovs: ObjectValidatorService) {}

  sort(model: any[], sort: Sort, _columns: ColumnInfo[]): any {
    if (sort === undefined || sort.active === '') {
      return model;
    }
    return model.sort((a, b) => {
      let isAsc = sort.direction === 'asc';
      let aVal = a[sort.active];
      let bVal = b[sort.active];

      if (this._ovs.isString(aVal) && this._ovs.isString(bVal)) {
        const aDate = this._ovs.isValidDate(aVal);
        if (aDate) {
          const bDate = this._ovs.isValidDate(bVal);
          if (bDate) {
            return isAsc
              ? new Date(aVal).getTime() - new Date(bVal).getTime()
              : new Date(bVal).getTime() - new Date(aVal).getTime();
          }
        }
        return isAsc ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
      }
      return isAsc ? aVal === bVal : bVal !== aVal;
    });
  }
}
