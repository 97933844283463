import { Component, NgModule } from '@angular/core';
import { StaticTextCellComponent } from './cells/static-text-cell/static-text-cell.component';
import { GridCellComponentMapperService } from './grid-cell-component-mapper.service';
import { InputTextCellComponent } from './cells/input-text-cell/input-text-cell.component';
import { CheckboxCellComponent } from './cells/checkbox-cell/checkbox-cell.component';
import { ButtonCellComponent } from './cells/button-cell/button-cell.component';
import { LinkCellComponent } from './cells/link-cell/link-cell.component';
import { ClipboardEnabledTextCellComponent } from './cells/clipboard-enabled-text-cell/clipboard-enabled-text-cell.component';
import { StatusCellComponent } from './cells/status-cell/status-cell.component';

@NgModule({})
export class GlobalFrontendGridModule {
  constructor(_gridCellComponentMapperService: GridCellComponentMapperService) {
    _gridCellComponentMapperService.registerComponent(
      'frontend-static-text-cell',
      StaticTextCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'frontend-input-text-cell',
      InputTextCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'frontend-checkbox-cell',
      CheckboxCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'frontend-button-cell',
      ButtonCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'frontend-link-cell',
      LinkCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'frontend-clipboard-enabled-text-cell',
      ClipboardEnabledTextCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'frontend-status-cell',
      StatusCellComponent as Component,
    );
  }
}
