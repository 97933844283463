import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { FormFieldConfig } from '../../form/form-field-config';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { StringHelper } from '../../core/string-helper';

@Injectable()
export class FormlyFiltersHelper {
  constructor(private _ovs: ObjectValidatorService, private _stringHelper: StringHelper) {}

  formInputFields(formFields$: Observable<FormFieldConfig[]>): Observable<FormFieldConfig[]> {
    return this.formPrefilterFields(formFields$).pipe(
      map(fields => fields.filter(field => !field.type.endsWith('action-button')))
    );
  }

  formPrefilterFields(formFields$: Observable<FormFieldConfig[]>): Observable<FormFieldConfig[]> {
    return formFields$.pipe(
      map(formFields =>
        formFields.filter(
          field =>
            this._ovs.isNullOrEmpty(['version', 'idKeys'].find(item => item === field.key)) &&
            this._ovs.isDefined(field.type) &&
            ((!this._ovs.isNullOrEmpty(field.key) && this._ovs.isDefined(field.defaultValue)) ||
              field.type.endsWith('action-button') ||
              field.type.endsWith('-filter'))
        )
      ),
      shareReplay(1)
    );
  }

  toParamsObject(formFieldConfigs: Observable<FormFieldConfig[]>): Observable<any> {
    return formFieldConfigs.pipe(
      map(fields =>
        fields.reduce((previous, field) => {
          previous[field.key] = field.defaultValue;
          return previous;
        }, {})
      ),
      shareReplay(1)
    );
  }

  entityIdsField(formFields$: Observable<FormFieldConfig[]>): Observable<string[]> {
    return formFields$.pipe(
      map(fields => {
        const idField = fields.find(
          field => !this._ovs.isPathNullOrEmpty(field, ['key']) && field.key === 'idKeys'
        );
        return this._ovs.isDefined(idField) ? idField.defaultValue : [];
      }),
      shareReplay(1)
    );
  }

  hasNoneInputField(fields$: Observable<FormFieldConfig[]>): Observable<boolean> {
    return fields$.pipe(
      map(fields =>
        fields.map(field => this._ovs.isNullOrEmpty(field.key) && this._ovs.isDefined(field.type))
      ),
      map(fields => !this._ovs.isNullOrEmpty(fields))
    );
  }

  hasInputs(fields$: Observable<FormFieldConfig[]>): Observable<boolean> {
    return fields$.pipe(
      map(fields =>
        fields.filter(field => this._ovs.isDefined(field.key) && this._ovs.isDefined(field.type))
      ),
      map(fields => !this._ovs.isNullOrEmpty(fields))
    );
  }

  showClear(fields$: Observable<FormFieldConfig[]>): Observable<boolean> {
    return fields$.pipe(
      map(fields =>
        fields.filter(
          field =>
            this._ovs.isDefined(field.key) &&
            !this._ovs.isDefined(field.type) &&
            field.key === 'version' &&
            !this._ovs.isPathNullOrEmpty(field, ['templateOptions', 'clearable']) //&&
            //this._ovs.isTrue(field.pr .props['clearable'])
        )
      ),
      map(fields => !this._ovs.isNullOrEmpty(fields))
    );
  }

  singularize(text) {
    return text.lastIndexOf('s') === text.length - 1 ? text.substring(0, text.length - 1) : text;
  }

  snakeToSpacing(title: string): string {
    return this._stringHelper.substitute(title, '-', ' ');
  }
}
