import { Component, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';

import { concat, Observable } from 'rxjs';
import { tap, map, filter, mergeAll, delay, switchMap, shareReplay } from 'rxjs/operators';
import { GuidGenerator } from '../../../core/guid-generator';
import { SubjectManager } from '../../../core/subject-manager';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { FormlyFiltersHelper } from '../formly-filters-helper';
import { LayoutPageServicesProxy } from '../layout-page-services-proxy';
import { PreFiltersService } from '../pre-filters.service';
import { Store } from '@ngrx/store';
import { GlobalState } from '../../../store/global-state';
import { FormsSelectors } from '../../../store/forms/selectors';

@Component({
  selector: 'app-form-pre-filters',
  templateUrl: './form-pre-filters.component.html',
  styleUrls: ['./form-pre-filters.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormPreFiltersComponent implements AfterViewInit, OnDestroy {
  private readonly _subjectManager = new SubjectManager();
  private _subscriptionManager = new SubscriptionManager();
  baseRoute: string;
  form = new UntypedFormGroup({});
  prefiltersfields$!: Observable<FormlyFieldConfig[]>;
  prefilters: any;
  private _prefilters$!: Observable<unknown>;
  isPrefiltersEnabled$!: Observable<boolean>;
  hasNoneInputField$: Observable<boolean>;
  page: string;
  area: string;
  isHavingAddPage: boolean;
  showingRefresh = true;
  buttonsEnabled$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  _guidGenerator = new GuidGenerator();
  showClear$: Observable<boolean>;
  titleLabel$!: Observable<string>;
  titleSubLabel$!: Observable<string>;

  constructor(
    public router: Router,
    private _formlyFiltersHelper: FormlyFiltersHelper,
    private _preFiltersService: PreFiltersService,
    public ovs: ObjectValidatorService,
    private _store: Store<GlobalState>,
    private _layoutPageServicesProxy: LayoutPageServicesProxy
  ) {
    this._subjectManager.registerMultiple(['isLoading', 'submit', 'reset', 'clear']);
    this.page = this.router.routerState.snapshot.url.split('/')[2].split('?')[0];
    this.area = this.router.routerState.snapshot.url.split('/')[1].split('?')[0];
    this.baseRoute = `${this.area}/${this.page}`;
    this.isLoading$ = this._isLoading();
  }

  ngOnDestroy(): void {
    this._subjectManager.clear();
    this._subscriptionManager.clear();
  }

  ngAfterViewInit(): void {
    const allFields$ = this._store.select(FormsSelectors.getForms).pipe(
      filter(forms => !!forms),
      map(
        forms =>
          forms?.find(form => form.formName === 'PreFilters' && form.route === this.baseRoute)
            ?.formFields ?? '[]'
      ),
      map(formFields => JSON.parse(formFields)),
      shareReplay(1)
    );

    this.titleLabel$ = allFields$.pipe(
      map(allFields => allFields.find(value => value.key === 'label')),
      filter(field => this.ovs.isDefined(field)),
      map(field => field.defaultValue[0])
    );

    this.titleSubLabel$ = allFields$.pipe(
      map(allFields => allFields.find(value => value.key === 'subLabel')),
      filter(field => this.ovs.isDefined(field)),
      map(field => field.defaultValue[0])
    );

    this.prefiltersfields$ = this._preFiltersService.addOptionalAddEntityPage(
      this._formlyFiltersHelper.formPrefilterFields(allFields$),
      this.router.config,
      this.baseRoute,
      this.area,
      this.page
    );
    this.showClear$ = this._formlyFiltersHelper.showClear(allFields$);
    this.buttonsEnabled$ = this._formlyFiltersHelper.hasInputs(allFields$);
    this.hasNoneInputField$ = this._formlyFiltersHelper.hasNoneInputField(allFields$);
    this._prefilters$ = this._concatenatedFieldsSources(this.prefiltersfields$);
    this.isPrefiltersEnabled$ = this._prefilters$.pipe(
      map(prefilters => Object.keys(prefilters).length > 0)
    );

    this._subscriptionManager.registerMultiple([
      this._subjectManager
        .getObservable('submit')
        .subscribe(_prefilters => this._submit(_prefilters)),
      this._clearFilterHandler()
    ]);
  }

  submit() {
    this._subjectManager.next('submit', this.prefilters);
  }

  reset() {
    this._subjectManager.next(
      'reset',
      this.router.parseUrl(this.router.routerState.snapshot.url).queryParams
    );
  }

  clear() {
    this._subjectManager.next('clear');
  }

  private _disableSubmitFor(delay: number) {
    this._subjectManager.next('isLoading', true);
    setTimeout(() => this._subjectManager.next('isLoading', false), delay);
  }

  private _submit(_prefilters: any) {
    const params = _prefilters ?? {};
    if (this.showingRefresh) {
      params['forceLoad'] = this._guidGenerator.newGuid();
    }
    this.form.markAsPristine();
    if (!this.ovs.isDefined(this._layoutPageServicesProxy.entitiesLoaders[this.baseRoute])) {
      this._disableSubmitFor(2000);
    }
    this.showingRefresh = true;
    this.router.navigate([this.baseRoute], {
      queryParams: params,
      queryParamsHandling: 'merge'
    });
  }

  private _isLoading(): Observable<boolean> {
    return (
      this.ovs.isDefined(this._layoutPageServicesProxy.entitiesLoaders[this.baseRoute])
        ? this._layoutPageServicesProxy.entitiesLoaders[this.baseRoute].apiCallInProgress$
        : this._subjectManager.getObservable('isLoading')
    ).pipe(map(isLoadingEvent => this.ovs.isTrue(isLoadingEvent)));
  }

  private _clearFilterHandler() {
    return this._subjectManager
      .getObservable('clear')
      .pipe(
        switchMap(() =>
          this._formlyFiltersHelper.toParamsObject(
            this._formlyFiltersHelper.formPrefilterFields(
              this._store.select(FormsSelectors.getForms).pipe(
                filter(forms => !!forms),
                map(
                  forms =>
                    forms?.find(
                      form => form.formName === 'PreFilters' && form.route === this.baseRoute
                    )?.formFields ?? '[]'
                ),
                map(formFields => JSON.parse(formFields))
              )
            )
          )
        )
      )
      .subscribe(prefilters => {
        this.form.markAsPristine();
        this.showingRefresh = false;
        this.prefilters = prefilters;
        this.router.navigate([this.baseRoute], {
          queryParams: prefilters,
          queryParamsHandling: 'merge'
        });
      });
  }

  private _concatenatedFieldsSources(
    prefiltersfields$: Observable<FormlyFieldConfig[]>
  ): Observable<any> {
    // Waiting for data to be loaded and an extra buffer for the base page to correctly redictect to a populated url.
    return concat([
      prefiltersfields$.pipe(
        delay(1000),
        map(() => this.router.parseUrl(this.router.routerState.snapshot.url).queryParams),
        filter(
          prefilters =>
            !this.ovs.isNullOrEmpty(prefilters) && !this.ovs.isNullOrEmpty(Object.keys(prefilters))
        ),
        tap(prefilters => (this.prefilters = prefilters))
      ),
      this._subjectManager.getObservable('reset').pipe(
        tap(prefilters => {
          this.prefilters = prefilters;
          this.form.markAsPristine();
          this.showingRefresh = false;
        })
      )
    ]).pipe(mergeAll());
  }
}
