import { createAction, props } from '@ngrx/store';
import { Form } from './form';

export class FormsActions {
  // Get forms
  static readonly formsRetrieving = createAction('[Forms] Fetch', props<{ params: any }>());
  static readonly formsRetrieved = createAction('[Forms] Fetched', props<{ forms: Form[] }>());
  static readonly formsRetrieveFailed = createAction('[Forms] Fetch Error', props<{ error: any }>());

  // Save Form
  static readonly formSaving = createAction(
    '[Form Edit Dock] Save Requested',
    props<{ form: Form }>()
  );
  static readonly formSaved = createAction('[Form Edit Dock] Saved', props<{ form: Form }>());
  static readonly formSavedFailed = createAction(
    '[Form Edit Dock] Saved Error',
    props<{ error: any }>()
  );

  // Create Form
  static readonly formCreating = createAction(
    '[Form Add Page] Create Requested',
    props<{ form: Form }>()
  );
  static readonly formCreated = createAction('[Form Add Page] Created', props<{ form: Form }>());
  static readonly formCreatedFailed = createAction(
    '[Form Add Page] Created Error',
    props<{ error: any }>()
  );
}
