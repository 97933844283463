import { Component, ViewEncapsulation, OnDestroy, OnInit, Inject } from '@angular/core';
import {
  TokenCacheService,
  LocalStorageKeyFactory,
  AccessControlService,
  LocalStorageManagerService
} from '@nimbus/shared-lib';
import { InitializerService } from './shared/initializer/initializer.service';
import { FeedbackComponent } from './shared/feedback/feedback.component';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, filter, map, of, switchMap } from 'rxjs';
import { environment } from '../environments/environment';
import { SiteMapAccessFilter } from '../site-map-access-filter';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { Title } from '@angular/platform-browser';
import { siteMap } from '../site-map';
import { ImpersonationTrackerService } from './shared/services/impersonation-tracker.service';
import { GoogleAnalyticsService } from './shared/services/google-analytics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  protected readonly GenericInputComponent = FeedbackComponent;
  isDebug$ = this._isDebugEnabled();
  environment: any = environment;
  appVersion = environment.appVersion;
  buildVersion = environment.buildVersion;
  private _subscriptionManager = new SubscriptionManager();
  menu$ = this._siteMapAccessFilter.allowedMenuItems(siteMap);
  siteMapLocal = siteMap;

  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    _initializerService: InitializerService,
    public tokenCacheService: TokenCacheService,
    public accessControlService: AccessControlService,
    private _impersonationTrackerService: ImpersonationTrackerService,
    private _activedRoute: ActivatedRoute,
    public _siteMapAccessFilter: SiteMapAccessFilter,
    _titleService: Title,
    private _localStorageManagerService: LocalStorageManagerService,
    private _localStorageKeyFactory: LocalStorageKeyFactory,
    private  _googleAnalyticsService: GoogleAnalyticsService,
    private _router: Router
  ) {
    _titleService.setTitle(environment.applicationName + environment.production ? '' : ' - ' + environment.name);
  }

  ngOnInit(): void {
    this._checkVersioning();
    this._impersonationTrackerService.init();
    this._oktaAuth.start();
  }

  private _checkVersioning() {
    const bVersion = this._localStorageManagerService.getKey(this._localStorageKeyFactory.build('app-version', this.buildVersion));
    const needsClearing = !!bVersion && this.buildVersion !== bVersion;

    if (needsClearing) {
      this._localStorageManagerService.clearAll();
      this._oktaAuth.clearStorage();
      this._oktaAuth.closeSession();
      this._oktaAuth.signOut();
    }

    this._localStorageManagerService.setKey(this._localStorageKeyFactory.build('app-version', this.buildVersion), this.buildVersion);

    if (needsClearing) {
      this._router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    this._oktaAuth.stop();
    this._subscriptionManager.clear();
  }

  private _isDebugEnabled(): Observable<boolean> {
    return this._activedRoute.queryParams.pipe(
      filter(params => params['debug']),
      switchMap(params => params['debug'] === 'true' ? this.accessControlService.validAccess$ : of(false)),
      map(validAccess => validAccess && this.accessControlService.canAccessBySelector('app-debug-bar'))
    );
  }
}
