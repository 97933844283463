import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { PendingChangesModel } from '../models/pending-changes.model';

// Service should be scoped to the module that holds the grid-page reference.
// Still provided in root so if a page doesn't care about change tracking, it can just ignore it.
@Injectable()
export class PendingChangesTracker {
  private _hasPendingChanges = false;
  readonly onChange: ReplaySubject<PendingChangesModel>;
  get hasPendingChanges(): boolean {
    return this._hasPendingChanges;
  }

  constructor() {
    this.onChange = new ReplaySubject<PendingChangesModel>();
    this.onChange.subscribe((pendingChanges: PendingChangesModel) => {
      this._hasPendingChanges = pendingChanges.hasPendingChanges;
    });
  }

  clear() {
    this._hasPendingChanges = false;
  }
}
