import { Injectable } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SiteMapNode } from 'core-global-frontend-navigation';
import { AccessControlService } from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SiteMapAccessFilter {
  constructor(
    public accessControlService: AccessControlService,
    private _ovs: ObjectValidatorService,
  ) {}

  filterUnAllowed(siteMapNodes: SiteMapNode[]): Observable<SiteMapNode[]> {
    return this.accessControlService.validAccess$.pipe(
      map(() => this._filter(siteMapNodes)),
    );
  }

  allowedMenuItems(siteMapNodes: SiteMapNode[]): Observable<SiteMapNode[]> {
    return this.accessControlService.validAccess$.pipe(
      map(() => this._filter(siteMapNodes, true)),
    );
  }

  private _filter(siteMapNodes: SiteMapNode[], forMenu = false): SiteMapNode[] {
    return siteMapNodes
      .filter(siteMapNode => siteMapNode.label)
      .filter(siteMapNode => !forMenu || siteMapNode.showInNav !== false)
      .reduce(
        (visibleNodes: SiteMapNode[], node: SiteMapNode) =>
          this._setupNode(node, forMenu, visibleNodes),
        [] as SiteMapNode[],
      );
  }

  private _setupNode(
    node: SiteMapNode,
    forMenu: boolean,
    visibleNodes: SiteMapNode[],
  ) {
    if (
      (this._ovs.isNullOrEmpty(node.resource) ||
        (node.label &&
          this.accessControlService.canAccessByResource(node.resource))) &&
      (!environment.production || !node.stageOnly)
    ) {
      const filteredSubItems = node.siteMapNodes
        ? this._filter(node.siteMapNodes, forMenu)
        : [];
      // If the node has none visible children but has none visible ones, we'll redirect to the first visible child
      if (
        this._ovs.isNullOrEmpty(filteredSubItems) &&
        !this._ovs.isNullOrEmpty(node.siteMapNodes)
      ) {
        node.path = node.siteMapNodes.find(subNode =>
          this.accessControlService.canAccessByResource(subNode.resource),
        )?.path;
      }
      visibleNodes.push({ ...node, siteMapNodes: filteredSubItems });
    }
    return visibleNodes;
  }
}
