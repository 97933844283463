<div [class]="(isDeleteAllowed$ | async) === false ? 'container view-only' : 'container'">
  <div title="view subvertical history">
    <svg role="img" (click)="$event.stopPropagation(); view()">
      <use xlink:href="assets/img/sprites.svg#eye-icon" />
    </svg>
  </div>
  <ng-container *ngIf="(isDeleteAllowed$ | async) === true">
    <div title="delete subvertical request">
      <svg role="img" class="remove-icon" (click)="$event.stopPropagation(); delete()">
        <use xlink:href="assets/img/sprites.svg#partner-trash-icon" />
      </svg>
    </div>
  </ng-container>
</div>
