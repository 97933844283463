import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PixelAccessTokenModel } from '../models/pixel-access-token.model';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';

@Injectable({
  providedIn: 'root',
})
export class PixelManagerWebService {
  constructor(private _webServiceBuilderService: NimbusWebServiceBuilderService) { }

  createPixelAccessToken(
    pixelAccessTokenModel: PixelAccessTokenModel,
  ): Observable<any> {
    return this._webServiceBuilderService.builder
      .withUrl(`pixels/${pixelAccessTokenModel.email}/accesstokens`)
      .build()
      .create(pixelAccessTokenModel);
  }

  getPixelAccessTokens(email: string): Observable<any> {
    return this._webServiceBuilderService.builder
      .withUrl(`pixels/${email}/accesstokens`)
      .build()
      .get({});
  }

  updatePixelAccessToken(
    pixelAccessTokenModel: PixelAccessTokenModel,
  ): Observable<any> {
    return this._webServiceBuilderService.builder
      .withUrl(
        `pixels/${pixelAccessTokenModel.email}/accesstokens/${pixelAccessTokenModel.id}`,
      )
      .build()
      .save(pixelAccessTokenModel);
  }
}
