import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormContainerComponent } from './containers/form-container/form-container.component';
import { RepeatSectionComponent } from './types/repeat-section/repeat-section.component';
import { ChildSectionComponent } from './wrappers/child-section/child-section.component';
import { StepperSectionComponent } from './types/stepper-section/stepper-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatCommonModule } from '@angular/material/core';
import { MatStepperModule } from '@angular/material/stepper';
import { SearchableMultiSelectComponent } from './types/searchable-multi-select/searchable-multi-select.component';
import { SearchableSelectControlModule } from '../form/searchable-select-control/searchable-select-control.module';
import { AngularMaterialModule } from '../core/angular-material.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MultischemaTypeComponent } from './types/multischema-type/multischema-type.component';
import { JsonFormComponent } from './containers/json-form/json-form.component';
import { ObjectTypeComponent } from './types/object-type/object-type.component';
import { NullTypeComponent } from './types/null-type/null-type.component';
import { FormlyJsonEditorModule } from './types/formly-json-editor/formly-json-editor.module';
import { StepperResetService } from '../services/stepper/stepper-reset.service';
import { ArrayTypeComponent } from './types/array-type/array-type.component';
import { ArrayMaxLengthValidator, ArrayMinLengthValidator } from './types/array-type/array-validators';

@NgModule({
  declarations: [
    FormContainerComponent,
    StepperSectionComponent,
    RepeatSectionComponent,
    ChildSectionComponent,
    SearchableMultiSelectComponent,
    ArrayTypeComponent,
    MultischemaTypeComponent,
    NullTypeComponent,
    ObjectTypeComponent,
    JsonFormComponent
  ],
  exports: [
    FormContainerComponent,
    StepperSectionComponent,
    SearchableMultiSelectComponent,
    RepeatSectionComponent,
    ChildSectionComponent,
    JsonFormComponent,
    NullTypeComponent,
    ObjectTypeComponent,
    MultischemaTypeComponent,
    ArrayTypeComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatCommonModule,
    MatMenuModule,
    AngularMaterialModule,
    MatStepperModule,
    MatExpansionModule,
    FormlyJsonEditorModule,
    ReactiveFormsModule,
    SearchableSelectControlModule,
    FormlyModule.forRoot({
      validators: [
        { name: 'arrayMinLength', validation: ArrayMinLengthValidator, options: { min: 1 } },
        { name: 'arrayMaxLength', validation: ArrayMaxLengthValidator, options: { max: 1 } }
      ],
      extras: { resetFieldOnHide: true },
      validationMessages: [
        {
          name: 'arrayMinLength',
          message: (_err, field: FormlyFieldConfig) =>
            `"${field.formControl.value}" must have at least .... value`
        },
        {
          name: 'arrayMaxLength',
          message: (_err, field: FormlyFieldConfig) =>
            `"${field.formControl.value}" must not contains more than ... value.`
        },
        {
          name: 'required',
          message: (_err, _field: FormlyFieldConfig) => 'This field is required'
        },
        {
          name: 'null',
          message: (_err, _field: FormlyFieldConfig) => 'should be null'
        },
        {
          name: 'minLength',
          message: (_err, field: FormlyFieldConfig) =>
            `should not be shorter than ${field.props['minLength']} characters`
        },
        {
          name: 'maxLength',
          message: (_err, field: FormlyFieldConfig) =>
            `should not be longer than ${field.props['maxLength']} characters`
        },
        {
          name: 'min',
          message: (_err, field: FormlyFieldConfig) =>
            `should be greater than or equal to ${field.props['min']}`
        },
        {
          name: 'max',
          message: (_err, field: FormlyFieldConfig) =>
            `should be less than or equal to ${field.props['max']}`
        },
        {
          name: 'multipleOf',
          message: (_err, field: FormlyFieldConfig) =>
            `should be multiple of ${field.props['step']}`
        },
        {
          name: 'exclusiveMinimum',
          message: (_err, field: FormlyFieldConfig) =>
            `should be greater than ${field.props['step']}`
        },
        {
          name: 'exclusiveMaximum',
          message: (_err, field: FormlyFieldConfig) =>
            `should be less than ${field.props['step']}`
        },
        {
          name: 'minItems',
          message: (_err, field: FormlyFieldConfig) =>
            `should not have fewer than ${field.props['minItems']} items`
        },
        {
          name: 'maxItems',
          message: (_err, field: FormlyFieldConfig) =>
            `should not have more than ${field.props['maxItems']} items`
        },
        {
          name: 'uniqueItems',
          message: (_err, _field: FormlyFieldConfig) => 'should not have duplicate items'
        },
        {
          name: 'const',
          message: (_err, field: FormlyFieldConfig) =>
            `should be equal to constant "${field.props['const']}"`
        }
      ],
      wrappers: [{ name: 'child', component: ChildSectionComponent }],
      types: [
        { name: 'repeat', component: RepeatSectionComponent },
        { name: 'stepper', component: StepperSectionComponent, wrappers: [] },
        { name: 'searchable-multi-select', component: SearchableMultiSelectComponent },
        {
          name: 'string',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              blur: (field, $event) => field.formControl.setValue(field.formControl.value.trim())
            }
          }
        },
        {
          name: 'number',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number'
            }
          }
        },
        {
          name: 'integer',
          extends: 'input',
          defaultOptions: {
            templateOptions: {
              type: 'number'
            }
          }
        },
        { name: 'boolean', extends: 'checkbox' },
        { name: 'enum', extends: 'select' },
        { name: 'null', component: NullTypeComponent, wrappers: ['form-field'] },
        { name: 'array', component: ArrayTypeComponent },
        { name: 'object', component: ObjectTypeComponent },
        { name: 'multischema', component: MultischemaTypeComponent }
      ]
    }),
    FormlyMaterialModule
  ],
  providers: [StepperResetService]
})
export class LibFormModule { }
