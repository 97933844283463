<div [class]="(apiCallInProgress$ | async) ? 'gray-loader' : ''">
  <form [formGroup]="form" (ngSubmit)="submitRequest()" class="centered-with-top-header">
    <div class="scrollable">
      <formly-form
        [model]="model"
        [fields]="fields"
        [options]="options"
        [form]="form"></formly-form>
    </div>
    <div class="subvertical-request-buttons container">
      <button mat-button type="submit" [disabled]="!canSubmit()" class="subvertical-request-buttons submit">
        Submit Request
      </button>
    </div>
  </form>
</div>
