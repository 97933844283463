import { Injectable } from '@angular/core';
import { SessionManagerService } from '../services/session-manager/session-manager.service';
import { ObjectValidatorService } from '../core/object-validator-service/object-validator.service';
import { LoggerConfigBuilder } from '../core/logging-service/logger-config-builder';
import { LoggerConfig } from '../core/logging-service/logger-config';
import { ILoggerConfig } from '../core/logging-service/ilogger-config';
import { Observable, ReplaySubject, take } from 'rxjs';

@Injectable()
export class ConfigService {
  selectedDebugEnvironment: string;
  private readonly _configuredSubject = new ReplaySubject<boolean>();
  configured$: Observable<boolean> = this._configuredSubject.asObservable().pipe(take(1));
  private _production = false;
  private _apis: { [id: string]: { host: string } };
  private _apiRoutes: { [id: string]: { path: string } } = {};
  private _debugApiEndpoints: { [id: string]: { host: string } } = {};
  private _debugEnvironments: Array<string> = new Array<string>();
  private _applicationId: number;
  private _applicationName: string;
  private _primaryApiName: string;
  private _googleTag: string;
  private _loggerConfiguration: LoggerConfig;
  private _embedIds: { [id: string]: { pageUuid: string; groupId: number } } = {};
  private _nimbusApiKey: string;
  get production(): boolean {
    return this._production;
  }

  constructor(
    private _sessionManager: SessionManagerService,
    private _ovs: ObjectValidatorService,
    private _loggerConfigBuilder: LoggerConfigBuilder
  ) {
    this._sessionManager.startupCleaning();
  }

  public init(
    apis: { [id: string]: { host: string } },
    prod: boolean,
    applicationId: number,
    applicationName: string,
    loggerConfiguration: ILoggerConfig,
    primaryApiName: string = 'RampAPI',
    apiRoutes: { [id: string]: { path: string } } = {},
    nimbusApiKey: string,
    googleTag?: string,
    embedIds?: { [id: string]: { pageUuid: string; groupId: number } },
  ): void {
    this._apis = apis;
    this._production = prod;
    this._applicationId = applicationId;
    this._applicationName = applicationName;
    this._loggerConfiguration = this._loggerConfigBuilder.build(loggerConfiguration);
    this._primaryApiName = primaryApiName;
    this._apiRoutes = apiRoutes;
    this._googleTag = googleTag as any;
    this._embedIds = embedIds;
    this._nimbusApiKey = nimbusApiKey;

    const nonDebugApiNames = Object.entries(apis)
      .filter(([id, endpoint]) => !id.toLowerCase().startsWith('debug'))
      .map(([id, endpoint]) => id);

    // Note: Debug API IDs have the format: Debug{apiId}{environmentName}
    // The ApiId should match exactly the non-debug name (case-sensitive).
    // Examples: DebugRampAPILocalhost, DebugRampPartnerAPIStaging
    Object.entries(apis)
      .filter(
        ([id, endpoint]) =>
          id.toLowerCase().startsWith('debug') && !this._ovs.isPathNullOrEmpty(endpoint, ['host'])
      )
      .forEach(([id, endpoint]) => {
        const debugApiNameAndEnvironment = id.substring(5);
        for (let apiId in nonDebugApiNames) {
          if (debugApiNameAndEnvironment.startsWith(nonDebugApiNames[apiId])) {
            const environmentName = debugApiNameAndEnvironment.replace(nonDebugApiNames[apiId], '');
            if (!this._debugEnvironments.includes(environmentName))
              this._debugEnvironments.push(environmentName);
            this._debugApiEndpoints[`${environmentName}-${nonDebugApiNames[apiId]}`] = endpoint;
            continue;
          }
        }
      });
    this._configuredSubject.next(true);
  }

  public getApplicationId(): number {
    return this._applicationId;
  }

  public getApplicationName(): string {
    return this._applicationName;
  }

  public getGoogleTag(): string {
    return this._googleTag;
  }

  public getLoggerConfiguration(): ILoggerConfig {
    return this._loggerConfiguration;
  }

  public getEmbedIds(containerId: string): { pageUuid: string; groupId: number  } {
    return this._embedIds[containerId];
  }

  public getNimbusApiKey(): string {
    return this._nimbusApiKey;
  }

  getApi(apiId: string = this._primaryApiName): any {
    if (
      this.selectedDebugEnvironment &&
      this.selectedDebugEnvironment.toLowerCase() !== 'default' &&
      !this.production
    ) {
      return this._debugApiEndpoints[`${this.selectedDebugEnvironment}-${apiId}`]
        ? this._debugApiEndpoints[`${this.selectedDebugEnvironment}-${apiId}`]
        : this._apis[apiId];
    }
    return this._apis[apiId];
  }

  getRoute(routeName: string, params: string[] = new Array<string>()): any {
    if (
      this._ovs.isNullOrEmpty(routeName) ||
      this._ovs.isPathNullOrEmpty(this._apiRoutes, [routeName])
    ) {
      return '';
    }

    const result = { ...this._apiRoutes[routeName] };
    params.forEach((param, i) => (result.path = result.path.replace(`{${i}}`, param)));
    result.path = result.path.replace(/{([0-9]*)}/g, '');
    return result;
  }

  getDebugEnvironments(): Array<string> {
    return !this.production && this._debugEnvironments
      ? this._debugEnvironments
      : new Array<string>();
  }
}
