export class DomainCreateModel {
  constructor(
    public partnerId: number,
    public vertical: string,
    public subVertical: string,
    public geo: string,
    public domainName: string,
    public language: string,
    public forcekey: boolean,
    public privacyRegion: boolean
  ) {}
}
