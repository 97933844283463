import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import {
  AccessControlService,
  TokenCacheService,
} from '@nimbus/shared-lib';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { DomainCreateModel } from '../../models/domain-create.model';
import { DomainCreatorFormModel } from '../../models/domain-creator-form.model';
import { DomainCreatorAddService } from '../services/domain-creator-add.service';
import { Observable, BehaviorSubject, EMPTY, forkJoin } from 'rxjs';
import { DomainCreatorSchemaService } from '../../schemas/domain-creator-schema.service';
import { DomainCreatorWebService } from '../services/domain-creator-web.service';
import { DomainCreateLimiterService } from '../services/domain-create-limiter.service';
import { catchError, tap } from 'rxjs/operators';
import { PartnerCacheService } from '../../../../shared/services/partner-cache.service';
import { LinksetModel } from '../../../../shared/models/linkset.model';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-domain-creator-form-wrapper',
  templateUrl: './domain-creator-form-wrapper.component.html',
  styleUrls: ['./domain-creator-form-wrapper.component.scss'],
})
export class DomainCreatorFormWrapperComponent implements OnInit, OnDestroy {
  private _subscriptionManager = new SubscriptionManager();
  private _firstPartnerId: number;
  private _domainsCountsReceived = false;

  protected readonly _apiCallInProgressSubject = new BehaviorSubject<boolean>(
    false,
  );
  readonly apiCallInProgress$: Observable<boolean> =
    this._apiCallInProgressSubject.asObservable();

  form = new FormGroup({});
  model = new DomainCreatorFormModel([
    new DomainCreateModel(-1, '', '', '', '', '', false, false),
  ]);
  options: FormlyFormOptions = {};
  fields = this._domainCreatorSchemaService.getSchema();
  defaultKeywordRecommendationMethod: boolean = true;

  constructor(
    private _accessControlService: AccessControlService,
    private _tokenCacheService: TokenCacheService,
    private _domainCreatorAddService: DomainCreatorAddService,
    private _domainCreatorSchemaService: DomainCreatorSchemaService,
    private _domainCreatorWebService: DomainCreatorWebService,
    public partnerCacheService: PartnerCacheService,
    private _router: Router,
    private _ovs: ObjectValidatorService,
    private _snackbarService: SnackbarService,
    public domainCreateLimiterService: DomainCreateLimiterService
  ) {
    this._subscriptionManager.register(
      this.partnerCacheService.partners$
        .pipe(
          tap(partners => {
            if (this._ovs.isArray(partners) && partners.length > 0) {
              this._firstPartnerId = partners[0].id;
            }
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  ngOnInit(): void {
    this._subscriptionManager.register(this.domainCreateLimiterService.domainCountUpdate$.subscribe(() => this._domainsCountsReceived = true));
  }

  removeInvalidDomains() {
    (this.form.get('domains') as unknown as FormArray).controls
      .map((control, index) => (control.invalid ? index : -1))
      .filter(index => index !== -1)
      .reverse()
      .forEach(index => this.model.domains.splice(index, 1));
  }

  onSubmit(): void {
    this.removeInvalidDomains();

    this._apiCallInProgressSubject.next(true);
    this.domainCreateLimiterService.clearDomains();

    const data = this.model.domains.map(domain => {
      const linksets = JSON.parse(domain['vertical-subvertical-country'])
        .availableLinksets as LinksetModel[];
      return {
        euDomain: domain.privacyRegion ?? false,
        facebookDomainVerificationCode: '',
        keywordRecommendationMethod:
          this._accessControlService.canAccessBySelector(
            'domain-creator-forcekey-checkbox',
          )
            ? domain.forcekey ?? false
            : this.defaultKeywordRecommendationMethod,
        linkset: linksets.find(
          linkset => linkset.twoLetterISOLanguageName === domain.language,
        ).linkset,
        parkedDomain: domain.domainName,
        partnerId: this._ovs.isNumber(domain.partnerId)
          ? domain.partnerId
          : this._firstPartnerId,
        requestor: this._tokenCacheService.tokenResponse.email,
        staticKeywords: [],
        useWebhook: false,
        urlParameters: '',
      };
    });
    const requestsByPartnerId = Object.entries(
      data.reduce((group, domain) => {
        group[domain.partnerId] = group[domain.partnerId] || [];
        group[domain.partnerId].push(domain);
        return group;
      }, Object.create(null)),
    );

    this._subscriptionManager.register(
      forkJoin(
        requestsByPartnerId.map(([partnerId, domains]) => this._domainCreatorWebService.createDomains(Number(partnerId), domains)
          .pipe(
            catchError(() => {
              this._snackbarService.open(
                'An error occurred while creating the domain(s).',
                SnackBarType.error,
              );
              this._apiCallInProgressSubject.next(false);
              return EMPTY;
            })
          ))
      ).pipe(
        tap(() => this._apiCallInProgressSubject.next(false)),
      ).subscribe(() => this._router.navigate(['/domain/manager'])),
      'registerDomains');
  }

  checkValid(): boolean {
    if (!this.form.get('domains') || !this._domainsCountsReceived) {
      return false;
    }
    const formControls = this.form.get('domains') as unknown as FormArray;
    let valid = false;
    for (const control of formControls.controls) {
      if (!control.valid && control.touched) {
        for (const property in control.value) {
          if (!this._ovs.isNullOrEmpty(control.value[property])) return false;
        }
      }
      if (control.valid) valid = true;
    }
    return valid;
  }

  canAddDomain(): boolean {
    return this.domainCreateLimiterService.canAddDomain();
  }

  showCreationForm(): boolean {
    return this.domainCreateLimiterService.showForm();
  }

  addDomain(): void {
    this._domainCreatorAddService.addDomain();
  }
}
