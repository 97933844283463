import { Component, OnDestroy } from '@angular/core';
import { BasePageComponent, ConfirmationService } from '@nimbus/shared-lib';
import { of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { HeaderService } from 'core/libs/global/frontend/header/src/lib/header.service';
import { PixelManagerBuysideNetworkService } from '../../../shared/services/pixel-manager-buyside-network.service';
import { ConfirmationSubscriberService } from '../../../shared/services/confirmation-subscriber.service';

@Component({
  selector: 'app-pixel-manager',
  templateUrl: './pixel-manager.component.html',
  styleUrls: ['./pixel-manager.component.scss']
})
export class PixelManagerComponent extends BasePageComponent implements OnDestroy {
  constructor(
    private _headerService: HeaderService,
    public pixelManagerService: PixelManagerBuysideNetworkService,
    public partnerCacheService: PartnerCacheService,
    public _confirmationSubscriberService: ConfirmationSubscriberService,
    _confirmationService: ConfirmationService
  ) {
    super();
    this._confirmationService.hide();
    this._headerService.setSubheading('System1 can host server-to-server conversion tracking on your behalf. Choose your buyside, then, provide your pixel ID and corresponding buyside access token.');

    this._subscriptionManager.register(
      this._confirmationSubscriberService.populateModal$
        .pipe(
          switchMap(value => {
            if (value) {
              return this._confirmationService.showAsync(
                'Warning',
                'Continue',
                null,
                null,
                true,
                true,
                null,
                null,
                'This edit will break conversion events leveraging the original mapping',
                true
              );
            }
            return of(false);
          })
        )
        .pipe(
          tap(value => {
            if (value) {
              this._confirmationSubscriberService.emitConfirmation();
              this._confirmationSubscriberService.clearEmitModal();
            }
          })
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this._headerService.setSubheading('');
  }
}
