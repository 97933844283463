import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { AccessControlService } from '@nimbus/shared-lib';
import { tap, filter, startWith, distinctUntilChanged } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import {
  DomainNameBuilderParams,
  DomainNameBuilderService,
} from '../domain-creator/services/domain-name-builder.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { DomainCreateLimiterService } from '../domain-creator/services/domain-create-limiter.service';

@Injectable()
export class DomainCreatorSchemaService {
  constructor(
    private _ovs: ObjectValidatorService,
    private _accessControlService: AccessControlService,
    private _domainNameBuilderService: DomainNameBuilderService,
    private _partnerCacheService: PartnerCacheService,
    private _domainCreateLimiterService: DomainCreateLimiterService,
  ) { }

  getSchema() {
    return [
      {
        key: 'domains',
        type: 'domain-creator-repeat',
        fieldArray: {
          fieldGroupClassName: 'field-group-css',
          fieldGroup: [
            {
              key: 'partnerId',
              type: 'partner-select',
              templateOptions: {
                label: 'Partner *',
                required: true,
                width: 100
              },
              hooks: {
                onInit: (field: FormlyFieldConfig) => {
                  field.parent?.formControl?.get('partnerId')?.valueChanges.pipe(distinctUntilChanged()).subscribe(partnerId => {
                    if (partnerId) this._partnerCacheService.setCurrentPartner(partnerId);
                    const domainName = field.parent.formControl.get('domainName').value;
                    if (domainName) {
                      const formIndex = field.parent.parent.fieldGroup.findIndex(section => section.fieldGroup[2].fieldGroup[1].fieldGroup[1].formControl.value == domainName);
                      this._domainCreateLimiterService.formDomainUpdated(partnerId, formIndex);
                      field.parent?.formControl?.get('domainName')?.updateValueAndValidity();
                    }
                  });
                  field.parent?.formControl?.get('domainName')?.valueChanges.pipe(distinctUntilChanged()).subscribe(domainName => {
                    if (domainName) {
                      const partnerId = field.parent?.formControl?.get('partnerId')?.value ?? 0;
                      const formIndex = field.parent.parent.fieldGroup.findIndex(section => section.fieldGroup[2].fieldGroup[1].fieldGroup[1].formControl.value == domainName);
                      this._domainCreateLimiterService.formDomainUpdated(partnerId, formIndex);
                      field.parent?.formControl?.get('domainName')?.updateValueAndValidity();
                    }
                  });
                }
              }
            },
            {
              key: 'vertical-subvertical-country',
              defaultValue: '',
              type: 'vertical-subvertical-country',
              templateOptions: {
                label: 'Vertical/Subvertical/Country *',
                required: true,
                width: 100,
              },
            },
            {
              fieldGroupClassName: 'second-row-wrapper-css',
              fieldGroup: [
                {
                  key: 'language',
                  defaultValue: '',
                  type: 'language',
                  className: 'field-styles',
                  templateOptions: {
                    label: 'Language',
                    width: 100,
                    required: true,
                    showFullList: false,
                  },
                },
                {
                  wrappers: ['warning-wrapper'],
                  fieldGroupClassName: 'warning-wrapper-css',
                  fieldGroup: [
                    {
                      key: 'warning',
                      defaultValue: '',
                      className: 'field-css field-warning',
                      type: 'warning',
                      templateOptions: {
                        closed: 'false',
                        message: 'If creating a custom domain, make sure it is relevant to the vertical'
                      }
                    },
                    {
                      key: 'domainName',
                      type: 'input-with-tooltip',
                      defaultValue: '',
                      className: 'field-css field-styles',
                      templateOptions: {
                        label: 'Domain Name *',
                        showInfoButton: true,
                        toolTip:
                          'The domain-name field will automatically populate with our suggestion, but you are able ' +
                          'to enter your own preferred domain name. However, if the domain name you choose already ' +
                          'exists, the purchase will fail, and you’ll need to start over! If the selected Geo is ' +
                          'not the United States or the selected Language is not English the domain name will be ' +
                          'appended with the selected Geo or Language.',
                        placeHolderText: 'The following top level domains are available: .live, .fyi, .world, or .zone',
                      },
                      validators: {
                        validation: [
                          'domain-name-terms-validator',
                          'domain-name-validator',
                          'domain-tld-validator',
                          'domain-limit-validator',
                          'domain-unique-validator'
                        ]
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) =>
                          combineLatest([
                            field.parent?.formControl
                              ?.get('vertical-subvertical-country')
                              ?.valueChanges.pipe(startWith()),
                            field.parent?.formControl
                              ?.get('language')
                              ?.valueChanges.pipe(startWith('')),
                          ]).pipe(
                            filter(
                              inputValues =>
                                !this._ovs.isNullOrEmpty(inputValues[0]),
                            ),
                            tap(inputValues =>
                              field.formControl?.setValue(
                                this._domainNameBuilderService.buildDomainName({
                                  serializedLinksets: inputValues[0],
                                  language: inputValues[1],
                                } as DomainNameBuilderParams),
                              ),
                            ),
                            tap(() => field.form?.markAllAsTouched()),
                          ),
                      },
                    },
                  ],
                },
              ],
            },
            {
              fieldGroupClassName: 'third-row-wrapper-css',
              fieldGroup: [
                {
                  key: 'privacyRegion',
                  type: 'checkbox-type',
                  props: {
                    label: 'Privacy Region',
                    showInfoButton: true,
                    toolTip:
                      'Please let us know if traffic for this domain will target any privacy or GDPR-regulated ' +
                      'regions. Regions include EU, UK, Brazil, and Iceland.',
                  },
                },
                {
                  key: 'forcekey',
                  type: 'checkbox-type',
                  props: {
                    label: 'Forcekey ',
                    showInfoButton: true,
                    tooltip:
                      'This feature allows you to customize the keywords requested for the domain vs using our ' +
                      'curated recommendations. The domain manager will help you set up all parameters once live.',
                  },
                  hide: !this._accessControlService.canAccessBySelector(
                    'domain-creator-forcekey-checkbox',
                  ),
                },
              ],
            },
          ],
        },
      },
    ];
  }
}
