import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  TokenCacheService,
} from '@nimbus/shared-lib';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { switchMap, tap } from 'rxjs/operators';
import { PixelAccessTokenModel } from '../../models/pixel-access-token.model';
import { PixelManagerCacheService } from '../../services/pixel-manager-cache.service';
import { PixelManagerWebService } from '../../services/pixel-manager-web.service';
import { PixelManagerEditService } from '../../services/pixel-manager-edit.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { EditAccessTokenInputComponent } from './edit-access-token-input/edit-access-token-input.component';
import { ImpersonationTrackerService } from '../../../../shared/services/impersonation-tracker.service';
import {
  ICustomCell,
  IClickableCell,
} from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';

@Component({
  selector: 'edit-access-token',
  templateUrl: './edit-access-token.component.html',
  styleUrls: ['./edit-access-token.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    EditAccessTokenInputComponent,
    FormsModule,
  ],
})
export class EditAccessTokenComponent
  implements ICustomCell, OnDestroy, IClickableCell
{
  isEditMode: boolean = false;
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() transform?: (input: string) => string;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Output() clickEvent = new EventEmitter<ICustomCell>();
  private readonly _uid = Date.now();
  private _subscriptionManager = new SubscriptionManager();

  constructor(
    private _snackbarService: SnackbarService,
    private _pixelManagerCacheService: PixelManagerCacheService,
    public _pixelManagerWebService: PixelManagerWebService,
    private _impersonationTrackerService: ImpersonationTrackerService,
    private _tokenCacheService: TokenCacheService,
    private _ovs: ObjectValidatorService,
    private _pixelManagerEditService: PixelManagerEditService,
  ) {
    this._subscriptionManager.register(
      this._pixelManagerEditService.cancelEditMode$
        .pipe(
          tap(id => {
            if (id !== this._uid) {
              return;
            }
            this.cancelEditMode(true);
            this._snackbarService.open(
              'Please save or discard your changes before making further edits.',
              SnackBarType.warn,
              10000,
            );
          }),
        )
        .subscribe(),
    );
  }

  isEditable(): boolean {
    return this.form.controls.status.value.toLowerCase() === 'active';
  }

  edit() {
    this.isEditMode = true;
    this._pixelManagerEditService.updateEditState(this._uid, true);
  }

  submitEdit(editedAccessToken: string) {
    this._snackbarService.open(
      'Processing your request.',
      SnackBarType.working,
      3000,
    );
    this.isEditMode = false;
    this._pixelManagerEditService.updateEditState(this._uid, false);

    this._subscriptionManager.register(
      this._tokenCacheService.email
        .pipe(
          switchMap(email => {
            const requestBody = new PixelAccessTokenModel(
              this.form.controls.partnerId.value,
              this.form.controls.buysideNetworkId.value,
              this.form.controls.pixelID.value,
              editedAccessToken,
              this.form.controls.id.value,
              this._ovs.isNullOrEmpty(this._impersonationTrackerService.user)
                ? email
                : this._impersonationTrackerService.user.email,
            );

            return this._pixelManagerWebService.updatePixelAccessToken(
              requestBody,
            );
          }),
          tap(() => {
            this._snackbarService.open(
              'Request processed!',
              SnackBarType.done,
              3000,
            );
            this._pixelManagerCacheService.load(true);
          }),
        )
        .subscribe(
          () => {},
          err => {
            this._snackbarService.open(err.message, SnackBarType.error, 3000);
          },
        ),
    );
  }

  cancelEditMode(value: any) {
    this.isEditMode = false;
    this._pixelManagerEditService.updateEditState(this._uid, false);
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }
}
