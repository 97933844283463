export class RangeType {
  static default(): RangeType {
    return new RangeType(0, 0, 0, 0);
  }
  get max(): number {
    return this._max;
  }

  set max(value: number) {
    const wasInitialState = this.isInInitialState;
    this._max = value;
    if (this._max < this.upper || wasInitialState) {
      this.upper = this._max;
    }
  }
  constructor(public min: number, private _max: number, public lower: any, public upper: any) {
    this.lower = lower;
    this.upper = upper;
    this.min = min;
    this.max = _max;
  }
  reset() {
    this.lower = this.min;
    this.upper = this.max;
  }

  get isInInitialState(): boolean {
    return this.lower === this.min && this.upper === this.max;
  }
}
