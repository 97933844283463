import { RouterModule, Routes } from '@angular/router';
import { NgModule, OnDestroy } from '@angular/core';
import { AccessControlGuardService } from '@nimbus/shared-lib';
import { OktaAuthGuard } from '@okta/okta-angular';
import { siteMap } from '../../../../site-map';
import { TableauComponent } from './tableau.component';
import { UtilityService } from 'core-global-frontend-object-validator';
import { SiteMapNode } from 'core-global-frontend-navigation';

const utilityService = new UtilityService();
const segments = utilityService.flattenListNodes<SiteMapNode>(siteMap as any);
const routes: Routes = segments
  .filter(segment => 'tableau' in segment)
  .map(segment => ({
    path: segment.path,
    component: TableauComponent,
    canActivate: [OktaAuthGuard, AccessControlGuardService],
    data: {
      resourceName: segments.find(s => s.path === segment.path).resource,
      tableau: segment.tableau,
    },
  }));

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TableauRoutingModule {}
