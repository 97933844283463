import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseWebService } from '../../../core/base-web.service';
import { ConfigService } from '../../../core/config.service';
import { HealthMonitor } from '../models/health-monitor.model';
import { HttpClientQ } from 'core-global-frontend-http';

@Injectable({
  providedIn: 'root',
})
export class HealthMonitorWebService extends BaseWebService {
  constructor(
    private _configService: ConfigService,
    private _http: HttpClientQ,
  ) {
    super();
  }

  get(): Observable<HealthMonitor> {
    return this._http
      .get(
        this._configService.getApi().host +
          this._configService.getRoute('HealthMonitor').path,
      )
      .pipe(catchError(err => throwError(err)));
  }
}
