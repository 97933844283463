import { Component } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

// Example if a link element that can be added to a form without any property mapping.
@Component({
  selector: 'add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.scss']
})
export class AddNewComponent extends FieldType<FieldTypeConfig> {
  validators: typeof Validators = Validators;
  internalForm = new FormGroup({});
}
