/* eslint-disable no-useless-escape */
import { Component } from '@angular/core';

@Component({
  selector: 'app-embedded-search',
  templateUrl: './embedded-search.component.html',
  styleUrls: ['../documentation.scss']
})
export class EmbeddedSearchComponent {
  communicationRequirements = [
    'The fully qualified domain name that will run the embedded search javascript ' +
      '(e.g. www.dogpile.com)',
    'The regions on your SERP (see the next section)',
    'The combination of ads and/or organic results you want to see rendered on the page.'
  ];
  accountParameters = [
    'Your partner ID.',
    'Your access key (make sure to keep this private!)',
    'Your segment name(s).',
    'Your region names.'
  ];
  jsScript =
    '<script async type="text/javascript" src="https://s.flocdn.com/@s1/embedded-search/embedded-search.js">\n' +
    '</script>';
  pageElements = [
    '<div data-s1search="mainline-top"></div>',
    '<div data-s1search="mainline-bottom"></div>',
    '<div data-s1search="sidebar"></div>'
  ];
  searchScript = `<script>
     function onResolved() {
      // console.log(\'completed\');
     }
     function onRejected(msg) {
       console.error(msg);
     }
     window.s1search = window.s1search || function () {
       (window.s1search.q = window.s1search.q || []).push(arguments)
     };
     window.s1search(\'config\', {
       category: "web",
       domain: "{{ your domain }}",
       partnerId: {{ your partner id }},
       isTest: true,
       onComplete: onResolved,
       onError: onRejected,
       query: "{{ query }}",
       segment: "{{ segment }}",
       signature: "{{ csr signature method }}"
     })
    </script>`;
  searchParametersTable = {
    headers: ['Key', 'Description', 'Accepted Values', 'Example'],
    data: [
      {
        key: 'personalizedAds (previously gdprOptIn)',
        description:
          'This parameter specifies whether the publisher has obtained consent ' +
          'from users from EU countries or in the State of California, as specified by GDPR ' +
          'or CCPA for processing of their personal data, including writing cookies ' +
          'and personalization tracking.\n' +
          'Set to false, it means the publisher has not obtained consent per GDPR/CCPA ' +
          'requirements.\n' +
          'Set to true, it means the publisher has obtained consent per GDPR/CCPA ' +
          'requirements.\n' +
          'Note: This parameter only affects users originating in EU countries or ' +
          'the State of California as determined by IP address/geolocation. It is ' +
          'otherwise ignored.\n',
        accepted_values: 'Boolean, true or false',
        default:
          'Optional. Default is false in EU countries and true in California. ' +
          '(This parameter is ignored for non-EU, non-California requests.)'
      },
      {
        key: 'domain',
        description: 'The fully qualified domain that this implementation is embedded in.',
        accepted_values: 'String',
        default: 'Required'
      },
      {
        key: 'partnerId',
        description: 'A unique identifier for you; mainly related to billing purposes.',
        accepted_values: 'Integer',
        default: 'Required'
      },
      {
        key: 'query',
        description:
          'The user’s search query. Ensure that this is the same exact query ' +
          'term that was used to generate the signature value below.',
        accepted_values: 'String',
        default: 'Required'
      },
      {
        key: 'querystringParams',
        description:
          'Where the SDK should look for the page and query string parameters ' +
          'in the URL.  For example, if “page” is set to “p” and “query” is set to ' +
          '“s” then the url http://www.dogpile.com/?s=dogs&p=2 means the query is ' +
          'for “dogs” and the page number is two.  Whatever is not specified gets ' +
          'the default value.',
        accepted_values: 'Object with “page” and “query” attributes',
        default: '{“page”: “page”, “query”: “q”}'
      },
      {
        key: 'segment',
        description: 'Work with your Partnership Manager to obtain your segment.',
        accepted_values: 'String',
        default: 'Required'
      },
      {
        key: 'signature',
        description: 'Encoded hashed value of timestamp, access key, and query terms.',
        accepted_values: 'String',
        default: 'Required'
      },
      {
        key: 'category',
        description: 'Sets the category for the user’s search.',
        accepted_values: 'web, images, video, news, shopping',
        default: 'web'
      },
      {
        key: 'subId',
        description:
          'Supplemental subId parameter used in addition to sub params on ' +
          'the source page url. Must be configured; work with your Partnership Manager ' +
          'to enable.',
        accepted_values: 'String',
        default: 'Optional'
      },
      {
        key: 'clickTrackingUrl',
        description:
          'This is the URL that will contain the information you want to ' +
          'receive on a end-user click. For more information on this topic, refer to ' +
          'this section.\n' +
          'Limit the length of the URL to 1000 chars or less.\n',
        accepted_values: 'String',
        default: 'Optional'
      },
      {
        key: 'isTest',
        description:
          'Used to indicate to content providers that the request is a test ' +
          'and should not be included in revenue collection.',
        accepted_values: 'Boolean, true or false',
        default: 'false'
      },
      {
        key: 'onComplete',
        description:
          'This callback is invoked once the results have been rendered. ' +
          'It will contain additional information about the results that were ' +
          'returned. Further details will be forthcoming.',
        accepted_values: 'JavaScript callback',
        default: 'Optional'
      },
      {
        key: 'onError',
        description:
          'If there are any errors or warnings that occurred during the ' +
          'call, this callback will be invoked with a list of those errors and warnings.',
        accepted_values: 'JavaScript callback',
        default: 'Optional'
      }
    ]
  };
  testingNotes = [
    'Pass in the domain parameter of the production domain.  So, let’s say you’re ' +
      'serving live traffic on www.system1.com, and you want to do dev work on ' +
      'dev.system1.com.  Set the domain in the s1search call to www.system1.com.',
    'Set the isTest flag to true.'
  ];
  requestPrerequisites = [
    'A System1 search segment',
    'A System1 search access key',
    'The system clock on each server that signs a request to be accurate within a ' +
      'maximum deviation of 1 minute.',
    'The signature is a specially formed binary hash of the following three values:'
  ];
  binaryHashValues = ['Request date and time', 'Access key/token', 'Query term(s)'];
  timeFormat = [
    'yyyy - 4 digit year',
    'MM - 2 digit month, 01-12',
    'dd - 2 digit day, 01-31',
    'HH - 2 digit hour, 24-hour clock, 00-23',
    'mm - 2 digit minute, 00-59'
  ];
  concatenationOrder = [
    'timestamp',
    'access key',
    'query term(s) (if you have no query terms use the empty string)'
  ];
  sampleInputs = [
    'Time: January 3rd, 2020 13:28:56',
    'Token: VKxZxMDp_hx2tchU1M6eWRfn',
    'Query Term: ipad'
  ];
  sampleSteps = [
    'Start with the UTC/GMT time -- January 3rd, 2020 13:28:56',
    'Round to the nearest minute -- January 3rd, 2020 13:29:00',
    'Format the time as a string -- 202001031329',
    'Concatenate the values together -- 202001031329VKxZxMDp_hx2tchU1M6eWRfnipad',
    'Encode concatenated string into a UTF-8 byte encoding -- ' +
      '202001031329VKxZxMDp_hx2tchU1M6eWRfnipad',
    'Perform a SHA-256 (preferred) or SHA-1 hash of the UTF-8 encoded string value. ' +
      'NOTE: This string representation of the hashed value may look different depending ' +
      'on what coding language you’re using to compute the hash. The important thing ' +
      '(also noted above) is to make sure that you’re using the binary hash output and ' +
      'NOT the hex representation when moving onto step 7 -- ' +
      '\\x1d\\x86\\xca\\x17\\x14,\\x1dY\\x1f"\\xf7\\xe7\\xd6}k\\xf4G\\xb5\\xa07pd\\xa8\\x8eM' +
      '_UoVR\\xb7\\xaa',
    'Encode the binary hashed value using url-safe base-64 encoding and ' +
      'trim any padding characters -- HYbKFxQsHVkfIvfn1n1r9Ee1oDdwZKiOTV9Vb1ZSt6o'
  ];
  clickTrackingParametersTable = {
    headers: ['Key', 'Description', 'Type', 'Sample value'],
    data: [
      {
        key: '{info[domain]}',
        description: 'The fully qualified domain this click happened on.',
        type: 'String',
        sample_value: 'www.dogpile.com'
      },
      {
        key: '{info[page]}',
        description: 'The page number that this click happened on (zero indexed).',
        type: 'Integer',
        sample_value: '0'
      },
      {
        key: '{info[query]}',
        description: 'The query on the current serp.',
        type: 'String',
        sample_value: 'dogs'
      },
      {
        key: '{info[query_category]}',
        description: 'The query category (web, images, news, videos, etc.)',
        type: 'String',
        sample_value: 'web'
      },
      {
        key: '{info[segment]}',
        description: 'The segment the click happened on.',
        type: 'String',
        sample_value: 'info.0001'
      },
      {
        key: '{info[subsequent_search]}',
        description: 'Whether or not this page is a subsequent search',
        type: 'Boolean',
        sample_value: 'false'
      },
      {
        key: '{requestu_args[ip]}',
        description: 'The end user’s IP address',
        type: 'IP Address',
        sample_value: '10.23.125.23'
      },
      {
        key: '{requestu_args[user_id]}',
        description:
          'The user’s cookie. This cookie is unique to the browser ' + 'and has a 1 year expiry.',
        type: 'String',
        sample_value: 'FqUyptgETnJXSgqC3gxk'
      },
      {
        key: '{info[persist_args][gclid]}',
        description: 'The Google Ads click tracking parameter, gclid.',
        type: 'String',
        sample_value: '....'
      },
      {
        key: '{info[persist_args][msclkid]}',
        description: 'The Bing Ads click tracking parameter, msclkid.',
        type: 'String',
        sample_value: '....'
      },
      {
        key: '{extra_args[p]}',
        description: 'Whether or not this is a paid or non-paid click',
        type: 'Integer',
        sample_value: '1'
      },
      {
        key: '{extra_args[b]}',
        description: 'The backend that the ad or algo result comes from.',
        type: 'String',
        sample_value: 'google'
      },
      {
        key: '{extra_args[position]}',
        description: 'The zero-indexed position of the ad or algo result that was clicked.',
        type: 'Integer',
        sample_value: '1'
      },
      {
        key: '{extra_args[advertiser_domain]}',
        description: 'The Bing Ads advertiser domain for a paid click.',
        type: 'String',
        sample_value: 'www.domain.com'
      }
    ]
  };

  constructor() {}

  getHeaderCount(table) {
    return `grid-template-columns: repeat(${table.headers.length}, auto)`;
  }

  getColumnHeaderClass(table, index) {
    return index < table.headers.length - 1
      ? 'documentation-grid column-header column-header__standard'
      : 'documentation-grid column-header column-header__end';
  }

  getColumnValueClass(columnIndex, table) {
    return columnIndex < table.headers.length - 1
      ? 'documentation-grid column-value column-value__standard'
      : 'documentation-grid column-value column-value__end';
  }
}
