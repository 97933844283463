<div class="documentation tab-content">
  <h3 class="documentation-sub-header">SEM Guidelines</h3>
  <div class="documentation-highlighted">Ad Copy</div>
  <div class="documentation shifted">
    Must contain the word 'search' or 'find' (or translation of that if buying foreign language
    traffic) in order to intimate to the user they are hitting a SERP & must not be misleading -
    here are some SEM ads for SERP to SERP arb that fit the requirements. (<span
      class="documentation italic"
      >Please note the use of ‘Compare’ is
      <span class="documentation bold">not an approved</span> word in replacement to ‘Search’ or
      ‘Find’ for Shopping domains/ecommerce examples.</span
    >)
  </div>
  <img class="documentation shifted documentation-img" src="assets/img/semguidelinesone.jpg" />
  <br />
  <div class="documentation shifted">
    Ad Copy should be relevant and not misleading. i.e. if you promote discounts or free delivery
    you could need to ensure this is matching the user experience once on your site.
  </div>
  <div class="documentation-highlighted">Keyword relevance specific to verticalized sites</div>
  <div class="documentation shifted">
    For a finance vertical site, the keyword would need to be related to finance - therefore
    marketing on ‘copd symptoms’, would not be relevant.
    <br /><br />
    For a shopping/ecommerce site - The keyword would need to be a product that the user can
    purchase therefore coupon/discount codes & vouchers, shop names, websites, puppies for sale etc
    would not be relevant.
  </div>
  <div class="documentation-highlighted">eCommerce/Shopping</div>
  <div class="documentation shifted">
    domain SERPs are required to show relevant products to the query.
  </div>
  <div class="documentation-highlighted">Sources</div>
  <div class="documentation shifted">
    Microsoft Ads (previously known as Bing Search Ads) & Google Ads (previously known as Adwords)
    are the only 2 approved sources for SEM direct to a SERP.
  </div>
  <div class="documentation-highlighted">Bidding</div>
  <div class="documentation shifted">
    The keyword you are bidding on should match the headline ad copy & the landing page keyword on
    your SERP. The only exception to this is where you are using broad match campaigns, and Google
    and Bing broad match your campaign. We are not permitted to upsell keywords or perform
    broadmatching outside of Google and Bing's own platform suggestions.
  </div>
  <img class="documentation shifted documentation-img" src="assets/img/semguidelinestwo.jpg" />
  <br />
  <div class="documentation-highlighted">3rd Party Buying</div>
  <div class="documentation shifted">
    The use of agencies to buy your traffic is strictly prohibited.
  </div>
  <div class="documentation-highlighted">Prohibited keywords</div>
  <div class="documentation shifted">
    Each traffic source has their own prohibited keywords which we must adhere to.
    <br /><br />
    In addition, we must steer clear from the very high value or sensitive terms in order to not
    upset engine partner advertisers. (for example but not limited to - Trademarks, Adult, certain
    legal terms, Mesothelioma, EDU etc.) A good rule of thumb is to buy around 80% of your keywords
    in the lower value verticals, and around 20% is the middle-value verticals). This will also help
    protect your sell-side traffic quality to a degree.
    <br /><br />
    As of June 2nd 2021, GoogleAds prohibited marketing on ‘Health Insurance’ related keywords,
    unless an approved supplier.
    <br /><br />
    Lastly we would like to ensure that keywords which may frustrate users or exacerbate already
    difficult situations are avoided in light or the current Covid-19 pandemic. Here are some
    examples, where we would ask partners to review their active keywords and pause any that match
    or are closely linked in intent:
    <ul class="prohibited-keywords-grid-container">
      <li *ngFor="let prohibitedKeyword of prohibitedKeywords">{{ prohibitedKeyword }}</li>
    </ul>
    Please also block keywords "Official [IRS or Social Security, Unemployment, Government etc]
    website" or similar where the ads have dynamic keyword insertion. It can insinuate a link to a
    government website.
  </div>
  <div class="documentation-highlighted">Generic SERP guidelines</div>
  <div class="documentation shifted">
    Related Searches / AYLF - is permissible in 2 placements maximum per SERP, of the following:
    Right Rail, Below Top Ads,Below Algo, Below Bottom Ads. Related searches cannot be sticky i.e.
    scroll with the page. Typically we see below Top Ads + Right Rail as the best performing
    sections.
    <br /><br />
    Ads and Algo (Algo can be web results, internal partner results or shopping results) must meet a
    ratio of 1:1. That means equal or less Ads than Algo per page.
    <br /><br />
    We must call and show a minimum of 3 Ads above fold (unless coverage of feed provides less)
    <br /><br />
    All Ads called must be shown
    <br /><br />
    All Ads must be labeled with ‘Ads’ or ‘Sponsored’.
    <br /><br />
    Display ads on a SERP must be pre-approved with S1 and engine partners.
  </div>
  <div>
    <span class="documentation bold">Disclaimer:</span> These guidelines are not all encompassing,
    they are provided as a general guide. There are additional considerations unique to some partner
    implementations or feed providers which you may have been informed of by your Partner Manager.
    <br /><br />
    Any questions, please contact your partner manager. Thank you!
  </div>
</div>
