import { Injectable } from '@angular/core';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FullGeoWebService {
  constructor(private _webServiceBuilderService: NimbusWebServiceBuilderService) { }

  getFullGeoList(): Observable<any> {
    return this._webServiceBuilderService.builder.withUrl('countries').build().get({});
  }
}
