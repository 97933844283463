import { Injectable } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SubjectManager } from '@nimbus/global-frontend-subscription-manager';
import { Observable, of } from 'rxjs';

@Injectable()
export class SubverticalReviewCacheService {
  private _subjectManager = new SubjectManager();
  private _subverticalRequestId: number;

  get subverticalRequestId() {
    return this._subverticalRequestId;
  }

  get closeSubverticalReviewDock$() {
    return this._subjectManager.get('subverticalReviewCloseDock');
  }

  get subverticalRequestId$() {
    return of(this._subverticalRequestId);
  }

  openDock$(): Observable<any> {
    return this._subjectManager.get('openDock');
  }

  constructor(private _ovs: ObjectValidatorService) {
    this._subjectManager.register('openDock');
    this._subjectManager.register('subverticalReviewCloseDock');
  }

  closeSubverticalReviewDock() {
    this._subverticalRequestId = null;
    this._subjectManager.next('subverticalReviewCloseDock', null);
  }

  setSubverticalRequestId(subverticalRequestId: number) {
    this._subverticalRequestId = subverticalRequestId;
    this._subjectManager.next('openDock', null);
  }
}
