import { SubjectManager } from './../../core/subject-manager';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Role } from '../../features/access-control/models/role';
import { UserModel } from '../../services/user/user.model';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationShareService {
  private _subjectManager = new SubjectManager();
  private readonly userInfoKey = 'userInfo';
  private readonly roleInfoKey = 'roleInfo';

  get userInfo$(): Observable<any> {
    return this._subjectManager.getObservable(this.userInfoKey);
  }

  get roleInfo$(): Observable<any> {
    return this._subjectManager.getObservable(this.roleInfoKey);
  }

  constructor() {
    this._subjectManager.register(this.userInfoKey);
    this._subjectManager.register(this.roleInfoKey);
  }

  startSession(userInfo: UserModel, roles: Role[]) {
    this._subjectManager.next(this.userInfoKey, userInfo);
    this._subjectManager.next(this.roleInfoKey, roles);
  }

  endSession() {
    this._subjectManager.next(this.userInfoKey, undefined);
    this._subjectManager.next(this.roleInfoKey, undefined);
  }
}
