import { Subject } from 'rxjs';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export enum RangeDisplayType {
  None = 0,
  Slider = 1,
  Text = 2,
  Both = 3,
}

export class FilterBoxCustomComponentType {
  constructor(
    public type: any,
    public data$: Subject<any[]>,
  ) {}
}

// Todo: using a builder like the columnConfiguration.
export class FilterBoxConfiguration {
  protected _ovs = new ObjectValidatorService();
  constructor(
    public rangeType = RangeDisplayType.None,
    public showCheck = false,
    public showExclude = false,
    public showEquals = false,
    public showInclude = false,
    public customComponentType: () => FilterBoxCustomComponentType,
  ) {}

  isFilterBoxVisible(): boolean {
    return (
      this.showInclude ||
      this.showCheck ||
      this.showExclude ||
      this.showEquals ||
      this.rangeType !== RangeDisplayType.None ||
      this.isCustomComponentEnabled()
    );
  }

  isCustomComponentEnabled(): boolean {
    return this._ovs.isDefined(this.customComponentType);
  }
}
