import { Component } from '@angular/core';
import { SubverticalReviewsCacheService } from '../../services/subvertical-reviews-cache.service';

@Component({
  selector: 'app-subvertical-reviews-manager-wrapper',
  templateUrl: './subvertical-reviews-manager-wrapper.component.html',
  styleUrls: ['./subvertical-reviews-manager-wrapper.component.scss']
})
export class SubverticalReviewsManagerWrapperComponent {
  constructor(public subverticalReviewsCacheService: SubverticalReviewsCacheService) {}
}
