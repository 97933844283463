import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private readonly _subheadingSubject = new BehaviorSubject<string | undefined>(
    '',
  );
  private readonly _headingSubject = new BehaviorSubject<string | undefined>(
    '',
  );
  subheading$ = this._subheadingSubject.asObservable();
  heading$ = this._headingSubject.asObservable();

  setSubheading(subheading: string) {
    this._subheadingSubject.next(subheading);
  }

  setHeading(heading: string) {
    this._headingSubject.next(heading);
  }

  clear() {
    this._subheadingSubject.next(undefined);
    this._headingSubject.next(undefined);
  }
}
