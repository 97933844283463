import { Observable, Subject } from 'rxjs';

export class SubjectItem<T> {
  observable$: Observable<T>;

  constructor(private _subject: Subject<T>) {
    this.observable$ = _subject.asObservable();
  }

  next(input: T) {
    this._subject.next(input);
  }

  getObservable(): Observable<T> {
    return this._subject.asObservable();
  }

  unsubscribe() {
    this._subject.unsubscribe();
  }
}
