<div class="documentation tab-content">
  <div>
    On any System1 hosted campaign, you can add tracking and reporting attributes by passing URL
    parameters.
    <br /><br />
    Compliance parameters such as <span class="documentation-highlighted">ref</span> and
    <span class="documentation-highlighted">rskey</span> are required. All other parameters are
    optional. The “Domain Manager” tab will allow you to configure your domains and guide you
    through setting up all of our parameters. Simply copy the link that is generated after entering
    all fields. If you are using a 3rd party redirect for tracking, you will need to work with your
    redirect provider to pass the necessary information to our system. <br /><br />Note: Domains may
    expire if they do not receive adequate traffic over a period of a few months. Please always
    check that a domain is live and rendering System1's lander before relaunching traffic.
  </div>
  <br />
  <h3 class="documentation-sub-header">Tracking Parameters</h3>
  <div>
    Tracking parameters are optional, but you are encouraged to experiment to find effective
    combinations. You can pass a conversion tracking URL corresponding to each step of the System1
    flow, impression (session), search, or click. Please ensure that tracking URLs are prefixed with
    https://. Sub IDs allow you to segment the traffic and monitor performance at a finer grain such
    as offer, publisher, feed ID, etc.
  </div>
  <br />
  <div class="documentation-highlighted">impression_track_url</div>
  <div>
    The URL you pass will be embedded on the keyword lander page as a tracking pixel. This would
    record sessions on your campaign.
  </div>
  <br />
  <div class="documentation-highlighted">search_track_url</div>
  <div>
    The URL you pass will be embedded on the search page as a tracking pixel, firing each time users
    initiate a search that takes them to the SERP (Search Engine Results Page). This feature is not
    supported on all embedded templates. Contact your account manager if you would like this feature
    enabled for your campaigns.
  </div>
  <br />
  <div class="documentation-highlighted">click_track_url</div>
  <div>
    The URL you pass will be pinged when the user clicks on a paying ad on a SERP via an onclick
    handler.
  </div>
  <br />
  <div class="documentation-highlighted">sub_id</div>
  <div>
    You can use the sub_id field to distinguish and monitor performance on different flows.
    <p>Please note: We do not support session-level SubIDs.</p>
    We accept upper and lowercase letters, digits, dashes, periods, tildes, and underscores. This is
    any character in the following set: [A-Za-z0-9.-~]. The full list of the 66 unreserved
    characters in RFC3986, is available
    <a
      class="documentation-link"
      target="_blank"
      href="http://tools.ietf.org/html/rfc3986#section-2.3"
      ><span class="documentation-link-text"
        >http://tools.ietf.org/html/rfc3986#section-2.3</span
      ></a
    >.
  </div>
  <br />
  <h3 class="documentation-sub-header">Required Parameters</h3>
  <div class="documentation-highlighted">ref</div>
  <div class="documentation shifted">
    <div>
      If you are sending traffic to a Google campaign, you must pass the correct HTTP referrer.
    </div>
    <br />
    <div>1. Natural Referrer:</div>
    <p>
      Specify the appropriate referrer policy on the origin page using
      <a class="documentation-link" target="_blank" href="https://www.w3.org/TR/referrer-policy"
        ><span class="documentation-link-text">https://www.w3.org/TR/referrer-policy</span></a
      >. Option “same-origin” is preferred as it preserves the full referrer URL.
    </p>
    <div>2. ref Parameter:</div>
    <p ngNonBindable>
      In order to pass accurate referrer information to Google, you must also pass the encoded
      referrer into the “ref=” parameter in the URL call to the Google Keyword lander.
      <br /><br />
      For native implementations, please use:
      <br />
      <span class="documentation italic">ref=outbrain-{{ publisher_name }}</span
      ><br />
      <span class="documentation italic">ref=taboola-&#123;site&#125;</span>
      <br /><br />
      For social implementations, please use:
      <br />
      <span class="documentation italic">ref=facebook-{{ placement }}</span
      ><br />
      <span class="documentation italic">ref=tiktok-__placement__</span>
      <br /><br />
      For content implementations, please pass:
      <br />
      <span class="documentation italic">ref=article URL</span>
    </p>
  </div>
  <div class="documentation-highlighted">rskey</div>
  <div class="documentation shifted">
    <div ngNonBindable>
      Rskey (referral source key) is a required parameter that helps to align ads with user intent.
      Please pass the main search term you plan to target for your campaign. If you have questions
      please contact your account manager when choosing terms. Example:
      <span ngNonBindable class="documentation-highlighted">
        campaigndomain.fyi/?rskey=search+term
      </span>
    </div>
  </div>
  <div class="documentation-highlighted">forcekey</div>
  <div class="documentation shifted">
    <div ngNonBindable>
      This parameter allows you to select the search terms requested on the keyword lander. Please
      note that there is no way to ensure all terms will be honored. The domain must be set up as
      “forcekey” to pass this parameter successfully. You can do this at the time of campaign
      creation or by checking the “forcekey” box in the Domain Manager. All terms must be relevant
      to the targeted vertical and domain name. Please see the below example.
      <p>
        <br />
        campaigndomain.fyi/?forcekeyA=Term1&forcekeyB=Term2&forcekeyC=Term3&forcekeyD=Term4&forcekeyE=Term5&forcekeyF=Term6
      </p>
      <br />
    </div>
  </div>
</div>
