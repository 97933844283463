export class GUIDBuilder {
  public static build(): string {
    return (
      Math.random().toString(16).substring(2, 10) +
      '-' +
      Math.random().toString(16).substring(2, 6) +
      '-' +
      Math.random().toString(16).substring(2, 6) +
      '-' +
      Math.random().toString(16).substring(2, 6) +
      '-' +
      Math.random().toString(16).substring(2, 14)
    );
  }
}
