export class NumberRange {
  constructor(
    public lower: number,
    public upper: number,
  ) {}
  fits(number: number): boolean {
    return number >= this.lower && number <= this.upper;
  }
  isValid(): boolean {
    return this.lower !== -1 && this.upper !== -1;
  }
}
