<div>
    <div>User Guide:</div>
    <p>
        Welcome to the System1 AFD Keyword Reporting API! This API is designed to provide you with easy, secure and timely access to data that you need to optimize your AFD Keyword mix.
    </p>
    <h2>Getting Started</h2>
    <h3>Authentication</h3>
    <p>All API calls must include an API key, which your account manager will provide via email. This API key grants access to all data relevant to your campaigns. It is advisable to guard this API key carefully and distribute it only to those team members who need access to your data. </p>
    <h3>Available Report</h3>
    <ul>
        <li><strong>Keyword Report:</strong>
        <p>Provides daily searches, clicks, and estimated revenue by date, domain, vertical, keyword, country, and device type
        </p></li>
    </ul>
    <app-accessing-api-reports [possibleReportTypeValues]="possibleReportTypeValues"></app-accessing-api-reports>
</div>
 