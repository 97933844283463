import { ValidationErrors, AbstractControl } from '@angular/forms';
import { InjectorService } from '../../services/injector.service';
import { CombineGeoLanguageSubdomainVerticalInputService } from '../../services/combine-geo-language-subdomain-vertical-input.service';
import { LinksetCacheService } from '../../services/linkset-cache.service';
import { SubverticalRequestCacheService } from '../../../areas/subverticals/subvertical-requests/services/subvertical-request-cache.service';

export function SubdomainExistenceValidator(control: AbstractControl): ValidationErrors {
  const geo = InjectorService.injector.get(
    CombineGeoLanguageSubdomainVerticalInputService
  ).StaticGeoValue;
  const language = InjectorService.injector.get(
    CombineGeoLanguageSubdomainVerticalInputService
  ).StaticLanguageValue;
  const vertical = InjectorService.injector.get(
    CombineGeoLanguageSubdomainVerticalInputService
  ).StaticVerticalValue;
  const checkVal = control.value.toLowerCase().trim();
  const requestedSubverticals = InjectorService.injector.get(
    SubverticalRequestCacheService
  ).staticRequestedSubverticals;
  const linksets = InjectorService.injector.get(LinksetCacheService).staticLinksets;
  if (geo === null || language === null) {
    return {};
  }
  return requestedSubverticals.find(
    subverticalRequest =>
      subverticalRequest.subvertical.toLowerCase().trim() === checkVal &&
      subverticalRequest.geo.toLowerCase().trim() === geo &&
      subverticalRequest.language.toLowerCase().trim() === language &&
      subverticalRequest.vertical.toLowerCase().trim() === vertical
  ) || linksets.find(linkset => linkset.linkset === `${vertical}:${checkVal}-${geo}:${language}`)
    ? { 'subdomain-existence-validator': true }
    : {};
}
