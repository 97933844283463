import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { PushPipe } from '@ngrx/component';
import { SiteMapNode } from './site-map-node';
import { ReplaySubject } from 'rxjs';

/**
 * @description The NavigatorComponent is a component that displays the navigation menu for the application.
 * @param isResourceAccessAllowed A function that takes a resource name and returns an observable of a boolean. This function is used to determine if a user has access to a resource.  This approach is used to allow for dynamic access control providers (required to support RBAC and the any new implementations).
 * @param ui A string that represents the UI of the application.
 * @param path A string that represents the path of the application.
 */
@Component({
  selector: 'frontend-navigator',
  templateUrl: './navigator.component.html',
  styleUrls: ['./navigator.component.scss'],
  imports: [
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    PushPipe,
    SubMenuComponent,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigatorComponent {
  private _menuSubject = new ReplaySubject<SiteMapNode[]>();
  menu$ = this._menuSubject.asObservable();

  @Input() set menu(value: SiteMapNode[]) {
    if (value && value.length > 0) {
      this._menuSubject.next(value);
    }
  }
  @Input() ui!: string;
  @Input() path: string | undefined = '';
}
