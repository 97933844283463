import { Injectable } from '@angular/core';
import { ObjectValidatorService } from '../object-validator-service/object-validator.service';
import { LocalStorageManagerService } from './local-storage-manager.service';
import { LocalStorageKeyFactory } from './local-storage-key-factory';
import { LocalStorageProxy } from './local-storage-proxy';
import { KeyService } from './key-service';

@Injectable()
export class LocalStorageProxyBuilder {
  constructor(
    private _localStorageKeyFactory: LocalStorageKeyFactory,
    private _localStorageManagerService: LocalStorageManagerService,
    private _ovs: ObjectValidatorService,
    private _keyService: KeyService
  ) {}

  // Marking as Obsolete until Compliance and partner gets updated.
  buildObsolete(version: string, contextName: string, defaultObject?: any): LocalStorageProxy {
    return new LocalStorageProxy(
      this._localStorageKeyFactory,
      this._ovs,
      this._localStorageManagerService,
      version,
      contextName,
      defaultObject
    );
  }

  build(
    contextName: string,
    objectSample?: any,
    defaultObject: any = undefined
  ): LocalStorageProxy {
    return new LocalStorageProxy(
      this._localStorageKeyFactory,
      this._ovs,
      this._localStorageManagerService,
      this._keyService.hashKey(objectSample),
      contextName,
      defaultObject !== undefined ? defaultObject : objectSample
    );
  }
}
