import { Component, Input } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { CommonModule } from '@angular/common';
import { ColumnClassHelper } from '../shared/columnClassHelper';
import { CallbackRunnerModule } from 'core-global-frontend-callback-runner';

@Component({
  selector: 'app-reporting-parameters',
  templateUrl: './reporting-parameters.component.html',
  styleUrl: './reporting-parameters.component.scss',
  standalone: true,
  imports: [CommonModule, CallbackRunnerModule],
})
export class ReportingParametersComponent {
  @Input() table: any;
  constructor(
    public ovs: ObjectValidatorService,
    public columnClassHelper: ColumnClassHelper,
  ) {}

  public parameterCount() {
    return this.columnClassHelper.numToWord(this.table.data[0].records.length);
  }
}
