import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-tabs-type',
  templateUrl: './tabs-type.component.html',
  styleUrls: ['./tabs-type.component.scss']
})
export class TabsTypeComponent extends FieldType implements AfterViewInit {
  selectedIndex: 0;
  invalidTabMessage = 'Incomplete section.';
  @ViewChild('tabGroup') tabGroup;

  isValid(field: FormlyFieldConfig) {
    if (field.key) {
      return field.formControl.valid;
    }

    return field.fieldGroup ? field.fieldGroup.every(f => this.isValid(f)) : true;
  }

  ngAfterViewInit() {
    this.tabGroup.selectedIndex = this.selectedIndex;
  }

  updateIndex(index: any) {
    this.tabGroup.selectedIndex = index;
  }
}
