import { NgModule } from '@angular/core';
import { TableauRoutingModule } from './tableau/tableau-routing.module';
import { EmbeddedRoutingModule } from './embedded/embedded-routing.module';

@NgModule({
  imports: [
    TableauRoutingModule, 
    EmbeddedRoutingModule,
  ],
})
export class ExternalToolsModule { }
