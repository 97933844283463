<div [class]="'wrapper ' + (props.internalCss ? props.internalCss : '')">
  <h2
    *ngIf="props.label?.length > 0 || props.description?.length > 0"
    [class]="'sub-title ' + (props.cssClass ? props.cssClass : '')">
    {{ props.label }}<span class="section-heading description">{{ props.description }}</span>
  </h2>
  <div>
    <ng-container #fieldComponent></ng-container>
  </div>
</div>
