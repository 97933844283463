import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { IConfirmValidator } from '../confirmation/confirm-validator.model';
import { ListItem } from '../../../core/list-item';
import { FormFieldConfig } from '../../../form/form-field-config';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';

@Injectable()
export class ConfirmationService {
  private _message = '';
  get message(): string {
    return this._message;
  }
  private _subMessage = '';
  get subMessage(): string {
    return this._subMessage;
  }
  private _showCloseButton = false;
  get showCloseButton(): boolean {
    return this._showCloseButton;
  }
  private _selectHeader = 'Select a value';
  get selectHeader(): string {
    return this._selectHeader;
  }
  private _options: any[];
  get options(): any[] {
    return this._options;
  }
  private _submitButtonName = 'Submit';
  get submitButtonName(): string {
    return this._submitButtonName;
  }
  private _showCancelButton = true;
  get showCancelButton(): boolean {
    return this._showCancelButton;
  }
  private _required = true;
  get required(): boolean {
    return this._required;
  }
  private _fields = [];
  get fields(): FormFieldConfig[] {
    return this._fields;
  }
  private _validator: IConfirmValidator;
  get validator(): IConfirmValidator {
    return this._validator;
  }

  private _visibleSubject = new ReplaySubject<boolean>();
  visible$ = this._visibleSubject.asObservable();
  private _confirmationSubject: Subject<boolean>;
  private _selectionChangedSubject = new ReplaySubject<ListItem>();
  selectionChanged$ = this._selectionChangedSubject.asObservable();

  constructor(private _ovs: ObjectValidatorService) { }

  confirm(isConfirmed: boolean): void {
    this._confirmationSubject.next(isConfirmed);
    this.hide();
  }

  select(item: any): void {
    this._selectionChangedSubject.next(item);
  }

  showAsync(
    message: string,
    submitButtonName = 'Submit',
    options = null,
    selectHeader = 'Select a value',
    showCancelButton = true,
    required = true,
    validator = null,
    fields = null,
    subMessage = '',
    showCloseButton = false
  ): Observable<boolean> {
    this._message = message;
    this._subMessage = subMessage;
    this._showCloseButton = showCloseButton;
    this._selectHeader = selectHeader;
    this._options = options;
    this._fields = fields;
    this._submitButtonName = submitButtonName;
    this._showCancelButton = showCancelButton;
    this._required = required;
    this._validator = validator;
    this._visibleSubject.next(true);
    if (this._ovs.isDefined(this._confirmationSubject)) {
      this._confirmationSubject.complete();
    }
    this._confirmationSubject = new Subject<boolean>();
    return this._confirmationSubject.asObservable();
  }

  hide() {
    this._visibleSubject.next(false);
  }
}
