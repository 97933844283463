import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class KeyService {
  hashKey(object: any): string {
    const key = Object.keys(object).join();
    return key;
  }
}
