import { Injectable } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class FileDownloadService {
  constructor(private _ovs: ObjectValidatorService) {}

  provideFile(content: any, filename: string, fileType: FileType) {
    const blob = new Blob([content], { type: fileType });
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && (window.navigator as any)['msSaveOrOpenBlob']) {
      (window.navigator as any)['msSaveOrOpenBlob'](blob);
      return;
    }
    const downloadLink = document.createElement('a');
    if (!this._ovs.isDefined(downloadLink)) {
      return;
    }
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    if (this._ovs.isFunction(downloadLink.click)) {
      downloadLink.click();
    }
    document.body.removeChild(downloadLink);
  }
}

export enum FileType {
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  CSV = 'application/csv',
  ZIP = 'application/zip',
  NONE = '',
}
