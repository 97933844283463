<p>There are {{ columnClassHelper.numToWord(keywordSpecificationMethods.length) }} different ways for you to provide the keywords that you’d like the end user to see on the RSoC block: </p>
<ol>
    <li *ngFor="let kwMethod of keywordSpecificationMethods">By {{ kwMethod }}</li>
</ol>
<p><span class="notice-this">It is not recommended to use more than one keyword selection method at a time.</span>Ramp.js will honor keywords from one source only and ignore any others that might be present. If keywords are supplied using more than one method, Ramp.js will first consider URL parameters, then JavaScript keywords, and then the meta tag, in that specific order of precedence. This means that if keywords are provided using all three methods at once, only URL parameters will be observed. If no keyword URL parameters are present but keywords are embedded in the page’s JavaScript and in the meta tag, only JavaScript keywords will be used. Meta tag keywords are used only when no keywords are present in URL parameters or JavaScript.</p>
<p>No matter which method you choose, <span class="notice-this">lease note that the keywords you provide are likely, but not guaranteed, to appear in the RSoC widget for any given end user.</span> This is because Google reserves the right to replace any or all of the terms that you provide with terms of its own. </p>
<p>Let’s dive into each of these methods in detail.</p>
<ol type="i">
    <li>
        <h2 id="keyword-url-parameters" class="documentation-sub-sub-header">Keyword URL Parameters</h2>
        <h4 id="forcekey" class="documentation-sub-sub-header">a. forceKey</h4>
        <p>
            Use the forceKey URL parameter to specify the terms you’d like the user to see in the RSoC widget. You’ll set forceKeyA as the first term, forceKeyB as the second term, and so on, as shown in this example:
        </p>
        <div class="code-block">
            <code>
                <pre> https://&lbrace;domain.com&rbrace;/&lbrace;article-name&rbrace;?&amp;forceKeyA=&lbrace;first_term&rbrace;&amp;forceKeyB=&lbrace;second_term&rbrace;&amp;forceKeyC=&lbrace;third_term&rbrace;&amp;forceKeyD=&lbrace;fourth_term&rbrace;&amp;forceKeyE=&lbrace;fifth_term&rbrace;&amp;forceKeyF=&lbrace;sixth_term&rbrace; </pre>
            </code>
        </div>
        <div>
            Please note:
            <ul>
                <li>You can only pass one keyword per forceKey.</li>
                <li>The <span class="underlined">K</span> in force<span class="underlined">K</span>ey must be capitalized.</li>
                <li>You should provide at least six unique forceKey terms (A - F) to ensure there are enough terms for all RSoC widgets on the page. </li>
                <li>If you’d like your page to feature more than one RSoC block, please notify your account manager first. You must then provide enough keywords to populate both blocks, and all keywords must be unique across both blocks.</li>
                <li>The forceKey parameter may be preferred to reduce engineering support or require no code changes to implement. However, if you have access to engineering resources, <a class="documentation-link" (click)="navigateToAnchor('keyword-javascript')">passing keywords in JavaScript</a> is the preferred method (because the keywords are not easily visible in the URL when passed via JavaScript).</li>
            </ul>
        </div>
        <h5>forceKey Dynamic Replacements</h5>
        <p>We support the ability to dynamically populate the following values in the forceKey parameters:</p>
        <ul>
            <li *ngFor="let param of forceKeyParameters">
                {{ param.name  }}
                <span *ngIf="param.note" class="foot-note"> (note: {{ param.note }})</span>
            </li>
        </ul>
        <br />
        <p>To use these dynamic replacements, simply use curly brackets, e.g. “Top 10 Dental Implants in &lbrace;city&rbrace;”. For example:</p>
        <div class="code-block">
            <code>
                <pre>https://&lbrace;domain.com&rbrace;/&lbrace;article-name&rbrace;?forceKeyA=Top+10+Dental+Implants+in+&lbrace;city&rbrace; </pre>
            </code>
        </div>  
        <br />
        <h4 id="headline" class="documentation-sub-sub-header">b. headline</h4>
        <p>“Referring ad creative” represents the buyside ad headline, Google calls this value the “ad headline.” The referring ad creative serves as a textual hint to both Google and System1 about the keywords you’d like to see in the RSoC widget.</p>
        <p>When using this parameter, use “headline,” not “referring ad creative.” For example:</p>
        <div class="code-block">
            <code>
                <pre>https://&lbrace;domain.com&rbrace;/&lbrace;article-name&rbrace;?headline=&lbrace;ad_creative&rbrace; </pre>
            </code>
        </div>
        <h4 id="optkey" class="documentation-sub-sub-header">c. optkey</h4>
        <p>Like referring ad creative, optkey is another content-based hint for term optimization. Note, “headline” has a stronger hierarchy in terms of influence on the terms, so the preference should be on providing optimal “headline” values.</p>
        <div class="code-block">
            <code>
                <pre>https://&lbrace;domain.com&rbrace;/&lbrace;article-name&rbrace;?optkey=&lbrace;optkey_hint&rbrace; </pre>
            </code>
        </div>
    </li>
    <br />
    <li>
        <h2 id="keyword-javascript" class="documentation-sub-sub-header">JavaScript</h2>
        <p>The terms selected for the RSoC widget can be specified directly in the JavaScript code that initializes RampJs. To do so, add a comma-separated list of terms in the _rampJs function call, as shown below: </p>
        <div class="code-block">
            <code>
                <pre>_rampJs(&lbrace; terms: “Test Term 1, Test Term 2, Test Term 3, Test Term 4” &rbrace;)&semi; </pre>
            </code>
        </div>
        <p>Full Example:</p>
        <div class="code-block">
            <code>
                <pre>&lt;html&gt;
    &lt;head&gt;
        ...
        &lt;script defer src="https://rampjs-cdn.system1.com/ramp.js"&gt;&lt;/script&gt;
        &lt;script&gt;
            (function(w,r)&lbrace;w[r]=w[r]||function()&lbrace;(w[r]['q']=w[r]['q']||[]).push(
    arguments)&rbrace;,w[r]['t']=1*new Date&rbrace;)(window,'_rampJs')&semi;
            _rampJs(&lbrace; terms: “Test Term 1, Test Term 2, Test Term 3, Test Term 4” &rbrace;)&semi;
        &lt;/script&gt;
        ...
    &lt;/head&gt;
    &lt;body&gt;
        ...
        &lt;div id=”rampjs_slot1”&gt;&lt;/div&gt;
        ...
        &lt;div id=”rampjs_slot2”&gt;&lt;/div&gt;
        ...
  &lt;/body&gt;
&lt;/html&gt; </pre>
            </code>
        </div>
    </li>
    <br />
    <li>
        <h2 id="keyword-metatag" class="documentation-sub-sub-header">Keywords Meta Tag</h2>
        <p>The keywords meta tag represents another way that you can specify the terms you’d like the end user to see in the RSoC widget. Note that these terms will only be used when there are no forceKey parameters present on the URL. </p>
        <p>Terms in the keywords tag should be separated with commas, as shown below:</p>
        <div class="code-block">
            <code>
                <pre>&lt;meta name="keywords" content="{{ metaTagList() }}"&gt; </pre>
            </code>
        </div>
        <p>This example would produce {{ keywordMetatagExamples.length }} terms:</p>
        <ul>
            <li *ngFor="let metaTag of keywordMetatagExamples">
                {{ metaTag }}
            </li> 
        </ul>
        <br />
        <p>Full Example:</p>
        <div class="code-block">
            <code>
                <pre>
&lt;html&gt;
&lt;head&gt;
    ...
    &lt;script defer src="https://rampjs-cdn.system1.com/ramp.js"&gt;&lt;/script&gt;
    &lt;script&gt;
        (function(w,r)&lbrace;w[r]=w[r]||function()&lbrace;(w[r]['q']=w[r]['q']||[]).push(
arguments)&rbrace;,w[r]['t']=1*new Date&rbrace;)(window,'_rampJs')&semi;
        _rampJs()&semi;
    &lt;/script&gt;
    ...
    &lt;meta name="keywords" content="Accounting Software, Accounting Degrees, Accounting Services, Tax Return Options"&gt;
    ...
&lt;/head&gt;
&lt;body&gt;
    ...
    &lt;div id=”rampjs_slot1”&gt;&lt;/div&gt;
    ...
    &lt;div id=”rampjs_slot2”&gt;&lt;/div&gt;
    ...
&lt;/body&gt;
&lt;/html&gt;
                </pre>
            </code>
        </div>
    </li>
</ol>