import { Injectable } from '@angular/core';
import { ObjectValidatorService } from '../../../../../core/object-validator-service/object-validator.service';
import { JsonEditorValidator } from './jsoneditor-validator.service';

@Injectable({
  providedIn: 'root'
})
export class InputMaxLengthValidatorService extends JsonEditorValidator {
  constructor(protected _ovs: ObjectValidatorService) {
    super();
    this.errorMessage = 'Input must have maxLength attribute';
  }

  override isMatch(obj: any): boolean {
    return (
      this._ovs.isObject(obj) && 'type' in obj && obj['type'].toString().toLowerCase() === 'input'
    );
  }

  isValid(obj: any): boolean {
    return (
      this._ovs.isObject(obj) &&
      this._ovs.isObject(obj['templateOptions']) &&
      'maxLength' in obj['templateOptions']
    );
  }
}
