import { Injectable } from '@angular/core';
import { FilterItem } from '../models/filter-item';
import { DateTime } from 'luxon';
import { FilterBox } from '../filter-box';
import { FilterDefaults } from '../models/filter-defaults';
import { RangeType } from '../range/range.model';
import { FilterConfiguration } from '../models/filter-configuration';
import { FilterBoxInit } from '../models/filter-box-init';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { QRow } from '../../q-models/q-row';
import {
  ColumnConfiguration,
  ColumnType,
  PipeType,
} from '../../q-models/column-configuration';
import { ColumnConfigurationBuilderFactory } from '../../q-services/column-configuration-builder-factory';
import { GridCallbackParameter } from '../../q-models/grid-callback-parameter';
// Filter box doesn't support dynamic columns (columns that use different field mapping based on the row's content).
@Injectable()
export class FilterBoxItemsBuilderService {
  constructor(
    private _ovs: ObjectValidatorService,
    private _columnConfigurationBuilderFactory: ColumnConfigurationBuilderFactory,
  ) {}

  initColFilter(
    data: any[],
    includeGlobalFilter: boolean = false,
    columnNamesWithListFilter: string[] = [],
    dateColumnNames?: string[],
    listCallback?,
  ): FilterItem[] {
    if (!this._ovs.isDefined(data) || !this._ovs.isDefined(data[0])) {
      return [];
    }
    const fi: FilterItem[] = [];
    let min = 0;
    const sampleRow = data[0];
    const cols: string[] = Object.keys(sampleRow).map(x => x);
    const types: string[] = Object.keys(sampleRow).map(
      x => typeof sampleRow[x],
    );
    let max = 0;
    let list: string[] = [];
    if (includeGlobalFilter) {
      cols[cols.length] = FilterBox.Global;
      types[types.length] = 'string';
    }
    for (let i = 0; i < cols.length; i++) {
      if (
        (types[i] === 'number' ||
          (this._ovs.isDefined(dateColumnNames) &&
            dateColumnNames.includes(cols[i]))) &&
        this._ovs.isDefined(data)
      ) {
        const minMax = this.findMinAndMax(cols[i], data, dateColumnNames);
        min = minMax.min;
        max = minMax.max;
      } else {
        min = 0;
        max = 0;
      }
      list =
        columnNamesWithListFilter.length !== 0 &&
        columnNamesWithListFilter.includes(cols[i])
          ? !this._ovs.isDefined(listCallback)
            ? this.findList(cols[i], data)
            : listCallback(cols[i])
          : [];
      fi.push(
        new FilterItem(
          cols[i],
          null,
          new FilterDefaults(
            new RangeType(min, max, min, max),
            '',
            '',
            list,
            FilterConfiguration.All,
            false,
            [],
            FilterConfiguration.CustomAll,
          ),
        ),
      );
    }
    return fi;
  }

  initQGridColFilter(
    grid: QRow[],
    columns: ColumnConfiguration[],
    filterBoxInit: FilterBoxInit,
  ): FilterItem[] {
    if (
      !this._ovs.isDefined(grid) ||
      !this._ovs.isDefined(grid[0]) ||
      !this._ovs.isDefined(grid[0].value)
    ) {
      return [];
    }
    const fi: FilterItem[] = [];
    let min: number;
    const visibleColumns = columns.filter(
      c => c.isColumnVisible() && this._ovs.isDefined(c.filterConfiguration),
    );
    if (filterBoxInit.globalFilter) {
      visibleColumns.push(
        this._columnConfigurationBuilderFactory.builder
          .withField(FilterBox.Global)
          .withColumnType(ColumnType.String)
          .build(null),
      );
    }
    let max: number;
    let list: string[] = [];
    let customValues: string[] = [];
    visibleColumns.forEach(col => {
      if (
        (col.columnType === ColumnType.Number ||
          (this._ovs.isDefined(filterBoxInit.dateColumnNames) &&
            filterBoxInit.dateColumnNames.includes(col.fieldName))) &&
        this._ovs.isDefined(grid)
      ) {
        const minMax = this.findMinAndMax(
          col.fieldName,
          grid.map(x => x.value),
          filterBoxInit.dateColumnNames,
          col.pipeType === PipeType.Percent,
        );
        min = minMax.min;
        max = minMax.max;
      } else {
        min = 0;
        max = 0;
      }
      list =
        col.filterConfiguration && col.filterConfiguration.showEquals
          ? !this._ovs.isDefined(filterBoxInit.listCallback)
            ? this.findList(
                col.fieldName,
                grid.map(x => x.value),
                col,
                grid,
              )
            : filterBoxInit.listCallback(col.fieldName)
          : [];
      customValues =
        col.filterConfiguration &&
        col.filterConfiguration.isCustomComponentEnabled()
          ? this.findList(
              col.fieldName,
              grid.map(x => x.value),
              col,
              grid,
            )
          : [];
      fi.push(
        new FilterItem(
          col.fieldName,
          null,
          new FilterDefaults(
            new RangeType(min, max, min, max),
            '',
            '',
            list,
            FilterConfiguration.All,
            false,
            customValues,
            FilterConfiguration.CustomAll,
          ),
        ),
      );
    });
    return fi;
  }

  findMinAndMax(
    field: string,
    table: any,
    dateColumnNames?: string[],
    percentage: boolean = false,
  ): { min: number; max: number } {
    // calculate min and max for normal number column type
    let list: any[] = table
      .map(row => row[field])
      .filter(x => this._ovs.isDefined(x))
      .map(x => (percentage ? Math.round(x * 100) : x));

    // calculate min and max for date columns in days, if not in hours
    if (
      this._ovs.isDefined(dateColumnNames) &&
      dateColumnNames.includes(field)
    ) {
      const dateToNumberList: number[] = [];
      list.forEach(date => {
        let totalDays = DateTime.now().diff(
          DateTime.fromISO(date),
          'days',
        ).days;
        if (totalDays === 0) {
          totalDays =
            DateTime.now().diff(DateTime.fromISO(date), 'hours').hours / 24;
        }

        dateToNumberList.push(parseFloat(totalDays.toFixed(1)));
      });
      list = dateToNumberList;
    }
    return { min: Math.min(...list), max: Math.max(...list) };
  }

  findList(
    field: string,
    table: any,
    column?: ColumnConfiguration,
    rows?: QRow[],
  ): string[] {
    let result: string[] = [];
    let list: any = [];

    if (
      this._ovs.isDefined(column) &&
      !this._ovs.isNullOrEmpty(rows) &&
      this._ovs.isFunction(column.filterBoxValue)
    ) {
      list.push(
        ...rows.map(qRow =>
          column.filterBoxValue(qRow.getGridCallbackParameter()),
        ),
      );
      list = list
        .join(',')
        .split(',')
        .map(x => new Object({ [field]: x }));
    } else {
      list = table;
    }
    result.push(
      ...list.map(x => x[field]).filter((x, i, a) => x && a.indexOf(x) === i),
    );
    result.sort();
    const allIndex = result.findIndex(x => x === 'All');
    if (allIndex > -1) {
      result.splice(allIndex, 1);
    }
    if (this._ovs.isNullOrEmpty(result)) {
      result = [];
    }
    result.unshift(FilterConfiguration.All);
    return result;
  }
}
