import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectManager } from '../../core/subject-manager';

@Injectable()
export class StepperResetService {
  stepperReset$: Observable<any>;
  private _subjectManager: SubjectManager = new SubjectManager();

  constructor() {
    this._subjectManager.register('stepperReset');
    this.stepperReset$ = this._subjectManager.getObservable('stepperReset');
  }

  resetStepper() {
    this._subjectManager.next('stepperReset');
  }

  ngOnDestroy() {
    this._subjectManager.clear();
  }
}
