import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

// v6 uses props.label. Check documentation.
@Component({
  selector: 'app-section-wrapper',
  templateUrl: './section-wrapper.component.html',
  styleUrls: ['./section-wrapper.component.scss'],
})
export class SectionWrapperComponent extends FieldWrapper {
  constructor(public ovs: ObjectValidatorService) {
    super();
  }
}
