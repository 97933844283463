import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';

@Component({
  selector: 'app-form-type',
  templateUrl: './form-type.component.html',
  styleUrls: ['./form-type.component.scss']
})
export class FormTypeComponent extends FieldType<FieldTypeConfig> implements OnInit {
  formlyFieldConfigs: FormlyFieldConfig[] = [];
  _form(): any {
    return this.form;
  }
  constructor(private _ovs: ObjectValidatorService) {
    super();
  }

  ngOnInit(): void {
    if (
      !this._ovs.isString(this.formControl?.value) ||
      this._ovs.isNullOrEmpty(this.formControl?.value)
    ) {
      this.formlyFieldConfigs = [
        {
          type: 'input',
          defaultValue: 'This form is empty.',
          templateOptions: {
            label: 'Warning',
            readonly: true
          }
        }
      ];
      return;
    }

    try {
      this.formlyFieldConfigs = JSON.parse(this.formControl.value);
    } catch (error) {
      this.formlyFieldConfigs = [
        {
          type: 'input',
          defaultValue: 'This form cannot be shown as it is not a valid object.',
          templateOptions: {
            label: 'Error',
            readonly: true
          }
        }
      ];
    }
  }
}
