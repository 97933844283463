import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { SchemasActions } from './schemas.actions';
import { Schema } from './schemas.models';
import { selectAllSchemas } from './schemas.selectors';
import { Injectable } from '@angular/core';

@Injectable()
export class SchemasService {
  schemas$: Observable<Schema[]> = this._store
    .select(selectAllSchemas)
    .pipe(map(schemas => schemas as Schema[]));

  constructor(private _store: Store<Schema>) {}

  initializeSchemaStore() {
    this._store.dispatch(SchemasActions.initializeSchemaStore());
  }

  getSchemaFields(id: string): Observable<string> {
    return this.schemas$.pipe(
      map(schemas => schemas.find(schema => schema.id === id)?.schema ?? []),
    ) as Observable<string>;
  }
}
