import { GridCopyPaste } from './grid-copy-paste';
import { ReplaySubject, Subject } from 'rxjs';
import {
  DistinctNumberStack,
  MouseEventParameter,
  NumberRange,
} from './copy-model';
import { debounceTime } from 'rxjs/operators';
import { ColumnConfiguration } from './column-configuration';
import { SubscriptionManager } from '../shared/subscription-manager';
import { QGrid } from './q-grid';
import { HighlightArea } from './highlight-area';
import { Area } from './area';
import { AreaSubject } from './area-subject';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export class GridMouseEditFeatures {
  private _subscriptionManager = new SubscriptionManager();
  private _handlePX = 0;
  private _handlePY = 0;
  private _stepCounter = 0;
  private _cellHeight: number;
  private _mouseMoveSubject = new Subject<MouseEventParameter>();
  private _firstRow = 0;
  private _firstCol = 0;
  private _isCopyNodeEnabled: boolean;
  private _mouseSelection: boolean;
  public highlightArea: HighlightArea;
  public copySourceArea: Area;
  public copyTargetArea: Area;
  public highlightSubject: ReplaySubject<AreaSubject> =
    new ReplaySubject<AreaSubject>();
  public copySourceAreaSubject: ReplaySubject<AreaSubject> =
    new ReplaySubject<AreaSubject>();
  public copyTargetAreaSubject: ReplaySubject<AreaSubject> =
    new ReplaySubject<AreaSubject>();

  get isCopyNodeEnabled(): boolean {
    return this._isCopyNodeEnabled;
  }

  public get highlightedColRange(): NumberRange {
    return new NumberRange(
      this.currentHighlightedColumns.min,
      this.currentHighlightedColumns.max,
    );
  }

  public get highlightedRowRange(): NumberRange {
    return new NumberRange(
      this.currentHighlightedRows.min,
      this.currentHighlightedRows.max,
    );
  }

  public get copiedCols(): DistinctNumberStack {
    return this._gridCopyPaste.copiedCols;
  }

  public get copiedRows(): DistinctNumberStack {
    return this._gridCopyPaste.copiedRows;
  }

  public get copyTargetCols(): DistinctNumberStack {
    return this._gridCopyPaste.copyTargetColumns;
  }

  public get copyTargetRows(): DistinctNumberStack {
    return this._gridCopyPaste.copyTargetRows;
  }

  public get subscriptionManager(): SubscriptionManager {
    return this._subscriptionManager;
  }

  constructor(
    public items: QGrid,
    editable: boolean,
    private _gridCopyPaste: GridCopyPaste,
    private _refreshSubject: ReplaySubject<void>,
    public currentHighlightedRows: DistinctNumberStack,
    public currentHighlightedColumns: DistinctNumberStack,
    private _canAddRowOnPaste: () => boolean,
    private _viewRowMax: () => number,
    private _colMax: () => number,
    private _addNewRow: () => void,
    private _subscribeToKeyboardService: () => void,
    private _exitEditingCell: () => void,
    _ovs: ObjectValidatorService,
  ) {
    this.highlightArea = new HighlightArea(_ovs);
    this.copySourceArea = new Area(_ovs);
    this.copyTargetArea = new Area(_ovs);
    this.subscriptionManager.register(
      this._mouseMoveSubject
        .asObservable()
        .pipe(debounceTime(55))
        .subscribe(param => {
          this._calculateNewHighlightArea(param);
          if (this._mouseSelection) {
            this._refreshSubject.next(null);
          }
        }),
    );
    this.subscriptionManager.register(
      this.currentHighlightedRows.changedSubject
        .asObservable()
        .subscribe((rowRange: NumberRange) => {
          this.highlightSubject.next(
            new AreaSubject(
              rowRange,
              currentHighlightedColumns.getNumberRange(),
            ),
          );
        }),
    );
    this.subscriptionManager.register(
      this.currentHighlightedColumns.changedSubject
        .asObservable()
        .subscribe((colRange: NumberRange) => {
          this.highlightSubject.next(
            new AreaSubject(currentHighlightedRows.getNumberRange(), colRange),
          );
        }),
    );
    if (editable) {
      // copy source area
      this.subscriptionManager.register(
        this.copiedRows.changedSubject.subscribe((rowRange: NumberRange) => {
          this.copySourceAreaSubject.next(
            new AreaSubject(rowRange, this.copiedCols.getNumberRange()),
          );
        }),
      );
      this.subscriptionManager.register(
        this.copiedCols.changedSubject.subscribe((colRange: NumberRange) => {
          this.copySourceAreaSubject.next(
            new AreaSubject(this.copiedRows.getNumberRange(), colRange),
          );
        }),
      );
      // copy target area
      this.subscriptionManager.register(
        this.copyTargetRows.changedSubject.subscribe(
          (rowRange: NumberRange) => {
            this.copyTargetAreaSubject.next(
              new AreaSubject(rowRange, this.copyTargetCols.getNumberRange()),
            );
          },
        ),
      );
      this.subscriptionManager.register(
        this.copyTargetCols.changedSubject.subscribe(
          (colRange: NumberRange) => {
            this.copyTargetAreaSubject.next(
              new AreaSubject(this.copyTargetRows.getNumberRange(), colRange),
            );
          },
        ),
      );
    }
  }

  startCopyArea(event: MouseEvent) {
    this._isCopyNodeEnabled = true;
    this._handlePX = event.x;
    this._handlePY = event.y;
    this._gridCopyPaste.copy();
    this._gridCopyPaste.copyTargetColumns.reset();
    this._gridCopyPaste.copyTargetRows.reset();
    this._refreshSubject.next(null);
  }

  private _handleMove(
    x: number,
    y: number,
    clientHeight: number,
    row: number,
    col: number,
  ) {
    if (this._isCopyNodeEnabled === false) {
      return;
    }
    const dx = x - this._handlePX;
    const dy = y - this._handlePY;

    if (
      this.currentHighlightedRows.contains(row) &&
      this.currentHighlightedColumns.contains(col)
    ) {
      this._gridCopyPaste.copyTargetRows.reset();
      this._gridCopyPaste.copyTargetColumns.reset();
      return;
    }

    if (
      this.currentHighlightedRows.contains(row) &&
      this.currentHighlightedColumns.notContains(col)
    ) {
      this._gridCopyPaste.copyVertical = false;
    } else if (
      this.currentHighlightedRows.notContains(row) &&
      this.currentHighlightedColumns.contains(col)
    ) {
      this._gridCopyPaste.copyVertical = true;
    } else {
      this._gridCopyPaste.copyVertical = Math.abs(dy) > Math.abs(dx);
    }

    if (
      (this._gridCopyPaste.copyVertical === false &&
        col === this._gridCopyPaste.copyTargetColumns.last) ||
      (this._gridCopyPaste.copyVertical &&
        row === this._gridCopyPaste.copyTargetRows.last)
    ) {
      return;
    }

    if (this._gridCopyPaste.copyVertical) {
      this._stepCounter = dy / (clientHeight + 0.5);
      if (this._stepCounter < 0) {
        this._stepCounter += -(
          this.currentHighlightedRows.min - this.currentHighlightedRows.max
        );
      }
    } else {
      if (col < this.currentHighlightedColumns.min) {
        this._stepCounter = col - this.currentHighlightedColumns.min;
      }
      if (col > this.currentHighlightedColumns.max) {
        this._stepCounter = col - this.currentHighlightedColumns.max;
      }
      if (this._stepCounter < 0) {
        this._stepCounter += -(
          this.currentHighlightedColumns.min -
          this.currentHighlightedColumns.max
        );
      }
    }
    this._expandCopyArea(
      this._gridCopyPaste.copyVertical,
      row,
      col,
      this.currentHighlightedRows,
      this.currentHighlightedColumns,
      this._gridCopyPaste.copyTargetRows,
      this._gridCopyPaste.copyTargetColumns,
    );
  }

  private _expandCopyArea(
    isVertical: boolean,
    row: number,
    col: number,
    currentHighlightedRows: DistinctNumberStack,
    currentCol: DistinctNumberStack,
    rowList: DistinctNumberStack,
    colList: DistinctNumberStack,
  ) {
    const viewRowMax = this._viewRowMax();
    let _control = isVertical ? viewRowMax : this._colMax();
    let _reverse = false;
    const currentHighlightedRowsCol = isVertical
      ? currentHighlightedRows
      : currentCol;
    const otherRowCol = isVertical ? currentCol : currentHighlightedRows;
    const rowCol = isVertical ? row : col;

    if (isVertical && row === -1 && col === -1 && rowList.max === viewRowMax) {
      this._addNewRow();
      return;
    }

    rowList.reset();
    colList.reset();
    if (currentHighlightedRowsCol.notContains(rowCol)) {
      let _rowColMin = 0;
      let _rowColMax = 0;
      if (rowCol < currentHighlightedRowsCol.min) {
        _rowColMin = currentHighlightedRowsCol.min - 1;
        _rowColMax = rowCol;
      } else {
        _rowColMin = currentHighlightedRowsCol.max + 1;
        _rowColMax = rowCol;
      }
      _reverse = _rowColMin > _rowColMax;
      for (
        let rc = _rowColMin;
        _reverse ? rc >= _rowColMax : rc <= _rowColMax;
        rc += _reverse ? -1 : 1
      ) {
        if (isVertical) {
          rowList.push(rc);
        } else {
          colList.push(rc);
        }
        _control--;
        if (_control < 0) {
          return;
        }
      }
    }
    for (let i = otherRowCol.min; i <= otherRowCol.max; i++) {
      if (isVertical) {
        colList.push(i);
      } else {
        rowList.push(i);
      }
    }
  }

  private _expandHighlightArea(
    isVertical: boolean,
    row: number,
    col: number,
    currentHighlightedRows: DistinctNumberStack,
    currentCol: DistinctNumberStack,
  ) {
    const viewRowMax = this._viewRowMax();
    let _control = isVertical ? viewRowMax : this._colMax();
    let _reverse = false;
    const rowCol = isVertical ? row : col;
    const minRowCol = isVertical ? this._firstRow : this._firstCol;
    // if (copyVertical) {
    if (
      isVertical &&
      row === -1 &&
      col === -1 &&
      currentHighlightedRows.max === viewRowMax
    ) {
      // to add code for scroll down here *************
    }

    let _rowColMin = 0;
    let _rowColMax = 0;
    if (isVertical) {
      currentHighlightedRows.reset();
    } else {
      currentCol.reset();
    }
    if (rowCol < minRowCol) {
      _rowColMin = rowCol;
      _rowColMax = minRowCol;
    } else {
      _rowColMin = minRowCol;
      _rowColMax = rowCol;
    }
    _reverse = _rowColMin > _rowColMax;
    for (
      let rc = _rowColMin;
      _reverse ? rc >= _rowColMax : rc <= _rowColMax;
      rc += _reverse ? -1 : 1
    ) {
      if (isVertical) {
        currentHighlightedRows.push(rc);
      } else {
        currentCol.push(rc);
      }
      _control--;
      if (_control < 0) {
        return;
      }
    }
  }

  handleUp(event: MouseEvent) {
    this._isCopyNodeEnabled = false;
    event.stopPropagation();
    this._gridCopyPaste.calcPaste();
  }

  // Highlighting mouse operations
  startHighlightArea(param: MouseEventParameter) {
    this._subscribeToKeyboardService();
    if (
      this.currentHighlightedRows.notContains(param.rowCol.row) &&
      this.currentHighlightedColumns.notContains(param.rowCol.col)
    ) {
      this._exitEditingCell();
    }

    if (this._isCopyNodeEnabled) {
      // mouse up happened outside of the grid
      this._isCopyNodeEnabled = false;
      this._gridCopyPaste.copyTargetColumns.reset();
      this._gridCopyPaste.copyTargetRows.reset();
      this._gridCopyPaste.resetCopy();
    }

    this._mouseSelection = true;
    this._cellHeight = (param.mouseEvent.target as any).clientHeight;

    if (param.mouseEvent.shiftKey) {
      this._calculateNewHighlightArea(param);
    } else {
      this._firstRow = param.rowCol.row;
      this._firstCol = param.rowCol.col;
      this.currentHighlightedColumns.emptyAndPushValue(param.rowCol.col);
      this.currentHighlightedRows.emptyAndPushValue(param.rowCol.row);
      this._refreshSubject.next(null);
    }
  }

  private _calculateNewHighlightArea(param: MouseEventParameter) {
    let needsRefresh = false;
    if (
      this._mouseSelection &&
      param.rowCol.row !== -1 &&
      param.rowCol.col !== -1
    ) {
      if (
        param.rowCol.col === this.currentHighlightedColumns.last &&
        param.rowCol.row === this.currentHighlightedRows.last
      ) {
        return;
      }

      const dx = param.mouseEvent.x - this._handlePX;
      const dy = param.mouseEvent.y - this._handlePY;
      this._gridCopyPaste.copyVertical = Math.abs(dy) > Math.abs(dx);

      // column select
      if (this.currentHighlightedColumns.last !== param.rowCol.col) {
        this._expandHighlightArea(
          false,
          param.rowCol.row,
          param.rowCol.col,
          this.currentHighlightedRows,
          this.currentHighlightedColumns,
        );
      }
      if (this.currentHighlightedRows.last !== param.rowCol.row) {
        // row select
        this._expandHighlightArea(
          true,
          param.rowCol.row,
          param.rowCol.col,
          this.currentHighlightedRows,
          this.currentHighlightedColumns,
        );
      }
      needsRefresh = true;
    }
    if (this._isCopyNodeEnabled) {
      this._handleMove(
        param.mouseEvent.x,
        param.mouseEvent.y,
        this._cellHeight,
        param.rowCol.row,
        param.rowCol.col,
      );
      needsRefresh = true;
    }
    if (needsRefresh) {
      this._refreshSubject.next(null);
    }
  }

  endHighlightArea(param: MouseEventParameter) {
    this._mouseSelection = false;
    if (this._isCopyNodeEnabled) {
      this._isCopyNodeEnabled = false;
      param.mouseEvent.stopPropagation();
      this._gridCopyPaste.calcPaste();
    }
    this._refreshSubject.next(null);
  }

  addRowOnCopy(event: MouseEvent, row: number, col: number) {
    if (event.buttons !== 1 || this._canAddRowOnPaste() === false) {
      return;
    }
    this._calculateNewHighlightArea({
      mouseEvent: event,
      rowCol: { row, col },
    });
    this._cellHeight = 0;
  }

  changeHighlightArea(param: MouseEventParameter) {
    this._mouseMoveSubject.next(param);
  }

  highlightRow(
    _key: number,
    colMin: number,
    colMax: number,
    multiSelect: boolean,
  ): void {
    this._firstRow = _key;
    this._firstCol = 0;
    if (!multiSelect) {
      this.currentHighlightedRows.emptyAndPushValue(this._firstRow);
    } else if (this.currentHighlightedRows.notContains(this._firstRow)) {
      const xRow =
        this.currentHighlightedRows.max <= this._firstRow
          ? this.currentHighlightedRows.min
          : this.currentHighlightedRows.max;
      this.currentHighlightedRows.reset();
      this.currentHighlightedRows.pushRangeBetween(
        xRow < this._firstRow ? xRow : this._firstRow,
        xRow < this._firstRow ? this._firstRow : xRow,
      );
    }
    this.currentHighlightedColumns.reset();
    this.currentHighlightedColumns.pushRangeBetween(colMin, colMax);
    this._refreshSubject.next(null);
  }

  highlightCol(
    column: ColumnConfiguration,
    rowMin: number,
    rowMax: number,
    multiSelect: boolean,
  ): void {
    this._firstRow = rowMin;
    this._firstCol = column.visibleColumnNumber;
    this.currentHighlightedRows.reset();
    this.currentHighlightedRows.pushRangeBetween(rowMin, rowMax);
    if (!multiSelect) {
      this.currentHighlightedColumns.emptyAndPushValue(
        column.visibleColumnNumber,
      );
    } else if (this.currentHighlightedColumns.notContains(this._firstCol)) {
      const xCol =
        this.currentHighlightedColumns.max <= this._firstCol
          ? this.currentHighlightedColumns.min
          : this.currentHighlightedColumns.max;
      this.currentHighlightedColumns.reset();
      this.currentHighlightedColumns.pushRangeBetween(
        xCol < this._firstCol ? xCol : this._firstCol,
        xCol < this._firstCol ? this._firstCol : xCol,
      );
    }
    this._refreshSubject.next(null);
  }

  resetMouseFlags() {
    this._mouseSelection = false;
    this._isCopyNodeEnabled = false;
  }
}
