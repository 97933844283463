import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { ColumnClassHelper } from "../shared/columnClassHelper";

@Component({
    selector: 'app-accessing-api-reports',
    templateUrl: './accessing-api-reports.component.html',
   // styleUrl: '/accessing-api-reports.component.scss',
    standalone: true,
    imports: [CommonModule]
})
export class AccessingApiReportsComponent {
    constructor (
      public columnClassHelper: ColumnClassHelper
    ) {}
    @Input() possibleReportTypeValues: string[];
    endpointOverview = {
    headers: ['Endpoint', 'Description', 'Response'],
    records: [
        {
          endpoint: 'Report',
          description:  'This endpoint accepts parameters that describe the desired report and returns the report_id, which is used in all subsequent calls.',
          response: 'report_id',
        },
        {
          endpoint: 'Status',
          description:  'This endpoint provides the status of the report that is specified in the URL with the report_id. The report_id is provided in the response from the previous API endpoint. A successful response from the Status Endpoint will also include the link that should be used to request the report download link from the Content Endpoint.',
          response: 'status, content endpoint URL',

        },
        {
          endpoint: 'Content',
          description:  'This endpoint returns an HTML code block containing a download link for the requested report. The HTML code can be rendered in a browser window to display the link to a user, or a script can access the link directly. ',
          response: 'HTML code with download link',

        },
    ],
   };
   reportEndpointParams = {
    headers: ['Parameter Name', 'Definition', 'Possible Values', 'Default'],
    records: [
        {
          parameter_name: 'report_type',
          required: true,
          definition:  'Used to indicate if the daily campaign report, daily summary report, or the hourly campaign report should be returned.',
        },
        {
          parameter_name: 'days',
          definition:  'The number of days for which data should be provided, ending on the "date".',
          default: '1',
        },
        {
          parameter_name: 'date',
          definition:  'The last date for which data should be provided.',
          default: 'current date',
        },
        {
          parameter_name: 'auth_key',
          required: true,
          definition:  'The auth key provided by your account manager, which should be guarded carefully.',
        },
    ],
   };

  possibleValues(parameterName: string): string[] {
    switch (parameterName) {
      case 'report_type':
        return this.possibleReportTypeValues;
      case 'days':
        return ['Any number 1 - 14.'];
      case 'date':
        return ['Any date in the format YYYY-MM-DD.'];
      case 'auth_key':
        return ['Consult your account manager for your auth_key.'];
      default:
        return [];
    }
  }
}