import { Injectable } from "@angular/core";
import { VideoInfo } from "../video-walkthroughs/video-playlist/models";

@Injectable({
    providedIn: 'root'
})
export class VideosService {
    getVideos(setName: string): VideoInfo[] {
        switch (setName) {
            case 'walkthroughs':
                return [
                    {
                        title: 'Domain Creator',
                        thumbnailUrl: '',
                        src: 'https://s.flocdn.com/@ramp-partner/tutorials/DomainCreator.mp4'
                    },
                    {
                        title: 'Domain Manager',
                        thumbnailUrl: '',
                        src: 'https://s.flocdn.com/@ramp-partner/tutorials/DomainManager.mp4'
                    },
                    {
                        title: 'Subvertical Requests',
                        thumbnailUrl: '',
                        src: 'https://s.flocdn.com/@ramp-partner/tutorials/SubverticalRequests.mp4'
                    },
                ];
            default:
                return [];
        }
    }
}