import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDockComponent } from './form-dock.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../../core/angular-material.module';
import { LibFormModule } from '../../../form/lib-form.module';

@NgModule({
  declarations: [FormDockComponent],
  exports: [FormDockComponent],
  imports: [CommonModule, AngularMaterialModule, FormsModule, ReactiveFormsModule, LibFormModule]
})
export class FormDockModule {}
