<h3>Accessing API Reports</h3>
    <p>
        Our API endpoints are asynchronous in order to facilitate requests for potentially large reports that may not be available immediately. This means that instead of making a single request to one API endpoint and waiting for a response, you will make a total of three API requests, one to each of the following endpoints, in the order listed: 
    </p>
    <div class="documentation-grid-container">
        <div
        *ngFor="let header of endpointOverview.headers; index as i"
        [class]="columnClassHelper.getColumnHeaderClass(endpointOverview, i)">
            <span class="documentation bold">{{ header }}</span>
        </div>
        <ng-container *ngFor="let record of endpointOverview.records">
            <div [class]="columnClassHelper.getColumnValueClass(0, endpointOverview)">
                <span class="documentation bold">{{ record.endpoint }} Endpoint</span>
            </div>
            <div [class]="columnClassHelper.getColumnValueClass(1, endpointOverview)">
                <span>{{ record.description }}</span>
            </div>
            <div [class]="columnClassHelper.getColumnValueClass(2, endpointOverview)">
                <span>
                {{ record.response }}
                </span>
            </div>
        </ng-container>
    </div>
    <p>These endpoints are designed for programmatic usage. For an optimal experience, API customers should plan to use a script to access our reports. The script should make a single call to the Report Endpoint to obtain the report_id, then use that report_id to call the Status Endpoint approximately every 30 seconds until the response indicates the report is ready. The script can then call the Content Endpoint to receive a download link for the requested data. Detailed information about how to call each endpoint is provided below.</p>
      <ol>
        <li>
            <h3>Report Endpoint</h3>
            <p>Use this endpoint initially to request your report. The response will include the report_id that you’ll use in all subsequent API calls.
            </p>
            <h4>Parameters</h4>
            <p>
                The Report Endpoint accepts the following parameters. Parameters can be provided in any order. <strong>Parameters annotated with an asterisk are required.</strong>
            </p>
            <div class="documentation-grid-container endpoint-params">
                <div
                *ngFor="let header of reportEndpointParams.headers; index as i"
                [class]="columnClassHelper.getColumnHeaderClass(reportEndpointParams, i)">
                    <span class="documentation bold">{{ header }}</span>
                </div>
                <ng-container *ngFor="let record of reportEndpointParams.records">
                    <div [class]="columnClassHelper.getColumnValueClass(0, reportEndpointParams)">
                        <span class="documentation bold">{{ record.parameter_name }}</span>
                        <span *ngIf="record.required"> *</span>
                    </div>
                    <div [class]="columnClassHelper.getColumnValueClass(1, reportEndpointParams)">
                        <span>{{ record.definition }}</span>
                    </div>
                    <div [class]="columnClassHelper.getColumnValueClass(2, reportEndpointParams)">
                        <ul class="possible-values-list">
                            <li *ngFor="let value of possibleValues(record.parameter_name)">
                                {{ value }} 
                            </li>
                        </ul>
                    </div>
                    <div [class]="columnClassHelper.getColumnValueClass(3, reportEndpointParams)">
                        <span>
                        {{ record.default || 'N/A'}}
                        </span>
                    </div>
                </ng-container>
            </div>
            <h4>Example Usage</h4>
            <h5>Request:</h5>
            <div class="code-block">
                <code>
                    <pre> $ curl -X POST -i 'https://reports.system1.com/partner/v1/report?report_type=syndication_afd_keyword_daily&days=14&date=2024-01-10&auth_key=AUTH_KEY_REDACTED' </pre>
                </code>
            </div>
            <h5>Response:</h5>
            <div class="code-block">
                <code>
                    <pre> &lbrace;"report_id": "[redacted]"&rbrace; </pre>
                </code>
            </div>
        </li>
        <li>
            <h3>Status Endpoint</h3>
            <p>
                Use this endpoint to retrieve the current status of a report that was previously requested using the Report Endpoint. The report_id of the report in question must be included in the URL. The report_id is included in the Report Endpoint response. 
            </p>
            <p>We recommend querying the Status Endpoint approximately every 30 seconds until a success or failure response is received.</p>
            <p>Your auth key is the only parameter accepted by the Status Endpoint. It is required. </p>
            <h4>Example Usage</h4>
            <h5>Request:</h5>
            <div class="code-block">
                <code>
                    <pre> $ curl  'https://reports.system1.com/partner/v1/report/REPORT_ID_REDACTED/status?auth_key=AUTH_KEY_REDACTED' </pre>
                </code>
            </div>
            <h5>Response:</h5>
            <p>The status endpoint will produce one of the following responses:</p>
            <ul>
                <li>success response:
                    <ul>
                        <li>
                            <div class="code-block">
                                <code>
                                    <pre> &lbrace;"report_status": "SUCCESS", "content_url": "/partner/v1/report/REDACTED/content?auth_key=REDACTED"&rbrace; </pre>
                                </code>
                            </div>
                        </li>
                        <li>
                            Use the content_url as provided to request your data from the Content Endpoint.
                        </li>
                    </ul>
                </li>
                <li>failure response:
                    <ul>
                        <li>
                            <div class="code-block"><code><pre>&lbrace;"report_status": "FAILED"&rbrace;</pre></code></div>
                        </li>
                        <li>
                            If there is no data available for the date range you have selected, you will receive the failure response. 
                        </li>
                    </ul>
                </li>
                <li>pending response:
                    <ul>
                        <li>
                            <div class="code-block"><code><pre>&lbrace;"report_status": "PENDING"&rbrace;</pre></code></div>
                        </li>
                    </ul>
            </ul>
        </li>
        <li>
            <h3>Content Endpoint</h3>
            <p>
                The Content Endpoint returns an HTML code block containing a download link for the requested report. The HTML code can be rendered in a browser window to display the link to a user, or a script can access the link directly. 
            </p>
            <p>
                The Content Endpoint will only provide the expected response after the Status Endpoint has returned a success response.
            </p>
            <p>
                Your auth key is the only parameter accepted by the Content Endpoint. It is required. 
            </p>
            <h4>Example Usage</h4>
            <h5>Request</h5>
            <div class="code-block">
                <code>
                    <pre> $ curl  'https://reports.system1.com/partner/v1/report/REPORT_ID_REDACTED/content?auth_key=AUTH_KEY_REDACTED' </pre>
                </code>
            </div>
            <h5>Response</h5>
            <div class="code-block">
                <code>
                    <pre>&lt;!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 3.2 Final//EN"&gt;
&lt;title&gt;Redirecting...&lt;/title&gt;
&lt;h1&gt;Redirecting...&lt;/h1&gt;
&lt;p&gt;You should be redirected automatically to target URL: 
&lt;a href="https://report-api-stage.s3.amazonaws.com/syndication_afd_keyword_daily/..."&gt;
https://report-api-stage.s3.amazonaws.com/syndication_afd_keyword_daily/...&lt;/a&gt;.  If not click the link. </pre>
                </code>
            </div>
        </li>
      </ol>
      <h2>Data Contract</h2>
      <h3>Data Availability</h3>
      <p>Reporting data for a given day will become available at 06:30am PST, on average. System1 report availability is dependent on reporting data from upstream third party advertising partners' platforms. Any data delays originating from upstream providers will also delay report availability.</p>
      <h3>Rate Limiting</h3>
      <p>System1 has a rate limit for each individual API call and for the API as a whole. These limits are conservative. Abuse of the APIs can result in account deactivation, temporary or permanently. </p>
      <p>
        Upon hitting the rate limit, you will receive a 429 error that will include a Retry-After header, which provides the number of seconds you need to wait before making another valid request. In the below output example, the caller should wait at least 17 seconds to ensure that subsequent requests do not run into the rate limit again:
      </p>
      <div class="code-block">
        <code>
            <pre>HTTP/1.0 429 TOO MANY REQUESTS
Retry-After: 17
Content-Type: application/json
Content-Length: 2
Date: Tue, 02 Jan 2018 00:06:40 GMT
Server: nginx
Connection: keep-alive </pre>
        </code>
    </div>
      <h3>Data Disclaimers</h3>
      <h4>Revenue-Based Metrics Disclaimer:</h4>
      <p>Please note:</p>
      <ul>
        <li>All reported revenue-based metrics are prior to revenue share calculations.</li>
        <li>All reported revenue-based metrics are based on estimated revenue.</li>
      </ul>
      <p>
        For additional clarity, please contact your partnership manager.
      </p>
      <h4>Possible Data Discrepancy Disclaimer</h4>
      <p>Some reports are provided as a guide for granularity indications but should not be relied upon for bidding or tROAS conversions. Revenue  metrics may change based on adjustments made by upstream engine providers who identify problematic traffic or clawback opportunities.</p>
      <p>For additional clarity, please contact your partnership manager.</p>