import { RouterModule, Routes } from "@angular/router";
import { OktaAuthGuard } from "@okta/okta-angular";
import { AccessControlGuardService, LayoutComponent } from "@nimbus/shared-lib";
import { SubverticalRequestComponent } from "./subvertical-requests/subvertical-request.component";
import { SubverticalReviewsManagerWrapperComponent } from "./subvertical-reviews/wrappers/subvertical-reviews-manager-wrapper/subvertical-reviews-manager-wrapper.component";
import { NgModule } from "@angular/core";

const routes: Routes = [
    {
        path: 'subverticals',
        component: LayoutComponent,
        canActivate: [OktaAuthGuard, AccessControlGuardService],
        data: {
          resourceName: 'SubverticalsComponent',
        },
        children: [
          {
            path: 'requests',
            component: SubverticalRequestComponent,
            data: {
              resourceName: 'SubverticalRequestComponent',
            },
          },
          {
            path: 'reviews',
            component: SubverticalReviewsManagerWrapperComponent,
            data: {
              resourceName: 'SubverticalReviewsManagerComponent',
            },
          }
        ] 
    }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SubverticalsRoutingModule {}