<div class="video-playlist">
    <mat-card *ngFor="let video of videos" class="video">
        <mat-card-header class="video__title">{{video.title}}</mat-card-header>
        <mat-card-content class="video__content-container">
            <video
              id="{{video.title}}Video"
              preload="auto"
              crossorigin
              controls
              poster=""
              width="500"
              >
                <source src="{{video.src}}" type="video/mp4">
                Download the MP4 of {{video.title}} <a href="{{video.src}}">here</a>.
            </video>
        </mat-card-content>
    </mat-card>
</div>