import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SearchableListItemWithSelect, SortOption } from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map, tap, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { LinksetCacheService } from '../../../../services/linkset-cache.service';
import { FullLanguageCacheService } from '../../../../services/full-language-cache-service';
import { CombineGeoLanguageSubdomainVerticalInputService } from '../../../../services/combine-geo-language-subdomain-vertical-input.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Component({
  selector: 'linkset-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  private readonly _subscriptionManager = new SubscriptionManager();
  readonly autoSort: SortOption = SortOption.AutoSort;
  isDisabled: boolean = true;
  items$: Observable<SearchableListItemWithSelect[]>;
  private readonly vertical_subvertical_country =
    'vertical-subvertical-country';

  constructor(
    public ovs: ObjectValidatorService,
    public linksetCacheService: LinksetCacheService,
    public fullLanguageCacheService: FullLanguageCacheService,
    private _combineGeoLanguageSubdomainInputService: CombineGeoLanguageSubdomainVerticalInputService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.field.props['showFullList']) {
      this._populateFullList();
      this._subscriptionManager.register(
        this.field.formControl.valueChanges
          .pipe(
            tap(language => {
              this._combineGeoLanguageSubdomainInputService.UpdateStaticLanguageValue(
                language,
                this.field,
              );
            }),
          )
          .subscribe(),
      );
    } else {
      this._subscriptionManager.register(
        this._updateOnParentChanged().subscribe(),
      );
    }
  }
  ngOnDestroy(): void {
    this._subscriptionManager.clear();
    this._combineGeoLanguageSubdomainInputService.clear();
  }

  private _updateOnParentChanged(): Observable<any> {
    return this.field.parent?.formControl
      .get(this.vertical_subvertical_country)
      .valueChanges.pipe(
        distinctUntilChanged(),
        map(linksetStr => JSON.parse(linksetStr).availableLinksets[0]),
        tap(linkset => {
          this.isDisabled = this.ovs.isNullOrEmpty(linkset);
          this.items$ = this.linksetCacheService.linksets.pipe(
            map(linksets =>
              Array.from(
                new Map(
                  linksets
                    .filter(
                      l =>
                        l.vertical === linkset.vertical &&
                        l.subVertical === linkset.subVertical &&
                        l.geoTwoLetterCode.toLowerCase() ===
                          linkset.geoTwoLetterCode.toLowerCase() &&
                        this.ovs.isDefined(l.language) &&
                        this.ovs.isDefined(l.twoLetterISOLanguageName),
                    )
                    .map(l => [l.language, l]),
                ).values(),
              ).map(l => {
                return new SearchableListItemWithSelect(
                  l.twoLetterISOLanguageName.toLowerCase().trim(),
                  false,
                  l.language.trim(),
                );
              }),
            ),
          );
        }),
        switchMap(linkset => {
          return this.linksetCacheService.getLanguages(linkset).pipe(
            tap(linksets => {
              if (this.ovs.isNullOrEmpty(linksets)) {
                this.formControl.setValue(null);
                return;
              }
              linksets.sort((a, b) => a.language.localeCompare(b.language));
              const engLinksets = linksets.filter(
                l => l.twoLetterISOLanguageName === 'en',
              );
              if (!this.ovs.isNullOrEmpty(engLinksets)) {
                this.formControl.setValue(
                  engLinksets[0].twoLetterISOLanguageName.toLowerCase(),
                );
              } else {
                this.formControl.setValue(
                  linksets[0].twoLetterISOLanguageName.toLowerCase(),
                );
              }
            }),
          );
        }),
      );
  }

  private _populateFullList(): void {
    this.isDisabled = false;
    this.items$ = this.fullLanguageCacheService.fullLanguage.pipe(
      map(languageList => {
        const availableLang = languageList.filter(
          l => this.ovs.isDefined(l.Name) && this.ovs.isDefined(l.Code),
        );
        const uniqueLangList: any[] = [
          ...new Map(availableLang.map(item => [item.Name, item])).values(),
        ];
        return uniqueLangList
          .map(language => {
            return new SearchableListItemWithSelect(
              language.Code.toLowerCase().trim(),
              false,
              language.Name.trim(),
            );
          })
          .sort((a, b) => a.display.localeCompare(b.display));
      }),
    );
  }
}
