import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavListItemComponent } from './nav-list-item/nav-list-item.component';
import { AngularMaterialModule } from '../../core/angular-material.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NavigationService } from './navigation.service';
import { AccessControlModule } from '../../features/access-control/access-control.module';
import { IsMenuActivePipe } from './is-menu-active.pipe';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UIBlockerService } from './ui-blocker-service/ui-blocker.service';
import { CallbackRunnerModule } from '../../core/callback-runner/callback-runner.module';

@NgModule({
  declarations: [NavListItemComponent, IsMenuActivePipe],
  imports: [
    CommonModule,
    AngularMaterialModule,
    RouterModule,
    FormsModule,
    AccessControlModule,
    MatSlideToggleModule,
    CallbackRunnerModule
  ],
  exports: [NavListItemComponent, IsMenuActivePipe],
  providers: [NavigationService, UIBlockerService]
})
export class NavigationModule {}
