import { Inject, Injectable, Optional } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable({
  providedIn: 'root',
})
export class HttpHeadersBuilderFactory {
  constructor(
    @Optional() @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private _ovs: ObjectValidatorService,
  ) {}

  get builder(): HttpHeadersBuilder {
    return new HttpHeadersBuilder(this?.oktaAuth, this._ovs);
  }
}

export class HttpHeadersBuilder {
  _headers: HttpHeaders;

  constructor(
    @Optional() @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private _ovs: ObjectValidatorService,
  ) {
    this._headers = new HttpHeaders();
  }

  withContentType(contentType?: string): HttpHeadersBuilder {
    this._headers = this._headers.append(
      'Content-Type',
      !contentType || (contentType?.length > 0 ?? false)
        ? 'application/json'
        : contentType,
    );
    return this;
  }

  withAuthorization(providedToken?: any): HttpHeadersBuilder {
    if (this.oktaAuth) {
      const token = !this._ovs.isNullOrEmpty(providedToken)
        ? providedToken
        : this.oktaAuth.getAccessToken();
      if (token) {
        this._headers = this._headers.append(
          'Authorization',
          `Bearer ${token}`,
        );
      }
    }

    return this;
  }

  build(): HttpHeaders {
    return this._headers;
  }
}
