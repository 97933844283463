import { Injectable } from '@angular/core';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { Observable } from 'rxjs';

@Injectable()
export class DomainCreatorWebService {
  constructor(private _webServiceBuilderService: NimbusWebServiceBuilderService) { }

  createDomains(partnerId: number, body: any): Observable<any> {
    return this._webServiceBuilderService.builder
      .withUrl(`partners/${partnerId}/parkeddomains`)
      .build()
      .create(body);
  }
}
