import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-subvertical-review-note',
  templateUrl: './subvertical-review-note.component.html',
  styleUrls: ['./subvertical-review-note.component.scss']
})
export class SubverticalReviewNoteComponent extends FieldType {
  constructor() {
    super();
  }

  formcontrol(): any {
    return this.formControl as any;
  }
}
