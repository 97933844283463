import { DateSelectionType } from './date-selection-type';
import { Injectable } from '@angular/core';
import { DateRange } from './date-range';
import { TimeService } from './time-service';
// Good post on UTC time:  https://stackoverflow.com/questions/26873200/momentjs-getting-javascript-date-in-utc
@Injectable()
export class DateRangeFactory {
  private _dateSelectionType: DateSelectionType;
  constructor(private _timeService: TimeService) {}
  withDateSelection(dateSelectionType: DateSelectionType): DateRangeFactory {
    this._dateSelectionType = dateSelectionType;
    return this;
  }
  build(): DateRange {
    return new DateRange(this._timeService, this._dateSelectionType);
  }
}
