<div ngNonBindable class="documentation tab-content">
  <div class="documentation-sub-header">
    Keywords Dynamic Values
  </div>
  <div>
    We support a number of dynamic values you can pass in a keyword. To do this, simply add the
    parameter surrounded by &lbrace;&rbrace;. This will auto-populate a term based on user's location or the
    calendar.
  </div>
  <div class="table">
    <ul>
      <li>&lbrace;city&rbrace;</li>
      <li>&lbrace;country&rbrace;</li>
      <li>&lbrace;day&rbrace;</li>
    </ul>
    <ul>
      <li>&lbrace;holiday&rbrace;</li>
      <li>&lbrace;month&rbrace;</li>
      <li>&lbrace;state&rbrace;</li>
    </ul>
    <ul>
      <li>&lbrace;year&rbrace;</li>
      <li>&lbrace;zip&rbrace;</li>
    </ul>
    </div>
    <div class="documentation-sub-header">
      Allowable Top Level Domains
    </div>
  <div class="table">
    <ul>
      <li>.live</li>
      <li>.fyi</li>
      <li>.world</li>
      <li>.zone</li>
    </ul>
  </div>