import { ImpersonationShareService } from './impersonation.share.service';
import { Injectable } from '@angular/core';
import { Role } from '../../features/access-control/models/role';
import { LocalStorageKeyFactory } from '../../core/local-storage/local-storage-key-factory';
import { LocalStorageKey } from '../../core/local-storage/local-storage-key.model';
import { LocalStorageManagerService } from '../../core/local-storage/local-storage-manager.service';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { SessionManagerService } from '../../services/session-manager/session-manager.service';
import { UserModel } from '../../services/user/user.model';
import { ConfigService } from '../../core/config.service';

@Injectable()
export class ImpersonationService {
  private readonly _rolesKey: LocalStorageKey;
  private readonly _userKey: LocalStorageKey;
  private _proxyUserName: string;
  private _proxyRoleName: string;
  private _roles: Role[];
  isDebug = false;

  get proxyUserName(): string {
    return this._proxyUserName;
  }

  get proxyRoleName(): string {
    return this._proxyRoleName;
  }

  get proxyRoles(): Role[] {
    return this._roles;
  }

  get isProxyRolesDefined(): boolean {
    return this._ovs.isArray(this._roles) && this._roles.length > 0;
  }

  constructor(
    private _ovs: ObjectValidatorService,
    _localStorageKeyFactory: LocalStorageKeyFactory,
    private _localStorageManagerService: LocalStorageManagerService,
    _sessionManagerService: SessionManagerService,
    private _configService: ConfigService,
    private _impersonationShareService: ImpersonationShareService
  ) {
    this._rolesKey = _localStorageKeyFactory.build('ProxyRoles', 'Rules');
    this._userKey = _localStorageKeyFactory.build('ProxyUser', 'User');
    this._getRoles();
    _sessionManagerService.subscribe(`ImpersonationService-${Date.now()}`, () =>
      this._deleteProxies()
    );
  }

  setProxies(user: UserModel, roles: Role[]) {
    if (this._ovs.isDefined(roles)) {
      const appRoles = roles.filter(role =>
        role.permissions.some(perm =>
          perm.resources.some(
            resource => resource.applicationId === this._configService.getApplicationId()
          )
        )
      );
      this._localStorageManagerService.setKey(this._rolesKey, JSON.stringify(appRoles));
      this._localStorageManagerService.setKey(this._userKey, JSON.stringify(user));
    }
  }

  private _getRoles() {
    const user = JSON.parse(this._localStorageManagerService.getKey(this._userKey)) as UserModel;
    this._roles = JSON.parse(this._localStorageManagerService.getKey(this._rolesKey)) as Role[];

    this._impersonationShareService.startSession(user, this._roles);

    if (!this._ovs.isArray(this._roles)) {
      return;
    }
    this._proxyUserName = user.username;
    this._proxyRoleName = this._roles.length === 1 ? this._roles[0].name : 'All';
  }

  exit() {
    this._deleteProxies();
    this._impersonationShareService.endSession();
    this.isDebug = false;
    window.location.reload();
  }

  private _deleteProxies() {
    this._localStorageManagerService.deleteKey(this._rolesKey);
    this._localStorageManagerService.deleteKey(this._userKey);
    this._roles = null;
  }
}
