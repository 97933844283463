<div class="date-header">
  <div class="date-header-data-field">
    <div>
      <span class="date-header-text">Date Submitted:</span>
      <span>{{ field.props['submitDate'] }}</span>
    </div>
    <div *ngIf="!ovs.isNullOrEmpty(field.props['reviewDate'])">
      <span class="date-header-text">Date Reviewed:</span>
      <span>{{ field.props['reviewDate'] }}</span>
    </div>
  </div>
</div>
