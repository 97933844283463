import { ViewContainerRef, ComponentRef } from '@angular/core';
import { QCellFocusComponent } from '../q-row/q-cell-focus/q-cell-focus.component';
import { CellFocusParameter } from './cell-focus-parameter';

export class CellFocus {
  private _oldContainerRef: ViewContainerRef;

  constructor(
    private _defaultContainerRef: ViewContainerRef,
    private _cellFocusComponentRef: ComponentRef<QCellFocusComponent>
  ) {
    this._oldContainerRef = this._defaultContainerRef;
  }

  private _setContainerAndFocus(newContainer: ViewContainerRef) {
    if (!newContainer || !this._oldContainerRef.get(0)) {
      this._defaultContainerRef.insert(this._cellFocusComponentRef.hostView);
      this._oldContainerRef = this._defaultContainerRef;
      return;
    }
    this._oldContainerRef.detach(
      this._oldContainerRef.indexOf(this._cellFocusComponentRef.hostView)
    );
    newContainer.insert(this._cellFocusComponentRef.hostView);
    this._oldContainerRef = newContainer;
    this._cellFocusComponentRef.instance.focus();
    this._oldContainerRef.detach(
      this._oldContainerRef.indexOf(this._cellFocusComponentRef.hostView)
    );
    this._defaultContainerRef.insert(this._cellFocusComponentRef.hostView);
    this._oldContainerRef = this._defaultContainerRef;
  }

  changeFocus(focusParam: CellFocusParameter) {
    if (!focusParam || !focusParam.rowData || !focusParam.column) {
      return;
    }
    this._setContainerAndFocus(
      focusParam.rowData.rowComponent.getFocusContainerViewRef(
        focusParam.column.visibleColumnNumber
      )
    );
  }
}
