import { Injectable } from '@angular/core';
import { LocalStorageManagerService } from '../../core/local-storage/local-storage-manager.service';
import { LocalStorageKeyFactory } from '../../core/local-storage/local-storage-key-factory';
import { LocalStorageKey } from '../../core/local-storage/local-storage-key.model';
import { SessionManagerService } from '../session-manager/session-manager.service';
import { IClearableSensitiveData } from '../../core/iclearable-cache';
import { AccessControlService } from '../../features/access-control/access-control.service';
import { Observable, ReplaySubject } from 'rxjs';
import { TokenResponse } from './token-response';
import { UserInfoResponse } from './user-info-response';
import { SubjectManager } from '../../core/subject-manager';
import { GuidGenerator } from '../../core/guid-generator';
import { SubjectType } from '../../core/subject-type';
import { Role } from '../../features/access-control/models/role';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Injectable()
export class TokenCacheService implements IClearableSensitiveData {
  guid = new GuidGenerator().newGuid();
  private _userHasAuthenticated$Subject = new ReplaySubject();
  private _subjectManager = new SubjectManager();
  private _subscriptionManager = new SubscriptionManager();
  private _userId$: Observable<string>;
  private _email$: Observable<string>;
  private _fullName$: Observable<string>;
  private _roles$: Observable<string>;
  userHasAuthenticated$ = this._userHasAuthenticated$Subject.asObservable();
  tokenResponse: TokenResponse = new TokenResponse();
  classVersion = '2.0.0';
  userToken = 'TokenCacheService';
  isAuthenticated$: Observable<boolean>;

  get userId(): Observable<string> {
    return this._userId$;
  }
  get email(): Observable<string> {
    return this._email$;
  }
  get fullName(): Observable<string> {
    return this._fullName$;
  }
  get roles(): Observable<string> {
    return this._roles$;
  }

  constructor(
    private _localStorageManagerService: LocalStorageManagerService,
    protected _sessionManagerService: SessionManagerService,
    private _accessControlService: AccessControlService,
    private _localStorageKeyFactory: LocalStorageKeyFactory
  ) {
    // Need to register to get wiped, but doesn't need to unsubscribe as this
    // is a singleton that never dies.
    this._subjectManager.register('authenticated', SubjectType.BehaviourSubject, true); //JY_TMP ADD TRUE AND BEHAVIOUR SUBJECT
    this._subjectManager.register('userId');
    this._subjectManager.register('email');
    this._subjectManager.register('fullName');
    this._subjectManager.register('roles');

    this.isAuthenticated$ = this._subjectManager.getObservable('authenticated');
    this._userId$ = this._subjectManager.getObservable('userId');
    this._email$ = this._subjectManager.getObservable('email');
    this._fullName$ = this._subjectManager.getObservable('fullName');
    this._roles$ = this._subjectManager.getObservable('roles');
    _sessionManagerService.subscribe(this.guid, () => this.clearAfterLogout());
  }

  authenticate(value: boolean) {
    this._subjectManager.next('authenticated', value);
  }

  clearAfterLogout(): void {
    this._localStorageManagerService.deleteKey(this.key());
    this.tokenResponse.userName = null;
    this.tokenResponse.fullName = null;
    this.tokenResponse.userId = null;
    this.tokenResponse.roles = null;
    this.tokenResponse.email = null;
    this._subjectManager.clear();
    this._subscriptionManager.clear();
  }

  setToken(userInfoResponse: UserInfoResponse) {
    this.tokenResponse.expiration = String(new Date());
    this._accessControlService.setRoles(userInfoResponse.roles);
    this.tokenResponse.userName = userInfoResponse.userName;
    this.tokenResponse.fullName = userInfoResponse.fullName;
    this.tokenResponse.userId = userInfoResponse.userId;
    this.tokenResponse.roles = userInfoResponse.roles;
    this.tokenResponse.email = userInfoResponse.email;
    this._emitUserInfo(userInfoResponse);
    this._localStorageManagerService.setKey(this.key(), JSON.stringify(this.tokenResponse));
    this._userHasAuthenticated$Subject.next(true);
  }

  private key(): LocalStorageKey {
    return this._localStorageKeyFactory.build('UserProfile', this.classVersion);
  }

  private _emitUserInfo(params: {userId: any, email: string, fullName: string, roles: Role[]}): void {
    this._subjectManager.next('userId', params.userId);
    this._subjectManager.next('email', params.email);
    this._subjectManager.next('fullName', params.fullName);
    this._subjectManager.next('roles', params.roles);
  }
}
