export enum FilterType {
  Default = -1,
  Range = 0,
  Contains = 1,
  NotContains = 2,
  ClearFilter = 3,
  NonZero = 4,
  Equals = 5,
  Custom = 6
}
