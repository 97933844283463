import { Subscription } from 'rxjs';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export class SubscriptionManager {
  private _subscriptions: Subscription[] = [];
  private _subscriptionsDictionary: { [id: string]: Subscription } = {};
  private _ovs = new ObjectValidatorService();

  register(subscription: Subscription, key = ''): void {
    if (!this._ovs.isDefined(subscription)) {
      return;
    }
    if (this._ovs.isNullOrEmpty(key)) {
      this._subscriptions.push(subscription);
      return;
    }
    this._registerKey(key, subscription);
  }

  private _registerKey(key: string, subscription: Subscription): void {
    if (!subscription) {
      return;
    }

    if (this._ovs.isDefined(this._subscriptionsDictionary[key])) {
      this.clearKey(key);
    }

    this._subscriptionsDictionary[key] = subscription;
  }

  registerMultiple(subscriptions: Subscription[]): void {
    if (!this._ovs.isNullOrEmpty(subscriptions)) {
      subscriptions.forEach(subscription => this.register(subscription));
    }
  }

  clear(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe());
    this._subscriptions = [];
    Object.keys(this._subscriptionsDictionary)
      .map(key => this._subscriptionsDictionary[key])
      .filter(subscribtion => this._ovs.isDefined(subscribtion))
      .forEach(subscribtion => subscribtion.unsubscribe());
    this._subscriptionsDictionary = {};
  }

  clearKey(key: string): void {
    if (this._ovs.isDefined(this._subscriptionsDictionary[key])) {
      this._subscriptionsDictionary[key].unsubscribe();
      this._subscriptionsDictionary[key] = new Subscription();
    }
  }
}
