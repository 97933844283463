import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { MatCommonModule } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PushPipe } from '@ngrx/component';
import { AngularMaterialModule } from '../../angular-material/angular-material.module';

/**
 * DialogContentExampleComponent:
 * This is an example of a component that can be injected in a Overlay/dialog.
 *
 * It can inject any component in the page layout's actionBar.
 * The actionBarContent component can be passed as an input or a route parameter as:
 * data: {
 *     actionBarComponent: DebugComponent
 *     //...
 *  }
 */
@Component({
  selector: 'text-lines-dialog',
  templateUrl: 'text-lines-dialog.component.html',
  standalone: true,
  styleUrls: ['text-lines-dialog.component.scss'],
  imports: [
    CommonModule,
    PushPipe,
    AngularMaterialModule,
    HttpClientModule,
    MatCommonModule,
  ],
})
export class TextLinesDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TextLinesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
