import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWebService } from '@nimbus/shared-lib';
import { catchError } from 'rxjs/operators';
import { OMLinkset } from '../models/o-m-linkset';
import { SubverticalRequestModel } from '../../../shared/models/subvertical-request.model';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { HttpClientWrapper } from '../../../../external-tools/tableau/services/http-client-wrapper.service';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { environment } from 'apps/partner/frontend/ui/src/environments/environment';

@Injectable()
export class SubverticalReviewWebService extends BaseWebService {
  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _httpClientWrapper: HttpClientWrapper,
  ) {
    super();
  }

  checkLinksetExists(
    vertical: string,
    subvertical: string,
    geo: string,
    language: string,
  ): Observable<boolean> {
    return this._webServiceBuilderService.builder.withUrl(`linksets/exists`).build().get({
      vertical: vertical,
      subvertical: subvertical,
      geo: geo,
      language: language,
    });
  }

  updateSubverticalRequest(
    subverticalRequestToUpdate,
  ): Observable<SubverticalRequestModel> {
    //not working
    return this._webServiceBuilderService.builder
      .withUrl(`subverticalrequests/${subverticalRequestToUpdate.id}`)
      .build()
      .save<SubverticalRequestModel>(subverticalRequestToUpdate);
  }

  //TODO: Once linksets are removed from ConsoleDB the createOmLinkset, handleOmError and any associated methods and classes can be removed.
  // This is a ramp api.
  createOMLinkset(omLinkset: OMLinkset): Observable<any> {
    return this._httpClientWrapper
      .post(
        `${environment.apis.RampAPI}/v1/linksets/keywords`,
        this._removeAllEmptyValues(omLinkset),
        null,
        this._oktaAuth.getAccessToken(),
      )
      .pipe(
        catchError(err => {
          return this._throwError(err);
        }),
      );
  }

  handleOmError(response: any) {
    if (
      !this._ovs.isPathNullOrEmpty(response, ['success']) &&
      !this._ovs.isPathNullOrEmpty(response, ['message']) &&
      response['success'] === false
    ) {
      const error = Error(response.message);
      error['reported'] = true;
      throw error;
    }
  }

  private _removeAllEmptyValues(params: any): any {
    return Object.keys(params)
      .filter(
        key =>
          !this._ovs.isNullOrEmpty(params[key]) &&
          params[key] !== '----' &&
          params[key] !== 'None',
      )
      .reduce((previous, key) => {
        previous[key] = params[key];
        return previous;
      }, {} as any);
  }
}
