import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormlyModule } from '@ngx-formly/core';
import { NimbusWrapperPanelComponent } from './components/nimbus-wrapper-panel/nimbus-wrapper-panel.component';

@NgModule({
  imports: [
    CommonModule,
    FormlyModule.forRoot({
      wrappers: [
        {
          name: 'nimbus-wrapper-panel',
          component: NimbusWrapperPanelComponent,
        },
      ],
    }),
  ],
})
export class NimbusFormlyCommonModule {}
