import { Injectable } from '@angular/core';
import * as _ from 'underscore';
import { sample } from 'lodash';
import { GeoModel } from '../../../../shared/models/geo.model';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export interface DomainNameBuilderParams {
  language: string;
  serializedLinksets: string;
}

@Injectable({
  providedIn: 'root',
})
export class DomainNameBuilderService {
  private _tldList: string[] = ['live','fyi','world','zone'];

  constructor(private _ovs: ObjectValidatorService) {}

  /**
   *
   * @param domainNameBuilderParams.language selected language
   * @param domainNameBuilderParams.serializedLinksets available linksets for the selected vertical/subvertical/country
   * @returns
   */
  buildDomainName(params: DomainNameBuilderParams): string {
    const linksets = JSON.parse(params.serializedLinksets).availableLinksets;
    const linkset = linksets.find(
      linkset =>
        linkset.twoLetterISOLanguageName ===
        (!this._ovs.isNullOrEmpty(params.language) && params.language !== 'en'
          ? params.language
          : 'en'),
    );
    const regex = /.*-([a-zA-Z0-9]{2,5})$/;
    const match = regex.exec(linkset.subVertical);
    const subVertical = linkset.subVertical.replace(/[\s_]+/g, '-');
    const language =
      !this._ovs.isNullOrEmpty(params.language) && params.language !== 'en'
        ? '-' + params.language
        : '';
    const country =
      !this._ovs.isNullOrEmpty(params.language) && params.language !== 'us';
    let domainName = !this._ovs.isNullOrEmpty(match)
      ? `${
          GeoModel.isCustomGeo(match[1]) || match[1] === 'us'
            ? subVertical.replace('-' + match[1], '')
            : subVertical
        }`
      : `${subVertical}${country ? '-' + linkset.geoTwoLetterCode : ''}`;
    domainName += `${language}-${this._getRandom()}.${sample(this._tldList)}`;
    return domainName.toLowerCase();
  }

  private _getRandom(): string {
    return String(Math.floor(Math.random() * 8999999) + 1000000);
  }
}
