import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import { Schema } from './schemas.models';
import { SchemasActions } from './schemas.actions';

export const SCHEMAS_FEATURE_KEY = 'schemas';

export interface SchemasState extends EntityState<Schema> {
  selectedId?: string | number; // which Schemas record has been selected
  loaded: boolean; // has the Schemas list been loaded
  error?: string | null; // last known error (if any)
}

export interface SchemasPartialState {
  readonly [SCHEMAS_FEATURE_KEY]: SchemasState;
}

export const schemasAdapter: EntityAdapter<Schema> =
  createEntityAdapter<Schema>();

export const initialSchemasState: SchemasState = schemasAdapter.getInitialState(
  {
    loaded: false,
  },
);

const reducer = createReducer(
  initialSchemasState,
  on(SchemasActions.initializeSchemaStore, state => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(SchemasActions.loadSchemasSuccess, (state, { schemas }) =>
    schemasAdapter.setAll(schemas, { ...state, loaded: true }),
  ),
  on(SchemasActions.loadSchemasFailure, (state, { error }) => ({
    ...state,
    error,
  })),
);

export function schemasReducer(
  state: SchemasState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
