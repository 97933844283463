import { Injectable } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class SubverticalRequestSchemaService {
  constructor(private _ovs: ObjectValidatorService) {}

  getSchema(
    keywordsShowTitle: boolean = true,
    includeSubverticalValidator: boolean = true,
  ): any[] {
    return [
      {
        key: 'subverticalRequest',
        wrappers: ['section-wrapper'],
        fieldGroupClassName: 'subvertical-wrapper',
        fieldGroup: [
          {
            key: 'vertical',
            defaultValue: '',
            type: 'vertical',
            className: 'field-styles',
            templateOptions: {
              label: 'Vertical *',
              required: true,
              width: 100,
            },
          },
          {
            key: 'subVertical',
            defaultValue: '',
            type: 'subvertical-input',
            className: 'field-styles',
            templateOptions: {
              label: 'Subvertical',
              required: true,
              width: 100,
            },
            validators: includeSubverticalValidator
              ? {
                  validation: [
                    'subdomain-name-validator',
                    'subdomain-existence-validator',
                  ],
                }
              : {
                  validation: ['subdomain-name-validator'],
                },
          },
          {
            key: 'geo',
            defaultValue: '',
            className: 'geo-display-show field-styles',
            type: 'geo',
            templateOptions: {
              label: 'Geo *',
              width: 100,
              required: true,
              showFullList: true,
            },
          },
          {
            key: 'language',
            defaultValue: '',
            type: 'language',
            className: 'field-styles',
            templateOptions: {
              label: 'Language *',
              width: 100,
              required: true,
              showFullList: true,
            },
          },
        ],
      },
      {
        key: 'keywords',
        defaultValue: '',
        type: 'keywords',
        className: 'key-styles',
        templateOptions: {
          placeholder: 'eg:\nkeyword1\nkeyword2\nkeyword3',
          width: 100,
          rows: 10,
          required: true,
          showTitle: keywordsShowTitle,
        },
        validators: {
          validation: ['keywords-validator', 'keywords-unique-validator'],
        },
      },
    ];
  }
}
