<h2 *ngIf="title">{{ title }}</h2>
<div [formGroup]="form" class="formly-container" (ngSubmit)="submitForm(form)">
  <formly-form
    [form]="form"
    [options]="options"
    [fields]="subjectManager.getObservable('fields') | async"
    [model]="model"></formly-form>
  <div class="action-buttons-container" *ngIf="buttonsEnabled$ | async">
    <button
      mat-flat-button
      color="primary"
      [disabled]="clicked || form.pristine || !form.valid"
      type="submit">
      Submit
    </button>
    <button class="reset-button" mat-flat-button color="secondary" type="button" (click)="cancel()">
      Cancel
    </button>
  </div>
</div>
