<div
  [class]="
    ((partnerCacheService.partners$ | ngrxPush | callbackRunnerPipe: ovs.isNullOrEmpty) &&
    (partnerCacheService.isLoading$ | ngrxPush)) || (domainListCacheService.isLoading$ | ngrxPush)
      ? 'gray-loader'
      : ''
  ">
  <app-domain-manager-formly-prefilters (filter)="filter($event)"></app-domain-manager-formly-prefilters>
  <app-domain-manager [filter]="filter$|async"></app-domain-manager>
</div>
