export class SubverticalRequestModel {
  constructor(
    public id: number = null,
    public vertical: string,
    public subvertical: string,
    public geo: string,
    public geoFullName: string,
    public language: string,
    public languageFullName: string,
    public keywords: string,
    public requestorId: string,
    public friendlyRequestorName: string,
    public friendlyRequestorEmail: string,
    public requestedDate: Date,
    public reviewedDate: Date = null,
    public reviewedBy: string = null,
    public friendlyReviewerName: string = null,
    public friendlyReviewerEmail: string = null,
    public status: number = null,
    public friendlyStatus: string = null,
    public notes: string = null,
    public requestedDateFormatStr: string = null,
    public reviewedDateFormatStr: string = null
  ) {}
}
