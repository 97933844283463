import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectManager } from '../../../core/subject-manager';
import { SubjectType } from '../../../core/subject-type';

@Injectable()
export class PrefiltersActionsService {
  private _subjectManager = new SubjectManager();
  onUpdate$: Observable<string>;

  constructor() {
    this._subjectManager.register('onUpdate');
    this.onUpdate$ = this._subjectManager.getObservable('onUpdate');
  }

  register(action: string) {
    this._subjectManager.register(action, SubjectType.Subject);
    this._subjectManager.next('onUpdate', action);
  }

  get(action: string): Observable<any> {
    return this._subjectManager.getObservable(action);
  }

  next(action: string, params: any = null) {
    this._subjectManager.next(action, params);
  }
}
