import { Injectable } from '@angular/core';
import { CellGridBuilder } from './cell-grid-builder';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
@Injectable()
export class CellGridBuilderFactory {
  constructor(private _ovs: ObjectValidatorService) {}

  get builder(): CellGridBuilder {
    return new CellGridBuilder(this._ovs);
  }
}
