import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class QGridStyleService {
  // styling classes
  gridContainerClass: string;
  tableClass: string;
  headerClass: string;
  footerClass: string;
  rowClass: string;
  cellClassSubject = new Subject<string>();
  cellClass$ = this.cellClassSubject.asObservable();

  // main color settings
  setColorAccent(color: string = '#f03b66') {
    document.documentElement.style.setProperty('--accent-color', color);
    document.documentElement.style.setProperty('--accent', color);
  }

  setColorAlternate(color: string = 'rgba(0, 2, 2, 0.07)') {
    document.documentElement.style.setProperty('--alternate-color', color);
  }

  setColorCopySourceCell(color: string = 'rgba(67, 243, 150, 0.07)') {
    document.documentElement.style.setProperty('--copy-source-color', color);
  }

  setColorCopyToCell(color: string = 'rgba(255, 152, 0, 0.25)') {
    document.documentElement.style.setProperty('--copy-to-color', color);
  }

  setColorHighlightBackground(color: string = 'rgba(76, 137, 252, 0.1)') {
    document.documentElement.style.setProperty(
      '--highlight-background-color',
      color
    );
  }

  setColorHighlightBorder(color: string = '#5C88DC') {
    document.documentElement.style.setProperty(
      '--highlight-border-color',
      color
    );
  }

  setColorHighlightBackgroundNoFocus(
    color: string = 'rgba(168, 178, 188, 0.1)'
  ) {
    document.documentElement.style.setProperty(
      '--highlight-background-color-no-focus',
      color
    );
  }

  setColorHighlightBorderNoFocus(color: string = '#a8b2bc') {
    document.documentElement.style.setProperty(
      '--highlight-border-color-no-focus',
      color
    );
  }

  setColorHover(color: string = 'rgba(66, 107, 243, 0.2)') {
    document.documentElement.style.setProperty('--hover-color', color);
  }

  setColorPrimary(color: string = '#126f6e') {
    document.documentElement.style.setProperty('--primary-color', color);
    document.documentElement.style.setProperty('--primary', color);
  }

  setColorPrimaryLight(color: string = '#CFEFEF') {
    document.documentElement.style.setProperty('--primary-light-color', color);
  }

  setColorReadonly(color: string = 'rgba(167, 167, 167, 0.05)') {
    document.documentElement.style.setProperty('--readonly-color', color);
  }

  setColorWarning(color: string = '#F4A460') {
    document.documentElement.style.setProperty('--warning-color', color);
  }

  setColorWhite(color: string = '#FFFFFF') {
    document.documentElement.style.setProperty('--white-color', color);
  }

  setFilterBoxBackgroundColor(color: string = '#f0f7f7') {
    document.documentElement.style.setProperty(
      '--filter-box-background-color',
      color
    );
  }

  constructor() {
    this.setColorAccent();
    this.setColorAlternate();
    this.setColorCopySourceCell();
    this.setColorCopyToCell();
    this.setColorHighlightBackground();
    this.setColorHighlightBorder();
    this.setColorHover();
    this.setColorPrimary();
    this.setColorPrimaryLight();
    this.setColorReadonly();
    this.setColorWarning();
    this.setColorWhite();
    this.setFilterBoxBackgroundColor();

    this.gridContainerClass = '';
    this.tableClass = '';
    this.headerClass = '';
    this.footerClass = '';
    this.rowClass = '';
    this.cellClassSubject.next('');
  }
}
