import { Injectable } from '@angular/core';
import { GridCopyPaste } from '../q-models/grid-copy-paste';
import { ReplaySubject } from 'rxjs';
import { DistinctNumberStack } from '../q-models/copy-model';
import { GridMouseEditFeatures } from '../q-models/grid-mouse-edit-features';
import { QGrid } from '../q-models/q-grid';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class GridMouseEditFeaturesBuilderService {
  constructor(private _ovs: ObjectValidatorService) {}

  build(
    items: QGrid,
    editable: boolean,
    gridCopyPaste: GridCopyPaste,
    refreshSubject: ReplaySubject<void>,
    currentHighlightedRows: DistinctNumberStack,
    currentHighlightedColumns: DistinctNumberStack,
    canAddRowOnPaste: () => boolean,
    viewRowMax: () => number,
    colMax: () => number,
    addNewRow: (refreshModel?: boolean) => void,
    subscribeToKeyboardService: () => void,
    exitEditingCell: () => void,
  ): GridMouseEditFeatures {
    return new GridMouseEditFeatures(
      items,
      editable,
      gridCopyPaste,
      refreshSubject,
      currentHighlightedRows,
      currentHighlightedColumns,
      canAddRowOnPaste,
      viewRowMax,
      colMax,
      addNewRow,
      subscribeToKeyboardService,
      exitEditingCell,
      this._ovs,
    );
  }
}
