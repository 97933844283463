import { NgModule } from '@angular/core';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { JsonEditorComponent } from './types/formly-json-editor/formly-json-editor.component';
import { ActionTypeComponent } from './types/action-type/action-type.component';
//import { ArrayMaxLengthValidator, ArrayMinLengthValidator } from './types/array-type/array-validators';
import { ArrayTypeComponent } from './types/array-type/array-type.component';
import { ChildSectionComponent } from './types/child-section/child-section.component';

@NgModule({
  imports: [
    FormlyModule.forChild({
      wrappers: [{ name: 'child', component: ChildSectionComponent }],
      types: [
        { name: 'formly-json-editor', component: JsonEditorComponent },
        { name: 'action', component: ActionTypeComponent },
        { name: 'array', component: ArrayTypeComponent },
      ],
      // validators: [
      //   { name: 'arrayMinLength', validation: ArrayMinLengthValidator, options: { min: 1 } },
      //   { name: 'arrayMaxLength', validation: ArrayMaxLengthValidator, options: { max: 1 } },
      // ],
      validationMessages: [
        {
          name: 'arrayMinLength',
          message: (_err, field: FormlyFieldConfig) =>
            `"${field?.formControl?.value}" must have at least .... value`,
        },
        {
          name: 'arrayMaxLength',
          message: (_err, field: FormlyFieldConfig) =>
            `"${field?.formControl?.value}" must not contains more than ... value.`,
        },
        {
          name: 'required',
          message: (_err, _field: FormlyFieldConfig) =>
            'This field is required',
        },
        {
          name: 'null',
          message: (_err, _field: FormlyFieldConfig) => 'should be null',
        },
        {
          name: 'minLength',
          message: (_err, field: FormlyFieldConfig) =>
            `should not be shorter than ${field?.props?.['minLength']} characters`,
        },
        {
          name: 'maxLength',
          message: (_err, field: FormlyFieldConfig) =>
            `should not be longer than ${field?.props?.['maxLength']} characters`,
        },
        {
          name: 'min',
          message: (_err, field: FormlyFieldConfig) =>
            `should be greater than or equal to ${field?.props?.['min']}`,
        },
        {
          name: 'max',
          message: (_err, field: FormlyFieldConfig) =>
            `should be less than or equal to ${field?.props?.['max']}`,
        },
        {
          name: 'multipleOf',
          message: (_err, field: FormlyFieldConfig) =>
            `should be multiple of ${field?.props?.['step']}`,
        },
        {
          name: 'exclusiveMinimum',
          message: (_err, field: FormlyFieldConfig) =>
            `should be greater than ${field?.props?.['step']}`,
        },
        {
          name: 'exclusiveMaximum',
          message: (_err, field: FormlyFieldConfig) =>
            `should be less than ${field?.props?.['step']}`,
        },
        {
          name: 'minItems',
          message: (_err, field: FormlyFieldConfig) =>
            `should not have fewer than ${field?.props?.['minItems']} items`,
        },
        {
          name: 'maxItems',
          message: (_err, field: FormlyFieldConfig) =>
            `should not have more than ${field?.props?.['maxItems']} items`,
        },
        {
          name: 'uniqueItems',
          message: (_err, _field: FormlyFieldConfig) =>
            'should not have duplicate items',
        },
        {
          name: 'const',
          message: (_err, field: FormlyFieldConfig) =>
            `should be equal to constant "${field?.props?.['const']}"`,
        },
      ],
    }),
  ],
})
export class NimbusFormsModule {}
