import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../core/angular-material.module';
import { SearchableSelectControlComponent } from './searchable-select-control.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BackdropService } from './models/backdrop.service';
import { CallbackRunnerModule } from '../../core/callback-runner/callback-runner.module';

@NgModule({
  declarations: [SearchableSelectControlComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    ScrollingModule,
    CallbackRunnerModule
  ],
  exports: [SearchableSelectControlComponent],
  providers: [BackdropService]
})
export class SearchableSelectControlModule {}
