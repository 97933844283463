import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessControlDirective } from './access-control.directive';
import { AccessControlWriteDirective } from './access-control-write.directive';
import { ImpersonationService } from '../../features/impersonation/impersonation.service';

@NgModule({
  declarations: [AccessControlDirective, AccessControlWriteDirective],
  imports: [CommonModule],
  exports: [AccessControlDirective, AccessControlWriteDirective],
  providers: [ImpersonationService]
})
export class AccessControlModule {}
