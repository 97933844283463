<div
  [class]="
    (partnerCacheService.partners$ | async | callbackRunnerPipe: ovs.isNullOrEmpty) &&
    (partnerCacheService.isLoading$ | async)
      ? 'gray-loader'
      : ''
  ">
  <ng-container
    *ngIf="
      !(partnerCacheService.partners$ | async | callbackRunnerPipe: ovs.isNullOrEmpty) &&
      (partnerCacheService.isLoading$ | async) === false
    ">
    <div class="domain-creator-container">
      <app-domain-creator-form-wrapper></app-domain-creator-form-wrapper>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      (partnerCacheService.partners$ | async | callbackRunnerPipe: ovs.isNullOrEmpty) &&
      (partnerCacheService.isLoading$ | async) === false
    ">
    <div class="domain-creator-container">
      <p>You do not have a Partner ID associated with this account.</p>
    </div>
  </ng-container>
</div>
<ng-container *ngIf="domainCountUpdate$ && domainCountUpdate$ | async"></ng-container>
