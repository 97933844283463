import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SearchableListItemWithSelect, SortOption } from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { LinksetCacheService } from '../../../../services/linkset-cache.service';
import { CombineGeoLanguageSubdomainVerticalInputService } from '../../../../services/combine-geo-language-subdomain-vertical-input.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'linkset-vertical',
  templateUrl: './vertical.component.html',
  styleUrls: ['./vertical.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy
{
  readonly noSort: SortOption = SortOption.None;
  private _subscriptionManager = new SubscriptionManager();
  items$: Observable<SearchableListItemWithSelect[]> = this._linksetCacheService
    .getVerticals()
    .pipe(
      map(verticals =>
        verticals.map(vertical => new SearchableListItemWithSelect(vertical)),
      ),
    );
  isLoading$ = this._linksetCacheService.isLoading$;

  constructor(
    public ovs: ObjectValidatorService,
    private _linksetCacheService: LinksetCacheService,
    private _combineGeoLanguageSubdomainInputService: CombineGeoLanguageSubdomainVerticalInputService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._subscriptionManager.register(
      this.field.formControl.valueChanges
        .pipe(
          tap(vertical => {
            this._combineGeoLanguageSubdomainInputService.UpdateStaticVerticalValue(
              vertical,
              this.field,
            );
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
    this._combineGeoLanguageSubdomainInputService.clear();
  }
}
