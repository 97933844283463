import React from 'react';
import Retool from 'react-retool';

type EmbeddedWrapperProps = {
    embedUrl: string;
    data: any;
};
export function EmbeddedWrapper({ embedUrl: retoolEmbedUrl, data }: EmbeddedWrapperProps) {
  return (
    retoolEmbedUrl && (
        <div
          className='retool-embed-wrapper'
        >
            <Retool url={retoolEmbedUrl} data={data} />
        </div>
        )
    );
}
