import { createAction, props } from '@ngrx/store';
import { Schema } from './schemas.models';

export class SchemasActions {
  static readonly initializeSchemaStore = createAction(
    '[Application Schemas] Initialize Schemas',
  );

  static readonly loadSchemasSuccess = createAction(
    '[Schemas/API] Load Schemas Success',
    props<{ schemas: Schema[] }>(),
  );

  static readonly loadSchemasFailure = createAction(
    '[Schemas/API] Load Schemas Failure',
    props<{ error: any }>(),
  );
}
