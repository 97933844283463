import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { PrefiltersActionsService } from '../../../features/layout/form-pre-filters/prefilters-actions.service';
import { Router } from '@angular/router';

// Example if a link element that can be added to a form without any property mapping.
@Component({
  selector: 'action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionLinkComponent extends FieldType<FieldTypeConfig> implements OnInit {
  validators: typeof Validators = Validators;
  internalForm = this._formBuilder.group({});

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _prefiltersActionsService: PrefiltersActionsService
  ) {
    super();
  }
  ngOnInit(): void {
    if (this.props['url']) {
      return;
    }
    this._prefiltersActionsService.register(this.props['action']);
  }

  action() {
    if (this.props['url']) {
      this._router.navigate([this.props['url']]);
    }
    this._prefiltersActionsService.next(this.props['action']);
  }
}
