import { Injectable } from '@angular/core';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ImpersonationShareService } from '@nimbus/shared-lib';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImpersonationTrackerService {
  private _user: any;
  private _subscriptionManager: SubscriptionManager = new SubscriptionManager();
  constructor(private _impersonationShareService: ImpersonationShareService) {}

  init() {
    this._subscriptionManager.register(
      this._impersonationShareService.userInfo$
        .pipe(
          tap(userInfo => {
            return (this._user = userInfo);
          })
        )
        .subscribe(),
      'impersonationUserInfo'
    );
  }

  get user(): any {
    return this._user;
  }

  get userInfo$() {
    return this._impersonationShareService.userInfo$;
  }
}
