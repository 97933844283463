import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'contentHeightPercent'
})
export class ContentHeightPercentPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return `height: calc(100% - ${value}px - 5px);`;
  }
}
