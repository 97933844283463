import { NgModule } from '@angular/core';
import { VideoWalkthroughsComponent } from './video-walkthroughs.component';
import { VideoPlaylistModule } from './video-playlist/video-playlist.module';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from 'core-global-frontend-common-ui';

@NgModule({
  declarations: [VideoWalkthroughsComponent],
  exports: [VideoWalkthroughsComponent],
  imports: [CommonModule, AngularMaterialModule, VideoPlaylistModule],
})
export class VideoWalkthroughsModule {}
