import { throwError, BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { IWebClient } from './iweb-client';
import { GuidGeneratorService } from 'core-global-generic-guid-generator';
import {
  IPaginationRequest,
  IPaginationResponse,
  PaginationProperties,
} from './ientities';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

export abstract class BaseWebService implements IWebClient {
  private readonly _paginationSubject = new ReplaySubject<IPaginationRequest>();
  readonly pagination$: Observable<IPaginationRequest> =
    this._paginationSubject.asObservable();
  protected _apiCallInProgressSubject = new BehaviorSubject<boolean>(false);
  private _apiCallInProgress$: Observable<boolean> =
    this._apiCallInProgressSubject.asObservable();
  get apiCallInProgress$(): Observable<boolean> {
    return this._apiCallInProgress$;
  }
  protected readonly _subscriptionManager = new SubscriptionManager();
  protected _ovs = new ObjectValidatorService();
  guid = new GuidGeneratorService().newGuid();
  protected paginationProperties = new PaginationProperties();

  constructor() {
    this._apiCallInProgressSubject.next(false);
  }

  overrideLoading(apiCallInProgress$: Observable<boolean>) {
    this._apiCallInProgressSubject.unsubscribe();
    this._apiCallInProgress$ = apiCallInProgress$;
  }

  protected _throwError(error: any): Observable<never> {
    this._apiCallInProgressSubject.next(false);
    return throwError(error);
  }

  removeAlls(params: any): any {
    return Object.keys(params)
      .filter(
        key => !this._ovs.isNullOrEmpty(params[key]) && params[key] !== 'All',
      )
      .reduce((previous: any, key) => {
        previous[key] = params[key];
        return previous;
      }, {} as any);
  }

  protected handlePagination(response: any, enablePagination: boolean = true) {
    if (
      enablePagination &&
      this.paginationProperties.properties.reduce(
        (p, c) => p && response && this._ovs.isDefined((response as any)[c]),
        true,
      )
    ) {
      this._paginationSubject.next({
        Skip: response.skip,
        Limit: response.limit,
        TotalResults: response.totalResults,
      });
      return response['results'];
    }
    return response;
  }
}
