import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { ICustomCell, IClickableCell } from '../icell-component';
import { ColumnInfo } from '../../grid/column-info';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

/**
 * @title Static text cell
 *
 * Default static text cell renderer for the grid.
 *
 * @param editable - boolean - Not used.  The cell is always readonly.
 * @param form - any - the form to bind to the cell
 * @param column - ColumnInfo - the column information
 */
@Component({
  selector: 'frontend-static-text-cell',
  templateUrl: './static-text-cell.component.html',
  styleUrls: ['./static-text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
  ],
})
export class StaticTextCellComponent
  implements OnInit, ICustomCell, IClickableCell
{
  @Input() form!: FormGroup;
  @Input() column!: ColumnInfo;
  @Input() editable = false;
  @Output() clickEvent = new EventEmitter<ICustomCell>();
  @Input() rowIndex!: number;
  @Input() dataSource!: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Input() formGroup!: FormGroup;
  value!: string;

  private _getValue(): string {
    if (!this.column.field) {
      return '';
    }
    const value = this.form?.controls?.[this.column.field]?.value ?? '';
    if (value === '' || !value) {
      return '';
    }
    return this.column.transform ? this.column.transform(value) : value;
  }

  ngOnInit(): void {
    this.value = this._getValue();
  }

  onClick(): void {
    this.clickEvent.emit({
      form: this.form,
      column: this.column,
      editable: this.editable,
      rowIndex: this.rowIndex,
      dataSource: this.dataSource,
    });
  }
}
