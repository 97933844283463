import { ObjectValidatorService } from '../object-validator-service/object-validator.service';
import { ILoggerConfig } from './ilogger-config';

export class LoggerConfig implements ILoggerConfig {
  get rndViewerbufferSize(): number {
    return this._ovs.isNumber(this._rndViewerbufferSize) ? this._rndViewerbufferSize : 0;
  }
  get useConsole(): boolean {
    return this._ovs.isTrue(this._useConsole) ? true : false;
  }
  get showAPICalls(): boolean {
    return this._ovs.isTrue(this._showAPICalls) ? true : false;
  }

  constructor(
    private _ovs: ObjectValidatorService,
    private _useConsole: boolean,
    private _rndViewerbufferSize: number,
    private _showAPICalls: boolean
  ) {}
}
