import { Component, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AccessControlModule,
  LayoutModule,
  SearchableSelectControlModule,
} from '@nimbus/shared-lib';
import { QGridModule } from '@nimbus/grid';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { SubverticalReviewsManagerComponent } from './subvertical-reviews/subvertical-reviews-manager.component';
import { SubverticalReviewsManagerWrapperComponent } from './subvertical-reviews/wrappers/subvertical-reviews-manager-wrapper/subvertical-reviews-manager-wrapper.component';
import { SubverticalReviewsPrefiltersComponent } from './subvertical-reviews/subvertical-reviews-prefilters/subvertical-reviews-prefilters.component';
import { SubverticalReviewsCacheService } from './subvertical-reviews/services/subvertical-reviews-cache.service';
import { SubverticalReviewsListColumnsBuilderService } from './subvertical-reviews/services/subvertical-reviews-list-columns-builder.service';
import { SubverticalReviewsActionCellComponent } from './subvertical-reviews/subvertical-reviews-action-cell/subvertical-reviews-action-cell.component';
import { SubverticalReviewsManagerPrefilterSchemaService } from './subvertical-reviews/schemas/subvertical-reviews-manager-prefilter-schema.service';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { SubverticalRequestComponent } from './subvertical-requests/subvertical-request.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { SubverticalRequestFormWrapperComponent } from './subvertical-requests/subvertical-requests-form-component/subvertical-request-form.component';
import { SubverticalRequestSchemaService } from './shared/schemas/subvertical-request-schema.service';
import { SectionWrapperComponent } from './shared/formly/wrappers/section-wrapper/section-wrapper.component';
import { SubverticalReviewComponent } from './subvertical-reviews/subvertical-review/subvertical-review.component';
import { SubverticalReviewCacheService } from './subvertical-reviews/subvertical-review/services/subvertical-review-cache.service';
import { SubverticalReviewNoteComponent } from './subvertical-reviews/formly/types/subvertical-review-note/subvertical-review-note.component';
import { SubverticalReviewSchemaService } from './subvertical-reviews/schemas/subvertical-review-schema.service';
import { SubverticalRequestsHistoryComponent } from './subvertical-requests/subvertical-requests-history/subvertical-requests-history.component';
import { SubverticalRequestsDockComponent } from './subvertical-requests/subvertical-requests-dock-component/subvertical-requests-dock.component';
import { SubverticalHistoryActionCellComponent } from './subvertical-requests/subvertical-history-action-cell/subvertical-history-action-cell.component';
import { DateHeaderComponent } from './subvertical-requests/formly/date-header/date-header.component';
import { SubverticalRequestsHistoryListColumnsBuilderService } from './subvertical-requests/services/subertical-requests-history-list-columns-builder.service';
import { SubverticalRequestsHistoryDockService } from './subvertical-requests/services/subvertical-requests-history-dock.service';
import { SubverticalRequestsDockSchemaService } from './subvertical-requests/subvertical-requests-dock-component/schemas/subvertical-requests-dock-schema.service';
import { SubverticalReviewWebService } from './subvertical-reviews/subvertical-review/services/subvertical-review-web.service';
import { SubverticalReadonlyComponent } from './subvertical-reviews/subvertical-readonly/subvertical-readonly.component';
import { SubverticalReadonlyCacheService } from './subvertical-reviews/subvertical-readonly/services/subvertical-readonly-cache.service';
import { SubverticalReadonlySchemaService } from './subvertical-reviews/subvertical-readonly/schemas/subvertical-readonly-schema.service';
import { SubverticalsRoutingModule } from './subverticals-routing.module';
import { KeywordsComponent } from '../../shared/formly/types/linksets/keywords/keywords.component';
import { SubVerticalInputComponent } from '../../shared/formly/types/linksets/sub-vertical-input/sub-vertical-input.component';
import { PendingChangesTracker } from '../../shared/services/pending-changes-tracker';
import { FormlyModule } from '@ngx-formly/core';
import {
  GlobalFrontendGridModule,
  GridCellComponentMapperService,
  GridComponent,
} from 'core-global-frontend-grid';
import { CallbackRunnerModule } from 'core-global-frontend-callback-runner';
import { PushPipe } from '@ngrx/component';

@NgModule({
  declarations: [
    SubverticalReviewsManagerComponent,
    SubverticalReviewsManagerWrapperComponent,
    SubverticalReviewsPrefiltersComponent,
    SubverticalRequestComponent,
    SubverticalRequestFormWrapperComponent,
    KeywordsComponent,
    SectionWrapperComponent,
    SubVerticalInputComponent,
    SubverticalReviewComponent,
    SubverticalReadonlyComponent,
    SubverticalReviewNoteComponent,
    SubverticalRequestsHistoryComponent,
    SubverticalHistoryActionCellComponent,
    SubverticalRequestsDockComponent,
    DateHeaderComponent,
  ],
  imports: [
    CommonModule,
    CallbackRunnerModule,
    AccessControlModule,
    QGridModule,
    PushPipe,
    LayoutModule,
    FormlyMaterialModule,
    ReactiveFormsModule,
    SearchableSelectControlModule,
    RouterModule,
    MatIconModule,
    MatTooltipModule,
    GlobalFrontendGridModule,
    BrowserModule,
    FormlyModule.forChild(),
    GridComponent,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    FormlyMatToggleModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule,
    SubverticalsRoutingModule,
  ],
  providers: [
    PendingChangesTracker,
    SubverticalReviewsCacheService,
    SubverticalReviewsManagerPrefilterSchemaService,
    SubverticalReviewsListColumnsBuilderService,
    SubverticalRequestSchemaService,
    SubverticalReviewCacheService,
    SubverticalReadonlyCacheService,
    SubverticalReadonlySchemaService,
    SubverticalReviewSchemaService,
    SubverticalRequestsHistoryListColumnsBuilderService,
    SubverticalRequestsHistoryDockService,
    SubverticalRequestsDockSchemaService,
    SubverticalReviewWebService,
  ],
})
export class SubverticalsModule {
  constructor(_gridCellComponentMapperService: GridCellComponentMapperService) {
    _gridCellComponentMapperService.registerComponent(
      'subvertical-reviews-action-cell',
      SubverticalReviewsActionCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'subvertical-history-action-cell',
      SubverticalHistoryActionCellComponent as Component,
    );
  }
}
