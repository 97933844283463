<div class="documentation tab-content">
  <div>
    The System1 Reporting API provides access to data in your account. This document intends to
    provide a high level view of the API endpoints, however further details and input/output
    examples can be found at
    <a
      class="documentation-link"
      target="_blank"
      href="http://docs.openmail.com/partner/reporting_api.html">
      <span class="documentation-link-text"
        >http://docs.openmail.com/partner/reporting_api.html</span
      >
    </a>
  </div>
  <br />
  <div>
    We offer various API endpoints depending on the granularity of data you are seeking, but the two
    main levels are
    <span class="documentation-highlighted">summary</span> which is going to contain finalized data
    from the previous day and <span class="documentation-highlighted">intraday</span> which is going
    to have estimates for the current day by hour. All reporting is in
    <span class="documentation-highlighted">UTC</span> timezone.
  </div>
  <br />
  <h3 class="documentation-sub-header">Summary Endpoints</h3>
  <div class="documentation-highlighted">
    https://reports.openmail.com/v1/summary.json?auth_key=
  </div>
  <div class="documentation shifted">
    This is the same data you can find in the
    <span class="documentation bold">31 Day Summary</span> tab in the emailed reports. Here you can
    find account-level metrics by day for the last 31 completed days.
  </div>
  <div class="documentation-highlighted">
    https://reports.openmail.com/v1/campaign.json?auth_key=
  </div>
  <div class="documentation shifted">
    This is the same data you can find in the
    <span class="documentation bold">31 Day By Campaign</span> tab in the emailed reports. Here you
    can find campaign-level metrics by day for the last 31 completed days.
  </div>
  <div class="documentation-highlighted">https://reports.openmail.com/v1/subid.json?auth_key=</div>
  <div class="documentation shifted">
    This is the same data you can find in the
    <span class="documentation bold">14 day by Campaign Subid</span> tab in the emailed reports.
    Here you can find subid-level metrics by day for the last 14 completed days.
  </div>
  <h3 class="documentation-sub-header">Intraday Endpoints</h3>
  <div>
    The following endpoints provide a cumulative hourly estimate of your traffic and revenue for
    each System1 campaign domain.
    <span class="documentation bold">Caution:</span
    ><span class="documentation italic">
      clicks and revenue are frequently discounted by our ad network partners in real-time, so this
      endpoint may overcount your daily totals.</span
    >
  </div>
  <br />
  <div class="documentation-highlighted">
    https://reports.openmail.com/v2/campaign_intraday.json?auth_key=
  </div>
  <div class="documentation shifted">
    Provides data at the campaign domain level consisting of
    <span class="documentation italic"><u>cumulative</u></span> searches, clicks, and revenue at the
    latest hour available for that date.
  </div>
  <div class="documentation-highlighted">
    https://reports.openmail.com/v2/subid_estimated_hourly.json?auth_key=
  </div>
  <div class="documentation shifted">
    Provides an estimate of your searches, clicks, and revenue for each System1 campaign domain and
    subid based on the hour the events occurred (<span class="documentation italic"
      ><u>non-cumulative</u></span
    >).
  </div>
  <h3 class="documentation-sub-header">API Parameters</h3>
  <div class="documentation-grid-container" [style]="getHeaderCount(tables[0])">
    <div
      *ngFor="let header of tables[0].headers; index as i"
      [class]="getColumnHeaderClass(tables[0], i)">
      <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let record of tables[0].data">
      <div [class]="getColumnValueClass(0, tables[0])">
        <span class="documentation bold">{{ record.parameter }}</span>
      </div>
      <div [class]="getColumnValueClass(1, tables[0])">
        <span>{{ record.description }}</span>
      </div>
      <div [class]="getColumnValueClass(2, tables[0])">
        <span>{{ record.example }}</span>
      </div>
    </ng-container>
  </div>
  <div class="documentation shifted">
    By default (without <span class="documentation bold">days</span> or
    <span class="documentation bold">campaigns</span> or
    <span class="documentation bold">subids</span>), the API will return all campaigns and subids
  </div>
  <h3 class="documentation-sub-header">Response Fields</h3>
  <div class="documentation-grid-container" [style]="getHeaderCount(tables[1])">
    <div
      *ngFor="let header of tables[1].headers; index as i"
      [class]="getColumnHeaderClass(tables[1], i)">
      <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let record of tables[1].data">
      <div [class]="getColumnValueClass(0, tables[1])">
        <span class="documentation bold">{{ record.field }}</span>
      </div>
      <div [class]="getColumnValueClass(1, tables[1])">
        <span>{{ record.description }}</span>
      </div>
      <div [class]="getColumnValueClass(2, tables[1])">
        <span>{{ record.example }}</span>
      </div>
      <div [class]="getColumnValueClass(3, tables[1])">
        <span>{{ record.dataType }}</span>
      </div>
    </ng-container>
  </div>
  <div class="documentation shifted">
    Response fields will vary by endpoint, not all listed above are available on each endpoint.
  </div>
</div>
