import { NgModule } from '@angular/core';
import { VideoPlaylistComponent } from './video-playlist.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMaterialModule } from 'core-global-frontend-common-ui';

@NgModule({
  declarations: [VideoPlaylistComponent],
  exports: [VideoPlaylistComponent],
  imports: [AngularMaterialModule, BrowserModule, CommonModule],
})
export class VideoPlaylistModule {}
