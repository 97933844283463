import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccessControlGuardService, LayoutComponent } from "@nimbus/shared-lib";
import { OktaAuthGuard } from "@okta/okta-angular";
import { PixelManagerComponent } from "./pixel-manager/pixel-manager.component";

const routes: Routes = [
    {
        path: 'pixel',
        component: LayoutComponent,
        canActivate: [OktaAuthGuard, AccessControlGuardService],
        data: {
          resourceName: 'PixelsComponent',
        },
        children: [
          {
            path: 'manager',
            component: PixelManagerComponent,
            data: {
              resourceName: 'PixelManagerComponent',
            },
          }
        ]
      },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PixelManagerRoutingModule {}
