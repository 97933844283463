import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'core-global-frontend-angular-material';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SiteMapNode } from '../site-map-node';

@Component({
  selector: 'frontend-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
  imports: [CommonModule, RouterModule, AngularMaterialModule, RouterModule],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubMenuComponent {
  @Input() siteMapNode!: SiteMapNode;
  @Input() path: string | undefined = '';
  @ViewChild('menu') public menu!: MatMenu;

  constructor(public ovs: ObjectValidatorService) {}
}
