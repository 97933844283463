import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import {
  TokenCacheService,
} from '@nimbus/shared-lib';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { PartnerCacheService } from '../../../../shared/services/partner-cache.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { SubverticalRequestSchemaService } from '../../shared/schemas/subvertical-request-schema.service';
import { catchError, map } from 'rxjs/operators';
import { SubverticalRequestModel } from '../../shared/models/subvertical-request.model';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { SubverticalRequestCacheService } from '../services/subvertical-request-cache.service';

@Component({
  selector: 'app-subvertical-request-form',
  templateUrl: './subvertical-request-form.component.html',
  styleUrls: ['./subvertical-request-form.component.scss'],
})
export class SubverticalRequestFormWrapperComponent {
  private _subscriptionManager = new SubscriptionManager();

  protected readonly _apiCallInProgressSubject = new BehaviorSubject<boolean>(
    false,
  );
  readonly apiCallInProgress$: Observable<boolean> =
    this._apiCallInProgressSubject.asObservable();

  form = new FormGroup({});
  model = {};
  options: FormlyFormOptions = {};
  fields = [];

  constructor(
    public partnerCacheService: PartnerCacheService,
    private _ovs: ObjectValidatorService,
    public _subverticalRequestSchemaService: SubverticalRequestSchemaService,
    private _tokenCacheService: TokenCacheService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _subverticalRequestCacheService: SubverticalRequestCacheService,
    private _snackbarService: SnackbarService,
  ) {
    this._subscriptionManager.register(
      this.partnerCacheService.partners$.subscribe(partners => {
        if (this._ovs.isArray(partners) && partners.length > 0)
          this.fields = this._subverticalRequestSchemaService.getSchema();

        let keywordsField = this.fields.find(obj => obj.key === 'keywords');

        if (!this._ovs.isPathNullOrEmpty(keywordsField, ['props', 'label'])) {
          keywordsField.props['label'] = 'Keywords *';
        }
      }),
    );
  }

  toPascaleCase(str): string {
    return str.replace(
      /(\w)(\w*)/g,
      (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase(),
    );
  }

  submitRequest(): void {
    let requestBody = undefined;
    this._apiCallInProgressSubject.next(true);
    this._tokenCacheService.userId.subscribe(userId => {
      const subverticalRequest = (this.form.value as any).subverticalRequest;
      let keywords = ((this.form.value as any).keywords as string).trim();
      keywords.endsWith('\n') ? (keywords = keywords.slice(0, -1)) : null;
      keywords = keywords
        .split('\n')
        .map(k => this.toPascaleCase(k))
        .join('\n');
      keywords = keywords.replace(/^\s*[\r\n ]/gm, '');

      requestBody = new SubverticalRequestModel(
        null,
        subverticalRequest.vertical,
        subverticalRequest.subVertical,
        subverticalRequest.geo,
        null,
        subverticalRequest.language,
        null,
        keywords,
        userId,
        '',
        '',
        new Date(),
        null,
        null,
        null,
        null,
        0,
        null,
        null,
      );
    });
    this._snackbarService.open(
      'Processing your request.',
      SnackBarType.working,
      3000,
    );
    this._webServiceBuilderService.builder
      .withUrl('subverticalrequests')
      .build()
      .create(requestBody)
      .pipe(
        map(() => this._subverticalRequestCacheService.load(true)),
        catchError(err => {
          this._snackbarService.open(
            'Request failed!',
            SnackBarType.error,
            3000,
          );
          this._apiCallInProgressSubject.next(false);
          throw err;
        }),
      )
      .subscribe(() => {
        this._snackbarService.open(
          'Request submitted successfully!',
          SnackBarType.done,
          3000,
        );
        this.options.resetModel();
        this._apiCallInProgressSubject.next(false);
      });
  }

  canSubmit(): boolean {
    return this.form.valid;
  }
}
