import { Injectable } from '@angular/core';
import { ColumnInfo, RowInfo } from 'core-global-frontend-grid';
import { DomainConfigurationService } from '../../domain-configuration/services/domain-configuration.service';
import { DomainStatusEnum } from '../../models/domain-status.enum';
import { DatePipe } from '@angular/common';

@Injectable()
export class DomainListColumnsBuilderService {
  rowInfo: RowInfo = {};

  constructor(
    private _domainConfigurationService: DomainConfigurationService,
    private _datePipe: DatePipe,
  ) {}

  buildColumns(isMultiPartner: boolean, keywordAccess: boolean) {
    let columns: ColumnInfo[] = [
      {
        field: 'actionButtonConfigure',
        label: 'Configure',
        type: 'configure-domain-cell',
        toolTip:
          'We’ll let you know when your domain is ready to go - it may take some time to come online!',
        csvExportable: false,
        clickEvent: customCell => {
          if (
            customCell.form.controls['status'].value ===
            DomainStatusEnum[DomainStatusEnum.Success].toString()
          ) {
            this._domainConfigurationService.setDomainConfigurationId(
              customCell.form.controls['id'].value,
            );
          }
        },
      },
      {
        field: 'status',
        sortable: true,
        label: 'Status',
        type: 'frontend-status-cell',
      },
    ];
    if (isMultiPartner) {
      columns.push({
        field: 'partnerName',
        label: 'Partner Name',
        sortable: true,
      });
    }
    columns = [
      ...columns,
      {
        label: 'Domain Name',
        field: 'domainName',
        sortable: true,
        toolTip:
          'Click in here to set up your domain parameters, including tracking URLs.',
        type: 'domain-url',
      },
      {
        label: 'Target Url',
        field: 'targetUrl',
        sortable: false,
        toolTip:
          'This is the root domain, plus all the parameters you’ve appended. It’s what you’ll input into your buyside network’s UI and where you’ll be sending traffic.',
        type: 'domain-url',
      },
    ];
    if (keywordAccess) {
      columns.push({
        label: 'Forcekey',
        field: 'forceKey',
        toolTip: 'Keyword Optimization.',
        type: 'keyword-optimization',
      });
    }
    return [
      ...columns,
      {
        label: 'Date Created',
        field: 'createdAt',
        subtype: 'date',
        sortable: true,
        transform: (value): any =>
          this._datePipe.transform(value, 'yyyy-MM-dd'),
        toolTip: 'This is the date that your domain was created.',
      },
      {
        label: 'Date Updated',
        sortable: true,
        subtype: 'date',
        field: 'updatedAt',
        transform: (value): any =>
          this._datePipe.transform(value, 'yyyy-MM-dd'),
        toolTip: 'This is the date that your domain was last updated.',
      },
    ];
  }
}
