import { DistinctNumberStack, RowCol } from './copy-model';
import { QRow } from './q-row';
import { ColumnConfiguration, ColumnType } from './column-configuration';
import { QGrid } from './q-grid';
import { GridClipboard } from './grid-clipboard';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { GridValue } from './grid-value';
import { ChangeEmitType } from './change-emit-type';
import { InvalidCharactersBlocker } from '../plugins/invalid-characters-blocker';
import { GridCallbackParameter } from './grid-callback-parameter';

export class GridCopyPaste {
  private _lastCalcValue = 0;
  private _d = 0;

  copyVertical = true;
  copyTargetColumns: DistinctNumberStack;
  copyTargetRows: DistinctNumberStack;

  copiedCols: DistinctNumberStack;
  copiedRows: DistinctNumberStack;

  constructor(
    private _ovs: ObjectValidatorService,
    private _items: QGrid,
    private _columns: ColumnConfiguration[],
    private _bulkOptions: () => boolean,
    private _canAddRowOnPaste: () => boolean,
    private _colMax: () => number,
    private _addNewRow: (refreshModel?: boolean) => void,
    private _updateHighLightArea: (from: RowCol, to: RowCol) => void,
    private _currentHighlightedColumns: DistinctNumberStack,
    private _currentHighlightedRows: DistinctNumberStack,
    private _clipboard: GridClipboard,
    private _gridValue: GridValue,
  ) {
    this.copyTargetColumns = new DistinctNumberStack(_ovs);
    this.copyTargetRows = new DistinctNumberStack(_ovs);
    this.copiedRows = new DistinctNumberStack(_ovs);
    this.copiedCols = new DistinctNumberStack(_ovs);
  }

  paste(isInternal: boolean) {
    if (isInternal) {
      this._cellToCellCopy();
    } else {
      this._pasteFromExternalSource();
    }
  }

  copy(): void {
    this.copiedCols.emptyAndPushValue(this._currentHighlightedColumns.min);
    this.copiedCols.push(this._currentHighlightedColumns.max);
    this.copiedRows.emptyAndPushValue(this._currentHighlightedRows.min);
    this.copiedRows.push(this._currentHighlightedRows.max);
    this._copyToClipboard();
  }

  private _copyToClipboard(): any {
    // copy cell values
    let value = '';
    let taggedValue = '';
    for (
      let sourceRowIndex = this.copiedRows.min;
      sourceRowIndex <= this.copiedRows.max;
      sourceRowIndex++
    ) {
      for (
        let sourceColIndex = this.copiedCols.min;
        sourceColIndex <= this.copiedCols.max;
        sourceColIndex++
      ) {
        const sourceRow: QRow = this._items.getRow(sourceRowIndex);
        const sourceColumn: ColumnConfiguration = this._columns.find(
          c => c.visibleColumnNumber === sourceColIndex,
        );
        if (!this._ovs.isDefined(sourceColumn)) {
          continue;
        }
        const sourceColName = sourceColumn.fieldName;
        const sourceColValue = this._gridValue.getCellValue(
          sourceColumn,
          sourceColName,
          this._items.getRow(sourceRowIndex),
        );
        value +=
          (sourceColIndex === this.copiedCols.min
            ? ''
            : this._clipboard.defaultColumnDelimiter) +
          (this._ovs.isDefined(sourceColValue)
            ? this._ovs.isObject(sourceColValue)
              ? JSON.stringify(sourceColValue)
              : sourceColValue
            : '');
        taggedValue +=
          (sourceColIndex === this.copiedCols.min
            ? ''
            : this._clipboard.colTag) +
          (this._ovs.isDefined(sourceColValue) ? sourceColValue : '');
      }
      if (sourceRowIndex < this.copiedRows.max) {
        value += this._clipboard.defaultRowDelimiter;
        taggedValue += this._clipboard.rowTag;
      }
    }
    this._clipboard.copyToClipboard(
      value,
      this._items.qGridComponent.gridGUID,
      taggedValue,
    );
  }

  private _pasteFromExternalSource() {
    const table = this._clipboard.getTable();
    if (!this._ovs.isDefined(table) || table.length === 0) {
      return;
    }
    const _copyRowMin = 0;
    let _copyRowMax =
      table.length > 1 &&
      table[table.length - 1].length === 1 &&
      table[table.length - 1][0] === ''
        ? table.length - 2
        : table.length - 1;
    const _copyColMin = 0;
    let _copyColMax = table[0].length - 1;

    if (!this._bulkOptions()) {
      _copyRowMax = 0;
      _copyColMax = 0;
    }

    // add necessary empty rows
    let rowsToBeAdded = this._calcNoOfRowsToAdd(_copyRowMin, _copyRowMax);
    while (rowsToBeAdded > 0) {
      this._addNewRow(false);
      rowsToBeAdded--;
    }
    // paste cell values from clipboard data
    let targetRowKey = this._currentHighlightedRows.min;
    let targetCol: number;
    for (
      let sourceRow = _copyRowMin;
      sourceRow <= _copyRowMax && targetRowKey <= this._items.pageMaxKey;
      sourceRow++
    ) {
      targetCol = this._currentHighlightedColumns.min;
      for (let sourceCol = _copyColMin; sourceCol <= _copyColMax; sourceCol++) {
        if (targetCol > this._colMax()) {
          break;
        }
        let cellValueToCopy =
          this._gridValue.cleanseValueIfTargetColumnIsNumberType(
            targetCol,
            table[sourceRow][sourceCol],
          );

        const invalidCharactersBlocker =
          (this._columns[targetCol].cellPlugins.find(
            plugin => plugin instanceof InvalidCharactersBlocker,
          ) as InvalidCharactersBlocker) ?? null;
        cellValueToCopy = this._ovs.isDefined(invalidCharactersBlocker)
          ? invalidCharactersBlocker.clean(cellValueToCopy)
          : cellValueToCopy;

        const targetRow = this._items.getRow(targetRowKey);

        if (
          this._clipboard.isClipboardDataCopyable(
            targetCol,
            cellValueToCopy,
            targetRow,
            this._columns,
          )
        ) {
          const targetColFieldName = this._columns.find(
            c => c.visibleColumnNumber === targetCol,
          ).fieldName;
          targetRow.update(
            targetColFieldName,
            this._ovs.isJsonString(cellValueToCopy) &&
              this._columns[targetCol].columnType === ColumnType.Component
              ? JSON.parse(cellValueToCopy)
              : this._ovs.isNumber(cellValueToCopy)
                ? Number(cellValueToCopy)
                : cellValueToCopy,
          );
          targetRow.trackChanges(
            targetColFieldName === ColumnConfiguration.qGridRowSelectorFieldName
              ? ChangeEmitType.Selection
              : ChangeEmitType.Pending,
          );
        }
        targetCol++;
      }
      targetRowKey++;
    }
    this._updateHighLightArea(
      {
        row: this._currentHighlightedRows.min,
        col: this._currentHighlightedColumns.min,
      },
      {
        row:
          targetRowKey <= this._items.pageMaxKey
            ? targetRowKey - 1
            : this._items.pageMaxKey,
        col: targetCol - 1,
      },
    );
    this.resetCopy(); // to hide green copy style
  }

  private _calcNoOfRowsToAdd(
    min: number = this.copiedRows.min,
    max: number = this.copiedRows.max,
  ): number {
    if (
      !(
        this._canAddRowOnPaste() &&
        this._items.isLastPage &&
        this._bulkOptions()
      )
    ) {
      return 0;
    }
    const rowDiff = this._items.viewCount - this._currentHighlightedRows.max;
    const copyLength = max - min + 1;
    return rowDiff >= copyLength ? 0 : copyLength - rowDiff;
  }

  resetCopy(): void {
    this.copiedCols.reset();
    this.copiedRows.reset();
    this._clipboard.resetInternalCopy();
  }

  calcPaste(): any {
    const copyLength = this.copiedRows.max - this.copiedRows.min + 1;
    if (this.copyVertical) {
      for (
        let copyColIndex = this.copyTargetColumns.min;
        copyColIndex <= this.copyTargetColumns.max;
        copyColIndex++
      ) {
        let nextSrcRow = this.copiedRows.min;
        for (
          let copyRowIndex = this.copyTargetRows.min;
          copyRowIndex <= this.copyTargetRows.max;
          copyRowIndex++
        ) {
          const nextSrcColumn = this._columns.find(
            c => c.visibleColumnNumber === copyColIndex,
          );
          const nextSrcColumnFieldName = nextSrcColumn.fieldName;
          const nextSrcColValue = this._gridValue.getCellValue(
            nextSrcColumn,
            nextSrcColumnFieldName,
            this._items.getRow(nextSrcRow),
            nextSrcColumn.visibleColumnNumber,
          );
          const targetRow = this._items.getRow(copyRowIndex);
          const fieldName = nextSrcColumn.fieldName;
          if (
            this.isCopyable(
              copyColIndex,
              copyColIndex,
              nextSrcColValue,
              targetRow,
            )
          ) {
            if (
              copyLength === 2 &&
              (this._columns[copyColIndex].columnType === ColumnType.String ||
                this._columns[copyColIndex].columnType === ColumnType.Number)
            ) {
              if (nextSrcColumn.columnType === ColumnType.Component) {
                targetRow.update(
                  nextSrcColumnFieldName,
                  this._gridValue.cleanseValueIfTargetColumnIsNumberType(
                    copyColIndex,
                    nextSrcColValue,
                  ),
                );
              } else {
                targetRow.update(
                  fieldName,
                  this._replace(
                    copyRowIndex === this.copyTargetRows.min,
                    this._gridValue.cleanseValueIfTargetColumnIsNumberType(
                      copyColIndex,
                      nextSrcColValue,
                    ),
                    copyRowIndex === this.copyTargetRows.min
                      ? this._items.getRow(nextSrcRow + 1).value[
                          nextSrcColumn.fieldName
                        ]
                      : null,
                  ),
                );
              }
            } else {
              targetRow.update(
                fieldName,
                this._gridValue.cleanseValueIfTargetColumnIsNumberType(
                  copyColIndex,
                  nextSrcColValue,
                ),
              );
            }
            targetRow.trackChanges(
              fieldName === ColumnConfiguration.qGridRowSelectorFieldName
                ? ChangeEmitType.Selection
                : ChangeEmitType.Pending,
            );
            nextSrcRow =
              nextSrcRow === this.copiedRows.max
                ? this.copiedRows.min
                : nextSrcRow + 1;
          }
        }
      }
    } else {
      // horizontal
      for (
        let copyRowIndex = this.copyTargetRows.min;
        copyRowIndex <= this.copyTargetRows.max;
        copyRowIndex++
      ) {
        let nextSrcCol = this.copiedCols.min;
        const copyRow = this._items.getRow(copyRowIndex);
        for (
          let copyColIndex = this.copyTargetColumns.min;
          copyColIndex <= this.copyTargetColumns.max;
          copyColIndex++
        ) {
          const sourceColumn = this._columns.find(
            c => c.visibleColumnNumber === nextSrcCol,
          );
          const sourceFieldName = sourceColumn.fieldName;
          const nextSrcColValue = this._gridValue.getCellValue(
            sourceColumn,
            sourceFieldName,
            copyRow,
            nextSrcCol,
          );
          if (
            this.isCopyable(copyColIndex, nextSrcCol, nextSrcColValue, copyRow)
          ) {
            const nextRowColumn = this._columns.find(
              c => c.visibleColumnNumber === copyColIndex,
            );
            const nextRowFieldName = nextRowColumn.fieldName;
            copyRow.update(
              nextRowFieldName,
              this._gridValue.cleanseValueIfTargetColumnIsNumberType(
                copyColIndex,
                nextSrcColValue,
              ),
            );
            nextSrcCol =
              nextSrcCol === this.copiedCols.max
                ? this.copiedCols.min
                : nextSrcCol + 1;
          }
        }
        copyRow.trackChanges(ChangeEmitType.Pending);
      }
    }

    this._copiedToHighlighted(
      this.copyTargetColumns,
      this._currentHighlightedColumns,
    );
    this.copyTargetColumns.reset();
    this._copiedToHighlighted(
      this.copyTargetRows,
      this._currentHighlightedRows,
    );
    this.copyTargetRows.reset();
    this.resetCopy();
  }

  isCopyable(
    targetColNo: number,
    sourceColNo: number,
    valueToCopy: any,
    targetRow: QRow,
  ): any {
    const targetCol = this._columns.find(
      c => c.visibleColumnNumber === targetColNo,
    );
    const sourceCol = this._columns.find(
      c => c.visibleColumnNumber === sourceColNo,
    );
    const gridCallbackParameter = targetRow.getGridCallbackParameter();
    if (
      targetCol.isColumnVisible() === false ||
      !(this._ovs.isFunction(targetCol.isEditable)
        ? (
            targetCol.isEditable as (
              qCallbackParam: GridCallbackParameter,
            ) => boolean
          )(gridCallbackParameter)
        : (targetCol.isEditable as boolean)) ||
      targetCol.fieldName === ColumnConfiguration.nA ||
      sourceCol.fieldName === ColumnConfiguration.nA
    ) {
      // can not be copied, if the target column is not visible, or not editable, or its field name is NA
      return false;
    }
    if (
      // if the target type is string, it can accept any value
      targetCol.columnType === ColumnType.Component &&
      sourceCol.columnType === ColumnType.Component &&
      targetCol.dynamicComponentType.name ===
        sourceCol.dynamicComponentType.name // if type is component, and component type's are the same, then can be copied
    ) {
      return true;
    }
    if (
      targetCol.columnType !== ColumnType.Component &&
      // if the target type is string, it can accept any value
      (targetCol.columnType === ColumnType.String || // if types are equal, and they're not lookups then can be copied
        (targetCol.columnType === sourceCol.columnType &&
          targetCol.columnType !== ColumnType.Lookup) || // if type is lookup, and their field names are equal, then can be copied
        (targetCol.columnType === sourceCol.columnType &&
          targetCol.columnType === ColumnType.Lookup &&
          (this._ovs.isFunction(targetCol.cellLookupsKey)
            ? (
                targetCol.cellLookupsKey as (
                  qCallbackParam: GridCallbackParameter,
                ) => string
              )(gridCallbackParameter)
            : (targetCol.cellLookupsKey as string)) ===
            (this._ovs.isFunction(sourceCol.cellLookupsKey)
              ? (
                  sourceCol.cellLookupsKey as (
                    qCallbackParam: GridCallbackParameter,
                  ) => string
                )(gridCallbackParameter)
              : (sourceCol.cellLookupsKey as string))))
    ) {
      return true;
    }
    if (targetCol.columnType === ColumnType.Number) {
      // fro number types, if the value can be parsed can then be copied
      if (this._ovs.isNullOrEmpty(valueToCopy)) {
        return true;
      }
      const cleanValue = this._gridValue.cleanseValueIfTargetColumnIsNumberType(
        targetColNo,
        valueToCopy,
      );
      return cleanValue !== '';
    }
    return false;
  }

  private _cellToCellCopy(): any {
    // add necessary empty rows
    let rowsToBeAdded = this._calcNoOfRowsToAdd();
    while (rowsToBeAdded > 0) {
      this._addNewRow(false);
      rowsToBeAdded--;
    }

    const copiedColCount = this.copiedCols.count;
    const copyColReset = copiedColCount < this._currentHighlightedColumns.count;
    let copyColRepeatMax =
      this.copiedCols.min +
      Math.floor(this._currentHighlightedColumns.count / copiedColCount) *
        copiedColCount +
      (this._currentHighlightedColumns.count % copiedColCount) -
      1;
    const copiedRowCount = this.copiedRows.max - this.copiedRows.min + 1;
    const copyRowReset = copiedRowCount < this._currentHighlightedRows.count;
    let copyRowRepeatMax =
      this.copiedRows.min +
      Math.floor(this._currentHighlightedRows.count / copiedRowCount) *
        copiedRowCount +
      (this._currentHighlightedRows.count % copiedRowCount) -
      1; // paste cell values
    const copyCRMax = copyColRepeatMax;
    let targetRowKey = this._currentHighlightedRows.min;
    let targetColNo: number;
    for (
      let sourceRowIndex = this.copiedRows.min;
      sourceRowIndex <=
        (copyRowReset
          ? Math.min(this.copiedRows.max, copyRowRepeatMax)
          : this.copiedRows.max) && targetRowKey <= this._items.pageMaxKey;
      sourceRowIndex++
    ) {
      targetColNo = this._currentHighlightedColumns.min;
      for (
        let sourceColIndex = this.copiedCols.min;
        sourceColIndex <=
        (copyColReset
          ? Math.min(this.copiedCols.max, copyColRepeatMax)
          : this.copiedCols.max);
        sourceColIndex++
      ) {
        if (targetColNo > this._colMax()) {
          break;
        }
        const sourceRow = this._items.getRow(sourceRowIndex);
        const sourceColumn = this._columns.find(
          c => c.visibleColumnNumber === sourceColIndex,
        );
        if (!this._ovs.isDefined(sourceColumn)) {
          continue;
        }
        const sourceColName = sourceColumn.fieldName;
        const targetRow = this._items.getRow(targetRowKey);
        const sourceValue = this._gridValue.getCellValue(
          sourceColumn,
          sourceColName,
          sourceRow,
          targetColNo,
        );
        const targetColumn = this._columns.find(
          c => c.visibleColumnNumber === targetColNo,
        );
        const targetFieldName = targetColumn.fieldName;
        if (
          this.isCopyable(targetColNo, sourceColIndex, sourceValue, targetRow)
        ) {
          if (this._ovs.isDefined(targetRow.value)) {
            targetRow.update(
              targetFieldName,
              this._gridValue.cleanseValueIfTargetColumnIsNumberType(
                targetColNo,
                sourceValue,
              ),
            );
            targetRow.trackChanges(
              targetFieldName === ColumnConfiguration.qGridRowSelectorFieldName
                ? ChangeEmitType.Selection
                : ChangeEmitType.Pending,
            );
          }
        }
        targetColNo++;
        if (
          sourceColIndex === this.copiedCols.max &&
          targetColNo <= this._currentHighlightedColumns.max &&
          copyColReset
        ) {
          sourceColIndex = this.copiedCols.min - 1;
          copyColRepeatMax -= copiedColCount;
        }
      }
      copyColRepeatMax = copyCRMax; // reset col repeat max
      targetRowKey++;
      if (
        sourceRowIndex === this.copiedRows.max &&
        targetRowKey <= this._currentHighlightedRows.max &&
        copyRowReset
      ) {
        sourceRowIndex = this.copiedRows.min - 1;
        copyRowRepeatMax -= copiedRowCount;
      }
    }

    this._updateHighLightArea(
      {
        row: this._currentHighlightedRows.min,
        col: this._currentHighlightedColumns.min,
      },
      {
        row:
          targetRowKey <= this._items.pageMaxKey
            ? targetRowKey - 1
            : this._items.pageMaxKey,
        col: targetColNo - 1,
      },
    );

    this.copyTargetRows.reset();
    this.copyTargetColumns.reset();
    this.resetCopy();
  }

  private _replace(
    isNewColumn: boolean,
    source: string,
    source2: string,
  ): string {
    if (this._ovs.isNullOrEmpty(source)) {
      return source;
    }
    // check if source starts with a number value
    const match = source.toString().match(/\d+$/);
    if (this._ovs.isDefined(match)) {
      let no: number;
      if (isNewColumn) {
        no = parseInt(match[0], 10);
        this._lastCalcValue = no;
        this._d = 1;
        if (this._ovs.isDefined(source2)) {
          // check if source2 starts with a number value
          const match2 = source2.toString().match(/\d+$/);
          if (this._ovs.isDefined(match2)) {
            const no2 = parseInt(match2[0], 10);
            this._d = no2 - no;
            this._lastCalcValue = no2;
          }
        }
      }

      this._lastCalcValue += this._d;
      const x = source.toString().substr(0, match.index) + this._lastCalcValue;
      return x;
    } else {
      return source;
    }
  }

  private _copiedToHighlighted(
    copiedNumbers: DistinctNumberStack,
    toHighlight: DistinctNumberStack,
  ): void {
    if (copiedNumbers.min < toHighlight.min) {
      const cc = toHighlight.clone();
      toHighlight.reset();
      copiedNumbers.list.map(uniqueNumber => toHighlight.push(uniqueNumber));
      cc.map(uniqueNumber => toHighlight.push(uniqueNumber));
      return;
    }

    copiedNumbers.list.map(uniqueNumber => toHighlight.push(uniqueNumber));
  }
}
