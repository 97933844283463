import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IClickableCell, ICustomCell } from '../icell-component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ColumnInfo } from '../../grid/column-info';
import { MatTableDataSource } from '@angular/material/table';
import { CommonModule } from '@angular/common';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'status',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
  ],
})
export class StatusCellComponent
  implements ICustomCell, IClickableCell, OnInit
{
  @Output() clickEvent = new EventEmitter<ICustomCell>();
  @Input() form!: FormGroup;
  @Input() column!: ColumnInfo;
  @Input() editable = false;
  @Input() rowIndex!: number;
  @Input() dataSource!: MatTableDataSource<FormGroup<any>, MatPaginator>;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.form.controls[this.column.field].valueChanges.subscribe(() => {
      this._changeDetectorRef.markForCheck();
      this._changeDetectorRef.detectChanges();
    });
  }

  getValue(): string {
    const value = this.form?.controls[this.column.field].value ?? '';
    return this.column.transform ? this.column.transform(value) : value;
  }

  getStatusIconClass(): string {
    return `status-icon status-icon-${this.getValue().toLowerCase()}`;
  }

  click(): void {
    this.clickEvent.emit(this);
  }
}
