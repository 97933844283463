<p>This section should take less than one hour to complete.</p>
<ol type="i">
    <li>
    <div class="documentation-highlighted">Add RampJs script</div>
    <br />
    <p>
        The code for either option can be inserted into the head of the HTML page or copied into a separate .js file that is referenced in the head. Either way, the RampJs code should be placed as high up in the head element as possible to ensure that the call to RampJs happens as quickly as possible. 
    </p>
    <p>
        To load and initialize RampJs, you will need to add a JavaScript snippet to your page. There are two ways to do so. Use the first method only if you need to set a subdomain for your SERP page. Otherwise, use the second. In all circumstances, avoid using both at the same time - this will cause Ramp.js to run twice.
    </p>
    <h4>Option 1 - Subdomain</h4>
    <p>Add the following javascript to the page to load and initialize RampJs with a subdomain:</p>
    <div class="code-block">
        <code>
            <pre>&lt;script async src="https://rampjs-cdn.system1.com/ramp.js"&gt;&lt;/script&gt;
&lt;script&gt;
    (function(w,r)&lbrace;w[r]=w[r]||function()&lbrace;(w[r]['q']=w[r]['q']||[]).push(
        arguments)&rbrace;,w[r]['t']=1*new Date&rbrace;
    )(window,'_rampJs');
    _rampJs(&lbrace; subdomain: "find" &rbrace;);
&lt;/script&gt; </pre>
        </code>
    </div>
    <br />
    <h4>Option 2 - No Subdomain</h4>
    <p>Add the following javascript to the page to load and initialize RampJs without a subdomain:</p>
    <div class="code-block">
        <code>
            <pre>&lt;script async src="https://rampjs-cdn.system1.com/ramp.js"&gt;&lt;/script&gt;
&lt;script&gt;
    (function(w,r)&lbrace;w[r]=w[r]||function()&lbrace;(w[r]['q']=w[r]['q']||[]).push(
        arguments)&rbrace;,w[r]['t']=1*new Date&rbrace;)(window,'_rampJs');
    _rampJs();
&lt;/script&gt; </pre>
        </code>
    </div>
    </li>
    <br />
    <li>
    <div class="documentation-highlighted">Developer mode</div>
    <p>
        If you require access for development from a host domain that differs from the final approved domain, you can utilize the <span class="notice-this">referrer</span> and <span class="notice-this">testMode</span> parameters shown highlighted below. Just bear in mind that this only works when passing the testMode as true, which by proxy will not report any revenues or clicks for live traffic. <span class="notice-this">It is therefore imperative that the ‘referrer’ and ‘testMode’ params be removed before going live on the final approved domain, in order to prevent loss of revenues.</span>
    </p>
    <div class="code-block">
        <code>
            <pre>
&lt;script defer src="https://rampjs-cdn.system1.com/ramp.js"&gt;&lt;/script&gt;
&lt;script&gt;
        (function(w,r)&lbrace;w[r]=w[r]||function()&lbrace;(w[r]['q']=w[r]['q']||[]).push(
        arguments)&rbrace;,w[r]['t']=1*new Date&rbrace;)(window,'_rampJs');
        _rampJs(&lbrace;
                referrer: "https://exampleproductionhost.com",
                testMode: true,
                terms: "Test Term 1, Test Term 2, Test Term 3, Test Term 4",
            init: &lbrace;
        segment: "segment01",
        click_track_url: "https://test.com",
        subid: "1234_test_camp99",
        fbid: "1234",
        fbclick: "Purchase"
        &rbrace;
    &rbrace;);
&lt;/script&gt; </pre>
        </code>
    </div>
    </li>
    <br />
    <li>
    <div class="documentation-highlighted">Add Div Placeholders</div>
    <p>Insert empty div placeholders wherever you want each RSoC widget to render on the page. Each div must have a unique id, and ids must follow the RampJs naming convention: rampjs_slot1, rampjs_slot2. A maximum of two RampJs divs are allowed.</p>
    <br />
    <div class="code-block">
        <code>
            <pre>&lt;div id="rampjs_slot1"&gt;&lt;/div&gt;
&lt;div id="rampjs_slot2"&gt;&lt;/div&gt;</pre>
        </code>
    </div>
    <br />
    <div>The example below shows both steps 1 and 2 together:</div>
    <br />
    <div class="code-block">
        <code>
            <pre> &lt;html&gt;
&lt;head&gt;
  ...
  &lt;script async src="https://rampjs-cdn.system1.com/ramp.js"&gt;&lt;/script&gt;
  &lt;script&gt;
    (function(w,r)&lbrace;w[r]=w[r]||function()&lbrace;(w[r]['q']=w[r]['q']||[]).push(
        arguments)&rbrace;,w[r]['t']=1*new Date&rbrace;)(window,'_rampJs');
    _rampJs();
  &lt;/script&gt;
  ...
&lt;/head&gt;
&lt;body&gt;
  ...
  &lt;div id="rampjs_slot1"&gt;&lt;/div&gt;
  ...
  &lt;div id="rampjs_slot2"&gt;&lt;/div&gt;
  ...
&lt;/body&gt;
&lt;/html&gt; </pre>
        </code>
        <br />
    </div>
    </li>
    <br />
    <li>
    <div class="documentation-highlighted"> Include Parameters [optional]</div>
    <p>
        Subsequent sections of this document explain what parameters you can pass in the URL of your page to specify keywords or set up reporting and conversion tracking. 
    </p>
    <p>
     <span class="notice-this">However, any such parameters that can be passed in the URL can alternately be passed directly in the page’s JavaScript code.</span> This method requires that the parameters are passed in the init object.
    </p>
    <p>
        The following example shows how the click_track_url, fbid, and fbclid conversion tracking parameters could be passed with JavaScript:
    </p>
    <div class="code-block">
        <code>
            <pre>
&lt;script defer src="https://rampjs-cdn.system1.com/ramp.js"&gt;&lt;/script&gt;
&lt;script&gt;
      (function(w,r)&lbrace;w[r]=w[r]||function()&lbrace;(w[r]['q']=w[r]['q']||[]).push(
       arguments)&rbrace;,w[r]['t']=1*new Date&rbrace;)(window,'_rampJs');
  	_rampJs(&lbrace;
               terms: "Test Term 1, Test Term 2, Test Term 3, Test Term 4",
	        init: &lbrace;
		segment: "segment01",
		click_track_url: "https://test.com",
		subid: "1234_test_camp99",
		fbid: "1234",
		fbclick: "Purchase"
	  &rbrace;
	&rbrace;);
&lt;/script&gt;
            </pre>
        </code>
    </div>
    </li>
</ol>