import { Component, Inject } from '@angular/core';
import { ColumnType } from '../grid/column-type';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AngularMaterialModule } from 'core-global-frontend-common-ui';

/**
 * The column filter is still in POC mode.  It works but would required more styling and testing to be production ready.
 * It is desabled by default and not i use in the grid yet.
 */
@Component({
  selector: 'frontend-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularMaterialModule, MatSlideToggleModule],
})
export class FilterDialogComponent {
  get filterLabel(): string {
    return this.data?.column?.label;
  }
  get isBooleanType(): boolean {
    return this.data?.column?.type === ColumnType.Checkbox;
  }

  constructor(
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  //Only search on enter.
  filterChanged(_: any): void {}

  submitted(event: any): void {
    let value = event.target?.value;
    let result = {
      label: this.filterLabel,
      field: this.data?.column?.field,
      filterString: value,
    };
    this.dialogRef.close(result);
  }
}
