<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<main role="main" id="main" class="main" [class.padded]="layoutService?.title">
  <lib-confirmation-wrapper
    class="lib-confirmation"
    [hidden]="!(confirmationService.visible$ | async)">
  </lib-confirmation-wrapper>
  <div [hidden]="!topBarShown">
    <div class="top">
      <app-top-options-toggler
        *ngIf="layoutService.topOptionTogglerVisible"></app-top-options-toggler>
      <h2 *ngIf="layoutService?.title" class="title">{{ layoutService.title }}</h2>
    </div>
    <div class="mobile-header" *ngIf="!layoutService.topOptionTogglerVisible"></div>
    <div
      #preFiltersDivWrapper
      [class]="
        topOptionsTogglerCacheService.isControlVisibleAndSmallerView()
          ? 'top-bar'
          : 'top-bar top-bar--shown'
      "
      [class.integrated]="layoutService?.title">
      <ng-container #preFiltersContainer></ng-container>
    </div>
  </div>
  <div class="relative" [style]="prefiltersHeight | contentHeightPercent">
    <div
      *ngIf="isResizing"
      class="resizer-container"
      (mouseup)="isResizing = false"
      (mousemove)="resize($event)"
      (mouseleave)="isResizing = false"></div>
    <section
      #dockPageContainer
      [style]="
        bottomDockHeight$
          | async
          | parentContainerHeightPercent: (layoutService.dockVisibilityChange$ | async)
      "
      class="section"
      [class.tight]="layoutService?.title">
      <router-outlet></router-outlet>
    </section>
    <div
      *ngIf="ovs.isDefined(dockContent$ | async) && ovs.isDefined(model$ | async)"
      class="bottom-dock bottom-dock__container"
      [style]="
        bottomDockHeight$
          | async
          | childContainerHeightPercent
            : [layoutService.dockVisibilityChange$ | async, maximizedHeight]
      ">
      <div
        [class]="'resizer ' + (isResizing ? ' resizer-active' : '')"
        (mousedown)="startResizing($event)"></div>
      <div class="bottom-dock">
        <div class="dock__btn-group">
          <!-- <button
            mat-mini-fab
            class="dock__max-btn"
            (click)="toggleMaximize()"
            data-test="dock-max-button"
            *ngIf="(layoutService.dockVisibilityChange$ | async) !== dockVisibleState.maximize">
            <svg role="img">
              <use xlink:href="assets/img/sprites.svg#right-arrow-icon" />
            </svg>
          </button>
          <button
            mat-mini-fab
            class="dock__min-btn"
            (click)="toggleMaximize()"
            data-test="dock-min-button"
            *ngIf="(layoutService.dockVisibilityChange$ | async) === dockVisibleState.maximize">
            <svg role="img">
              <use xlink:href="assets/img/sprites.svg#right-arrow-icon" />
            </svg>
          </button> -->
          <button
            mat-mini-fab
            class="dock__close-btn"
            (click)="layoutService.closeDock(true)"
            data-test="dock-close-button">
            <svg role="img">
              <use xlink:href="assets/img/sprites.svg#close-icon-no-circle" />
            </svg>
          </button>
        </div>
      </div>
      <lib-dock
        [dockContent]="dockContent$ | async"
        [model]="model$ | async"
        [visibleDockableIndex]="visibleDockableIndex$ | async"
        (modelUpdate)="modelUpdate($event)">
      </lib-dock>
    </div>
  </div>
</main>
