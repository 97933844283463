import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'core-global-frontend-config';
import { LoggingService, LoggerMethodEvent } from 'core-global-frontend-logging';

@Component({
  selector: 'lib-notification',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css'],
})
export class NotificationComponent implements OnInit {
  constructor(
    private _http: HttpClient,
    private _configService: ConfigService,
    private _loggingService: LoggingService
  ) {}

  ngOnInit(): void {
    this.sendNotification();
  }

  sendNotification(): void {
    const notificationPayload = {
      sourceApplication: this._configService.applicationName,
      transport: 'slack',
      target: '#advertising-utilities-eks-notifications',
      message: `Application ${this._configService.applicationName} started in ${this._configService.env} image ${this._configService.getTag()} host: ${this._configService.getHost()}`,
    };
    
    const headers = new HttpHeaders({
      'nimbus-api-key': this._configService.getNimbusApiKey() || '',
    });

    this._http.post<string>(`${this._configService.getApi('NotificationAPI')}/app/send-notification`, notificationPayload, { headers, responseType: 'text' as 'json' }).subscribe({
      next: (response) => {
        this._loggingService.log(
        'NotificationComponent', 
        'sendNotification', 
        LoggerMethodEvent.Information, 
        'Notification sent successfully, UUID:', 
        response);
      },
      error: (error) => {
        this._loggingService.log(
        'NotificationComponent', 
        'sendNotification', 
        LoggerMethodEvent.Error, 
        'Failed to send notification', 
        error);
      }
    });
  }
}
