import { SortOption } from './../../core/sort.options';
import { Component, Input, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map, startWith, tap } from 'rxjs/operators';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ListRange } from '@angular/cdk/collections';
import { SearchableListGroup } from './models/searchable-list-group';
import { SelectAllHandler } from './models/select-all-handler';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { SearchableListItemWithSelect } from './models/searchable-list-item-with-select';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { BackdropService } from './models/backdrop.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { SubjectManager } from '../../core/subject-manager';
import { IListItem } from './models/i-list-item';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lib-searchable-select-control',
  templateUrl: './searchable-select-control.component.html',
  styleUrls: ['./searchable-select-control.component.scss']
})
export class SearchableSelectControlComponent implements OnInit, OnDestroy {
  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  @ViewChild(MatAutocompleteTrigger) autoComplete: MatAutocompleteTrigger;
  @ViewChild('container', { static: true }) private _container: ElementRef;
  initialRange: ListRange = { start: 0, end: 5 } as ListRange;
  adjustMargins = false;
  height: string;
  selectAllHandler: SelectAllHandler;
  filterField = new FormControl();
  filteredList$: Observable<SearchableListItemWithSelect[]>;
  private _subscriptionManager = new SubscriptionManager();
  private _subjectManager = new SubjectManager();
  private _allowSorting = false;
  private _enableMultiSelect = false;
  private _label = '';
  label$: Observable<string>;
  private _readonly = false;
  private _populateListOnLoad = true;
  isListPopulated = false;
  initialDisplayValue: string;
  private _selectAllDisplay = 'Select All';
  private _autoOpenPanel = false;
  private _displayItems = 4;
  private _isDisabled = false;
  private _showAdd = false;
  private _flattenedList = [];
  private _list: SearchableListGroup[] = [];
  private _searchableListItems: SearchableListItemWithSelect[];
  private _filteredValues: SearchableListItemWithSelect[];
  private _appendOnly = true;
  private _sortOption: SortOption = SortOption.AutoSort;
  @Input() control: any;
  // Cannot be a set input - will lose reference to original form control and stop data binding
  @Input() set sortOption(sortOption: SortOption) {
    this._subjectManager.next('sortOption', sortOption);
  }
  @Input() set appendOnly(enable: boolean) {
    this._subjectManager.next('appendOnly', enable);
  }
  get appendOnly(): boolean {
    return this._appendOnly;
  }
  @Input() set allowSorting(allowSorting: boolean) {
    this._subjectManager.next('allowSorting', allowSorting);
  }
  @Input() set enableMultiSelect(enableMultiSelect: boolean) {
    this._subjectManager.next('enableMultiSelect', enableMultiSelect);
  }
  get enableMultiSelect() {
    return this._enableMultiSelect;
  }
  @Input() set label(label: string) {
    this._subjectManager.next('label', label);
  }
  get label() {
    return this._label;
  }
  @Input() set populateListOnLoad(populateListOnLoad: boolean) {
    this._subjectManager.next('populateListOnLoad', populateListOnLoad);
  }
  get populateListOnLoad() {
    return this._populateListOnLoad;
  }
  @Input() set readonly(readonly: boolean) {
    this._subjectManager.next('readonly', readonly);
  }
  get readonly() {
    return this._readonly;
  }
  @Input() set selectAllDisplay(selectAllDisplay: string) {
    this._subjectManager.next('selectAllDisplay', selectAllDisplay);
  }
  @Input() set autoOpenPanel(autoOpenPanel: boolean) {
    this._subjectManager.next('autoOpenPanel', autoOpenPanel);
  }
  @Input() set displayItems(displayItems: number) {
    this._subjectManager.next('displayItems', displayItems);
  }
  @Input() set isDisabled(isDisabled: boolean) {
    this._subjectManager.next('isDisabled', isDisabled);
  }
  @Input() set showAdd(showAdd: boolean) {
    this._subjectManager.next('showAdd', showAdd);
  }
  get showAdd(): boolean {
    if (this._list.length > 1) {
      return false;
    }
    return this._showAdd;
  }
  @Input() set searchableListItems(searchableListItems: SearchableListItemWithSelect[]) {
    this._subjectManager.next('searchableListItems', searchableListItems);
  }
  @Input() defaultPlaceholder = '';
  get selectedItems(): string {
    const joinedItems = this._list
      .map(listGroup => [...listGroup.value.filter(x => x.selected).map(x => x.display)])
      .filter(x => !this.ovs.isNullOrEmpty(x))
      .join();
    return this.ovs.isNullOrEmpty(joinedItems) ? this.defaultPlaceholder : joinedItems;
  }

  constructor(
    public ovs: ObjectValidatorService,
    private _backdrop: BackdropService,
    private sanitized: DomSanitizer
  ) {
    this.registerSubjectsAndDefaults();
    this._subscriptionManager.register(
      _backdrop.onBackdropClose.subscribe(event => {
        if (!event.bubbles && this.autoComplete.panelOpen) {
          this.autoComplete.closePanel();
        }
      })
    );
  }

  clickEmitter(event: MouseEvent) {
    if(!this.enableMultiSelect){
      this._container?.nativeElement?.click(event);
    }
  }

  registerSubjectsAndDefaults() {
    this._subjectManager.registerMultiple([
      'control',
      'sortOption',
      'allowSorting',
      'enableMultiSelect',
      'label',
      'readonly',
      'selectAllDisplay',
      'autoOpenPanel',
      'displayItems',
      'isDisabled',
      'showAdd',
      'searchableListItems',
      'appendOnly',
      'populateListOnLoad'
    ]);

    this._subjectManager.next('allowSorting', this._allowSorting);
    this._subjectManager.next('enableMultiSelect', this._enableMultiSelect);
    this._subjectManager.next('label', this._label);
    this._subjectManager.next('readonly', this._readonly);
    this._subjectManager.next('selectAllDisplay', this._selectAllDisplay);
    this._subjectManager.next('autoOpenPanel', this._autoOpenPanel);
    this._subjectManager.next('displayItems', this._displayItems);
    this._subjectManager.next('isDisabled', this._isDisabled);
    this._subjectManager.next('showAdd', this._showAdd);
    this._subjectManager.next('appendOnly', this._appendOnly);
    this._subjectManager.next('populateListOnLoad', this._populateListOnLoad);
  }

  ngOnDestroy(): void {
    this._subjectManager.clear();
    this._subscriptionManager.clear();
  }

  ngOnInit(): void {
    this.label$ = this._subjectManager.getObservable('label').pipe(
      tap(label => (this._label = this.ovs.isNullOrEmpty(label) ? this._label : label)),
      map(label => label + (this.control.hasValidator(Validators.required) ? ' *' : ''))
    );

    this._subscriptionManager.registerMultiple([
      this._subjectManager
        .getObservable('appendOnly')
        .pipe(
          tap(
            enable =>
              (this._appendOnly = this.ovs.isNullOrEmpty(enable) ? this._appendOnly : enable)
          )
        )
        .subscribe(),
      this._subjectManager
        .getObservable('sortOption')
        .pipe(
          tap((sortOption: SortOption) => {
            this._sortOption = this.ovs.isNullOrUndefined(sortOption)
              ? SortOption.AutoSort
              : sortOption;
          })
        )
        .subscribe(),
      combineLatest([
        this.control.valueChanges.pipe(
          tap(values => {
            this._setSelectedValues(values as any);
            if (!this.ovs.isNullOrEmpty(this._searchableListItems)) {
              this._calculateDefaultValue();
            }
          })
        ),
        this._subjectManager
          .getObservable('allowSorting')
          .pipe(
            tap(
              allowSorting =>
              (this._allowSorting = this.ovs.isNullOrEmpty(allowSorting)
                ? this._label
                : allowSorting)
            )
          ),
        this._subjectManager
          .getObservable('enableMultiSelect')
          .pipe(
            tap(
              enableMultiSelect =>
              (this._enableMultiSelect = this.ovs.isNullOrEmpty(enableMultiSelect)
                ? this._enableMultiSelect
                : enableMultiSelect)
            )
          ),
        this._subjectManager
          .getObservable('readonly')
          .pipe(
            tap(
              readonly =>
                (this._readonly = this.ovs.isNullOrEmpty(readonly) ? this._readonly : readonly)
            )
          ),
        this._subjectManager
          .getObservable('populateListOnLoad')
          .pipe(
            tap(
              populateListOnLoad =>
              (this._populateListOnLoad = this.ovs.isNullOrEmpty(populateListOnLoad)
                ? this._populateListOnLoad
                : populateListOnLoad)
            )
          ),
        this._subjectManager
          .getObservable('selectAllDisplay')
          .pipe(
            tap(
              selectAllDisplay =>
              (this._selectAllDisplay = this.ovs.isNullOrEmpty(selectAllDisplay)
                ? this._selectAllDisplay
                : selectAllDisplay)
            )
          ),
        this._subjectManager.getObservable('autoOpenPanel').pipe(
          tap(autoOpenPanel => {
            this._autoOpenPanel = this.ovs.isNullOrEmpty(autoOpenPanel)
              ? this._autoOpenPanel
              : autoOpenPanel;
            if (autoOpenPanel) {
              setTimeout(() => {
                if (this.ovs.isDefined(this.autoComplete)) {
                  this.autoComplete.openPanel();
                }
              }, 250);
            }
          })
        ),
        this._subjectManager
          .getObservable('displayItems')
          .pipe(debounceTime(150))
          .pipe(
            tap(displayItems => {
              this._displayItems = this.ovs.isNullOrEmpty(displayItems)
                ? this._displayItems
                : displayItems;
              this.initialRange = { start: 0, end: displayItems } as ListRange;
              this.height = displayItems * 50 + 'px';
            })
          ),
        this._subjectManager.getObservable('isDisabled').pipe(
          tap(isDisabled => {
            this._isDisabled = this.ovs.isNullOrEmpty(isDisabled) ? this._isDisabled : isDisabled;
            if (this._isDisabled) {
              this.filterField.disable();
            } else {
              this.filterField.enable();
            }
          })
        ),
        this._subjectManager
          .getObservable('showAdd')
          .pipe(
            tap(
              showAdd => (this._showAdd = this.ovs.isNullOrEmpty(showAdd) ? this._showAdd : showAdd)
            )
          ),
        this._subjectManager.getObservable('searchableListItems').pipe(
          tap((searchableListItems: SearchableListItemWithSelect[]) => {
            this._searchableListItems = searchableListItems;
            if (this._populateListOnLoad) {
              this._populateList();
            } else {
              if (!this.ovs.isNullOrEmpty(this._searchableListItems)) {
                this._calculateDefaultValue();
              }
            }
          })
        )
      ]).subscribe(() => {
        this._afterDataLoad();
      })
    ]);
  }

  private _calculateDefaultValue() {
    this.initialDisplayValue =
      this._searchableListItems[0].value == this._searchableListItems[0].display
        ? this.control?.value
        : this._searchableListItems.find(item => item.value === this.control?.value)?.display;
  }

  private _afterDataLoad() {
    if (this._enableMultiSelect) {
      this.selectAllHandler = new SelectAllHandler(
        this._list[0].value,
        this._selectAllDisplay,
        this.ovs
      );
    }
    this.filterField.setValue('');
    this._setSelectedValues(this.control.value);
    this._setFilteredList();
  }

  opened() {
    this.viewPort.setRenderedContentOffset(0);
    this.viewPort.setRenderedRange(this.initialRange);
    this._backdrop.onPanelOpen.next();
  }

  closed() {
    this._backdrop.onPanelClose.next();
  }

  onFocus() {
    this.control.markAsTouched();
    this.control.markAsDirty();
  }

  scrolledIndexChanged() {
    if (!this.initialRange) {
      this.initialRange = this.viewPort.getRenderedRange();
    }
  }

  toggleSelection(searchableListItem: SearchableListItemWithSelect) {
    if (searchableListItem.disabled) {
      return;
    }
    if (!this._enableMultiSelect) {
      this._list.forEach(listGroup => listGroup.value.forEach(item => (item.selected = false)));
      searchableListItem.selected = true;
      this.filterField.setValue(searchableListItem.display, { emitEvent: false });
      this.autoComplete.closePanel();
    } else {
      searchableListItem.selected = !searchableListItem.selected;
      if (this.selectAllHandler) {
        if (searchableListItem.selected) {
          this.selectAllHandler.select();
        } else {
          this.selectAllHandler.deselect();
        }
      }
    }
    this._listChanged();
  }

  toggleSelectAll() {
    if (!this._enableMultiSelect) {
      return;
    }
    const selected = this.selectAllHandler.isSelected;
    this._list.map(listGroup =>
      listGroup.value.map(item => {
        if (
          !this.ovs.isNullOrEmpty(this._filteredValues) &&
          this._filteredValues.map(x => x.value).includes(item.value)
        ) {
          item.selected = !selected;
        }
        return item;
      })
    );
    this.selectAllHandler.toggle();
    this._listChanged();
  }

  async addListItem(_clickEvent) {
    const filterValue = this.filterField.value;
    if (this.ovs.isDefined(filterValue) && typeof filterValue === 'string') {
      if (!this._list[0].value.find(item => item.value === filterValue)) {
        this._list[0].value.push(new SearchableListItemWithSelect(filterValue, true, filterValue));
        this.updatedFlattedList();
        this._setFilteredList();
        this._listChanged();
      }
    }
  }

  boldMatchText(display: string, userInput: string) {
    return this.sanitized.bypassSecurityTrustHtml(
      display.replace(
        new RegExp(userInput, 'gi'),
        match => `<span style="font-weight:bold">${match}</span>`
      )
    );
  }

  private updatedFlattedList() {
    switch (this._sortOption) {
      case SortOption.AutoSort:
        this._list.sort((a, b) => a.display.localeCompare(b.display));
        this._list = this._list.map(eachlist => {
          eachlist.value.sort((a, b) => a.display.localeCompare(b.display));
          return eachlist;
        });
        break;
      case SortOption.AutoSortWithCustomOption:
        this._list.sort((a, b) => a.display.localeCompare(b.display));
        // The fist item in each group won't be sorted.
        for (let i = 0; i < this._list.length; i++) {
          if (this._list[i].value.length <= 1) continue;
          const subItems = this._list[i].value.slice(1);
          subItems.sort((a, b) => a.display.localeCompare(b.display));
          subItems.unshift(this._list[i].value[0]);
          this._list[i].value = subItems;
        }
        break;
    }
    this._flattenedList = [];
    if (this._hasOptionGroups()) {
      this._list.forEach(group => {
        this._flattenedList.push(
          new SearchableListItemWithSelect(null, false, group.display, '', true)
        );
        this._flattenedList.push(...group.value);
        this.adjustMargins = true;
      });
    } else {
      this._flattenedList = this._list.reduce((acc, val) => acc.concat(val.value), []);
    }
  }

  private _initializeFlattenedList(list: IListItem[]) {
    if (this.ovs.isNullOrEmpty(list)) {
      list = [new SearchableListGroup([], null)];
    }
    if (!this.ovs.isArray(list[0].value)) {
      list = [new SearchableListGroup(list, null)];
    }
    this._list = list;
    this.updatedFlattedList();
  }

  private _hasOptionGroups(): boolean {
    return !this.ovs.isNullOrEmpty(this._list.find(x => !this.ovs.isNullOrEmpty(x.display)));
  }

  private _setSelectedValues(localValues: string[] | string) {
    if (!this._readonly && this.ovs.isNullOrEmpty(localValues)) {
      localValues = [];
      if (this.control.hasValidator(Validators.required)) {
        this.control.setErrors({ required: true });
      }
      if (!this._enableMultiSelect) {
        this.filterField.setValue('');
      }
    } else {
      this.control.setErrors(null);
    }
    this._list.forEach(group => {
      group.value.forEach(item => {
        item.selected = false;
        if (!this.ovs.isNullOrEmpty(localValues) && !this.ovs.isArray(localValues)) {
          if (item.value === (localValues as string)) {
            this._setFieldValue(item);
          }
        } else if (!this.ovs.isNullOrEmpty(localValues)) {
          if (
            !this.ovs.isNullOrEmpty((localValues as string[]).find(value => item.value === value))
          ) {
            this._setFieldValue(item);
          }
        }
      });
    });
  }

  private _setFieldValue(item: IListItem) {
    item.selected = true;
    if (!this._enableMultiSelect) {
      this.filterField.setValue(item.display);
    }
  }

  private _listChanged() {
    const values = [].concat(
      ...this._list.map(listGroup =>
        listGroup.value
          .filter(item => item.selected)
          .filter(item => item.value !== null)
          .map(item => item.value)
      )
    );
    let finalValues = values;
    if (!this._enableMultiSelect) {
      finalValues = this.ovs.isNullOrEmpty(finalValues) ? null : values[0];
    }
    this.control.setValue(finalValues);
    this.control.markAsDirty();
    this.control.markAsTouched();
  }

  private _setFilteredList() {
    this.filteredList$ = this.filterField.valueChanges.pipe(
      startWith(''),
      map(value => {
        const list = this._flattenedList.filter(item => {
          if (item.disabled) {
            return [];
          }
          let filteredValues: any[];
          if (
            !this._enableMultiSelect &&
            !this.ovs.isNullOrEmpty(this.control.value) &&
            (value === this.control.value ||
              value === this._getDisplayFromValue(this.control.value))
          ) {
            filteredValues = item;
          } else {
            filteredValues = item.display.toLowerCase().includes(value.toLowerCase());
          }
          return filteredValues;
        });
        if (list.length < this._displayItems) {
          this.height = list.length * 50 + 'px';
        } else {
          this.height = this._displayItems * 50 + 'px';
        }
        return list;
      }),
      map(list => {
        this._filteredValues = this._allowSorting ? list.sort(a => (a.selected ? -1 : 1)) : list;
        return this._filteredValues;
      })
    );
  }

  private _getDisplayFromValue(value: any): string {
    if (this.ovs.isNullOrEmpty(this._list)) {
      return null;
    }
    let display = null;
    this._list
      .filter(list => !this.ovs.isNullOrEmpty(list))
      .forEach(list => {
        const item = list.value.find(item => item.value === value);
        if (this.ovs.isDefined(item)) {
          display = item.display;
          return;
        }
      });
    return display;
  }

  populateListAndOpenPanel() {
    this._populateList();
    this.autoComplete.openPanel();
  }

  private _populateList() {
    this.isListPopulated = true;
    this._initializeFlattenedList(this._searchableListItems as any[]);
    this._afterDataLoad();
  }
}
