import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { AccessingApiReportsComponent } from "../accessing-api-reports/accessing-api-reports.component";

@Component({
    selector: 'app-reporting-api-for-afd-kw-data',
    templateUrl: './reporting-api-for-afd-kw-data.component.html',
    styleUrl: './reporting-api-for-afd-kw-data.component.scss',
    standalone: true,
    imports: [CommonModule, AccessingApiReportsComponent]
})
export class ReportingApiForAfdKwDataComponent {
  possibleReportTypeValues = ['syndication_afd_keyword_daily'];
}