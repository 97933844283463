import { Injectable } from '@angular/core';


@Injectable()
export class SessionManagerService {
  private _pages: { [id: string]: () => void /*clearAfterLogout()*/ } = {};

  startupCleaning() {
    // Here we could probably look at the user signing in and double check if it's the same as
    // what we have in the local storage.  Different users might need different settings.
    // For how many users do we want to save the settings? Are we running out of storage?
    // ... all things to think about...
  }

  subscribe(key: string, clearAfterLogoutCallback: () => void) {
    this._pages[key] = clearAfterLogoutCallback;
  }

  unsubscribe(key: string) {
    const keysToKeep = Object.keys(this._pages).filter(k => k !== key);

    const pageToKeep: { [id: string]: () => void } = {};
    keysToKeep.map(k => {
      pageToKeep[k] = this._pages[k];
    });
    this._pages = pageToKeep;
  }

  clean() {
    Object.keys(this._pages).map(k => this._pages[k]());
  }
}
