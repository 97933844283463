import { DateSelectionType } from './date-selection-type';
import { TimeService } from './time-service';
import { DateTime } from 'luxon';

export class DateRange {
  dateRangeType: DateSelectionType;
  startDate: DateTime;
  endDate: DateTime;

  constructor(
    private _timeService: TimeService,
    private _dateSelectionType: DateSelectionType,
    _startDate?: DateTime,
    _endDate?: DateTime,
    private _isUtc = false
  ) {
    if (this.dateRangeType !== null) {
      this.setDate(_dateSelectionType, _startDate as any, _endDate as any);
    }
  }

  private _today() {
    return this._isUtc ? this._timeService.todayUtc() : this._timeService.today();
  }

  equals(dateRange: DateRange): boolean {
    return !(
      this.dateRangeType !== dateRange.dateRangeType ||
      Math.abs(this.startDate.diff(dateRange.startDate).toMillis()) >= 1 ||
      Math.abs(this.endDate.diff(dateRange.endDate).toMillis()) >= 1
    );
  }

  setByType(dateSelectorVal: DateSelectionType) {
    this.dateRangeType = dateSelectorVal;
    const dayOfMonth = this._today().day;

    switch (dateSelectorVal) {
      case DateSelectionType.Yesterday:
        this.startDate = this._today().plus({ days: -1 });
        this.endDate = this._today().plus({ seconds: -1 });
        break;
      case DateSelectionType.TwoDaysAgo:
        this.startDate = this._today().plus({ days: -2 });
        this.endDate = this._today().plus({ days: -1 }).plus({ seconds: -1 });
        break;
      case DateSelectionType.ThreeDaysAgo:
        this.startDate = this._today().plus({ days: -3 });
        this.endDate = this._today().plus({ days: -2 }).plus({ seconds: -2 });
        break;
      case DateSelectionType.LastThreeDays:
        this.startDate = this._today().plus({ days: -3 });
        this.endDate = this._today().plus({ seconds: -1 });
        break;
      case DateSelectionType.ThreeDaysPriorToYesterday:
        this.startDate = this._today().plus({ days: -4 });
        this.endDate = this._today().plus({ days: -1 }).plus({ seconds: -1 });
        break;
      case DateSelectionType.LastSevenDays:
        this.startDate = this._today().plus({ days: -7 });
        this.endDate = this._today().plus({ seconds: -1 });
        break;
      case DateSelectionType.SevenDaysPriorToYesterday:
        this.startDate = this._today().plus({ days: -8 });
        this.endDate = this._today().plus({ days: -1 }).plus({ seconds: -1 });
        break;
      case DateSelectionType.ThisWeek:
        this.startDate = this._today().plus({ days: -1 * this._today().day });
        this.endDate = this._today().plus({ seconds: -1 });
        break;
      case DateSelectionType.LastWeek:
        this.startDate = this._today().plus({ days: -1 * (this._today().weekday + 7) });
        this.endDate = this._today()
          .plus({ days: -1 * this._today().weekday })
          .plus({ seconds: -1 });
        break;
      case DateSelectionType.LastFourteenDays:
        this.startDate = this._today().plus({ days: -14 });
        this.endDate = this._today().plus({ seconds: -1 });
        break;
      case DateSelectionType.LastThirtyDays:
      case DateSelectionType.MonLast30:
      case DateSelectionType.TuesLast30:
      case DateSelectionType.WedLast30:
      case DateSelectionType.ThursLast30:
      case DateSelectionType.FriLast30:
      case DateSelectionType.SatLast30:
      case DateSelectionType.SunLast30:
        this.startDate = this._today().plus({ days: -30 });
        this.endDate = this._today().plus({ seconds: -1 });
        break;
      case DateSelectionType.ThisMonth:
        this.startDate = this._today().plus({ days: -1 * dayOfMonth + 1 });
        this.endDate = this._today().plus({ seconds: -1 });
        break;
      case DateSelectionType.LastMonth: {
        const numOfDaysInLastMonth = this._today().plus({ months: -1 }).daysInMonth;
        this.startDate = this._today()
          .plus({ days: -1 * dayOfMonth + -1 * numOfDaysInLastMonth + 1 })
          .plus({ days: 1 })
          .plus({ seconds: -1 });
        this.endDate = this._today()
          .plus({ days: -1 * dayOfMonth })
          .plus({ days: 1 })
          .plus({ seconds: -1 });
        break;
      }
      case DateSelectionType.NineDays:
        this.startDate = this._today().plus({ day: -9 });
        this.endDate = this._today().plus({ second: -1 });
        break;
    }
  }

  setDate(dateType: DateSelectionType, startDate?: DateTime, endDate?: DateTime) {
    if (dateType === 'Custom') {
      this.startDate = startDate;
      this.endDate = endDate;
      this.dateRangeType = dateType;
    } else {
      this.setByType(dateType);
    }
  }

  setDateRange(dateRange: DateRange) {
    this.dateRangeType = dateRange.dateRangeType;
    this.startDate = dateRange.startDate;
    this.endDate = dateRange.endDate;
  }

  setByDates(startDate: Date = null, endDate: Date = null) {
    this.startDate = DateTime.fromJSDate(startDate);
    this.endDate = DateTime.fromJSDate(endDate);
    this.dateRangeType = DateSelectionType.Custom;
  }

  clone(): DateRange {
    const dr: DateRange = new DateRange(this._timeService, this._dateSelectionType);
    // RPT-60 Test
    // LuxonJS immutability makes this pointless; just reuse the object
    dr.startDate = this.startDate;
    dr.endDate = this.endDate;
    return dr;
  }
}
