import { LocalStorageKey } from './local-storage-key.model';
import { LocalStorageManagerService } from './local-storage-manager.service';
import { LocalStorageKeyFactory } from './local-storage-key-factory';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export class LocalStorageProxy {
  constructor(
    private _localStorageKeyFactory: LocalStorageKeyFactory,
    private _ovs: ObjectValidatorService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _modelStructureIndicator: string,
    private _contextName: string,
    defaultObject?: unknown,
  ) {
    if (!this.found() && _ovs.isDefined(defaultObject)) {
      this.saveState(defaultObject);
    }
  }

  found(): boolean {
    return !this._ovs.isNullOrEmpty(
      this._localStorageManagerService.getKey(this._key()),
    );
  }

  private _key(): LocalStorageKey {
    return this._localStorageKeyFactory.build(
      this._contextName,
      this._modelStructureIndicator,
    );
  }

  retrieve<T>(): T {
    const content = this._localStorageManagerService.getKey(this._key());
    return (this._ovs.isNullOrEmpty(content) ? null : JSON.parse(content)) as T;
  }

  saveState(state: unknown): void {
    this._localStorageManagerService.setKey(this._key(), JSON.stringify(state));
  }

  delete(): void {
    this._localStorageManagerService.deleteKey(this._key());
  }
}
