import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { AccessControlService } from './access-control.service';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { TokenCacheService } from '../../services/user/token-cache.service';
import { Observable, combineLatest, filter, map, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessControlGuardService {
  constructor(
    private _snackbarService: SnackbarService,
    private _accessControlService: AccessControlService,
    private _tokenCacheService: TokenCacheService
  ) { }

  canActivate(next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([
      this._tokenCacheService.isAuthenticated$.pipe(filter(isAuthenticated => isAuthenticated)),
      this._accessControlService.validAccess$.pipe(filter(isAuthenticated => isAuthenticated))
    ]).pipe(
      map(_ => this._accessControlService.canAccessByResource(
        next.routeConfig.data?.['resourceName']
      )
      ),
      tap(canAccess => {
        if (!canAccess) {
          this._snackbarService.open(
            `You do not have access to this url. Please contact an administrator for access. [Component: ${next.routeConfig.data?.['resourceName']}]`,
            SnackBarType.error
          );
        }
      }));
  }
}
