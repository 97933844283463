import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

export enum SubjectType {
  ReplaySubject,
  Subject,
  BehaviourSubject,
}

export class SubjectRegistration<T> {
  constructor(
    public key: string,
    public subjectType: SubjectType,
  ) {}
}

export class SubjectManager<T> {
  private _subjects: { [id: string]: SubjectItem<any> } = {};
  private _ovs = new ObjectValidatorService();

  registerMultiple(
    subjectRegistrations: SubjectRegistration<T>[] | string[],
    subjectType: SubjectType = SubjectType.ReplaySubject,
  ) {
    if (!subjectRegistrations || subjectRegistrations?.length === 0 || false) {
      return;
    }
    if (this._ovs.isString(subjectRegistrations[0])) {
      (subjectRegistrations as string[]).forEach(key =>
        this.register(key, subjectType),
      );
      return;
    }

    subjectRegistrations.forEach(subjectRegistration =>
      this.register(
        (subjectRegistration as any as SubjectRegistration<T>).key,
        (subjectRegistration as any as SubjectRegistration<T>).subjectType,
      ),
    );
  }

  register<T>(
    key: string,
    subjectType: SubjectType = SubjectType.ReplaySubject,
    input: T | null = null,
  ) {
    if (!this._ovs.isDefined(this._subjects[key])) {
      switch (subjectType) {
        case SubjectType.ReplaySubject:
          this._subjects[key] = new SubjectItem<T>(new ReplaySubject<T>());
          if (input) {
            this._subjects[key].next(input as T);
          }
          break;
        case SubjectType.BehaviourSubject:
          if (input) {
            this._subjects[key] = new SubjectItem<T>(
              new BehaviorSubject<T>(input),
            );
          }
          break;
        case SubjectType.Subject:
          this._subjects[key] = new SubjectItem<T>(new Subject<T>());
          if (input) {
            this._subjects[key].next(input);
          }
          break;
      }
    }
  }

  next<T>(key: string, input: T): void {
    this._subjects[key].next(input as any);
  }

  clear() {
    for (const key in this._subjects) {
      this._subjects[key].unsubscribe();
    }
    this._subjects = {};
  }

  get<T>(key: string): Observable<T> {
    return this._subjects[key].getObservable() as any;
  }

  getSharedObservable<T>(key: string): Observable<T> {
    return this._subjects[key].observable$ as any;
  }
}

export class SubjectItem<T> {
  observable$: Observable<T>;

  constructor(private _subject: Subject<T>) {
    this.observable$ = _subject.asObservable();
  }

  next(input: T) {
    this._subject.next(input);
  }

  getObservable(): Observable<T> {
    return this._subject.asObservable();
  }

  unsubscribe() {
    this._subject.unsubscribe();
  }
}
