import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { LocalStorageKey } from './local-storage-key.model';
import { LocalStorageManagerService } from './local-storage-manager.service';
import { LocalStorageKeyFactory } from './local-storage-key-factory';
import { TimeService } from '../time-service';

export class LocalStorageProxy {
  constructor(
    private _localStorageKeyFactory: LocalStorageKeyFactory,
    private _timeService: TimeService,
    private _ovs: ObjectValidatorService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _classVersion: string,
    private _contextName: string,
    defaultObject?: any,
  ) {
    if (!this.found() && !_ovs.isNullOrEmpty(defaultObject)) {
      this.saveState(defaultObject);
    }
  }

  found(): boolean {
    return !this._ovs.isNullOrEmpty(
      this._localStorageManagerService.getKey(this._key()),
    );
  }

  private _key(): LocalStorageKey {
    return this._localStorageKeyFactory.build(
      this._contextName,
      this._classVersion,
      this._timeService.todayUtc().plus({ years: 1 }).toJSDate(),
    );
  }

  retrieve<T>(): any {
    return JSON.parse(
      this._localStorageManagerService.getKey(this._key()),
    ) as T;
  }

  saveState(state: any): void {
    this._localStorageManagerService.setKey(this._key(), JSON.stringify(state));
  }

  delete(): void {
    this._localStorageManagerService.deleteKey(this._key());
  }
}
