import { Component } from '@angular/core';
import { FieldArrayType, FormlyFieldConfig } from '@ngx-formly/core';
import { DomainCreatorAddService } from '../../domain-creator/services/domain-creator-add.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { DomainCreateLimiterService } from '../../domain-creator/services/domain-create-limiter.service';

@Component({
  selector: 'app-domain-creator-single-form',
  templateUrl: './domain-creator-single-form.component.html',
  styleUrls: ['./domain-creator-single-form.component.scss'],
})
export class DomainCreatorSingleFormComponent extends FieldArrayType {
  private _subscriptionManager: SubscriptionManager = new SubscriptionManager();
  expandedIndex = 0;

  constructor(
    private _domainCreatorAddService: DomainCreatorAddService,
    private _domainCreateLimiterService: DomainCreateLimiterService,
    private _ovs: ObjectValidatorService
  ) {
    super();

    this._subscriptionManager.register(
      this._domainCreatorAddService.domainAdded$.subscribe(c => this.addDomain()),
      'new-domain-button-click'
    );
    this.field = {} as any;
  }

  isExpanded(index: number): boolean {
    return (
      this.form.controls['domains'].value.length === 1 ||
      this.expandedIndex === index
    );
  }

  panelOpened(index: number): void {
    this.expandedIndex = index;
  }

  panelClosed(index: number): void {
    if (this.expandedIndex === index) this.expandedIndex = -1;
  }

  getPanelHeader(index: number): string {
    return this.expandedIndex !== index || this.expandedIndex === -1
      ? this._ovs.isNullOrEmpty(
          this.form.controls['domains'].value[index].domainName,
        )
        ? 'incomplete'
        : this.form.controls['domains'].value[index].domainName
      : '';
  }

  invalidCheck(field: FormlyFieldConfig, panelIndex: number): boolean {
    return !field.form?.controls[panelIndex].valid;
  }

  showDivider(index: number): boolean {
    return this.field?.fieldGroup?.length !== index + 1;
  }

  showExpansionHeader(): boolean {
    return this.field?.fieldGroup?.length > 1;
  }

  addDomain(): void {
    if (this._ovs.isNullOrUndefined(this.model)) {
      return;
    }
    this.add();
    this.expandedIndex = this.form.controls['domains'].value.length - 1;
  }

  removeDomain(index: number): void {
    this.remove(index);
    this._domainCreateLimiterService.formDomainUpdated(-1, index);
    for (const repeatableSection of this.formControl.controls) repeatableSection.get('domainName').setErrors(null);
  }
}
