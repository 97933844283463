import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalStorageKey } from './local-storage-key.model';
import { ILocalStorageService } from './ilocal-storage-service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { Observable, of, EMPTY } from 'rxjs';
import {
  LoggerMethodEvent,
  LoggingService,
} from 'core-global-frontend-logging';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements ILocalStorageService {
  constructor(
    private loggingService: LoggingService,
    private _ovs: ObjectValidatorService,
  ) {}

  get(key: string): string | null {
    return localStorage.getItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getKey(key: LocalStorageKey): string {
    return localStorage.getItem(JSON.stringify(key)) as string;
  }

  getKeyWtihCallback(
    key: LocalStorageKey,
    callback: () => Observable<string>,
  ): Observable<unknown> {
    const value = this.getKey(key);
    if (this._ovs.isDefined(value)) {
      return of(value);
    }
    if (this._ovs.isNullOrUndefined(callback)) {
      return EMPTY;
    }
    return callback().pipe(
      map(response => {
        if (this._ovs.isNullOrUndefined(response)) {
          return EMPTY;
        }
        this.setKey(key, response);
        return response;
      }),
      catchError(() => {
        return EMPTY;
      }),
    );
  }

  setKey(key: LocalStorageKey, value: string) {
    try {
      localStorage.setItem(JSON.stringify(key), value);
    } catch {
      this.loggingService.log(
        'QLocalStorageService',
        'setKey',
        LoggerMethodEvent.Error,
        'Local storage running out of space.',
      );
    }
  }

  delete(key: string) {
    localStorage.removeItem(key);
  }

  deleteKey(key: LocalStorageKey) {
    localStorage.removeItem(JSON.stringify(key));
  }
}
