import { Injectable } from '@angular/core';
import { LocalStorageKey } from './local-storage-key.model';
import { ILocalStorageService } from './ilocal-storage-service';

@Injectable()
export class QLocalStorageService implements ILocalStorageService {
  get(key: string): string {
    return localStorage.getItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getKey(key: LocalStorageKey): string {
    return localStorage.getItem(JSON.stringify(key));
  }

  setKey(key: LocalStorageKey, value: string) {
    localStorage.setItem(JSON.stringify(key), value);
  }

  delete(key: string) {
    localStorage.removeItem(key);
  }

  deleteKey(key: LocalStorageKey) {
    localStorage.removeItem(JSON.stringify(key));
  }
}
