import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import OktaAuth from '@okta/okta-auth-js';
import { OKTA_AUTH, OktaAuthModule } from '@okta/okta-angular';

@Component({
  selector: 'frontend-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [OktaAuthModule],
})
export class LoginComponent implements OnInit {
  callbackRoute = 'login/callback';

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private _router: Router,
  ) {}

  async ngOnInit() {
    if (await this.oktaAuth.isAuthenticated()) {
      await this._router.navigate(['/authenticate']);
      return;
    }
    await this.oktaAuth.signInWithRedirect();
  }
}
