import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AccessControlGuardService, LayoutComponent } from "@nimbus/shared-lib";
import { OktaAuthGuard } from "@okta/okta-angular";
import { DomainCreatorComponent } from "./domain-creator/domain-creator.component";
import { DomainManagerWrapperComponent } from "./domain-manager/domain-manager-wrapper/domain-manager-wrapper.component";

const routes: Routes = [
    {
        path: 'domain',
        component: LayoutComponent,
        canActivate: [
          OktaAuthGuard, 
          AccessControlGuardService
        ],
        data: {
          resourceName: 'DomainManagerComponent',
        },
        children: [
          {
            path: 'create',
            component: DomainCreatorComponent,
            canActivate: [OktaAuthGuard, AccessControlGuardService],
            data: {
              resourceName: 'DomainCreatorComponent',
            },
          },
          {
            path: 'manager',
            component: DomainManagerWrapperComponent,
            canActivate: [OktaAuthGuard, AccessControlGuardService],
            data: {
              resourceName: 'DomainManagerComponent',
            },
          },

        ]
      },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DomainRoutingModule {}
