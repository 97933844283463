import { Component } from '@angular/core';
import { ConfirmationService } from './confirmation.service';

@Component({
  selector: 'lib-confirmation-wrapper',
  templateUrl: './confirmation-wrapper.component.html',
  styleUrls: ['./confirmation-wrapper.component.scss']
})
export class ConfirmationWrapperComponent {
  constructor(public confirmationService: ConfirmationService) {}
}
