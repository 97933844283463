import { Injectable } from '@angular/core';
import { SubverticalRequestSchemaService } from '../../shared/schemas/subvertical-request-schema.service';

@Injectable()
export class SubverticalReviewSchemaService {
  private _schema: any[];
  constructor(private _subverticalRequestSchemaService: SubverticalRequestSchemaService) {}

  getSchema(): any[] {
    this._schema = this._subverticalRequestSchemaService.getSchema(false, false);

    const additionalElements = [
      {
        key: 'notes',
        defaultValue: '',
        type: 'app-subvertical-review-note',
        templateOptions: {
          placeholder: `eg. The Keywords requested don't adhere to our language policy.`,
          label: 'Type a response outlining why the request was approved or denied.',
          width: 100,
          rows: 5
        }
      }
    ];

    this._schema.push(...additionalElements);
    return this._schema;
  }
}
