import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormsFeature } from './form';
import { IFormState } from './state';

export class FormsSelectors {
  static readonly getFormsFeatureState = createFeatureSelector<IFormState>(FormsFeature);
  static readonly loading = createSelector(
    this.getFormsFeatureState,
    (state): boolean => state.loading ?? false
  );
  static readonly getForms = createSelector(this.getFormsFeatureState, state => state.forms);
}
