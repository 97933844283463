import { Injectable } from '@angular/core';
import { DomainManagerPrefilterModel } from '../../models/domain-manager-prefilter.model';
import { DomainStatusEnum } from '../../models/domain-status.enum';

@Injectable({ providedIn: 'root' })
export class StatusMapperService {

  mapStatuses(filter: DomainManagerPrefilterModel){
    let newFilter: DomainManagerPrefilterModel = {domainLookup: filter.domainLookup, statuses: [], pageSize: filter.pageSize};
    filter.statuses.forEach(status => {
      switch(status){
      case "Failed":
        newFilter.statuses.push("domain_failed");
        break;
      case "Updating":
        newFilter.statuses.push("pending_template_update");
        break;
      case "Pending":
        newFilter.statuses.push("pending_domain");
        newFilter.statuses.push("requested");
        break;
      case "Disabled":
        newFilter.statuses.push("disabled");
        newFilter.statuses.push("pending_disabled");
        newFilter.statuses.push("pending_disable_poll");
        break;
      case "Success":
        newFilter.statuses.push("domain_verified");
        break;
      }
    });
    return newFilter;
  }

  setParkedDomainStatus(parkedDomainStatus: string): string {
    switch (parkedDomainStatus) {
      case 'domain_failed':
        return DomainStatusEnum[DomainStatusEnum.Failed].toString();
      case 'domain_verified':
        return DomainStatusEnum[DomainStatusEnum.Success].toString();
      case 'pending_template_update':
        return DomainStatusEnum[DomainStatusEnum.Updating].toString();
      case 'pending_domain':
      case 'requested':
        return DomainStatusEnum[DomainStatusEnum.Pending].toString();
      case 'disabled':
      case 'pending_disabled':
      case 'pending_disable_poll':
        return DomainStatusEnum[DomainStatusEnum.Disabled].toString();
      default:
        return DomainStatusEnum[DomainStatusEnum.Updating].toString();
    }
  }
}