import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { IClickableCell, ICustomCell } from '../icell-component';
import { ColumnInfo } from '../../grid/column-info';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'frontend-clipboard-enabled-text-cell',
  templateUrl: './clipboard-enabled-text-cell.component.html',
  styleUrls: ['./clipboard-enabled-text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
  ],
  providers: [SnackbarService],
})
export class ClipboardEnabledTextCellComponent
  implements ICustomCell, IClickableCell
{
  @Input() form!: FormGroup;
  @Input() column!: ColumnInfo;
  @Input() editable = false;
  @Output() clickEvent = new EventEmitter<ICustomCell>();
  @Input() rowIndex!: number;
  @Input() dataSource!: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Input() formGroup!: FormGroup;

  constructor(private _snackbarService: SnackbarService) {}

  getValue(): string {
    const value = this.form?.controls[this.column.field].value ?? '';
    return this.column.transform ? this.column.transform(value) : value;
  }

  onClick(): void {
    this.clickEvent.emit(this);
  }

  copyToClipboard(): void {
    let value = this.getValue();
    navigator.clipboard.writeText(value);
    this._snackbarService.open(
      `"${value}" copied to clipboard!`,
      SnackBarType.done,
      3000,
    );
  }
}
