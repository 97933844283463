import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterBoxComponent } from './filter-box.component';
import { FormsModule } from '@angular/forms';
import { RangeComponent } from './range/range.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FilterBoxCustomContainerComponent } from './filter-box-custom/filter-box-custom-container.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatCardModule,
    MatTooltipModule,
    MatSelectModule,
    ScrollingModule
  ],
  exports: [FilterBoxComponent, RangeComponent],
  declarations: [FilterBoxComponent, RangeComponent, FilterBoxCustomContainerComponent]
})
export class FilterBoxModule {}
