import { Injectable } from '@angular/core';
import { ActionButtonKeyValidatorService } from './plugins/action-button-validator.service';
import { InputMaxLengthValidatorService } from './plugins/input-validator.service';
import { SearchableMultiSelectOptionsValidatorService } from './plugins/searchable-multi-select-validator.service';

@Injectable({
  providedIn: 'root'
})
export class PluginsService {
  constructor(
    private _inputMaxLengthValidatorService: InputMaxLengthValidatorService,
    private _searchableMultiSelectOptionsValidatorService: SearchableMultiSelectOptionsValidatorService,
    private _actionButtonKeyValidatorService: ActionButtonKeyValidatorService
  ) {}

  plugins() {
    return {
      input: {
        InputMaxLengthValidatorService: this._inputMaxLengthValidatorService
      },
      'searchable-multi-select': {
        SearchableMultiSelectOptionsValidatorService:
          this._searchableMultiSelectOptionsValidatorService
      },
      'action-button': {
        ActionButtonKeyValidatorService: this._actionButtonKeyValidatorService
      }
    };
  }
}
