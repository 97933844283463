import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { AccessingApiReportsComponent } from "../accessing-api-reports/accessing-api-reports.component";
import { ColumnClassHelper } from "../shared/columnClassHelper";

@Component({
    selector: 'app-reporting-api-for-bing-hosted',
    templateUrl: './reporting-api-for-bing-hosted.component.html',
    styleUrl: './reporting-api-for-bing-hosted.component.scss',
    standalone: true,
    imports: [CommonModule, AccessingApiReportsComponent]
})
export class ReportingApiForBingHostedComponent {
  possibleReportTypeValues = [
    'bing_hosted_segment_adcountry_performance_daily',
    'bing_hosted_partner_ptqs_performance_daily',
    'bing_hosted_hourly_estimated',
    'bing_hosted_partner_performance_hourly_rollup_to_daily',
  ];
  reportTypes = [
    {
       name: "Segment Report {bing_hosted_segment_adcountry_performance_daily} ",
       description: " : Provides accurate data from Bing. This report should be used to calculate final revenue. This data is 48-72 hours delayed.  ",
    },
    {
       name: "PTQS Report {bing_hosted_partner_ptqs_performance_daily} ",
       description: " : Provides a daily traffic quality score by Ad Country for the past 7 days.  ",
    },
    {
       name: "Hourly Estimated {bing_hosted_hourly_estimated} ",
       description: " : Provides estimated hourly data that is available intraday to help make ad hoc decisions quickly.  ",
    },
    {
       name: "Daily Estimated {bing_hosted_partner_performance_hourly_rollup_to_daily}", 
       description: "Provides a daily rollup of the hourly estimated report by country for the past 7 days.",
    },
  ];
  metrics = [
    {
        reportName: 'Segment Report',
        reportNotes: 'Note: All metrics are available by total searches, initial searches and follow on searches. This data should be used to measure performance.',
        metricsOverview: {
            headers: ['Metric', 'Definition'],
            records: [
                {
                    metric: 'Date',
                    definition: 'The number of days can be provided as a range of days or a specific date.'
                },
                {
                    metric: 'Segment',
                    definition: 'Data is available by segment as specified in the URL.'
                },
                {
                    metric: 'Device',
                    definition: 'Device should be either mobile or desktop.'
                },
                {
                    metric: 'Country',
                    definition: 'The country of the user as detected by Bing.'
                },
                {
                    metric: 'Ad Country',
                    definition: 'The country (market demographic) ads were served for.'
                },
                {
                    metric: 'Gross revenue',
                    definition: 'Your total revenue before rev share is deducted.'
                },
                {
                    metric: 'Ad Impressions',
                    definition: 'Total number of ads that Bing returned during the reporting period.'
                },
                {
                    metric: 'Ad Requested Page Views',
                    definition: 'Number of requests received by Bing.'
                },
                {
                    metric: 'Paid Clicks',
                    definition: 'Number of ad clicks received during the specified reporting period.'
                },
                {
                    metric: 'Impressions',
                    definition: 'Number of page views that received at least one ad during the specified reporting period.'
                },
                {
                    metric: 'Search Result Page Views',
                    definition: 'Total number of page views for traffic sent to Bing.com, including those ineligible for ads.'
                },
                {
                    metric: 'Ad Depth',
                    definition: 'Average number of ads per page view that received at least one ad (Ad Impressions/Impressions).'
                },
                {
                    metric: 'RPC',
                    definition: 'Average revenue per click (Gross Revenue/Paid Clicks).'
                },
                {
                    metric: 'PCTR',
                    definition: 'Percentage of ad clicks per (Clicks/Impressions).'
                },
                {
                    metric: 'RPM',
                    definition: 'Average revenue per 1000 Ad Requested Page Views (Gross Revenue*1000/Ad Requested Page Views).'
                },
                {
                    metric: 'Coverage',
                    definition: 'Percentage of ad requested page views that received at least one ad (Impressions/Ad Requested Page Views).'
                },
            ]
        },
    },
    {
        reportName: 'PTQS Report',
        reportNotes: 'Note: A PTQS score of -1 means that there is insufficient traffic to generate a score.',
        metricsOverview: {
            headers: ['Metric', 'Definition'],
            records: [
                {
                    metric: 'Date',
                    definition: 'The number of days can be provided as a range of days or a specific date.'
                },
                {
                    metric: 'Device',
                    definition: 'Device should be either mobile or desktop.'
                },
                {
                    metric: 'Ad Country',
                    definition: 'The country (market demographic) ads were served for.'
                },
                {
                    metric: 'Impact Score',
                    definition: 'Calculated score to provide quick insight into which traffic slice would have the greatest impact on a metric such as PTQS at an aggregated level. Possible values: low, medium & high.'
                },
                {
                    metric: 'PTQS',
                    definition: 'Quality score generated by Bing.'
                },
            ]
        },
    },
    {
        reportName: 'Daily & Hourly Estimated Report',
        reportNotes: 'Note: These two reports provide the same data. Hourly is available intraday by hour and Daily provides a rollup of the hourly data for the last 7 days. All data is estimated and you may see some discrepancies with the Segment Report data.',
        metricsOverview: {
            headers: ['Metric', 'Definition'],
            records: [
                {
                    metric: 'Date',
                    definition: 'The number of days can be provided as a range of days or a specific date.'
                },
                {
                    metric: 'Hour (in Hourly Report)',
                    definition: 'Metrics are reported by GMT hour.'
                },
                {
                    metric: 'Device',
                    definition: 'Device should be either mobile or desktop.'
                },
                {
                    metric: 'Country',
                    definition: 'The country of the user as detected by Bing.'
                },
                {
                    metric: 'Est Ad Requested Page Views',
                    definition: 'Number of requests received by Bing.'
                },
                {
                    metric: 'Est Paid Clicks',
                    definition: 'Number of ad clicks received during the specified reporting period.'
                },
                {
                    metric: 'Est Ad Impressions',
                    definition: 'Total number of ads that Bing returned during the reporting period.'
                },
                {
                    metric: 'Est Impressions',
                    definition: 'Number of page views that received at least one ad during the specified reporting period.'
                },
                {
                    metric: 'Est Gross Revenue',
                    definition: 'Your estimated revenue before rev share is deducted.'
                },
                {
                    metric: 'Internal Forward Requests',
                    definition: 'Number of requests calculated by System1.'
                },
                {
                    metric: 'Est Ad Depth',
                    definition: 'Average number of ads per page view that received at least one ad (Ad Impressions/Impressions).'
                },
                {
                    metric: 'Est RPC', 
                    definition: 'Average revenue per click (Gross Revenue/Paid Clicks).' 
                },
                {
                    metric: 'Est PCTR', 
                    definition: 'Percentage of ad clicks per (Clicks/Impressions).' 
                },
            ]
        },
    },
  ]

  constructor(
    public columnClassHelper: ColumnClassHelper
  ) {}
}
