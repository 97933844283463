import { Injectable } from '@angular/core';
import { Role } from './models/role';
import { ResourcePermissions } from './models/resource-permissions';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { ConfigService } from '../../core/config.service';
import { ImpersonationService } from '../../features/impersonation/impersonation.service';
import { Observable, ReplaySubject } from 'rxjs';
import { SubjectManager } from '../../core/subject-manager';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  readonly ALWAYS_ALLOW = 'ALWAYS_ALLOW';
  private _roles: Role[];
  private _resourcePermissions: ResourcePermissions[];
  private _subjectManager = new SubjectManager();
  validAccess$: Observable<boolean>;
  private _resourcePermissionsSubject = new ReplaySubject<ResourcePermissions[]>(1);
  resourcePermissions$ = this._resourcePermissionsSubject.asObservable();

  constructor(
    private _ovs: ObjectValidatorService,
    private _config: ConfigService,
    private _impersonationService: ImpersonationService
  ) {
    this._subjectManager.register('validAccess');
    this.validAccess$ = this._subjectManager.getObservable('validAccess');
  }

  setRoles(roles: Role[]) {
    if (this._impersonationService.isProxyRolesDefined) {
      const proxyRoles = this._impersonationService.proxyRoles;
      if (this._ovs.isDefined(proxyRoles)) {
        roles = proxyRoles;
      }
    }

    if (roles === null) {
      return;
    }

    this._roles = roles;
    this.getResourcePermissions();
    this._subjectManager.next('validAccess', true);
  }

  async userHasWriteAccessBySelector(selector: string): Promise<boolean> {
    return !this._ovs.isNullOrEmpty(this._resourcePermissions)
      ? this._resourcePermissions.findIndex(
        x => x.selector === selector && this._hasWriteAccess(x)
      ) > -1
      : Promise.resolve(false);
  }

  async userHasWriteAccessByResourceName(name: string): Promise<boolean> {
    return !this._ovs.isNullOrEmpty(this._resourcePermissions)
      ? this._resourcePermissions.findIndex(x => x.name === name && this._hasWriteAccess(x)) > -1
      : Promise.resolve(false);
  }

  canAccessBySelector(selector: string): boolean {
    return !this._ovs.isNullOrEmpty(this._resourcePermissions)
      ? selector === this.ALWAYS_ALLOW ||
      this._resourcePermissions.findIndex(
        x => x.selector === selector && x.applicationId === this._config.getApplicationId()
      ) > -1
      : false;
  }

  canAccessByResource(name: string): boolean {
    return this.canAccessByResourceName(name) || this.canAccessBySelector(name);
  }

  canAccessByResourceName(name: string): boolean {
    return !this._ovs.isNullOrEmpty(this._resourcePermissions)
      && this._resourcePermissions.findIndex(x => x.name === name && x.applicationId === this._config.getApplicationId()) > -1;
  }

  getResourcePermissions() {
    this._resourcePermissions = [];
    this._roles
      .filter(role => !this._ovs.isNullOrEmpty(role.permissions))
      .map(role => {
        role.permissions
          .filter(permission => !this._ovs.isNullOrEmpty(permission.resources))
          .map(permission => {
            permission.resources.map(resource => {
              this._resourcePermissions.push(
                new ResourcePermissions(
                  resource.id,
                  resource.name,
                  resource.selector,
                  resource.applicationId,
                  permission.accessLevel
                )
              );
            });
          });
      });
    if (!this._ovs.isNullOrEmpty(this._config.getApplicationId())) {
      this._resourcePermissions = this._resourcePermissions.filter(
        resource => resource.applicationId === this._config.getApplicationId()
      );
    }
    this._resourcePermissionsSubject.next(this._resourcePermissions);
  }

  private _hasWriteAccess(permission: ResourcePermissions): boolean {
    return permission.accessLevel.toLowerCase() === 'write';
  }
}
