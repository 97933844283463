import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpersonationComponent } from './impersonation.component';
import { AngularMaterialModule } from '../../core/angular-material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImpersonationStatusComponent } from './impersonation-status/impersonation-status.component';
import { SearchableSelectControlModule } from '../../form/searchable-select-control/searchable-select-control.module';
import { AccessControlModule } from '../../features/access-control/access-control.module';

@NgModule({
  declarations: [ImpersonationComponent, ImpersonationStatusComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    SearchableSelectControlModule,
    AccessControlModule
  ],
  exports: [ImpersonationComponent, ImpersonationStatusComponent]
})
export class ImpersonationModule {}
