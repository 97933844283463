import { Component } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { CommonModule } from '@angular/common';
import { ColumnClassHelper } from '../shared/columnClassHelper';
import { CallbackRunnerModule } from 'core-global-frontend-callback-runner';

@Component({
  selector: 'app-conversion-tracking-parameters',
  templateUrl: './conversion-tracking-parameters.component.html',
  styleUrl: './conversion-tracking-parameters.component.scss',
  standalone: true,
  imports: [CommonModule, CallbackRunnerModule],
})
export class ConversionTrackingParametersComponent {
  constructor(
    public ovs: ObjectValidatorService,
    public columnClassHelper: ColumnClassHelper,
  ) {}

  buysideNetworks = [
    `Google`,
    `Facebook`,
    `Taboola`,
    `Outbrain`,
    `TikTok`,
    `Snapchat`,
    `Zemanta`,
  ];

  tables = [
    {
      title: 'Standard Tracking Parameters',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Tracking Parameters',
          records: [
            {
              parameter: 'impression_track_url',
              description:
                'URL to fire when the user reaches the System1 ' +
                'content article lander',
              example:
                'impression_track_url=https%3A%2F%2' +
                'Fwww.trackingsite.com%2Fid%2F1234',
            },
            {
              parameter: 'search_track_url',
              description: 'URL to fire when a user executes a search',
              example:
                'search_track_url=https%3A%2F%2Fwww.trackingsite.com' +
                '%2Fid%2F1234%3Fquery%3DOMKEYWORD',
            },
            {
              parameter: 'click_track_url',
              description:
                'URL to fire when a user monetizes with an advertiser',
              example:
                'click_track_url=https%3A%2F%2Fwww.trackingsite.com' +
                '%2Fid%2F1234%3Fstate%3Dclick',
            },
          ],
        },
      ],
    },
    {
      title: 'Google',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Google Parameters',
          records: [
            {
              parameter: 'gamid',
              description:
                'Google Ads Measurement ID for your account. ' +
                'The property ID may represent measurement for different ' +
                'Google products, including a Universal Analytics property ' +
                '("UA-XXXXXXXX"), a Google Analytics 4 property ' +
                '("G-XXXXXXXX"), Google Ads ("AW-XXXXXXXX") or ' +
                'Floodlight ("DC-XXXXXXXX")',
              example: 'gamid=AW-123456789',
            },
            {
              parameter: 'glcid',
              description:
                'Conversion ID associated with the Google Ads conversion ' +
                'you would like to fire on the lander',
              example: 'glcid=AW-123456789/AbC-D_efG-h 12_34-567',
            },
            {
              parameter: 'ga4mid',
              description:
                'Optional - an additional Property ID to measure page views and events',
              example: 'ga4mid=G-123456789',
            },
          ],
        },
      ],
    },
    {
      title: 'Facebook',
      notes: [
        `The parameters marked with an asterisk (*) are only available
    to partners that have worked with System1 to set up server-to-server
    tokens.`,
        `Without tokens, only impression tracking (fbland) is available on
    Facebook.`,
        `If you would like to configure server-to-server tokens, please reach
    out to your account manager.`,
      ],
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Facebook Parameters',
          records: [
            {
              parameter: 'fbid',
              description: 'ID of the Facebook pixel',
              example: 'fbid=1234567890',
            },
            {
              parameter: 'fbland',
              description:
                'Name of the conversion event to fire when the user reaches ' +
                'the System1 campaign lander',
              example: 'fbland=PageView',
            },
            {
              parameter: 'fbserp*',
              description:
                'Name of the conversion event to fire when a user executes ' +
                'a search',
              example: 'fbserp=Add+To+Wishlist',
            },
            {
              parameter: 'fbclick*',
              description:
                'Name of the conversion event to fire when a ' +
                'user monetizes with an advertiser',
              example: 'fbclick=Purchase',
            },
            {
              parameter: 'fbclid*',
              description: 'Facebook click ID assigned to the user',
              example: 'fbclid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'fbtest*',
              description:
                'The test_event_code linked to this pixel, for the purpose of ' +
                'testing server events',
              example: 'fbtest=TEST1234',
            },
          ],
        },
      ],
    },
    {
      title: 'Taboola',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Taboola Parameters',
          records: [
            {
              parameter: 'tbclickid',
              required: true,
              description:
                'Taboola click ID assigned to the user. ' +
                'This is available on the Taboola platform as the macro {click_id}',
              example: 'tbclickid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'tbid',
              description:
                'Taboola click ID assigned to the user. This is available on the Taboola platform as the macro {click_id} (server-to-server)',
              example: 'tbid=1234556748',
            },
            {
              parameter: 'tbland',
              description:
                'Name of the conversion event to fire when the user reaches the ' +
                'System1 campaign lander',
              example: 'tbland=PageView',
            },
            {
              parameter: 'tbserp',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'tbserp=Add+To+Wishlist',
            },
            {
              parameter: 'tbclick',
              description:
                'Name of the conversion event to fire when a user monetizes ' +
                'with an advertiser',
              example: 'tbclick=Purchase',
            },
          ],
        },
      ],
    },
    {
      title: 'Outbrain',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Outbrain Parameters',
          records: [
            {
              parameter: 'obclickid',
              required: true,
              description:
                'Outbrain click ID assigned to the user. ' +
                'This is available on the Outbrain platform as the macro ' +
                '{{ob_click_id}}',
              example: 'obclickid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'obland',
              description:
                'Name of the conversion event to fire when the user ' +
                'reaches the System1 campaign lander',
              example: 'obland=PageView',
            },
            {
              parameter: 'obserp',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'obserp=Add+To+Wishlist',
            },
            {
              parameter: 'obclick',
              description:
                'Name of the conversion event to fire when a user monetizes ' +
                'with an advertiser',
              example: 'obclick=Purchase',
            },
          ],
        },
      ],
    },
    {
      title: 'TikTok',
      notes: [
        `The parameters marked with an asterisk (*) are only available to
    partners that have worked with System1 to set up server-to-server tokens.`,
        `Without tokens, only impression tracking (ttland) is available on TikTok.`,
        `If you would like to configure server-to-server tokens, please reach out
    to System1.`,
      ],
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'TikTok Parameters',
          records: [
            {
              parameter: 'ttid',
              description: 'ID of the TikTok pixel',
              example: 'ttid=1234567890',
            },
            {
              parameter: 'ttland',
              description:
                'Name of the conversion event to fire when ' +
                'the user reaches the System1 campaign lander',
              example: 'ttland=PageView',
            },
            {
              parameter: 'ttserp*',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'ttserp=Add+To+Wishlist',
            },
            {
              parameter: 'ttclick*',
              description:
                'Name of the conversion event to fire when a user ' +
                'monetizes with an advertiser',
              example: 'ttclick=Purchase',
            },
            {
              parameter: 'ttclid*',
              description:
                'TikTok click ID assigned to the user. This is available on ' +
                'the TikTok platform as the macro __CLICKID__',
              example: 'ttclid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'tttest*',
              description:
                'The test_event_code linked to this pixel, ' +
                'for the purpose of testing server events',
              example: 'tttest=TEST1234',
            },
          ],
        },
      ],
    },
    {
      title: 'Snapchat',
      notes: [
        `The parameters marked with an asterisk (*) are only available to
    partners that have worked with System1 to set up server-to-server tokens.`,
        `Without tokens, only impression tracking (scland) is available on Snapchat.`,
        `If you would like to configure server-to-server tokens,
    please reach out to your account manager.`,
      ],
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Snapchat Parameters',
          records: [
            {
              parameter: 'scid',
              description: 'ID of the Snapchat pixel',
              example: 'scid=f5932083-b4da-436e-b63c-94b659dde332',
            },
            {
              parameter: 'scland',
              description:
                'Name of the conversion event to fire when the user reaches the ' +
                'System1 campaign lander',
              example: 'scland=VIEW_CONTENT',
            },
            {
              parameter: 'scserp*',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'scserp=SEARCH',
            },
            {
              parameter: 'scclick*',
              description:
                'Name of the conversion event to fire when a ' +
                'user monetizes with an advertiser',
              example: 'scclick=PURCHASE',
            },
          ],
        },
      ],
    },
    {
      title: 'Zemanta',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Zemanta Parameters',
          records: [
            {
              parameter: 'zmid',
              description: 'Zemanta tag ID (fka account ID)',
              example: 'zmid=12345',
            },
            {
              parameter: 'zmclickid',
              description:
                'Zemanta click ID. Use the {postbackid} macro in Zemanta to ' +
                'add this param to the campaign url',
              example: 'zmclickid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'zmland',
              description:
                'Name of the conversion event to fire when the user reaches the ' +
                'System1 campaign lander',
              example: 'zmland=PageView',
            },
            {
              parameter: 'zmserp',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'zmserp=Add+To+Wishlist',
            },
            {
              parameter: 'zmclick',
              description:
                'Name of the conversion event to fire when a user ' +
                'monetizes with an advertiser',
              example: 'zmclick=Purchase',
            },
          ],
        },
      ],
    },
  ];
}
