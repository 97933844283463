<div *ngIf="impersonationService.isProxyRolesDefined" class="impersonation-status-bar">
  <div>
    Impersonating User:
    <b class="impersonation-status-bar__text">{{ impersonationDisplayText }}</b>
  </div>
  <div>
    Role: <b class="impersonation-status-bar__text">{{ impersonationService.proxyRoleName }}</b>
  </div>
  <a
    class="user-area__btn impersonation-status-bar-btn"
    href="javascript:void(0)"
    (click)="exitImpersonation()"
    >Exit</a
  >
</div>
