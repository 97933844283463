import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SubjectManager } from '../../../core/subject-manager';

@Injectable({
  providedIn: 'root'
})
export class UIBlockerService {
  isBlocked$: Observable<boolean>;
  private _subjectManager = new SubjectManager();

  constructor() {
    this._subjectManager.register('blocked');
    this.isBlocked$ = this._subjectManager.getObservable('blocked');
    this.unblocked();
  }

  blocked() {
    this._subjectManager.next('blocked', true);
  }

  unblocked() {
    this._subjectManager.next('blocked', false);
  }
}
