import { NgModule } from '@angular/core';
import { TimePickerComponent } from './time-picker.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [TimePickerComponent],
  imports: [CommonModule, MatInputModule, MatSelectModule, FormsModule, ReactiveFormsModule],
  exports: [TimePickerComponent]
})
export class TimePickerModule {}
