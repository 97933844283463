import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, forkJoin, from } from 'rxjs';
import { DomainModel, DomainResponseModel } from '../../models/domain.model';
import { DomainManagerPrefilterModel } from '../../models/domain-manager-prefilter.model';
import { DomainListWebService } from './domain-list-web.service';
import { DomainStatusEnum } from '../../models/domain-status.enum';
import { filter } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
  toArray,
  withLatestFrom,
} from 'rxjs/operators';
import { PaginatedDomainModel } from '../../models/paginated-domain.model';
import { Partner } from '../../../../shared/models/partner.model';
import { PartnerCacheService } from '../../../../shared/services/partner-cache.service';
import { SubjectManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { Sort } from '@angular/material/sort';
import { StatusMapperService } from './status-mapper.service';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';

@Injectable({ providedIn: 'root' })
export class DomainListCacheService {
  private _subjectManager = new SubjectManager();
  private _updatedAt: Date;
  private readonly _isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoadingSubject.asObservable();
  domains: DomainResponseModel[] = [];
  domainManagerList: DomainModel[] = [];
  private get domainManagerList$(): Observable<DomainModel[]> {
    return this._subjectManager.get('domainManagerList');
  }
  readonly _filteredDomainsSubject = new BehaviorSubject<
    DomainModel[] | undefined
  >(undefined);
  filteredDomains$ = this._filteredDomainsSubject.asObservable().pipe(
    startWith([]),
    filter(domains => domains !== undefined),
    map(domains =>
      domains.filter(
        domain =>
          domain.status !==
          DomainStatusEnum.Disabled.toString(),
      ),
    ),
  );
  pageSize: number = 100;
  selectedDomainModelFilters: DomainManagerPrefilterModel =
    this.getDomainManagerPrefilterModel(this.pageSize);
  totalDomainRecordPages: number;

  get isLoaded(): boolean {
    return !this._ovs.isNullOrEmpty(this.domains);
  }

  get updatedAt(): Date {
    return this._updatedAt;
  }

  constructor(
    private _ovs: ObjectValidatorService,
    private _domainListWebService: DomainListWebService,
    private _partnerCacheService: PartnerCacheService,
    private _statusMapperService: StatusMapperService,
    private _snackbarService: SnackbarService
  ) {
    this._subjectManager.registerMultiple([
      'partnerList',
      'domainManagerList',
    ]);
  }

  updateDomainList(domainId: number, dictionary: object) {
    const domain = this.domainManagerList.find(x => x.id === domainId);
    if (!this._ovs.isNullOrEmpty(domain)) {
      Object.keys(dictionary).forEach(key => {
        domain[key] = dictionary[key];
      });
    }
  }

  load(
    forceRefresh: boolean = false,
    pageNumber: number = 0,
    pageSize: number = 20,
    sort: Sort = { active: "createdAt", direction: "desc" },
    filter: DomainManagerPrefilterModel = { domainLookup: "", statuses: ["Failed", "Updating", "Pending", "Disabled", "Success"], pageSize: 20 }
  ): Observable<PaginatedDomainModel[]> {
    if (
      !forceRefresh &&
      this._ovs.isDefined(this._updatedAt) &&
      new Date(this._updatedAt.getTime() + 3600000) > new Date(Date.now())
    ) {
      return of(null);
    }
    this._isLoadingSubject.next(true);
    this.pageSize = pageSize;
    return this._partnerCacheService.partners$.pipe(
      distinctUntilChanged(),
      switchMap(partners => this._getDomainList(this.pageSize, partners, pageNumber + 1, this._mapSortColumnName(sort), this._statusMapperService.mapStatuses(filter))),
      tap(() => this._isLoadingSubject.next(false))
    );
  }

  loadAll(
    sort: Sort = { active: "createdAt", direction: "desc" },
    filter: DomainManagerPrefilterModel = { domainLookup: "", statuses: ["Failed", "Updating", "Pending", "Disabled", "Success"], pageSize: 20 }
  ): Observable<DomainModel[]> {
    return this._partnerCacheService.partners$.pipe(
      distinctUntilChanged(),
      switchMap(partners => this._getAllDomainsList(partners, this._mapSortColumnName(sort), this._statusMapperService.mapStatuses(filter))),
    );
  }

  private _getDomainList(
    pageSize: number,
    partners: Partner[],
    pageNumber: number,
    sort: Sort,
    filter: DomainManagerPrefilterModel
  ): Observable<PaginatedDomainModel[]> {
    return forkJoin(
      this._domainListWebService.getDomains(partners, pageNumber, pageSize, sort, filter),
    ).pipe(
      tap(() => (this._updatedAt = new Date(Date.now()))),
      tap(response => {
        this.domainManagerList = response[0].parkedDomains.map(
          domain =>
            new DomainModel(
              domain.partnerId,
              partners.find(partner => partner.id === domain.partnerId)?.name,
              domain.id,
              this._statusMapperService.setParkedDomainStatus(domain.status),
              domain.parkedDomain,
              this._getDomainTargetUrl(JSON.parse(domain.urlParameters || null)),
              domain.createdAt,
              domain.updatedAt,
              domain.keywordRecommendationMethod,
              domain.facebookDomainVerificationCode,
              domain.euDomain,
              domain.urlParameters,
              domain.staticKeywords,
            )
        );
        this._subjectManager.next('domainManagerList', this.domainManagerList);
        this._filteredDomainsSubject.next(this.domainManagerList);
      }),
    );
  }

  private _getAllDomainsList(
    partners: Partner[],
    sort: Sort,
    filter: DomainManagerPrefilterModel
  ): Observable<DomainModel[]> {
    return forkJoin(
      this._fetchAllPages(partners, sort, filter)
    ).pipe(
      map(response => response[0].map(
        domain => {
          let domainTargetUrl;
          try {
            domainTargetUrl = this._getDomainTargetUrl(JSON.parse(domain.urlParameters || null));
          }
          catch {
            domainTargetUrl = {};
          }
          return new DomainModel(
            domain.partnerId,
            partners.find(partner => partner.id === domain.partnerId)?.name,
            domain.id,
            this._statusMapperService.setParkedDomainStatus(domain.status),
            domain.parkedDomain,
            domainTargetUrl,
            domain.createdAt,
            domain.updatedAt,
            domain.keywordRecommendationMethod,
            domain.facebookDomainVerificationCode,
            domain.euDomain,
            domain.urlParameters,
            domain.staticKeywords,
          )
        })
      ),
    );
  }

  private _fetchAllPages(partners: Partner[], sort: Sort, filter: DomainManagerPrefilterModel): Observable<DomainResponseModel[]> {
    let erroredOut = false;
    return this._domainListWebService.getDomains(partners, 1, 100, sort, filter).pipe(
      tap(() => this._snackbarService.open("Your report is downloading. You will be notified when it is ready.", SnackBarType.warn)),
      concatMap(initialResponse =>
        from(
          Array.from({ length: initialResponse.pages }, (_, i) => i + 1)
            .map(page => this._domainListWebService.getDomains(partners, page, 100, sort, filter)))
          .pipe(
            concatMap(obs => obs),
            catchError(err => {
              if (!erroredOut) {
                this._snackbarService.open('Some domains are missing from the results, we are currently working on it.', SnackBarType.warn, 10000)
              }
              erroredOut = true;
              return of(new PaginatedDomainModel([], 0));
            }),
            toArray(),
            map(responses => responses.flatMap(response => response.parkedDomains)),
          )
      ),
      tap(() => this._snackbarService.open("Your report is finished downloading.", SnackBarType.done)),
    );
  }

  filter(
    domains: DomainModel[],
    filters: DomainManagerPrefilterModel,
  ): DomainModel[] {
    const list = domains.filter(
      domain =>
        domain.domainName.toLocaleLowerCase().includes(filters.domainLookup) &&
        (this._ovs.isNullOrEmpty(filters.statuses) ||
          filters.statuses.includes(domain.status)),
    );
    this.pageSize = filters.pageSize;
    return list.sort(
      (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt),
    );
  }

  getDomainBy(domainId: number | undefined): Observable<DomainModel> {
    if (this._ovs.isNullOrEmpty(this.domainManagerList)) {
      return this.load(true).pipe(
        withLatestFrom(this.domainManagerList$),
        map(([_, domains]) => domains),
        map(domains => domains.find(domain => domain.id === domainId)),
      );
    } else {
      return of(this.domainManagerList.find(domain => domain.id === domainId));
    }
  }

  getPartnerIdByDomainId(domainId: number): Observable<number> {
    return this.getDomainBy(domainId).pipe(map(result => result.partnerId));
  }

  getDomainManagerPrefilterModel(pageSize = 25) {
    return new DomainManagerPrefilterModel(
      '',
      [
        DomainStatusEnum[DomainStatusEnum.Failed].toString(),
        DomainStatusEnum[DomainStatusEnum.Updating].toString(),
        DomainStatusEnum[DomainStatusEnum.Pending].toString(),
        DomainStatusEnum[DomainStatusEnum.Disabled].toString(),
        DomainStatusEnum[DomainStatusEnum.Success].toString(),
      ],
      pageSize,
    );
  }

  private _getDomainTargetUrl(urlParameters: any) {
    try {
      return this._ovs.isNullOrEmpty(urlParameters) ||
        urlParameters === '{"Querystring":"","FormSchema":""}'
        ? ''
        : `https://${urlParameters.Querystring}`;
    } catch (exception) {
      return '';
    }
  }

  private _mapSortColumnName(sort: Sort) {
    switch (sort.active) {
      case "domainName":
        sort.active = "parked_domain";
        break;
      case "createdAt":
        sort.active = "created_at";
        break;
      case "updatedAt":
        sort.active = "updated_at";
        break;
      case "partnerName":
        sort.active = "partner_id";
        break;
    }
    return sort;
  }
}
