<div class="documentation tab-content">
  <div>
    System1 supports adding conversion tracking pixels to your landing pages. By specifying the ad
    network pixel ID and event you’d like to track via our URL parameters, we will embed your pixel
    conversion pixel code on your campaigns.
  </div>
  <br />
  <div>
    You can use these parameters to track conversions when users land or execute a search for
    advertisers on our campaigns.
  </div>
  <br />
  <div>
    The following ad networks are supported as of this release:
    <ul>
      <li *ngFor="let network of adNetworks">{{ network }}</li>
    </ul>
  </div>
  <div>
    You can verify that the pixels are firing on your campaigns via the ad networks’ browser
    extension tools. Chrome Store Links:
    <ul>
      <li *ngFor="let pixel of pixels">
        <a class="documentation-link" target="_blank" [href]="pixel.link">
          <span class="documentation-link-text">{{ pixel.name }}</span>
        </a>
      </li>
    </ul>
  </div>
  <br />
  <h3 class="documentation-sub-header">Parameters</h3>
  <div>
    You must specify the <span class="documentation bold">id</span> and conversion name in order to
    fire your pixel event(s). You can specify any number of supported networks.
  </div>
  <div class="documentation-grid-container">
    <div *ngFor="let header of table.headers; index as i" [class]="getColumnHeaderClass(table, i)">
      <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let element of table.data">
      <div class="documentation-grid section-header">
        <span class="documentation bold">{{ element.header }}</span>
      </div>
      <div
        class="documentation-grid section-header section-header sub-header"
        *ngIf="!ovs.isNullOrEmpty(element.subHeader)">
        <span>{{ element.subHeader }}</span>
      </div>
      <ng-container *ngFor="let record of element.records">
        <div [class]="getColumnValueClass(0, table)">
          <span class="documentation bold">{{ record.parameter }}</span>
        </div>
        <div [class]="getColumnValueClass(1, table)">
          <span>{{ record.description }}</span>
        </div>
        <div [class]="getColumnValueClass(2, table)">
          <span>
            <span class="documentation bold">{{ record.parameter }}</span
            >{{ record.example }}
          </span>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
