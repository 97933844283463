import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommunalModule } from '../../../communal.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormTypeComponent } from './form-type.component';
import { MatCommonModule } from '@angular/material/core';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { AngularMaterialModule } from '../../../core/angular-material.module';
import { CallbackRunnerModule } from '../../../core/callback-runner/callback-runner.module';
import { ContextModule } from '../../../features/context/context.module';
import { TopOptionsTogglerModule } from '../../../features/top-options-toggler/top-options-toggler.module';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    CommunalModule,
    TopOptionsTogglerModule,
    ContextModule,
    FormsModule,
    ReactiveFormsModule,
    MatCommonModule,
    NgJsonEditorModule,
    AngularMaterialModule,
    CallbackRunnerModule,
    FormlyModule.forRoot({
      types: [{ name: 'json-form-viewer', component: FormTypeComponent }]
    }),
    FormlyMaterialModule
  ],
  declarations: [FormTypeComponent]
})
export class FormTypeModule {}
