// Angular
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { AccessControlModule, LayoutModule } from '@nimbus/shared-lib';
import { ButtonComponent } from './types/button/button.component';
import { CopyService } from './services/copy.service';
import { RampjsScriptBuilderComponent } from './rampjs-builder/rampjs-builder.component';
import { QGridModule } from '@nimbus/grid';
import { RampjsRoutingModule } from './rampjs-routing.module';
import { CallbackRunnerModule } from 'core-global-frontend-callback-runner';

@NgModule({
  declarations: [ButtonComponent, RampjsScriptBuilderComponent],
  imports: [
    AccessControlModule,
    BrowserModule,
    CallbackRunnerModule,
    CommonModule,
    FormlyModule.forRoot({
      types: [{ name: 'button', component: ButtonComponent }],
    }),
    FormlyMaterialModule,
    LayoutModule,
    QGridModule,
    ReactiveFormsModule,
    RampjsRoutingModule,
    RouterModule,
  ],
  providers: [CopyService],
})
export class RampjsModule {}
