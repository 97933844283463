<div class="documentation tab-content">
  <div>
    The purpose of this checklist is to be a quick reference for high level requirements and not
    intended to be an exhaustive list.
  </div>
  <br />
  <h3 class="documentation-sub-header">Google AFD</h3>
  <div>
    AFD guidelines fall under the following categories:
    <ul>
      <li *ngFor="let guideline of afdGuidelines">{{ guideline }}</li>
    </ul>
  </div>
  <h3 class="documentation-sub-header">Headlines, CTAs, and Images</h3>
  <div>
    <ul>
      <li>
        There must be representation on the SERP.
        <ul>
          <li>
            Ads for the product must exist, including any major modifiers. (senior, signs/symptoms,
            high-limit credit card, etc.)
          </li>
        </ul>
      </li>
      <li>
        Do not imply a more-than-ads experience. Avoid:
        <ul>
          <li><span class="documentation-highlighted">Find out how to</span> redo your bathroom</li>
          <li><span class="documentation-highlighted">What are</span> breast cancer symptoms?</li>
          <li><span class="documentation-highlighted">Learn how</span> you can remove mold</li>
          <li><span class="documentation-highlighted">How do</span> I choose a new SUV?</li>
          <li><span class="documentation-highlighted">Calculate</span> your credit score</li>
          <li><span class="documentation-highlighted">Application/apply for</span></li>
          <li>
            <span class="documentation-highlighted">Medicare changes/Changes to medicare</span>
          </li>
        </ul>
      </li>
      <li>
        Do not imply a curated list or specific item/piece of info.
        <ul>
          <li>
            See <span class="documentation-highlighted">the</span> work from home jobs in
            &#123;city&#125;
          </li>
          <li>
            Check out <span class="documentation-highlighted">these</span> AC repair services near
            you
          </li>
          <li>
            Interested in a masters degree? You should see
            <span class="documentation-highlighted">this!</span>
          </li>
          <li>
            <span class="documentation-highlighted">_______ that you might be interested in</span>
            (can switch it around to say
            <span class="documentation-highlighted background"
              >You might be interested in ______</span
            >)
          </li>
          <li>
            Getting a visa might have never been
            <span class="documentation-highlighted">this</span> easy
          </li>
        </ul>
      </li>
      <li>
        Do not use definitive statements (replace with
        <span class="documentation-highlighted background">may</span> or
        <span class="documentation-highlighted background">might</span> to make definitive
        statements compliant).
        <ul>
          <li>Prices <span class="documentation-highlighted">will</span> surprise you</li>
          <li>
            <span class="documentation-highlighted">Here's what</span> dental implants cost (can use
            <span class="documentation-highlighted background">see what</span> instead)
          </li>
          <li>
            People in (city) <span class="documentation-highlighted">are</span> looking for air
            ambulance services
          </li>
        </ul>
      </li>
      <li>
        Do not ask a question that the user won’t get an answer to in the ads. Can ask questions
        about the user’s own experience/thoughts.
        <ul>
          <li>
            <span class="documentation-highlighted background"
              >How much does a dental implant cost?</span
            >
          </li>
          <li><span class="documentation-highlighted background">Looking for a new SUV?</span></li>
          <li>
            Avoid:
            <span class="documentation-highlighted">Can I clean my dog with baby wipes?</span>
          </li>
        </ul>
      </li>
      <li>
        Do not use “senior” unless the product specifically has options for seniors.
        <ul>
          <li>
            Can use:
            <span class="documentation-highlighted background">Senior cell phones/plans</span>,
            <span class="documentation-highlighted background">senior cars</span>
          </li>
          <li>
            Avoid: <span class="documentation-highlighted">Senior Medical Debt Programs</span>
          </li>
        </ul>
      </li>
      <li>
        Do not imply a specific value to a product.
        <ul>
          <li>
            Dental implants <span class="documentation-highlighted">should</span> cost this much
          </li>
          <li>
            Interested in a masters degree? You
            <span class="documentation-highlighted">should</span> see this!
          </li>
        </ul>
      </li>
      <li>
        Do not use superlatives.
        <ul>
          <li>
            Avoid:
            <span class="documentation-highlighted"
              >Best, top, leading, elite, cheapest, lowest, most ______</span
            >
          </li>
        </ul>
      </li>
      <li>
        Do not use <span class="documentation-highlighted">search</span> except in
        <span class="documentation-highlighted background">search ads</span>. Do not use
        <span class="documentation-highlighted">research</span> except in
        <span class="documentation-highlighted background">research options/offers</span>.
      </li>
      <li>
        Do not imply customization for the user.
        <ul>
          <li>
            Special Life Insurance Plans <span class="documentation-highlighted">For You</span>
          </li>
        </ul>
      </li>
      <li>
        Do not use language that implies timeliness or urgency.
        <ul>
          <li>2020 SUVs <span class="documentation-highlighted">have just arrived</span></li>
          <li>Mold removal remedies <span class="documentation-highlighted">are here</span></li>
          <li><span class="documentation-highlighted">Cutting edge</span> breast implants</li>
          <li>Walk-in tub offers <span class="documentation-highlighted">won't last</span></li>
          <li><span class="documentation-highlighted">Hurry, Act Now, Today Only</span></li>
        </ul>
      </li>
      <li>
        Do not imply personal reviews about products.
        <ul>
          <li><span class="documentation-highlighted">My</span> favorite thing about _________</li>
        </ul>
      </li>
      <li>
        Do not use aggressive or fear-mongering language/themes in ad copies or images, including
        Covid fear-mongering.
        <ul>
          <li><span class="documentation-highlighted">Critical warnings</span></li>
          <li><span class="documentation-highlighted">Prepare for Post-Corona Economy</span></li>
          <li><span class="documentation-highlighted">...a matter of life and death</span></li>
        </ul>
      </li>
      <li>
        Do not imply there has been new research or successful outcomes in medical verticals.
        <ul>
          <li><span class="documentation-highlighted">New</span> diabetes treatments</li>
          <li><span class="documentation-highlighted">The latest</span> in psoriasis treatment</li>
          <li><span class="documentation-highlighted">Good</span> treatments for diabetes</li>
        </ul>
      </li>
      <li>
        Do not use “advanced” and “early” in the same medical vertical headline, as they are
        contradictory.
        <ul>
          <li>
            <span class="documentation-highlighted"
              >See early symptoms for advanced breast cancer.</span
            >
          </li>
        </ul>
      </li>
      <li>
        Do not direct the user to interact with the ad, whether in the ad copy or in surrounding
        content text.
        <ul>
          <li>
            <span class="documentation-highlighted"
              >Look here, Click here, Continue, Click below</span
            >
          </li>
          <li>
            <span class="documentation-highlighted">On the next page, select an option</span> to
            start your search today
          </li>
        </ul>
      </li>
      <li>
        Do not imply special pricing.
        <ul>
          <li><span class="documentation-highlighted">Deals</span> on new TVs</li>
          <li>See <span class="documentation-highlighted">discounted</span> RV motorhomes</li>
          <li>Cruise <span class="documentation-highlighted">promotions</span> near you</li>
          <li>SUV <span class="documentation-highlighted">specials</span> for Labor Day</li>
          <li>
            SUVs <span class="documentation-highlighted">on sale</span> now (<span
              class="documentation-highlighted background"
              >for sale</span
            >
            is compliant)
          </li>
        </ul>
      </li>
      <li>
        Do not imply a comparative experience.
        <ul>
          <li>
            Looking for a new car? <span class="documentation-highlighted">Compare</span> sedans and
            SUVs
          </li>
          <li>Chair lifts <span class="documentation-highlighted">vs</span> Home elevators</li>
          <li><span class="documentation-highlighted">Pros and Cons</span> of Reverse Mortgages</li>
        </ul>
      </li>
      <li>
        Do not use "<span class="documentation-highlighted">free</span>" unless it is a truly free
        product/experience. (If a user has to enter a credit card to get a "free" trial, it is not
        truly free.)
        <ul>
          <li>
            Compliant: <span class="documentation-highlighted background">free quotes</span>,
            <span class="documentation-highlighted background">free websites</span>,
            <span class="documentation-highlighted background">free dating apps</span>
          </li>
          <li>
            Not compliant:
            <span class="documentation-highlighted"
              >free government phone, free trucks, free credit check</span
            >
          </li>
        </ul>
      </li>
      <li>Do not use adult/sexual/obscene language. All wording must be PG.</li>
      <li>
        Do not imply a product is covered by medicare unless it truly is. Not covered:
        <ul>
          <li><span class="documentation-highlighted">mattresses</span></li>
          <li><span class="documentation-highlighted">hearing aids</span></li>
          <li><span class="documentation-highlighted">dental implants</span></li>
        </ul>
      </li>
      <li>
        Do not use <span class="documentation-highlighted">AARP</span> or
        <span class="documentation-highlighted">creditcards.com</span> in any ad copies.
      </li>
      <li>Compkeys must be included for pricing, years, and geographic locations.</li>
      <li>Any words in images must follow all the guidelines above.</li>
      <li>
        Images must not be misleading or clickbait, and must represent real options available to
        users.
        <ul>
          <li><span class="documentation-highlighted">Senior living “chateaus”</span></li>
          <li>
            <span class="documentation-highlighted">Concept cars</span> (cars that are not yet on
            the market)
          </li>
          <li>
            <span class="documentation-highlighted">Renderings of cars</span> (car images that are
            not clearly a real photo)
          </li>
          <li>
            <span class="documentation-highlighted">Futuristic gadgets that do not yet exist</span>
          </li>
        </ul>
      </li>
      <li>
        Images must be licensed for unlimited commercial use. A few websites where you can find
        these images are:
        <ul class="image-grid-container">
          <li *ngFor="let imageSite of imageSiteList">{{ imageSite }}</li>
        </ul>
      </li>
      <li>Images must not contain large logos/branding/brand names.</li>
      <li>Images must not contain celebrities.</li>
      <li>
        Images must not contain adult content/nudity.
        <ul>
          <li><span class="documentation-highlighted">Lingerie</span></li>
          <li><span class="documentation-highlighted">Revealing clothing/posing</span></li>
        </ul>
      </li>
      <li>
        Subjects in images must be properly dressed for the vertical. (i.e. swimsuits are allowed in
        beach vacation verticals, but not in business verticals, etc.)
      </li>
    </ul>
  </div>
  <h3 class="documentation-sub-header">Content Flows</h3>
  <div>
    <ul>
      <li>
        CTA blocks must include ads designation in branding text. Currently approved phrases are:
        <span class="documentation-highlighted background">Related Topics (Ads)</span>,
        <span class="documentation-highlighted background">Sponsored Listings</span>,
        <span class="documentation-highlighted background">Ads</span>.
        <ul>
          <li>
            Ads designation must be at top left corner and must be the same size/color/readability
            as the content piece font (or larger).
          </li>
        </ul>
      </li>
      <li>
        Do not place a CTA block directly under the content piece title. We recommend 75+ words
        above the first CTA block.
      </li>
      <li>CTAs must not take up more than 30% of the overall content.</li>
      <li>
        For mobile views, CTAs must not take up more than 60% of the screen at any given time. We
        use iPhone X (or any 800 pixel device) as the standard mobile screen size for reviewing.
      </li>
      <li>
        Upon loading the page on mobile view, CTAs must not take up more than 35% - 45% of the
        screen.
      </li>
      <li>
        CTAs must not be truncated or cut off in any way. (CTA buttons must be whole, and the
        wording must not be shortened with a “...” at the end.)
      </li>
      <li>
        CTA must not be directly below anything that refers to, or encourages interaction with, the
        CTA.
        <ul>
          <li><span class="documentation-highlighted">hanging colons</span></li>
          <li>
            <span class="documentation-highlighted">bold, italicized, or underlined words</span>
          </li>
          <li><span class="documentation-highlighted">headings or subheadings</span></li>
          <li>
            <span class="documentation-highlighted"
              >directional language (Look here, Click here, Continue…, Click below, etc.)</span
            >
          </li>
        </ul>
      </li>
      <li>
        Cite any and all information that is not widely known, either with direct links or clearly
        mentioned in the content so that a user could search for the source and find it in the first
        3-4 results. Citations can be in content, or below/next to content.
      </li>
      <li>
        Anything other than a 4 keyword CTA block (or a single CTA) needs explicit compliance
        approval before running.
      </li>
      <li>CTA font size should not be larger than the content font (can be smaller).</li>
      <li>
        CTA must not contain <span class="documentation-highlighted">arrows</span>.
        <ul>
          <li>
            Chevrons (<span class="documentation-highlighted background"><</span>,
            <span class="documentation-highlighted background">></span>) are allowed.
          </li>
        </ul>
      </li>
      <li>
        Images in articles must not dominate over content and must be relevant to the content piece.
      </li>
      <li>
        Content must add value to the user, and must contain more information than a user could get
        by simply visiting the advertiser's page.
      </li>
      <li>Content must support any claims in the title.</li>
      <li>Content must be original; no plagiarism.</li>
      <li>
        CTAs must stay within the same vertical and sub-vertical of the content piece.
        <ul>
          <li>
            <span class="documentation-highlighted"
              >Travel credit card CTAs in an article about cruise ships</span
            >
          </li>
          <li>
            <span class="documentation-highlighted"
              >Psychics CTAs in an article about Chinese New Year</span
            >
          </li>
          <li>
            <span class="documentation-highlighted background"
              >Truck CTAs in an article about Ford F-150 trucks</span
            >
          </li>
        </ul>
      </li>
      <li>
        Site must have 50+ articles. If not, please provide a content creation schedule for when it
        will reach 50.
      </li>
      <li>Site must have working navigation tabs (home, different topics, etc.).</li>
      <li>Must have working links to Privacy Policy, About Us, Contact Us, and Legal Terms.</li>
      <li>Any other links must work properly.</li>
      <li>Search functions (and any other functions) must work as expected.</li>
      <li>It’s highly recommended to include alternate monetization (adsense, taboola, etc).</li>
      <li>It’s highly recommended to include related articles in the sidebar.</li>
    </ul>
  </div>
  <br />
  <h3 class="documentation-sub-header">Traffic Quality</h3>
  <div>
    Please refer to the denylist provided by your Account Manager for the most up to date list of
    known problematic sources. Partners are responsible for any invalid activity stemming from
    upstream traffic sources, including but not
    <span class="documentation italic">limited</span> to all sources on the denylist.
  </div>
</div>
