import { Injectable } from '@angular/core';
import { LocalStorageKey } from './local-storage-key.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageKeyFactory {
  build(keyName: string, modelStructureIndicator: string): LocalStorageKey {
    return new LocalStorageKey(modelStructureIndicator, keyName);
  }
}
