import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaAuthGuard } from '@okta/okta-angular';
import { AccessControlGuardService } from '@nimbus/shared-lib';
import { AreaShellComponent } from 'core-global-frontend-common-ui';
import { UtilityService } from 'core-global-frontend-object-validator';
import { SiteMapNode } from 'core-global-frontend-navigation';
import { siteMap } from '../../../site-map';
import { DocumentationComponent } from './documentation/documentation.component';
import { componentsMap } from './components_map';

const utilityService = new UtilityService();
const segments = utilityService.flattenListNodes<SiteMapNode>(siteMap as any);
const segment = segments.find(segment => segment.path === 'docs' ?? false);
const defaultRoute =
  segment.siteMapNodes.find(siteMapNode => siteMapNode.defaultPage ?? false) ??
  segment.siteMapNodes[0];
@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: 'docs',
        component: AreaShellComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: defaultRoute.path.replace('docs/', ''),
          },
          ...segment.siteMapNodes.map(siteMapNode => ({
            component: DocumentationComponent,
            path: siteMapNode.path.replace('docs/', ''),
            children: [
              {
                path: '',
                canActivate: [OktaAuthGuard, AccessControlGuardService],
                component: componentsMap[siteMapNode.component],
                data: {
                  resourceName: siteMapNode.resource,
                },
              },
            ],
          })),
        ],
      },
    ] as Routes),
  ],
  exports: [RouterModule],
})
export class DocumentationRoutingModule {}
