import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';

@Component({
  selector: 'frontend-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
  standalone: true,
  imports: [MatIconModule, FormsModule, MatSelectModule],
})
export class DownloadButtonComponent {
  @Output() download = new EventEmitter<boolean>();
  readonly allPages = '-1';
  readonly currentPage = '0';
}
