import { Component, Input } from "@angular/core";
import { VideoInfo } from "./models";

@Component({
    selector: 'app-video-playlist',
    templateUrl: './video-playlist.component.html',
    styleUrls: ['./video-playlist.component.scss']
})
export class VideoPlaylistComponent {
    @Input() videos: VideoInfo[] = [];
}