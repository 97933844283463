<section class="documentation-section">
  <div class="documentation-left-bar"></div>
  <div class="documentation-container">
    <div class="documentation-sidebar">
      <div class="documentation-sidebar-contents">
        <h2>Contents</h2>
        <div
          class="documentation-sidebar-contents-link"
          [class.active]="i === selectedTabIndex"
          *ngFor="let tab of (tabList$|ngrxPush); let i = index"
          (click)="loadComponent(i)">
          <a>{{ tab.label }}</a>
        </div>
      </div>
    </div>
    <div class="documentation-content">
      <h1>{{selectedLabel}}</h1>
      <router-outlet></router-outlet>
    </div>
  </div>
</section>
