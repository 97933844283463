import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { ICustomCell } from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';

@Component({
  selector: 'app-domain-url-cell-wrapper',
  templateUrl: './domain-url-cell-wrapper.component.html',
  styleUrls: ['./domain-url-cell-wrapper.component.scss'],
})
export class DomainUrlCellWrapperComponent implements ICustomCell {
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() transform?: (input: string) => string;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;

  constructor(
    public ovs: ObjectValidatorService,
    private _snackbarService: SnackbarService,
  ) {}

  getValue(): string {
    const value = this.form?.controls[this.column.field].value ?? '';
    return this.column.transform ? this.column.transform(value) : value;
  }

  checkLink(): boolean {
    return /https?:\/\//.test(this.form.controls[this.column.field].value);
  }

  copyToClipboard(): void {
    let value = this.getValue();
    navigator.clipboard.writeText(value);
    this._snackbarService.open(
      `"${value}" copied to clipboard!`,
      SnackBarType.done,
      3000,
    );
  }
}
