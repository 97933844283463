<ng-container>
  <div class="impersonation-container" *appAccessControl="'lib-impersonation'">
    <div [class]="(isLoading$ | async) ? 'gray-loader' : ''">
      <lib-searchable-select-control
        *ngIf="!ovs.isNullOrEmpty(usersList)"
        label="User"
        [control]="userControl"
        [searchableListItems]="usersList"
        [sortOption]="userSearchableSelectSortOption"
        [enableMultiSelect]="false">
      </lib-searchable-select-control>
      <lib-searchable-select-control
        *ngIf="!ovs.isNullOrEmpty(rolesList)"
        label="Role"
        [control]="roleControl"
        [searchableListItems]="rolesList"
        [sortOption]="roleSearchableSelectSortOption"
        [enableMultiSelect]="false">
      </lib-searchable-select-control>
    </div>
    <a
      mat-flat-button
      color="accent"
      class="user-area__btn"
      href="javascript:void(0)"
      [disabled]="buttonDisabled"
      (click)="impersonate()">
      Impersonate
    </a>
  </div>
</ng-container>
