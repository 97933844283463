import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  IDockable,
  TokenCacheService,
} from '@nimbus/shared-lib';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { SubverticalRequestModel } from '../../shared/models/subvertical-request.model';
import { SubverticalReviewCacheService } from './services/subvertical-review-cache.service';
import { SubverticalReviewsCacheService } from '../services/subvertical-reviews-cache.service';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { SubverticalReviewSchemaService } from '../schemas/subvertical-review-schema.service';
import { SubverticalReviewsStatusEnum } from '../models/subvertical-reviews-status.enum';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SubverticalReviewWebService } from './services/subvertical-review-web.service';
import { OMLinkset } from './models/o-m-linkset';
import { OMLinksetKeyword } from './models/o-m-linkset-keyword';
import {
  SubjectManager,
  SubscriptionManager,
} from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-subvertical-review',
  templateUrl: './subvertical-review.component.html',
  styleUrls: ['./subvertical-review.component.scss'],
})
export class SubverticalReviewComponent
  implements OnInit, OnDestroy, IDockable
{
  private _subscriptionManager = new SubscriptionManager();
  private _subjectManager = new SubjectManager();
  private readonly _subverticalRequestId: number;
  isLoading$: Observable<boolean>;
  selectedSubverticalReview: SubverticalRequestModel;

  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  formModel: any = {};

  constructor(
    public ovs: ObjectValidatorService,
    public reviewsCacheService: SubverticalReviewsCacheService,
    private _subverticalReviewCacheService: SubverticalReviewCacheService,
    private _subverticalReviewSchemaService: SubverticalReviewSchemaService,
    private _subverticalReviewWebService: SubverticalReviewWebService,
    private _snackbarService: SnackbarService,
    private _tokenCacheService: TokenCacheService,
  ) {
    this._subverticalRequestId =
      this._subverticalReviewCacheService.subverticalRequestId;
    this._subjectManager.register('formModel');
  }

  @Input() model: any;
  @Output() modelChange: EventEmitter<any>;
  tabIndex: number;
  title: string;

  ngOnInit(): void {
    this._subscriptionManager.registerMultiple([
      combineLatest([
        this._tokenCacheService.userId,
        this._tokenCacheService.email,
        this._tokenCacheService.fullName,
      ])
        .pipe(
          tap(results => {
            this.selectedSubverticalReview =
              this.reviewsCacheService.subverticalRequestList.find(
                value => value.id === this._subverticalRequestId,
              );
            this.fields = this._subverticalReviewSchemaService.getSchema();
            this.formModel = {
              subverticalRequest: {
                vertical: this.selectedSubverticalReview.vertical,
                subVertical: this.selectedSubverticalReview.subvertical,
                geo: this.selectedSubverticalReview.geo,
                language: this.selectedSubverticalReview.language,
              },
              keywords: this.selectedSubverticalReview.keywords,
              requestor: {
                email: this.selectedSubverticalReview.friendlyRequestorEmail,
                name: this.selectedSubverticalReview.friendlyRequestorName,
              },
              reviewer: {
                userId: results[0],
                email: results[1],
                name: results[2],
              },
              notes: this.selectedSubverticalReview.notes,
            };
          }),
        )
        .subscribe(),
    ]);
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  onModelChange(formModel) {
    this._subjectManager.next('formModel', formModel);
  }

  submitRequest(status: boolean) {
    this._snackbarService.open('Processing...', SnackBarType.working, 3000);
    status ? this._approvedRequest() : this._deniedRequest();
  }

  private _approvedRequest() {
    this.isLoading$ = of(true);
    this._subscriptionManager.registerMultiple([
      this._subverticalReviewWebService
        .checkLinksetExists(
          this.formModel.subverticalRequest.vertical,
          this.formModel.subverticalRequest.subVertical,
          this.formModel.subverticalRequest.geo,
          this.formModel.subverticalRequest.language,
        )
        .pipe(
          tap(result => {
            result ? this._invalidRequest() : this._handleRequest(true);
          }),
        )
        .subscribe(),
    ]);
  }

  private _deniedRequest() {
    this.isLoading$ = of(true);
    this._subscriptionManager.registerMultiple([
      this._handleRequest(false).subscribe(),
    ]);
  }

  private _handleRequest(status: boolean): Observable<any> {
    this._snackbarService.open(
      'No additional changes to the request can be made.',
      SnackBarType.done,
      3000,
    );
    this._updateRequest(status);
    return EMPTY;
  }

  private _invalidRequest(): Observable<any> {
    this._snackbarService.open(
      'The vertical/subvertical-geo/language combination already exists',
      SnackBarType.error,
      3000,
    );
    this.isLoading$ = of(false);
    return EMPTY;
  }

  private _updateRequest(status: boolean): void {
    let keywords = (this.formModel.keywords as string).trim();
    keywords.endsWith('\n') ? (keywords = keywords.slice(0, -1)) : null;
    this.formModel.keywords = keywords;

    if (status) {
      const omLinkset = new OMLinkset(
        this.formModel.subverticalRequest.vertical,
        this.formModel.subverticalRequest.subVertical,
        this._createOMKeywords(),
        this.formModel.subverticalRequest.geo,
        this.formModel.subverticalRequest.language,
      );

      this._subscriptionManager.register(
        this._subverticalReviewWebService
          .createOMLinkset(omLinkset)
          .pipe(
            catchError(error => {
              this._snackbarService.open(
                'An error occurred while creating a new linkset in the database.',
                SnackBarType.error,
              );
              this.isLoading$ = of(false);
              return EMPTY;
            }),
            switchMap(() => this._updateSubvertical(status)),
          )
          .subscribe(),
      );
    } else {
      this._subscriptionManager.register(
        this._updateSubvertical(status).subscribe(),
      );
    }
  }

  private _updateSubvertical(status: boolean): Observable<any> {
    if (status) {
      this.selectedSubverticalReview.vertical =
        this.formModel.subverticalRequest.vertical;
      this.selectedSubverticalReview.subvertical =
        this.formModel.subverticalRequest.subVertical;
      this.selectedSubverticalReview.keywords = this.formModel.keywords;
      this.selectedSubverticalReview.geo =
        this.formModel.subverticalRequest.geo;
      this.selectedSubverticalReview.language =
        this.formModel.subverticalRequest.language;
    }
    this.selectedSubverticalReview.status = status
      ? SubverticalReviewsStatusEnum.Approved
      : SubverticalReviewsStatusEnum.Denied;
    this.selectedSubverticalReview.notes = this.formModel.notes;
    this.selectedSubverticalReview.reviewedBy = this.formModel.reviewer.userId;

    return this._subverticalReviewWebService
      .updateSubverticalRequest(this.selectedSubverticalReview)
      .pipe(
        catchError(() => {
          this._snackbarService.open(
            'An error occurred while updating the subvertical request.',
            SnackBarType.error,
          );
          this.isLoading$ = of(false);
          return EMPTY;
        }),
        tap(() => {
          this.isLoading$ = of(false);
          this._subverticalReviewCacheService.closeSubverticalReviewDock();
        }),
      );
  }

  private _createOMKeywords(): OMLinksetKeyword[] {
    const omLinksetKeywords: OMLinksetKeyword[] = [];
    const description =
      `Request from ${this.formModel.requestor.name} with email ` +
      `${this.formModel.requestor.email} was approved by ${this.formModel.reviewer.name} with email ` +
      `${this.formModel.reviewer.email}`;
    this.formModel.keywords
      .split('\n')
      .filter(keyword => !this.ovs.isNullOrEmpty(keyword.trim()))
      .forEach(keyword =>
        omLinksetKeywords.push(
          new OMLinksetKeyword(
            keyword,
            true,
            true,
            this.formModel.requestor.email,
            description,
            this.formModel.subverticalRequest.language,
          ),
        ),
      );
    return omLinksetKeywords;
  }
}
