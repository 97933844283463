import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { AngularMaterialModule } from '../../core/angular-material.module';
import { LayoutComponent } from './layout/layout.component';
import { RouterModule } from '@angular/router';
import { DockModule } from '../layout/dock/dock.module';
import { ParentContainerHeightPercentPipe } from './parent-container-height-percent-pipe';
import { ChildContainerHeightPercentPipe } from './child-container-height-percent-pipe';
import { ContentHeightPercentPipe } from './content-height-percent-pipe';
import { ConfirmationWrapperComponent } from './confirmation-wrapper/confirmation-wrapper.component';
import { ConfirmationModule } from '../layout/confirmation/confirmation.module';
import { TopOptionsTogglerModule } from '../top-options-toggler/top-options-toggler.module';
import { BannerComponent } from 'core-global-frontend-common-ui';

@NgModule({
  declarations: [
    LayoutComponent,
    ParentContainerHeightPercentPipe,
    ChildContainerHeightPercentPipe,
    ContentHeightPercentPipe,
    ConfirmationWrapperComponent,
  ],
  exports: [LayoutComponent, ConfirmationWrapperComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatInputModule,
    MatOptionModule,
    MatAutocompleteModule,
    RouterModule,
    DockModule,
    ConfirmationModule,
    TopOptionsTogglerModule,
  ],
})
export class LayoutModule {}
