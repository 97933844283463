import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ConfirmationSubscriberService } from '../../../../shared/services/confirmation-subscriber.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { SubverticalRequestsHistoryDockService } from '../services/subvertical-requests-history-dock.service';
import { SubverticalRequestCacheService } from '../services/subvertical-request-cache.service';
import { ICustomCell } from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';

@Component({
  selector: 'subvertical-history-action-cell',
  templateUrl: './subvertical-history-action-cell.component.html',
  styleUrls: ['./subvertical-history-action-cell.component.scss'],
})
export class SubverticalHistoryActionCellComponent
  implements ICustomCell, OnInit, OnDestroy
{
  private readonly _subscriptionManager = new SubscriptionManager();
  isDeleteAllowed$ = of(false);
  subverticalRequestToBeDeletedID: number;
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() transform?: (input: string) => string;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;

  constructor(
    private _SubverticalRequestsHistoryDockService: SubverticalRequestsHistoryDockService,
    private _subverticalRequestCacheService: SubverticalRequestCacheService,
    public _confirmationSubscriberService: ConfirmationSubscriberService,
    private _ovs: ObjectValidatorService,
    private _snackbarService: SnackbarService,
  ) {
    this._subscriptionManager.registerMultiple([
      this._confirmationSubscriberService.confirmation$
        .pipe(
          tap(confirm => {
            if (confirm) {
              if (this._ovs.isDefined(this.subverticalRequestToBeDeletedID)) {
                this._snackbarService.open(
                  'Processing delete request',
                  SnackBarType.working,
                  3000,
                );
                this._subverticalRequestCacheService
                  .deleteSubverticalRequest(
                    this.subverticalRequestToBeDeletedID,
                  )
                  .subscribe(
                    () => {
                      this._snackbarService.open(
                        'Subvertical Request deleted!',
                        SnackBarType.done,
                        3000,
                      );
                      this._subverticalRequestCacheService.load(true);
                    },
                    () => {
                      this._snackbarService.open(
                        'There was an error processing your request',
                        SnackBarType.error,
                        3000,
                      );
                    },
                  );
              }
              this._confirmationSubscriberService.clearEmitConfirmation();
              this.subverticalRequestToBeDeletedID = null;
            }
          }),
        )
        .subscribe(),
      this._confirmationSubscriberService.closeModal$
        .pipe(
          tap(() => {
            this.subverticalRequestToBeDeletedID = undefined;
          }),
        )
        .subscribe(),
    ]);
  }

  ngOnInit(): void {
    this.isDeleteAllowed$ = of(
      this.form.controls.friendlyStatus.value.toLowerCase() === 'pending',
    );
  }

  view() {
    this._SubverticalRequestsHistoryDockService.setSelectedSubverticalHistoryId(
      this.form.controls.id.value,
    );
  }

  delete() {
    this.subverticalRequestToBeDeletedID = this.form.controls.id.value;
    this._confirmationSubscriberService.emitModal();
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }
}
