<div class="array-wrapper">
  <div class="row-with-button">
    <h4 *ngIf="props.label">{{ props.label }}</h4>
    <button
      mat-flat-button
      color="primary"
      (click)="add(); $event.stopPropagation()"
    >
      Add
    </button>
  </div>
  <p *ngIf="props.description">{{ props.description }}</p>

  <!-- <div class="alert alert-danger" role="alert" *ngIf="showError && formControl.errors">
    <formly-validation-message [field]="field"></formly-validation-message>
  </div> -->

  <div
    *ngFor="let field of field.fieldGroup; let i = index"
    class="row-with-button indent-item"
  >
    <formly-field class="col" [field]="field"></formly-field>
    <div *ngIf="field?.props?.['removable'] !== false ?? false">
      <button
        mat-flat-button
        color="accent"
        (click)="remove(i); $event.stopPropagation()"
      >
        X
      </button>
    </div>
  </div>
</div>
