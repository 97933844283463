import { Injectable } from '@angular/core';
import { TokenCacheService } from '@nimbus/shared-lib';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, tap, map, switchMap } from 'rxjs/operators';
import { SubverticalRequestModel } from '../../shared/models/subvertical-request.model';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { ImpersonationTrackerService } from '../../../../shared/services/impersonation-tracker.service';
import { GatewayService } from '../../../external-tools/tableau/services/gateway.service';
import { GridModel } from 'core/libs/global/frontend/grid/src/lib/grid-models/grid-model';

@Injectable({
  providedIn: 'root',
})
export class SubverticalRequestsWebService {
  constructor(private _gatewayService: GatewayService, private _ovs: ObjectValidatorService) {}

  getSubverticalRequests(
    requestorId?: string | undefined,
    statuses?: number[] | undefined
  ): Observable<SubverticalRequestModel[]> {
    return this._gatewayService.get(`subverticalrequests`, {
      requestorId,
      statusfilterthirtydays: !this._ovs.isNullOrEmpty(statuses) ? statuses.join(',') : null
    });
  }

  deleteSubverticalRequest(subverticalRequestId: number) {
    return this._gatewayService.delete(`subverticalrequests/${subverticalRequestId}`);
  }
}
@Injectable({
  providedIn: 'root',
})
export class SubverticalRequestCacheService {
  private _updatedAt: Date;
  private readonly _subscriptionManager = new SubscriptionManager();
  private readonly _requestedSubverticalsSubject = new BehaviorSubject<
    SubverticalRequestModel[]
  >([]);
  readonly requestedSubverticals$ =
    this._requestedSubverticalsSubject.asObservable();
  staticRequestedSubverticals = [];
  pageSize: Observable<number> = of(20);
  isLoading$: Observable<boolean>;

  requestedSubverticalsEmpty$ = this.requestedSubverticals$.pipe(
    map(requestedSubverticals =>
      this._ovs.isNullOrEmpty(requestedSubverticals),
    ),
  );

  requestedSubverticalsGridModel$: Observable<GridModel> = this.requestedSubverticals$.pipe(
    map(requested => new GridModel("requested-subverticals", requested))
  );

  constructor(
    private _subverticalRequestsWebService: SubverticalRequestsWebService,
    private _ovs: ObjectValidatorService,
    private _tokenCacheService: TokenCacheService,
    private _impersonationTrackerService: ImpersonationTrackerService,
  ) {
    this.load(true);
  }

  load(refresh: boolean = false): void {
    if (
      !refresh &&
      this._ovs.isDefined(this._updatedAt) &&
      new Date(this._updatedAt.getTime() + 3600000) > new Date(Date.now())
    ) {
      return;
    }
    this.isLoading$ = of(true);
    this._subscriptionManager.registerMultiple([
      this._tokenCacheService.userId
        .pipe(
          switchMap(user =>
            this._subverticalRequestsWebService.getSubverticalRequests(
                this._ovs.isNullOrEmpty(this._impersonationTrackerService.user)
                  ? user
                  : this._impersonationTrackerService.user.userId,
              ),
          ),
          tap(requestedSubverticals => {
            this.staticRequestedSubverticals = requestedSubverticals;
            this._requestedSubverticalsSubject.next(
              requestedSubverticals.sort((a, b) => {
                if (a.friendlyStatus === b.friendlyStatus) {
                  return a.requestedDate <= b.requestedDate ? 1 : -1;
                } else if (
                  a.friendlyStatus.toLowerCase() === 'pending' ||
                  (a.friendlyStatus.toLowerCase() === 'denied' &&
                    b.friendlyStatus.toLowerCase() === 'approved')
                ) {
                  return -1;
                } else {
                  return 1;
                }
              }),
            );
            this._updatedAt = new Date(Date.now());
            this.isLoading$ = of(false);
          }),
          catchError(error => {
            this.isLoading$ = of(false);
            throw error;
          }),
        )
        .subscribe(),
      this.requestedSubverticals$.subscribe(),
    ]);
  }

  deleteSubverticalRequest(subverticalRequestId: number) {
    return this._subverticalRequestsWebService.deleteSubverticalRequest(subverticalRequestId);
  }
}
