import { ValidationErrors, AbstractControl, FormArray, FormGroup } from '@angular/forms';

export function DomainUniqueValidator(control: AbstractControl): ValidationErrors {
  const domains = control.root.get('domains') as FormArray;
  let nonEmptyDomainNames = [];
  for (const domain of domains.controls) {
    const subform = domain as FormGroup;
    if (!subform.controls.domainName) {
      continue;
    }
    if (subform.controls.domainName.value)
      nonEmptyDomainNames.push(subform.controls.domainName.value);
  }

  let distinctDomains: string[] = [...new Set(nonEmptyDomainNames)];
  return distinctDomains.length < nonEmptyDomainNames.length
    ? { 'domain-unique-validator': true }
    : {};
}
