import { SubverticalRequestsHistoryDockService } from '../services/subvertical-requests-history-dock.service';
import { SubverticalRequestsDockSchemaService } from './schemas/subvertical-requests-dock-schema.service';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { IDockable } from '@nimbus/shared-lib';
import { tap, withLatestFrom } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SubverticalRequestCacheService } from '../services/subvertical-request-cache.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-subvertical-requests-dock',
  templateUrl: './subvertical-requests-dock.component.html',
  styleUrls: ['./subvertical-requests-dock.component.scss'],
})
export class SubverticalRequestsDockComponent
  implements OnInit, OnDestroy, IDockable
{
  private readonly _subscriptionManager = new SubscriptionManager();
  model: any;
  tabIndex: number;
  title: string;
  modelChange: EventEmitter<any>;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  formModel: any = {};

  constructor(
    private _subverticalRequestsHistoryDockService: SubverticalRequestsHistoryDockService,
    private _subverticalRequestCacheService: SubverticalRequestCacheService,
    private _subverticalRequestsDockSchemaService: SubverticalRequestsDockSchemaService,
    private _ovs: ObjectValidatorService,
  ) {}

  ngOnInit(): void {
    this._subscriptionManager.register(
      this._subverticalRequestsHistoryDockService.selectedSubverticalHistoryId$
        .pipe(
          withLatestFrom(
            this._subverticalRequestCacheService.requestedSubverticals$,
          ),
        )
        .pipe(
          tap(value => {
            const selectedRequestedSubvertical = value[1].find(
              r => r.id === value[0],
            );
            this.formModel = {
              subverticalRequest: {
                vertical: selectedRequestedSubvertical.vertical,
                subVertical: selectedRequestedSubvertical.subvertical,
                geo: selectedRequestedSubvertical.geo,
                language: selectedRequestedSubvertical.language,
              },
              keywords: selectedRequestedSubvertical.keywords,
              submitDate: this.dateTouchup(
                selectedRequestedSubvertical.requestedDate,
              ),
              reviewDate: this.dateTouchup(
                selectedRequestedSubvertical.reviewedDate,
              ),
              notes: selectedRequestedSubvertical.notes,
            };
            this.fields = this._subverticalRequestsDockSchemaService.getSchema(
              this.formModel,
            );
          }),
        )
        .subscribe(),
      'loadSubverticalHistoryDock',
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  private dateTouchup(date: Date): string {
    if (this._ovs.isNullOrUndefined(date)) {
      return '';
    }
    const newDate = new Date(date);
    return newDate.toISOString().split('T')[0];
  }
}
