import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { ILocalStorageService } from './ilocal-storage-service';
import { QLocalStorageService } from './local-storage.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { LocalStorageKey } from './local-storage-key.model';

@Injectable()
export class LocalStorageManagerService implements ILocalStorageService {
  constructor(
    private _qLocalStorage: QLocalStorageService,
    private _ovs: ObjectValidatorService,
  ) {
    this._clearExpired();
  }

  clearAll() {
    this._existingKeys().map(k => this.deleteKey(k));
  }

  getKey(key: LocalStorageKey): string {
    const value = this._qLocalStorage.getKey(key);
    if (this._ovs.isDefined(value)) {
      return value;
    }

    // There should never be more than one expired record at the time.
    const expiredEntries = this._existingKeys().filter(
      k => k.equals(key) && DateTime.fromJSDate(k.expiration) > DateTime.now(),
    );
    if (this._ovs.isNullOrEmpty(expiredEntries)) {
      return null;
    }

    // If one expired exists, refresh it's expiration and reuse
    return this._refreshExpiredContent(expiredEntries, key.expiration);
  }

  private _refreshExpiredContent(
    expiredEntries: LocalStorageKey[],
    newExpiration: Date,
  ) {
    const expiredContentKey = expiredEntries[0];
    const content = this._qLocalStorage.getKey(expiredContentKey);
    expiredEntries.forEach(expiredEntry =>
      this._qLocalStorage.deleteKey(expiredEntry),
    );
    expiredContentKey.expiration = newExpiration;
    this.setKey(expiredContentKey, content);
    return content;
  }

  setKey(key: LocalStorageKey, value: string) {
    this._qLocalStorage.setKey(key, value);
  }

  deleteKey(key: LocalStorageKey): void {
    this._qLocalStorage.deleteKey(key);
  }

  private _clearExpired() {
    const existingKeys = this._existingKeys();
    if (this._ovs.isNullOrEmpty(existingKeys)) {
      return;
    }
    existingKeys.map(k => {
      if (DateTime.fromJSDate(k.expiration) < DateTime.now()) {
        this.deleteKey(k);
      }
    });
  }

  private _existingKeys(): LocalStorageKey[] {
    const entries = [];
    for (const x in localStorage) {
      if (x.startsWith('{"') === false) {
        continue;
      }
      const key = JSON.parse(x) as LocalStorageKey;
      entries.push(
        new LocalStorageKey(
          key.expiration,
          key.clientVersion,
          key.userSpecific,
          key.keyName,
        ),
      );
    }
    return entries;
  }
}
