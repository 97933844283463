import { CommonModule, DatePipe } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import {
  ConfirmationModule,
  ConfirmationService,
  LayoutModule,
  SearchableSelectControlModule,
} from '@nimbus/shared-lib';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { BrowserModule } from '@angular/platform-browser';
import { MatInputModule } from '@angular/material/input';
import { PixelManagerComponent } from './pixel-manager/pixel-manager.component';
import { PixelManagerFormComponent } from './pixel-manager-form/pixel-manager-form.component';
import { PixelManagerHistoryComponent } from './pixel-manager-history/pixel-manager-history.component';
import { EditAccessTokenComponent } from './pixel-manager-history/edit-access-token/edit-access-token.component';
import { AccessTokenDateComponent } from './pixel-manager-history/access-token-date/access-token-date.component';
import { PixelManagerEditService } from './services/pixel-manager-edit.service';
import { PixelManagerRoutingModule } from './pixel-manager-routing.module';
import { BuysideInputComponent } from '../../shared/formly/types/buyside-input/buyside-input.component';
import { ConfirmationSubscriberService } from '../../shared/services/confirmation-subscriber.service';
import { FormlyModule } from '@ngx-formly/core';
import {
  GlobalFrontendGridModule,
  GridCellComponentMapperService,
  GridComponent,
} from 'core-global-frontend-grid';
import { PushPipe } from '@ngrx/component';

@NgModule({
  declarations: [
    PixelManagerComponent,
    PixelManagerFormComponent,
    BuysideInputComponent,
    PixelManagerHistoryComponent,
  ],
  imports: [
    LayoutModule,
    CommonModule,
    PushPipe,
    FormsModule,
    GridComponent,
    FormlyMaterialModule,
    MatFormFieldModule,
    GlobalFrontendGridModule,
    FormlyMatToggleModule,
    ReactiveFormsModule,
    ConfirmationModule,
    SearchableSelectControlModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    BrowserModule,
    FormlyModule.forChild(),
    PixelManagerRoutingModule,
  ],
  providers: [
    DatePipe,
    ConfirmationService,
    ConfirmationSubscriberService,
    PixelManagerEditService,
  ],
})
export class PixelManagerModule {
  constructor(_gridCellComponentMapperService: GridCellComponentMapperService) {
    _gridCellComponentMapperService.registerComponent(
      'access-token-date',
      AccessTokenDateComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'edit-access-token',
      EditAccessTokenComponent as Component,
    );
  }
}
