import { ReplaySubject, Observable, Subject } from 'rxjs';
import { ChangeEmitType } from './change-emit-type';
import { QRow } from './q-row';
import { OnModelChangeParameter } from './on-model-change-parameter';
import { ChangeTrackerDump } from './change-tracker-dump';
import { QGrid } from './q-grid';
import { debounceTime } from 'rxjs/operators';

export class ChangeTracker {
  private _list: QRow[];
  private _paused: boolean;
  private _tableModelChangeSubject = new ReplaySubject<OnModelChangeParameter>();
  private _triggerPipesSubject = new Subject<void>();
  private _triggerPipes$: Observable<void>;
  onModelChanged: Observable<OnModelChangeParameter>;

  constructor(qGrid: () => QGrid) {
    this._triggerPipes$ = this._triggerPipesSubject.asObservable();
    //Untrack
    this._triggerPipes$
      .pipe(debounceTime(250))
      .subscribe(() => qGrid().list.forEach(row => row.triggerPipes()));
    this._list = [];
    this._paused = false;
    this.onModelChanged = this._tableModelChangeSubject.asObservable();
  }

  private _isModelChanged(row: QRow): boolean {
    let childRowsChanged = false;

    const parentRowChanged = this._list
      ? this._list.length > 0 &&
        // and there is at least one changed row in the list
        this._list.findIndex(childRow => childRow.isChanged) > -1
      : false;

    if (!parentRowChanged && row.hasChildren) {
      childRowsChanged = row.children.findIndex(childRow => childRow.isChanged) > -1;
    }

    return parentRowChanged || childRowsChanged;
  }

  private _isModelSelected(): boolean {
    return this._list
      ? this._list.length > 0 &&
          // and there is at least one changed row in the list
          this._list.findIndex(row => row.isSelected) > -1
      : false;
  }

  track(row: QRow, changeType: ChangeEmitType) {
    if (!this._paused && !this._list.includes(row)) {
      this._list.push(row);
    }
    this._tableModelChangeSubject.next(
      new OnModelChangeParameter(
        changeType,
        this._isModelChanged(row),
        this._isModelSelected(),
        row.userOverridesRowSelection
      )
    );
    this._triggerPipesSubject.next(null);
  }

  untrack(row: QRow, changeType: ChangeEmitType) {
    if (this._list.includes(row)) {
      if (!row.isSelected) {
        this._list.splice(this._list.indexOf(row), 1);
      }
      this._tableModelChangeSubject.next(
        new OnModelChangeParameter(
          changeType,
          this._isModelChanged(row),
          this._isModelSelected(),
          row.userOverridesRowSelection
        )
      );
      this._triggerPipesSubject.next(null);
    }
  }

  untrackAll() {
    this._tableModelChangeSubject.next(
      new OnModelChangeParameter(ChangeEmitType.Cancelled, false, this._isModelSelected(), false)
    );
    this._triggerPipesSubject.next(null);
  }

  getToSaveList(): QRow[] {
    if (this._isModelSelected()) {
      return this._list.filter(x => x.isSelected);
    }
    return this._list;
  }

  getList(): QRow[] {
    return this._list;
  }

  pauseTracking() {
    this._paused = true;
  }

  resumeTracking() {
    this._paused = false;
  }

  dumpAsString(items: QGrid): string {
    return JSON.stringify(
      this._list
        .filter(x => x.key >= items.pageMinKey && x.key <= items.pageMaxKey)
        .map(x => new ChangeTrackerDump(x.key, x.isSelected, x.isChanged) as any)
    );
  }
}
