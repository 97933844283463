import { Component } from '@angular/core';

@Component({
  selector: 'app-search-api-endpoints',
  templateUrl: './search-api-endpoints.component.html',
  styleUrls: ['../documentation.scss']
})
export class SearchApiEndpointsComponent {
  rateLimits = [
    'HTTP/1.0 429 TOO MANY REQUESTS',
    'Retry-After: 17',
    'Content-Type: application/json',
    'Content-Length: 2',
    'Date: Tue, 02 Jan 2018 00:06:40 GMT',
    'Server: nginx',
    'Connection: keep-alive'
  ];
  rateSummaries = [
    'All data is in Pacific Time (PST)',
    'Data Update Cadence: Daily (contingent on upstream partner data availability ' +
      'and our hosting provider uptime) - API Rate Limits: accessing API at 10 ' +
      'requests/second, 120 requests/minute and 240 requests/hour should allow ' +
      'partners to keep within our rate limits and allow you to pull: 10 months of ' +
      'campaign and summary level data in 10 seconds'
  ];
  parametersTable = {
    headers: ['Parameter name', 'Parameter function'],
    data: [
      {
        name: 'days (optional)',
        function:
          'This parameter allows you to specify the number of days ' +
          "you'd like to get data for or specify specific days that you would " +
          'like data for. If you omit this parameter it will return the full 31 days.'
      }
    ]
  };
  dataGlossaryTable = {
    headers: ['Report Attributes', 'Definition'],
    data: [
      {
        attribute: 'AYLF Clicks',
        definition:
          'Are You Looking For (AYLF), also known as “Related Search” ' +
          '- These are clicks that open a secondary or subsequent search result page.'
      },
      {
        attribute: 'AYLF CTR',
        definition:
          'Percentage of search result pages where the Are You Looking ' +
          'For/Related Search results are clicked.'
      },
      {
        attribute: 'Country',
        definition: 'Country associated with the conversion.'
      },
      {
        attribute: 'Date',
        definition:
          'All data sources are aligned to the US/Pacific (including PST ' + 'and PDT) day.'
      },
      {
        attribute: 'Device Type',
        definition: 'Device type is specified desktop, mobile, tablet, or other.'
      },
      {
        attribute: 'Hour',
        definition:
          'All data sources are aligned to the US/Pacific ' + '(including PST and PDT) time.'
      },
      {
        attribute: 'Impressions ',
        definition: 'Number of generated Search pages with at least one ad returned.'
      },
      {
        attribute: 'Initial Non-paid Clicks',
        definition: 'Count of non-paid clicks that originate from the initial search page.'
      },
      {
        attribute: 'Initial Page',
        definition:
          'Regularly called, “Initial Search” pages, these are defined as ' +
          'the first search page (which is hosted/managed by System1)'
      },
      {
        attribute: 'Initial Pageviews',
        definition: 'Initial pageviews are the first page viewed by the user'
      },
      {
        attribute: 'Initial Paid Clicks',
        definition:
          'Count of paid ad clicks that take place on the first ' + 'or initial search page.'
      },
      {
        attribute: 'Non-paid Clicks',
        definition: 'Clicks not attributed to revenue, i.e. organic clicks'
      },
      {
        attribute: 'Page Views',
        definition: 'Page Events or “Searches”'
      },
      {
        attribute: 'Pageviews Per Session',
        definition: 'Number of generated SERPs / sessions'
      },
      {
        attribute: 'Paid Clicks',
        definition: 'Number of paid ad clicks'
      },
      {
        attribute: 'Paid Clicks Per Impression',
        definition:
          'Number of non-paid and paid clicks your ad receives ' +
          '(Non-paid clicks + Paid clicks) divided by the number of times ' +
          'your ad is shown (Impressions)'
      },
      {
        attribute: 'Paid Clicks Per Pageview',
        definition: 'Percentage of Paid Clicks with respect to Pageviews'
      },
      {
        attribute: 'Paid CTR',
        definition:
          'Number of paid clicks your ad receives (Paid Clicks) ' +
          'divided by Searches (sum of search sessions)'
      },
      {
        attribute: 'Revenue',
        definition: 'Estimated revenue before revenue share calculations'
      },
      {
        attribute: 'RPC',
        definition: 'Revenue / Total Click'
      },
      {
        attribute: 'RPM',
        definition: 'Revenue per 1000 impression'
      },
      {
        attribute: 'Segment',
        definition:
          'Segment designates the desired Search page configuration/layout ' +
          'that traffic is sent to. It can also be used to send specific SEM ' +
          'campaigns to targeted segments for granularity purposes.'
      },
      {
        attribute: 'SERP',
        definition: 'Search Engine Result Page (see Page View)'
      },
      {
        attribute: 'Sessions',
        definition: 'Closest number to unique of users, SERP sessions'
      },
      {
        attribute: 'Subsequent Search',
        definition:
          'The “Subsequent Search” - Meaning, the secondary search ' +
          'page that originates from a pagination click, AYLF/related search click, ' +
          'or typing in another search into the search bar'
      },
      {
        attribute: 'Subsequent Non-paid Clicks',
        definition:
          'The count of non-paid (non-ad) clicks that take place on ' +
          'subsequent or secondary search pages. These include pagination, ' +
          'AYLF/Related Search or Organic result (non-ad) clicks'
      },
      {
        attribute: 'Subsequent Pageviews',
        definition:
          'The count of page events that are “subsequent ' + 'searches” (as defined above)'
      },
      {
        attribute: 'Subsequent Pageviews Per Session',
        definition:
          'Within a user’s session, the count of secondary or ' +
          'subsequent search pages (as defined above).'
      },
      {
        attribute: 'Subsequent Paid Clicks',
        definition:
          'Paid ad clicks that take place on the ' + 'secondary or subsequent search page.'
      },
      {
        attribute: 'Subsequent Paid CTR',
        definition:
          'Percentage of paid clicks that take place on ' +
          'the secondary or subsequent search page'
      },
      {
        attribute: 'Top Keywords by Paid Clicks',
        definition: 'Keywords attributed to revenue'
      },
      {
        attribute: 'Total Clicks',
        definition: 'Sum of Paid Clicks and Non-paid Clicks'
      },
      {
        attribute: 'Total Clicks Per Impression',
        definition: 'Number of non-paid and paid clicks / Impressions'
      },
      {
        attribute: 'Total Clicks Per Pageview',
        definition:
          'Number of non-paid and paid clicks your ad receives ' +
          '(Non-paid clicks + Paid clicks) divided by Pageviews'
      },
      {
        attribute: 'Total CTR',
        definition:
          'Number of non-paid and paid clicks your ad receives ' +
          '(Non-paid clicks + Paid clicks) divided by Searches (sum of serp sessions)'
      },
      {
        attribute: 'W/W% Clicks',
        definition: 'WoW% change in paid clicks'
      },
      {
        attribute: 'W/W% Clicks',
        definition: 'WWoW% change in clicks'
      },
      {
        attribute: 'W/W% Impressions',
        definition: 'WoW% change in impressions'
      },
      {
        attribute: 'W/W% Nonpaid Clicks',
        definition: 'WoW% change in nonpaid clicks'
      },
      {
        attribute: 'W/W% Pageviews',
        definition: 'WoW% change in pageviews'
      },
      {
        attribute: 'W/W% Pageviews Per Session',
        definition: 'WoW% change in pageviews per session'
      },
      {
        attribute: 'W/W% Paid CTR',
        definition: 'WoW% change in paid CTR'
      },
      {
        attribute: 'W/W% Revenue',
        definition: 'WoW% change in revenue'
      },
      {
        attribute: 'W/W% RPC',
        definition: 'WoW% change in RPC'
      },
      {
        attribute: 'W/W% RPM',
        definition: 'WoW% change in RPM'
      },
      {
        attribute: 'W/W% Sessions',
        definition: 'WoW% change in sessions'
      },
      {
        attribute: 'W/W% Total CTR',
        definition: 'WoW% change in total CTR'
      }
    ]
  };
  standardReports = [
    'search_performance',
    'top_keywords',
    'search_breakdown',
    'non_rev_subID',
    'subID',
    'subID daily',
    'search_performance_hourly'
  ];
  keywordsTierTable = {
    headers: ['Segment Count', 'Maximum Keywords Returned Per Segment'],
    data: [
      {
        segment_count: 'Less than 50',
        keywords_returned: '1000'
      },
      {
        segment_count: 'Between 50 and 100',
        keywords_returned: '500'
      },
      {
        segment_count: 'Between 100 - 250',
        keywords_returned: '200'
      },
      {
        segment_count: 'Greater than 250',
        keywords_returned: '100'
      }
    ]
  };

  constructor() {}

  getHeaderCount(table) {
    return `grid-template-columns: repeat(${table.headers.length}, auto)`;
  }

  getColumnHeaderClass(table, index) {
    return index < table.headers.length - 1
      ? 'documentation-grid column-header column-header__standard'
      : 'documentation-grid column-header column-header__end';
  }

  getColumnValueClass(columnIndex, table) {
    return columnIndex < table.headers.length - 1
      ? 'documentation-grid column-value column-value__standard'
      : 'documentation-grid column-value column-value__end';
  }
}
