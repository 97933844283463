import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { FieldArrayType, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { AngularMaterialModule } from '../../../angular-material/angular-material.module';
import { NimbusFormlyCommonModule } from 'core-global-frontend-form-nimbus-formly-common';
import { NgJsonEditorModule } from 'ang-jsoneditor';

@Component({
  selector: 'frontend-array-type',
  templateUrl: './array-type.component.html',
  styleUrls: ['./array-type.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FormlyModule,
    NgJsonEditorModule,
    FormlyMaterialModule,
    MatCommonModule,
    NimbusFormlyCommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
  ],
})
export class ArrayTypeComponent extends FieldArrayType {}
