import { FilterConfiguration } from './filter-configuration';
import { FilterType } from './filter-type';
import { FilterDefaults } from './filter-defaults';
import { Subject } from 'rxjs';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { RangeType } from '../range/range.model';

export class FilterItem {
  onClear: Subject<void> = new Subject<void>();
  protected _ovs = new ObjectValidatorService();

  constructor(
    public columnName: string,
    public filterConfigurations: FilterConfiguration[],
    public filterDefaults: FilterDefaults,
  ) {}

  clear() {
    this.filterDefaults.clear();
    if (!this.filterConfigurations) {
      return;
    }
    if (this._ovs.isDefined(this.filterConfigurations[FilterType.Contains])) {
      this.filterConfigurations[FilterType.Contains] = null;
    }
    if (
      this._ovs.isDefined(this.filterConfigurations[FilterType.NotContains])
    ) {
      this.filterConfigurations[FilterType.NotContains] = null;
    }
    if (this._ovs.isDefined(this.filterConfigurations[FilterType.Range])) {
      this.filterConfigurations[FilterType.Range] = null;
    }
    if (this._ovs.isDefined(this.filterConfigurations[FilterType.NonZero])) {
      this.filterConfigurations[FilterType.NonZero] = null;
    }
    if (this._ovs.isDefined(this.filterConfigurations[FilterType.Equals])) {
      this.filterConfigurations[FilterType.Equals] = null;
    }
    // force filter to clear
    this.filterConfigurations[FilterType.ClearFilter] = new FilterConfiguration(
      FilterType.ClearFilter,
      null,
    );
    this.onClear.next(null); // send message to filter components to clear
    // leave this line at the very end
    // set last filter object to null
    if (
      this._ovs.isDefined(this.filterConfigurations[FilterType.ClearFilter])
    ) {
      this.filterConfigurations[FilterType.ClearFilter] = null;
    }
  }
}
