import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserContextEntry } from '../models/user-context-entry';
import { BaseWebService } from '../../../core/base-web.service';
import { ConfigService } from '../../../core/config.service';
import { HttpClientQ } from 'core-global-frontend-http';

@Injectable()
export class ContextWebService extends BaseWebService {
  constructor(
    private configService: ConfigService,
    private http: HttpClientQ,
  ) {
    super();
  }

  getUserContext(checkForReport = true): Observable<UserContextEntry[]> {
    return this.http.get(`${this.configService.getApi().host}/users/context`, {
      checkForReport: checkForReport,
    });
  }
}
