<div class="documentation-highlighted">Reporting Parameters</div>
<br />
<div>
  There are {{ parameterCount() }} reporting parameters that allow for tracking of various metrics at different
  levels of granularity. These parameters names are case sensitive and should be passed as written in all lowercase.  The parameters marked with an asterisk (*) are REQUIRED.:
</div>
<div class="documentation-grid-container">
  <div
    *ngFor="let header of table.headers; index as i"
    [class]="columnClassHelper.getColumnHeaderClass(table, i)">
    <span class="documentation bold">{{ header }}</span>
  </div>
  <ng-container *ngFor="let element of table.data">
    <ng-container *ngFor="let record of element.records">
      <div [class]="columnClassHelper.getColumnValueClass(0, table)">
        <span class="documentation bold">{{ record.parameter }}{{ record.isRequired && ' *' }}</span>
      </div>
      <div [class]="columnClassHelper.getColumnValueClass(1, table)">
        <span>{{ record.description }}</span>
      </div>
      <div [class]="columnClassHelper.getColumnValueClass(2, table)">
        <span>{{ record.example }}</span>
      </div>
      <div [class]="columnClassHelper.getColumnValueClass(3, table)">
        <span>{{ record.errorLogic || 'N/A' }}</span>
      </div>
    </ng-container>
  </ng-container>
</div>