<mat-form-field class="mat-form-field--no-padding" [floatLabel]="'always'">
  <mat-label *ngIf="!ovs.isNullOrEmpty(field.props['label'])">{{
    field.props['label']
  }}</mat-label>
  <input
    (focus)="onFocusEvent($event)"
    (focusout)="onFocusOutEvent($event)"
    type="text"
    matInput
    [formControl]="formControl"
    [placeholder]="''"
    [readonly]="
      ovs.isNullOrUndefined(field.props['readOnly']) ? false : field.props['readOnly']
    "
    [required]="field.props['required']" />
</mat-form-field>
<span class="mat-mdc-form-field-error" role="alert">
  <formly-validation-message
    [field]="field"
    *ngIf="
      formControl.touched && !formControl.valid && field.props['required']
    "></formly-validation-message>

  <div
    class="place-holder"
    *ngIf="!(formControl.touched && !formControl.valid && field.props['required'])">
    &nbsp;
  </div>
</span>
