import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OktaAuthModule } from '@okta/okta-angular';

@Component({
  selector: 'frontend-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
  standalone: true,
  imports: [OktaAuthModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthenticationComponent {}
