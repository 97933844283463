import { Injectable, TemplateRef } from '@angular/core';
import { SubverticalReviewsStatusEnum } from '../models/subvertical-reviews-status.enum';

@Injectable()
export class SubverticalReviewsManagerPrefilterSchemaService {
  getSchema(): any[] {
    return [
      {
        fieldGroupClassName: 'field-group-css-row-1',
        fieldGroup: [
          {
            wrappers: ['icon-wrapper'],
            fieldGroup: [
              {
                key: 'partnerLookup',
                type: 'input',
                defaultValue: '',
                templateOptions: {
                  label: 'Partner'
                },
                className: 'field-css'
              }
            ]
          },
          {
            key: 'statuses',
            type: 'searchable-multi-select',
            defaultValue: [],
            templateOptions: {
              enableMultiSelect: true,
              label: 'Status',
              selectOptions: [
                {
                  value: SubverticalReviewsStatusEnum.Pending,
                  selected: true,
                  display: 'Pending'
                },
                {
                  value: SubverticalReviewsStatusEnum.Approved,
                  selected: false,
                  display: 'Approved'
                },
                {
                  value: SubverticalReviewsStatusEnum.Denied,
                  selected: false,
                  display: 'Denied'
                }
              ]
            },
            className: 'field-css'
          }
        ]
      },

    ];
  }
}
