import { Component } from '@angular/core';

@Component({
  selector: 'app-compliance-checklist',
  templateUrl: './compliance-checklist.component.html',
  styleUrls: ['./compliance-checklist.component.scss', '../documentation.scss']
})
export class ComplianceChecklistComponent {
  afdGuidelines = [
    'Cannot imply an improper value',
    'Cannot represent timelines that cannot be sustained in a bid situation',
    'Cannot assume comparative authority',
    'Cannot misrepresent the ads-only experience (represents more than ads)',
    'Cannot make unsupported or misleading statements of fact',
    'Cannot actively push the user to take action',
    'Images must relate realistically to the headline',
    'Cannot contain inappropriate content in headlines or images'
  ];
  imageSiteList = [
    'Adobe Stock',
    'Deposit Photos',
    'Pixabay',
    'Pexels',
    'iStock Photo',
    'Pxfuel',
    'Shutterstock',
    'PickPik',
    'Peakpx'
  ];

  constructor() {}
}
