import { Component } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-ad-network-pixel-tracking',
  templateUrl: './ad-network-pixel-tracking.component.html',
  styleUrls: [
    './ad-network-pixel-tracking.component.scss',
    '../documentation.scss',
  ],
})
export class AdNetworkPixelTrackingComponent {
  adNetworks = [
    'Facebook',
    'Google Display Network',
    'Taboola',
    'Outbrain',
    'Snapchat',
    'Verizon Media Native (Gemini)',
    'TikTok',
  ];
  pixels = [
    {
      name: 'Facebook Pixel Helper',
      link: 'https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc?hl=en',
    },
    {
      name: 'Taboola Pixel Helper',
      link: 'https://chrome.google.com/webstore/detail/taboola-pixel-helper/aefiepimkogajhddmhcekceihikjcabd?hl=en',
    },
    {
      name: 'Outbrain Pixel Tracker',
      link: 'https://chrome.google.com/webstore/detail/outbrain-pixel-tracker/daebadnaphbiobojnpgcenlkgpihmbdc?hl=en',
    },
    {
      name: 'Snap Pixel Helper',
      link: 'https://chrome.google.com/webstore/detail/snap-pixel-helper/hnlbfcoodjpconffdbddfglilhkhpdnf?',
    },
    {
      name: 'TikTok Pixel Helper',
      link: 'https://chrome.google.com/webstore/detail/tiktok-pixel-helper/aelgobmabdmlfmiblddjfnjodalhidnn?hl=en',
    },
  ];
  table = {
    headers: ['Parameter', 'Description', 'Example'],
    data: [
      {
        header: 'Facebook Pixel',
        subHeader:
          'For server to server tracking, please provide a list of pixels and their corresponding API Access Token ' +
          'to your account manager',
        records: [
          {
            parameter: 'fbid',
            description: 'The ID of the Facebook pixel',
            example: '=1234556748',
          },
          {
            parameter: 'fbland',
            description:
              'The percent-encoded name of the conversion event you ' +
              'would like to fire when the user reaches the System1 ' +
              'campaign lander, spaces substituted with a ‘+’ character',
            example: '=View+Content',
          },
          {
            parameter: 'fbserp',
            description:
              'The percent-encoded name of the conversion event you ' +
              'would like to fire when a user executes a search, spaces ' +
              'substituted with a ‘+’ character',
            example: '=Search',
          },
          {
            parameter: 'fbclick',
            description:
              'The percent-encoded name of the conversion event you ' +
              'would like to fire when a user executes monetizes with an ' +
              'advertiser',
            example: '=Click',
          },
          {
            parameter: 'fbclid',
            description:
              'The click ID that Facebook adds to destination URLs. ' +
              'This is a required parameter in order to launch our S2S ' +
              'integration on your behalf',
            example: '=IwAR0_ZoLApAV3KbGrveMa38rptuB_v9zrbo-z7ToY7aofROkIvHBh',
          },
        ],
      },
      {
        header: 'Google Ads',
        records: [
          {
            parameter: 'gamid',
            description: 'Google Ads Measurement ID for your account',
            example: '=AW-123456789',
          },
          {
            parameter: 'glcid',
            description:
              'The conversion ID associated with the Google Ads ' +
              'conversion you would like to fire on the lander',
            example: '=AW-123456789/AbC-D_dfG-h12_34-567',
          },
          {
            parameter: 'gcid',
            description:
              'The conversion ID associated with the Google Ads conversion ' +
              'you would like to fire on the SERP. This is in addition to, or separate from, ' +
              'the prior glcid parameter',
            example: '=AW-123456789/AbC-D_dfG-h12_34-567',
          },
          {
            parameter: 'gclcid',
            description:
              'Conversion ID associated with the Google Ads conversion you would like to fire ' +
              'when a user monetizes with an advertiser',
            example: '=AW-123456789/AbC-D_efG-h 12_34-567',
          },
          {
            parameter: 'ga4mid',
            description:
              'Optional - an additional Property ID to measure page views and events',
            example: '=G-123456789',
          },
        ],
      },
      {
        header: 'Taboola Pixel',
        records: [
          {
            parameter: 'tbid',
            description: 'The ID of the Taboola pixel',
            example: '=1234556748',
          },
          {
            parameter: 'tbland',
            description:
              'The name of the conversion event you would like to fire ' +
              'when the user reaches the System1 campaign lander, ' +
              'spaces substituted with a ‘+’ character',
            example: '=Land',
          },
          {
            parameter: 'tbserp',
            description:
              'The name of the conversion event you would like to fire ' +
              'when a user executes a search, spaces substituted with a ' +
              '‘+’ character',
            example: '=Search',
          },
          {
            parameter: 'tbclick',
            description:
              'The name of the conversion event you would like to fire ' +
              'when a user monetizes with an advertiser',
            example: '=Purchase',
          },
          {
            parameter: 'tbclickid',
            description:
              'Taboola click ID assigned to the user. This is available on ' +
              'the Taboola platform as the macro {click_id}',
            example: '={click_id}',
          },
        ],
      },
      {
        header: 'Outbrain Pixel',
        records: [
          {
            parameter: 'obid',
            description: 'The ID of the Outbrain pixel',
            example: '=test',
          },
          {
            parameter: 'obland',
            description:
              'The name of the conversion event you would like to fire ' +
              'when the user reaches the System1 campaign lander, ' +
              'spaces substituted with a ‘+’ character',
            example: '=Land',
          },
          {
            parameter: 'obserp',
            description:
              'The name of the conversion event you would like to fire ' +
              'when a user executes a search, spaces substituted with a ' +
              '‘+’ character',
            example: '=Search',
          },
          {
            parameter: 'obclick',
            description:
              'The name of the conversion event you would like to fire' +
              'when a user monetizes with an advertiser',
            example: '=Purchase',
          },
          {
            parameter: 'obclickid',
            description:
              'The Outbrain click ID assigned to the user. This is ' +
              'available on the Outbrain platform as the macro ' +
              '{{ob_click_id}}',
            example: '={{ob_click_id}}',
          },
        ],
      },
      {
        header: 'Snapchat Pixel',
        subHeader:
          'For server to server tracking, please provide a list of pixels and their corresponding API Access Token ' +
          'to your account manager',
        records: [
          {
            parameter: 'scid',
            description: 'The ID of the Snapchat pixel',
            example: '=1abc-2def',
          },
          {
            parameter: 'scland',
            description:
              'The name of the conversion event you would like ' +
              'to fire when the user reaches the System1 campaign lander, ' +
              'spaces substituted with a ‘+’ character',
            example: '=PAGE_VIEW',
          },
          {
            parameter: 'scserp',
            description:
              'The name of the conversion event you would like ' +
              'to fire when a user executes a search, spaces substituted ' +
              'with a ‘+’ character',
            example: '=PURCHASE',
          },
          {
            parameter: 'scclick',
            description:
              'Name of the conversion event to fire when a user monetizes with ' +
              'an advertiser ((requires server to server setup with your ' +
              'System1 account manager)',
            example: '=PURCHASE',
          },
        ],
      },
      {
        header: 'Verizon Media Native (Gemini) Pixel',
        records: [
          {
            parameter: 'ygid',
            description: 'The ID of the Gemini pixel',
            example: '=1abc',
          },
          {
            parameter: 'ygpid',
            description: 'The Project ID corresponding to your pixel',
            example: '=1111',
          },
          {
            parameter: 'ygland',
            description:
              'The name of the conversion event you would like to fire ' +
              'when the user reaches the System1 campaign lander, spaces substituted ' +
              'with a ‘+’ character',
            example: '=PAGE_VIEW',
          },
          {
            parameter: 'ygserp',
            description:
              'The name of the conversion event you would like to ' +
              'fire when a user executes a search, spaces substituted with ' +
              'a ‘+’ character',
            example: '=PURCHASE',
          },
          {
            parameter: 'scclick',
            description:
              'Name of the conversion event to fire when a user monetizes ' +
              'with an advertiser',
            example: '=PURCHASE',
          },
        ],
      },
      {
        header: 'TikTok Pixel',
        subHeader:
          'For server to server tracking, please provide a list of pixels and their corresponding API Access Token ' +
          'to your account manager',
        records: [
          {
            parameter: 'ttid',
            description: 'The ID of the TikTok pixel',
            example: '=1234567890',
          },
          {
            parameter: 'ttland',
            description:
              'Name of the conversion event to fire when the user ' +
              'reaches the System1 campaign lander',
            example: '=ViewContent',
          },
          {
            parameter: 'ttserp',
            description:
              'Name of the conversion event to fire when a user ' +
              'executes a search',
            example: '=AddToWishlist',
          },
          {
            parameter: 'ttclick',
            description:
              'Name of the conversion event to fire when a user ' +
              'monetizes with an advertiser (requires server to server ' +
              'setup with your System1 account manager)',
            example: '=CompletePayment',
          },
          {
            parameter: 'ttclid',
            description:
              'TikTok click ID assigned to the user. You can pass this ' +
              'dynamically through Tiktok',
            example: '=_CLICKID_',
          },
        ],
      },
    ],
  };

  constructor(public ovs: ObjectValidatorService) {}

  getColumnHeaderClass(table, index) {
    return index < table.headers.length - 1
      ? 'documentation-grid column-header column-header__standard'
      : 'documentation-grid column-header column-header__end';
  }

  getColumnValueClass(columnIndex, table) {
    return columnIndex < table.headers.length - 1
      ? 'documentation-grid column-value column-value__standard'
      : 'documentation-grid column-value column-value__end';
  }
}
