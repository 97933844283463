import { Injectable } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class StringFunctionsService {
  constructor(private _ovs: ObjectValidatorService) {}

  replaceCharacters(
    srchList: string[],
    stringValue: string,
    replaceBy: string,
  ): string {
    if (!this._ovs.isNullOrEmpty(stringValue)) {
      srchList.forEach(
        srchItem =>
          (stringValue = stringValue.replace(
            new RegExp('[' + srchItem + ']', 'g'),
            replaceBy,
          )),
      );
    }
    return stringValue;
  }

  replaceStrings(
    srchStringsList: string[],
    value: string,
    replaceBy: string,
  ): string {
    if (!this._ovs.isNullOrEmpty(value)) {
      srchStringsList.forEach(
        item => (value = value.replace(new RegExp(item, 'g'), replaceBy)),
      );
    }
    return value;
  }
}
