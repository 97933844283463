<div class="documentation tab-content">
    <p>
        Welcome to System1's Related Search on Content (RSoC) Guide for Partners! This document will
        guide you through the process of configuring a page on your domain to work with System1's RampJs
        product for RSoC.
    </p>
    <br />
    <p>
        There are {{ columnClassHelper.numToWord(steps.length) }} steps that you'll need to complete before your domain can show RSoC ads to end users:
    </p>
    <ol>
        <li *ngFor="let step of steps">{{ step }}</li>
    </ol>
    <p>Let's get started!</p>
    <h3 class="documentation-sub-header">1. Installing RampJs</h3>
    <app-installing-rampjs></app-installing-rampjs>
    <h3 id="keywords" class="documentation-sub-header">2. Specifying Keywords</h3>
    <app-specifying-keywords></app-specifying-keywords> 
    <h3 id="allow-listed-domains" class="documentation-sub-header">3. Allow Listed Domains</h3>
    <p>
        The last step in getting RSoC ads to show on your page is to allow for the serving of ads from either or both of the following domains:
    </p>
    <ul class="allowlisted-domain-urls">
        <li *ngFor="let domain of allowListedDomainUrls">{{ domain }}</li>
    </ul>
    <p>
        For more information on this requirement, see here: <a href="https://support.google.com/adsense/answer/14201307?hl=en" target="_blank">AdSense for Search and other Search Ads products to use new serving domains and deprecate ad personalization </a>
    </p>
    <hr />
    <h3 id="reporting-and-conversion-tracking" class="documentation-sub-header">4. Reporting and Conversion Tracking</h3>
    <ol>
        <li>
            <app-reporting-parameters [table]="tableReportingParameters"></app-reporting-parameters>
            <p>
                You can think of your page’s URL like an affiliate URL. It will usually follow the below template*:
            </p>
            <div class="code-block">
                <code>
                    <pre>
https://&lbrace;domain.com&rbrace;/&lbrace;article-name&rbrace;?segment=&lbrace;segment&rbrace;&amp;utm_source=&lbrace;buyside&rbrace;&amp;forceKeyA=&lbrace;first_term&rbrace;&amp;forceKeyB=&lbrace;second_term&rbrace;&amp;forceKeyC=&lbrace;third_term&rbrace;&amp;forceKeyD=&lbrace;fourth_term&rbrace;&amp;forceKeyE=&lbrace;fifth_term&rbrace;&amp;forceKeyF=&lbrace;sixth_term&rbrace;&amp;fbid=&lbrace;facebook_pixel_id&rbrace;&amp;fbclick=&amp;s1paid=&lbrace;accountid&rbrace;&amp;s1pcid=&lbrace;campaignid&rbrace;&amp;s1pagid=&lbrace;adgroupid&rbrace;&amp;s1pplacement=&lbrace;placement&rbrace;&amp;s1padid=&lbrace;adid&rbrace;&amp;s1particle=&lbrace;articlename&rbrace; 
                    </pre>
                </code>
            </div>
            <p class="foot-note">*This template contains Facebook Conversion Tracking Parameters.  Other buyside conversion tracking parameters follow the same template and can be found below.</p>
            <br />
            <p>When all of these parameters are filled in with real values, the link might look something like this:</p>
            <div class="code-block">
                <code>
                    <pre>
https://DOMAIN.com/health/understanding-psoriatic-arthritis-symptoms-causes-and-treatment-options?segment=rsocdomain001&amp;utm_source=facebook&amp;forceKeyA=see+early+psoriatic+arthritis+signs+after+50+and+best+new+medications&amp;forceKeyB=see+early+psoriatic+arthritis+signs+after+40+and+best+new+medications&amp;forceKeyC=see+1st+psoriatic+arthritis+signs+after+50+and+best+new+medications&amp;forceKeyD=see+1st+psoriatic+arthritis+signs+after+40+and+best+new+medications&amp;forceKeyE=must+see+early+psoriatic+arthritis+signs+after+50+and+best+new+medications&amp;forceKeyF=arthritis+signs+after+50+and+best+new+medications&amp;fbid=24848139939683&amp;fbclick=clickt&amp;s1paid=1476091026530280&amp;s1pcid=75859678&amp;s1pagid=CA87485&amp;spplacement=FB_feed&amp;s1padid=FB_feed_2x3&amp;s1particle=&lbrace;Top10DentalImplantProviders&rbrace;  
                    </pre>
                </code>
            </div>
            <br />
            <p>The forceKey and headline parameters are discussed in the section on <a class="documentation-link" (click)="navigateToAnchor('keywords')">
                <span class="documentation-link-text">specifying keywords</span></a
              >. The other parameters in this URL are reporting and conversion tracking parameters. The following sections explain what purpose these parameters serve and how they should be used.</p>
        </li>
        <hr />
        <li>
          <app-conversion-tracking-parameters></app-conversion-tracking-parameters>
        </li>
    </ol>
    <hr />
    <app-reporting-api-for-rsoc-online></app-reporting-api-for-rsoc-online>
    <hr />
    <app-accessing-api-reports [possibleReportTypeValues]="possibleReportTypeValues"></app-accessing-api-reports>
</div>