import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationComponent } from './confirmation.component';
import { AngularMaterialModule } from '../../../core/angular-material.module';
import { SearchableSelectControlModule } from '../../../form/searchable-select-control/searchable-select-control.module';
import { LibFormModule } from '../../../form/lib-form.module';
import { MatCommonModule } from '@angular/material/core';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

@NgModule({
  declarations: [ConfirmationComponent],
  exports: [ConfirmationComponent],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    MatCommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormlyModule.forRoot(),
    FormlyMaterialModule,
    LibFormModule,
    SearchableSelectControlModule,
    LibFormModule
  ]
})
export class ConfirmationModule {}
