import { SortMode } from './sort-mode';
import { LoadMethod } from './load-method';
import { GridTabMode } from './grid-tab-mode';
import { FileType } from '../q-services/file-download.service';
import { GridLookups } from './grid-lookups';
import { FilterBoxInit } from '../filter-box/models/filter-box-init';
import { ColumnConfiguration } from './column-configuration';

export class CellGrid {
  constructor(public columns: ColumnConfiguration[]) {}
  // defaulted
  loadMethod: LoadMethod = LoadMethod.Default;
  tabMode: GridTabMode = GridTabMode.AllCells;
  downloadFileType: FileType = FileType.NONE;
  showCancel = false;
  showSubmit = false;
  showClose = false;
  includeRowSelector = false;
  sortMode = SortMode.None;
  includeRowNoColumn = false;
  includeRowExpander = false;
  hideAddRow = true;
  addRowButtonName = 'Add Row';
  bulkOption = true;
  yAxisHeaders: string[];
  lookups: GridLookups;
  filterBoxInit: FilterBoxInit = new FilterBoxInit();
  // used in builder
  editable: (row: any, rows: any[]) => boolean = () => false;
  addRowOnDownArrow: (row: any, rows: any[]) => boolean = () => false;
}
