/* eslint-disable no-empty-pattern */
import { createReducer, on } from '@ngrx/store';
import { IFormState, initialFormsState } from './state';
import { FormsActions } from './actions';
import { FormsFeature } from './form';

export const formsReducer = createReducer<IFormState>(
  initialFormsState as IFormState,
  on(FormsActions.formsRetrieved, (_state, { forms }) => ({ forms: [...forms], loading: false })),

  on(FormsActions.formCreating, (state, {}) => ({ forms: state.forms, loading: true })),
  on(FormsActions.formCreated, (state, { form }) => ({
    forms: [...state.forms, form],
    loading: false
  })),

  on(FormsActions.formSaving, (state, {}) => ({ forms: state.forms, loading: true })),
  on(FormsActions.formSaved, (state, { form }) => ({
    forms: state[FormsFeature].map(stateForm => (stateForm['id'] === form.id ? form : stateForm)),
    loading: false
  }))
);
