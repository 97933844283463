import { ValidationErrors, AbstractControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function MinCharacterLengthValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: any = {}
): ValidationErrors {
  if (control.value == null || control.value == undefined || options.minCharacters == undefined) {
    return {};
  }

  if (options.validateKeywords && options.validateKeywords === true) {
    return control.value
      .split('\n')
      .filter(keyword => keyword)
      .findIndex(keyword => keyword.length < options.minCharacters) > -1
      ? { 'min-character-validation': true }
      : {};
  } else {
    return control.value.length < options.minCharacters ? { 'min-character-validation': true } : {};
  }
}
