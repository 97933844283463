import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PushPipe } from '@ngrx/component';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { Observable, map, switchMap } from 'rxjs';
import { BannerInfo } from './banner-info';
import { ConfigService } from 'core-global-frontend-config';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'lib-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  standalone: true,
  imports: [CommonModule, PushPipe],
})
export class BannerComponent {
  bannerText$!: Observable<string>;
  
  constructor(
    @Inject(OKTA_AUTH) public _oktaAuth: OktaAuth,
    public _webServiceBuilderService: NimbusWebServiceBuilderService,
    public _configService: ConfigService) {
    this._oktaAuth.authStateManager.subscribe(authState => {
      if (authState?.isAuthenticated === false ?? false) {
        return;
      }
      this.bannerText$ = this._configService.configured$.pipe(
        switchMap(() => this._webServiceBuilderService.builder
            .withUrl('banner')
            .build()
            .get({}) as Observable<BannerInfo>
        ),
        map(x => x?.message ?? ''));
    });
  }
}
