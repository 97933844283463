import { Input, Component, OnInit } from '@angular/core';
import { AccessControlService } from '../../features/access-control/access-control.service';
import { Role } from '../../features/access-control/models/role';
import { UserModel } from '../../services/user/user.model';
import { ImpersonationService } from './impersonation.service';
import { ImpersonationWebService } from './impersonation-web.service';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { ConfigService } from '../../core/config.service';
import { FormControl } from '@angular/forms';
import { SearchableListItemWithSelect } from '../../form/searchable-select-control/models/searchable-list-item-with-select';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { GuidGenerator } from '../../core/guid-generator';
import { Observable } from 'rxjs';
import { SortOption } from '../../core/sort.options';

@Component({
  selector: 'lib-impersonation',
  templateUrl: './impersonation.component.html',
  styleUrls: ['./impersonation.component.scss']
})
export class ImpersonationComponent implements OnInit {
  @Input() userSearchableSelectSortOption: SortOption = SortOption.AutoSortWithCustomOption;
  @Input() roleSearchableSelectSortOption: SortOption = SortOption.AutoSort;
  private _users: UserModel[];
  private _roles: Role[];
  private _rBACApplicationsAll = 1;
  private _impersonationUser: UserModel = {
    userId: new GuidGenerator().newGuid(),
    username: 'Impersonation User',
    fullName: 'Impersonation User',
    type: ''
  };

  userControl = new FormControl();
  usersList: SearchableListItemWithSelect[] = [];
  roleControl = new FormControl();
  rolesList: SearchableListItemWithSelect[] = [];
  selectedUserId: string;
  selectedRoleId: number;
  isLoading$: Observable<boolean>;

  get buttonDisabled(): boolean {
    return (
      (this.ovs.isNullOrEmpty(this.selectedUserId) ||
        this.selectedUserId === this._impersonationUser.userId) &&
      (this.ovs.isNullOrEmpty(this.selectedRoleId) || this.selectedRoleId === -1)
    );
  }

  @Input() set isDebug(enabled: boolean) {
    if (
      !enabled &&
      this.impersonationService.isDebug &&
      this.impersonationService.isProxyRolesDefined
    ) {
      this.impersonationService.exit();
    }
    this.impersonationService.isDebug = enabled;
  }

  get isDebug(): boolean {
    return this.impersonationService.isDebug;
  }

  get selectedUser(): UserModel {
    const nullUser = { userId: '', username: '' } as UserModel;
    if (!this.ovs.isDefined(this.selectedUserId)) {
      return nullUser;
    }
    const localUser = this._users.find(user => user.userId === this.selectedUserId);
    return this.ovs.isDefined(localUser) ? localUser : nullUser;
  }

  constructor(
    private _impersonationWebService: ImpersonationWebService,
    public accessControlService: AccessControlService,
    public impersonationService: ImpersonationService,
    private _configService: ConfigService,
    private _snackBar: SnackbarService,
    public ovs: ObjectValidatorService
  ) {
    this.isLoading$ = this._impersonationWebService.apiCallInProgress$;
    this._impersonationWebService.getUsers().subscribe(users => {
      this.usersList = users.map(
        user => new SearchableListItemWithSelect(user.userId, false, user.fullName)
      );
      this.usersList.unshift(
        new SearchableListItemWithSelect(this._impersonationUser.userId, false, 'None')
      );
      this._users = users;
    });
    this._populateRoles();
  }

  ngOnInit(): void {
    this.userControl.valueChanges.subscribe(user => {
      this._populateRoles(user !== this._impersonationUser.userId ? user : null);
    });
    this.roleControl.valueChanges.subscribe(role => {
      this.selectedRoleId = this.ovs.isDefined(role) ? role : null;
    });
  }

  private _populateRoles(userId?: string) {
    this.selectedUserId = userId;
    this.selectedRoleId = -1;
    if (this.ovs.isDefined(userId)) {
      this._impersonationWebService.getRolesBy(userId).subscribe(userInfo => {
        this.rolesList = userInfo.roles
          .filter(role => this._roleHasAccessToApplication(role))
          .map(role => new SearchableListItemWithSelect(role.id, false, role.name));
        this.rolesList.unshift(
          new SearchableListItemWithSelect(-1, false, 'Impersonate Full User')
        );
        this._roles = userInfo.roles;
        this.roleControl.setValue(-1);
      });
      return;
    }
    this._impersonationWebService.getRoles().subscribe(roles => {
      this.rolesList = roles.map(
        role => new SearchableListItemWithSelect(role.id, false, role.name)
      );
      this._roles = roles;
    });
  }

  impersonate() {
    if (!this.ovs.isArray(this._roles) || this._roles.length === 0) {
      this._snackBar.open(`${this.selectedUser.username} has no RBAC roles defined.`);
      return;
    }
    if (!this._rolesHasAccessToApplication()) {
      // This should never happen as the user can only select roles for this application
      this._snackBar.open(`Selected role has no RBAC permissions defined to access
        ${this._configService.getApplicationName()}.`);
      return;
    }
    if (
      this.ovs.isDefined(this.selectedUserId) &&
      (!this.ovs.isDefined(this.selectedRoleId) || this.selectedRoleId === -1)
    ) {
      // Impersonating user with all their assigned roles
      this.impersonationService.setProxies(this.selectedUser, this._roles);
    } else if (
      this.ovs.isDefined(this.selectedUserId) &&
      this.selectedUserId != this._impersonationUser.userId &&
      this.ovs.isDefined(this.selectedRoleId)
    ) {
      // Impersonating user with a single assigned role
      this.impersonationService.setProxies(this.selectedUser, [
        this._roles.find(role => role.id === this.selectedRoleId)
      ]);
    } else if (
      (!this.ovs.isDefined(this.selectedUserId) ||
        this.selectedUserId == this._impersonationUser.userId) &&
      this.ovs.isDefined(this.selectedRoleId)
    ) {
      // Impersonating no user (dummy user) and a single role
      const selectedRole = [this._roles.find(role => role.id === this.selectedRoleId)];
      this.impersonationService.setProxies(this._impersonationUser, [
        this._roles.find(role => role.id === this.selectedRoleId)
      ]);
    }
    window.location.reload();
  }

  private _rolesHasAccessToApplication() {
    return this._roles.some(role => this._roleHasAccessToApplication(role));
  }

  private _roleHasAccessToApplication(role: Role) {
    return (
      this.ovs.isArray(role.permissions) &&
      role.permissions.some(
        perm =>
          this.ovs.isArray(perm.resources) &&
          perm.resources.some(
            resource =>
              resource.applicationId === this._configService.getApplicationId() ||
              resource.applicationId === this._rBACApplicationsAll
          )
      )
    );
  }
}
