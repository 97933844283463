import {
  RangeDisplayType,
  FilterBoxConfiguration,
  FilterBoxCustomComponentType
} from '../filter-box-configuration';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterBoxConfigurationBuilder {
  private _rangeType: RangeDisplayType = RangeDisplayType.None;
  private _showCheck = false;
  private _showExclude = false;
  private _showInclude = true;
  private _showEquals = false;
  private _customComponentType: () => FilterBoxCustomComponentType;

  withRangeType(type: RangeDisplayType) {
    this._rangeType = type;
    return this;
  }

  /**
   * Enables the non-zero check box
   */
  withNonZero() {
    this._showCheck = true;
    return this;
  }

  /**
   * Enables the col excludes field
   */
  withExclude() {
    this._showExclude = true;
    return this;
  }

  /**
   * Disables the col contains field
   */
  withoutInclude() {
    this._showInclude = false;
    return this;
  }

  /**
   * Enables the equals field
   */
  withEquals() {
    this._showEquals = true;
    return this;
  }

  /**
   * Enables the custom field
   */
  withCustomComponent(customComponentType: () => FilterBoxCustomComponentType) {
    this._customComponentType = customComponentType;
    return this;
  }
  build(): FilterBoxConfiguration {
    return new FilterBoxConfiguration(
      this._rangeType,
      this._showCheck,
      this._showExclude,
      this._showEquals,
      this._showInclude,
      this._customComponentType
    );
  }
}
