<div class="documentation tab-content">
  <h3 class="documentation-sub-header">Overview</h3>
  <div>
    The System1 Reporting API provides access to data in your account. This document intends to
    provide specifications of the API endpoints responsible for returning reporting data to you and
    our other partners. All endpoints require authentication in addition to any otherwise optional
    parameters listed.
  </div>
  <br />
  <h3 class="documentation-sub-header">Authentication</h3>
  <div>
    All System1 partners can use our API by requesting access from your Partnership Manager. When
    you ask for access you will receive an email from your Account Manager which includes your API
    key. This API key will provide you and your team access to only reports that are relevant to
    your business needs.
    <br /><br />
    All requests to the reporting API use GET requests. These GET requests can include optional
    query parameters, and must include an authentication parameter for every request. Let's start
    with an example and say that your authentication key is "test_06IDAJuUjUKgsdd". If you want to
    make an API request, you would simply make a GET request to:
    <br />
    <div class="documentation shifted">
      <span class="documentation-highlighted background">
        https://reports.system1.com/v3/&#123;endpoint&#125;?
        auth_key=test_06IDAJuUjUKgsdd&days=&#123;&#125;
      </span>
    </div>
    <span class="documentation italic">Note</span>: the reporting data status call is the same above
    GET request. <br /><br />
    When providing additional parameters to any of these endpoints, the order of query parameters
    does not matter.
  </div>
  <br />
  <h3 class="documentation-sub-header">Data Availability</h3>
  <div>
    Reporting data for a given day will become available at 06:30am PST, on average. System1 report
    availability is dependent on reporting data from upstream third party advertising partners'
    platforms.
  </div>
  <br />
  <h3 class="documentation-sub-header">Rate Limits</h3>
  <div>
    System1 has a rate limit for each individual API call, and on the API as a whole. These limits
    are conservative, as the default API configuration will return all available data from the
    existing email reports. Abuse of the APIs can result in account deactivation, temporary or
    permanently.
    <br /><br />
    Upon hitting the rate limit, you will receive a 429 error that will include a Retry-After
    header, which provides how many seconds you need to wait to make another valid request. In the
    below output example, the caller should wait at least 17 seconds to be able to make another
    request without running into the rate limit again:
    <br />
    <div class="documentation shifted">
      <span class="documentation-highlighted background" *ngFor="let limit of rateLimits">
        {{ limit }}<br />
      </span>
    </div>
    In summary:
    <ul>
      <li *ngFor="let summary of rateSummaries">{{ summary }}</li>
    </ul>
  </div>
  <h3 class="documentation-sub-header">Getting Started - Suggested API usage</h3>
  <div class="documentation-highlighted">Revenue-Based Metrics Disclaimer</div>
  <div class="documentation shifted">
    (Covers all reporting via email and API, including but not limited to columns on revenue, RPC,
    RPS, RPM etc
    <br /><br />
    All reported revenue-based metrics are prior to revenue share calculations.<br />
    All reported revenue-based metrics are based on estimated revenue.
    <br /><br />
    For additional clarity, please contact your partnership manager.
  </div>
  <div class="documentation-highlighted">Possible Data Discrepancy Disclaimer</div>
  <div class="documentation shifted">
    Referencing the negative values in hourly reporting, and referencing internal vs external
    metrics and consistently point back to - Summary is total revenue, subID is only including
    traffic from that passed with a subID, and subID hourly may well be missing data, some reports
    are provided as a guide for granularity indications but should not be relied upon for bidding or
    tROAS conversions, we rewrite revenue backwards up to 7 days on reports XYZ inline with
    adjustments made by upstream engine providers due to latterly identified clawbacks, problematic
    traffic, etc.
    <br /><br />
    For additional clarity, please contact your partnership manager.
  </div>
  <div class="documentation-highlighted">Check Data Status Endpoint</div>
  <div class="documentation shifted">
    In order to generate complete and correct reporting for any given day, System1 must fetch
    reporting data from upstream third party advertising partners' platforms. For this reason, to
    ensure complete and correct reports, System1 will provide all the data available for a given
    day. To confirm if there is complete data for a certain day, please call the data status
    readiness /v3/status endpoint, which only applies to daily endpoints.
    <br /><br />
    /v3/status endpoint returns a JSON array of dates where System1 has complete reporting
    information. Dates that do not show up in this API call will not have any data returned by any
    of the other API calls. For example, you can include the days parameter in your request and
    dictate how many days of data you would like. The days parameters are optional and if not
    provided will return 31 days of data. Additionally, you can specify the number of days you would
    like returned (e.g. days = 10) or you can request specific days (e.g.
    days=2018-01-02,2018-01-01).
    <p>Sample call</p>
    Input:<br />
    <span class="documentation-highlighted background"
      >$ curl "https://reports.system1.com /v3/status?auth_key=test_06IDAJuUjUKgsdd&days=3"</span
    >
    <br /><br />
    Output:<br />
    <span class="documentation-highlighted background">
      ["2020-10-28","2020-10-27","2020-10-26"]
    </span>
    <br /><br />
    The above example returns the last 3 days of
    <span class="documentation bold">complete</span> data. For example, if you hit this endpoint on
    2020-10-29, the output tells you that there is complete data for the last previous 3 days which
    is expected. But if you were to hit the endpoint the morning of 2020-10-30, the above output
    would tell you that there is a delay and data incompleteness in the 2020-10-29 report, so you
    should check back a later time.
  </div>
  <div class="documentation-highlighted">Parameters</div>
  <div class="documentation shifted">
    <span>
      All reports have one (1) specifiable parameter, <span class="documentation bold">days</span>.
    </span>
  </div>
  <div class="documentation-grid-container" [style]="getHeaderCount(parametersTable)">
    <div
      *ngFor="let header of parametersTable.headers; index as i"
      [class]="getColumnHeaderClass(parametersTable, i)">
      <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let record of parametersTable.data">
      <div [class]="getColumnValueClass(0, parametersTable)">
        <span>{{ record.name }}</span>
      </div>
      <div [class]="getColumnValueClass(1, parametersTable)">
        <span>{{ record.function }}</span>
      </div>
    </ng-container>
  </div>
  <div class="documentation shifted">
    <p>Examples</p>
    <div>
      <span class="documentation-highlighted background">days=10</span>
      - get the most recent 10 days, the latest being yesterday PT*<br />
      <span class="documentation-highlighted background">days=2018-01-02,2018-01-01</span>
      - get the 2 dates of data specified*<br />
      <span class="documentation-highlighted background"
        >days=2018-01-03,2018-01-02,2018-01-01</span
      >
      - get the 3 dates of data specified*<br /><br />
      <span class="documentation italic">
        *Subid reports can not be pulled for more than 1 day at a time, so partners will need to
        pull for separate days if they want to see multiple days’ subID performance.
      </span>
    </div>
  </div>
  <h3 class="documentation-sub-header">Data Glossary</h3>
  <div class="documentation-grid-container" [style]="getHeaderCount(dataGlossaryTable)">
    <div
      *ngFor="let header of dataGlossaryTable.headers; index as i"
      [class]="getColumnHeaderClass(dataGlossaryTable, i)">
      <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let record of dataGlossaryTable.data">
      <div [class]="getColumnValueClass(0, dataGlossaryTable)">
        <span>{{ record.attribute }}</span>
      </div>
      <div [class]="getColumnValueClass(1, dataGlossaryTable)">
        <span>{{ record.definition }}</span>
      </div>
    </ng-container>
  </div>
  <h3 class="documentation-sub-header">Hosted/Embedded Reports</h3>
  <div>
    System1 offers 7 types of standard reports, usually available to SEM partners.<br />
    <ul>
      <li *ngFor="let report of standardReports">{{ report }}</li>
    </ul>
  </div>
  <div>
    <ol>
      <li>
        Search Performance (note: this report can be pulled on the channel level)
        <div class="documentation shifted">
          This is a daily endpoint for the search performance daily report broken down by date,
          segment, country, and device type.
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com/v3/search_performance?
            auth_key=test_06IDAJuUjUKgsdd&days=3"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background"
            >[["Date","Segment","Country","Device Type", "Sessions","W/W%
            Sessions","Impressions","W/W% Impressions","Pageviews", "W/W% Pageviews","Paid
            Clicks","W/W% Paid Clicks","Nonpaid Clicks", "W/W% Nonpaid Clicks","Total Clicks","W/W%
            Total Clicks","Revenue", "W/W% Revenue","Paid CTR","W/W% Paid CTR","Total CTR","W/W%
            Total CTR", "RPM","W/W% RPM","RPC","W/W% RPC","Pageviews Per Session", "W/W% Pageviews
            Per Session"],["2020-11-01","segment1","ZA","desktop",
            5,400,4,100,5,400,0,100,0,100,0,100,0,100,0,100,0,1 00,0,100,0,100,1,0],
            ["2020-11-02","segment1","IE","mobile",1,100,1,100,1,100,0,100,0,100,0, 100,0,100,
            0,100,0,100,0,100,0,100,1,100],["2020-11-03","segment3","PT",
            "desktop",1,100,1,100,1,100,0,100,0,100, 0,100,0,100,0,100,0,100,0,100, 0,100,1,100]]
          </span>
          <br /><br />
          The above example returns the last 3 days of complete data.
        </div>
      </li>
      <li>
        Top Keywords
        <div class="documentation shifted">
          This is a daily endpoint for the Top Keyword Performance report, which includes keyword
          data aggregated at the segment level based on paid clicks.
          <br />
          There is a query limit and the tiers are:
        </div>
        <div class="documentation-grid-container" [style]="getHeaderCount(keywordsTierTable)">
          <div
            *ngFor="let header of keywordsTierTable.headers; index as i"
            [class]="getColumnHeaderClass(keywordsTierTable, i)">
            <span class="documentation bold">{{ header }}</span>
          </div>
          <ng-container *ngFor="let record of keywordsTierTable.data">
            <div [class]="getColumnValueClass(0, keywordsTierTable)">
              <span>{{ record.segment_count }}</span>
            </div>
            <div [class]="getColumnValueClass(1, keywordsTierTable)">
              <span>{{ record.keywords_returned }}</span>
            </div>
          </ng-container>
        </div>
        <div class="documentation shifted">
          Returns a JSON array of arrays, each sub-array representing a row from the 'Top Keywords'
          report. The first array returned defines the fields used in the subsequent arrays (the
          header row).<br />
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com/v3/top_keywords.json?auth_key=test_06IDAJuUjUKgsdd
            &days=’2020-11-02’"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background"
            >[["Date","Segment","Top Keywords by Paid Clicks","Impressions","Pageviews","Paid
            Clicks","Nonpaid Clicks","Paid Clicks Per Impression","Total Clicks Per
            Impression","Paid Clicks Per Pageview","Total Clicks Per
            Pageview"],["2020-11-02","segment1","Buy cheap SUV
            2020",1,1,1,0,100,100,100,100],["2020-11-02","segment2","Mid-size SUVs in
            2020",1,1,1,0,100,100,100,100]]</span
          >
          <br /><br />
          The above example returns the top keywords for the day of ‘2020-11-02’.
        </div>
      </li>
      <li>
        Search Breakdown
        <div class="documentation shifted">
          This daily endpoint is another version of the search performance report but using System1
          internally tracked metrics.
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com/v3/search_breakdown?
            auth_key=test_06IDAJuUjUKgsdd&days=1"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background"
            >[["Date","Segment","Pageviews", "Total Clicks","Nonpaid Clicks","Paid Clicks","Paid
            CTR", "Initial Pageviews","Initial Pageview Nonpaid Clicks", "Initial Pageview Paid
            Clicks","Initial Pageview Paid CTR", "Subsequent Pageviews","Subsequent Pageview Nonpaid
            Clicks", "Subsequent Pageview Paid Clicks","Subsequent Pageview Paid CTR", "Subsequent
            Pageviews Per Session","AYLF Paid Clicks","AYLF Paid CTR"],
            ["2020-11-02","segment1",1006,327,114,213,28.71,740,48,124,16.76,266,
            66,104,39.1,2.29,89,55.28] ,["2020-11-02","segment2",32,6,1,5,23.81,
            21,0,1,4.76,11,1,4,36.36,1.83,2,25],["2020-11-02","segment3" ,77638,
            29750,10472,19278,34.13,56258,5017,13342,23.72,21380,5455,8261,38.64, 2.18,6199,44.43]]
          </span>
          <br /><br />
          The above example returns the search breakdown of the most recent day.
        </div>
      </li>
      <li>
        Non Rev SubID
        <div class="documentation shifted">
          This is an hourly endpoint to track internal metrics on a pre-defined parameter. This
          report does not have revenue reporting since there are more values than we have
          channels/tags. This is useful for tracking parameters with many values such as the browser
          version, app installation id, unique user id, user agent, or date of installation.
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com
            /v3/non_rev_subid?auth_key=test_06IDAJuUjUKgsdd&days=1"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background"
            >[["Date","Segment","Device Type", "Country","Non-Rev
            Subid","Sessions","Pageviews","Impressions", "Paid Clicks","Non-Paid
            Clicks"],["2020-11-01","segment1",
            "desktop","US",&#123;"subagid1":"1357897348552809","subaid1":"4M65", "sub
            cid1":"316890501"&#125;,18,21,21,10,1],["2020-11-01","segment2",
            "desktop","US",&#123;"subagid2":"13611959204558 04","subaid2":"QAT8",
            "subcid2":"407142009"&#125;,1,1,1,2,0],["2020-11-01","segment3","tablet",
            "US",&#123;"subag id3":"1348001743908064","subaid3":"4M65","subcid3":
            "316890479"&#125;,1,1,1,1,0]]
          </span>
          <br /><br />
          The above example returns the non rev subID performance report of 2020-11-01.
        </div>
      </li>
      <li>
        SubId
        <div class="documentation shifted">
          This is an hourly endpoint to track revenue metrics on a pre-defined parameter. We have 2
          subID setups- most common is via the SERP URL, however we also have a set up where the
          partner can pass this in the page code via SDK. We don't support both methods
          concurrently. This report broken down by hour is just an ESTIMATE. It is subject to change
          and should not be considered final. It can be used as a helpful tool, but should not be
          relied on to make bidding decisions like for tROAS.
          <br /><br />
          This has more limitations than non-rev subID, since each value will use a channel/tag. So,
          there cannot be more values that get traffic per day than the number of available
          channels/tags. This is useful for tracking parameters such as buy-side campaigns or
          adgroupids.
          <br /><br />
          This is a reminder that subID reports can not be pulled for more than 1 day at a time, so
          partners will need to pull for separate days if they want to see multiple days’ subID
          performance.
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com
            /v3/subid?auth_key=test_06IDAJuUjUKgsdd&days=2020-11-01"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background"
            >[["Date","Hour","Segment","Device",
            "Country","Subid","Sessions","Pageviews","Impressions","Paid Clicks",
            "Revenue"],["2020-11-01","15","segment1","desktop","US",
            &#123;"subagid1":"110788641514","subaid1": "0462","subcid1":"10975366740"&#125;,
            5,7,7,3,0.74],["2020-11-01","17","segment2","desktop","US", &#123;"subagid2
            ":"110522282154","subaid2":"0462","subcid2":"10945585819"&#125;,
            0,0,0,0,0],["2020-11-01","23","segment3"," desktop","US",
            &#123;"subagid3":"1352399790598756","subaid3":"QAT8","subcid3":"316890451"&#125;,
            1,1,0,0,0]]
          </span>
          <br /><br />
          The above example returns the subID performance report of 2020-11-01.
        </div>
      </li>
      <li>
        subID Daily Performance
        <div class="documentation shifted">
          Since the subID hourly sometimes shows negative values when the data is not complete, we
          now have a subid performance report that rolls up into a calculated daily view. While
          subid hourly grain data is <span class="documentation bold">estimated</span> data using
          the daily number provided by the network, subID daily has data provided by the network
          without any estimation. Hourly rolled up to daily should be close in values, but not
          exact. SubID daily is considered more accurate.
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com
            /v3/subid_daily?auth_key=test_06IDAJuUjUKgsdd&days=1"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background"
            >[["Date","Segment","Country", "Device Type","Sessions","W/W% Sessions","Impressions",
            "W/W% Impressions","Pageviews","W/W% Pageviews","Paid Clicks", "W/W% Paid
            Clicks","Nonpaid Clicks","W/W% Nonpaid Clicks","Total Clicks", "W/W% Total
            Clicks","Revenue","W/W% Revenue","Paid CTR", "W/W% Paid CTR","Total CTR","W/W% Total
            CTR","RPM","W/W% RPM", "RPC","W/W% RPC","Pageviews Per Session","W/W% Pageviews Per
            Session"], ["2020-12-07","segment1","FR","mobile",1,-66.67,0,-100,1,-75,0,
            100,0,100,0,100,0,100,0,100,0,100,0,100,0,100,1,-24.81],["2020-12-07",
            "segment2","US","desktop",2538,13.61,3549,6.45,3584,6.38,11 61,4.59,
            731,-8.51,1892,-0.89,1873.21,88.01,0.46,-8,0.75,-11.76,738.07,65.49,
            1.61,78.89,1.41,-6.62]]
          </span>
        </div>
      </li>
      <li>
        Search Performance Hourly
        <div class="documentation shifted">
          This gives you an hourly breakdown of the current day’s performance data. Data provided in
          our hourly API is an ESTIMATE. It is subject to change and should not be considered final.
          It can be used as a helpful tool, but should not be relied on to make bidding decisions.
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com
            /v3/search_performance_hourly?auth_key=test_06IDAJuUjUKgsdd&days=1"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background"
            >[["Date","Hour","Segment", "Device Type","Sessions","W/W% Sessions","Impressions",
            "W/W% Impressions","Pageviews","W/W% Pageviews","Paid Clicks", "W/W% Paid
            Clicks","Nonpaid Clicks","W/W% Nonpaid Clicks", "Total Clicks","W/W% Total
            Clicks","Revenue","W/W% Revenue", "Paid CTR","W/W% Paid CTR","Total CTR","W/W% Total
            CTR","RPM", "W/W% RPM","RPC","W/W% RPC","Pageviews Per Session", "W/W% Pageviews Per
            Session"],["2020-11-01","4","segment2",
            "other",1212,-32.48,1198,-32.55,1212,-32.48,0,null,0,null,0,null,
            0,null,0,null,0,null,0,null,null,null,1,0],["2020-11-01","7","segment2",
            "desktop",8,-27.27,15,25,1 5,15.38,5,25,3,200,8,60,0.51,-45.74,62.5,
            71.89,100,120.02,63.73,-25.3,0.1,-56.52,1.88,59.32],["2020-1 1-01",
            "10","segment3","desktop",1,null,1,null,1,null,0,null,0,null,0,
            null,0,null,0,null,0,null,0,null ,null,null,1,null]]
          </span>
          <br /><br />
          The above example returns the Search Performance Hourly report of 2020-11-01.
        </div>
      </li>
    </ol>
  </div>
  <h3 class="documentation-sub-header">Forwarded Reports</h3>
  <div>Bing and Yahoo reports usually used by DLA /Extension partners.</div>
  <br />
  <div>
    <ol>
      <li>
        Ptag
        <div class="documentation shifted">
          This is a daily endpoint. Short for “Partner Tag” - This represents Bing’s “forwarded”
          experience, where System1 redirects the user to a Bing Hosted Search page (arguably a
          replica of a bing.com search experience). This report may be a few days late due to the
          nature of the Bing API. The data is subject to change and should not be considered final
          (Bing may take back revenue/data based on what they recognize as bot traffic).
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com
            /v3/ptag?auth_key=test_06IDAJuUjUKgsdd&days=’2020-11-01’"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background"
            >[["Date","Segment","Device", "Country","Pageviews","Impressions","Paid
            Clicks","Revenue"], ["2020-11-01","segment1","mobile","GR",3,1,0,0],["2020-11-01",
            "segment2","mobile", "SA",1,0,0,0],["2020-11-01","segment3", "desktop","GB",1,0,0,0]]
          </span>
          <br /><br />
          The above example returns the ptag report of 2020-11-01.
        </div>
      </li>
      <li>
        Yahoo Hosted
        <div class="documentation shifted">
          This is a daily endpoint. Yahoo Hosted is a “forwarded” experience, where System1
          redirects the user to a Yahoo Hosted Search page (arguably a replica of a yahoo.com search
          experience). The data is subject to change and should not be considered final (Yahoo may
          take back revenue/data based on what they recognize as bot traffic).
          <p>Sample call</p>
          Input:<br />
          <span class="documentation-highlighted background"
            >$ curl "https://reports.system1.com
            /v3/yahoo_hosted?auth_key=test_06IDAJuUjUKgsdd&days=’2020-11-01’"</span
          >
          <br /><br />
          Output:<br />
          <span class="documentation-highlighted background">
            [["Date","Segment","Device","Country","Pageviews","Impressions", "Paid
            Clicks","Revenue"],["2020-11-01","segment1","desktop",
            "NL",13,25,0,0],["2020-11-01","segment2","desktop","CL",6,33,1,0.01],
            ["2020-11-01","segment3","desktop","PH",55,36,0,0]]
          </span>
          <br /><br />
          The above example returns the yahoo hosted report of 2020-11-01.
        </div>
      </li>
    </ol>
  </div>
</div>
