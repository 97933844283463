import { Component, ChangeDetectionStrategy } from '@angular/core';
import { TopOptionsTogglerCacheService } from './top-options-toggler-cache.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-top-options-toggler',
  templateUrl: './top-options-toggler.component.html',
  styleUrls: ['./top-options-toggler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopOptionsTogglerComponent {
  constructor(public topOptionsTogglerCacheService: TopOptionsTogglerCacheService) {}
}
