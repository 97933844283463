import { Injectable } from '@angular/core';
import {
  IEntitiesLoader,
  IEntityLoader,
  IEntityDeleter,
  IEntityUpdater,
  IEntityCreator
} from '../../core/ientities';

// The prefilters and the dock are outside of the page.
// To keep those decoupled from the services provided by the pages,
// We use this 1:n service proxy.
// A prefered D.I. pattern would have been prefered but solution not found.
@Injectable()
export class LayoutPageServicesProxy {
  entitiesLoaders: { [id: string /* page path */]: IEntitiesLoader } = {};
  entityLoaders: { [id: string /* page path */]: IEntityLoader } = {};
  entityDeleters: { [id: string /* page path */]: IEntityDeleter } = {};
  entityUpdaters: { [id: string /* page path */]: IEntityUpdater } = {};
  entityCreators: { [id: string /* page path */]: IEntityCreator } = {};

  clear() {
    this.entitiesLoaders = {};
    this.entityLoaders = {};
    this.entityDeleters = {};
    this.entityUpdaters = {};
    this.entityCreators = {};
  }
}
