import { Observable, of } from 'rxjs';
import { IWebClient } from './iweb-client';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PaginationProperties {
  properties = ['limit', 'totalResults', 'skip'];
  properties$ = of(this.properties);
}

//temp until the backend get fixes.  After that we'll only keep one of the 2 IPagination.
export interface IPaginationRequest {
  TotalResults: number;
  Limit: number;
  Skip: number;
}

export interface IPaginationResponse {
  totalResults: number;
  limit: number;
  skip: number;
}

export interface IEntitiesLoader<T> extends IWebClient {
  filter(params?: any): Observable<T[]>;
  entities$: Observable<T[]>;
}

export interface IEntityLoader<T> extends IWebClient {
  get(params: any): Observable<T>;
  entity$: Observable<T>;
}

export interface IEntityLoaderAsync<T> extends IWebClient {
  getAsync(params: any): void;
  entity$: Observable<T>;
}

export interface IEntityDeleter extends IWebClient {
  delete(id: unknown): Observable<unknown>;
}

export interface IEntityDeleterAsync extends IWebClient {
  deleteAsync(id: unknown): void;
}

export interface IEntityUpdater extends IWebClient {
  save(entity: unknown): Observable<unknown>;
}

export interface IEntityUpdaterAsync extends IWebClient {
  saveAsync(entity: unknown): void;
}

// returns the Prefilters id parameter(s) as Observable:
// {id: '23'}, or {accountId:'11', providerId:'2'} in case of a combined key.
// Prefilters works with strings
export interface IEntityCreator extends IWebClient {
  create(id: any): Observable<any>;
}

export interface IEntityCreatorAsync extends IWebClient {
  createAsync(id: any): void;
}
