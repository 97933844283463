import { ApplicationRef, Component, Injectable, Input, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GenericWebService, GenericWebServiceBuilder } from '../../../core/generic-web.service';
import { SortOption } from '../../../core/sort.options';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { SearchableListItemWithSelect } from '../../../form/searchable-select-control/models/searchable-list-item-with-select';

// This service is expected to live all the application life.  No need to clear it.
//This should not be reused.  It is private meant to be used only for the SearchableMultiSelectComponent.
@Injectable()
export class SearchableMultiSelectSharedDataService {
  private readonly _datas: Map<string, Observable<any[]>> = new Map<string, Observable<any[]>>();

  register(key: string, observable$: Observable<any>): void {
    this._datas[key] = this._datas[key] ?? observable$;
    return this._datas[key];
  }

  get<T>(key: string): Observable<T> {
    return this._datas[key];
  }
}

@Component({
  selector: 'lib-multi-select',
  templateUrl: './searchable-multi-select.component.html',
  styleUrls: ['./searchable-multi-select.component.scss']
})
export class SearchableMultiSelectComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy {
  @Input() searchableSelectSortOption: SortOption = SortOption.None;
  private _genericWebService: GenericWebService;

  selectOptions$: Observable<any[]>;
  isLoading$: Observable<boolean>;
  private readonly _subscriptionManager = new SubscriptionManager();
  private _cacheKey: any;

  constructor(
    private _searchableMultiSelectSharedDataService: SearchableMultiSelectSharedDataService,
    public _ovs: ObjectValidatorService,
    private _genericWebServiceBuilder: GenericWebServiceBuilder,
    private _applicationRef: ApplicationRef
  ) {
    super();
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  ngOnInit(): void {
    this._cacheKey =
      this.field.props['endpoint'] +
      this.field.props['api'] +
      (this.field.props['idProperty'] ?? '') +
      (this.field.props['nameProperty'] ?? '');
    this._genericWebService = this._genericWebServiceBuilder
      .withApi(this.field.props['endpoint'], this.field.props['api'])
      .withCaching()
      .build();

    this.isLoading$ = this._genericWebService.apiCallInProgress$;
    const first =
      undefined ===
      this._searchableMultiSelectSharedDataService.get<SearchableListItemWithSelect[]>(
        this._cacheKey
      );
    if (first) {
      this._searchableMultiSelectSharedDataService.register(
        this._cacheKey,
        this._genericWebService.entities$
      );
    }
    this.selectOptions$ = this._selectOptions(
      this._searchableMultiSelectSharedDataService.get<any[]>(this._cacheKey)
    );
    if (first && this._ovs.isDefined(this.field.props['api'])) {
      this._subscriptionManager.register(this._genericWebService.getCollection().subscribe());
    }
  }

  private _removeEmptyAndInvalidValues(entities: any[]): any[] {
    const newList = entities.filter(entity => !this._ovs.isNullOrEmpty(entity));
    if (this._ovs.isNullOrEmpty(this.field.props['idProperty'])) {
      return newList;
    }
    if (this._ovs.isNullOrEmpty(this.field.props['nameProperty'])) {
      throw new Error('nameProperty is required when idProperty is defined');
    }
    return newList.filter(
      entity =>
        !this._ovs.isNullOrEmpty(entity[this.field.props['idProperty']]) &&
        !this._ovs.isNullOrEmpty(entity[this.field.props['nameProperty']])
    );
  }

  private _selectOptions(observable$: Observable<any[]> | undefined): Observable<any> {
    if (this._ovs.isDefined(this.field.props['selectOptions'])) {
      return of(this.field.props['selectOptions']);
      // } else if (this._ovs.isDefined(this.field.props['selectOptions$'])) {
      //   return this.field.props['selectOptions$'];
    } else if (
      this._ovs.isDefined(this.field.props['api']) &&
      this._ovs.isDefined(this.field.props['endpoint']) &&
      this._ovs.isDefined(observable$)
    ) {
      return observable$.pipe(
        map(collection => this._removeEmptyAndInvalidValues(collection)),
        map(collection =>
          collection.map(
            entity =>
              new SearchableListItemWithSelect(
                this._ovs.isNullOrEmpty(this.field.props['idProperty'])
                  ? entity
                  : this._ovs.isDefined(this.field.props?.['idAsString']) &&
                    this.field.props?.['idAsString'] === false
                    ? entity[this.field.props['idProperty']]
                    : entity[this.field.props['idProperty']].toString(),
                false,
                this._ovs.isNullOrEmpty(this.field.props['nameProperty'])
                  ? entity
                  : this._ovs.isDefined(this.field.props?.['idAsString']) &&
                    this.field.props?.['idAsString'] === false
                    ? entity[this.field.props['nameProperty']]
                    : entity[this.field.props['nameProperty']].toString()
              )
          )
        ),
        map(options =>
          this._ovs.isDefined(this.field.props['customSelection'])
            ? [
              new SearchableListItemWithSelect(this.field.props['customSelection']),
              ...(options as SearchableListItemWithSelect[])
            ]
            : options
        ),
        tap(_options => this._applicationRef.tick)
      );
    } else {
      return of(null);
    }
  }
}
