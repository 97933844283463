import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { ObjectValidatorService } from '../object-validator-service/object-validator.service';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';

export interface IValidationError {
  errors: { [id: string]: string[] };
}

export interface IValidationErrorResponse {
  error: IValidationError;
  reported: boolean;
}

@Injectable()
export class ValidationErrorService {
  constructor(private _snackbarService: SnackbarService, private _ovs: ObjectValidatorService) {}

  displaySupportedErrors(errorResponse: IValidationErrorResponse): Observable<any> {
    if (!this._ovs.isPathNullOrEmpty(errorResponse, ['error', 'errors'])) {
      Object.keys(errorResponse.error.errors).forEach(key =>
        errorResponse.error.errors[key].forEach(errorMessage =>
          this._snackbarService.open(key + ' error: ' + errorMessage, SnackBarType.error)
        )
      );
      errorResponse.reported = true;
      return throwError(errorResponse);
    }
    return throwError(errorResponse);
  }
}
