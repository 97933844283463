<div class="container" title="review subvertical request">
  <svg
    *ngIf="isReviewPendingOrDenied"
    [class]="{ enabled: true }"
    role="img"
    (click)="$event.stopPropagation(); setMode(true)">
    <use xlink:href="assets/img/sprites.svg#partner-pencil-icon" />
  </svg>
  <svg
    *ngIf="!isReviewPendingOrDenied"
    [class]="{ enabled: true }"
    role="img"
    (click)="$event.stopPropagation(); setMode(false)">
    <use xlink:href="assets/img/sprites.svg#eye-icon" />
  </svg>
</div>
