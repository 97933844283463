import { Injectable } from '@angular/core';
import { LocalStorageKey } from './local-storage-key.model';
import { ILocalStorageService } from './ilocal-storage-service';
import { LoggerMethodEvent, LoggingService } from '../logging-service/logging.service';

@Injectable()
export class QLocalStorageService implements ILocalStorageService {
  constructor(private loggingService: LoggingService) {}

  get(key: string): string {
    return localStorage.getItem(key);
  }

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  getKey(key: LocalStorageKey): string {
    return localStorage.getItem(JSON.stringify(key));
  }

  setKey(key: LocalStorageKey, value: string) {
    try {
      localStorage.setItem(JSON.stringify(key), value);
    } catch {
      this.loggingService.log(
        'QLocalStorageService',
        'setKey',
        LoggerMethodEvent.Error,
        'Local storage running out of space.'
      );
    }
  }

  delete(key: string) {
    localStorage.removeItem(key);
  }

  deleteKey(key: LocalStorageKey) {
    localStorage.removeItem(JSON.stringify(key));
  }
}
