import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-date-header',
  templateUrl: './date-header.component.html',
  styleUrls: ['./date-header.component.scss'],
})
export class DateHeaderComponent extends FieldType<FieldTypeConfig> {
  constructor(public ovs: ObjectValidatorService) {
    super();
  }
}
