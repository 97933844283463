import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GUIDBuilder } from '../../q-models/guid-builder';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable({ providedIn: 'root' })
export class BackdropService {
  static readonly id = `GridLookupBackdrop-` + GUIDBuilder.build();
  private _backdropElement: HTMLDivElement;
  onBackdropClose: Subject<MouseEvent> = new Subject<MouseEvent>();
  onPanelOpen: Subject<void> = new Subject<void>();
  onPanelClose: Subject<void> = new Subject<void>();

  constructor(private _ovs: ObjectValidatorService) {
    this._backdropElement = this._createOrGet();
    //Untrack
    this.onPanelOpen.pipe(debounceTime(200)).subscribe(() => this._show());
    //Untrack
    this.onPanelClose.subscribe(() => this._hide());
  }

  private _createOrGet() {
    let element = document.getElementById(BackdropService.id) as HTMLDivElement;
    if (!this._ovs.isDefined(element)) {
      element = document.createElement('div');
      document.body.appendChild(element);
    }
    this.setBackdropStyle(element, BackdropService.id);
    return element;
  }

  private setBackdropStyle(element: HTMLDivElement, id: string) {
    element.id = id;
    element.style.position = 'fixed';
    element.style.left = '0';
    element.style.top = '0';
    element.style.width = '100%';
    element.style.height = '0';
    element.style.opacity = '0';
    element.style.zIndex = '999';
    element.hidden = true;
    element.onclick = event => {
      this._hide();
      this.onBackdropClose.next(event);
    };
  }

  private _hide() {
    this._backdropElement.hidden = true;
    this._backdropElement.style.height = '0';
  }

  private _show() {
    this._backdropElement.hidden = false;
    this._backdropElement.style.height = '100%';
  }
}
