import { QGrid } from '../q-models/q-grid';
import { QRow } from '../q-models/q-row';
import {
  ColumnConfiguration,
  ColumnType,
} from '../q-models/column-configuration';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { PluginType } from '../q-models/plugin-type';
import { StringFunctionsService } from '../shared/string-functions.service';
import { ICellFilter } from '../icell-filter';

export class GridValue {
  constructor(
    private _stringFuncs: StringFunctionsService,
    public ovs: ObjectValidatorService,
    public items: QGrid,
    public columns: ColumnConfiguration[],
    private _hasFilter: () => boolean,
  ) {}

  getCellValue(
    sourceColumn: ColumnConfiguration,
    fieldName: string,
    row: QRow,
    targetColNo: number = null,
  ): any {
    if (fieldName === ColumnConfiguration.qGridRowSelectorFieldName) {
      return row.isSelected;
    }
    let value = row.value[fieldName];
    if (
      !this.ovs.isDefined(value) &&
      this._hasFilter() &&
      !this.ovs.isNullOrEmpty(sourceColumn.cellPlugins)
    ) {
      const filter = sourceColumn.cellPlugins.find(
        c => c.pluginType === PluginType.Filter,
      ) as ICellFilter;
      if (this.ovs.isDefined(filter)) {
        value = filter.filter(this.items.fullList, fieldName, row);
      }
    }
    return this.ovs.isDefined(targetColNo)
      ? this.cleanseValueIfTargetColumnIsNumberType(targetColNo, value)
      : value;
  }

  cleanseValueIfTargetColumnIsNumberType(
    targetColNo: number,
    nextSrcColValue: any,
  ): any {
    const colType = this.columns.find(
      c => c.visibleColumnNumber === targetColNo,
    ).columnType;
    if (colType !== ColumnType.Number) {
      return nextSrcColValue;
    }
    return this.cleanseNumberValue(nextSrcColValue);
  }

  cleanseNumberValue(value: any): any {
    if (!this.ovs.isDefined(value)) {
      return '';
    }
    let stringValue = ('' + value).toString();
    const srchList = ['%', '$', ',', '\t'];
    stringValue = this._stringFuncs.replaceCharacters(
      srchList,
      stringValue,
      '',
    );
    const numValue = Number.parseFloat(stringValue);
    return isNaN(numValue) ? '' : numValue;
  }
}
