import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { SubjectManager, SubjectType } from '@nimbus/shared-lib';
import { map, tap } from 'rxjs/operators';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { GatewayService } from './services/gateway.service';
import { TableauTokenModel } from '../../../shared/authentication/models/tableau-token.model';

@Component({
  selector: 'app-tableau',
  templateUrl: './tableau.component.html',
  styleUrls: ['./tableau.component.scss']
})
export class TableauComponent implements OnInit {
  @ViewChild('vizContainer', { static: true }) private _vizContainer: ElementRef<HTMLDivElement>;
  private _subscriptionManager = new SubscriptionManager();
  subjectManager = new SubjectManager();
  syndicationAccountURL = (environment as any).tableauUrl + '/PartnerSyndication-AccountSummary_16657720038320/AccountSummary';

  constructor(
    private _gatewayService: GatewayService,
    private _route: ActivatedRoute
  ) {
    this.subjectManager.register('firstvizsizeknown', SubjectType.BehaviourSubject, false);
    this.subjectManager.register('onFirstInteractive', SubjectType.BehaviourSubject, false);
  }

  ngOnInit() {
    const reportUrl = (environment as any).tableauUrl + '/' + this._route.snapshot.data.tableau.path;
    const showToolbar =
      !environment.production &&
      (reportUrl === this.syndicationAccountURL);
    this._subscriptionManager.registerMultiple([
      this._gatewayService.get('tableau-token').pipe(
        map((token, index) => new TableauTokenModel(index, JSON.parse(JSON.stringify(token))).token)
      ).pipe(
        tap(token => {
          this._vizContainer.nativeElement.innerHTML = `<tableau-viz id="tableauViz"
                    src="${reportUrl}"
                    token="${token[this._route.snapshot.data.tableau.tokenType]}"
                    device="default"
                    toolbar="${!showToolbar ? 'hidden' : 'top'}"
                    hide-tabs
                    width="calc(100vw - var(--sidenav-width) - 4rem)"
                    height="calc(100vh - 5rem)"
                    disable-url-actions="true"
                    showVizHome="no"
                    highDpi="true"
                    tabs="no"
                    firstvizsizeknown
                    customviewloaded
                    firstinteractive>
                  </tableau-viz>`;
        })
      )
        .subscribe(() => this.CheckIfIFrameLoaded()),
    ]);
  }

  private CheckIfIFrameLoaded() {
    const iframe = document.getElementById('tableauViz');
    iframe.addEventListener('firstvizsizeknown', () => {
      this.subjectManager.next('firstvizsizeknown', true);
    });
    iframe.addEventListener('firstinteractive', () => {
      this.subjectManager.next('onFirstInteractive', true)
    });
  }
}
