import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormBuilder,
  ReactiveFormsModule,
  FormsModule,
} from '@angular/forms';
import { Router } from '@angular/router';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { ActionService } from '../../action.service';
import { AngularMaterialModule } from '../../../angular-material/angular-material.module';
import { CommonModule } from '@angular/common';
import { MatCommonModule } from '@angular/material/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { NimbusFormlyCommonModule } from 'core-global-frontend-form-nimbus-formly-common';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'action-type',
  templateUrl: './action-type.component.html',
  styleUrls: ['./action-type.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FormlyModule,
    FormlyMaterialModule,
    MatCommonModule,
    NimbusFormlyCommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
  ],
})
export class ActionTypeComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  validators: typeof Validators = Validators;
  internalForm = this._formBuilder.group({});

  constructor(
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _actionService: ActionService<unknown>,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.props['url']) {
      return;
    }
    this._actionService.register(this.props['action']);
  }

  action() {
    if (this.props['url']) {
      this._router.navigate([this.props['url']]);
      return;
    }
    this._actionService.next(this.props['action']);
  }
}
