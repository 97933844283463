import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'lib-object-type',
  templateUrl: './object-type.component.html',
  styleUrls: ['./object-type.component.scss']
})
export class ObjectTypeComponent extends FieldType implements OnInit {
  simpleFields = [];
  complexFields = [];

  ngOnInit(): void {
    this.complexFields = this.field.fieldGroup.filter(x => this._isComplexField(x));
    this.simpleFields = this.field.fieldGroup.filter(x => !this._isComplexField(x));
  }

  private _isComplexField(fieldConfig: FormlyFieldConfig) {
    return (
      fieldConfig.type === 'multischema' ||
      fieldConfig.type === 'array' ||
      fieldConfig.type === 'object'
    );
  }
}
