import { Injectable } from '@angular/core';
import { UserContextEntry } from '../models/user-context-entry';
import { Division } from '../models/division.model';
import { Site } from '../models/site.model';
import { Group } from '../models/group.model';
import { Subject, Observable } from 'rxjs';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { LocalStorageProxyBuilder } from '../../../core/local-storage/local-storage-proxy-builder';
import { LocalStorageProxy } from '../../../core/local-storage/local-storage-proxy';
import { IClearableSensitiveData } from '../../../core/iclearable-cache';
import { SessionManagerService } from '../../../services/session-manager/session-manager.service';
import { GuidGenerator } from '../../../core/guid-generator';
import { Domain } from '../models/domain.model';
import { SearchableListItemWithSelect } from '../../../form/searchable-select-control/models/searchable-list-item-with-select';

export class ContextServiceState {
  divisionName: string;
  groupName: string;
  siteName: string;
}

@Injectable()
export class ContextService implements IClearableSensitiveData {
  guid = new GuidGenerator().newGuid();
  private readonly _objectName = 'ContextService';
  private _all = 'All';
  private _eventChangeSubject = new Subject<number[]>();
  siteUpdated = new Subject<string>();
  eventChanged: Observable<number[]>;
  appliedSelectedDomainName = this._all;
  appliedSelectedSiteName = this._all;
  appliedSelectedGroupName = this._all;
  appliedSelectedDivisionName = this._all;
  private _localStorageProxy: LocalStorageProxy;
  private _hiddenSiteIds: number[] = [13619]; // don't show the default mapping site as its not a valid selection

  private _selectedDivisionName = this._all;
  get selectedDivisionName(): string {
    return this._selectedDivisionName;
  }
  set selectedDivisionName(divisionName: string) {
    this._selectedDivisionName = divisionName;
    this._selectedGroupName = this._all;
    this._selectedSiteName = this._all;
    this._selectedDomainName = this._all;
  }

  private _selectedGroupName = this._all;
  get selectedGroupName(): string {
    return this._selectedGroupName;
  }
  set selectedGroupName(groupName: string) {
    this._selectedGroupName = groupName;
    this._selectedSiteName = this._all;
    this._selectedDomainName = this._all;
  }

  private _selectedSiteName = this._all;
  get selectedSiteName(): string {
    return this._selectedSiteName;
  }
  set selectedSiteName(siteName: string) {
    this._selectedSiteName = siteName;
    this._selectedDomainName = this._all;
  }

  private _selectedDomainName = this._all;
  get selectedDomainName(): string {
    return this._selectedDomainName;
  }
  set selectedDomainName(domainName: string) {
    this._selectedDomainName = domainName;
  }

  private _userContextEntries: UserContextEntry[] = [];
  get userContextEntries(): UserContextEntry[] {
    return this._userContextEntries;
  }
  set userContextEntries(ar: UserContextEntry[]) {
    this._userContextEntries = ar;
    this.applySelection();
  }

  constructor(
    private _ovs: ObjectValidatorService,
    protected _sessionManagerService: SessionManagerService,
    _localStorageProxyBuilder: LocalStorageProxyBuilder
  ) {
    this._localStorageProxy = _localStorageProxyBuilder.build(
      this._objectName,
      {
        divisionName: null,
        groupName: null,
        siteName: null
      },
      null
    );
    this.eventChanged = this._eventChangeSubject.asObservable();
    this.initState();
    _sessionManagerService.subscribe(this.guid, () => this.clearAfterLogout());
  }

  clearAfterLogout(): void {
    this._localStorageProxy.delete();
  }

  initState() {
    const contextServiceState = this._localStorageProxy.retrieve<ContextServiceState>();
    if (this._ovs.isDefined(contextServiceState)) {
      this._selectedDomainName = contextServiceState.domainName;
      this._selectedSiteName = contextServiceState.siteName;
      this._selectedGroupName = contextServiceState.groupName;
      this._selectedDivisionName = contextServiceState.divisionName;
      this.appliedSelectedDomainName = this.selectedDomainName;
      this.appliedSelectedSiteName = this.selectedSiteName;
      this.appliedSelectedGroupName = this.selectedGroupName;
      this.appliedSelectedDivisionName = this.selectedDivisionName;
    }
  }

  hasPendingChanges(): boolean {
    return !(
      this.appliedSelectedDomainName === this.selectedDomainName &&
      this.appliedSelectedSiteName === this.selectedSiteName &&
      this.appliedSelectedGroupName === this.selectedGroupName &&
      this.appliedSelectedDivisionName === this.selectedDivisionName
    );
  }

  applySelection() {
    const content = new ContextServiceState();
    content.divisionName = this.selectedDomainName;
    content.siteName = this.selectedSiteName;
    content.groupName = this.selectedGroupName;
    content.divisionName = this.selectedDivisionName;
    this.appliedSelectedDomainName = this.selectedDomainName;
    this.appliedSelectedSiteName = this.selectedSiteName;
    this.appliedSelectedGroupName = this.selectedGroupName;
    this.appliedSelectedDivisionName = this.selectedDivisionName;
    this._localStorageProxy.saveState(content);

    // Run listeners
    // Multiple pages could need to be notified when a selection changes even if they do not have the focus.
    // For instance, the options available in the Create new Rule page are site specific.
    //  Therefore, if the site changes, the curent model being under edit might need to be wiped out and
    //  options to be re-requested again.
    // Note that there is no listener deregistration so far, so only register service that are global to the application
    //  (not scoped to a specific compoment, otherwise it would get disposed when the component is disposed and that will
    //   cause exceptions here).
    this._eventChangeSubject.next(this.getSiteIds());
  }

  isUntouched(): boolean {
    return (
      this.appliedSelectedDomainName === this.selectedDomainName &&
      this.appliedSelectedSiteName === this.selectedSiteName &&
      this.appliedSelectedGroupName === this.selectedGroupName &&
      this.appliedSelectedDivisionName === this.selectedDivisionName
    );
  }

  isEmpty(): boolean {
    return this.userContextEntries.length === 0;
  }

  divisionNames(): SearchableListItemWithSelect[] {
    const list: SearchableListItemWithSelect[] = this._userContextEntries
      .map(item => ({ name: item.divisionName, order: item.divisionSortOrder }))
      .filter(
        (value, index, self) =>
          self.map(x => x.name).indexOf(value.name) === index && value.name !== this._all
      )
      .sort((a, b) => {
        return a.order === b.order ? (a.name < b.name ? -1 : 1) : a.order < b.order ? -1 : 1;
      })
      .map(x => new SearchableListItemWithSelect(x.name, false, x.name));
    list.splice(0, 0, new SearchableListItemWithSelect(this._all, false, this._all));
    return list;
  }

  groupNames(): SearchableListItemWithSelect[] {
    const list = this._userContextEntries
      .filter(value => value.divisionName === this._selectedDivisionName)
      .map(item => item.groupName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(group => new SearchableListItemWithSelect(group, false, group));
    list.splice(0, 0, new SearchableListItemWithSelect(this._all, false, this._all));
    return list;
  }

  siteNames(isActive: boolean): string[] {
    const list = this._userContextEntries
      .filter(
        value =>
          value.divisionName === this._selectedDivisionName &&
          value.isRecentlyUsed === isActive &&
          !this._hiddenSiteIds.includes(value.siteId) &&
          (value.groupName === this._selectedGroupName || this._selectedGroupName === this._all)
      )
      .map(item => item.siteName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b));
    list.splice(0, 0, this._all);
    return list;
  }

  domainNames(): SearchableListItemWithSelect[] {
    const list = this._userContextEntries
      .filter(
        value =>
          value.divisionName === this._selectedDivisionName &&
          (value.groupName === this._selectedGroupName || this._selectedGroupName === this._all) &&
          (value.siteName === this._selectedSiteName || this._selectedSiteName === this._all)
      )
      .map(item => item.domainName)
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.localeCompare(b))
      .map(domain => new SearchableListItemWithSelect(domain, false, domain));
    list.splice(0, 0, new SearchableListItemWithSelect(this._all, false, this._all));
    return list;
  }

  getCurrentDivision(): Division {
    const index = this._userContextEntries
      .map(item => item.divisionName)
      .indexOf(this.selectedDivisionName);
    if (index === -1) {
      return new Division(-1, 'Loading...', -1);
    }
    return new Division(
      this._userContextEntries[index].id,
      this._userContextEntries[index].divisionName,
      this._userContextEntries[index].divisionSortOrder
    );
  }

  getCurrentGroup(): Group {
    if (this._selectedGroupName === this._all) {
      return new Group('', this._all, 0);
    }

    const foundAt = this._userContextEntries
      .map(item => item.groupName)
      .indexOf(this.selectedGroupName);

    return new Group(
      this._userContextEntries[foundAt].groupId,
      this._userContextEntries[foundAt].groupName,
      this._userContextEntries[foundAt].groupSortOrder
    );
  }

  getCurrentSite(): Site {
    if (this._selectedSiteName === this._all) {
      return new Site(-1, this._all);
    }
    const foundSite = this._userContextEntries.find(
      userContextEntry => userContextEntry.siteName === this.selectedSiteName
    );
    return !foundSite ? new Site(-1, this._all) : new Site(foundSite.siteId, foundSite.siteName);
  }

  getCurrentDomain(): Domain {
    if (this._selectedDomainName === this._all) {
      return new Domain(-1, this._all);
    }
    const foundDomain = this._userContextEntries.find(
      userContextEntry => userContextEntry.domainName === this.selectedDomainName
    );
    return !foundDomain
      ? new Domain(-1, this._all)
      : new Domain(foundDomain.domainId, foundDomain.domainName);
  }

  getSelectedSites(isActive: boolean): Site[] {
    if (this.appliedSelectedSiteName === this._all) {
      return this._userContextEntries
        .filter(
          value =>
            value.divisionName === this._selectedDivisionName &&
            value.isRecentlyUsed === isActive &&
            (value.groupName === this._selectedGroupName || this._selectedGroupName === this._all)
        )
        .map(item => {
          return {
            siteId: item.siteId,
            siteName: item.siteName
          };
        });
    }
    const entry = this._userContextEntries.find(e => e.siteName === this.selectedSiteName);
    return entry.isRecentlyUsed === isActive ? [new Site(entry.siteId, entry.siteName)] : [];
  }

  getSiteIds(): number[] {
    if (this.appliedSelectedSiteName === this._all) {
      const list = this._userContextEntries.filter(
        value =>
          value.divisionName === this._selectedDivisionName &&
          (value.groupName === this._selectedGroupName || this._selectedGroupName === this._all)
      );
      if (this._ovs.isNullOrEmpty(list)) {
        return [];
      }
      const names = list
        .map(item => item.siteId)
        .filter((value, valueIndex, self) => self.indexOf(value) === valueIndex);
      return this._ovs.isNullOrEmpty(names) ? [] : names;
    }
    const index = this._userContextEntries
      .map(item => item.siteName)
      .indexOf(this.appliedSelectedSiteName);
    return index === -1 ? [] : [this._userContextEntries[index].siteId];
  }

  hasValidSelection(): boolean {
    return (
      this.appliedSelectedSiteName !== this._all ||
      this.appliedSelectedGroupName !== this._all ||
      this.appliedSelectedDivisionName !== this._all
    );
  }

  getSites(): Site[] {
    const list = this._userContextEntries
      .filter(
        value =>
          value.divisionName === this._selectedDivisionName &&
          (value.groupName === this._selectedGroupName || this._selectedGroupName === this._all)
      )
      .map(item => ({ siteName: item.siteName, siteId: item.siteId }))
      .filter((value, index, self) => self.indexOf(value) === index)
      .sort((a, b) => a.siteName.localeCompare(b.siteName));
    list.splice(0, 0, { siteName: this._all, siteId: -1 });
    return list;
  }

  selectSiteById(siteId) {
    const contextEntry = this._userContextEntries.find(entry => entry.siteId === siteId);
    this.selectedDivisionName = contextEntry.divisionName;
    this.selectedGroupName = contextEntry.groupName;
    this.selectedSiteName = contextEntry.siteName;
    this.applySelection();
    return this.selectedSiteName;
  }

  overwriteContextFromService(params: any): any {
    params['divisions'] = this.selectedDivisionName;
    params['groups'] = this.selectedGroupName;
    params['sites'] = this.selectedSiteName;
    return params;
  }
}
