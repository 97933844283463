import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'linkset-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeywordsComponent extends FieldType {
  constructor(public ovs: ObjectValidatorService) {
    super();
  }

  formcontrol(): any {
    return this.formControl as any;
  }
}
