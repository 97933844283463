import { Injectable } from '@angular/core';
import { ContextService } from './context.service';
import { ContextWebService } from './context-web.service';
import { Observable, combineLatest, of } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { UserContextEntry } from '../models/user-context-entry';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { LocalStorageKeyFactory } from '../../../core/local-storage/local-storage-key-factory';
import { LocalStorageManagerService } from '../../../core/local-storage/local-storage-manager.service';
import { LocalStorageKey } from '../../../core/local-storage/local-storage-key.model';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { SessionManagerService } from '../../../services/session-manager/session-manager.service';
import { IClearableSensitiveData } from '../../../core/iclearable-cache';
import { GuidGenerator } from '../../../core/guid-generator';
import { TokenCacheService } from '../../../services/user/token-cache.service';
import { ConfigService } from '../../../core/config.service';

// This class's responsability is to
//  provide the UserContextEntry[] by ensuring that
//  they will be coming from the memory storage is available,
//  if not, from the localStorage or finally from the web service.
//  But it also manage retrieving the content and saving it on the cache service class.
@Injectable()
export class ContextFactoryService implements IClearableSensitiveData {
  guid = new GuidGenerator().newGuid();
  classVersion = '2.0.0';

  constructor(
    private _contextService: ContextService,
    private _tokenCacheService: TokenCacheService,
    private _configService: ConfigService,
    private _ovs: ObjectValidatorService,
    private _localStorageKeyFactory: LocalStorageKeyFactory,
    protected _sessionManagerService: SessionManagerService,
    private _localStorageService: LocalStorageManagerService,
    private _snackbarService: SnackbarService,
    private _contextWebService: ContextWebService
  ) {
    _sessionManagerService.subscribe(this.guid, () => this.clearAfterLogout());
  }

  // Getting the context via the factory ensure that you'll get
  //  the collection from the best location and always initialized.
  //  But since the context is always pre initialized from the guard,
  //  We can probably get it via the cache service directly in every components,
  //   Or component's caching service (anything that doesn't get the collection at the init time).
  getContext(hardRefresh: boolean = false): Observable<UserContextEntry[]> {
    if (hardRefresh) {
      this._localStorageService.deleteKey(this._key());
      return this.getFromService();
    }
    if (this._ovs.isNullOrEmpty(this._contextService.userContextEntries) === false) {
      return of(this._contextService.userContextEntries);
    }

    const userContextFromLocalStorage = this._localStorageService.getKey(this._key());
    if (this._ovs.isNullOrEmpty(userContextFromLocalStorage) === false) {
      this._contextService.userContextEntries = JSON.parse(userContextFromLocalStorage);
      return of(this._contextService.userContextEntries);
    }

    return this.getFromService();
  }

  private _key(): LocalStorageKey {
    return this._localStorageKeyFactory.build('ContextFactoryService', this.classVersion);
  }

  private getFromService(): Observable<UserContextEntry[]> {
    return combineLatest([
      this._configService.configured$,
      this._tokenCacheService.userHasAuthenticated$
    ]).pipe(
      switchMap(() => this._contextWebService.getUserContext()),
      map(userContextEntries => {
        let userContext = null;
        try {
          userContext = JSON.stringify(userContextEntries);
        } catch {
          this._snackbarService.open(
            'Problem with deserializing the response.',
            SnackBarType.error
          );
        }
        this._localStorageService.setKey(this._key(), userContext);
        this._contextService.userContextEntries = userContextEntries;
        return this._contextService.userContextEntries;
      }),
      catchError(() => {
        this._snackbarService.open(
          'An error has occurred while loading the accounts list',
          SnackBarType.error
        );
        return of([]);
      })
    );
  }

  updateSiteName(siteId: number, newSiteName: string) {
    this.getContext().subscribe(
      response => {
        response.forEach(userContextItem => {
          if (userContextItem.siteId === siteId) {
            userContextItem.siteName = newSiteName;
          }
        });
        this._localStorageService.setKey(this._key(), JSON.stringify(response));
        this._contextService.userContextEntries = response;
        this._contextService.siteUpdated.next(newSiteName);
      },
      errorResponse => {
        this._snackbarService.open(
          'Loading Users: An error has occurred: ' + errorResponse.error.message,
          SnackBarType.error
        );
      }
    );
  }

  clearAfterLogout(): void {
    this._localStorageService.deleteKey(this._key());
  }
}
