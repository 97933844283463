import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { KeyboardAction } from '../q-models/keyboard-action';
import { KeyboardActions } from '../q-models/keyboard-actions';
import { GridClipboard } from '../q-models/grid-clipboard';
import { GUIDBuilder } from '../q-models/guid-builder';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class GridKeyboardService implements OnDestroy {
  private _keydownSubject = new Subject<KeyboardEvent>();
  private _keydown$ = this._keydownSubject.asObservable();
  private _keypressSubject = new Subject<KeyboardEvent>();
  private _keypress$ = this._keypressSubject.asObservable();

  private _keyActionSubject: Subject<KeyboardAction> = null;
  private _clipboard: GridClipboard;
  private readonly _defaultGUID: string;
  private _focusedgridGUID: string;
  private _readOnly = false;
  private _acceptableElementNames: string[] = ['g-grid'];
  onLostKeyboard: Subject<string> = null;

  constructor(private _ovs: ObjectValidatorService) {
    this._defaultGUID = this.generateGUID();
    document.body.addEventListener('keydown', event => {
      if (_ovs.isDefined(this._keyActionSubject)) {
        this._keydownSubject.next(event);
      }
    });
    document.body.addEventListener('keypress', event => {
      if (_ovs.isDefined(this._keyActionSubject)) {
        this._keypressSubject.next(event);
      }
    });
    //Untrack
    this._keydown$.subscribe(event => this._keydown(event));
    //Untrack
    this._keypress$.subscribe(event => this._keypress(event));
    document.body.addEventListener('mousedown', event => {
      let x = null;
      this._acceptableElementNames.forEach((elementName: string) => {
        x = (event.target as any).closest(elementName);
        if (x) {
          return;
        }
      });
      if (!x) {
        // if null, then pause sending keystrokes
        this.pauseSendingKeyStrokes();
      }
    });

    this._focusedgridGUID = this._defaultGUID;
    this.onLostKeyboard = new Subject<string>();
  }

  ngOnDestroy(): void {
    this._unsubscribeKeyAction();
    this._focusedgridGUID = this._defaultGUID;
  }

  public generateGUID(): string {
    return GUIDBuilder.build();
  }

  public setAcceptableElementName(elementName: string) {
    if (!this._acceptableElementNames.includes(elementName)) {
      this._acceptableElementNames.push(elementName);
    }
  }

  public getExclusiveObservable(
    gridGUID: string = this._defaultGUID,
    readOnly: boolean = false,
    clipboard: GridClipboard = null,
  ): Observable<KeyboardAction> {
    this._readOnly = readOnly;
    // the Table already got exclusive access
    this._prepareNewSubscription(gridGUID);
    // return new subscription
    this._keyActionSubject = new Subject<KeyboardAction>();
    this._clipboard = clipboard;
    return this._keyActionSubject.asObservable();
  }

  public pauseSendingKeyStrokes() {
    this._prepareNewSubscription(this._defaultGUID);
  }

  private _prepareNewSubscription(activegridGUID: string) {
    this._focusedgridGUID = activegridGUID;
    // unsubscribe existing componentE
    this._unsubscribeKeyAction();
    // sending lost keyboard message
    this.onLostKeyboard.next(activegridGUID);
  }

  hasExclusiveAccess(gridGUID: string): boolean {
    return this._focusedgridGUID === gridGUID;
  }

  private _unsubscribeKeyAction() {
    if (this._ovs.isDefined(this._keyActionSubject)) {
      this._keyActionSubject.unsubscribe();
      this._keyActionSubject = null;
    }
  }

  private _keydown(event: KeyboardEvent) {
    const ctrlOrMeta = event.ctrlKey || event.metaKey;
    switch (event.code) {
      case 'ArrowLeft':
        event.stopPropagation();
        this._keyActionSubject.next(
          new KeyboardAction(
            KeyboardActions.LeftArrow,
            event.shiftKey,
            ctrlOrMeta,
            null,
            () => event.preventDefault(),
          ),
        );
        break;
      case 'ArrowUp':
        event.stopPropagation();
        this._keyActionSubject.next(
          new KeyboardAction(
            KeyboardActions.UpArrow,
            event.shiftKey,
            ctrlOrMeta,
            null,
            () => event.preventDefault(),
          ),
        );
        break;
      case 'ArrowRight':
        event.stopPropagation();
        this._keyActionSubject.next(
          new KeyboardAction(
            KeyboardActions.RightArrow,
            event.shiftKey,
            ctrlOrMeta,
            null,
            () => event.preventDefault(),
          ),
        );
        break;
      case 'ArrowDown':
        event.stopPropagation();
        this._keyActionSubject.next(
          new KeyboardAction(
            KeyboardActions.DownArrow,
            event.shiftKey,
            ctrlOrMeta,
            null,
            () => event.preventDefault(),
          ),
        );
        break;
      case 'Home':
        event.stopPropagation();
        this._keyActionSubject.next(
          new KeyboardAction(
            KeyboardActions.Home,
            event.shiftKey,
            ctrlOrMeta,
            null,
            () => event.preventDefault(),
          ),
        );
        break;
      case 'End':
        event.stopPropagation();
        this._keyActionSubject.next(
          new KeyboardAction(
            KeyboardActions.End,
            event.shiftKey,
            ctrlOrMeta,
            null,
            () => event.preventDefault(),
          ),
        );
        break;
      case 'KeyA': // select all (Ctrl+A Ctrl+a)
        if (ctrlOrMeta) {
          event.stopPropagation();
          this._keyActionSubject.next(
            new KeyboardAction(
              KeyboardActions.SelectAll,
              event.shiftKey,
              true,
              null,
              () => event.preventDefault(),
            ),
          );
        }
        break;
      case 'KeyC': // copy (Ctrl+C Ctrl+c)
        if (ctrlOrMeta && this._clipboard) {
          event.stopPropagation();
          this._keyActionSubject.next(
            new KeyboardAction(
              KeyboardActions.Copy,
              event.shiftKey,
              true,
              null,
              () => event.preventDefault(),
            ),
          );
        }
        break;
      case 'KeyV': // copy (Ctrl+V Ctrl+v)
        if (ctrlOrMeta && this._clipboard) {
          if (this._readOnly) {
            return;
          }
          event.stopPropagation();
          this._keyActionSubject.next(
            new KeyboardAction(
              KeyboardActions.Paste,
              event.shiftKey,
              true,
              null,
              () => event.preventDefault(),
            ),
          );
        }
        break;
      case 'KeyZ': // copy (Ctrl+Z Ctrl+z)
        if (ctrlOrMeta) {
          if (this._readOnly) {
            return;
          }
          event.stopPropagation();
          this._keyActionSubject.next(
            new KeyboardAction(
              KeyboardActions.Undo,
              event.shiftKey,
              true,
              null,
              () => event.preventDefault(),
            ),
          );
        }
        break;
      case 'Backspace':
      case 'Delete':
        if (this._readOnly) {
          return;
        }
        event.stopPropagation();
        this._keyActionSubject.next(new KeyboardAction(KeyboardActions.Delete));
        break;
      case 'Escape':
        event.stopPropagation();
        this._keyActionSubject.next(new KeyboardAction(KeyboardActions.Escape));
        break;
      case 'Enter':
      case 'NumpadEnter':
        event.stopPropagation();
        this._keyActionSubject.next(new KeyboardAction(KeyboardActions.Enter));
        break;
      case 'F2':
        if (this._readOnly) {
          return;
        }
        event.stopPropagation();
        this._keyActionSubject.next(
          new KeyboardAction(KeyboardActions.EnableTyping),
        );
        break;
      case 'Tab':
        event.stopPropagation();
        this._keyActionSubject.next(
          new KeyboardAction(
            KeyboardActions.Tab,
            event.shiftKey,
            true,
            null,
            () => event.preventDefault(),
          ),
        );
        break;
      default:
        this._keypress(event);
        break;
    }
  }

  private _keypress(event: KeyboardEvent) {
    if (this._readOnly && event.code !== 'Space') {
      return;
    }
    // all printable keys
    if (
      event.key.length === 1 &&
      event.key.charCodeAt(0) >= 32 &&
      event.key.charCodeAt(0) <= 126 &&
      !event.metaKey &&
      !event.ctrlKey &&
      !event.altKey
    ) {
      this._keyActionSubject.next(
        new KeyboardAction(
          KeyboardActions.Typing,
          event.shiftKey,
          false,
          event.key,
          () => event.preventDefault(),
        ),
      );
    }
  }
}
