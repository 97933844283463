<ng-container>
  <h2 class="header">
    <svg role="img" class="icon icon_spacing">
      <use xlink:href="assets/img/sprites.svg#partner-review" />
    </svg>
    <span class="font-weight-bold">Review Subvertical Request</span>
  </h2>
</ng-container>
<div [class]="{ 'review-container': true, 'gray-loader': (isLoading$ | async) }">
  <ng-container *ngIf="!(this.selectedSubverticalReview | callbackRunnerPipe: ovs.isNullOrEmpty)">
    <form class="review-form" [formGroup]="form" id="ngForm" #documentEditForm="ngForm">
      <formly-form
        [form]="form"
        [fields]="fields"
        [model]="formModel"
        (modelChange)="onModelChange(formModel)"></formly-form>
    </form>
    <span class="subvertical-review-button">
      <button
        type="submit"
        (click)="$event.stopPropagation(); submitRequest(true)"
        [disabled]="!form.valid"
        form="ngForm"
        class="subvertical-review-button approved">
        Approve
      </button>
      <button
        type="submit"
        (click)="$event.stopPropagation(); submitRequest(false)"
        form="ngForm"
        class="subvertical-review-button denied">
        Deny
      </button>
    </span>
  </ng-container>
</div>
