import { Injectable } from '@angular/core';
import { LoggingService, LoggerMethodEvent } from '../../core/logging-service/logging.service';
import { MessageHubService } from '../../core/message-hub.service';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';

@Injectable({
  providedIn: 'root'
})
export class NewBuildNotifierService {
  constructor(
    _messageHubService: MessageHubService,
    _loggingService: LoggingService,
    _snackbarService: SnackbarService
  ) {
    _messageHubService.add('newconsolebuild', () => {
      _loggingService.log(MessageHubService.name, 'newconsolebuild', LoggerMethodEvent.Information);
      _snackbarService.open(
        'A new build is available. Please reload the page to use the new build.',
        SnackBarType.important
      );
    });
  }
}
