<mat-tab-group #tabGroup (selectedIndexChange)="updateIndex($event)">
  <mat-tab *ngFor="let tab of field.fieldGroup; let i = index">
    <ng-template mat-tab-label>
      <svg
        role="img"
        [class]="i === this.tabGroup.selectedIndex ? 'tab-icon selected' : 'tab-icon'">
        <use [attr.xlink:href]="'assets/img/sprites.svg#' + tab.props['icon']" />
      </svg>
      {{ tab.props['label'] }}
      <svg
        *ngIf="!isValid(tab)"
        [matTooltip]="invalidTabMessage"
        role="img"
        class="formly-field-input svg invalid">
        <use xlink:href="assets/img/sprites.svg#warning" />
      </svg>
    </ng-template>
    <formly-field [class]="tab.props['css']" [field]="tab"></formly-field>
  </mat-tab>
</mat-tab-group>
