export enum KeyboardActions {
  UpArrow,
  LeftArrow,
  DownArrow,
  RightArrow,
  Home,
  End,
  SelectAll,
  Copy,
  Paste,
  Undo,
  Delete,
  Escape,
  Enter,
  Typing,
  EnableTyping,
  Tab
}
