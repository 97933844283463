import { Injectable } from '@angular/core';
import { SubjectManager } from '@nimbus/global-frontend-subscription-manager';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class AnchorService {
  private _subjectManager = new SubjectManager();

  get anchorLocation$(): Observable<any> {
    return this._subjectManager.get('navigationEvent');
  }

  constructor() {
    this._subjectManager.register('navigationEvent');
  }

  updateAnchorLocation(location: string) {
    this._subjectManager.next('navigationEvent', { a: location });
  }
}