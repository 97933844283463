import { ChangeTracker } from './change-tracker';
import { QRow } from './q-row';
import { QGrid } from './q-grid';
import { ChangeEmitType } from './change-emit-type';
import { QGridComponent } from '../q-grid/q-grid.component';

export class GridTableAccess {
  private items: QGrid;
  private refreshSubject: any;
  private changeTracker: ChangeTracker;

  setItems(items: QGrid) {
    this.items = items;
  }

  setRefresher(refreshSubject: any) {
    this.refreshSubject = refreshSubject;
  }

  setChangeTracker(changeTracker: ChangeTracker) {
    this.changeTracker = changeTracker;
  }

  getItems(): QGrid {
    return this.items;
  }

  refreshTable() {
    this.refreshSubject.next(null);
  }

  trackChanges(row: QRow, changeType: ChangeEmitType) {
    this.changeTracker.track(row, changeType);
  }

  download(allPages: boolean = false) {
    (this.items.qGridComponent as QGridComponent).fileDownload(allPages);
  }
}
