import { Pipe, PipeTransform } from '@angular/core';
import { ObjectValidatorService } from '../object-validator-service/object-validator.service';

@Pipe({ name: 'callbackRunnerPipe' })
export class CallbackRunnerPipe implements PipeTransform {
  constructor(private _ovs: ObjectValidatorService) {}
  transform(value: any, callback: (value: any) => any): any {
    if (this._ovs.isFunction(callback)) {
      return callback(value);
    }
  }
}
