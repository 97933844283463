<div class="mat-elevation-z8 table-container">
  <mat-card>
    <mat-card-content>
      <frontend-actions-bar
        *ngIf="showOptionsBar"
        [useExternalDownload]="useExternalDownload"
        [showRefreshButton]="showRefreshButton"
        [refreshButtonText]="refreshButtonText"
        (onRefreshButtonClicked)="refreshGrid()"
        (download)="download($event)"
      ></frontend-actions-bar>
      <frontend-grid-presentation
        *ngIf="
          (dataSource$ | ngrxPush) &&
          (columns$ | ngrxPush) &&
          editable !== undefined
        "
        [dataSource]="dataSourceAsAny$ | ngrxPush"
        [columns]="columns$ | ngrxPush"
        [editable]="editable"
        [disableComponentsCache]="disableComponentsCache"
        [disableSubmitForUnchanged]="disableSubmitForUnchanged"
        (submitEvent)="submitEvent.emit($event)"
        [rowInfo]="rowInfo"
        [formArray]="gridDatasourceBuilder.formArray"
        (sortEvent)="sort($event)"
        (matPaginator)="paginator = $event"
        (pageChange)="pageChanged($event)"
        [submitButtonLabel]="submitButtonLabel"
        [pageSize]="pageSize"
        [serversidePagination]="serversidePagination"
        [dataLength]="dataLength"
        [dataId]="dataId"
        [defaultSort]="defaultSort"
      ></frontend-grid-presentation>
      <mat-progress-spinner
        *ngIf="!(dataSource$ | ngrxPush)"
        color="primary"
        mode="indeterminate"
      ></mat-progress-spinner>
    </mat-card-content>
  </mat-card>
</div>
