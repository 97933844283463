import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../../core/angular-material.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { ContextComponent } from './component/context.component';
import { ContextService } from './services/context.service';
import { ContextWebService } from './services/context-web.service';
import { ContextFactoryService } from './services/context-factory.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HasAccountContextGuard } from './services/has-account-context-guard.service';
import { DynamicButtonModule } from '../../form/dynamic-button/dynamic-button.module';
import { SearchableSelectControlModule } from '../../form/searchable-select-control/searchable-select-control.module';

@NgModule({
  declarations: [ContextComponent],
  exports: [ContextComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    MatInputModule,
    MatAutocompleteModule,
    MatOptionModule,
    DynamicButtonModule,
    SearchableSelectControlModule
  ],
  providers: [
    MatDialog,
    ContextService,
    ContextWebService,
    ContextFactoryService,
    HasAccountContextGuard
  ]
})
export class ContextModule {}
