import { Component, OnInit } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-icon-wrapper',
  templateUrl: './icon-wrapper.html',
  styleUrls: ['./icon-wrapper.scss']
})
export class IconWrapperComponent extends FieldWrapper {
  constructor() {
    super();
  }
}
