import { Injectable } from '@angular/core';
import { SubjectManager, SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { Observable } from 'rxjs';

@Injectable()
export class SubverticalRequestsHistoryDockService {
  private readonly _subjectManager = new SubjectManager();
  private readonly _subscriptionManager = new SubscriptionManager();
  private _selectedSubverticalHistoryId: Observable<number>;
  private _subverticalHistoryCloseDock: Observable<any>;
  get selectedSubverticalHistoryId$(): Observable<number> {
    return this._selectedSubverticalHistoryId;
  }
  get subverticalHistoryCloseDock$(): Observable<any> {
    return this._subverticalHistoryCloseDock;
  }

  constructor() {
    this._subjectManager.register('selectedSubverticalHistoryId');
    this._selectedSubverticalHistoryId = this._subjectManager.get(
      'selectedSubverticalHistoryId'
    );
    this._subjectManager.register('subverticalHistoryCloseDock');
    this._subverticalHistoryCloseDock = this._subjectManager.get(
      'subverticalHistoryCloseDock'
    );
    this._subscriptionManager.registerMultiple([
      this._selectedSubverticalHistoryId.subscribe(),
      this._subverticalHistoryCloseDock.subscribe()
    ]);
  }

  closeSubverticalReviewDock() {
    this._subjectManager.next('subverticalHistoryCloseDock', null);
  }

  setSelectedSubverticalHistoryId(value: number) {
    this._subjectManager.next('selectedSubverticalHistoryId', value);
  }
}
