import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ColumnClassHelper {
    getColumnHeaderClass(table, index) {
        return index < table.headers.length - 1
          ? 'documentation-grid column-header column-header__standard'
          : 'documentation-grid column-header column-header__end';
      }
    
      getColumnValueClass(columnIndex, table) {
        return columnIndex < table.headers.length - 1
          ? 'documentation-grid column-value column-value__standard'
          : 'documentation-grid column-value column-value__end';
      }   

    numToWord(num: number): string {
        switch (num) {
            case 0: return 'zero';
            case 1: return 'one';
            case 2: return 'two';
            case 3: return 'three';
            case 4: return 'four';
            case 5: return 'five';
            case 6: return 'six';
            case 7: return 'seven';
            case 8: return 'eight';
            case 9: return 'nine';
            case 10: return 'ten';
            default:
                return `${num}`;        

        }
    }
}