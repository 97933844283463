import { Injectable } from '@angular/core';

@Injectable()
export class CopyService {

  private readonly _copyElement: HTMLTextAreaElement;

  constructor() {
    this._copyElement = document.createElement('textarea');
    this._copyElement.style.width = '0';
    this._copyElement.style.height = '0';
    this._copyElement.style.opacity = '0';
    document.body.appendChild(this._copyElement);
  }

  copy(value: string | undefined) {
    this._copyElement.value = value === undefined ? '' : value;
    this._copyElement.focus();
    this._copyElement.select();
    document.execCommand('copy');
  }
}
