import { FilterType } from './filter-type';

export class FilterConfiguration {
  public static Zero = '0';
  public static NZero = 'a';
  public static All = 'All';
  public static CustomAll = [];
  public static getDefault(): FilterConfiguration {
    return new FilterConfiguration(FilterType.Default, ['']);
  }
  constructor(public filterType: FilterType, public values: string[]) {}
}
