<div class="access-token-container">
  <mat-form-field>
    <input
      #accessTokenInput
      type="text"
      class="edit-token-input"
      matInput
      [(ngModel)]="accessToken"
      (ngModelChange)="onAccessTokenChange($event)"
      required />
  </mat-form-field>
  <div class="container-grid">
    <div class="separator"></div>
    <div (click)="edit()" [class]="!tokenIsModified ? 'disableSave' : 'save'">
      <svg role="img" class="svg">
        <use xlink:href="assets/img/sprites.svg#partner-checkmark" />
      </svg>
      <span class="text">Save</span>
    </div>
    <div class="separator"></div>
    <div (click)="cancel()" class="cancel">
      <svg role="img" class="svg">
        <use xlink:href="assets/img/sprites.svg#partner-close" />
      </svg>
      <span class="text">Cancel</span>
    </div>
  </div>
</div>
