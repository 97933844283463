import { Injectable } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { LocalStorageManagerService } from './local-storage-manager.service';
import { LocalStorageKeyFactory } from './local-storage-key-factory';
import { TimeService } from '../time-service';
import { LocalStorageProxy } from './local-storage-proxy';

@Injectable()
export class LocalStorageProxyBuilder {
  constructor(
    private _localStorageKeyFactory: LocalStorageKeyFactory,
    private _timeService: TimeService,
    private _localStorageManagerService: LocalStorageManagerService,
    private _ovs: ObjectValidatorService,
  ) {}

  build(
    version: string,
    contextName: string,
    defaultObject?: any,
  ): LocalStorageProxy {
    return new LocalStorageProxy(
      this._localStorageKeyFactory,
      this._timeService,
      this._ovs,
      this._localStorageManagerService,
      version,
      contextName,
      defaultObject,
    );
  }
}
