import { FilterDefaults } from './models/filter-defaults';
import { DateTime } from 'luxon';
import { FilterConfiguration } from './models/filter-configuration';
import { FilterType } from './models/filter-type';
import { FilterItem } from './models/filter-item';
import { FilterBoxPersistence } from './models/filter-box-persistence';
import { QRow } from '../q-models/q-row';
import {
  ColumnConfiguration,
  ColumnType,
  LookupDisplayType,
} from '../q-models/column-configuration';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SubscriptionManager } from '../shared/subscription-manager';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FilterPersistence } from './models/filter-persistence';
import { RangeType } from './range/range.model';
import { GridCallbackParameter } from '../q-models/grid-callback-parameter';
import { cloneDeep } from 'lodash';

export interface IFilterBox {
  updateFilter(
    colName: string,
    newFilterConfiguration: FilterConfiguration[],
  ): any;
  getFiltered(data: any[]): any[];
  getColDefaults(colName: string, hasList): FilterDefaults;
}

export class FilterBox implements IFilterBox {
  static readonly Global: string = 'filter-box-global-col';
  private _filterItems: FilterItem[] = [];
  private readonly _persistenceEnabled: boolean;
  private _subscriptionManager = new SubscriptionManager();
  private _persistanceSave$ = new Subject<FilterPersistence[]>();
  private _persistanceDelete$ = new Subject<void>();
  protected _ovs = new ObjectValidatorService();

  get filterItems() {
    return this._filterItems;
  }
  set filterItems(inFilterItems: FilterItem[]) {
    if (this._persistenceEnabled) {
      const persistedData = this._filterBoxPersistence.getData();
      if (
        !this._ovs.isNullOrEmpty(persistedData) &&
        !this._ovs.isNullOrEmpty(inFilterItems)
      ) {
        inFilterItems = inFilterItems.map(filterItem => {
          const pData = persistedData.find(
            x => x.columnName === filterItem.columnName,
          );
          if (
            this._ovs.isDefined(pData) &&
            this._ovs.isDefined(pData.filterDefaults)
          ) {
            filterItem.filterDefaults = new FilterDefaults(
              new RangeType(
                pData.filterDefaults.range.min,
                pData.filterDefaults.range.max,
                pData.filterDefaults.range.lower,
                pData.filterDefaults.range.upper,
              ),
              pData.filterDefaults.includeText,
              pData.filterDefaults.excludeText,
              filterItem.filterDefaults.equals,
              pData.filterDefaults.selectedEqual,
              pData.filterDefaults.check,
              filterItem.filterDefaults.customValues,
              pData.filterDefaults.selectedCustomValues,
            );
          }
          return filterItem;
        });
      }
    }
    this._filterItems = inFilterItems;
  }

  constructor(private _filterBoxPersistence?: FilterBoxPersistence) {
    this._persistenceEnabled = this._ovs.isDefined(_filterBoxPersistence);
    if (!this._persistenceEnabled) {
      return;
    }
    this._subscriptionManager.registerMultiple([
      this._persistanceSave$
        .pipe(debounceTime(500))
        .subscribe(filterPersistenceData => {
          this._filterBoxPersistence.setData(filterPersistenceData);
        }),
      this._persistanceDelete$.pipe(debounceTime(500)).subscribe(() => {
        this._filterBoxPersistence.deleteData();
      }),
    ]);
  }

  updateFilter(
    colName: string,
    newFilterConfiguration: FilterConfiguration[],
  ): any {
    if (this._ovs.isNullOrEmpty(this.filterItems)) {
      return;
    }
    const filterItem = this.filterItems.find(c => c.columnName === colName);
    if (!this._ovs.isDefined(filterItem)) {
      return;
    }
    filterItem.filterConfigurations = newFilterConfiguration;
    const filterItemsToSave = this.filterItems.filter(x =>
      x.filterDefaults.needsToPersist(),
    );
    if (this._ovs.isNullOrEmpty(filterItemsToSave)) {
      this._persistanceDelete$.next(null);
      return;
    }
    this._persistanceSave$.next(
      filterItemsToSave.map(
        filterItem =>
          new FilterPersistence(
            filterItem.filterDefaults,
            filterItem.columnName,
          ),
      ),
    );
  }

  getFiltered(data: any[]): any[] {
    let filteredList: any = data;
    if (this._ovs.isNullOrEmpty(filteredList)) {
      return [];
    }

    const fItems = this.filterItems;
    fItems
      .filter(f => this._ovs.isDefined(f.filterConfigurations))
      .forEach(f => {
        filteredList = filteredList.filter(row =>
          f.columnName !== FilterBox.Global
            ? this._match(
                row[f.columnName],
                f.filterConfigurations,
                f.columnName === 'lastUpdated',
              )
            : this._rowMatch(row, f.filterConfigurations),
        );
      });
    return filteredList;
  }

  getQGridFiltered(grid: QRow[]): QRow[] {
    let filteredList: QRow[] = grid;
    if (this._ovs.isNullOrEmpty(filteredList)) {
      return [];
    }

    this.filterItems
      .filter(filterItem =>
        this._ovs.isDefined(filterItem.filterConfigurations),
      )
      .forEach(filterItem => {
        filteredList = filteredList.filter(qRow => {
          // do not filter out newly added, unchanged rows
          if (qRow.isNew && !qRow.isChanged) {
            return true;
          }
          if (filterItem.columnName === FilterBox.Global) {
            return this._rowMatch(
              this._getValueForGlobalFilter(qRow),
              filterItem.filterConfigurations,
            );
          }
          const filterColumn =
            this._ovs.isDefined(qRow.qGrid) &&
            !this._ovs.isNullOrEmpty(qRow.qGrid.columns)
              ? qRow.qGrid.columns.find(
                  column => column.fieldName === filterItem.columnName,
                )
              : null;
          if (this._ovs.isNullOrEmpty(filterColumn)) {
            throw `The filter is not defined for column [${filterItem.columnName}]`;
          }
          return this._match(
            this._getValueForMatch(filterItem, filterColumn, qRow),
            filterItem.filterConfigurations,
            filterItem.columnName === 'lastUpdated',
          );
        });
      });
    return filteredList;
  }

  private _getValueForMatch(
    filterItem: FilterItem,
    filterColumn: ColumnConfiguration,
    qRow: QRow,
  ): any {
    if (
      filterColumn.columnType !== ColumnType.Lookup &&
      filterColumn.columnType !== ColumnType.Component
    ) {
      return qRow.value[filterItem.columnName];
    }
    if (
      this._ovs.isDefined(filterColumn) &&
      filterColumn.lookupDisplayType === LookupDisplayType.Label &&
      filterColumn.columnType === ColumnType.Lookup
    ) {
      return qRow.getLookupLabel(
        this._ovs.isFunction(filterColumn.cellLookupsKey)
          ? (
              filterColumn.cellLookupsKey as (
                qCallbackParam: GridCallbackParameter,
              ) => string
            )(qRow.getGridCallbackParameter())
          : (filterColumn.cellLookupsKey as string),
        qRow.value[filterItem.columnName],
      );
    }
    if (
      filterColumn.columnType === ColumnType.Component &&
      this._ovs.isDefined(
        filterColumn.dynamicComponentType &&
          this._ovs.isFunction(filterColumn.filterBoxValue),
      )
    ) {
      return filterColumn.filterBoxValue(qRow.getGridCallbackParameter());
    }
    return qRow.value[filterItem.columnName];
  }

  private _getValueForGlobalFilter(qRow: QRow): any {
    const gridCallbackParameter = qRow.getGridCallbackParameter();
    const lookupColumns =
      this._ovs.isDefined(qRow.qGrid) &&
      !this._ovs.isNullOrEmpty(qRow.qGrid.columns)
        ? qRow.qGrid.columns.filter(
            column =>
              column.columnType === ColumnType.Lookup &&
              column.lookupDisplayType === LookupDisplayType.Label,
          )
        : null;

    const customComponentColumns =
      this._ovs.isDefined(qRow.qGrid) &&
      !this._ovs.isNullOrEmpty(qRow.qGrid.columns)
        ? qRow.qGrid.columns.filter(
            column =>
              column.columnType === ColumnType.Component &&
              this._ovs.isFunction(column.dynamicComponentType),
          )
        : null;

    if (
      this._ovs.isNullOrEmpty(lookupColumns) &&
      this._ovs.isNullOrEmpty(customComponentColumns)
    ) {
      return qRow.value;
    }

    const newRowValue = cloneDeep(qRow.value);
    if (!this._ovs.isNullOrEmpty(lookupColumns)) {
      lookupColumns.forEach(column => {
        newRowValue[column.fieldName] = qRow.getLookupLabel(
          this._ovs.isFunction(column.cellLookupsKey)
            ? (
                column.cellLookupsKey as (
                  qCallbackParam: GridCallbackParameter,
                ) => string
              )(gridCallbackParameter)
            : (column.cellLookupsKey as string),
          qRow.value[column.fieldName],
        );
      });
    }
    if (!this._ovs.isNullOrEmpty(customComponentColumns)) {
      customComponentColumns.forEach(column => {
        const value = this._ovs.isFunction(column.filterBoxValue)
          ? column.filterBoxValue(gridCallbackParameter)
          : qRow.value[column.fieldName];
        if (this._ovs.isDefined(value)) {
          newRowValue[column.fieldName] = value;
        }
      });
    }

    return newRowValue;
  }

  getColDefaults(colName: string): FilterDefaults {
    if (!this._ovs.isDefined(this.filterItems)) {
      return FilterDefaults.getDefault();
    }
    const f = this.filterItems.find(x => x.columnName === colName);
    if (!this._ovs.isDefined(f)) {
      return FilterDefaults.getDefault();
    }
    return f.filterDefaults;
  }

  private _match(
    value: any,
    filterConfiguration: FilterConfiguration[],
    isDate?: boolean,
  ): boolean {
    if (!this._ovs.isDefined(value)) {
      value = '';
    }

    if (isDate) {
      let totalDays =
        DateTime.now().diff(DateTime.fromISO(value), 'days').days | 0;
      if (totalDays === 0) {
        totalDays =
          DateTime.now().diff(DateTime.fromISO(value), 'hours').hours / 24;
      }

      value = parseFloat(totalDays.toFixed(1));
    }

    const isMatch: boolean = this._ovs.isDefined(filterConfiguration)
      ? (this._ovs.isDefined(filterConfiguration[FilterType.Range])
          ? value >= filterConfiguration[FilterType.Range].values[0] &&
            value <= filterConfiguration[FilterType.Range].values[1]
          : true) && // text contains
        (this._ovs.isDefined(filterConfiguration[FilterType.Contains]) &&
        filterConfiguration[FilterType.Contains].values[0] !== ''
          ? value
              .toString()
              .toLocaleUpperCase()
              .includes(
                filterConfiguration[FilterType.Contains].values[0]
                  .toString()
                  .toLocaleUpperCase(),
              )
          : true) && // text not contains
        (this._ovs.isDefined(filterConfiguration[FilterType.NotContains]) &&
        filterConfiguration[FilterType.NotContains].values[0] !== ''
          ? false ===
            value
              .toString()
              .toLocaleUpperCase()
              .includes(
                filterConfiguration[FilterType.NotContains].values[0]
                  .toString()
                  .toLocaleUpperCase(),
              )
          : true) && // non-zero
        (this._ovs.isDefined(filterConfiguration[FilterType.NonZero])
          ? this._isNoneZeroString(value.toString())
          : true) && // list
        (this._ovs.isDefined(filterConfiguration[FilterType.Equals]) &&
        this._ovs.isDefined(filterConfiguration[FilterType.Equals].values) &&
        filterConfiguration[FilterType.Equals].values[0] !==
          FilterConfiguration.All
          ? typeof value !== 'boolean'
            ? value
                .toString()
                .toLocaleUpperCase()
                .includes(
                  filterConfiguration[FilterType.Equals].values[0]
                    .toString()
                    .toLocaleUpperCase(),
                )
            : value ===
              (filterConfiguration[FilterType.Equals].values[0] === 'true')
          : true) && // Custom
        (this._ovs.isDefined(filterConfiguration[FilterType.Custom]) &&
        !this._ovs.isNullOrEmpty(filterConfiguration[FilterType.Custom].values)
          ? filterConfiguration[FilterType.Custom].values.findIndex(keyword =>
              value
                .toString()
                .toLocaleUpperCase()
                .includes(keyword.toString().toLocaleUpperCase()),
            ) > -1
          : true)
      : true;
    return isMatch;
  }

  private _isNoneZeroString(value: string): boolean {
    const striped = value.replace(/\D/g, '');
    for (let i = 0; i < striped.length; i++) {
      if (striped[i] !== '0') {
        return true;
      }
    }
    return false;
  }

  private _rowMatch(
    row: any,
    filterConfigurations: FilterConfiguration[],
  ): boolean {
    const list = Object.keys(row).map(x => row[x]);
    if (!this._ovs.isDefined(filterConfigurations)) {
      return false;
    }

    if (this._ovs.isDefined(filterConfigurations[FilterType.NonZero])) {
      if (
        !this._ovs.isDefined(
          list.find(
            c =>
              !this._ovs.isNullOrEmpty(c) &&
              c.toString() !== FilterConfiguration.Zero,
          ),
        )
      ) {
        return false;
      }
    }

    let isDefined: boolean = !(
      !this._ovs.isDefined(filterConfigurations[FilterType.Contains]) ||
      !this._ovs.isDefined(filterConfigurations[FilterType.Contains].values) ||
      filterConfigurations[FilterType.Contains].values[0].toString() === ''
    );
    if (isDefined) {
      isDefined = this._ovs.isDefined(
        list.find(
          c =>
            !this._ovs.isNullOrEmpty(c) &&
            c
              .toString()
              .toLocaleUpperCase()
              .includes(
                !this._ovs.isNullOrEmpty(
                  filterConfigurations[FilterType.Contains].values,
                ) &&
                  !this._ovs.isNullOrEmpty(
                    filterConfigurations[FilterType.Contains].values[0],
                  )
                  ? filterConfigurations[FilterType.Contains].values[0]
                      .toString()
                      .toLocaleUpperCase()
                  : '',
              ),
        ),
      );
      if (!isDefined) {
        return false;
      }
    }

    return true;
  }

  public clear() {
    this._subscriptionManager.clear();
  }
}
