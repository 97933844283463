<lib-confirmation
  [message]="confirmationService.message"
  [subMessage]="confirmationService.subMessage"
  [showCloseButton]="confirmationService.showCloseButton"
  [selectHeader]="confirmationService.selectHeader"
  [options]="confirmationService.options"
  [validator]="confirmationService.validator"
  [submitButtonName]="confirmationService.submitButtonName"
  [showCancelButton]="confirmationService.showCancelButton"
  [required]="confirmationService.required"
  [fields]="confirmationService.fields"
  (confirmation)="confirmationService.confirm($event)"
  (selectionChanged)="confirmationService.select($event)">
</lib-confirmation>
