import { Injectable } from '@angular/core';
import { TimeService } from '../time-service';
import { LocalStorageKey } from './local-storage-key.model';
@Injectable()
export class LocalStorageKeyFactory {
  constructor(private _timeService: TimeService) {}
  build(keyName: string, classVersion: string = '1.0.0', expiration: Date = null): LocalStorageKey {
    return new LocalStorageKey(
      expiration !== null
        ? expiration
        : this._timeService.todayUtc().plus({ hours: 47 }).toJSDate(),
      classVersion,
      true,
      keyName
    );
  }
}
