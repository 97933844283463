import { NavListItemCssStyle } from './custom-css-input.type';

export enum NavigationType {
  Route = 'Route',
  Link = 'Link',
  Header = 'Header'
}

export class NavigationItem {
  constructor(
    public label: string,
    public order: number,
    public icon: string,
    public route?: string,
    public children?: NavigationItem[],
    public type?: string,
    public href?: string,
    public elementId?: string,
    public componentSelector?: string,
    public customCss?: NavListItemCssStyle
  ) {}
}

export class NavigationSet {
  constructor(public navigationItem: NavigationItem, public parent?: string) {}
}
