import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable()
export class TimeService {
  today(): DateTime {
    return this.todayAt(0);
  }

  todayAt(hourOfDay: number): DateTime {
    const startDate = new Date();
    const date = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      hourOfDay
    );
    const dateLocal = DateTime.fromJSDate(date);

    return dateLocal;
  }

  todayUtc(): DateTime {
    return this.todayUtcAt(0);
  }

  todayUtcAt(hourOfDay: number): DateTime {
    const startDate = new Date();
    const date = Date.UTC(
      startDate.getUTCFullYear(),
      startDate.getUTCMonth(),
      startDate.getUTCDate(),
      hourOfDay
    );
    const dateUtc = DateTime.fromMillis(date, { zone: 'utc' });

    return dateUtc;
  }

  getDate(): Date {
    return this.getDateAt(0);
  }

  getDateAt(time: number): Date {
    const startDate = new Date();
    return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), time);
  }

  getDateUTC(): number {
    return this.getDateUTCAt(0);
  }

  getDateUTCAt(time: number): number {
    const startDate = new Date();
    return Date.UTC(
      startDate.getUTCFullYear(),
      startDate.getUTCMonth(),
      startDate.getUTCDate(),
      time
    );
  }

  ensure24h(time: number): number {
    let t = time;
    while (t < 0) {
      t += 24;
    }
    while (t > 23) {
      t -= 24;
    }
    return t;
  }

  utcToLocalTime(utcTime: Date): any {
    return DateTime.fromJSDate(utcTime).toUTC().toLocal().toLocaleString();
  }
}
