import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommunalModule } from '../../../communal.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CustomInputComponent } from './custom-input.component';
import { MatCommonModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AngularMaterialModule } from '../../../core/angular-material.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommunalModule,
    MatCommonModule,
    AngularMaterialModule,
    FormlyModule.forRoot({
      types: [{ name: 'custom-input', component: CustomInputComponent }]
    }),
    FormlyMaterialModule
  ],
  declarations: [CustomInputComponent]
})
export class CustomInputMudule {}
