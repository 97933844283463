/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubjectManager } from '../../../core/subject-manager';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';

// The purpose of this FormContainer class is to wrap the formly html elements
// in a component that includes the submit and reset buttons ensuring a consistant
// buttons and actions.  This can be expanded as needed.
@Component({
  selector: 'lib-form-container',
  templateUrl: './form-container.component.html',
  styleUrls: ['./form-container.component.scss']
})
export class FormContainerComponent implements OnInit, OnDestroy {
  subjectManager = new SubjectManager();
  clicked = false;
  @Input() form!: FormGroup;
  @Input() title!: string;
  @Input() model!: any;
  @Input() set externalReset(event) {
    if (event) {
      this.options.resetModel();
    }
  }
  @Output() onCancel = new EventEmitter<void>();
  buttonsEnabled$: Observable<boolean>;
  @Input() set defaultButtons(defaultButtons) {
    this.subjectManager.next('defaultButtons', defaultButtons);
  }
  @Input() public set fields(fields: FormlyFieldConfig[]) {
    if (!this._ovs.isDefined(fields)) {
      return;
    }
    this.subjectManager.next('fields', fields);
  }
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<unknown>();
  options: FormlyFormOptions = {};

  constructor(private _ovs: ObjectValidatorService) {
    this.subjectManager.registerMultiple(['fields', 'defaultButtons']);
  }

  ngOnInit(): void {
    this.buttonsEnabled$ = combineLatest([
      this.subjectManager.getObservable('fields'),
      this.subjectManager.getObservable('defaultButtons')
    ]).pipe(
      map(([fields, defaultButtons]) => {
        return (
          defaultButtons &&
          !this._ovs.isNullOrEmpty(
            fields.filter(
              f =>
                (this._ovs.isDefined(f.type) && this._ovs.isDefined(f.key)) ||
                this._ovs.isDefined(f.wrappers)
            )
          )
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subjectManager.clear();
  }

  submitForm(form: FormGroup) {
    const { value } = form;
    this.clicked = true;
    this.submit.emit(value);
  }

  cancel() {
    this.clicked = false;
    this.onCancel.emit();
  }
}
