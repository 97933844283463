<lib-searchable-select-control
  [control]="formControl"
  [class]="(isLoading$ | async) ? 'gray-loader' : ''"
  [searchableListItems]="items$ | async"
  [sortOption]="sort"
  [label]="field.props['label']">
</lib-searchable-select-control>
<span class="mat-mdc-form-field-error text" role="alert">
  <formly-validation-message
    [field]="field"
    *ngIf="
      formControl.touched && field.props['required'] && !formControl.valid
    "></formly-validation-message>
  <div
    class="place-holder"
    *ngIf="!(formControl.touched && field.props['required'] && !formControl.valid)">
    &nbsp;
  </div>
</span>
