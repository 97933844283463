<div class="container-flex" *ngIf="isVisible$|async">
  <div class="warning-info-svg">
    <svg role="img" class="svg exclamation">
      <use xlink:href="assets/img/sprites.svg#ramp-exclamation-icon" />
    </svg>
  </div>
  <div class="warning">
    <div class="content title">Warning</div>
    <div class="content text" [innerText]="field.props['message']"></div>
  </div>
  <div class="separator"></div>
  <svg role="img" (click)="close()" class="svg close">
    <use xlink:href="assets/img/sprites.svg#ramp-close-icon" />
  </svg>
</div>
<ng-container *ngIf="touchedEvent$ && touchedEvent$|async"></ng-container>
