import { ChangeEmitType } from './change-emit-type';

export class OnModelChangeParameter {
  constructor(
    public changeEmitType: ChangeEmitType,
    public pendingChanges: boolean,
    public pendingSelection: boolean,
    public userOverridesRowSelection: boolean
  ) {}
}
