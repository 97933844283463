export class OMLinksetKeyword {
  constructor(
    public keyword: string,
    public google: boolean,
    public yahoo: boolean,
    public author: string,
    public description: string,
    public language: string
  ) {}
}
