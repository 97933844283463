import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { ConfirmationSubscriberService } from '../../../../../shared/services/confirmation-subscriber.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-edit-access-token-input',
  templateUrl: './edit-access-token-input.component.html',
  styleUrls: ['./edit-access-token-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class EditAccessTokenInputComponent implements AfterViewInit, OnDestroy {
  @ViewChild('accessTokenInput') input: ElementRef;
  private _strRegex = new RegExp(/^[a-z0-9]+$/i);
  private readonly _subscriptionManager = new SubscriptionManager();
  private _originalAccessToken: string;
  private _validAccessToken: string;
  tokenIsModified: boolean = false;
  @Input() position: number;
  @Input() accessToken: string;
  @Output() cancelEdit: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() submitEdit: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public _confirmationSubscriberService: ConfirmationSubscriberService,
    private _snackbarService: SnackbarService,
  ) {
    this._subscriptionManager.registerMultiple([
      this._confirmationSubscriberService.confirmation$
        .pipe(
          distinctUntilChanged(),
          tap(confirm => {
            if (confirm) {
              this.submitEdit.emit(this.accessToken);
              this._confirmationSubscriberService.clearEmitConfirmation();
            }
          }),
        )
        .subscribe(),
    ]);
  }
  ngAfterViewInit(): void {
    this._originalAccessToken = this.accessToken;
    this._validAccessToken = this.accessToken;
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  edit() {
    this._confirmationSubscriberService.emitModal();
  }

  onAccessTokenChange(event) {
    if (this._strRegex.test(event)) {
      this._validAccessToken = event;
    } else {
      this.input.nativeElement.value = this._validAccessToken;
      this.accessToken = this._validAccessToken;
      this._snackbarService.open(
        'Only letter or number characters are allowed.',
        SnackBarType.warn,
        10000,
      );
    }
    this.tokenIsModified = this._originalAccessToken !== this.accessToken;
  }

  cancel() {
    this.cancelEdit.emit(true);
  }
}
