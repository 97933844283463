import { Component } from '@angular/core';
import { OverlayService } from './overlay.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material/angular-material.module';

@Component({
  selector: 'frontend-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  imports: [CommonModule, AngularMaterialModule],
  standalone: true,
})
export class OverlayComponent {
  constructor(_dialog: MatDialog, _overlayService: OverlayService) {
    _overlayService.set(_dialog);
  }
}
