import { Injectable, ViewContainerRef, ComponentRef } from '@angular/core';
import { QCellFocusComponent } from '../q-row/q-cell-focus/q-cell-focus.component';
import { CellFocus } from '../q-models/cell-focus';

@Injectable()
export class CellFocusBuilderService {
  build(
    defaultContainerRef: ViewContainerRef,
    cellFocusComponentRef: ComponentRef<QCellFocusComponent>
  ): CellFocus {
    return new CellFocus(defaultContainerRef, cellFocusComponentRef);
  }
}
