import { AccessingApiReportsComponent } from './documentation/accessing-api-reports/accessing-api-reports.component';
import { AdNetworkPixelTrackingComponent } from './documentation/ad-network-pixel-tracking/ad-network-pixel-tracking.component';
import { CampaignDomainUrlParametersComponent } from './documentation/campaign-domain-url-parameters/campaign-domain-url-parameters.component';
import { ComplianceChecklistComponent } from './documentation/compliance-checklist/compliance-checklist.component';
import { ConversionTrackingParametersComponent } from './documentation/conversion-tracking-parameters/conversion-tracking-parameters.component';
import { EmbeddedSearchComponent } from './documentation/embedded-search/embedded-search.component';
import { InstallingRampjsComponent } from './documentation/installing-rampjs/installing-rampjs.component';
import { ReferenceMaterialComponent } from './documentation/reference-material/reference-material.component';
import { ReportingApiForAfdKwDataComponent } from './documentation/reporting-api-for-afd-kw-data/reporting-api-for-afd-kw-data.component';
import { ReportingApiForBingHostedComponent } from './documentation/reporting-api-for-bing-hosted/reporting-api-for-bing-hosted.component';
import { ReportingApiForRsocOnlineComponent } from './documentation/reporting-api-for-rsoc-online/reporting-api-for-rsoc-online.component';
import { ReportingParametersComponent } from './documentation/reporting-parameters/reporting-parameters.component';
import { RsocGuidelinesOnlineFeedComponent } from './documentation/rsoc-guidelines-online-feed/rsoc-guidelines-online-feed.component';
import { RsocGuidelinesComponent } from './documentation/rsoc-guidelines/rsoc-guidelines.component';
import { SearchApiEndpointsComponent } from './documentation/search-api-endpoints/search-api-endpoints.component';
import { SemComponent } from './documentation/sem/sem.component';
import { SpecifyingKeywordsComponent } from './documentation/specifying-keywords/specifying-keywords.component';
import { SyndicationApiEndpointsComponent } from './documentation/syndication-api-endpoints/syndication-api-endpoints.component';
import { VideoWalkthroughsComponent } from './documentation/video-walkthroughs/video-walkthroughs.component';

export const componentsMap = {
    'accessing-api-reports': AccessingApiReportsComponent,
    'ad-network-pixel-tracking': AdNetworkPixelTrackingComponent,
    'campaign-domain-url-parameters': CampaignDomainUrlParametersComponent,
    'compliance-checklist': ComplianceChecklistComponent,
    'conversion-tracking-parameters': ConversionTrackingParametersComponent,
    'embedded-search': EmbeddedSearchComponent,
    'installing-rampjs': InstallingRampjsComponent,
    'reference-material': ReferenceMaterialComponent,
    'reporting-api-for-afd-kw-data': ReportingApiForAfdKwDataComponent,
    'reporting-api-for-bing-hosted': ReportingApiForBingHostedComponent,
    'reporting-api-for-rsoc-online': ReportingApiForRsocOnlineComponent,
    'reporting-parameters': ReportingParametersComponent,
    'rsoc-guidelines': RsocGuidelinesComponent,
    'rsoc-guidelines-online-feed': RsocGuidelinesOnlineFeedComponent,
    'search-api-endpoints': SearchApiEndpointsComponent,
    'app-video-walkthroughs': VideoWalkthroughsComponent,
    'sem': SemComponent,
    'specifying-keywords': SpecifyingKeywordsComponent,
    'syndication-api-endpoints': SyndicationApiEndpointsComponent,
};
