import { ReplaySubject } from 'rxjs';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export class DistinctNumberStack {
  private _list: number[];
  public changedSubject: ReplaySubject<NumberRange> =
    new ReplaySubject<NumberRange>();
  get isSingle(): boolean {
    return this.count === 1;
  }
  get count(): number {
    return this.isEmpty ? 0 : this._list.length;
  }
  get isEmpty(): boolean {
    return this._list.length === 0;
  }
  get min(): number {
    return !this.isEmpty ? Math.min(...this._list) : Number.MAX_VALUE;
  }
  get max(): number {
    return !this.isEmpty ? Math.max(...this._list) : 0;
  }
  get last(): number {
    return this._list[this._list.length - 1];
  }
  get first(): number {
    return this._list[0];
  }
  get lastDir(): Directions {
    let dir = Directions.One;
    // max !== last item return left
    if (!this.isEmpty && this.max !== this._list[this._list.length - 1]) {
      dir = Directions.MinusOne;
    }
    return dir;
  }
  get list(): number[] {
    return this._list;
  }

  constructor(private _ovs: ObjectValidatorService) {
    this._list = [];
  }

  push(value: number, skipSubject: boolean = false) {
    if (!this._ovs.isDefined(value)) {
      return;
    }
    if (value < 0) {
      return;
    }
    if (this.contains(value)) {
      return;
    }
    this._list.push(value);
    if (!skipSubject) {
      this._updateHighlightSubject();
    }
  }

  private _updateHighlightSubject() {
    this.changedSubject.next(this.getNumberRange());
  }

  pushRangeBetween(minValue: number, maxValue: number) {
    if (
      !this._ovs.isDefined(minValue) ||
      !this._ovs.isDefined(maxValue) ||
      minValue < 0 ||
      maxValue < 0
    ) {
      return;
    }
    for (let i = minValue; i <= maxValue; i++) {
      this.push(i, true);
    }
    this._updateHighlightSubject();
  }

  pop(): number {
    const x = this._list.pop();
    this._updateHighlightSubject();
    return x;
  }

  emptyAndPushDirection(dir: Directions) {
    const cm = this.min;
    this._list = [];
    this.push(cm + dir < 0 ? 0 : cm + dir);
  }

  emptyAndPushMin() {
    const cm = this.min;
    this._list = [];
    this.push(cm);
  }

  emptyAndPushValue(value: number) {
    this._list = [];
    this.push(value);
  }

  contains(value: number) {
    if (this.count === 0) {
      return false;
    }
    return this._list.findIndex(x => x === value) > -1;
  }

  notContains(value: number) {
    return this.contains(value) === false;
  }

  init(defaultKey: number = 0, skipSubject: boolean = false) {
    this._list = [];
    this.push(defaultKey, skipSubject);
  }

  reset(skipSubject: boolean = false) {
    this._list = [];
    if (!skipSubject) {
      this._updateHighlightSubject();
    }
  }

  clone(): number[] {
    return [...this._list];
  }

  getNumberRange(): NumberRange {
    return new NumberRange(this.min, this.max);
  }
}

export interface RowCol {
  row: number;
  col: number;
}

export interface MouseEventParameter {
  mouseEvent: MouseEvent;
  rowCol: RowCol;
}

export interface MouseWheelEventParameter {
  event: WheelEvent;
  row: number;
}

export class NumberRange {
  constructor(
    public lower: number,
    public upper: number,
  ) {}

  fits(number: number): boolean {
    return number >= this.lower && number <= this.upper;
  }

  isValid(): boolean {
    return this.lower !== -1 && this.upper !== -1;
  }
}

export enum Directions {
  One = 1,
  MinusOne = -1,
}
