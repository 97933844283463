import { Component, inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILayoutContent } from './ilayout-content';
import { LayoutService } from './layout.service';
import { ConfirmationService } from './confirmation-wrapper/confirmation.service';
import { Observable, of, Subject, Subscription, combineLatest } from 'rxjs';
import { filter, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { PageSpecificFilters } from './pre-filters/page-specific-filters';
import { FormPreFiltersComponent } from './form-pre-filters/form-pre-filters.component';
import { PreFiltersService } from './pre-filters.service';
import { FormlyFiltersHelper } from './formly-filters-helper';
import { LocalStorageProxy } from '../../core/local-storage/local-storage-proxy';
import { LocalStorageProxyBuilder } from '../../core/local-storage/local-storage-proxy-builder';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { FormFieldConfig } from '../../form/form-field-config';
import { DockContent } from './dock/dock-content';
import { DockVisibleState } from './dock/dock-visible-state';
import { GlobalState } from '../../store/global-state';
import { Store } from '@ngrx/store';
import { FormsSelectors } from '../../store/forms/selectors';
import { SubjectManager } from '@nimbus/global-frontend-subscription-manager';

@Injectable()
export abstract class BasePageComponent implements ILayoutContent {
  __onRowClickSubject = new Subject<PageSpecificFilters>();
  protected _onRowClick$ = this.__onRowClickSubject.asObservable();
  ovs = inject(ObjectValidatorService);
  protected readonly _subscriptionManager = new SubscriptionManager();
  readonly _subjectManager = new SubjectManager();
  protected _pageRoute: string;
  _localStorageProxy: LocalStorageProxy;
  protected _idsParameters$: Observable<string[]> = of([]);
  protected _queryStringParameters$: Observable<string[]>;
  isLoading$: Observable<boolean> = of(false);
  protected _objectName = '';
  protected _layoutService = inject(LayoutService);
  protected _router = inject(Router);
  protected _activatedRoute = inject(ActivatedRoute);
  protected _confirmationService = inject(ConfirmationService);
  protected _preFiltersService = inject(PreFiltersService);
  protected _formlyFiltersHelper = inject(FormlyFiltersHelper);
  protected _store = inject(Store<GlobalState>);
  protected _localStorageProxyBuilder = inject(LocalStorageProxyBuilder);
  formFields$: Observable<FormFieldConfig[]>;
  onParentDockResize$: Observable<number> = this._layoutService.onParentDockResize$;

  constructor() {
    if (!this.ovs.isDefined(this._layoutService)) {
      return;
    }
    this._layoutService.clear();
    this._confirmationService.hide();
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
    this._subjectManager.clear();
  }

  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  ngAfterViewInit(pageRoute: string): void {
    this._pageRoute = pageRoute;
    this._layoutService.preFiltersComponent = null;
    this.closeDock(false);
    this._subscriptionManager.registerMultiple([
      this._layoutService.modelChange$.subscribe(model => this.onModelChange(model)),
      this._layoutService.onDockClose$.subscribe(() => this._router.navigate([this._pageRoute]))
    ]);
  }

  private _extractParametersFromSchema(pageRoute: string) {
    this.formFields$ = this._store.select(FormsSelectors.getForms).pipe(
      filter(forms => !!forms),
      map(
        forms =>
          forms?.find(form => form.formName === 'PreFilters' && form.route === pageRoute)
            ?.formFields ?? '[]'
      ),
      map(formFields => JSON.parse(formFields)),
      shareReplay(1)
    );
    this._idsParameters$ = this._formlyFiltersHelper.entityIdsField(this.formFields$);
    this._queryStringParameters$ = this._formlyFiltersHelper
      .formPrefilterFields(this.formFields$)
      .pipe(
        map(fields => fields.map(field => field.key)),
        map(keys => keys.filter(key => this.ovs.isDefined(key))),
        shareReplay(1)
      );
    return combineLatest([
      this._formlyFiltersHelper.toParamsObject(
        this._formlyFiltersHelper.formPrefilterFields(this.formFields$)
      ),
      this._queryStringParameters$
    ]).pipe(
      tap(([params, queryStringParameters]) => {
        this._setupEventHooks();
        this._setupRegistration();
        this._localStorageProxy = this._localStorageProxyBuilder.build(
          this._pageRoute,
          this._preFiltersService.mapToTargetedProperties(params, queryStringParameters)
        );

        if (
          this.ovs.isDefined(queryStringParameters) &&
          !this._preFiltersService.areEqual(
            Object.keys(this._localStorageProxy.retrieve()),
            Object.keys(params)
          )
        ) {
          this._localStorageProxy.saveState(queryStringParameters);
        }

        this._subscriptionManager.registerMultiple([
          this._redirectWhenQueryStringParamsMissing(),
          this._loadWhenFormPrefiltersWithoutParams(),
          this._handlePopulatedQueryString()
        ]);
      })
    );
  }

  onModelChange(_model: any): void { }
  protected _onLoadData(_prefilters: PageSpecificFilters) { }
  protected _setupEventHooks() { }
  protected _setupRegistration() { }

  setDockModel(model: any): void {
    if (!this.ovs.isDefined(model)) {
      throw "Layout system: Model can't be null.  Use closeDock() instead.";
    }
    this._layoutService.dockVisibility = DockVisibleState.open;
    this._layoutService.model = model;
  }

  manuallyDisplayDockItem(index: number): void {
    this._layoutService.visibleDockableIndex = index;
  }

  // When using the FormPrefilters, the version and the idKeys have to be provided in the schema
  enableQueryStringRoutingWithFormPrefilters() {
    this._layoutService.preFiltersComponent = FormPreFiltersComponent;
    this._subscriptionManager.register(
      this._extractParametersFromSchema(this._pageRoute).subscribe()
    );
  }

  enableQueryStringRouting(
    preFilterComponent: Component,
    preFiltersDefault: PageSpecificFilters = null
  ): void {
    if (preFilterComponent === FormPreFiltersComponent) {
      throw new Error(
        'Do not provide version and defaults with FormPrefiltersComponent as it should be part of the schema.'
      );
    }
    this._layoutService.preFiltersComponent = preFilterComponent;
    this._queryStringParameters$ = of(
      Object.keys(preFiltersDefault).filter(key => this.ovs.isDefined(preFiltersDefault[key]))
    );
    this._idsParameters$ = of(
      Object.keys(preFiltersDefault).filter(key => this.ovs.isNullOrEmpty(preFiltersDefault[key]))
    );

    this._subscriptionManager.registerMultiple([
      this._queryStringParameters$.subscribe(queryStringParameters => {
        this._localStorageProxy = this._localStorageProxyBuilder.build(
          this._pageRoute,
          this._preFiltersService.mapToTargetedProperties(preFiltersDefault, queryStringParameters)
        );
      }),
      this._redirectWhenQueryStringParamsMissing(),
      this._handlePopulatedQueryString(),
      this._queryStringParameters$.subscribe(queryStringParameters => {
        if (this.ovs.isNullOrEmpty(queryStringParameters)) {
          this._onLoadData({});
        }
      })
    ]);
  }

  closeDock(withRedirect: boolean = false): void {
    this._layoutService.closeDock(withRedirect);
  }

  setDockContent(dockContent: DockContent): void {
    this._layoutService.dockContent = dockContent;
    //Enable all dock dependant observables.
    this._subscriptionManager.registerMultiple([this._closeDockOnEmptyRoute(), this._onClick()]);
  }

  protected _handlePopulatedQueryString(): Subscription {
    return combineLatest([this._activatedRoute.queryParams, this._queryStringParameters$])
      .pipe(
        filter(([params, queryStringParameters]) =>
          this._preFiltersService.hasPrefiltersParams(params, queryStringParameters)
        ),
        map(([params, queryStringParameters]) =>
          this._preFiltersService.mapToTargetedProperties(params, queryStringParameters)
        ),
        tap(prefilters => this._localStorageProxy.saveState(prefilters)),
        tap(prefilters => this._onLoadData(prefilters))
      )
      .subscribe();
  }

  protected _onClick(): Subscription {
    return combineLatest([this._onRowClick$])
      .pipe(
        withLatestFrom(this._idsParameters$),
        withLatestFrom(this._activatedRoute.queryParams),
        tap(([[click, idsParameters], params]) => {
          const newParameters = this._preFiltersService.addIdProperties(
            this.ovs.isArray(click) ? click[0] : click,
            params,
            idsParameters
          );
          if (this._preFiltersService.isSameIdProperties(newParameters, params, idsParameters[0])) {
            this._router.navigate([this._pageRoute]);
            return;
          }
          this._router.navigate([this._pageRoute], {
            queryParams: newParameters,
            queryParamsHandling: 'merge'
          });
        })
      )
      .subscribe();
  }

  protected _loadWhenFormPrefiltersWithoutParams(): Subscription {
    return combineLatest([
      this._activatedRoute.queryParams,
      this.formFields$,
      this._queryStringParameters$
    ])
      .pipe(
        filter(
          ([params, formFields, queryStringParameters]) =>
            this.ovs.isNullOrEmpty(
              formFields.filter(
                field => this.ovs.isDefined(field.type) && this.ovs.isDefined(field.defaultValue)
              )
            ) && !this._preFiltersService.hasPrefiltersParams(params, queryStringParameters)
        ),
        tap(params => this._onLoadData(params))
      )
      .subscribe();
  }

  protected _redirectWhenQueryStringParamsMissing(): Subscription {
    return combineLatest([this._activatedRoute.queryParams, this._queryStringParameters$])
      .pipe(
        filter(
          ([params, queryStringParameters]) =>
            !this._preFiltersService.hasPrefiltersParams(params, queryStringParameters)
        ),
        map(() => this._localStorageProxy.retrieve()),
        withLatestFrom(this._queryStringParameters$),
        filter(([params, queryStringParameters]) =>
          this._preFiltersService.hasPrefiltersParams(params, queryStringParameters)
        ),
        map(([params, queryStringParameters]) =>
          this._preFiltersService.mapToTargetedProperties(params, queryStringParameters)
        ),
        tap(model =>
          this._router.navigate([this._pageRoute], {
            queryParams: model,
            queryParamsHandling: 'merge'
          })
        )
      )
      .subscribe();
  }

  protected _closeDockOnEmptyRoute(): Subscription {
    return combineLatest([this._activatedRoute.queryParams, this._idsParameters$])
      .pipe(
        filter(
          ([params, idsParameters]) =>
            !this._preFiltersService.hasPopulatedIdProperties(params, idsParameters)
        ),
        tap(() => this.closeDock(false))
      )
      .subscribe();
  }
}
