import { Injectable } from '@angular/core';

@Injectable()
export class DomainManagerPrefilterSchemaService {
  getSchema(): any[] {
    return [
      {
        fieldGroupClassName: 'field-group-css-row-1',
        fieldGroup: [
          {
            key: 'domainLookup',
            type: 'input',
            defaultValue: '',
            templateOptions: {
              label: 'Domain Lookup'
            },
            className: 'field-css'
          },
          {
            key: 'statuses',
            type: 'searchable-multi-select',
            defaultValue: [],
            templateOptions: {
              enableMultiSelect: true,
              label: 'Status',
              selectOptions: [
                {
                  value: 'Failed',
                  selected: true,
                  display: 'Failed'
                },
                {
                  value: 'Updating',
                  selected: true,
                  display: 'Updating'
                },
                {
                  value: 'Pending',
                  selected: true,
                  display: 'Pending'
                },
                {
                  value: 'Disabled',
                  selected: true,
                  display: 'Disabled'
                },
                {
                  value: 'Success',
                  selected: true,
                  display: 'Success'
                }
              ]
            },
            className: 'field-css'
          }
        ]
      }
    ];
  }
}
