import { ICellInputBlocker } from './icell-input-blocker';
import { PluginType } from '../q-models/plugin-type';
import { QRow } from '../q-models/q-row';
import { ValidatorType } from '../q-models/validator-type';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export class InvalidCharactersBlocker implements ICellInputBlocker {
  private _ovs = new ObjectValidatorService();
  description: string;
  pluginType = PluginType.Validator;
  validatorType = ValidatorType.Blocker;

  constructor(private _invalidCharacters: string[]) {
    this.description = 'Blocking invalid characters';
  }

  isValid(
    _rows?: QRow[],
    _fieldName: string = '',
    _row: QRow = null,
    _context: any = null,
  ): boolean {
    return !this._ovs.isDefined(
      this._invalidCharacters.find(
        invalidCharacter => _context === invalidCharacter,
      ),
    );
  }

  clean(value: string): string {
    if (this._ovs.isNullOrEmpty(this._invalidCharacters)) {
      return value;
    }

    this._invalidCharacters.forEach(invalidCharacter => {
      while (value.indexOf(invalidCharacter) > -1) {
        value = value.replace(invalidCharacter, '');
      }
    });
    return value;
  }
}
