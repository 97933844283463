import { Injectable } from '@angular/core';
import { SubverticalReviewsManagerPrefilterModel } from '../models/subvertical-reviews-manager-prefilter.model';
import { SubverticalReviewsStatusEnum } from '../models/subvertical-reviews-status.enum';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { SubverticalRequestModel } from '../../shared/models/subvertical-request.model';
import { catchError, debounceTime, tap } from 'rxjs/operators';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { GridModel } from 'core/libs/global/frontend/grid/src/lib/grid-models/grid-model';

@Injectable()
export class SubverticalReviewsCacheService {
  private _updatedAt: Date;

  pageSize: number = 20;
  selectedReviewsModelFilters: SubverticalReviewsManagerPrefilterModel =
    this.getReviewsManagerPrefilterModel(this.pageSize);
  isLoadingSubject = new BehaviorSubject<boolean>(false);
  isLoading$ = this.isLoadingSubject.asObservable();
  subverticalRequestList: SubverticalRequestModel[] = [];
  filteredSubverticalRequests: SubverticalRequestModel[] = [];
  filteredSubverticalRequestsSubject = new ReplaySubject<
    SubverticalRequestModel[]
  >();
  filteredSubverticalRequests$ =
    this.filteredSubverticalRequestsSubject.asObservable();
  subverticalRequestListGridModel = new GridModel("subvertical-requests", this.subverticalRequestList);

  constructor(
    private _ovs: ObjectValidatorService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
  ) { }

  load(forceRefresh: boolean = false): Observable<SubverticalRequestModel[]> {
    if (
      !forceRefresh &&
      this._ovs.isDefined(this._updatedAt) &&
      new Date(this._updatedAt.getTime() + 3600000) > new Date(Date.now())
    ) {
      return of([]);
    }
    return this.loadSubverticalRequests();
  }

  loadSubverticalRequests(): Observable<SubverticalRequestModel[]> {
    this.isLoadingSubject.next(true);
    return this._webServiceBuilderService.builder
      .withUrl(`subverticalrequests`)
      .build()
      .get({
        requestorId: null,
        statusfilterthirtydays: !this._ovs.isNullOrEmpty(
          this.selectedReviewsModelFilters.statuses,
        )
          ? this.selectedReviewsModelFilters.statuses.join(',')
          : null,
      })
      .pipe(
        debounceTime(230),
        tap((subverticalRequests: SubverticalRequestModel[]) => {
          subverticalRequests.map(subverticalRequest => {
            subverticalRequest.requestedDateFormatStr =
              this._convertDateToString(subverticalRequest.requestedDate);
            subverticalRequest.reviewedDateFormatStr =
              this._convertDateToString(subverticalRequest.reviewedDate);
            return subverticalRequest;
          });
          this.subverticalRequestList = subverticalRequests;
          this.filteredSubverticalRequests = cloneDeep(
            this.subverticalRequestList,
          );
          if (!this._ovs.isNullOrEmpty(this.selectedReviewsModelFilters))
            this.filter();
          this._updatedAt = new Date(Date.now());
          this.isLoadingSubject.next(false);
        }),
        catchError(err => {
          this.isLoadingSubject.next(false);
          throw err;
        }),
      );
  }

  getReviewsManagerPrefilterModel(pageSize: number) {
    return new SubverticalReviewsManagerPrefilterModel(
      '',
      [SubverticalReviewsStatusEnum.Pending],
      pageSize,
    );
  }

  filter() {
    this.filteredSubverticalRequests.forEach(request => {
      request.requestedDate = this._convertDate(request.requestedDate);
      request.requestedDateFormatStr = this._convertDateToString(
        request.requestedDate,
      );
      if (!this._ovs.isNullOrEmpty(request.reviewedDate)) {
        request.reviewedDate = this._convertDate(request.reviewedDate);
        request.reviewedDateFormatStr = this._convertDateToString(
          request.reviewedDate,
        );
      }
    });
  }

  private _convertDate(date: Date) {
    return new Date(
      DateTime.fromISO(date.toString(), { zone: 'utc' }).toString(),
    );
  }

  private _convertDateToString(date: Date) {
    if (this._ovs.isNullOrEmpty(date)) return '';
    try {
      date.getFullYear();
    } catch (e) {
      date = this._convertDate(date);
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    const formattedHours =
      (date.getHours() < 12 ? date.getHours() : date.getHours() % 12) || 12;
    const hours = String(formattedHours).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} ${ampm}`;
  }
}
