import { Injectable } from '@angular/core';
import { GridCopyPaste } from '../q-models/grid-copy-paste';
import { QGrid } from '../q-models/q-grid';
import { ColumnConfiguration } from '../q-models/column-configuration';
import { DistinctNumberStack, RowCol } from '../q-models/copy-model';
import { GridClipboard } from '../q-models/grid-clipboard';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { GridValue } from '../q-models/grid-value';

@Injectable()
export class GridCopyPasteBuilderService {
  build(
    ovs: ObjectValidatorService,
    items: QGrid,
    columns: ColumnConfiguration[],
    bulkOption: () => boolean,
    canAddRowOnPaste: () => boolean,
    colMax: () => number,
    addNewRow: (refreshModel?: boolean) => void,
    updateHighLightArea: (from: RowCol, to: RowCol) => void,
    currentHighlightedColumns: DistinctNumberStack,
    currentHighlightedRows: DistinctNumberStack,
    clipboard: GridClipboard,
    gridValue: GridValue,
  ): GridCopyPaste {
    return new GridCopyPaste(
      ovs,
      items,
      columns,
      bulkOption,
      canAddRowOnPaste,
      colMax,
      addNewRow,
      updateHighLightArea,
      currentHighlightedColumns,
      currentHighlightedRows,
      clipboard,
      gridValue,
    );
  }
}
