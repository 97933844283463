import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import {
  ObjectValidatorService,
  SearchableListItemWithSelect,
  SortOption,
} from '@nimbus/shared-lib';
import { combineLatest, Observable } from 'rxjs';
import { map, tap, filter, switchMap } from 'rxjs/operators';
import { LinksetCacheService } from '../../../../services/linkset-cache.service';
import { FullGeoCacheService } from '../../../../services/full-geo-cache.service';
import { CombineGeoLanguageSubdomainVerticalInputService } from '../../../../services/combine-geo-language-subdomain-vertical-input.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Component({
  selector: 'linkset-geo',
  templateUrl: './geo.component.html',
  styleUrls: ['./geo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeoComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  private readonly _subscriptionManager = new SubscriptionManager();
  readonly autoSort: SortOption = SortOption.AutoSort;
  isDisabled: boolean = true;
  items$: Observable<SearchableListItemWithSelect[]>;
  private readonly subVerticalInputName = 'subVertical';
  private readonly verticalInputName = 'vertical';

  constructor(
    public ovs: ObjectValidatorService,
    public linksetCacheService: LinksetCacheService,
    public fullGeoCacheService: FullGeoCacheService,
    private _combineGeoLanguageSubdomainInputService: CombineGeoLanguageSubdomainVerticalInputService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.field.props['showFullList']) {
      this._populateFullList();
      this._subscriptionManager.register(
        this.field.formControl.valueChanges
          .pipe(
            tap(geo => {
              this._combineGeoLanguageSubdomainInputService.UpdateStaticGeoValue(geo, this.field);
            })
          )
          .subscribe()
      );
    } else {
      this._subscriptionManager.register(this._updateOnParentChanged().subscribe());
    }
  }
  ngOnDestroy(): void {
    this._subscriptionManager.clear();
    this._combineGeoLanguageSubdomainInputService.clear();
  }

  private _updateOnParentChanged(): Observable<any> {
    return combineLatest([
      this.field.parent?.formControl.get(this.verticalInputName).valueChanges,
      this.field.parent?.formControl.get(this.subVerticalInputName).valueChanges
    ]).pipe(
      tap(([vertical, subvertical]) => {
        this.isDisabled = this.ovs.isNullOrEmpty(vertical) || this.ovs.isNullOrEmpty(subvertical);
        this.items$ = this.linksetCacheService.linksets.pipe(
          map(linksets =>
            Array.from(
              new Map(
                linksets
                  .filter(
                    l =>
                      l.vertical === vertical &&
                      l.subVertical === subvertical &&
                      this.ovs.isDefined(l.geoFullName) &&
                      this.ovs.isDefined(l.geoTwoLetterCode)
                  )
                  .map(l => [l.geoFullName, l])
              ).values()
            ).map(
              l =>
                new SearchableListItemWithSelect(
                  l.geoTwoLetterCode.toLowerCase().trim(),
                  false,
                  l.geoFullName.trim()
                )
            )
          )
        );
      }),
      filter(
        ([vertical, subvertical]) =>
          !this.ovs.isNullOrEmpty(vertical) && !this.ovs.isNullOrEmpty(subvertical)
      ),
      switchMap(([vertical, subvertical]) => {
        return this.linksetCacheService.getGeos(vertical, subvertical).pipe(
          tap(linksets => {
            if (this.ovs.isNullOrEmpty(linksets)) {
              this.formControl.setValue(null);
              return;
            }
            linksets.sort((a, b) => a.geoFullName.localeCompare(b.geoFullName));
            const engLinksets = linksets.filter(l => l.twoLetterISOLanguageName === 'en');
            if (!this.ovs.isNullOrEmpty(engLinksets)) {
              const usIdx = engLinksets.findIndex(l => l.geoTwoLetterCode === 'us');
              if (usIdx > -1) {
                this.formControl.setValue(engLinksets[usIdx].geoTwoLetterCode.toLowerCase());
              } else {
                this.formControl.setValue(engLinksets[0].geoTwoLetterCode.toLowerCase());
              }
            } else if (linksets.findIndex(l => l.geoTwoLetterCode === 'us') > -1) {
              this.formControl.setValue('us');
            } else {
              this.formControl.setValue(linksets[0].geoTwoLetterCode.toLowerCase());
            }
          })
        );
      })
    );
  }

  private _populateFullList(): void {
    this.isDisabled = false;
    this.items$ = this.fullGeoCacheService.fullGeo.pipe(
      map(fullGeos => {
        const availableGeos = fullGeos.filter(
          g => this.ovs.isDefined(g.Name) && this.ovs.isDefined(g.TwoLetterCode)
        );
        const uniqueGeoListByKey: any[] = [
          ...new Map(availableGeos.map(item => [item.Name, item])).values()
        ];
        return uniqueGeoListByKey
          .map(geo => {
            return new SearchableListItemWithSelect(
              geo.TwoLetterCode.toLowerCase().trim(),
              false,
              geo.Name.trim()
            );
          })
          .sort((a, b) => a.display.localeCompare(b.display));
      })
    );
  }
}
