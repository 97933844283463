import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BuysideNetworkModel } from '../../areas/pixel-manager/models/buyside-networks.model';
import { SubjectManager } from '@nimbus/global-frontend-subscription-manager';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';

@Injectable({
  providedIn: 'root',
})
export class PixelManagerBuysideNetworkService {
  private readonly _loadingInProgressSubject = new BehaviorSubject<boolean>(
    false,
  );
  private _buysideNetworks$: Observable<BuysideNetworkModel[]>;
  private readonly _buysideNetworksAPIPATH: string = 'buysidenetworks';
  private readonly _subjectManager = new SubjectManager();
  isLoading$: Observable<boolean> =
    this._loadingInProgressSubject.asObservable();

  constructor(private _webServiceBuilderService: NimbusWebServiceBuilderService) {
    this._subjectManager.register('buysideNetworks');
    this._buysideNetworks$ = this._subjectManager.get('buysideNetworks');
    this.buysideNetworks.subscribe();
    this.getBuysideNetworksList().subscribe();
  }

  get buysideNetworks(): Observable<BuysideNetworkModel[]> {
    return this._buysideNetworks$;
  }

  private getBuysideNetworksList() {
    this._loadingInProgressSubject.next(true);
    return this._webServiceBuilderService.builder
      .withUrl(this._buysideNetworksAPIPATH)
      .build()
      .get({})
      .pipe(
        tap(() => this._loadingInProgressSubject.next(false)),
        tap(networks => {
          this._subjectManager.next('buysideNetworks', networks);
        }),
      );
  }
}
