import { ParkedDomainKeywordRecommendationMethod } from './parked-domain-keyword-recommendation-method.model';
import { UrlParameters } from './url-parameters.model';

export class UpdateParkedDomain {
  facebookDomainVerificationCode: string;
  euDomain: boolean;
  keywordRecommendationMethod: ParkedDomainKeywordRecommendationMethod;
  urlParameters: UrlParameters | string;
  staticKeywords: string[];

  constructor(
    facebookDomainVerificationCode: string,
    euDomain: boolean,
    keywordRecommendationMethod: boolean,
    urlParameters: UrlParameters | string,
    staticKeywords: string[]
  ) {
    this.facebookDomainVerificationCode = facebookDomainVerificationCode;
    this.euDomain = euDomain;
    this.keywordRecommendationMethod = this.validateKeywordRecommendationMethod(
      keywordRecommendationMethod
    );
    this.urlParameters = urlParameters;
    this.staticKeywords = staticKeywords;
  }

  private validateKeywordRecommendationMethod(
    keywordRecommendation: boolean
  ): ParkedDomainKeywordRecommendationMethod {
    return keywordRecommendation
      ? ParkedDomainKeywordRecommendationMethod.Querystring
      : ParkedDomainKeywordRecommendationMethod.Zoltar;
  }
}
