import {environment} from './environments/environment';

export default {
  oidc: {
      issuer: 'https://system1-app.oktapreview.com/oauth2/default',
      clientId: '0oa4imnlbmVdfQYv51d7',
      scopes: ['openid', 'profile'],
      pkce: true,
      testing: {
        disableHttpsCheck: environment.oktaDisableHttpsCheck,
      },
      cookies: {
        secure: environment.cookieSecure,
      },
      redirectUri: environment.oktaRedirect,
    },
  widget: {
    useInteractionCodeFlow: true
  }
}
