<div #container>
  <mat-horizontal-stepper #stepper>
    <mat-step
      *ngFor="let step of getActiveSteps(field.fieldGroup); let index = index; let last = last">
      <ng-template matStepLabel>{{ step.props['label'] }}</ng-template>
      <div
        class="stepper"
        *ngIf="field.props['stepperType'] === 'vertical' && hideFirstStep()">
        <div
          *ngFor="
            let labelStep of getActiveSteps(field.fieldGroup);
            let stepperIndex = index;
            let stepperLast = last
          ">
          <div
            *ngIf="!labelStep.props['disabled']"
            class="step"
            [class.interactable]="interactable(stepperIndex)"
            (click)="interactable(stepperIndex) ? skipTo(stepperIndex) : ''">
            <div>
              <mat-icon
                *ngIf="!interactable(stepperIndex)"
                fontIcon="radio_button_unchecked"
                class="gray"></mat-icon>
              <mat-icon
                *ngIf="!isValid(labelStep) && interactable(stepperIndex) && stepperIndex !== index"
                fontIcon="radio_button_checked"
                class="gray"></mat-icon>
              <mat-icon *ngIf="stepperIndex === index" fontIcon="edit" class="magenta"></mat-icon>
              <mat-icon
                *ngIf="stepperIndex !== index && isValid(labelStep) && interactable(stepperIndex)"
                class="green"
                fontIcon="check_circle"></mat-icon>
            </div>
            <div>
              <b>Step {{ stepperIndex + 1 }}:</b>
              <div [class.current]="step === labelStep">{{ labelStep.props['label'] }}</div>
            </div>
          </div>
        </div>
      </div>
      <h2 class="title">{{ index + 1 }}. {{ step.props['title'] }}</h2>
      <div>{{ step.props['summary'] }}</div>
      <formly-field [field]="step"></formly-field>

      <div class="col-sm-2 d-flex align-items-center">
        <button
          matStepperPrevious
          *ngIf="index !== 0"
          mat-flat-button
          color="primary"
          type="button"
          class="button"
          (click)="container.scrollIntoView()">
          Previous
        </button>
        <button
          matStepperNext
          *ngIf="!last"
          mat-flat-button
          color="primary"
          type="button"
          [disabled]="!isValid(step)"
          class="button"
          data-test="stepper-continue-button"
          (click)="container.scrollIntoView()"
          id="stepperNext"
          [hidden]="!hideFirstStep()">
          Save and continue
        </button>
        <button
          matStepperNext
          *ngIf="last"
          mat-flat-button
          color="primary"
          type="submit"
          [disabled]="!formValid(field.fieldGroup)"
          class="button"
          data-test="stepper-save-button">
          Save
        </button>
      </div>
      <div *ngIf="field.props['showStepperUserHelpers'] !== false">
        <div *ngIf="!isValid(step)">* Make sure to fill the required field(s).</div>
        <div *ngIf="isValid(step) && !last">Your done click next...</div>
        <div *ngIf="isValid(step) && last">Your done, you can submit.</div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
