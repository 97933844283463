import { Component } from '@angular/core';
import { VideoInfo } from './video-playlist/models';
import { VideosService } from '../services/videos.service';

@Component({
    selector: 'app-video-walkthroughs',
    templateUrl: './video-walkthroughs.component.html',
    styleUrls: ['./video-walkthroughs.component.scss']
})
export class VideoWalkthroughsComponent {
    constructor(private videosService: VideosService) {}
    getVideos(setName: string): VideoInfo[] {
        return this.videosService.getVideos(setName);
    }
}

