import { Injectable } from '@angular/core';
import { ObjectValidatorService } from '../object-validator-service/object-validator.service';
import { ILoggerConfig } from './ilogger-config';
import { LoggerConfig } from './logger-config';

@Injectable()
export class LoggerConfigBuilder {
  constructor(private _ovs: ObjectValidatorService) {}

  build(loggerConfiguration: ILoggerConfig) {
    return new LoggerConfig(
      this._ovs,
      loggerConfiguration.useConsole,
      loggerConfiguration.rndViewerbufferSize,
      loggerConfiguration?.showAPICalls
    );
  }
}
