import { ObjectValidatorService } from '../core/object-validator-service/object-validator.service';

export class ListItem {
  private _ovs = new ObjectValidatorService();
  constructor(
    public label: string,
    protected value?: string,
    protected id?: number,
    protected metadata?: ListItemMetadata
  ) { }

  getValue(): any {
    return !this._ovs.isNullOrUndefined(this.value) ? this.value : this.id;
  }

  getId(): any {
    return !this._ovs.isNullOrUndefined(this.id) ? this.id : null;
  }

  getMetadata(): any {
    return !this._ovs.isNullOrUndefined(this.metadata) ? this.metadata : null;
  }
}

export class ListItemMetadata {
  constructor(public flag: boolean = null) {}
}
