import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { ColumnClassHelper } from "../shared/columnClassHelper";

@Component({
    selector: 'app-reporting-api-for-rsoc-online',
    templateUrl: './reporting-api-for-rsoc-online.component.html',
    styleUrls: ['./reporting-api-for-rsoc-online.component.scss'],
    standalone: true,
    imports: [CommonModule]
})
export class ReportingApiForRsocOnlineComponent {
    constructor(
        public columnClassHelper: ColumnClassHelper
    ) {}
    reportTypes = [
        {
            name: 'Campaign',
            description: 'Provides daily data by campaign and date. The segment, account id, and buyside campaign id for each campaign is included.'
        },
        {
            name: 'Summary',
            description: 'Aggregates data for all campaigns and provides a summary for each date.'
        },
        {
            name: 'Intraday Campaign',
            description: 'Provides hourly data by campaign and date. The segment, account id, and buyside campaign id for each campaign is included.'
        },
    ];

    metricsOverview = {
        headers: ['Metric', 'Definition'],
        records: [
            {
                metric: 'sellside clicks',
                definition: 'clicks on the SERP'
            },
            {
                metric: 'sellside searches',
                definition: 'views of the SERP'
            },
            {
                metric: 'partner net revenue',
                definition: 'the estimated net revenue that will be disbursed to you'
            },
            {
                metric: 'click through rate',
                definition: 'sellside clicks / sellside searches'
            },
            {
                metric: 'revenue per click',
                definition: 'estimated net revenue / sellside clicks'
            }
        ]
    };

}