import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  AfterViewInit,
  ComponentRef,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ColumnInfo } from '../grid/column-info';
import { MatTableDataSource } from '@angular/material/table';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { CellComponentInjectorComponent } from '../cell-component-injector/cell-component-injector.component';
import { MatSort, Sort } from '@angular/material/sort';
import { RowInfo } from '../grid/row-info';
import { OverlayService } from 'core-global-frontend-common-ui';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { Observable, Subscription } from 'rxjs';
import { FilterInfo } from '../grid-models/filter-info';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { gridCustomTooltipDefaults } from './grid-custom-tooltip_defaults';
import { LocalStorageProxy, LocalStorageProxyBuilder } from 'core-libs-global-frontend-local-storage';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'frontend-grid-presentation',
  templateUrl: './grid-presentation.component.html',
  styleUrls: ['./grid-presentation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    CellComponentInjectorComponent,
  ],
  providers: [
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: gridCustomTooltipDefaults,
    },
  ],
})
export class GridPresentationComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatSort) tableSort!: MatSort;
  @ViewChild('paginator') paginator!: MatPaginator;
  columnsFields!: string[];
  private readonly _subscriptionManager = new SubscriptionManager();
  cellComponentsCache: Map<ComponentRef<any>, Map<number, any>> | undefined;
  @Output() submitEvent = new EventEmitter<any>();
  @Output() sortEvent = new EventEmitter<Sort>();
  @Output() filterEvent = new EventEmitter<FilterInfo>();
  @Input() submitButtonLabel = 'Submit';
  _editable!: boolean;
  @Input() set editable(value: boolean) {
    this._editable = value;
    this._reset();
  }
  @Input() rowInfo!: RowInfo;
  readonly enableColumnFilters = false;
  @Input() formArray!: any;
  @Input() disableComponentsCache = false;
  @Output() matPaginator = new EventEmitter<MatPaginator>();
  @Output() pageChange = new EventEmitter<PageEvent>();
  private _initialized = false;
  private _dataSource!: MatTableDataSource<FormGroup<any>, MatPaginator>;
  private _dialogAfterClose$: Observable<any> | undefined;
  public get dataSource(): MatTableDataSource<FormGroup<any>, MatPaginator> {
    return this._dataSource;
  }
  @Input() public set dataSource(
    value: MatTableDataSource<FormGroup<any>, MatPaginator>,
  ) {
    this._dataSource = value;
    this._reset();
  }
  @Input() pageSize!: number;
  @Input() disableSubmitForUnchanged!: boolean;
  private _columns!: ColumnInfo[];
  get columns(): ColumnInfo[] {
    return this._columns;
  }
  @Input() set columns(columns: ColumnInfo[]) {
    this.columnsFields = columns?.map(column => column.field) ?? [];
    this._columns = columns;
    this._reset();
  }
  @Input() serversidePagination!: boolean;
  @Input() dataLength!: number;
  @Input() dataId!: string;
  @Input() defaultSort?: Sort;
  private _serverPageIndex: number = 0;
  private _localStorageProxy!: LocalStorageProxy;

  constructor(private overlayService: OverlayService,
    private _changeDetectionRef: ChangeDetectorRef,
    private _localStorageProxyBuilder: LocalStorageProxyBuilder,
    private _ovs: ObjectValidatorService
  ) {
    if (!this.disableComponentsCache) {
      this.cellComponentsCache = new Map<ComponentRef<any>, Map<number, any>>();
    }
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }

  click(rowForm: FormGroup) {
    if (this.rowInfo.clickEvent) {
      this.rowInfo.clickEvent(rowForm.value, this.dataSource);
    }
  }

  ngAfterViewInit(): void {
    this._initialized = true;
    this.paginator.pageSize = this.pageSize;
    this.dataSource.sort = this.tableSort;
    this._reset();
    this.matPaginator.emit(this.paginator);
    this._localStorageProxy = this._localStorageProxyBuilder.build(
      `${this.dataId} sort`,
      this.defaultSort || {}
    );
    if (!this._ovs.isObjectNullOrEmpty(this._localStorageProxy.retrieve())) {
      this.sortData(this._localStorageProxy.retrieve());
    }
  }

  private _reset() {
    if (!this._initialized) {
      return;
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.tableSort;
    setTimeout(() => {
      if (this.serversidePagination) {
        this.paginator.length = this.dataLength;
        this.paginator.pageIndex = this._serverPageIndex;
      }
      this._changeDetectionRef.markForCheck();
      this._changeDetectionRef.detectChanges();
    }, 100);
  }

  sortData(sort: Sort) {
    if (this.serversidePagination) {
      this.resetServerPageIndexIndex();
    }
    this._localStorageProxy.saveState(sort)
    this.sortEvent.emit(sort);
  }

  openFilterDialog(event: any, columnInfo: ColumnInfo) {
    event.stopPropagation();
    this._dialogAfterClose$ = this.overlayService.openDialog(
      FilterDialogComponent,
      { data: { column: columnInfo } },
    );
    const subscription = this._dialogAfterClose$?.subscribe(result => {
      this.filterEvent.emit(result);
    });
    if (subscription) {
      this._subscriptionManager.register(subscription as Subscription);
    }
  }

  submit() {
    this.submitEvent.emit(this.dataSource.data.map(x => x.value));
  }

  pageChanged(pageEvent: PageEvent) {
    this.pageChange.emit(pageEvent)
    if (this.serversidePagination) {
      this._serverPageIndex = pageEvent.pageIndex;
    }
  }

  resetServerPageIndexIndex() {
    this._serverPageIndex = 0;
  }
}
