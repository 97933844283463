import { Injectable } from '@angular/core';
import {
  CanActivate,
  CanActivateChild,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileCacheService } from './user-profile-cache.service';
import { map } from 'rxjs/operators';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';

@Injectable()
export class HasUserContextGuard {
  constructor(
    private _ovs: ObjectValidatorService,
    private _userProfileCacheService: UserProfileCacheService
  ) {}

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this._userProfileCacheService.loadUsers().pipe(
      map(usersResponse => {
        if (this._ovs.isNullOrEmpty(usersResponse)) {
          return false;
        }
        return true;
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
