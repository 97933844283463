import { Injectable } from '@angular/core';

@Injectable()
export class StringHelper {
  substitute(title: string, oldChar: string, newChar: string): string {
    let input = title;
    while (input.indexOf('-') !== -1) {
      input = input.replace('-', '');
    }
    return input;
  }

  singularize(text) {
    return text.lastIndexOf('s') === text.length - 1 ? text.substring(0, text.length - 1) : text;
  }
}
