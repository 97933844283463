import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { IDockable } from '@nimbus/shared-lib';
import { SubverticalReadonlyCacheService } from './services/subvertical-readonly-cache.service';
import { SubverticalReviewsCacheService } from '../services/subvertical-reviews-cache.service';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { SubverticalReadonlySchemaService } from './schemas/subvertical-readonly-schema.service';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Component({
  selector: 'app-subvertical-readonly',
  templateUrl: './subvertical-readonly.component.html',
  styleUrls: ['./subvertical-readonly.component.scss'],
})
export class SubverticalReadonlyComponent
  implements IDockable, OnDestroy, OnInit
{
  private readonly _subscriptionManager = new SubscriptionManager();
  model: any;
  tabIndex: number;
  title: string;
  modelChange: EventEmitter<any>;
  fields: FormlyFieldConfig[];
  form = new FormGroup({});
  formModel: any = {};

  constructor(
    private _subverticalReadonlyCacheService: SubverticalReadonlyCacheService,
    private _reviewsCacheService: SubverticalReviewsCacheService,
    private _ovs: ObjectValidatorService,
    private _subverticalReadonlySchemaService: SubverticalReadonlySchemaService,
  ) {}
  ngOnInit(): void {
    this._subscriptionManager.register(
      combineLatest([
        this._reviewsCacheService.isLoading$.pipe(distinctUntilChanged()),
        this._subverticalReadonlyCacheService.subverticalRequestId$.pipe(
          distinctUntilChanged(),
        ),
      ])
        .pipe(
          map(([isLoading, id]) => {
            if (!isLoading) {
              return this._reviewsCacheService.subverticalRequestList.find(
                x => x.id === id,
              );
            }
            return undefined;
          }),
          tap(model => {
            if (this._ovs.isNullOrUndefined(model)) {
              return;
            }
            this.formModel = {
              subverticalRequest: {
                vertical: model.vertical,
                subVertical: model.subvertical,
                geo: model.geo,
                language: model.language,
              },
              keywords: model.keywords,
              notes: model.notes,
            };
            this.fields = this._subverticalReadonlySchemaService.getSchema();
          }),
        )
        .subscribe(),
    );
  }
  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }
}
