import { Injectable } from '@angular/core';
import { ILocalStorageService } from './ilocal-storage-service';
import { QLocalStorageService } from './local-storage.service';
import { LocalStorageKey } from './local-storage-key.model';

@Injectable()
export class LocalStorageManagerService implements ILocalStorageService {
  constructor(private _qLocalStorage: QLocalStorageService) {}

  clearAll() {
    this._existingKeys().map(k => this.deleteKey(k));
  }

  getKey(key: LocalStorageKey): string {
    return this._qLocalStorage.getKey(key);
  }

  setKey(key: LocalStorageKey, value: string) {
    this._qLocalStorage.setKey(key, value);
  }

  deleteKey(key: LocalStorageKey): void {
    this._qLocalStorage.deleteKey(key);
  }

  private _existingKeys(): LocalStorageKey[] {
    const entries = [];
    for (const x in localStorage) {
      if (x.startsWith('{"') === false) {
        continue;
      }
      const key = JSON.parse(x) as LocalStorageKey;
      entries.push(new LocalStorageKey(key.modelStructureIndicator, key.keyName));
    }
    return entries;
  }
}
