import { Injectable } from '@angular/core';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { BaseWebService } from './base-web.service';
import { ConfigService } from './config.service';
import {
  IEntitiesLoader,
  IEntityLoader,
  IEntityUpdater,
  IEntityCreator,
  IEntityDeleter,
  PaginationProperties,
} from './ientities';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { HttpClientQ } from 'core-global-frontend-http';

@Injectable()
export class GenericWebServiceBuilder {
  private _path;
  private _apiId;
  private _cacheEnable = false;
  private _enablePagination = false;

  constructor(
    private _configService: ConfigService,
    private _http: HttpClientQ,
    private _snackbarService: SnackbarService,
  ) {}

  withApi(path: string, apiId: string) {
    this._path = path;
    this._apiId = apiId;
    return this;
  }

  withCaching(cacheEnable: boolean = true) {
    if (cacheEnable === true) {
      this._cacheEnable = cacheEnable;
    }
    this._cacheEnable = true;
    return this;
  }

  withPagination() {
    this._enablePagination = true;
    return this;
  }

  build() {
    return new GenericWebService(
      this._configService,
      this._http,
      this._apiId,
      this._path,
      this._snackbarService,
      this._cacheEnable,
      new PaginationProperties(),
      this._enablePagination,
    );
  }
}

export class GenericWebService
  extends BaseWebService
  implements
    IEntitiesLoader,
    IEntityLoader,
    IEntityUpdater,
    IEntityCreator,
    IEntityDeleter
{
  entities$: Observable<any[]>;
  entity$: Observable<any>;
  isItemLoaded = false;

  constructor(
    private _configService: ConfigService,
    private _http: HttpClientQ,
    private _apiId: string,
    private _path: string,
    private _snackbarService: SnackbarService,
    private _cacheEnable: boolean,
    override paginationProperties: PaginationProperties,
    public enablePagination: boolean,
  ) {
    super();
    this._subjectManager.registerMultiple(['getCollection', 'get']);
    this.entities$ = this._subjectManager.getObservable('getCollection');
    this.entity$ = this._subjectManager.getObservable('get');
  }

  getCollection(params: any = {}): Observable<any[]> {
    this._guardForValidPath();
    this._apiCallInProgressSubject.next(true);
    const response$ = of(this._configService.configured$).pipe(
      switchMap(() =>
        this._http.get(
          `${this._configService.getApi(this._apiId).host}/${this._path}`,
          params,
        ),
      ),
      tap(_response => this._apiCallInProgressSubject.next(false)),
      map(response => this.handlePagination(response, this.enablePagination)),
      tap(response =>
        this._subjectManager.next(
          'getCollection',
          this.enablePagination && !!response?.['results']
            ? response['results']
            : response,
        ),
      ),
      shareReplay(this._cacheEnable ? 1 : 0),
    );

    this._subscriptionManager.register(
      //????
      response$.subscribe(),
      'getCollection',
    );
    return this.entities$;
  }

  private _guardForValidPath() {
    if (this._ovs.isNullOrEmpty(this._path)) {
      throw new Error('GenericWebService: No API path provided.');
    }
  }

  get(params: any): Observable<unknown> {
    if (this._ovs.isNullOrEmpty(this._path)) {
      throw Error('GenericWebService: entityName not defined.');
    }
    this._apiCallInProgressSubject.next(true);
    if (!this._cacheEnable) {
      this._subscriptionManager.register(
        this._http
          .get(`${this._configService.getApi().host}/${this._path}`, params)
          .pipe(
            tap(_response => this._apiCallInProgressSubject.next(false)),
            tap(item => this._subjectManager.next('get', item)),
            catchError(err => {
              this._apiCallInProgressSubject.next(false);
              if (!err.reported) {
                this._snackbarService.open(
                  'An error occured while loading the requested data.',
                  SnackBarType.error,
                  3000,
                );
              }
              return of(null);
            }),
          )
          .subscribe(),
        'get',
      );
    } else {
      this._subscriptionManager.register(
        this._http
          .get(`${this._configService.getApi().host}/${this._path}`, params)
          .pipe(
            tap(_response => this._apiCallInProgressSubject.next(false)),
            shareReplay(1),
            tap(item => this._subjectManager.next('get', item)),
            catchError(err => {
              this._apiCallInProgressSubject.next(false);
              if (!err.reported) {
                this._snackbarService.open(
                  'An error occured while loading the requested data.',
                  SnackBarType.error,
                  3000,
                );
              }
              return of(null);
            }),
          )
          .subscribe(),
        'get',
      );
    }
    return this.entity$;
  }

  delete(params: unknown): Observable<unknown> {
    return this._http.delete(
      `${this._configService.getApi().host}/${this._path}`,
      params,
    );
  }

  save(params: any): Observable<unknown> {
    let localParams = { ...params };
    delete localParams['body'];
    return this._http.put(
      `${this._configService.getApi().host}/${this._path}`,
      params['body'],
      localParams,
    );
  }

  create(params: any): Observable<any> {
    let localParams = { ...params };
    delete localParams['body'];
    return this._http.post(
      `${this._configService.getApi().host}/${this._path}`,
      params['body'],
      localParams,
    );
  }
}
