import { FormControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';

export function ArrayMinLengthValidator(
  control: FormControl,
  _field: FormlyFieldConfig,
  options = {}
): ValidationErrors {
  return control.value?.length >= options['min'] ?? false ? null : { arrayMinLength: true };
}

export function ArrayMaxLengthValidator(
  control: FormControl,
  _field: FormlyFieldConfig,
  options = {}
): ValidationErrors {
  return control.value?.length <= options['Max'] ?? false ? null : { arrayMaxLength: true };
}
