import { Injectable } from '@angular/core';
import { Subject, of, startWith, switchMap, combineLatest, ReplaySubject } from 'rxjs';
import { DomainCount, ParkedDomainsCount } from '../../models/domain-count.model';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { PartnerCacheService } from '../../../../shared/services/partner-cache.service';
import { DomainFormUpdate } from '../../models/domain-form-update.model';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { Partner } from '../../../../shared/models/partner.model';
import { DomainResponseModel } from '../../models/domain.model';

@Injectable()
export class DomainCreateLimiterService {
  private _subscriptionManager = new SubscriptionManager();
  private _partnerCounts: DomainCount[] = [];
  private _lastPartnerId: number = -1;
  private _domainCountUpdateSubject = new ReplaySubject<DomainCount[]>();
  domainCountUpdate$ = this._domainCountUpdateSubject.asObservable();
  private _domainFormUpdateSubject = new Subject<DomainFormUpdate>();
  private _domainFormUpdate$ = this._domainFormUpdateSubject.asObservable().pipe(startWith(<DomainFormUpdate>{ partnerId: 0, index: -1 }));
  private _formPartnerIndexes: number[] = [];
  private _showForm: boolean = true;

  constructor(
    public partnerCacheService: PartnerCacheService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
  ) {
    this._subscriptionManager.register(
      combineLatest([
        this.partnerCacheService.partners$,
        this.partnerCacheService.currentPartner$,
        this._domainFormUpdate$,
      ])
        .pipe(
          switchMap(([partners, currentPartner, _]) => combineLatest([
            of(partners),
            of(currentPartner),
            ...partners.map(partner => this._webServiceBuilderService.builder.withApi('PartnerAPI').withUrl(`partners/${partner.id}/parkeddomains/usage`).build().get<ParkedDomainsCount>({}))
          ]))
        ).subscribe(([partners, currentPartner, ...parkedDomainsCounts]) => {
          this._lastPartnerId = currentPartner.id;
          this._partnerCounts = partners.map((p, index) => {
            const formCount = this._formPartnerIndexes.reduce((acc, i) => acc + (i === p.id ? 1 : 0), 0);
            return <DomainCount>{
              partnerId: p.id,
              partnerName: p.name,
              dailyLimit: p.domainDailyLimit,
              allTimeLimit: p.domainAllTimeLimit,
              usedToday: parkedDomainsCounts[index].daily_domain_creations + formCount,
              usedAllTime: parkedDomainsCounts[index].alltime_domain_creations + formCount
            };
          });
          this._domainCountUpdateSubject.next(this._partnerCounts);
        }));
  }

  showForm(): boolean {
    return this._showForm;
  }

  canAddDomain(): boolean {
    return this.checkCountsCanAddDomain(this._partnerCounts);
  }

  checkCountsCanAddDomain(counts: DomainCount[]): boolean {
    const dailySums = counts.reduce((acc, p) => [acc[0] + p.usedToday, acc[1] + p.dailyLimit], [0, 0]);
    const allTimeSums = counts.reduce((acc, p) => [acc[0] + p.usedAllTime, acc[1] + p.allTimeLimit], [0, 0]);
    return (dailySums[0] < dailySums[1] || counts.some(p => p.dailyLimit == null)) &&
      (allTimeSums[0] < allTimeSums[1] || counts.some(p => p.allTimeLimit == null))
  }

  isDomainLimitExceeded(): boolean {
    return this._partnerCounts.some(p => p.dailyLimit != null && p.usedToday > p.dailyLimit);
  }

  isAllTimeLimitExceeded(): boolean {
    return this._partnerCounts.some(p => p.allTimeLimit != null && p.usedAllTime > p.allTimeLimit);
  }

  clearDomains(): void {
    this._partnerCounts = [];
    this._formPartnerIndexes = [];
    this.formDomainUpdated(-2, -2);
  }

  formDomainUpdated(partnerId: number, index: number): void {
    if (partnerId === -1) {
      this._formPartnerIndexes.splice(index, 1);
    }
    else if (index > -1) {
      this._formPartnerIndexes[index] = partnerId === 0 ? this._lastPartnerId : partnerId;
    }
    this._domainFormUpdateSubject.next(<DomainFormUpdate>{ partnerId, index });
  }
}
