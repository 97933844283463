export class Division {
  public divisionId = -1;
  public divisionName: string;
  public divisionSortOrder: number;

  constructor(divisionId: number, divisionName: string, divisionSortOrder: number) {
    this.divisionId = divisionId;
    this.divisionName = divisionName;
    this.divisionSortOrder = divisionSortOrder;
  }
}
