import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OktaAuthGuard } from '@okta/okta-angular';
import { AccessControlGuardService } from '@nimbus/shared-lib';
import { DashboardMenuItemResolver } from './dashboard-landing-menu.service';
import { AreaShellComponent } from 'core-global-frontend-common-ui';

const routes: Routes = [
  {
    path: 'dashboard',
    component: AreaShellComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [OktaAuthGuard, AccessControlGuardService],
        data: {
          resourceName: 'DashboardComponent',
        },
        resolve: {
          siteMapNodes: DashboardMenuItemResolver,
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {}
