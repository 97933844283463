<div>
    <div>User Guide:</div>
    <h1>Reporting API for Bing Hosted</h1>
    <p>Welcome to the System1 Reporting API! This API is designed to provide you with easy, secure and timely access to the data that you need to manage your Bing Hosted feeds effectively.</p>
    <h2>Getting Started</h2>
    <h3>Authentication</h3>
    <p>All API calls must include an API key, which your account manager will provide via email. This API key grants access to all data relevant to your traffic. It is advisable to guard this API key carefully and distribute it only to those team members who need access to your data.</p>
    <h3>Available Reports</h3>
    <p>There are currently {{ columnClassHelper.numToWord(reportTypes.length) }} reports available for Bing Hosted data:</p>
    <ul>
        <li *ngFor="let reportType of reportTypes">
            <span class="report-type-title">{{ reportType.name }} Report: </span><span class="report-type-description">
            {{ reportType.description }}
            </span>
        </li>
    </ul>
    <h3>Metrics</h3>
    <div 
        *ngFor="let metricsSet of metrics"
        class="metrics-set"
        >
        <h4>{{ metricsSet.reportName }}</h4>
        <p class="metric-set-notes">{{ metricsSet.reportNotes }}</p>
        <div class="documentation-grid-container">
            <div
            *ngFor="let header of metricsSet.metricsOverview.headers; index as i"
            [class]="columnClassHelper.getColumnHeaderClass(metricsSet.metricsOverview, i)">
                <span class="documentation bold">{{ header }}</span>
            </div>
            <ng-container *ngFor="let record of metricsSet.metricsOverview.records">
                <div [class]="columnClassHelper.getColumnValueClass(0, metricsSet.metricsOverview)">
                    <span class="documentation bold">{{ record.metric }}</span>
                </div>
                <div [class]="columnClassHelper.getColumnValueClass(1, metricsSet.metricsOverview)">
                    <span>{{ record.definition }}</span>
                </div>
            </ng-container>
        </div>
        <hr />
    </div>
    <app-accessing-api-reports [possibleReportTypeValues]="possibleReportTypeValues"></app-accessing-api-reports>
</div>
 