import { Injectable } from '@angular/core';
import { ConfigService } from '../../../core/config.service';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { HealthMonitor } from '../models/health-monitor.model';
import { HealthMonitorWebService } from './health-monitor-web.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentDetailsService {
  healthMonitor: HealthMonitor = null;

  constructor(
    private _healthMonitorWebService: HealthMonitorWebService,
    private configService: ConfigService,
    private _snackbarService: SnackbarService
  ) {}

  populateDebugBarOptions() {
    this._healthMonitorWebService.get().subscribe(
      healthMonitor => {
        this.healthMonitor = healthMonitor;
        this.healthMonitor.debugEnvironments = this.configService.getDebugEnvironments();

        if (!this.healthMonitor.debugEnvironments.includes('Default'))
          this.healthMonitor.debugEnvironments.unshift('Default');
      },
      error => {
        this._snackbarService.open(('An error has occured: ' + error) as any, SnackBarType.error);
      }
    );
  }

  public set selectedDebugEnvironment(value: string) {
    this.configService.selectedDebugEnvironment = value;
    this.populateDebugBarOptions();
  }
  public get selectedDebugEnvironment() {
    return this.configService.selectedDebugEnvironment;
  }
}
