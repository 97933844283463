import { SortOption } from './../../../core/sort.options';
import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { IConfirmValidator } from './confirm-validator.model';
import { SearchableListItemWithSelect } from '../../../form/searchable-select-control/models/searchable-list-item-with-select';
import { FormFieldConfig } from '../../../form/form-field-config';

// Warning.  This is now embeded in the Layout system!
// If you are on a page using the layout system, rather look at simply
// using the ConfirmationService with the showAsync method!
@Component({
  selector: 'lib-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  @Input() message: string;
  outsideForm !: FormGroup;
  form !: FormGroup;
  _required = true;
  private _options: SearchableListItemWithSelect[];
  @Input() subMessage: string;
  @Input() showCloseButton = false;
  @Input() searchableSelectSortOption: SortOption = SortOption.None;
  @Input() selectHeader = 'Select a value';
  @Input() validator: IConfirmValidator;
  @Input() submitButtonName = 'Submit';
  @Input() showCancelButton = true;
  @Output() confirmation = new EventEmitter<any>();
  @Output() selectionChanged: EventEmitter<SearchableListItemWithSelect> =
    new EventEmitter<SearchableListItemWithSelect>();
  selectedItemControl = new FormControl();
  @Input() set required(required: boolean) {
    if (this._required === required) {
      return;
    }
    if (required) {
      this.selectedItemControl.setValidators([Validators.required]);
    } else {
      this.selectedItemControl.clearValidators();
    }
    this._required = required;
  }
  @Input() set options(options: SearchableListItemWithSelect[]) {
    if (this.ovs.isNullOrEmpty(options)) {
      this._options = options;
      return;
    }
    if (!this.ovs.isNullOrEmpty(options.find(x => x.selected === true))) {
      this.selectedItemControl.setValue([options.find(x => x.selected === true).value]);
    }
    this._options = options;
  }

  get options(): SearchableListItemWithSelect[] {
    return this._options;
  }

  model = {};
  private _fields: FormFieldConfig[];
  get fields(): FormFieldConfig[] {
    return this._fields;
  }
  @Input() set fields(value: FormFieldConfig[]) {
    this._fields = value;
  }

  constructor(private _formBuilder: FormBuilder, public ovs: ObjectValidatorService) {
    this.outsideForm = this._formBuilder.group({});
    this.form = this._formBuilder.group({});
    }

  ngOnInit(): void {
    this.selectedItemControl.setValidators([Validators.required]);
    this.selectedItemControl.valueChanges.subscribe(value => {
      this.selectionChanged.emit(value as SearchableListItemWithSelect);
    });
  }

  confirm(response: any) {
    if (
      response &&
      !this.ovs.isNullOrEmpty(this.options) &&
      this.ovs.isNullOrEmpty(this.selectedItemControl.value) &&
      this._required
    ) {
      return;
    }
    if (response !== false && this.ovs.isDefined(this._fields)) {
      this.confirmation.emit(this.model);
      return;
    }
    this.confirmation.emit(response);
  }

  isDisable(): boolean {
    return this.validator ? !this.validator.canConfirm() : false;
  }
}
