import { ValidationErrors, AbstractControl } from '@angular/forms';

export function KeywordsValidator(control: AbstractControl): ValidationErrors {
  return new Set(
    control.value
      .split('\n')
      .filter(keyword => keyword.trim().length > 0)
      .map(k => k.toLowerCase().trim())
  ).size >= 10
    ? {}
    : { 'keywords-validator': true };
}
