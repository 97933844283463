import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';

@Injectable()
export class TimeService {
  todayUtc(): DateTime {
    return this.todayUtcAt(0);
  }

  todayUtcAt(hourOfDay: number): DateTime {
    const startDate = new Date();
    const date = Date.UTC(
      startDate.getUTCFullYear(),
      startDate.getUTCMonth(),
      startDate.getUTCDate(),
      hourOfDay
    );
    const dateUtc = DateTime.fromMillis(date).toUTC();
    return dateUtc;
  }

  getDateUTC(): number {
    return this.getDateUTCAt(0);
  }

  getDateUTCAt(time: number): number {
    const startDate = new Date();
    return Date.UTC(
      startDate.getUTCFullYear(),
      startDate.getUTCMonth(),
      startDate.getUTCDate(),
      time
    );
  }

  ensure24h(time: number): number {
    let t = time;
    while (t < 0) {
      t += 24;
    }
    while (t > 23) {
      t -= 24;
    }
    return t;
  }

  utcToLocalTime(utcTime: Date): string {
    return DateTime.fromJSDate(utcTime).toString();
  }
}
