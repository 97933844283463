import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableauModule } from '../external-tools/tableau/tableau.module';
import {
  AngularMaterialModule,
  AreaShellComponent,
  PageShellComponent,
} from 'core-global-frontend-common-ui';
import { AccessingApiReportsComponent } from './documentation/accessing-api-reports/accessing-api-reports.component';
import { AdNetworkPixelTrackingComponent } from './documentation/ad-network-pixel-tracking/ad-network-pixel-tracking.component';
import { CampaignDomainUrlParametersComponent } from './documentation/campaign-domain-url-parameters/campaign-domain-url-parameters.component';
import { ComplianceChecklistComponent } from './documentation/compliance-checklist/compliance-checklist.component';
import { ConversionTrackingParametersComponent } from './documentation/conversion-tracking-parameters/conversion-tracking-parameters.component';
import { EmbeddedSearchComponent } from './documentation/embedded-search/embedded-search.component';
import { InstallingRampjsComponent } from './documentation/installing-rampjs/installing-rampjs.component';
import { ReferenceMaterialComponent } from './documentation/reference-material/reference-material.component';
import { ReportingApiForRsocOnlineComponent } from './documentation/reporting-api-for-rsoc-online/reporting-api-for-rsoc-online.component';
import { ReportingParametersComponent } from './documentation/reporting-parameters/reporting-parameters.component';
import { RsocGuidelinesComponent } from './documentation/rsoc-guidelines/rsoc-guidelines.component';
import { RsocGuidelinesOnlineFeedComponent } from './documentation/rsoc-guidelines-online-feed/rsoc-guidelines-online-feed.component';
import { SearchApiEndpointsComponent } from './documentation/search-api-endpoints/search-api-endpoints.component';
import { SemComponent } from './documentation/sem/sem.component';
import { SpecifyingKeywordsComponent } from './documentation/specifying-keywords/specifying-keywords.component';
import { SyndicationApiEndpointsComponent } from './documentation/syndication-api-endpoints/syndication-api-endpoints.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { CallbackRunnerModule } from 'core-global-frontend-callback-runner';
import { VideoWalkthroughsModule } from './documentation/video-walkthroughs/video-walthroughs.module';
import { RouterModule } from '@angular/router';
import { DocumentationRoutingModule } from './documentation-routing.module';
import { PushPipe } from '@ngrx/component';
import { ReportingApiForBingHostedComponent } from './documentation/reporting-api-for-bing-hosted/reporting-api-for-bing-hosted.component';

@NgModule({
  imports: [
    CommonModule,
    TableauModule,
    AngularMaterialModule,
    RouterModule,
    AreaShellComponent,
    PageShellComponent,
    ReportingApiForRsocOnlineComponent,
    ReportingApiForBingHostedComponent,
    VideoWalkthroughsModule,
    AccessingApiReportsComponent,
    ConversionTrackingParametersComponent,
    ReportingParametersComponent,
    PushPipe,
    InstallingRampjsComponent,
    SpecifyingKeywordsComponent,
    CallbackRunnerModule,
    DocumentationRoutingModule,
  ],
  declarations: [
    AdNetworkPixelTrackingComponent,
    ComplianceChecklistComponent,
    CampaignDomainUrlParametersComponent,
    EmbeddedSearchComponent,
    ReferenceMaterialComponent,
    RsocGuidelinesComponent,
    RsocGuidelinesOnlineFeedComponent,
    SearchApiEndpointsComponent,
    SemComponent,
    SyndicationApiEndpointsComponent,
    DocumentationComponent,
  ],
})
export class DocumentationModule {}
