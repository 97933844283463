import { QRow } from './q-row';
import { ViewContainerRef, ComponentRef } from '@angular/core';
import { QCellEditorComponent } from '../q-row/q-cell-editor/q-cell-editor.component';
import { CellEditParameter } from './cell-edit-parameter';
import { ColumnConfiguration } from './column-configuration';
import { Subject } from 'rxjs';
import { RowCol } from './copy-model';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { InvalidCharactersBlocker } from '../plugins/invalid-characters-blocker';
import { GridCallbackParameter } from './grid-callback-parameter';

export class CellEditor {
  private _oldContainerRef: ViewContainerRef;
  private _closed: boolean;
  rowData: QRow;
  onLeave: Subject<void>;
  onFocus: Subject<RowCol>;
  column: ColumnConfiguration;

  constructor(
    private _defaultContainerRef: ViewContainerRef,
    private _cellEditorComponentRef: ComponentRef<QCellEditorComponent>,
    private _ovs: ObjectValidatorService,
    public columns: ColumnConfiguration[],
  ) {
    this._cellEditorComponentRef.instance.setCellEditor(this);
    this.onLeave = new Subject<void>();
    this.onFocus = new Subject<RowCol>();
    this.rowData = null;
    this._closed = true;
    this._oldContainerRef = this._defaultContainerRef;
    //Untrack
    this._cellEditorComponentRef.instance.onLeave.subscribe(() =>
      this.onLeave.next(null),
    );
    //Untrack
    this._cellEditorComponentRef.instance.onFocus.subscribe(() => {
      if (_ovs.isDefined(this.rowData) && _ovs.isDefined(this.column)) {
        this.onFocus.next({
          row: this.rowData.key,
          col: this.column.visibleColumnNumber,
        });
      }
    });
  }

  isEditClosed(): boolean {
    return this._closed;
  }

  unsubscribe() {
    if (this.onLeave) {
      this.onLeave.unsubscribe();
    }
    if (this.onFocus) {
      this.onFocus.unsubscribe();
    }
  }

  private _setCellEditorOnNewContainer(newContainer: ViewContainerRef) {
    this._oldContainerRef.detach(
      this._oldContainerRef.indexOf(this._cellEditorComponentRef.hostView),
    );
    newContainer.insert(this._cellEditorComponentRef.hostView);
    this._oldContainerRef = newContainer;
  }

  closeCellEdit() {
    if (this._closed) {
      return;
    }
    const foundBlocker =
      this._ovs.isNullOrEmpty(this.column) ||
      this._ovs.isNullOrEmpty(this.column.cellPlugins)
        ? null
        : (this.column.cellPlugins.find(
            plugin => plugin instanceof InvalidCharactersBlocker,
          ) as InvalidCharactersBlocker);
    if (this._ovs.isDefined(foundBlocker)) {
      const colName = this.column.fieldName;
      this.rowData.update(
        colName,
        foundBlocker.clean(this.rowData.value[colName]),
      );
    }
    this._cellEditorComponentRef.instance.closeCellEdit();
    this._setCellEditorOnNewContainer(this._defaultContainerRef);
    this._closed = true;
    if (this._ovs.isNullOrEmpty(this.columns)) {
      return;
    }
  }

  openCellEdit(param: CellEditParameter) {
    if (!param.column.requiresCellEditor) {
      return;
    }
    this.closeCellEdit();
    this._cellEditorComponentRef.instance.emptyFields();
    this._closed = false;
    this.column = param.column;
    this.rowData = param.rowData;
    this._setCellEditorOnNewContainer(
      this.rowData.rowComponent.getEditContainerViewRef(
        param.column.visibleColumnNumber,
      ),
    );
    this._cellEditorComponentRef.instance.openCellEdit(param);
  }
}
