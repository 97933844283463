import { ValidationErrors, AbstractControl } from "@angular/forms";
import { FormlyFieldConfig } from "@ngx-formly/core";

export function MatchCharacterLengthValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: any = {}
): ValidationErrors {
  return !control.value || !options.numCharacters || control.value.length === options.numCharacters ? {} : { 'match-character-length-validator': true };
}
