import { Injectable } from '@angular/core';
import { QGrid } from '../q-models/q-grid';
import { ColumnConfiguration } from '../q-models/column-configuration';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { GridValue } from '../q-models/grid-value';
import { StringFunctionsService } from '../shared/string-functions.service';

@Injectable()
export class GridValueBuilderService {
  constructor(
    private _stringFuncs: StringFunctionsService,
    private _ovs: ObjectValidatorService,
  ) {}

  build(
    items: QGrid,
    columns: ColumnConfiguration[],
    hasFilter: () => boolean,
  ) {
    return new GridValue(
      this._stringFuncs,
      this._ovs,
      items,
      columns,
      hasFilter,
    );
  }
}
