import { Inject, Injectable } from '@angular/core';
import { HttpClientWrapper } from './http-client-wrapper.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { Observable } from 'rxjs';
import { ConfigService } from '@nimbus/shared-lib';

/**
 * @deprecated Use the Nimbus Web Client instead, this is an edge case for Tableau only.
 */
@Injectable({
  providedIn: 'root'
})
export class GatewayService {
  private _apiHost;

  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _httpClientWrapper: HttpClientWrapper,
    _configService: ConfigService
  ) {
    this._apiHost = _configService.getApi().host;
  }

  get(route: string, parameters?: any | null): Observable<any> {
    return this._httpClientWrapper.get(
      `${this._apiHost}/${route}`,
      parameters,
      this._oktaAuth.getAccessToken()
    );
  }

  delete(route: string, parameters?: any | null): Observable<any> {
    return this._httpClientWrapper.delete(
      `${this._apiHost}/${route}`,
      parameters,
      this._oktaAuth.getAccessToken()
    );
  }
}
