import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseWebService } from '../../core/base-web.service';
import { UserModel } from './user.model';
import { ConfigService } from '../../core/config.service';
import { HttpClientQ } from 'core-global-frontend-http';

@Injectable()
export class UserWebService extends BaseWebService {
  constructor(
    private _configService: ConfigService,
    private _http: HttpClientQ,
  ) {
    super();
  }

  getUsers(): Observable<UserModel[]> {
    return this._http.get(
      this._configService.getApi().host +
        this._configService.getRoute('Users').path,
    ) as any;
  }
}
