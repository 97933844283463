export class Form {
  constructor(
    public route: string,
    public formName: string,
    public formFields: string,
    public id: number
  ) {}
}

export const FormsFeature: string = 'forms';
