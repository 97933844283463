<div class="menu-card">
    <div class="menu-card__text-side">
        <h2 class="menu-card__title">{{siteMapNode.label}}</h2>
        <p class="menu-card__description">{{siteMapNode.description}}</p>
        <button mat-button class="menu-card__btn" (click)="'/'+followLink(siteMapNode.path)">
            <div class="menu-card__btn-content">
                {{siteMapNode.dashboardCard.ctaLabel}}
            </div>
        </button>
    </div>
    <div class="menu-card__image-side">
        <img src="{{siteMapNode.dashboardCard.icon}}" alt="{{siteMapNode.label}} image"
            class="menu-card__image-side__{{siteMapNode.dashboardCard.iconClassLabel}}" />
    </div>

    
</div>