import { Injectable, ViewContainerRef, ComponentRef } from '@angular/core';
import { CellEditor } from '../q-models/cell-editor';
import { ColumnConfiguration } from '../q-models/column-configuration';
import { QCellEditorComponent } from '../q-row/q-cell-editor/q-cell-editor.component';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable()
export class CellEditorBuilderService {
  constructor(private _ovs: ObjectValidatorService) {}
  build(
    defaultContainerRef: ViewContainerRef,
    cellEditorComponentRef: ComponentRef<QCellEditorComponent>,
    columns: ColumnConfiguration[],
  ): CellEditor {
    return new CellEditor(
      defaultContainerRef,
      cellEditorComponentRef,
      this._ovs,
      columns,
    );
  }
}
