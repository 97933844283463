<div
  [class]="
    (partnerCacheService.partners$ | async | callbackRunnerPipe: ovs.isNullOrEmpty) &&
    (partnerCacheService.isLoading$ | async)
      ? 'gray-loader'
      : ''
  ">
  <ng-container
    *ngIf="
      !(partnerCacheService.partners$ | async | callbackRunnerPipe: ovs.isNullOrEmpty) &&
      (partnerCacheService.isLoading$ | async) === false
    ">
    <div class="domain-subvertical-request divider-line">
      <app-subvertical-request-form></app-subvertical-request-form>
    </div>

    <div class="history-header centered-with-top-header">
      <span class="history-header-icon">
        <svg role="img">
          <use xlink:href="assets/img/sprites.svg#subvertical-history-icon" />
        </svg>
      </span>
      <span class="history-header-text">Request History</span>
    </div>
    <div class="history-body centered-with-top-header">
      <app-subvertical-request-history [class]="(subverticalRequestCacheService.isLoading$|ngrxPush) ? 'gray-loader' : ''"></app-subvertical-request-history>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      (partnerCacheService.partners$ | async | callbackRunnerPipe: ovs.isNullOrEmpty) &&
      (partnerCacheService.isLoading$ | async) === false
    ">
    <div class="domain-subvertical-request">
      <p>You do not have a Partner ID associated with this account.</p>
    </div>
  </ng-container>
</div>
