<div class="formly-field-input container">
  <svg
    *ngIf="field.props['showInfoButton']"
    [matTooltip]="props['toolTip']"
    role="img"
    class="formly-field-input svg">
    <use xlink:href="assets/img/sprites.svg#ramp-info-icon" />
  </svg>
  <lib-searchable-select-control
    *ngIf="selectOptions$"
    [class]="(isLoading$ | async) ? 'gray-loader' : ''"
    [populateListOnLoad]="field.props['populateListOnLoad']"
    [control]="formControl"
    [searchableListItems]="selectOptions$ | async"
    [sortOption]="searchableSelectSortOption"
    [enableMultiSelect]="field.props['enableMultiSelect']"
    [label]="field.props['label']"
    [showAdd]="field.props['showAdd']"
    [readonly]="field.props['readOnly']"
    data-test="lib-multi-select-dropdown">
  </lib-searchable-select-control>
</div>
