import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Role } from '../../features/access-control/models/role';
import { User } from '../../features/access-control/models/user';
import { BaseWebService } from '../../core/base-web.service';
import { ConfigService } from '../../core/config.service';
import { UserModel } from '../../services/user/user.model';
import { tap } from 'rxjs/operators';
import { HttpClientQ } from 'core-global-frontend-http';

@Injectable({
  providedIn: 'root',
})
export class ImpersonationWebService extends BaseWebService {
  constructor(
    private _configService: ConfigService,
    private _http: HttpClientQ,
  ) {
    super();
  }

  getUsers(): Observable<UserModel[]> {
    this._apiCallInProgressSubject.next(true);
    return this._http
      .get(
        this._configService.getApi().host +
          this._configService.getRoute('UsersByApplication', [
            this._configService.getApplicationId().toString(),
          ]).path,
      )
      .pipe(
        tap(() => this._apiCallInProgressSubject.next(false)),
        catchError(err => throwError(err)),
      );
  }

  getRoles(): Observable<Role[]> {
    this._apiCallInProgressSubject.next(true);
    return this._http
      .get(
        this._configService.getApi().host +
          this._configService.getRoute('Roles', [
            this._configService.getApplicationId().toString(),
          ]).path,
      )
      .pipe(
        tap(() => this._apiCallInProgressSubject.next(false)),
        catchError(err => throwError(err)),
      );
  }

  getRolesBy(userId: string): Observable<User> {
    this._apiCallInProgressSubject.next(true);
    return this._http
      .get(
        this._configService.getApi().host +
          this._configService.getRoute('UserInfo', [userId]).path,
      )
      .pipe(
        tap(() => this._apiCallInProgressSubject.next(false)),
        catchError(err => throwError(err)),
      );
  }
}
