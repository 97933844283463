export enum DateSelectionType {
  Yesterday = 'Yesterday',
  TwoDaysAgo = '2 days ago',
  ThreeDaysAgo = '3 days ago',
  LastThreeDays = 'Last 3 days',
  ThreeDaysPriorToYesterday = '3 Days Prior to Yesterday',
  LastSevenDays = 'Last 7 days',
  SevenDaysPriorToYesterday = '7 Days Prior to Yesterday',
  ThisWeek = 'This week',
  LastWeek = 'Last Week',
  LastFourteenDays = 'Last 14 days',
  LastThirtyDays = 'Last 30 days',
  ThisMonth = 'This month',
  LastMonth = 'Last month',
  MonLast30 = 'Mondays, last 30 days',
  TuesLast30 = 'Tuesdays, last 30 days',
  WedLast30 = 'Wednesdays, last 30 days',
  ThursLast30 = 'Thursdays, last 30 days',
  FriLast30 = 'Fridays, last 30 days',
  SatLast30 = 'Saturdays, last 30 days',
  SunLast30 = 'Sundays, last 30 days',
  NineDays = 'Last 9 Days',
  Custom = 'Custom'
}
