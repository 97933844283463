import { Injectable } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root'
})
export class CombineGeoLanguageSubdomainVerticalInputService {
  private _geoValue: string;
  private _languageValue: string;
  private _verticalValue: string;

  get StaticGeoValue(): string {
    return this._geoValue;
  }
  get StaticLanguageValue(): string {
    return this._languageValue;
  }
  get StaticVerticalValue(): string {
    return this._verticalValue;
  }
  UpdateStaticVerticalValue(value: string, field: FormlyFieldConfig) {
    if (value === null) {
      return;
    }
    this._verticalValue = value.trim().toLowerCase();
    setTimeout(() => {
      field.parent?.formControl.get('subVertical').updateValueAndValidity();
    }, 100);
  }
  UpdateStaticGeoValue(value: string, field: FormlyFieldConfig) {
    if (value === null) {
      return;
    }
    this._geoValue = value.trim().toLowerCase();
    setTimeout(() => {
      field.parent?.formControl.get('subVertical').updateValueAndValidity();
    }, 100);
  }
  UpdateStaticLanguageValue(value: string, field: FormlyFieldConfig) {
    if (value === null) {
      return;
    }
    this._languageValue = value.trim().toLowerCase();
    setTimeout(() => {
      field.parent?.formControl.get('subVertical').updateValueAndValidity();
    }, 100);
  }

  clear() {
    this._geoValue = null;
    this._languageValue = null;
    this._verticalValue = null;
  }
}
