<div class="documentation tab-content">
  <h3 class="documentation-sub-header">Segments</h3>
  <div>
    A segment is the identifier used in embedded-search to identify a bucket of your traffic. This
    key is used to provide the correct configuration of results on your pages, as well as to
    identify your traffic in our reporting system. Work with your partnership manager to configure
    your segments.
  </div>
  <br />
  <h3 class="documentation-sub-header">Regions</h3>
  <div>
    The embedded search service is built around the “regions” on your page. In HTML notation, these
    are the container divs that the javascript will place the content like ads, organic results, and
    related searches.
    <br /><br />
    A very simple search page will have exactly one content div, typically called the “mainline”
    div. It’s also possible to have a “sidebar” div where things like related searches will be
    displayed.
    <br /><br />
    For some implementations, you only want ads and you want them to be placed above and below your
    mainline search results. We typically call those content divs “mainline-top” and
    “mainline-bottom”. You can still have a “sidebar” content div in this case, too.
    <br /><br />
    These are just guidelines - there’s no set requirement of what the divs are named and where they
    are located on the page, as long as you communicate it clearly with your account manager and
    stay consistent on a per-segment basis.
  </div>
  <br />
  <h3 class="documentation-sub-header">Implementation Steps</h3>
  <div class="documentation-highlighted">Communicate Requirements to your Account Manager</div>
  <div class="documentation shifted">
    The first step is to share details of your proposed implementation with your account manager.
    <br /><br />
    The key things to include are:
    <ol>
      <li *ngFor="let requirement of communicationRequirements">{{ requirement }}</li>
    </ol>
  </div>
  <div class="documentation-highlighted">Get Parameters from your Account Manager</div>
  <div class="documentation shifted">
    After communicating all the requirements to your Account Manager, they should give you back the
    following things:
    <ol>
      <li *ngFor="let params of accountParameters">{{ params }}</li>
    </ol>
  </div>
  <div class="documentation-highlighted">JavaScript Changes</div>
  <div class="documentation shifted">
    Now you’re ready to finish the integration. After your web server has calculated the signature
    token using the access key (see the section below on the Authentication Algorithm), the web page
    returned to the browser client will include a few lines of JavaScript required to request and
    render search results.
  </div>
  <div class="documentation-highlighted">Include reference to System1 JS</div>
  <div class="documentation shifted">
    In your web application include a reference to System1’s client-side search results JS at the
    end of the body tag, the JS includes configuration settings specific to your segment.
    <br /><br />
    <span class="documentation-highlighted background">{{ jsScript }}</span>
  </div>
  <div class="documentation-highlighted">Construct page elements</div>
  <div class="documentation shifted">
    The System1 JS will execute an asynchronous client-side call to System1 for search results, and
    the JSON response will be used by the System1 JS to build the page markup that makes up the
    Search modules. All search results will render simultaneously on the page. For each search
    results container include a div on the page where it should appear. For each div include the
    data-s1search attribute that matches the containers specified to you by your account manager.
    <p>Sample HTML:</p>
    <span class="documentation-highlighted background">
      <span *ngFor="let element of pageElements">{{ element }}<br /></span>
    </span>
  </div>
  <div class="documentation-highlighted">Configure s1search() request</div>
  <div class="documentation shifted">
    The parameters you include in the s1search() call will be passed to System1’s search service to
    retrieve search results. Include the script block to call s1search(), as seen in the below
    example, in the &lt;head&gt; tag of your page.
    <p>Sample s1search() call and parameters</p>
    <span class="documentation-highlighted background">
      {{ searchScript }}
    </span>
    <p>Key Parameters</p>
  </div>
  <div class="documentation-grid-container" [style]="getHeaderCount(searchParametersTable)">
    <div
      *ngFor="let header of searchParametersTable.headers; index as i"
      [class]="getColumnHeaderClass(searchParametersTable, i)">
      <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let record of searchParametersTable.data">
      <div [class]="getColumnValueClass(0, searchParametersTable)">
        <span>{{ record.key }}</span>
      </div>
      <div [class]="getColumnValueClass(1, searchParametersTable)">
        <span>{{ record.description }}</span>
      </div>
      <div [class]="getColumnValueClass(2, searchParametersTable)">
        <span>{{ record.accepted_values }}</span>
      </div>
      <div [class]="getColumnValueClass(3, searchParametersTable)">
        <span>{{ record.default }}</span>
      </div>
    </ng-container>
  </div>
  <div class="documentation-highlighted">
    Note on Testing from Staging & Development Environments
  </div>
  <div class="documentation shifted">
    If you have a staging or development environment you want to setup your integration with, you
    can do so with the following steps:
    <br />
    <ol>
      <li *ngFor="let note of testingNotes">{{ note }}</li>
    </ol>
  </div>
  <div class="documentation-highlighted">Signing the Request</div>
  <div class="documentation shifted">
    System1’s embedded-search API requires you to sign all requests. This allows the API to validate
    that the requests originated from your application, and prevents unauthorized access to the API
    using your credentials. Without a valid signature, the requests will be rejected, and no results
    returned.
    <br /><br />
    Prerequisites:
    <ul>
      <li *ngFor="let request of requestPrerequisites; index as i">
        {{ request }}
        <span *ngIf="i === requestPrerequisites.length - 1">
          <ol>
            <li *ngFor="let hashVal of binaryHashValues">{{ hashVal }}</li>
          </ol>
        </span>
      </li>
    </ul>
    <span class="documentation italic">
      Note: The query term used to generate the signature must be the exact same query term that is
      passed to the s1search() request. For example, if the query term will be trimmed of trailing
      spaces before making the request to s1search(), ensure this happens prior to generating the
      signature.
    </span>
  </div>
  <div class="documentation-highlighted">Algorithm</div>
  <div class="documentation shifted">
    The basic algorithm for creating the signature starts with creating a timestamp as follows:
    <ol>
      <li>Start with the UTC/GMT time</li>
      <li>Round to the nearest minute (30 seconds or greater rounds up, otherwise round down)</li>
      <li>
        Format the time as a string: yyyyMMddHHmm
        <ul>
          <li *ngFor="let format of timeFormat">{{ format }}</li>
        </ul>
      </li>
      <li>
        Concatenate the values together in the following order:
        <ul>
          <li *ngFor="let order of concatenationOrder">{{ order }}</li>
        </ul>
      </li>
      <li>Encode concatenated string into a UTF-8 byte encoding.</li>
      <li>Perform a SHA-256 (preferred) or SHA-1 hash of the UTF-8 encoded string value.</li>
      <li>
        Encode the binary hashed value using url-safe base-64 encoding and trim any padding
        characters (“=“ sign in base64); see
        <a target="_blank" href="http://tools.ietf.org/html/rfc4648#section-5">
          http://tools.ietf.org/html/rfc4648#section-5</a
        >
        <ul>
          <li>
            Caution: some languages’ hash functions default to a hex output instead of a binary
            output (e.g. PHP is a common language that does this); ensure you are base64 encoding
            the <span class="documentation bold">binary</span> hash output, not the hex
            representation.
          </li>
          <li>
            Note: url-safe base64 encoding is a variation on standard base64 encoding and results in
            slightly different output. Consult your language documentation for an implementation or
            review the link above.
          </li>
        </ul>
      </li>
    </ol>
    Example implementations in several programming languages can be found here for reference:
    <a target="_blank" href="http://www.infospace.com/partners/sdk/csr/signingSample.html">
      http://www.infospace.com/partners/sdk/csr/signingSample.html
    </a>
    <br /><br />
    Below is a step by step sample of the outputs during signature construction that can be used to
    help troubleshoot any issues you may be having.
    <br /><br />
    Starting with the following sample inputs:
    <br />
    <span class="documentation shifted" *ngFor="let input of sampleInputs">{{ input }}<br /></span>
    <ol>
      <li *ngFor="let step of sampleSteps">{{ step }}</li>
    </ol>
  </div>
  <h3 class="documentation-sub-header">Click Tracking in Search Results</h3>
  <div>
    Goal: gain deeper insights to user behavior on an System1 search experience with our Site
    Tracking solution. System1 enables partners to receive data related to a click event for
    reporting and analysis.
  </div>
  <br />
  <div class="documentation-highlighted">How it works</div>
  <div class="documentation shifted">
    The partner provides a click handler URL that the System1 click handler calls every time a click
    event occurs. This URL may contain custom data via query string parameters that a partner needs
    with every click, (e.g. Token) that will be passed back every time the URL is called. In
    addition to any custom data, the System1 click handler can supply a number of variables that
    characterize that particular click.
    <br />
    <p>Supported parameters:</p>
  </div>
  <div class="documentation-grid-container" [style]="getHeaderCount(clickTrackingParametersTable)">
    <div
      *ngFor="let header of clickTrackingParametersTable.headers; index as i"
      [class]="getColumnHeaderClass(clickTrackingParametersTable, i)">
      <span class="documentation bold">{{ header }}</span>
    </div>
    <ng-container *ngFor="let record of clickTrackingParametersTable.data">
      <div [class]="getColumnValueClass(0, clickTrackingParametersTable)">
        <span>{{ record.key }}</span>
      </div>
      <div [class]="getColumnValueClass(1, clickTrackingParametersTable)">
        <span>{{ record.description }}</span>
      </div>
      <div [class]="getColumnValueClass(2, clickTrackingParametersTable)">
        <span>{{ record.type }}</span>
      </div>
      <div [class]="getColumnValueClass(3, clickTrackingParametersTable)">
        <span>{{ record.sample_value }}</span>
      </div>
    </ng-container>
  </div>
  <div class="documentation shifted">
    To begin, pass the URL in as the clickTrackingUrl parameter in s1search() call. If you are a
    hosted partner, provide your partnership manager with your click handler URL.
    <br /><br />
    A sample URL used to forward the click to the partner click handler looks like this (The example
    below is for documentation purposes only, and is not intended to be used):
    <br />
    <div class="documentation shifted">
      <span class="documentation-highlighted background"
        >http://partner.clickserver.com/ClickHandler?partnerCustomParameter=value1
        &secondParameter=value2&page=&#123;info[page]&#125;&query=&#123;info[query]&#125;
        &ip=&#123;requestu_args[ip]&#125;&paid=&#123;extra_args[p]&#125;
        &backend=&#123;extra_args[b]&#125;&position=&#123;extra_args[position]&#125;</span
      >
    </div>
    A sample URL used to forward the click to the partner click handler looks like this (The example
    below is for documentation purposes only, and is not intended to be used):
    <br />
    <div class="documentation shifted">
      <span class="documentation-highlighted background"
        >http://partner.clickserver.com/ClickHandler?partnerCustomParameter=value1
        &secondParameter=value2&page=0&query=dogs+and+cats
        &ip=139.31.222.12&paid=1&backend=google&position=2</span
      >
    </div>
  </div>
</div>
