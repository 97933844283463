import { Injectable } from '@angular/core';
import { NavigationItem, NavigationType, NavigationSet } from './navigation.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ObjectValidatorService } from '../../core/object-validator-service/object-validator.service';
import { Event, Router, NavigationEnd } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { SubjectManager } from '../../core/subject-manager';
import { NavigatorView } from './navigator/navigator-view';

@Injectable()
export class NavigationService {
  private _menu: NavigationItem[] = [];
  private _headersList: NavigationItem[] = [];
  private _subHeadersSet: NavigationSet[] = [];
  private _subjectManager = new SubjectManager();
  onViewChange$: Observable<NavigatorView> = of(NavigatorView.Expanded);
  currentUrl = new BehaviorSubject<string>(undefined);
  isNavbarExpanded = false;

  get menu(): NavigationItem[] {
    return this._menu;
  }

  addSubHeaderSetToList(set: NavigationSet) {
    this._subHeadersSet.push(set);
  }

  constructor(private _ovs: ObjectValidatorService, public router: Router) {
    this.router.events.pipe(debounceTime(250)).subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl.next(event.urlAfterRedirects);
      }
    });
    this._subjectManager.register('navToggleState');
    this._subjectManager.register('viewChange');
    this.onViewChange$ = this._subjectManager.getObservable('viewChange');
    this.setToggleState(true);
  }

  setToggleState(value: boolean) {
    this._subjectManager.next('navToggleState', value);
  }

  getToggleState(): Observable<boolean> {
    return this._subjectManager.getObservable('navToggleState');
  }

  addHeader(header: NavigationItem) {
    this._headersList.push(header);
  }

  // Called by the navigator component when ready to render.
  build(): NavigationItem[] {
    this._menu = [];
    if (this._ovs.isDefined(this._headersList)) {
      for (const value of this._headersList) {
        this._addMenuHeader(value);
      }
    }
    if (this._ovs.isDefined(this._subHeadersSet)) {
      for (const value of this._subHeadersSet) {
        this.addMenuItem(value.navigationItem, value.parent);
      }
    }

    return this._menu;
  }

  private _addMenuHeader(headerItem: NavigationItem) {
    const header = new NavigationItem(
      headerItem.label,
      headerItem.order,
      headerItem.icon,
      null,
      [],
      NavigationType.Header,
      headerItem.href,
      headerItem.elementId,
      headerItem.componentSelector
    );

    this._menu.push(header);
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  addMenuItem(item: NavigationItem, parent?: string): void {
    if (!this._ovs.isDefined(item)) {
      return;
    }

    if (
      !this._ovs.isDefined(parent) &&
      this._menu.filter(x => this._ovs.isDefined(x) && x.label === item.label).length === 0
    ) {
      this._menu.push(item);
    }
    this._menu.sort((a, b) => this._orderMenuByOrder(a, b));
    if (this._ovs.isDefined(parent)) {
      this._addSubMenuToCorrectLevel(this._menu, item, parent);
    }
  }

  private _addSubMenuToCorrectLevel(
    menu: NavigationItem[],
    item: NavigationItem,
    parent: string
  ): void {
    if (menu.filter(x => this._ovs.isDefined(x) && x.label === parent).length === 0) {
      for (const value of menu) {
        if (this._ovs.isDefined(value.children) && value.children.length !== 0) {
          this._addSubMenuToCorrectLevel(value.children, item, parent);
        }
      }
    } else {
      const submenu = menu.filter(x => this._ovs.isDefined(x) && x.label === parent)[0].children;
      submenu.push(item);
      submenu.sort((a, b) => this._orderMenuByOrder(a, b));
    }
  }

  isMenuHeader(type: string): boolean {
    return type === NavigationType.Header;
  }

  private _orderMenuByOrder(a: NavigationItem, b: NavigationItem) {
    return a.order - b.order;
  }

  toggleSidenav() {
    this.isNavbarExpanded = !this.isNavbarExpanded;
    this._subjectManager.next(
      'viewChange',
      this.isNavbarExpanded ? NavigatorView.Expanded : NavigatorView.Collapsed
    );
  }

  expandNavbar() {
    if (!this.isNavbarExpanded) {
      this.toggleSidenav();
    }
  }
}
