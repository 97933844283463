import { Component } from '@angular/core';
import { ImpersonationService } from '../impersonation.service';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';

@Component({
  selector: 'lib-impersonation-status',
  templateUrl: './impersonation-status.component.html',
  styleUrls: ['../impersonation.component.scss']
})
export class ImpersonationStatusComponent {
  get impersonationDisplayText(): string {
    return this._ovs.isNullOrEmpty(this.impersonationService.proxyUserName)
      ? 'User not selected'
      : this.impersonationService.proxyUserName;
  }

  constructor(
    public impersonationService: ImpersonationService,
    private _ovs: ObjectValidatorService
  ) {}

  exitImpersonation() {
    this.impersonationService.exit();
  }
}
