import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ColumnInfo } from 'core-global-frontend-grid';

@Injectable()
export class SubverticalRequestsHistoryListColumnsBuilderService {
  readonly columns: ColumnInfo[] = [
    {
      field: 'friendlyStatus',
      type: 'frontend-status-cell',
      toolTip: 'Current status of the subvertical request.',
      label: 'Status',
    },
    {
      type: 'subvertical-history-action-cell',
      field: 'na',
      toolTip: 'Click to review the subvertical request',
      label: 'Action',
    },
    {
      field: 'vertical',
      label: 'Vertical',
    },
    {
      field: 'subvertical',
      label: 'Subvertical',
    },
    {
      field: 'geoFullName',
      label: 'Geo',
    },
    {
      field: 'languageFullName',
      label: 'Language',
    },
    {
      field: 'requestedDate',
      transform: (value): any => this._datePipe.transform(value, 'yyyy-MM-dd'),
      label: 'Date Submitted',
    },
    {
      field: 'reviewedDate',
      transform: (value): any => this._datePipe.transform(value, 'yyyy-MM-dd'),
      label: 'Date Reviewed',
    },
  ];

  constructor(private _datePipe: DatePipe) {}
}
