import { Form } from './form';

export interface IFormState {
  forms?: Form[];
  selectedForm?: Form;
  loading: boolean;
}

export const initialFormsState: IFormState = {
  forms: undefined,
  selectedForm: undefined,
  loading: true
};
