<div
  *ngFor="let field of field.fieldGroup; let panelIndex = index"
  class="row align-items-baseline item-container">
  <mat-expansion-panel
    [expanded]="isExpanded(panelIndex)"
    (opened)="panelOpened(panelIndex)"
    (closed)="panelClosed(panelIndex)"
    [class]="{ panel: true, base: true, collapsed: !isExpanded(panelIndex) }">
    <mat-expansion-panel-header *ngIf="showExpansionHeader()">
      <mat-panel-title
        >{{ getPanelHeader(panelIndex) }}
        <mat-icon *ngIf="invalidCheck(field, panelIndex) && !isExpanded(panelIndex)">
          <svg role="img" class="exclamation">
            <use xlink:href="assets/img/sprites.svg#ramp-exclamation-icon" />
          </svg>
        </mat-icon>
      </mat-panel-title>
      <mat-panel-description>
        {{ isExpanded(panelIndex) ? 'hide ' : 'show ' }} options
      </mat-panel-description>
    </mat-expansion-panel-header>
    <formly-field class="col" [field]="field"></formly-field>
  </mat-expansion-panel>
  <div>
    <button
      class="btn btn-danger remove"
      type="button"
      (click)="removeDomain(panelIndex)"
      *ngIf="this.form.controls['domains'].value.length > 1">
      <svg role="img" class="remove-icon">
        <use xlink:href="assets/img/sprites.svg#partner-trash-icon" />
      </svg>
    </button>
  </div>
  <hr *ngIf="showDivider(panelIndex)" />
</div>
