import { Component } from '@angular/core';
import { BasePageComponent } from '@nimbus/shared-lib';
import { PartnerCacheService } from '../../../shared/services/partner-cache.service';
import { switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ConfirmationSubscriberService } from '../../../shared/services/confirmation-subscriber.service';
import { SubverticalRequestCacheService } from './services/subvertical-request-cache.service';

@Component({
  selector: 'app-subvertical-request',
  templateUrl: './subvertical-request.component.html',
  styleUrls: ['./subvertical-request.component.scss']
})
export class SubverticalRequestComponent extends BasePageComponent {
  constructor(
    public partnerCacheService: PartnerCacheService,
    public subverticalRequestCacheService: SubverticalRequestCacheService,
    public _confirmationSubscriberService: ConfirmationSubscriberService
  ) {
    super();
    this._subscriptionManager.register(
      this._confirmationSubscriberService.populateModal$
        .pipe(
          switchMap(value => {
            if (value) {
              return this._confirmationService.showAsync(
                'Warning',
                'Delete',
                null,
                null,
                true,
                true,
                null,
                null,
                'This will delete the Subvertical Request permanently. Do you want to proceed?',
                true
              );
            }
            return of(false);
          })
        )
        .pipe(
          tap(value => {
            if (value) {
              this._confirmationSubscriberService.emitConfirmation();
              this._confirmationSubscriberService.clearEmitModal();
            } else {
              this._confirmationSubscriberService.emitCloseModalConfirmation();
            }
          })
        )
        .subscribe()
    );
  }
}
