import { AfterViewInit, ElementRef, Input, OnDestroy } from '@angular/core';
import {
  Component,
  ComponentFactoryResolver,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { DockVisibleState } from '../../layout/dock/dock-visible-state';
import { DockContent } from '../../layout/dock/dock-content';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { LayoutService } from '../layout.service';
import { ConfirmationService } from '../confirmation-wrapper/confirmation.service';
import { SubjectManager } from '../../../core/subject-manager';
import { TopOptionsTogglerCacheService } from '../../top-options-toggler/top-options-toggler-cache.service';

@Component({
  selector: 'lib-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements AfterViewInit, OnDestroy {
  private readonly _subscriptionManager = new SubscriptionManager();
  private readonly _subjectManager = new SubjectManager();
  @ViewChild('preFiltersContainer', { read: ViewContainerRef, static: true })
  preFiltersContainer: ViewContainerRef;
  @ViewChild('preFiltersDivWrapper', { read: ElementRef, static: true })
  private _preFiltersDivWrapper: ElementRef;
  @ViewChild('dockPageContainer', { static: true })
  private _dockPageContainer: ElementRef;
  @Input() topBarShown = false;
  dockContent$: Observable<DockContent>;
  model$: Observable<any>;
  visibleDockableIndex$: Observable<number>;
  dockVisibleState: typeof DockVisibleState = DockVisibleState;
  isResizing = false;
  bottomDockHeight$: Observable<number>;
  maximizedHeight = 100;

  get prefiltersHeight(): any {
    if (this.ovs.isDefined(this._preFiltersDivWrapper)) {
      return this._preFiltersDivWrapper.nativeElement.clientHeight;
    }
    return null;
  }

  constructor(
    public ovs: ObjectValidatorService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public layoutService: LayoutService,
    public confirmationService: ConfirmationService,
    public topOptionsTogglerCacheService: TopOptionsTogglerCacheService
  ) {
    this._subjectManager.register('bottomDockHeight');
    this.bottomDockHeight$ = this._subjectManager.getObservable('bottomDockHeight');
    this._subjectManager.next('bottomDockHeight', 50);
    layoutService.dockVisibilityChange$.pipe(debounceTime(300)).subscribe(() => {
      layoutService.parentDockResizeSubject.next(
        this._dockPageContainer.nativeElement.clientHeight
      );
    });
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
    this._subjectManager.clear();
  }

  ngAfterViewInit(): void {
    this._subscriptionManager.clear();
    this.layoutService.closeDock(false);
    this.dockContent$ = this.layoutService.dockContentChange$;
    this.model$ = this.layoutService.modelChange$;
    this.visibleDockableIndex$ = this.layoutService.visibleDockableIndexChange$;
    this._subscriptionManager.register(this.layoutService.dockVisibilityChange$.subscribe());
    this._createPreFilterComponent();
    setTimeout(() => {
      this.layoutService.parentDockResizeSubject.next(
        this._dockPageContainer.nativeElement.clientHeight
      );
    }, 300);
  }

  modelUpdate(event) {
    this.layoutService.model = event;
  }

  private _createPreFilterComponent() {
    this.layoutService.preFiltersComponentChange$
      .pipe(
        tap(() => this.preFiltersContainer.clear()),
        filter(preFiltersComponent => {
          const define = this.ovs.isDefined(preFiltersComponent);
          this.layoutService.topOptionTogglerVisible = define;
          return define;
        })
      )
      .subscribe(preFiltersComponent => {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
          preFiltersComponent as Type<Component>
        );
        this.preFiltersContainer.createComponent(componentFactory);
        this._subscriptionManager.clear();
      });
  }

  toggleMaximize() {
    if (this.layoutService.dockVisibility === DockVisibleState.open) {
      this.layoutService.dockVisibility = DockVisibleState.maximize;
      return;
    }
    this.layoutService.dockVisibility = DockVisibleState.open;
  }

  startResizing(mouseEvent: MouseEvent) {
    if (mouseEvent.buttons !== 1) {
      return;
    }
    this.isResizing = true;
  }

  resize(mouseEvent: MouseEvent) {
    if (!this.isResizing) {
      return;
    }
    let newHeight = 100 - (mouseEvent.offsetY / (mouseEvent.target as any).clientHeight) * 100;
    if (newHeight < 10) {
      newHeight = 10;
    }
    if (newHeight > this.maximizedHeight) {
      newHeight = this.maximizedHeight;
    }
    this._subjectManager.next('bottomDockHeight', newHeight);
    setTimeout(() => {
      this.layoutService.parentDockResizeSubject.next(
        this._dockPageContainer.nativeElement.clientHeight
      );
    }, 300);
  }
}
