import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, catchError, of, map, Observable } from 'rxjs';
import { SchemasActions } from './schemas.actions';
import { HttpClient } from '@angular/common/http';
import { Schema } from './schemas.models';
import { ConfigService } from 'core/libs/global/frontend/config/src/lib/config.service';

@Injectable({
  providedIn: 'root',
})
export class SchemasEffects {
  private actions$ = inject(Actions);
  constructor(
    private _configService: ConfigService,
    private _http: HttpClient,
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SchemasActions.initializeSchemaStore),
      //Can't use the NimbusWebClient here as this doesn't reqiore a token amd the other header that the NimbusWebClient adds.
      switchMap(
        () =>
          this._http.get(
            `https://nimbus-schemas.s3.amazonaws.com/${this._configService.schemaFolder}/schema.json`,
          ) as Observable<{ [id: string]: unknown }>,
      ),
      map(schemas =>
        Object.keys(schemas).map(
          key => ({ id: key, schema: schemas[key] }) as Schema,
        ),
      ),
      map(schemas => SchemasActions.loadSchemasSuccess({ schemas: schemas })),
      catchError(error => of(SchemasActions.loadSchemasFailure({ error }))),
    ),
  );
}
