import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { AccessControlService } from '@nimbus/shared-lib';
import { FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { DomainConfigurationWebService } from '../../domain-configuration/services/domain-configuration-web.service';
import { UrlParameters } from '../../domain-configuration/models/url-parameters.model';
import { DomainConfigurationService } from '../../domain-configuration/services/domain-configuration.service';
import { DomainListCacheService } from '../services/domain-list-cache.service';
import { DomainStatusEnum } from '../../models/domain-status.enum';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ColumnInfo } from 'core-global-frontend-grid';
import { UpdateParkedDomain } from '../../domain-configuration/models/update-parked-domain';
import {
  ICustomCell,
  IClickableCell,
} from 'core/libs/global/frontend/grid/src/lib/cells/icell-component';

@Component({
  selector: 'app-keyword-optimization-cell',
  templateUrl: './keyword-optimization-cell.component.html',
  styleUrls: ['./keyword-optimization-cell.component.scss'],
})
export class KeywordOptimizationCellComponent
  implements OnInit, ICustomCell, IClickableCell
{
  @Input() form: FormGroup<any>;
  @Input() column: ColumnInfo;
  @Input() editable: boolean;
  @Input() rowIndex: number;
  @Input() dataSource: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Output() clickEvent = new EventEmitter<ICustomCell>();
  private _subscriptionManager = new SubscriptionManager();
  formControl: any;
  formModel: any;

  constructor(
    private _accessControlService: AccessControlService,
    private _domainConfigurationWebService: DomainConfigurationWebService,
    private _domainConfigurationService: DomainConfigurationService,
    private _domainListCacheService: DomainListCacheService,
    private _ovs: ObjectValidatorService,
  ) {}

  click(_: MouseEvent) {
    const checkboxState = this.form.controls[this.column.field].value;
    this._domainConfigurationService.closeConfigurationDock();
    if (!this.formControl.disabled) {
      this.formControl.disable({ emitEvent: false });
    }
    this.form.controls['status'].setValue(
      DomainStatusEnum[DomainStatusEnum.Updating].toString(),
    );
    this.formModel.keywordSelection.keywordSelectionTypes = checkboxState;
    this._subscriptionManager.register(
      this._updateDomain(checkboxState)
        .pipe(
          tap(parkedDomain => {
            this.form.controls['keywordRecommendationMethod'].setValue(
              (parkedDomain as any).keywordRecommendationMethod,
            );
            const dictionary = {
              keywordRecommendationMethod: (parkedDomain as any)
                .keywordRecommendationMethod,
              status: DomainStatusEnum[DomainStatusEnum.Updating].toString(),
            };
            this._domainListCacheService.updateDomainList(
              (parkedDomain as any).id,
              dictionary,
            );
          }),
        )
        .subscribe(),
    );
  }

  ngOnInit(): void {
    this.formControl = this.form.controls[this.column.field];
    this.formModel = this._createFormModel();
    this.formModel.keywordSelection.keywordSelectionTypes =
      this.form.controls[this.column.field].value;
    if (
      this.form.controls['status'].value !== 'Success' ||
      !this._accessControlService.canAccessBySelector(
        'domain-creator-forcekey-checkbox',
      )
    ) {
      this.formControl.disable();
    }
  }

  private _updateDomain(checkboxState: any) {
    return this._domainConfigurationWebService.putParkedDomain(
      this.form.controls['partnerId'].value,
      this.form.controls['id'].value,
      new UpdateParkedDomain(
        this.form.controls['facebookDomainVerificationCode'].value,
        this.form.controls['euDomain'].value,
        checkboxState as any,
        this.form.controls['urlParameters'].value !== '"null"' &&
        this.form.controls['urlParameters'].value !== '""'
          ? new UrlParameters(
              this.formModel
                ? this._domainConfigurationService.backwardsCompatible(
                    this.formModel,
                    this.form.controls['domainName'].value,
                  )
                : ''!,
              JSON.stringify(this.formModel),
            )
          : new UrlParameters('', ''),
        this.form.controls['urlParameters'].value !== '"null"' &&
        this.form.controls['urlParameters'].value !== '""'
          ? this._domainConfigurationService.collectKeywords(
              this.formModel.keywordSelection,
            )
          : [''],
      ),
    );
  }

  private _createFormModel() {
    let params = null;
    try {
      params = JSON.parse(this.form.controls['urlParameters'].value);
    } catch (exception) {
      params = null;
    }

    const urlParamStr =
      this.form.controls['urlParameters'].value !== '"null"' &&
      this.form.controls['urlParameters'].value !== '""'
        ? params
        : null;
    let urlParamObj = null;
    try {
      urlParamObj = JSON.parse(urlParamStr);
    } catch (exception) {
      urlParamObj = null;
    }
    let formModel =
      urlParamObj && urlParamObj.FormSchema && urlParamObj.FormSchema !== ''
        ? JSON.parse(urlParamObj.FormSchema)
        : {};

    if (this._ovs.isNullOrEmpty(formModel.keywordSelection)) {
      formModel.keywordSelection = {};
    }

    if (
      this._ovs.isNullOrEmpty(formModel.keywordSelection.keywordSelectionTypes)
    ) {
      formModel.keywordSelection.keywordSelectionTypes = {};
    }
    return formModel;
  }
}
