<lib-searchable-select-control
  [control]="formControl"
  [class]="(isLoading$ | async) ? 'gray-loader' : ''"
  [searchableListItems]="items$ | async"
  [sortOption]="noSort"
  [readonly]="
    ovs.isNullOrUndefined(field.props['readOnly']) ? false : field.props['readOnly']
  "
  [label]="field.props['label']">
</lib-searchable-select-control>
<span
  class="mat-mdc-form-field-error"
  role="alert"
  *ngIf="formControl.touched && !formControl.valid && field.props['required']">
  <formly-validation-message [field]="field"></formly-validation-message>
</span>
