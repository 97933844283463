<ng-container>
  <div class="actions-bar">
    <div *ngIf="useExternalDownload" class="download-btn">
      <frontend-download-button
        (download)="download.emit($event)"
      ></frontend-download-button>
    </div>
    <div *ngIf="showRefreshButton" class="refresh-button-container">
      <button
        class="refresh-button"
        mat-flat-button
        (click)="$event.stopPropagation(); refreshGrid(); $event.stopPropagation();"
        matTooltip="Refresh data in grid"
      >
        <svg role="img">
          <use
            class="refresh-buttons icon"
            xlink:href="assets/img/sprites.svg#refresh-icon"
          />
        </svg>
        {{ refreshButtonText }}
      </button>
      <div class="updated-container">
        <div class="updated-label-container">
          <span class="updated-label">Updated:</span>
          <span class="time-display">{{ refreshDate | lowercase }}</span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
