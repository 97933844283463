import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { IArea } from './i-area';

export class HighlightArea implements IArea {
  private _copyNodeMargin = 14;
  public firstTd: HTMLTableDataCellElement;
  public lastTd: HTMLTableDataCellElement;

  private get _firstExists(): boolean {
    return this._ovs.isDefined(this.firstTd);
  }

  private get _lastExists(): boolean {
    return this._ovs.isDefined(this.lastTd);
  }

  public get left(): number {
    return this._firstExists ? this.firstTd.offsetLeft - 1 : 0;
  }

  public get top(): number {
    return this._firstExists ? this.firstTd.offsetTop - 1 : 0;
  }

  public get width(): number {
    return this._firstExists && this._lastExists
      ? this.lastTd.offsetLeft -
          this.firstTd.offsetLeft +
          this.lastTd.clientWidth -
          1
      : 0;
  }

  public get height(): number {
    return this._firstExists && this._lastExists
      ? this.lastTd.offsetTop -
          this.firstTd.offsetTop +
          this.lastTd.offsetHeight -
          1
      : 0;
  }

  get copyNodeLeft(): number {
    return this.left + this.width - this._copyNodeMargin;
  }

  get copyNodeTop(): number {
    return this.top + this.height - this._copyNodeMargin;
  }

  get cellIndicatorHorizontalLength(): number {
    return this.width + 1;
  }

  get cellIndicatorVerticlLength(): number {
    return this.height + 1;
  }

  constructor(private _ovs: ObjectValidatorService) {}

  public isReady(): boolean {
    return this._firstExists && this._lastExists;
  }
}
