import { SubjectManager } from '@nimbus/global-frontend-subscription-manager';
import { UrlParameters } from '../models/url-parameters.model';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { UpdateParkedDomain } from '../models/update-parked-domain';

@Injectable()
export class DomainConfigurationService {
  private _subjectManager = new SubjectManager();
  private _domainId: number;

  get domainId() {
    return this._domainId;
  }

  get domainId$() {
    return of(this._domainId);
  }

  get closeConfigurationDock$() {
    return this._subjectManager.get('domainConfigurationCloseDock');
  }

  openDock$(): Observable<any> {
    return this._subjectManager.get('openDock');
  }

  constructor(private _ovs: ObjectValidatorService) {
    this._subjectManager.register('openDock');
    this._subjectManager.register('domainConfigurationCloseDock');
  }

  setDomainConfigurationId(domainId: number) {
    this._domainId = domainId;
    this._subjectManager.next('openDock', null);
  }

  closeConfigurationDock() {
    this._domainId = null;
    this._subjectManager.next('domainConfigurationCloseDock', null);
  }

  backwardsCompatible(
    model: any,
    domainName: string,
    keywordRecommendationMethod?: number,
  ) {
    if (!this._ovs.isNullOrEmpty(model?.reporting?.sub_id2)) {
      delete model['reporting']['sub_id2'];
      delete model['keywordSelection']['keywords_static'];
    }

    if (!this._ovs.isNullOrEmpty(model?.reporting?.sub_id1)) {
      const subid1Val = model['reporting']['sub_id1'];
      delete model['reporting']['sub_id1'];
      model['reporting']['sub_id'] = subid1Val;
    }

    if (!this._ovs.isNullOrEmpty(model?.reporting?.subid)) {
      const subidVal = model['reporting']['subid'];
      delete model['reporting']['subid'];
      model['reporting']['sub_id'] = subidVal;
    }

    if (!this._ovs.isNullOrEmpty(model?.compliance?.primary_key)) {
      const rskeyVal = model['compliance']['primary_key'];
      delete model['compliance']['primary_key'];
      model['compliance']['rskey'] = rskeyVal;
    }

    if (this._ovs.isNullOrEmpty(model.keywordSelection))
      model.keywordSelection = {};
    if (this._ovs.isNullOrEmpty(model.keywordSelection.keywordSelectionTypes))
      model.keywordSelection.keywordSelectionTypes = {};

    if (!this._ovs.isNullOrEmpty(keywordRecommendationMethod))
      model.keywordSelection.keywordSelectionTypes =
        !!keywordRecommendationMethod;

    const parameter = this.liveUpdateUrlParameters(model);
    return !parameter ? domainName : `${domainName}?${parameter}`;
  }

  createParkedDomain(url: string, model: any): UpdateParkedDomain {
    const urlParameters: UrlParameters = new UrlParameters(
      url,
      JSON.stringify(model),
    );
    return new UpdateParkedDomain(
      model.configure.facebookVerificationCode ?? '',
      model.configure.eu_designated_domain,
      this._ovs.isNullOrEmpty(model?.keywordSelection?.keywordSelectionTypes)
        ? true
        : model?.keywordSelection?.keywordSelectionTypes,
      urlParameters,
      this.collectKeywords(model.keywordSelection),
    );
  }

  collectKeywords(obj) {
    let list = [];
    for (let property in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        if (typeof obj[property] == 'object') {
          list = list.concat(this.collectKeywords(obj[property]));
        } else if (
          obj[property] !== '' &&
          property !== 'keywordSelectionTypes'
        ) {
          list = list.concat([this.valueTouchUp(property, obj[property])]);
        }
      }
    }
    return list;
  }

  liveUpdateUrlParameters(model: any): string {
    if (!model) return '';
    let list = this.collectKeyValuePairs(model);
    return list.join('&');
  }

  private collectKeyValuePairs(obj: object): string[] {
    let list = [];
    for (let property in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        // Property names should match the key's value in domain-configuration-schema.service
        if (
          !(
            property === 'trafficSource' ||
            property === 'facebookVerificationCode' ||
            property === 'eu_designated_domain' ||
            property === 'keywordSelectionTypes'
          )
        ) {
          if (typeof obj[property] == 'object') {
            list = list.concat(this.collectKeyValuePairs(obj[property]));
          } else if (obj[property] !== '') {
            list = list.concat([
              this.encodePropertyValue(property, obj[property]),
            ]);
          }
        }
      }
    }
    return list;
  }

  private encodePropertyValue(property: string, value: string): string {
    if (
      property === 'impression_track_url' ||
      property === 'search_track_url' ||
      property === 'click_track_url'
    ) {
      return `${property}=${encodeURIComponent(value)}`;
    } else {
      return `${property}=${this.valueTouchUp(property, value)}`;
    }
  }

  private valueTouchUp(property: string, value: string): string {
    const valuesToCheck = ['forcekey', 'rskey', 'headline'];
    return valuesToCheck.includes(property) ||
      valuesToCheck.some(element => property.includes(element))
      ? value
          .trim()
          .split(' ')
          .filter(element => element !== '')
          .join('+')
      : value.trim();
  }
}
