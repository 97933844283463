import { IListItem } from './i-list-item';

export class SearchableListItemWithSelect implements IListItem {
  constructor(
    public value: any,
    public selected: boolean = false,
    public display: string = '',
    public tooltip: string = '',
    public disabled: boolean = false
  ) {
    try {
      this.display = (this.display === null || this.display === '') ? this.value.toString() : this.display;
    } catch (error) {
      throw Error('The display property is required if the provided value is not strignifable.');
    }
  }
}
