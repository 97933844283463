import { FilterBoxInit } from '../filter-box/models/filter-box-init';
import { FilterBoxInitBuilder } from '../filter-box/services/filter-box-init-builder.service';
import { CellGrid } from '../q-models/cell-grid';
import { ColumnConfiguration } from '../q-models/column-configuration';
import { GridLookups } from '../q-models/grid-lookups';
import { SortMode } from '../q-models/sort-mode';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

export class CellGridBuilder {
  constructor(private _ovs: ObjectValidatorService) {}
  private _sortMode = SortMode.None;
  private _includeRowNoColumn = false;
  private _hideAddRow = true;
  private _addRowButtonName: string;
  private _bulkOption = true;
  private _yAxisHeaders: string[];
  private _lookups: GridLookups;
  private _filterBoxInit: FilterBoxInit = new FilterBoxInitBuilder().build();
  private _editable: (row: any, rows: any[]) => boolean = () => false;
  private _addRowOnDownArrow: (row: any, rows: any[]) => boolean = () => false;

  editable(
    editable: (row: any, rows: any[]) => boolean = null,
  ): CellGridBuilder {
    if (!this._ovs.isFunction(editable)) {
      this._editable = () => true;
      return this;
    }

    this._editable = editable;
    return this;
  }

  withSortMode(sortMode: SortMode = SortMode.Default): CellGridBuilder {
    this._sortMode = sortMode;
    return this;
  }

  withRowNumberColumn(): CellGridBuilder {
    this._includeRowNoColumn = true;
    return this;
  }

  noBulkOption(): CellGridBuilder {
    this._bulkOption = false;
    return this;
  }

  withAddRowButton(buttonText: string = 'Add Row'): CellGridBuilder {
    this._hideAddRow = false;
    this._addRowButtonName = buttonText;
    return this;
  }

  withYAxisHeaders(yHeaders: string[]): CellGridBuilder {
    this._yAxisHeaders = yHeaders;
    return this;
  }

  withLookups(lookups: GridLookups): CellGridBuilder {
    this._lookups = lookups;
    return this;
  }

  withFilterBox(filterBoxInit: FilterBoxInit): CellGridBuilder {
    if (this._ovs.isDefined(filterBoxInit)) {
      this._filterBoxInit = filterBoxInit;
    }
    return this;
  }

  withAddRowOnDownArrow(
    addRowOnDownArrow: (row: any, rows: any[]) => boolean = () => null,
  ): CellGridBuilder {
    if (!this._ovs.isFunction(addRowOnDownArrow)) {
      this._addRowOnDownArrow = () => true;
      return this;
    }

    this._addRowOnDownArrow = addRowOnDownArrow;
    return this;
  }

  build(columns: ColumnConfiguration[]): CellGrid {
    const cellGrid = new CellGrid(columns);
    cellGrid.editable = this._editable;
    cellGrid.addRowButtonName = this._addRowButtonName;
    cellGrid.bulkOption = this._bulkOption;
    cellGrid.filterBoxInit = this._filterBoxInit;
    cellGrid.hideAddRow = this._hideAddRow;
    cellGrid.includeRowNoColumn = this._includeRowNoColumn;
    cellGrid.lookups = this._lookups;
    cellGrid.sortMode = this._sortMode;
    cellGrid.yAxisHeaders = this._yAxisHeaders;
    cellGrid.addRowOnDownArrow = this._addRowOnDownArrow;
    return cellGrid;
  }
}
