<h2>Start typing a Vertical, Subvertical, or Geo code (eg. US).</h2>
<!--TODO: Remove paragraph above and remove ngIf directive below when subverticals are ready. -->
<div *ngIf="false">
  <h2>Start typing a Vertical, Subvertical, or Geo code (eg. US). 
  </h2>
  <p>
  Don't see the Subvertical you are
  looking for ? <span class="cta-message">Request it <a class="clickable" (click)="goToRequestPage()">here</a>.</span>
  </p>
</div>
<div class="formly-field-input container">
  <svg
    [matTooltip]="
      'You can search by vertical, subvertical, or country. Just start typing what you are looking for and we will provide the best match.'
    "
    role="img"
    class="formly-field-input svg">
    <use xlink:href="assets/img/sprites.svg#ramp-info-icon" />
  </svg>
  <lib-searchable-select-control
    [control]="formControl"
    [class]="(isLoading$ | async) ? 'gray-loader' : ''"
    [searchableListItems]="items$ | async"
    [sortOption]="noSort"
    [label]="field.props['label']"
    [defaultPlaceholder]="'Example: auto/car_deals/us'">
  </lib-searchable-select-control>

  <span class="mat-mdc-form-field-error text" role="alert">
    <formly-validation-message
      [field]="field"
      *ngIf="
        formControl.touched && field.props['required'] && !formControl.valid
      "></formly-validation-message>
    <div
      class="place-holder"
      *ngIf="!(formControl.touched && field.props['required'] && !formControl.valid)">
      &nbsp;
    </div>
  </span>
</div>
