import { Component } from '@angular/core';

@Component({
  selector: 'app-campaign-domain-url-parameters',
  templateUrl: './campaign-domain-url-parameters.component.html',
  styleUrls: ['../documentation.scss']
})
export class CampaignDomainUrlParametersComponent {
  constructor() {}
}
