<div
  [class]="
    (pixelManagerService.isLoading$ | async) || (partnerCacheService.isLoading$ | async)
      ? 'minheight gray-loader'
      : ''
  ">
  <ng-container
    *ngIf="
      (pixelManagerService.isLoading$ | async) === false &&
      (partnerCacheService.isLoading$ | async) === false
    ">
    <div [class]="(pixelManagerService.isLoading$ | async) ? 'gray-loader' : ''">
      <div class="access-token-create divider-line filter-box">
        <app-pixel-manager-form></app-pixel-manager-form>
      </div>
      <div class="history-body">
        <app-pixel-manager-history></app-pixel-manager-history>
      </div>
    </div>
  </ng-container>
</div>
