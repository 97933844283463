import { AfterViewInit, Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { BehaviorSubject, Observable, filter, map, take, tap } from 'rxjs';

@Component({
  selector: 'formly-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
})
export class WarningComponent extends FieldType<FieldTypeConfig> implements AfterViewInit {
  private readonly _isVisibleSubject = new BehaviorSubject<boolean>(false);
  isVisible$ = this._isVisibleSubject.asObservable();
  touchedEvent$: Observable<boolean> | undefined;

  constructor(public ovs: ObjectValidatorService) {
    super();
  }

  ngAfterViewInit(): void {
    this.touchedEvent$ = this.form.controls['domainName'].valueChanges
      .pipe(
        filter(() => (this.form.controls['domainName'] as FormControl).touched),
        take(1),
        tap(() => this._isVisibleSubject.next(true))
      );
  }

  close(): void {
    this._isVisibleSubject.next(false);
    this.field.props['closed'] = true;
  }
}
