<div
  *ngIf="
    ovs.isNullOrUndefined(field.props['showTitle']) ? true : field.props['showTitle']
  "
  class="title">
  Please provide a minimum of 10 distinct keywords, one per line
</div>
<div
  *ngIf="
    ovs.isNullOrUndefined(field.props['label']) ||
    ovs.isNullOrEmpty(field.props['label'])
      ? false
      : true
  "
  class="dock-title">
  {{ field.props['label'] }}
</div>
<textarea
  [rows]="field.props['rows']"
  [formControl]="formcontrol()"
  [readonly]="
    ovs.isNullOrUndefined(field.props['readOnly']) ? false : field.props['readOnly']
  "
  [formlyAttributes]="field"></textarea>
<span class="mat-mdc-form-field-error text" role="alert">
  <formly-validation-message
    [field]="field"
    *ngIf="
      formControl.touched && field.props['required'] && !formControl.valid
    "></formly-validation-message>
  <div
    class="place-holder"
    *ngIf="!(formControl.touched && field.props['required'] && !formControl.valid)">
    &nbsp;
  </div>
</span>
