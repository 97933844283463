import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { siteMap } from '../../../site-map';
import { SiteMapAccessFilter } from '../../../site-map-access-filter';
import { Observable, map } from 'rxjs';
import { SiteMapNode } from 'core-global-frontend-navigation';
import { UtilityService } from 'core-global-frontend-object-validator';

@Injectable({
  providedIn: 'root',
})
export class DashboardMenuItemResolver {
  constructor(
    private _siteMapAccessFilter: SiteMapAccessFilter,
    private _utilityService: UtilityService,
  ) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): Observable<SiteMapNode[]> {
    return this._siteMapAccessFilter.filterUnAllowed(siteMap).pipe(
      map(siteMapNodes => {
        return this._utilityService
          .flattenListNodes<SiteMapNode>(siteMapNodes)
          .filter(siteMapNode => siteMapNode.dashboardCard);
      }),
      map(siteMapNodes => siteMapNodes.slice(0, 4)),
    );
  }
}
