import { Injectable } from '@angular/core';

@Injectable()
export class EnumHelperService {
  // Obsoleted.  To be deletaded once rename all over.
  getEnumFromValue(value: string | number, enumeration: any) {
    return Object.keys(enumeration)
      .map(key => [key, enumeration[key]])
      .filter(h => h[1] === value)[0][0];
  }

  getKeyAsString(value: string | number, enumeration: any): string {
    return Object.keys(enumeration)
      .map(key => [key, enumeration[key]])
      .filter(h => h[1] === value)[0][0];
  }
}
