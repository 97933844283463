<div class="formly-check container">
  <mat-checkbox [formControl]="getFormControl()" [required]="field.props['required']">
    {{ field.props['label'] }}
  </mat-checkbox>
  <svg
    *ngIf="field.props['showInfoButton']"
    [matTooltip]="field.props['toolTip']"
    role="img"
    class="formly-check svg">
    <use xlink:href="assets/img/sprites.svg#ramp-info-icon" />
  </svg>
</div>
