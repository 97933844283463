import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PixelManagerSchemaService {
  getSchema(): any[] {
    return [
      {
        key: 'pixelAccessTokenRequest',
        wrappers: ['section-wrapper'],
        fieldGroup: [
          {
            key: 'partnerId',
            type: 'partner-select',
            className: 'field-styles',
            templateOptions: {
              label: 'Partner *',
              required: true,
              width: 100
            }
          },
          {
            fieldGroupClassName: 'access-token-wrapper',
            fieldGroup: [
              {
                key: 'buysideNetwork',
                defaultValue: '',
                type: 'buyside-input',
                className: 'field-styles',
                templateOptions: {
                  required: true,
                  label: 'Buyside Networks *',
                  width: 100,
                  showFullList: true
                }
              },
              {
                key: 'pixelId',
                defaultValue: '',
                type: 'input-with-tooltip',
                className: 'field-styles',
                templateOptions: {
                  required: true,
                  label: 'Pixel ID',
                  width: 100,
                  showFullList: true,
                  allowedCharactersMessage: 'Only letters, digits, or hyphens are permitted'
                },
                validators: {
                  validation: [
                    { name: 'allowed-characters-validator', options: { regex: /^[-a-z0-9]+$/i } },
                    { name: 'min-character-validation', options: { minCharacters: 15 } },
                  ]
                }
              }
            ]
          }
        ]
      },
      {
        key: 'buysideAccessToken',
        type: 'input-with-tooltip',
        defaultValue: '',
        className: 'field-styles',
        templateOptions: {
          required: true,
          label: 'Buyside Access Token',
          width: 100,
          showFullList: true,
          allowedCharactersMessage: 'Only letters or digits are permitted'
        },
        validators: {
          validation: [
            { name: 'allowed-characters-validator', options: { regex: /^[a-z0-9]+$/i } },
            { name: 'min-character-validation', options: { minCharacters: 40 } },
          ]
        }
      }
    ];
  }
}
