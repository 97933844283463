import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableauComponent } from './tableau.component';
import { PushPipe } from '@ngrx/component';
import { BannerComponent } from 'core-global-frontend-common-ui';

@NgModule({
  declarations: [TableauComponent],
  imports: [CommonModule, PushPipe, BannerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TableauModule {}
