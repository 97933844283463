<mat-toolbar>{{ props.label }}</mat-toolbar>
<mat-card appearance="outlined">
  <mat-card appearance="outlined" *ngFor="let localField of field.fieldGroup; let i = index">
    <mat-card-title>{{ localField.props['label'] }}</mat-card-title>
    <div class="row">
      <formly-field
        class="col col-sm-8 d-flex align-items-left"
        [field]="localField"></formly-field>
      <button
        class="col-sm-2 d-flex align-items-right"
        class="col"
        mat-icon-button
        color="accent"
        (click)="remove(i)"
        *ngIf="!field.props['readOnly'] && !props['itemName']"
        type="button">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
    <mat-card-footer *ngIf="!field.props['readOnly'] && props['itemName']">
      <button
        class="remove-button"
        mat-flat-button
        color="accent"
        type="button"
        (click)="remove(i)">
        Remove {{ props?.['itemName'] ?? '' }}
      </button>
    </mat-card-footer>
  </mat-card>
  <mat-card-footer *ngIf="!field.props['readOnly']">
    <button mat-flat-button color="primary" type="button" (click)="add()" class="button">
      Add {{ props?.['itemName'] ?? '' }}
    </button>
  </mat-card-footer>
</mat-card>
