import { Inject, Injectable } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import {
  TokenCacheService,
} from '@nimbus/shared-lib';
import { Router } from '@angular/router';
import { CustomUserClaim, OktaAuth } from '@okta/okta-auth-js';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import {
  Observable,
  catchError,
  from,
  filter,
  switchMap,
  tap,
  EMPTY,
} from 'rxjs';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { ConfigService } from 'core-global-frontend-config';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _router: Router,
    private _tokenCacheService: TokenCacheService,
    private _ovs: ObjectValidatorService,
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _configService: ConfigService,
  ) { }

  init() {
    this._oktaAuth.authStateManager.subscribe(async authState => {
      if (authState.isAuthenticated ?? false) {
        from(this._init()).subscribe();
        return;
      }
      this._refreshingToken();
    });
  }

  private _refreshingToken() {
    this._oktaAuth.tokenManager
      .get('refreshToken')
      .then(function (refreshToken) {
        this._oktaAuth.token
          .refresh(refreshToken)
          .then(_ => {
            // Manage newIdToken (e.g., update the Authorization header)
          })
          .catch(_ => {
            // Handle error during token refresh
            this._router.navigate(['/login']);
          });
      })
      .catch(_ => {
        // Handle error if no refresh token is available
        this._router.navigate(['/login']);
      });
  }

  async _init() {
    this._configService.configured$
      .pipe(
        switchMap(
          () => from(this._oktaAuth.getUser()) as Observable<CustomUserClaim>,
        ),
        filter(
          oktaUser =>
            !(
              oktaUser &&
              !this._ovs.isPathNullOrEmpty(oktaUser, ['preferred_username'])
            ),
        ),
        tap(() => this._router.navigate(['/login'])),
      )
      .subscribe();

    this._configService.configured$
      .pipe(
        switchMap(() => from(this._oktaAuth.getUser())),
        filter(
          oktaUser =>
            oktaUser &&
            !this._ovs.isPathNullOrEmpty(oktaUser, ['preferred_username']),
        ),
        switchMap(oktaUser =>
          this._webServiceBuilderService.builder
            .withUrl('users/info')
            .build()
            .get<any>({ email: oktaUser.preferred_username }),
        ),
        tap(userInfo => {
          if (this._ovs.isNullOrEmpty(userInfo)) {
            throw new Error('User not found');
          }
        }),
        tap(userInfo => {
          this._tokenCacheService.setToken({
            userName: userInfo.userName,
            roles: userInfo.roles,
            fullName: userInfo.fullName,
            userId: userInfo.userId,
            email: userInfo.email,
          });
          this._tokenCacheService.authenticate(true);
        }),
        catchError(() => {
          localStorage.clear();
          this._oktaAuth.clearStorage();
          this._oktaAuth.closeSession();
          this._oktaAuth.signOut();
          this._router.navigate(['/']);
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
