import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { JsonEditorOptions, NgJsonEditorModule } from 'ang-jsoneditor';
import { ReplaySubject, debounceTime, filter, withLatestFrom } from 'rxjs';
import { AngularMaterialModule } from '../../../angular-material/angular-material.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCommonModule } from '@angular/material/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { NimbusFormlyCommonModule } from 'core-global-frontend-form-nimbus-formly-common';

@Component({
  selector: 'frontend-json-editor',
  templateUrl: './formly-json-editor.component.html',
  styleUrls: ['./formly-json-editor.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    FormlyModule,
    NgJsonEditorModule,
    FormlyMaterialModule,
    MatCommonModule,
    NimbusFormlyCommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
  ],
})
export class JsonEditorComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  editorOptions = new JsonEditorOptions();
  private readonly _changeEventSubject = new ReplaySubject<any>();
  private readonly _changeEvent$ = this._changeEventSubject
    .asObservable()
    .pipe(debounceTime(300));
  private readonly _applySubject = new ReplaySubject();
  private readonly _applyEvent$ = this._applySubject.asObservable();

  constructor(private _ovs: ObjectValidatorService) {
    super();
  }

  ngOnInit(): void {
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.mainMenuBar = true;
    this.editorOptions.navigationBar = true;
    this.editorOptions.statusBar = true;
    this.editorOptions.language = 'en';
    this._changeEventSubject.next(null);
    this._applyEvent$
      .pipe(
        withLatestFrom(this._changeEvent$),
        filter(
          ([_, changeEvent]) =>
            changeEvent &&
            (!this._ovs.isDefined(changeEvent['isTrusted']) ||
              changeEvent['isTrusted']),
        ),
      )
      .subscribe(([_, changeEvent]) => this.formControl.setValue(changeEvent));
  }

  apply() {
    this._applySubject.next({});
  }

  change(event: any) {
    this._changeEventSubject.next(event);
  }
}
