import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { IListItem } from './i-list-item';

export class SelectAllHandler {
  private _selectedCount = 0;
  readonly selectAllItem: IListItem;
  get isSelected(): boolean {
    return this._list.length === this._selectedCount;
  }
  get isIndeterminate(): boolean {
    return this._selectedCount > 0 && this._selectedCount < this._list.length;
  }
  constructor(
    private _list: IListItem[],
    private _selectAllDisplay: string,
    private _ovs: ObjectValidatorService
  ) {
    const selectedList = _list.filter(value => value.selected);
    this._selectedCount = this._ovs.isNullOrUndefined(selectedList) ? 0 : selectedList.length;
    this.selectAllItem = {
      value: _selectAllDisplay,
      selected: false,
      display: _selectAllDisplay
    };
  }
  select() {
    if (this._selectedCount === this._list.length) {
      return;
    }
    this._selectedCount++;
  }
  deselect() {
    if (this._selectedCount === 0) {
      return;
    }
    this._selectedCount--;
  }
  toggle() {
    this._selectedCount = this.isSelected ? 0 : this._list.length;
  }
}
