import { Injectable } from '@angular/core';
import { FilterBoxConfigurationBuilder } from './filter-box-configuration-builder';
@Injectable({
  providedIn: 'root'
})
export class FilterBoxConfigurationBuilderFactory {
  get builder(): FilterBoxConfigurationBuilder {
    return new FilterBoxConfigurationBuilder();
  }
}
