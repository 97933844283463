import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import {
  BasePageComponent,
  DockableItem,
  DockContent,
  ILayoutContent,
} from '@nimbus/shared-lib';
import { filter, tap } from 'rxjs/operators';
import { SubverticalRequestsHistoryListColumnsBuilderService } from '../services/subertical-requests-history-list-columns-builder.service';
import { SubverticalRequestCacheService } from '../services/subvertical-request-cache.service';
import { Subscription } from 'rxjs';
import { SubverticalRequestsHistoryDockService } from '../services/subvertical-requests-history-dock.service';
import { SubverticalRequestsDockComponent } from '../subvertical-requests-dock-component/subvertical-requests-dock.component';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { ColumnInfo } from 'core-global-frontend-grid';
import { GridModel } from 'core/libs/global/frontend/grid/src/lib/grid-models/grid-model';

@Component({
  selector: 'app-subvertical-request-history',
  templateUrl: './subvertical-requests-history.component.html',
  styleUrls: ['./subvertical-requests-history.component.scss'],
})
export class SubverticalRequestsHistoryComponent
  extends BasePageComponent
  implements AfterViewInit, OnDestroy, ILayoutContent
{
  columns: ColumnInfo[];
  gridModel: GridModel = new GridModel("subvertical-requests-history", []);

  constructor(
    private _historyListColumnsBuilderService: SubverticalRequestsHistoryListColumnsBuilderService,
    private _SubverticalRequestsHistoryDockService: SubverticalRequestsHistoryDockService,
    public subverticalRequestCacheService: SubverticalRequestCacheService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ovs: ObjectValidatorService,
  ) {
    super();
    this.columns = this._historyListColumnsBuilderService.columns;
  }

  async ngAfterViewInit() {
    super.ngAfterViewInit('self-serve/subverticals/requests');

    this.setDockContent(
      new DockContent(
        [new DockableItem('', SubverticalRequestsDockComponent)],
        'id',
      ),
    );
    this._setupSubscriptions();
  }

  private _setupSubscriptions() {
    this._subscriptionManager.registerMultiple([
      this._SubverticalRequestsHistoryDockService.subverticalHistoryCloseDock$
        .pipe(tap(() => this.closeDock()))
        .subscribe(),
      this._setDockModelOnRouteChange(),
      this.subverticalRequestCacheService.requestedSubverticals$.subscribe(
        () => {
          this._changeDetectorRef.markForCheck();
          this._changeDetectorRef.detectChanges();
        },
      ),
    ]);
  }

  private _setDockModelOnRouteChange(): Subscription {
    return this._SubverticalRequestsHistoryDockService.selectedSubverticalHistoryId$
      .pipe(filter(values => !this._ovs.isNullOrEmpty(values)))
      .subscribe(values => this.setDockModel(values));
  }
}
