import { Sort } from "@angular/material/sort";

export class GridModel {
  constructor(
    public id: string,
    public data: any[],
    public defaultSort?: Sort,
    public length?: number,
  ) {}
}
  