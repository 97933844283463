import { Component } from '@angular/core';
import {
  FieldType,
  FieldTypeConfig,
  FormlyFieldConfig,
} from '@ngx-formly/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

// v6 uses props.label. Check documentation.
@Component({
  selector: 'formly-field-input',
  templateUrl: './info-button-input-template.component.html',
  styleUrls: ['./info-button-input-template.component.scss'],
})
export class InfoButtonInputTemplateComponent extends FieldType<FieldTypeConfig> {
  warnComponent: FormlyFieldConfig;

  constructor(public ovs: ObjectValidatorService) {
    super();
  }

  onFocusEvent(event: any): void {
    this.warnComponent = this?.field?.parent?.fieldGroup?.find(
      elem => elem.key == 'warning',
    );

    if (
      !this.ovs.isNullOrEmpty(this.warnComponent) &&
      this.warnComponent.props['closed'] === 'false'
    ) {
      this.warnComponent.hideExpression = false;
    }
  }
}
