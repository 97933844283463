import { Injectable } from '@angular/core';
import { TermModel } from '../models/term.model';
import { DomainTermsWebService } from './domain-terms-web.service';

@Injectable({
  providedIn: 'root'
})
export class DomainTermsCacheService {
  invalidTerms: TermModel[] = [];

  constructor(private _domainTermsWebService: DomainTermsWebService) {
    this._domainTermsWebService
      .getDisallowedTerms()
      .subscribe(
        terms =>
          (this.invalidTerms = terms.map(
            term => new TermModel(term.term, term.allowIfContainedInWord)
          ))
      );
  }
}
