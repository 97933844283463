<div class="breadcrumbs">
  <span *ngIf="makeBreadcrumbs().length; else youAreHome">
    <a routerLink="/"><mat-icon>home</mat-icon></a>
    <mat-icon>chevron_right</mat-icon>
  </span>
  <ng-template #youAreHome>
    <span class="breadcrumbs__home">Welcome <mat-icon>home</mat-icon></span>
  </ng-template>
  <span *ngFor="let breadcrumb of makeBreadcrumbs(); let last = last">
    <a *ngIf="!last" [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
    <mat-icon *ngIf="!last">chevron_right</mat-icon>
    <span *ngIf="last" class="breadcrumbs__last">{{ breadcrumb.label }}</span>
  </span>
</div>
