<div class="filter-dialog-box">
  <h3 class="filter-dialog-box-title">Filter "{{ filterLabel }}"</h3>
  <form
    class="filter-dialog-box-form"
    (keydown.enter)="$event.preventDefault(); submitted($event)"
  >
    containing:
    <input
      type="text"
      (change)="filterChanged($event)"
      placeholder="start typing..."
    />
  </form>
</div>
