import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HttpTransportType } from '@microsoft/signalr';
import { LoggerMethodEvent, LoggingService } from '../core/logging-service/logging.service';
import { ObjectValidatorService } from '../core/object-validator-service/object-validator.service';
import { TokenCacheService } from '../services/user/token-cache.service';
import { ConfigService } from './config.service';
import { IClearableService } from './iclearable-cache';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';

export class Listener {
  constructor(public method: string, public callback: (data: any) => void) { }
}

@Injectable()
export class MessageHubService implements IClearableService {
  private _hubConnection: HubConnection;
  private _subscriptionManager = new SubscriptionManager();
  private _externalListeners: Listener[] = [];
  private _initialized = false;

  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _ovs: ObjectValidatorService,
    protected _configService: ConfigService,
    protected _tokenCacheService: TokenCacheService,
    protected _loggingService: LoggingService
  ) { }

  clearOnComponentDestroy(): void {
    this._subscriptionManager.clear();
  }

  async init() {
    this._subscriptionManager.register(
      this._tokenCacheService.userHasAuthenticated$.subscribe(() => {
        this._startConnection('messagehub');
        this._externalListeners.forEach(listener =>
          this._hubConnection.on(listener.method, listener.callback)
        );
      }),
      'userHasAuthenticated$'
    );
  }

  remove(method: string) {
    if (
      this._ovs.isNullOrEmpty(this._externalListeners.find(listener => listener.method === method))
    ) {
      return;
    }
    if (this._initialized) {
      this._hubConnection.off(method);
    }
    this._externalListeners = this._externalListeners.filter(
      listener => listener.method !== method
    );
  }

  add(method: string, callback: (data: any) => void) {
    if (
      this._ovs.isNullOrEmpty(this._externalListeners.find(listener => listener.method === method))
    ) {
      this._externalListeners.push(new Listener(method, callback));
    }
    if (this._initialized) {
      this._hubConnection.on(method, callback);
    }
  }

  private _startConnection(route: string) {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${this._configService.getApi().host}/v1/hubs/${route}`, {
        accessTokenFactory: () => this._oktaAuth.getAccessToken(),
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets
      })
      .withAutomaticReconnect()
      .build();

    this._hubConnection
      .start()
      .then(() => {
        this._initialized = true;
        this._loggingService.log(
          MessageHubService.name,
          this._startConnection.name,
          LoggerMethodEvent.Information,
          'SignalR connection started for ' + route
        );
      })
      .catch(err => {
        this._initialized = false;
        this._loggingService.log(
          MessageHubService.name,
          this._startConnection.name,
          LoggerMethodEvent.Error,
          'Error starting SignalR connection for ' + route + ': ' + err
        );
      });
  }
}
