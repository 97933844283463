import { Component, Input } from '@angular/core';
import { ColumnClassHelper } from '../shared/columnClassHelper';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { AnchorService } from '../../anchor.service';

@Component({
  selector: 'app-rsoc-guidelines-online-feed',
  templateUrl: './rsoc-guidelines-online-feed.component.html',
  styleUrl: './rsoc-guidelines-online-feed.component.scss',
})
export class RsocGuidelinesOnlineFeedComponent {
  @Input() set tabAnchor(value) {
    if (!this.ovs.isNullOrEmpty(value)) {
      document.getElementById(value).scrollIntoView();
    }
  }

  allowListedDomainUrls = [
    'https://www.adsensecustomsearchads.com',
    'https://syndicatedsearch.goog',
  ];

  possibleReportTypeValues = [
    'syndication_afd_keyword_daily',
    'syndication_rsoc_online_campaign_daily',
    'syndication_rsoc_online_partner_daily',
    'syndication_rsoc_online_campaign_hourly',
  ];

  steps = [
    'Installing RampJs',
    'Specifying keywords',
    'Setting up URL parameters for reporting and conversion tracking',
  ];

  keywordActions = [
    `By appending URL parameters to the page's URL`,
    `By including keywords directly in the page's JavaScript, or`,
    `By using the keywords meta tag in the page's HTML`,
  ];

  forcekeyDynamicReplacements = [
    `area`,
    `city`,
    `country`,
    `day`,
    `keyword (note: dynamically populated via 'dynamic_term' url parameter)`,
    `holiday`,
    `month`,
    `region`,
    `state`,
    `year`,
    `zip`,
  ];

  metaKeywords = [
    `Accounting Software`,
    `Accounting Degrees`,
    `Accounting Services`,
    `Tax Return Options`,
  ];

  buysideNetworks = [
    `Google`,
    `Facebook`,
    `Taboola`,
    `Outbrain`,
    `TikTok`,
    `Snapchat`,
    `Zemanta`,
  ];
  tableReportingParameters = {
    headers: ['Parameter', 'Description', 'Example', 'Error Logic'],
    data: [
      {
        records: [
          {
            parameter: 'utm_source',
            description:
              'System1 Partner Required Parameter - Account ID - A bucket of traffic; typically a buyside name',
            example: 'facebook',
          },
          {
            parameter: 'segment',
            description:
              'A segment can change the experiences that load for the user.  These are System1 generated.' +
              '\n' +
              'System1 Partner Required Parameter - ' +
              '\n' +
              'We typically recommend that each segment represents a buyside account. ' +
              '\n',
            example: 'rsoc.(yourdomain).001-010',
            errorLogic:
              'If no segment is passed, System1 uses the “default” ' +
              'segment, and this will not affect the rest of the URL params sent.',
            isRequired: true,
          },
          {
            parameter: 's1paid',
            description:
              'System1 Partner Required Parameter - Account ID - A bucket of traffic; typically a buyside account',
            example: '476091026530280',
            isRequired: true,
          },
          {
            parameter: 's1pcid',
            description:
              'System1 Partner Required Parameter - Campaign ID - More granular performance tracking at a campaign level',
            example: '75859678',
            errorLogic:
              'You have a finite number of SPcid’s per day.  We have around 400 available. Once you reach that limit, new SPcid’s will stop being tracked. Your account manager will be notified when you get close to the limit.',
            isRequired: true,
          },
          {
            parameter: 's1pagid',
            description:
              'System1 Partner Required Parameter - AdGroup ID - More granular performance tracking at an AdGroup level',
            example: 'CA87485',
            isRequired: true,
          },
          {
            parameter: 's1pplacement',
            description:
              'System 1 Partner Required Parameter - Placement ID - More granular performance tracking at the ad placement level',
            example: 'FB_feed',
            isRequired: true,
          },
          {
            parameter: 's1padid',
            description:
              'System1 Partner Required Parameter Ad ID - More granular performance tracking at the specific ad level',
            example: 'FB_feed_2x3',
            isRequired: true,
          },
          {
            parameter: 's1particle',
            description:
              'System1 Partner Parameter Article Name - More granular performance tracking at the specific ad level',
            example: 'Top10DentalImplantProviders',
          },
        ],
      },
    ] as any,
  };

  tables = [
    {
      title: 'Standard Tracking Parameters',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Tracking Parameters',
          records: [
            {
              parameter: 'impression_track_url',
              description:
                'URL to fire when the user reaches the System1 ' +
                'content article lander',
              example:
                'impression_track_url=https%3A%2F%2' +
                'Fwww.trackingsite.com%2Fid%2F1234',
            },
            {
              parameter: 'search_track_url',
              description: 'URL to fire when a user executes a search',
              example:
                'search_track_url=https%3A%2F%2Fwww.trackingsite.com' +
                '%2Fid%2F1234%3Fquery%3DOMKEYWORD',
            },
            {
              parameter: 'click_track_url',
              description:
                'URL to fire when a user monetizes with an advertiser',
              example:
                'click_track_url=https%3A%2F%2Fwww.trackingsite.com' +
                '%2Fid%2F1234%3Fstate%3Dclick',
            },
          ],
        },
      ],
    },
    {
      title: 'Google',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Google Parameters',
          records: [
            {
              parameter: 'gamid',
              description:
                'Google Ads Measurement ID for your account. ' +
                'The property ID may represent measurement for different ' +
                'Google products, including a Universal Analytics property ' +
                '("UA-XXXXXXXX"), a Google Analytics 4 property ' +
                '("G-XXXXXXXX"), Google Ads ("AW-XXXXXXXX") or ' +
                'Floodlight ("DC-XXXXXXXX")',
              example: 'gamid=AW-123456789',
            },
            {
              parameter: 'glcid',
              description:
                'Conversion ID associated with the Google Ads conversion ' +
                'you would like to fire on the lander',
              example: 'glcid=AW-123456789/AbC-D_efG-h 12_34-567',
            },
            {
              parameter: 'ga4mid',
              description:
                'Optional - an additional Property ID to measure page views and events',
              example: 'ga4mid=G-123456789',
            },
          ],
        },
      ],
    },
    {
      title: 'Facebook',
      notes: [
        `The parameters marked with an asterisk (*) are only available
    to partners that have worked with System1 to set up server-to-server
    tokens.`,
        `Without tokens, only impression tracking (fbland) is available on
    Facebook.`,
        `If you would like to configure server-to-server tokens, please reach
    out to your account manager.`,
      ],
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Facebook Parameters',
          records: [
            {
              parameter: 'fbid',
              description: 'ID of the Facebook pixel',
              example: 'fbid=1234567890',
            },
            {
              parameter: 'fbland',
              description:
                'Name of the conversion event to fire when the user reaches ' +
                'the System1 campaign lander',
              example: 'fbland=PageView',
            },
            {
              parameter: 'fbserp*',
              description:
                'Name of the conversion event to fire when a user executes ' +
                'a search',
              example: 'fbserp=Add+To+Wishlist',
            },
            {
              parameter: 'fbclick*',
              description:
                'Name of the conversion event to fire when a ' +
                'user monetizes with an advertiser',
              example: 'fbclick=Purchase',
            },
            {
              parameter: 'fbclid*',
              description: 'Facebook click ID assigned to the user',
              example: 'fbclid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'fbtest*',
              description:
                'The test_event_code linked to this pixel, for the purpose of ' +
                'testing server events',
              example: 'fbtest=TEST1234',
            },
          ],
        },
      ],
    },
    {
      title: 'Taboola',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Taboola Parameters',
          records: [
            {
              parameter: 'tbclickid',
              required: true,
              description:
                'Taboola click ID assigned to the user. ' +
                'This is available on the Taboola platform as the macro {click_id}',
              example: 'tbclickid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'tbid',
              description:
                'Taboola click ID assigned to the user. This is available on the Taboola platform as the macro {click_id} (server-to-server)',
              example: 'tbid=1234556748',
            },
            {
              parameter: 'tbland',
              description:
                'Name of the conversion event to fire when the user reaches the ' +
                'System1 campaign lander',
              example: 'tbland=PageView',
            },
            {
              parameter: 'tbserp',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'tbserp=Add+To+Wishlist',
            },
            {
              parameter: 'tbclick',
              description:
                'Name of the conversion event to fire when a user monetizes ' +
                'with an advertiser',
              example: 'tbclick=Purchase',
            },
          ],
        },
      ],
    },
    {
      title: 'Outbrain',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Outbrain Parameters',
          records: [
            {
              parameter: 'obclickid',
              required: true,
              description:
                'Outbrain click ID assigned to the user. ' +
                'This is available on the Outbrain platform as the macro ' +
                '{{ob_click_id}}',
              example: 'obclickid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'obland',
              description:
                'Name of the conversion event to fire when the user ' +
                'reaches the System1 campaign lander',
              example: 'obland=PageView',
            },
            {
              parameter: 'obserp',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'obserp=Add+To+Wishlist',
            },
            {
              parameter: 'obclick',
              description:
                'Name of the conversion event to fire when a user monetizes ' +
                'with an advertiser',
              example: 'obclick=Purchase',
            },
          ],
        },
      ],
    },
    {
      title: 'TikTok',
      notes: [
        `The parameters marked with an asterisk (*) are only available to
    partners that have worked with System1 to set up server-to-server tokens.`,
        `Without tokens, only impression tracking (ttland) is available on TikTok.`,
        `If you would like to configure server-to-server tokens, please reach out
    to System1.`,
      ],
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'TikTok Parameters',
          records: [
            {
              parameter: 'ttid',
              description: 'ID of the TikTok pixel',
              example: 'ttid=1234567890',
            },
            {
              parameter: 'ttland',
              description:
                'Name of the conversion event to fire when ' +
                'the user reaches the System1 campaign lander',
              example: 'ttland=PageView',
            },
            {
              parameter: 'ttserp*',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'ttserp=Add+To+Wishlist',
            },
            {
              parameter: 'ttclick*',
              description:
                'Name of the conversion event to fire when a user ' +
                'monetizes with an advertiser',
              example: 'ttclick=Purchase',
            },
            {
              parameter: 'ttclid*',
              description:
                'TikTok click ID assigned to the user. This is available on ' +
                'the TikTok platform as the macro __CLICKID__',
              example: 'ttclid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'tttest*',
              description:
                'The test_event_code linked to this pixel, ' +
                'for the purpose of testing server events',
              example: 'tttest=TEST1234',
            },
          ],
        },
      ],
    },
    {
      title: 'Snapchat',
      notes: [
        `The parameters marked with an asterisk (*) are only available to
    partners that have worked with System1 to set up server-to-server tokens.`,
        `Without tokens, only impression tracking (scland) is available on Snapchat.`,
        `If you would like to configure server-to-server tokens,
    please reach out to your account manager.`,
      ],
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Snapchat Parameters',
          records: [
            {
              parameter: 'scid',
              description: 'ID of the Snapchat pixel',
              example: 'scid=f5932083-b4da-436e-b63c-94b659dde332',
            },
            {
              parameter: 'scland',
              description:
                'Name of the conversion event to fire when the user reaches the ' +
                'System1 campaign lander',
              example: 'scland=VIEW_CONTENT',
            },
            {
              parameter: 'scserp*',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'scserp=SEARCH',
            },
            {
              parameter: 'scclick*',
              description:
                'Name of the conversion event to fire when a ' +
                'user monetizes with an advertiser',
              example: 'scclick=PURCHASE',
            },
          ],
        },
      ],
    },
    {
      title: 'Zemanta',
      notes: null,
      headers: ['Parameter', 'Description', 'Example'],
      data: [
        {
          header: 'Zemanta Parameters',
          records: [
            {
              parameter: 'zmid',
              description: 'Zemanta tag ID (fka account ID)',
              example: 'zmid=12345',
            },
            {
              parameter: 'zmclickid',
              description:
                'Zemanta click ID. Use the {postbackid} macro in Zemanta to ' +
                'add this param to the campaign url',
              example: 'zmclickid=AbCdEfGhIjKlMnOpQrStUvWxYz1234567890',
            },
            {
              parameter: 'zmland',
              description:
                'Name of the conversion event to fire when the user reaches the ' +
                'System1 campaign lander',
              example: 'zmland=PageView',
            },
            {
              parameter: 'zmserp',
              description:
                'Name of the conversion event to fire when a user executes a search',
              example: 'zmserp=Add+To+Wishlist',
            },
            {
              parameter: 'zmclick',
              description:
                'Name of the conversion event to fire when a user ' +
                'monetizes with an advertiser',
              example: 'zmclick=Purchase',
            },
          ],
        },
      ],
    },
  ];

  constructor(
    public ovs: ObjectValidatorService,
    public columnClassHelper: ColumnClassHelper,
    private _anchorService: AnchorService,
  ) {}

  public navigateToAnchor(location: string) {
    this._anchorService.updateAnchorLocation(location);
  }
}
