import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchableChipListComponent } from './searchable-chip-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from '../../core/angular-material.module';

@NgModule({
  declarations: [SearchableChipListComponent],
  imports: [AngularMaterialModule, ReactiveFormsModule, MatChipsModule, CommonModule],
  exports: [SearchableChipListComponent]
})
export class SearchableChipListModule {}
