import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedDomainModel } from '../../models/paginated-domain.model';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';
import { Sort } from '@angular/material/sort';
import { DomainManagerPrefilterModel } from '../../models/domain-manager-prefilter.model';
import { HttpParams } from '@angular/common/http';
import { Partner } from '../../../../shared/models/partner.model';

@Injectable()
export class DomainListWebService {
  constructor(private _webServiceBuilderService: NimbusWebServiceBuilderService) { }

  getDomains(
    partners: Partner[],
    page: number = 1,
    pageSize: number = 25,
    sort: Sort,
    filter: DomainManagerPrefilterModel
  ): Observable<PaginatedDomainModel> {
    let params = new HttpParams()
    .append('pageNumber', page)
    .append('pageSize', pageSize)
    .append('sortBy', sort.active)
    .append('sortDirection', sort.direction)
    .append('domainNameFilter', filter.domainLookup);

    partners.forEach(partner => {
      params = params.append('partnerId', partner.id);
    });

    filter.statuses.forEach(status => {
      params = params.append('status', status);
    });

    return this._webServiceBuilderService.builder
      .withApi("PartnerAPI")
      .withUrl(`partners/parkeddomains`)
      .build()
      .get(params);
  }
}
