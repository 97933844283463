import { RouterModule, Routes } from "@angular/router";
import { AccessControlGuardService } from "@nimbus/shared-lib";
import { OktaAuthGuard } from "@okta/okta-angular";
import { RampjsScriptBuilderComponent } from "./rampjs-builder/rampjs-builder.component";
import { NgModule } from "@angular/core";

const routes: Routes = [
        {
            path: 'rampjs/builder',
            canActivate: [OktaAuthGuard, AccessControlGuardService],
            component: RampjsScriptBuilderComponent,
            data: { resourceName: 'RampjsScriptBuilderComponent' }
          },      
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class RampjsRoutingModule {}