import { Injectable } from '@angular/core';
import {
  CurrencyPipe,
  PercentPipe,
  DatePipe,
  DecimalPipe,
} from '@angular/common';
import { DateTime } from 'luxon';
import {
  ColumnConfiguration,
  PipeType,
} from '../q-models/column-configuration';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { TimeService } from '../shared/time-service';

// This formater is great but we now need other type of filters
// and now that we have the new validators and formaters as plugins,
// it would make sense to refactor this one up too later on.
@Injectable()
export class ValueFormatterService {
  private readonly _locale = 'en-US';
  private _currencyPipe = new CurrencyPipe(this._locale);
  private _percentPipe = new PercentPipe(this._locale);
  private _datePipe = new DatePipe(this._locale);
  private _decimalPipe = new DecimalPipe(this._locale);

  constructor(
    private _ovs: ObjectValidatorService,
    private _timeService: TimeService,
  ) {}

  format(col: ColumnConfiguration, value: any): any {
    if (col.nullSubstitute !== null && !this._ovs.isDefined(value)) {
      value = col.nullSubstitute;
    }
    switch (col.pipeType) {
      case PipeType.Currency:
        return isNaN(parseFloat(value))
          ? value
          : this._currencyPipe.transform(value);
      case PipeType.Percent:
        return isNaN(parseFloat(value))
          ? value
          : this._percentPipe.transform(value, col.pipeFormat);
      case PipeType.Date:
        return this._datePipe.transform(
          value,
          col.pipeFormat !== '' ? col.pipeFormat : 'yyyy-MM-dd',
        );
      case PipeType.Time:
        return this._datePipe.transform(
          value,
          col.pipeFormat !== '' ? col.pipeFormat : 'h:mm:ss a',
        );
      case PipeType.DateTime:
        return this._datePipe.transform(
          value,
          col.pipeFormat !== '' ? col.pipeFormat : 'yyyy-MM-dd h:mm:ss a',
        );
      case PipeType.Decimal:
        return isNaN(parseFloat(value))
          ? value
          : this._decimalPipe.transform(
              value,
              col.pipeFormat !== '' ? col.pipeFormat : '1.0-2',
            );
      case PipeType.DaysOrHours:
        return this.getDateInDaysOrHours(value, col.nullSubstitute);
      case PipeType.Default:
      default:
        return value;
    }
  }

  getDateInDaysOrHours(
    updateTime: string,
    nullInputReturn: string = '',
  ): string {
    if (!this._ovs.isDefined(updateTime) || updateTime === '') {
      return nullInputReturn;
    }
    const dateTime = new Date(Date.parse(updateTime));
    const totalDays =
      DateTime.now().diff(
        DateTime.fromISO(this._timeService.utcToLocalTime(dateTime)),
        'days',
      ).days | 0;
    if (totalDays === 0) {
      return (
        (
          DateTime.now().diff(
            DateTime.fromISO(this._timeService.utcToLocalTime(dateTime)),
            'hours',
          ).hours | 0
        ).toString() + ' h'
      );
    } else {
      if (totalDays > 99) {
        return '99+ days';
      }
      return totalDays.toString() + ' days';
    }
  }
}
