import { Injectable } from '@angular/core';
import { ConfigService } from '../../core/config.service';
import { ObjectValidatorService } from '../object-validator-service/object-validator.service';

// The event just help quickly classifying log enries.
// Feel free to add new event types as needed.
export enum LoggerMethodEvent {
  TraceStart = 'TraceStart',
  TraceEnd = 'TraceEnd',
  Tracing = 'Tracing',
  QuickReturn = 'QuickReturn',
  Information = 'Information',
  Warning = 'Warning',
  Error = 'Error'
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  private _counter = 0;
  private _logs: string[] = [];
  get logs(): string[] {
    return this._logs;
  }

  constructor(private _configService: ConfigService, private _ovs: ObjectValidatorService) {}

  ClearLogs() {
    this._logs = [];
  }

  log(
    componentName: string,
    methodName: string,
    event: LoggerMethodEvent,
    comment?: string,
    body?: any
  ): void {
    const logEntry =
      (this._counter++).toString() +
      ' - ' +
      event +
      ' - ' +
      componentName +
      ' - ' +
      methodName +
      (this._ovs.isNullOrEmpty(comment) ? '' : ' - ' + comment);

    const errorBody = body ? JSON.stringify(body) : '';
    this.logs.push(logEntry);
    if (!this._ovs.isNullOrEmpty(errorBody)) {
      this.logs.push(errorBody);
    }

    if (this.logs.length > this._configService.getLoggerConfiguration().rndViewerbufferSize) {
      this.logs.slice(
        this._configService.getLoggerConfiguration().rndViewerbufferSize - this.logs.length,
        this.logs.length - 1
      );
    }

    if (this._configService.getLoggerConfiguration().useConsole) {
      console.log(logEntry);
      if (!this._ovs.isNullOrEmpty(errorBody)) {
        console.log(body);
      }
    }
  }
}
