export class PixelAccessTokenModel {
  constructor(
    public partnerId: number,
    public buysideNetworkId: number,
    public pixelId: string,
    public accessToken: string,
    public id?: string,
    public email?: string
  ) {}
}
