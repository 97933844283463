import { Component, Input } from '@angular/core';
import { BreadcrumbsPrettifyService } from './breadcrumbs.prettify.service';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AngularMaterialModule } from 'core-global-frontend-angular-material';

interface Breadcrumb {
  url: string;
  label: string;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'nimbus-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  imports: [CommonModule, RouterModule, AngularMaterialModule, BrowserModule],
  standalone: true,
})
export class BreadcrumbsComponent {
  @Input() path!: string;

  constructor(private prettifyService: BreadcrumbsPrettifyService) {}

  makeBreadcrumbs(): Breadcrumb[] {
    const steps = this.path.split('/');
    return steps
      .filter(p => !(p === '' || p === 'dashboard'))
      .map((p, i) => ({
        url: steps.slice(0, i + 1).join('/'),
        label: this.prettifyService.makeLabel(p),
      }));
  }
}
