import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Injectable({
  providedIn: 'root',
})
export class HttpOptionsBuilderFactory {
  constructor(private _ovs: ObjectValidatorService) {}
  get builder(): HttpOptionBuilder {
    return new HttpOptionBuilder(this._ovs);
  }
}

export class HttpOptionBuilder {
  private _httpOptions = {};
  constructor(private _ovs: ObjectValidatorService) {}

  withParameters(parameters?: any): HttpOptionBuilder {
    if (this._ovs.isDefined(parameters)) {
      let params = new HttpParams();
      Object.keys(parameters)
        .filter(key => !this._ovs.isNullOrUndefined(parameters[key]))
        .map(k => {
          if (this._ovs.isArray(parameters[k])) {
            parameters[k].map(value => (params = params.append(k, value)));
          } else {
            params = params.append(k, parameters[k]);
          }
        });
      (this._httpOptions as any).params = params;
    }
    return this;
  }

  withHeaders(headers: any): HttpOptionBuilder {
    if (this._ovs.isDefined(headers)) {
      (this._httpOptions as any).headers = headers;
    }
    return this;
  }

  withObserve(observe: string): HttpOptionBuilder {
    if (this._ovs.isDefined(observe)) {
      (this._httpOptions as any).observe = observe;
    }
    return this;
  }

  withResponseType(type: string): HttpOptionBuilder {
    if (this._ovs.isDefined(type)) {
      (this._httpOptions as any).responseType = type;
    }
    return this;
  }

  withCredentials(credentials: boolean): HttpOptionBuilder {
    if (this._ovs.isDefined(credentials)) {
      (this._httpOptions as any).withCredentials = credentials;
    }
    return this;
  }

  build(): any {
    return this._httpOptions;
  }
}
