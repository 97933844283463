import { Component, Injectable } from '@angular/core';
import { ColumnType } from './grid/column-type';

/**
 * @title Key/Component mapper service
 *
 * Register any custom cell types.
 *
 * When the grid parse the schemas, any column definition that has a type matching a key in the map will use the component pointed to by the key.
 * If no mapping is found, the default cell is used.
 */
@Injectable({
  providedIn: 'root',
})
export class GridCellComponentMapperService {
  private readonly _componentMap = new Map<string, Component>();

  registerComponent(key: string | ColumnType, component: Component) {
    this._componentMap.set(<string>key, component);
  }

  componentMap(key: string | ColumnType): Component {
    return this._componentMap.get(<string>key) as Component;
  }
}
