export class LocalStorageKey {
  keyVersion = '1.0.0';

  constructor(
    public expiration: Date,
    public clientVersion: string,
    public userSpecific: boolean,
    public keyName: string
  ) {
    this.clientVersion = clientVersion;
    this.userSpecific = userSpecific;
    this.keyName = keyName;
    this.expiration = expiration;
  }

  getString() {
    return JSON.stringify(this);
  }

  equals(key: LocalStorageKey): boolean {
    return (
      this.keyName === key.keyName &&
      this.clientVersion === key.clientVersion &&
      this.keyVersion === key.keyVersion
    );
  }
}
