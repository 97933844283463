import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { QGridComponent } from './q-grid/q-grid.component';
import { QRowComponent } from './q-row/q-row.component';
import { QPaginatorComponent } from './q-paginator/q-paginator.component';
import { GridKeyboardService } from './q-services/grid-keyboard.service';
import { QCellEditorComponent } from './q-row/q-cell-editor/q-cell-editor.component';
import { QCellEditorContainerComponent } from './q-row/q-cell-editor/q-cell-editor-container.component';
import { QCustomCellContainerComponent } from './q-row/q-custom-cell-container/q-custom-cell-container.component';
import { QCellFocusComponent } from './q-row/q-cell-focus/q-cell-focus.component';
import { QCellFocusContainerComponent } from './q-row/q-cell-focus/q-cell-focus-container.component';
import { FilterBoxModule } from './filter-box/filter-box.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { SubscriptionManager } from './shared/subscription-manager';
import { FilterBoxItemsBuilderService } from './filter-box/services/filter-box-items-builder';
import { ValueFormatterService } from './q-services/value-formatter.service';
import { TimeService } from './shared/time-service';
import { QResizableColDirective } from './q-models/q-resizable-col.directive';
import { QGridStyleService } from './q-services/q-grid-style.service';
import { QHighlightAreaComponent } from './q-highlight-area/q-highlight-area.component';
import { QCopyAreaComponent } from './q-copy-area/q-copy-area.component';
import { FileDownloadService } from './q-services/file-download.service';
import { ClipboardDataConversionService } from './q-services/clipboard-data-conversion.service';
import { GridClipboardBuilderService } from './q-services/grid-clipboard-builder.service';
import { GridCopyPasteBuilderService } from './q-services/grid-copy-paste-builder.service';
import { GridTableAccessBuilderService } from './q-services/q-grid-table-access-builder.service';
import { GridValueBuilderService } from './q-services/grid-value-builder.service';
import { GridMouseEditFeaturesBuilderService } from './q-services/grid-mouse-edit-features-builder.service';
import { ChangeTrackerBuilderService } from './q-services/change-tracker-builder.service';
import { CellEditorBuilderService } from './q-services/cell-editor-builder.service';
import { CellFocusBuilderService } from './q-services/cell-focus-builder.service';
import { CellGridBuilderFactory } from './q-services/cell-grid-builder-factory';
import { StringFunctionsService } from './shared/string-functions.service';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { LocalStorageProxyBuilder } from './shared/local-storage/local-storage-proxy-builder';
import { LocalStorageKeyFactory } from './shared/local-storage/local-storage-key-factory';
import { LocalStorageManagerService } from './shared/local-storage/local-storage-manager.service';
import { QLocalStorageService } from './shared/local-storage/local-storage.service';
import { DisplayValidatorPipe } from './q-row/display-validator.service';
import { GetColumnCssClassPipe } from './q-row/pipes/get-column-css-class-pipe';
import { QStickyColumnDirective } from './q-models/q-sticky-column.directive';
import { QColumnWidthDirective } from './q-models/q-column-width.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DynamicPipe } from './q-row/pipes/dynamic-pipe';
import { GetLabelPipe } from './q-row/pipes/get-label-pipe';
import { GetFormattedColValuePipe } from './q-row/pipes/get-formatted-col-value-pipe';
import { GetColValuePipe } from './q-row/pipes/get-col-value-pipe';
import { HasCellFilterPipe } from './q-row/pipes/has-cell-filter-pipe';
import { GetCellFilterPipe } from './q-row/pipes/get-cell-filter-pipe';
import { CallbackRunnerModule } from './shared/callback-runner/callback-runner.module';
import { QGridExpanderContainerComponent } from './q-grid/q-grid-expander-container/q-grid-expander-container.component copy';
import { DownloadButtonComponent } from './download-button/download-button.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    FilterBoxModule,
    MatOptionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatCardModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatButtonModule,
    MatListModule,
    MatTabsModule,
    MatMenuModule,
    ScrollingModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatInputModule,
    CallbackRunnerModule,
  ],
  declarations: [
    DownloadButtonComponent,
    QGridComponent,
    QRowComponent,
    GetColumnCssClassPipe,
    QPaginatorComponent,
    QCellEditorComponent,
    QCellEditorContainerComponent,
    QCustomCellContainerComponent,
    QGridExpanderContainerComponent,
    QCellFocusComponent,
    QCellFocusContainerComponent,
    QResizableColDirective,
    QHighlightAreaComponent,
    QCopyAreaComponent,
    QStickyColumnDirective,
    QColumnWidthDirective,
    DynamicPipe,
    GetLabelPipe,
    GetFormattedColValuePipe,
    GetColValuePipe,
    DisplayValidatorPipe,
    HasCellFilterPipe,
    GetCellFilterPipe,
  ],
  exports: [
    QGridComponent,
    QRowComponent,
    GetColumnCssClassPipe,
    QCellEditorComponent,
    QCellEditorContainerComponent,
    QCellFocusContainerComponent,
  ],
  providers: [
    StringFunctionsService,
    CellGridBuilderFactory,
    CellFocusBuilderService,
    CellEditorBuilderService,
    ChangeTrackerBuilderService,
    ValueFormatterService,
    GridMouseEditFeaturesBuilderService,
    GridValueBuilderService,
    GridTableAccessBuilderService,
    GridCopyPasteBuilderService,
    ClipboardDataConversionService,
    GridClipboardBuilderService,
    GridKeyboardService,
    FileDownloadService,
    ObjectValidatorService,
    SubscriptionManager,
    FilterBoxItemsBuilderService,
    ValueFormatterService,
    TimeService,
    QGridStyleService,
    LocalStorageProxyBuilder,
    LocalStorageKeyFactory,
    LocalStorageManagerService,
    QLocalStorageService,
  ],
})
export class QGridModule {}
