<ng-container>
  <h2 class="header add-margin">
    <svg role="img" class="icon icon_spacing">
      <use xlink:href="assets/img/sprites.svg#partner-note" />
    </svg>
    <span class="font-weight-bold">Add a Note</span>
  </h2>
</ng-container>
<mat-label>{{ field.props['label'] }}</mat-label>
<textarea
  [rows]="field.props['rows']"
  [formControl]="formcontrol()"
  [placeholder]="field.props['placeholder']"></textarea>
