import { Component } from '@angular/core';
import { BasePageComponent, ILayoutContent } from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PixelManagerAccessTokenHistoryColumnBuilderService } from '../services/pixel-manager-access-token-history-column-builder.service';
import { PixelManagerCacheService } from '../services/pixel-manager-cache.service';
import { ColumnInfo } from 'core-global-frontend-grid';
import { GridModel } from 'core/libs/global/frontend/grid/src/lib/grid-models/grid-model';

@Component({
  selector: 'app-pixel-manager-history',
  templateUrl: './pixel-manager-history.component.html',
  styleUrls: ['./pixel-manager-history.component.scss'],
})
export class PixelManagerHistoryComponent
  extends BasePageComponent
  implements ILayoutContent
{
  emailID: string;
  columns: ColumnInfo[];
  accessTokens$: Observable<any> =
    this.pixelManagerCacheService.accessTokens$.pipe(
      startWith([]),
      map(accessTokens =>
        accessTokens.sort((a, b) => {
          if (a.updatedDate === b.updatedDate)
            return a.createdDate > b.createdDate ? -1 : 1;
          else if (!a.updatedDate) return 1;
          else if (!b.updatedDate) return -1;
          else return a.updatedDate > b.updatedDate ? -1 : 1;
        }),
      )
    );
  emptyGridModel: GridModel = new GridModel("pixel-manager", []);
  gridModel$: Observable<GridModel> = this.accessTokens$.pipe(
    map(token => new GridModel("pixel-manager", token))
  );

  constructor(
    public pixelManagerCacheService: PixelManagerCacheService,
    private _pixelAccessTokenHistoryColumnBuilderService: PixelManagerAccessTokenHistoryColumnBuilderService,
  ) {
    super();
    this.columns = this._pixelAccessTokenHistoryColumnBuilderService.columns;
  }
}
