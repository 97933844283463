import { CommonModule } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { Observable, filter, map } from 'rxjs';

/**
 * PageShellComponent:
 * This class should be used as a wrapper around any content component.
 *
 * It can inject any component in the page layout's actionBar.
 * The actionBarContent component can be passed as an input or a route parameter as:
 * data: {
 *     actionBarComponent: DebugComponent
 *     //...
 *  }
 */
@Component({
  templateUrl: './page-shell.component.html',
  styleUrls: ['./page-shell.component.scss'],
  imports: [CommonModule, RouterModule],
  standalone: true,
})
export class PageShellComponent implements OnInit, OnDestroy {
  @ViewChild('actionBar', { read: ViewContainerRef, static: true })
  _actionBar!: ViewContainerRef;
  title$!: Observable<string>;
  private _sm = new SubscriptionManager();

  constructor(private _activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this._sm.register(
      this._activatedRoute.data
        .pipe(
          filter(
            data =>
              !!this._actionBar &&
              !!data &&
              !!(data as any)['actionBarComponent'],
          ),
          map(data => (data as any)['actionBarComponent']),
        )
        .subscribe(actionBarComponent =>
          this._actionBar.createComponent(actionBarComponent),
        ),
    );

    this.title$ = this._activatedRoute.data.pipe(
      filter(data => !!this._actionBar && !!data && !!(data as any)['title']),
      map(data => (data as any)['title'] as string),
    );
  }

  ngOnDestroy(): void {
    this._sm.clear();
  }
}
