import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { SearchableListItemWithSelect, SortOption } from '@nimbus/shared-lib';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PartnerCacheService } from '../../../services/partner-cache.service';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Component({
  selector: 'app-partner-select',
  templateUrl: './partner-select.component.html',
  styleUrls: ['./partner-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerSelectComponent
  extends FieldType<FieldTypeConfig>
  implements AfterViewInit, OnDestroy {
  private _firstPartnerId: number;
  private readonly _subscriptionManager = new SubscriptionManager();
  partnerHasMultiple = true;

  sort: SortOption = SortOption.AutoSort;
  items$: Observable<SearchableListItemWithSelect[]> =
    this._partnerCacheService.partners$.pipe(
      tap(partners => (this._firstPartnerId = partners[0].id)),
      tap(partners => (this.partnerHasMultiple = partners.length > 1)),
      map(partners =>
        partners.map(
          partner =>
            new SearchableListItemWithSelect(partner.id, false, partner.name),
        ),
      ),
    );

  isLoading$ = this._partnerCacheService.isLoading$;

  constructor(
    public ovs: ObjectValidatorService,
    private _partnerCacheService: PartnerCacheService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this._subscriptionManager.register(
      this.isLoading$
        .pipe(
          tap(isLoading => {
            if (isLoading) {
              return;
            }
            if (!this.partnerHasMultiple) {
              this.formControl.setValue(this._firstPartnerId);
              this.field.hide = true;
            } else {
              this.formControl.setValue(null);
              this.field.hide = false;
            }
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
  }
}
