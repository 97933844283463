<div class="container" title="Edit Access Token">
  <div *ngIf="!isEditMode">
    <svg *ngIf="isEditable()" role="img" (click)="edit()">
      <use xlink:href="assets/img/sprites.svg#partner-pencil-icon" />
    </svg>
    <div *ngIf="!isEditable()" class="spacer"></div>
    <span>
      {{ form.controls.accessToken.value }}
    </span>
  </div>
  <div *ngIf="isEditMode">
    <app-edit-access-token-input
      [accessToken]="form.controls.accessToken.value"
      (submitEdit)="submitEdit($event)"
      (cancelEdit)="cancelEditMode($event)">
    </app-edit-access-token-input>
  </div>
</div>
