import { Pipe, PipeTransform } from '@angular/core';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';

@Pipe({ name: 'callbackRunnerPipe' })
export class CallbackRunnerPipe implements PipeTransform {
  constructor(private _ovs: ObjectValidatorService) {}
  transform(
    value: any,
    callback: (value: any) => any,
    _params: any = null,
  ): any {
    if (this._ovs.isFunction(callback)) {
      return callback(value);
    }
  }
}
