import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Input,
  OnDestroy,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IClickableCell, ICustomCell } from '../cells/icell-component';
import { GridCellComponentMapperService } from '../grid-cell-component-mapper.service';
import { ColumnInfo } from '../grid/column-info';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ColumnType } from '../grid/column-type';
import { Subscription } from 'rxjs';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

/**
 * @title mat-table cell injector.
 *
 * This component is used to inject the correct cell component based on the column type.
 * If the column type is not found in the map, the default cell is used.
 *
 * @param editable - boolean - if true, the cell will be editable
 * @param form - the formControl bound to the cell
 * @param column - ColumnInfo - the column information
 * @param rowIndex - Row index
 * @param (optional) cellComponentsCache - component instantiation will be skipped if the component is already in the cache.
 */
@Component({
  selector: 'frontend-cell-component-injector',
  templateUrl: './cell-component-injector.component.html',
  styleUrls: ['./cell-component-injector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
  ],
})
export class CellComponentInjectorComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef, static: true })
  container!: ViewContainerRef;
  private _clickSubscription: Subscription | undefined;
  @Input() editable = false;
  @Input() rowForm!: FormGroup;
  @Input() column!: ColumnInfo;
  @Input() rowIndex!: number;
  @Input() dataSource!: MatTableDataSource<FormGroup<any>, MatPaginator>;
  @Input() cellComponentsCache:
    | Map<ComponentRef<unknown>, Map<number, any>>
    | undefined;
  private readonly _defaultCell: ColumnType = ColumnType.StaticText;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private _gridCellComponentMapperService: GridCellComponentMapperService,
  ) {}

  ngOnDestroy(): void {
    if (this._clickSubscription) {
      this._clickSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    const instanceType: string = this.column?.type ?? this._defaultCell;
    let component = this._getComponent(instanceType) as any;
    component = this._initCellData(component);
    component = this._attachEvents(component);
    this._cache(instanceType, component);
  }

  private _cache(instanceType: any, component: any) {
    const cellInstances = this._getCellInstancesFor(
      instanceType,
      this.cellComponentsCache,
    );
    if (cellInstances && !cellInstances.has(this.rowIndex)) {
      cellInstances.set(this.rowIndex, component);
    }
  }

  private _attachEvents(component: Component) {
    //Could be improved to support a list of common events.
    const clickableComponent = component as IClickableCell;
    if (clickableComponent.clickEvent && this.column.clickEvent) {
      this._clickSubscription = clickableComponent.clickEvent.subscribe(
        context =>
          (this.column.clickEvent as (cell: ICustomCell) => void)(context),
      );
    }
    return component;
  }

  private _initCellData(component: any) {
    component.form = this.rowForm;
    component.editable = this.editable;
    component.column = this.column;
    component.transform = this.column.transform;
    component.rowIndex = this.rowIndex;
    component.dataSource = this.dataSource;
    return component;
  }

  private _getComponent(instanceType: string): Component {
    if (this.cellComponentsCache) {
      const cellInstancesMap: Map<number, Component> | undefined =
        this.cellComponentsCache.get(this.column.field as any);
      if (cellInstancesMap) {
        const cellInstancesMapAtRow = cellInstancesMap.get(this.rowIndex);
        if (cellInstancesMapAtRow) {
          return cellInstancesMapAtRow as Component;
        }
      }
    }

    return this.container.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(
        this._gridCellComponentMapperService.componentMap(
          instanceType as any,
        ) as Type<any>,
      ),
    ).instance;
  }

  private _getCellInstancesFor(
    instanceType: any,
    cellComponentsCache: Map<ComponentRef<any>, Map<number, any>> | undefined,
  ): Map<number, any> | undefined {
    if (!cellComponentsCache) {
      return undefined;
    }
    let newMap = undefined;
    if (!cellComponentsCache.has(instanceType)) {
      newMap = new Map<number, any>();
      cellComponentsCache.set(this.column.field as any, newMap);
      return newMap;
    }
    return cellComponentsCache.get(this.column.field as any) as Map<
      number,
      any
    >;
  }
}
