<div class="download-button container">
  <button
    type="button"
    class="download-button button download-container"
    (click)="$event.stopPropagation(); download.emit(mySelect.value === allPages)"
    title="Download"
  >
    <svg role="img" class="download-icon">
      <use xlink:href="assets/img/sprites.svg#ramp-download-icon" />
    </svg>
    Download
    <div
      (click)="$event.stopPropagation(); mySelect.toggle()"
      class="download-options"
    >
      <mat-select #mySelect="matSelect" [value]="allPages">
        <mat-option [value]="allPages"> All</mat-option>
        <mat-option [value]="currentPage"> Page</mat-option>
      </mat-select>
      <mat-icon class="mat-icon">keyboard_arrow_down</mat-icon>
    </div>
    <div class="click-handler"></div>
    <div
      class="dropdown-click-handler"
      (click)="$event.stopPropagation(); mySelect.toggle()"
    ></div>
  </button>
</div>
