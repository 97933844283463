import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { ImpersonationTrackerService } from '../../../../shared/services/impersonation-tracker.service';

@Injectable({
  providedIn: 'root',
})
export class HttpHeadersBuilderFactory {
  constructor(
    private _ovs: ObjectValidatorService,
    private _impersonationTrackerService: ImpersonationTrackerService,
  ) {}

  get builder(): HttpHeadersBuilder {
    return new HttpHeadersBuilder(this._ovs, this._impersonationTrackerService);
  }
}

export class HttpHeadersBuilder {
  _headers: HttpHeaders;

  constructor(
    private _ovs: ObjectValidatorService,
    private _impersonationTrackerService: ImpersonationTrackerService,
  ) {
    this._headers = new HttpHeaders();
  }

  withContentType(): HttpHeadersBuilder {
    this._headers = this._headers.append('Content-Type', 'application/json');
    return this;
  }

  withAuthorization(token?: any): HttpHeadersBuilder {
    if (!this._ovs.isNullOrEmpty(token)) {
      this._headers = this._headers.append('Authorization', `Bearer ${token}`);
    }
    return this;
  }

  withImpersonatedEmail(): HttpHeadersBuilder {
    if (!this._ovs.isNullOrEmpty(this._impersonationTrackerService.user)) {
      this._headers = this._headers.append(
        'ImpersonatedEmail',
        this._impersonationTrackerService.user?.email,
      );
    }
    return this;
  }

  build(): HttpHeaders {
    return this._headers;
  }
}
