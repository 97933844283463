import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DockComponent } from './dock/dock.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { AngularMaterialModule } from '../../../core/angular-material.module';

@NgModule({
  declarations: [DockComponent],
  exports: [DockComponent],
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatInputModule,
    MatOptionModule,
    MatAutocompleteModule
  ]
})
export class DockModule {}
