<lib-searchable-select-control
  [isDisabled]="isDisabled"
  [control]="formControl"
  [class]="
    (linksetCacheService.isLoading$ | async) || (fullGeoCacheService.isLoading$ | async)
      ? 'gray-loader'
      : ''
  "
  [searchableListItems]="items$ | async"
  [sortOption]="autoSort"
  [readonly]="
    ovs.isNullOrUndefined(field.props['readOnly']) ? false : field.props['readOnly']
  "
  [label]="field.props['label']">
</lib-searchable-select-control>
<span
  class="mat-mdc-form-field-error"
  role="alert"
  *ngIf="formControl.touched && field.props['required'] && !formControl.valid">
  <formly-validation-message [field]="field"></formly-validation-message>
</span>
