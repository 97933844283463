import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { ContextFactoryService } from './context-factory.service';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';

@Injectable({
  providedIn: 'root'
})
export class HasAccountContextGuard {
  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _contextFactoryService: ContextFactoryService,
    private _ovs: ObjectValidatorService
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this._oktaAuth.isAuthenticated()) {
      return of(false);
    }
    return this._contextFactoryService.getContext().pipe(
      map(
        userContextEntries => {
          if (this._ovs.isNullOrEmpty(userContextEntries)) {
            return false;
          }
          return true;
        },
        catchError(err => {
          return of(false);
        })
      )
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(route, state);
  }
}
