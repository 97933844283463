import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, combineLatest, EMPTY } from 'rxjs';
import {
  tap,
  map,
  withLatestFrom,
  catchError,
} from 'rxjs/operators';
import { LinksetModel } from '../models/linkset.model';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import {
  SubjectManager,
  SubscriptionManager,
} from '@nimbus/global-frontend-subscription-manager';
import { SnackbarService, SnackBarType } from 'core-global-frontend-snackbar';
import { NimbusWebServiceBuilderService } from 'core-global-frontend-http';

@Injectable({
  providedIn: 'root',
})
export class LinksetCacheService {
  private _linksets$: Observable<LinksetModel[]>;
  private _staticLinksets: LinksetModel[] = [];
  private _verticals$: Observable<string[]>;
  private readonly _loadingInProgressSubject = new BehaviorSubject<boolean>(
    false,
  );
  private readonly _subjectManager = new SubjectManager();
  private readonly _subscriptionManager = new SubscriptionManager();
  get linksets(): Observable<LinksetModel[]> {
    return this._linksets$;
  }
  get staticLinksets(): LinksetModel[] {
    return this._staticLinksets;
  }

  readonly isLoading$: Observable<boolean> = this._loadingInProgressSubject.asObservable();

  constructor(
    private _webServiceBuilderService: NimbusWebServiceBuilderService,
    private _ovs: ObjectValidatorService,
    private _snackbarService: SnackbarService,
  ) {
    this._subjectManager.register('linksets');
    this._subjectManager.register('verticals');
    this._linksets$ = this._subjectManager.get('linksets');
    this._verticals$ = this._subjectManager.get('verticals');
    this._subscriptionManager.registerMultiple([
      this.getLinksets().subscribe(),
      this.linksets.subscribe(),
    ]);
  }

  getLinksets() {
    this._loadingInProgressSubject.next(true);
    return this._webServiceBuilderService.builder.withUrl('linksets').build().get<LinksetModel[]>({ isexternallyenabled: true })
      .pipe(
        tap(),
        catchError(() => {
          this._snackbarService.open("Error getting linksets. Please try again!");
          return EMPTY;
        }),
        tap(() => this._loadingInProgressSubject.next(false)),
        tap(linksets => {
          this._staticLinksets = linksets;
          this._subjectManager.next('linksets', linksets);
        }),
        map(linksets => ([...new Set(linksets.filter(x => !!x).map(linkset => linkset?.['vertical']))])),
        tap(verticals => this._subjectManager.next('verticals', verticals)),
        catchError(() => {
          this._snackbarService.open("Error getting linksets. Please try again.");
          return EMPTY;
        })
      );
  }

  getVerticals(): Observable<string[]> {
    return this._verticals$;
  }

  getSubverticals(vertical: string): Observable<string[]> {
    if (this._ovs.isNullOrEmpty(vertical)) {
      return of([]);
    }
    return of(vertical).pipe(
      withLatestFrom(this._linksets$),
      map(([vertical, linksets]) =>
        this._getSubverticalsFor(vertical, linksets),
      ),
    );
  }

  private _getSubverticalsFor(
    vertical: string,
    linksets: LinksetModel[],
  ): string[] {
    if (this._ovs.isNullOrEmpty(vertical)) {
      [];
    }
    return linksets
      .filter(linkset => linkset.vertical === vertical)
      .map(linkset => linkset.subVertical);
  }

  getGeos(vertical: string, subvertical: string): Observable<LinksetModel[]> {
    if (
      this._ovs.isNullOrEmpty(vertical) ||
      this._ovs.isNullOrEmpty(subvertical)
    ) {
      return of([]);
    }
    return combineLatest(of(vertical), of(subvertical)).pipe(
      withLatestFrom(this._linksets$),
      map(([parts, linksets]) =>
        this._getGeosFor(parts[0], parts[1], linksets),
      ),
    );
  }

  private _getGeosFor(
    vertical: string,
    subvertical: string,
    linksets: LinksetModel[],
  ): LinksetModel[] {
    return linksets.filter(
      linkset =>
        linkset.vertical === vertical &&
        linkset.subVertical === subvertical &&
        !this._ovs.isNullOrEmpty(linkset.geoTwoLetterCode) &&
        !this._ovs.isNullOrEmpty(linkset.geoFullName),
    );
  }

  getLanguages(linksetInput: LinksetModel): Observable<LinksetModel[]> {
    if (
      this._ovs.isNullOrEmpty(linksetInput.vertical) ||
      this._ovs.isNullOrEmpty(linksetInput.subVertical)
    ) {
      return of([]);
    }
    return of(linksetInput).pipe(
      withLatestFrom(this._linksets$),
      map(([linksetInput, linksets]) =>
        this._getLanguagesFor(linksetInput, linksets),
      ),
    );
  }

  private _getLanguagesFor(
    linksetInput: LinksetModel,
    linksets: LinksetModel[],
  ): LinksetModel[] {
    return linksets.filter(
      linkset =>
        linkset.vertical === linksetInput.vertical &&
        linkset.subVertical === linksetInput.subVertical &&
        linkset.geoTwoLetterCode.toLowerCase() ===
        linksetInput.geoTwoLetterCode.toLowerCase() &&
        !this._ovs.isNullOrEmpty(linkset.language) &&
        !this._ovs.isNullOrEmpty(linkset.twoLetterISOLanguageName),
    );
  }
}
