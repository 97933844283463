<h1 mat-dialog-title>Welcome back to Ramp Partner, enjoying our new UX?</h1>
<div style="padding: 30px">
  <p>
    Welcome back to Ramp Partner, enjoying our new UX?
  </p>
  <p>
    We would like to announce the launch of our new URL Builder:
  </p>
  <ul>
    <li>
      <a class="link" [mat-dialog-close]="'Thanks for acknowledging.'" (click)="navigate('/url-builder')">
        The URL Builder
      </a>
      is built for both AFD and RSOC
    </li>
  </ul>
  <p> Please reach out to your account manager if you have any questions! </p>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="'Thanks for acknowledging.'" cdkFocusInitial>
    Ok
  </button>
</div>