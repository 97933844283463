import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ColumnInfo } from '../../grid/column-info';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IClickableCell, ICustomCell } from '../icell-component';
import {
  AngularMaterialModule,
  NimbusFormsModule,
} from 'core-global-frontend-common-ui';
import { CommonModule } from '@angular/common';
import { StaticTextCellComponent } from '../static-text-cell/static-text-cell.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

/**
 * @title Input text cell
 *
 * If editable, the cell renders a input element and bind it to the form.
 * If readonly, the cell renders a default static text.
 *
 * @param editable - boolean - if true, the cell will be editable
 * @param form - FormGroup - the form to bind to the cell
 * @param column - ColumnInfo - the column information
 */
@Component({
  selector: 'frontend-input-text-cell',
  templateUrl: './input-text-cell.component.html',
  styleUrls: ['./input-text-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AngularMaterialModule,
    NimbusFormsModule,
    ReactiveFormsModule,
    StaticTextCellComponent,
    InputTextCellComponent,
  ],
})
export class InputTextCellComponent implements ICustomCell, IClickableCell {
  editable = false;
  private _form!: FormGroup;
  public get form(): FormGroup {
    return this._form;
  }
  @Input() public set form(value: FormGroup) {
    if (!value) {
      return;
    }
    this._form = value;
  }
  @Input() column!: ColumnInfo;
  @Output() clickEvent = new EventEmitter<ICustomCell>();
  @Input() rowIndex!: number;
  @Input() dataSource!: MatTableDataSource<FormGroup<any>, MatPaginator>;

  getValue() {
    return this.form.controls[this.column.field].value;
  }

  get field(): any {
    return this.form.controls[this.column.field];
  }

  onClick(): void {
    this.clickEvent.emit({
      form: this.form,
      column: this.column,
      editable: this.editable,
      rowIndex: this.rowIndex,
      dataSource: this.dataSource,
    });
  }
}
