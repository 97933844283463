import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsPrettifyService {
  makeLabel(step: string): string {
    switch (step) {
      case 'account':
        return 'Account';
      case 'bing-hosted':
        return 'Bing Hosted';
      case 'create':
        return 'Creator';
      case 'documentation':
        return 'Documentation';
      case 'domain':
        return 'Domain';
      case 'domain-performance-summary':
        return 'Domain Performance Summary';
      case 'domain-performance-trending':
        return 'Domain Performance Trending';
      case 'domain-sub-id-performance':
        return 'Domain Sub ID Performance';
      case 'hourly-intraday-pacing':
        return 'Hourly Intraday Pacing';
      case 'keyword':
        return 'Keyword';
      case 'rampjs':
        return 'RAMP.js';
      case 'self-serve':
        return 'Self Serve';
      case 'manager':
        return 'Manager';
      case 'partner-dashboard':
        return 'Partner Dashboard';
      case 'pixel':
        return 'Pixel';
      case 'requests':
        return 'Requests';
      case 'reviews':
        return 'Reviews';
      case 'search':
        return 'Search';
      case 'sub-id-performance':
        return 'Sub ID Performance';
      case 'subverticals':
        return 'Subverticals';
      case 'syndication':
        return 'Syndication';
      case 'top-keywords':
        return 'Top Keywords';
      case 'top-level':
        return 'Top Level';
      case 'yahoo-hosted':
        return 'Yahoo Hosted';
      default:
        return step;
    }
  }
}
