<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<ng-container>
  <div>
    <frontend-grid
    *ngIf="!(subverticalRequestCacheService.isLoading$ | async)"
    [model]="subverticalRequestCacheService.requestedSubverticalsGridModel$ | async"
    [columns]="columns"
    ></frontend-grid>
    <frontend-grid
    *ngIf="subverticalRequestCacheService.isLoading$ | async"
    [model]="gridModel"
    [columns]="columns"
    class="gray-loader minheight"
    ></frontend-grid>
  </div>
</ng-container>
