import { FilterBoxConfiguration } from '../filter-box/filter-box-configuration';
import { ObjectValidatorService } from 'core-global-frontend-object-validator';
import { FilterBoxConfigurationBuilderFactory } from '../filter-box/services/filter-box-configuration-builder-factory';
import { CellGrid } from '../q-models/cell-grid';
import {
  ValueType,
  InputRequiredType,
  LookupDisplayType,
  PipeType,
  ColumnType,
  SortOrder,
  ColumnConfiguration,
} from '../q-models/column-configuration';
import { CustomAction } from '../q-models/custom-action';
import { GridCallbackParameter } from '../q-models/grid-callback-parameter';
import { ICellPlugin } from '../plugins/icell-plugin';
import { Type } from '@angular/core';
import { ICustomCellComponent } from '../q-models/custom-cell-component-interface';
import { Observable } from 'rxjs';

export class ColumnConfigurationBuilder {
  private _valueType = ValueType.Value;
  private _inputRequiredType = InputRequiredType.Optional;
  private _cellPlugins: ICellPlugin[] = [];
  private _filterConfiguration: FilterBoxConfiguration;
  private _lookupDisplayType = LookupDisplayType.Value;
  private _pipeType: PipeType = PipeType.Default;
  private _pipeFormat = '';
  private _nullSubstitute: string;
  private _columnHeaderText: string;
  private _tooltipText: string;
  private _qGridType = false;
  private _width: number = null;
  private _resizable = false;
  private _cellGrid: CellGrid = null;
  private _columnType: ColumnType = ColumnType.Number;
  private _sticky = false;

  constructor(
    _filterBoxConfigurationBuilderFactory: FilterBoxConfigurationBuilderFactory,
    private _ovs: ObjectValidatorService,
  ) {
    this._filterConfiguration =
      _filterBoxConfigurationBuilderFactory.builder.build();
  }

  private _dynamicComponentType: Type<ICustomCellComponent>;
  private _defaultValue: ((qCallbackParam: GridCallbackParameter) => any) | any;
  private _customCellActions:
    | ((qCallbackParam: GridCallbackParameter) => CustomAction[])
    | CustomAction[];
  private _defaultSelectedRowCellValue$: Observable<any>;
  private _enableRowClickEmit: boolean = false;
  private _cellLookupsKey:
    | ((qCallbackParam: GridCallbackParameter) => string)
    | string = null;
  private _customSort: (
    rowA: any,
    rowB: any,
    rows: any[],
    sortOrder: SortOrder,
  ) => number = null;
  private _customFooter: (data: any[]) => any = null;
  private _onCellClick: (event: any, data: any) => void = null;
  private _isColumnVisible: () => boolean = () => true;
  private _isEditable:
    | ((qCallbackParam: GridCallbackParameter) => boolean)
    | boolean = false;
  private _skipDownload: boolean = false;
  private _cellCssClass: (row: any, rows: any[]) => string = () => '';
  private _fieldName: string;
  private _filterBoxValue:
    | ((qCallbackParam: GridCallbackParameter) => any)
    | any;

  withFooter(customFooter: (data: any[]) => any = null) {
    this._customFooter = customFooter;
    return this;
  }

  withValuesAsKeys(): ColumnConfigurationBuilder {
    this._valueType = ValueType.Key;
    return this;
  }

  withComponentType(
    componentType: Type<ICustomCellComponent>,
  ): ColumnConfigurationBuilder {
    this._dynamicComponentType = componentType;
    this._columnType = ColumnType.Component;
    return this;
  }

  withQGridType(cellGrid: CellGrid): ColumnConfigurationBuilder {
    this._cellGrid = cellGrid;
    this._qGridType = true;
    return this;
  }

  withSort(
    customSort: (
      rowA: any,
      rowB: any,
      rows: any[],
      sortOrder: SortOrder,
    ) => number,
  ): ColumnConfigurationBuilder {
    this._customSort = customSort;
    return this;
  }

  resizable(): ColumnConfigurationBuilder {
    this._resizable = true;
    return this;
  }

  withWidth(width: number): ColumnConfigurationBuilder {
    this._width = width;
    return this;
  }

  inputRequired(): ColumnConfigurationBuilder {
    this._inputRequiredType = InputRequiredType.Required;
    return this;
  }

  withCellPlugins(plugins: ICellPlugin[]): ColumnConfigurationBuilder {
    this._cellPlugins = plugins;
    return this;
  }

  withCellCss(
    cellCssClass: (row: any, rows: any[]) => string,
  ): ColumnConfigurationBuilder {
    this._cellCssClass = cellCssClass;
    return this;
  }

  withLookupsKey(
    cellLookupsKey:
      | ((qCallbackParam: GridCallbackParameter) => string)
      | string,
  ): ColumnConfigurationBuilder {
    this._cellLookupsKey = cellLookupsKey;
    this._columnType = ColumnType.Lookup;
    return this;
  }

  withFilterBox(
    filterConfiguration: FilterBoxConfiguration,
    filterBoxValue:
      | ((qCallbackParam: GridCallbackParameter) => any)
      | any = null,
  ): ColumnConfigurationBuilder {
    this._filterConfiguration = filterConfiguration;
    this._filterBoxValue = filterBoxValue;
    return this;
  }

  withLookupDisplayAsLabel(): ColumnConfigurationBuilder {
    this._lookupDisplayType = LookupDisplayType.Label;
    this._columnType = ColumnType.Lookup;
    return this;
  }

  withPipeType(pipeType: PipeType): ColumnConfigurationBuilder {
    this._pipeType = pipeType;
    return this;
  }

  withColumnHeaderText(columnHeaderText: string): ColumnConfigurationBuilder {
    this._columnHeaderText = columnHeaderText;
    return this;
  }

  withToolTipText(tooltipText: string): ColumnConfigurationBuilder {
    this._tooltipText = tooltipText;
    return this;
  }

  withPipeFormat(pipeFormat: string): ColumnConfigurationBuilder {
    this._pipeFormat = pipeFormat;
    return this;
  }

  withNullSubstitute(nullSubstitute: string): ColumnConfigurationBuilder {
    this._nullSubstitute = nullSubstitute;
    return this;
  }

  withCellClick(
    onCellClick: (event: any, data: any) => void,
  ): ColumnConfigurationBuilder {
    this._onCellClick = onCellClick;
    return this;
  }

  withColumnType(columnType: ColumnType): ColumnConfigurationBuilder {
    this._columnType = columnType;
    return this;
  }

  withField(fieldName: string): ColumnConfigurationBuilder {
    this._fieldName = fieldName;
    return this;
  }

  withColumnVisible(
    isColumnVisible: () => boolean,
  ): ColumnConfigurationBuilder {
    this._isColumnVisible = isColumnVisible;
    return this;
  }

  withDefaultValue(
    defaultValue: ((qCallbackParam: GridCallbackParameter) => any) | any = null,
  ): ColumnConfigurationBuilder {
    this._defaultValue = defaultValue;
    return this;
  }

  withCustomCellActions(
    customCellActions:
      | ((qCallbackParam: GridCallbackParameter) => CustomAction[])
      | CustomAction[] = null,
  ): ColumnConfigurationBuilder {
    this._customCellActions = customCellActions;
    return this;
  }

  withDefaultSelectedRow(
    defaultSelectedRowCellValue$: Observable<any> = null,
    enableRowClickEmit: boolean = false,
  ): ColumnConfigurationBuilder {
    this._defaultSelectedRowCellValue$ = defaultSelectedRowCellValue$;
    this._enableRowClickEmit = enableRowClickEmit;
    return this;
  }

  hidden(): ColumnConfigurationBuilder {
    this._isColumnVisible = () => false;
    return this;
  }

  editable(
    editable:
      | ((qCallbackParam: GridCallbackParameter) => boolean)
      | boolean = null,
  ): ColumnConfigurationBuilder {
    if (!this._ovs.isFunction(editable)) {
      this._isEditable = () => true;
      return this;
    }

    this._isEditable = editable;
    return this;
  }

  skipDownload(): ColumnConfigurationBuilder {
    this._skipDownload = true;
    return this;
  }

  rowSelector(
    rowSelectedDefault:
      | ((qCallbackParam: GridCallbackParameter) => boolean)
      | boolean = null,
  ): ColumnConfigurationBuilder {
    if (this._ovs.isFunction(rowSelectedDefault)) {
      this._defaultValue = rowSelectedDefault;
    }

    return this.withField(ColumnConfiguration.qGridRowSelectorFieldName)
      .editable()
      .withColumnType(ColumnType.Boolean);
  }

  sticky(): ColumnConfigurationBuilder {
    this._sticky = true;
    return this;
  }

  build(text: string = ''): ColumnConfiguration {
    let columnTypeLocal = this._columnType;
    if (
      this._cellLookupsKey !== null ||
      this._lookupDisplayType === LookupDisplayType.Label
    ) {
      columnTypeLocal = ColumnType.Lookup;
    }
    if (this._qGridType) {
      columnTypeLocal = ColumnType.QGrid;
    }
    const column = new ColumnConfiguration(
      text,
      this._fieldName,
      columnTypeLocal,
    );
    column.cellLookupsKey =
      this._cellLookupsKey === null
        ? () => this._fieldName
        : this._cellLookupsKey;
    column.valueType = this._valueType;
    column.inputRequiredType = this._inputRequiredType;
    if (this._columnHeaderText !== null) {
      column.columnHeaderText = this._columnHeaderText;
    }
    if (this._tooltipText !== null) {
      column.tooltipText = this._tooltipText;
    }
    column.cellPlugins = this._cellPlugins;
    column.filterConfiguration = this._filterConfiguration;
    column.lookupDisplayType = this._lookupDisplayType;
    column.pipeType = this._pipeType;
    column.pipeFormat = this._pipeFormat;
    column.nullSubstitute = this._nullSubstitute;
    column.onCellClick = this._onCellClick;
    column.isColumnVisible = this._isColumnVisible;
    column.isEditable = this._isEditable;
    column.defaultValue = this._defaultValue;
    column.customCellActions = this._customCellActions;
    column.defaultSelectedRowCellValue$ = this._defaultSelectedRowCellValue$;
    column.enableRowClickEmit = this._enableRowClickEmit;
    column.cellCssClass = this._cellCssClass;
    column.customSort = this._customSort;
    column.customFooter = this._customFooter;
    column.filterBoxValue = this._filterBoxValue;
    column.width = this._width;
    column.resizable = this._resizable;
    column.cellGrid = this._cellGrid;
    column.isSticky = this._sticky;
    column.skipDownload = this._skipDownload;
    column.dynamicComponentType = this._dynamicComponentType;
    if (column.columnType === ColumnType.QGrid) {
      column.filterConfiguration = null;
    }
    return column;
  }
}
