import { Injectable, OnDestroy } from '@angular/core';
import { SubjectManager, SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';

@Injectable()
export class ConfirmationSubscriberService implements OnDestroy {
  private readonly _subscriptionManager = new SubscriptionManager();
  private readonly _subjectManager = new SubjectManager();

  get confirmation$() {
    return this._subjectManager.get('confirmation');
  }
  get populateModal$() {
    return this._subjectManager.get('populateModal');
  }

  get closeModal$() {
    return this._subjectManager.get('closeModal');
  }

  constructor() {
    this._subjectManager.register('confirmation');
    this._subjectManager.register('populateModal');
    this._subjectManager.register('closeModal');
  }

  ngOnDestroy(): void {
    this._subscriptionManager.clear();
    this._subjectManager.clear();
  }

  emitModal() {
    this._subjectManager.next('populateModal', true);
  }

  clearEmitModal() {
    this._subjectManager.next('populateModal', false);
  }

  emitConfirmation() {
    this._subjectManager.next('confirmation', true);
  }

  clearEmitConfirmation() {
    this._subjectManager.next('confirmation', false);
  }

  emitCloseModalConfirmation() {
    this._subjectManager.next('closeModal', true);
  }
}
