import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

/***
 * @title Grid datasource builder.
 * This service creates a datasource from any model.
 */
@Injectable({
  providedIn: 'root',
})
export class GridDatasourceBuilder {
  formArray!: FormArray<FormControl<unknown>>;

  constructor(private _formBuilder: FormBuilder) {}

  buildDatasource(
    model: any[],
  ): MatTableDataSource<FormGroup<any>, MatPaginator> {
    this.formArray = this._buildFormArray(model);
    return new MatTableDataSource<any>(this.formArray.controls);
  }

  /***
   * @title build Form Array
   * @param model - any[] - the rows to build the FormArray from
   */
  private _buildFormArray(model: any[]): FormArray<FormControl<any>> {
    return this._formBuilder.array(
      model.map(
        row =>
          this._formBuilder.group(
            Object.keys(row).reduce((p, key) => {
              (p as any)[key] = [row[key]];
              return p;
            }, {}),
          ) as any,
      ),
    );
  }
}
