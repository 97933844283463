import { Pipe, PipeTransform } from '@angular/core';
import { DockVisibleState } from './dock/dock-visible-state';

@Pipe({
  name: 'parentContainerHeightPercent'
})
export class ParentContainerHeightPercentPipe implements PipeTransform {
  transform(value: number, args?: DockVisibleState): any {
    return `height:
        ${
          args === DockVisibleState.close
            ? 100
            : args === DockVisibleState.maximize
            ? 0
            : 100 - value
        }%;`;
  }
}
