import {
  Component,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UtilityService } from 'core-global-frontend-object-validator';
import { Router } from '@angular/router';
import { SiteMapAccessFilter } from '../../../../site-map-access-filter';
import { SiteMapNode } from 'core-global-frontend-navigation';
import { siteMap } from '../../../../site-map';
import { componentsMap } from '../components_map';
import { Observable, combineLatest, of } from 'rxjs';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DocumentationComponent {
  @ViewChild('dynamic', { read: ViewContainerRef })
  dynamicContainer: ViewContainerRef;
  selectedTabIndex: number | null = null;
  selectedLabel: string | null = null;
  tabList$: Observable<SiteMapNode[]> = this._siteMapAccessFilter
    .filterUnAllowed(siteMap)
    .pipe(
      map(segments =>
        this._utilityService.flattenListNodes<SiteMapNode>(segments),
      ),
      map(segments =>
        segments.find(segment => segment.label === 'Docs' ?? false),
      ),
      map(segment =>
        segment.siteMapNodes.map(siteMapNode => ({
          label: siteMapNode.label,
          path: siteMapNode.path,
          component: componentsMap[siteMapNode.component],
        })),
      ),
    );

  constructor(
    private _router: Router,
    private readonly _siteMapAccessFilter: SiteMapAccessFilter,
    private _utilityService: UtilityService,
  ) {}

  loadComponent(index: number) {
    of(index)
      .pipe(
        withLatestFrom(this.tabList$),
        map(([index, tabList]) => tabList[index].path),
        tap(path => this._router.navigate([path])),
      )
      .subscribe();
  }
}
