import { DomainConfigurationWebService } from './domain-configuration/services/domain-configuration-web.service';
import { DomainConfigurationSchemaService } from './schemas/domain-configuration-schema.service';
import { Component, NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonModule } from '@angular/material/button';
import { KeywordOptimizationCellComponent } from './domain-manager/keyword-optimization-cell/keyword-optimization-cell.component';
import {
  ConfirmationService,
  LayoutModule,
  SearchableSelectControlModule,
  AccessControlModule,
} from '@nimbus/shared-lib';
import { DomainManagerComponent } from './domain-manager/domain-manager.component';
import { DomainCopyCellComponent } from './domain-manager/domain-copy-cell/domain-copy-cell.component';
import { DomainCreatorComponent } from './domain-creator/domain-creator.component';
import { DomainCreatorFormWrapperComponent } from './domain-creator/domain-creator-form-wrapper/domain-creator-form-wrapper.component';
import { DomainCreatorSingleFormComponent } from './types/domain-creator-single-form/domain-creator-single-form.component';
import { DomainCreatorWebService } from './domain-creator/services/domain-creator-web.service';
import { DomainCreatorAddService } from './domain-creator/services/domain-creator-add.service';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { CopyService } from './domain-manager/services/copy.service';
import { DomainManagerWrapperComponent } from './domain-manager/domain-manager-wrapper/domain-manager-wrapper.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DomainManagerFormlyPrefiltersComponent } from './domain-manager/domain-manager-formly-prefilters/domain-manager-formly-prefilters.component';
import { DomainConfigurationComponent } from './domain-configuration/domain-configuration.component';
import { DomainManagerPrefilterSchemaService } from './schemas/domain-manager-prefilter-schema.service';
import { DomainCreatorSchemaService } from './schemas/domain-creator-schema.service';
import { RouterModule } from '@angular/router';
import { DomainCreateLimiterService } from './domain-creator/services/domain-create-limiter.service';
import { DomainConfigurationService } from './domain-configuration/services/domain-configuration.service';
import { DomainListColumnsBuilderService } from './domain-manager/services/domain-list-columns-builder.service';
import { DomainListWebService } from './domain-manager/services/domain-list-web.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { MatSelectModule } from '@angular/material/select';
import { DomainUrlCellWrapperComponent } from './domain-manager/domain-url-cell-wrapper/domain-url-cell-wrapper.component';
import {
  AngularMaterialModule,
  AreaShellComponent,
  PageShellComponent,
} from 'core-global-frontend-common-ui';
import { SnackbarService } from 'core-global-frontend-snackbar';
import { PushPipe } from '@ngrx/component';
import { DomainRoutingModule } from './domain-routing.module';
import { VerticalComponent } from '../../shared/formly/types/linksets/vertical/vertical.component';
import { SubVerticalComponent } from '../../shared/formly/types/linksets/sub-vertical/sub-vertical.component';
import { GeoComponent } from '../../shared/formly/types/linksets/geo/geo.component';
import { LanguageComponent } from '../../shared/formly/types/linksets/language/language.component';
import { PartnerSelectComponent } from '../../shared/formly/types/partner-select/partner-select.component';
import { InfoButtonInputTemplateComponent } from '../../shared/formly/types/info-button-input/info-button-input-template.component';
import { PanelWrapperComponent } from '../../shared/formly/wrappers/panel-wrapper/panel-wrapper.component';
import { TabsTypeComponent } from '../../shared/formly/types/tabs/tabs-type.component';
import { CheckboxTypeComponent } from '../../shared/formly/types/checkbox/checkbox-type.component';
import { ToggleComponent } from '../../shared/formly/types/toggle/toggle.component';
import { WarningComponent } from '../../shared/formly/types/warning/warning.component';
import { WarningWrapperComponent } from '../../shared/formly/wrappers/warning-wrapper/warning-wrapper.component';
import { ActionsWrapperComponent } from '../../shared/formly/wrappers/actions-wrapper/actions-wrapper.component';
import { VerticalSubverticalCountryComponent } from '../../shared/formly/types/linksets/vertical-subvertical-country/vertical-subvertical-country.component';
import { PendingChangesTracker } from '../../shared/services/pending-changes-tracker';
import { PartnerCacheService } from '../../shared/services/partner-cache.service';
import { FormlyModule } from '@ngx-formly/core';
import { CallbackRunnerModule } from 'core-global-frontend-callback-runner';
import {
  GlobalFrontendGridModule,
  GridCellComponentMapperService,
  GridComponent,
} from 'core-global-frontend-grid';
import { ConfigureDomainCellComponent } from './domain-manager/configure-domain-cell/configure-domain-cell.component';

@NgModule({
  declarations: [
    KeywordOptimizationCellComponent,
    DomainManagerComponent,
    DomainCopyCellComponent,
    DomainManagerWrapperComponent,
    DomainManagerFormlyPrefiltersComponent,
    DomainConfigurationComponent,
    DomainCreatorComponent,
    DomainCreatorFormWrapperComponent,
    DomainCreatorSingleFormComponent,
    VerticalComponent,
    SubVerticalComponent,
    GeoComponent,
    LanguageComponent,
    PartnerSelectComponent,
    InfoButtonInputTemplateComponent,
    PanelWrapperComponent,
    TabsTypeComponent,
    CheckboxTypeComponent,
    ToggleComponent,
    WarningComponent,
    WarningWrapperComponent,
    DomainUrlCellWrapperComponent,
    ActionsWrapperComponent,
    VerticalSubverticalCountryComponent,
  ],
  imports: [
    CommonModule,
    CallbackRunnerModule,
    LayoutModule,
    MatTooltipModule,
    AccessControlModule,
    AreaShellComponent,
    PageShellComponent,
    BrowserModule,
    AngularMaterialModule,
    DomainRoutingModule,
    FormlyModule.forChild(),
    ReactiveFormsModule,
    SearchableSelectControlModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    FormlyMaterialModule,
    GridComponent,
    GlobalFrontendGridModule,
    FormlyMatToggleModule,
    RouterModule,
    MatIconModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatSelectModule,
    PushPipe,
  ],
  providers: [
    PendingChangesTracker,
    ConfirmationService,
    CopyService,
    DatePipe,
    PartnerCacheService,
    DomainListWebService,
    DomainManagerPrefilterSchemaService,
    DomainConfigurationSchemaService,
    DomainConfigurationService,
    DomainCreatorSchemaService,
    DomainConfigurationWebService,
    DomainCreatorWebService,
    DomainCreatorAddService,
    DomainCreateLimiterService,
    DomainConfigurationWebService,
    DomainListColumnsBuilderService,
    SnackbarService,
  ],
})
export class DomainModule {
  constructor(_gridCellComponentMapperService: GridCellComponentMapperService) {
    _gridCellComponentMapperService.registerComponent(
      'configure-domain-cell',
      ConfigureDomainCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'keyword-optimization',
      KeywordOptimizationCellComponent as Component,
    );
    _gridCellComponentMapperService.registerComponent(
      'domain-url',
      DomainUrlCellWrapperComponent as Component,
    );
  }
}
