<form
  *ngIf="(isPrefiltersEnabled$ | async) || (hasNoneInputField$ | async)"
  [formGroup]="form"
  (ngSubmit)="submit()">
  <div class="prefilter-title">
    <h2>{{ titleLabel$ | async }}</h2>
    <h4>{{ titleSubLabel$ | async }}</h4>
  </div>
  <div class="prefilter-content">
    <lib-form-container
      class="form-prefilters-container"
      [defaultButtons]="false"
      [fields]="prefiltersfields$ | async"
      [form]="form"
      [model]="prefilters">
    </lib-form-container>
    <div *ngIf="buttonsEnabled$ | async" class="action-buttons-container">
      <button
        mat-flat-button
        [color]="showingRefresh && form.pristine ? 'accent' : 'primary'"
        [disabled]="(form.pristine && !showingRefresh) || !form.valid || (isLoading$ | async)"
        type="submit"
        data-test="pre-filters-submit-button">
        {{ showingRefresh && form.pristine ? 'Refresh' : 'Submit' }}
      </button>
      <button
        mat-flat-button
        color="secondary"
        (click)="reset()"
        [disabled]="form.pristine"
        type="button"
        data-test="pre-filters-reset-button">
        Reset
      </button>
      <button
        mat-flat-button
        color="secondary"
        (click)="clear()"
        *ngIf="showClear$ | async"
        type="reset">
        Clear
      </button>
    </div>
  </div>
</form>