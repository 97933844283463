import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { IError, JsonEditorOptions } from 'ang-jsoneditor';

import { ObjectValidatorService } from '../../../core/object-validator-service/object-validator.service';
import { SubjectManager } from '../../../core/subject-manager';
import { SubscriptionManager } from '@nimbus/global-frontend-subscription-manager';
import { PluginsService } from './validator-plugins/plugins.service';
import { JsonEditorValidator } from './validator-plugins/plugins/jsoneditor-validator.service';
import { map, startWith, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-formly-json-editor',
  templateUrl: './formly-json-editor.component.html',
  styleUrls: ['./formly-json-editor.component.scss']
})
export class FormlyJsonEditorComponent
  extends FieldType<FieldTypeConfig>
  implements OnDestroy, OnInit {
  readonly subjectManager = new SubjectManager();
  private readonly _subscriptionManager = new SubscriptionManager();
  editorOptions = new JsonEditorOptions();
  value: unknown = {};
  private _bkp: unknown;
  private _validators: JsonEditorValidator[] = [];

  constructor(private _ovs: ObjectValidatorService, private _pluginsService: PluginsService) {
    super();
  }

  ngOnDestroy(): void {
    this.subjectManager.clear();
    this._subscriptionManager.clear();
  }

  ngOnInit(): void {
    this.editorOptions.modes = ['code', 'text', 'tree', 'view'];
    this.editorOptions.navigationBar = true;
    this.editorOptions.statusBar = true;
    this.editorOptions.language = 'en';
    this.editorOptions.onValidate = this.validate.bind(this);
    this.editorOptions.onValidationError = this.hasValidationErrors.bind(this);
    this._subscriptionManager.register(
      this.form
        .get('formFields')
        .valueChanges.pipe(
          startWith(this.form.get('formFields').value),
          map(value => {
            const hasKey = this._ovs.isDefined(
              Object.keys(this.form.controls).find(k => this.form.controls[k] === this.formControl as any)
            );

            return hasKey ? JSON.parse(this.formControl?.value ?? '{}') : this.model ?? {};
          }),
          tap(value => (this._bkp = cloneDeep(value))),
          tap(value => (this.value = value))
        )
        .subscribe()
    );
    this._validators = this._buildValidators(this.field?.props?.['validatorPlugins']);
  }

  validate(events: any | any[]): IError[] {
    const validateObject = (obj: any, index: number) => {
      return this._validators
        .filter(validator => validator.isMatch(obj) && !validator.isValid(obj))
        .map(
          validator =>
          ({
            path: [index],
            message: validator.errorMessage
          } as IError)
        );
    };
    if (this._ovs.isObject(events)) events = [events];

    return (events as any[]).reduce(
      (acc, curr, index) => (acc as IError[]).concat(validateObject(curr, index)),
      []
    );
  }

  hasValidationErrors(errors: object[]) {
    this.formControl.setErrors(errors.length > 0 ? { incorrect: true } : null);
  }

  change(event: unknown) {
    // isTrusted is provided when this is called by the submit rather than the change event.
    if (this._ovs.isDefined(event['isTrusted'])) {
      return;
    }

    this.formControl.setValue(JSON.stringify(event));
    if (this._bkp != this.formControl.value) {
      this.formControl.markAsTouched();
      this.formControl.markAsDirty();
    } else {
      this.formControl.markAsUntouched();
      this.formControl.markAsPristine();
    }
  }

  private _buildValidators(validatorsPath: string[]) {
    if (this._ovs.isNullOrEmpty(validatorsPath) || !this._ovs.isArray(validatorsPath)) {
      return [];
    }
    /*
    This method will transform object into 1-depth obj
    Ex: {'a': {'b': 1}} => {'a/b': 1}
        {'a': 1, 'b': {'c': 2}} => {'a': 1, 'b/c': 2}
    */
    const flatten = (obj, roots = [], sep = '/') =>
      Object
        // find props of given object
        .keys(obj)
        // return an object by iterating props
        .reduce(
          (memo, prop) =>
            Object.assign(
              // create a new object
              {},
              // include previously returned object
              memo,
              // Object.prototype.toString.call(obj[prop]) === '[object Object]'
              obj[prop].constructor.name === 'Object'
                ? // keep working if value is an object
                flatten(obj[prop], roots.concat([prop]), sep)
                : // include current prop and value and prefix prop with the roots
                { [roots.concat([prop]).join(sep)]: obj[prop] }
            ),
          {}
        );

    const plugins = flatten(this._pluginsService.plugins());
    return validatorsPath.filter(value => value in plugins).map(value => plugins[value]);
  }
}
