import { ValidationErrors, AbstractControl } from '@angular/forms';
import { LoggerMethodEvent, LoggingService } from '@nimbus/shared-lib';
import { TermModel } from '../../models/term.model';
import { InjectorService } from '../../services/injector.service';
import { DomainTermsCacheService } from '../../services/domain-terms-cache.service';

export function DomainTermsValidator(control: AbstractControl): ValidationErrors {
  const invalidTerms: TermModel[] =
    InjectorService.injector.get(DomainTermsCacheService).invalidTerms;

  let foundTerm = '';
  return invalidTerms.findIndex(termItem => {
    const words = termItem.term.split(' ');
    const found: boolean =
      words.filter(word =>
        standardWordCheck(word, termItem.allowIfContainedInWord, control.value.toLowerCase())
      ).length === words.length;

    if (found) {
      foundTerm = termItem.term;
      InjectorService.injector
        .get(LoggingService)
        .log(
          'DomainTermsValidator',
          'DomainTermsValidator',
          LoggerMethodEvent.Tracing,
          `Term '${termItem.term}' failed validation for value '${control.value}'`
        );
    }

    return found;
  }) > -1
    ? {
        'domain-name-terms-validator': {
          message: `Cannot include "${foundTerm}" in the domain name`
        }
      }
    : {};
}

function wordRegExpCheck(word: string, val: string): boolean {
  return new RegExp(word, 'ig').test(val);
}

function standardWordCheck(word: string, allowIfContainedInWord: boolean, val: string): boolean {
  if (word.length > 0 && val.indexOf(word.toLowerCase()) < 0) return false;

  return allowIfContainedInWord ? new RegExp(`(\\b${word}\\b)`, 'ig').test(val) : true;
}
