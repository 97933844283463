import { Injectable } from '@angular/core';
import { SubverticalReviewCacheService } from '../subvertical-review/services/subvertical-review-cache.service';
import { SubverticalReadonlyCacheService } from '../subvertical-readonly/services/subvertical-readonly-cache.service';

@Injectable()
export class SubverticalReviewsListColumnsBuilderService {
  columns = [
    {
      field: 'friendlyStatus',
      type: 'frontend-status-cell',
      toolTip: 'Current status of the subvertical request.',
      label: 'Status',
    },
    {
      field: 'unused',
      label: 'Actions',
      type: 'subvertical-reviews-action-cell',
      toolTip: 'Click to review the subvertical request',
      csvExportable: false,
      clickEvent: (customCell) => {
      if ((customCell as any)?.review) {
        this._subverticalReviewCacheService.setSubverticalRequestId(customCell.form.controls['id'].value);
        return;
      }
      this._subverticalReadonlyCacheService.setSubverticalRequestId(customCell.form.controls['id'].value);
      },
    },
    {
      field: 'friendlyRequestorName',
      label: 'Partner',
    },
    {
      field: 'vertical',
      label: 'Vertical',
    },
    {
      field: 'subvertical',
      label: 'Subvertical',
    },
    {
      field: 'geoFullName',
      label: 'Geo',
    },
    {
      field: 'languageFullName',
      label: 'Language',
    },
    {
      field: 'requestedDateFormatStr',
      label: 'Date Submitted',
    },
    {
      field: 'reviewedDateFormatStr',
      label: 'Date Reviewed',
    },
  ];

  constructor(
      private _subverticalReviewCacheService: SubverticalReviewCacheService,
      private _subverticalReadonlyCacheService: SubverticalReadonlyCacheService
    ) {}
}
